import React, { useState, useRef, useContext, useEffect, useLayoutEffect } from 'react'

import { Box, Tabs, Tab, Button, Tooltip, Dialog, DialogTitle, DialogContent, Divider } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ConfirmationDialogCaptcha from '../../../Dialogs/ConfimationDialogCaptcha';
import {
    DataGridPro,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    gridClasses,
    LicenseInfo,
    ptBR
} from '@mui/x-data-grid-pro';

import {
    createTheme,
    ThemeProvider,
    StyledEngineProvider
} from '@mui/material/styles';

import { ptBR as corePtBR } from '@mui/material/locale';
import * as moment from "moment";

import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import CallMadeIcon from '@mui/icons-material/CallMade';
import CallReceivedIcon from '@mui/icons-material/CallReceived';

import useNotification from '../../../Notification';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';

import { vivrestyles } from './vivrestyles.js'

import Loading from '../../../Loading';

import api from '../../../../api';
import EditBotModal from './EditBotModal';
import ConfirmationDialog from '../../../Dialogs/Confirmation';
import MarketplaceDialog from '../../../Dialogs/Marketplace';
import DeleteIcon from '@mui/icons-material/Delete';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { constructorContext } from '../../Builder';
import ImportBotModal from './ImportBotModal';
import { replaceMCDU } from './replaceMCDU';
import MCDUBuilderModal from './mcdubuildermodal';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel'
import ErrorIcon from '@mui/icons-material/Error';

import { getSession } from '../../../../auth'

import {
    stringOperators,
    booleanOperators,
    dateOperators
} from "../../../../utils/filterOperators";

const theme = createTheme({
    palette: {
        primary: { main: '#21446C' },
    },
}, ptBR, corePtBR);

const conglomerateRows = (activeTab, rows, builderRows = []) => {
    if (activeTab === 1) return rows
    const data = [...builderRows, ...rows]
    data.map((each, index) => {
        each.idx = index + 1
        each.type = 'bot'
    })
    return data
}

export const BuilderModalDialog = ({ data, onClose, turnOffFrame, builderData }) => {

    const frame = useRef()
    const [isMaximized, setIsMaximized] = useState(false)

    return (
        <>
            <Dialog keepMounted
                open={true}
                disablePortal
                disableEnforceFocus
                sx={{ position: 'absolute' }}
                // onClose={props.handleClose}
                aria-labelledby="customized-dialog-title"
                className={'no-max-width'}
            >
                <DialogTitle id="customized-dialog-title" className={'group-flexbox'}>
                    <span>{data === 'create' ? 'Criar novo' : 'Editar'} Builder</span>
                    <Box>
                        {!isMaximized ?
                            <Tooltip title="Maximizar">
                                <Button className="action-button"  >
                                    <CallMadeIcon onClick={() => { setIsMaximized(true) }} />
                                </Button>
                            </Tooltip>
                            :
                            <Tooltip title="Minimizar" >
                                <Button className="action-button"  >
                                    <CallReceivedIcon onClick={() => { setIsMaximized(false) }} />
                                </Button>
                            </Tooltip>
                        }
                        <Tooltip title="Fechar" >
                            <Button className="action-button"  >
                                <CloseIcon onClick={onClose} />
                            </Button>
                        </Tooltip>
                    </Box>

                </DialogTitle>

                <Divider />

                <DialogContent sx={{ width: "unset", height: "unset" }} className={`vivre-ura-dialog ${isMaximized ? 'maximized' : ''}`}>
                    <object
                        ref={frame}
                        onLoad={turnOffFrame}
                        data={`${builderData.original_url}${data === 'create' ? '/pt-BR/typebots/create' : `/pt-BR/typebots/${data}/edit`}`}
                        type="text/html"
                        style={{ width: "100%", height: "100%", zIndex: 1, position: 'relative' }}
                    />
                </DialogContent>
            </Dialog>
        </>
    )
}


const Vivremenu = ({ data, setData, createVIVRE }) => {


    const { has_voice, activeTab, enterpriseId, setLoading, loading } = useContext(constructorContext)
    const [uraModal, setUraModal] = useState()
    const [editBuilderModal, setEditBuilderModal] = useState()
    const [mcduBuilderModal, setMCDUBuilderModal] = useState()
    const [deleteModal, setDeleteModal] = useState()
    const [openMarketplace, setOpenMarketplace] = useState()
    const [isMaximized, setIsMaximized] = useState(false)
    const [editBotModal, setEditBotModal] = useState()
    const [rows, setRows] = useState([])
    const [mcdus, setMCDUs] = useState([])
    const [builderData, setBuilderData] = useState()
    const frame = useRef()

    const getMCDU = async () => {
        const res = await api.get(`api/enterprises/${enterpriseId}/mcdu/chat/`, { timeout: 0 })
        if (res.status == 200) {
            setMCDUs(res.data)
            return
        }
        useNotification(
            'Ops!',
            'Não foi possível carregar os canais.',
            'danger'
        )

    }

    const getBuilder = async () => {
        const res = await api.get('/api/getBuilder/?marketplace')
        if (res.status === 200) {
            if (res.data.botlist)
                res.data.botlist.map(each => each.builder = true)
            setBuilderData(res.data)
        }
    }

    const exportBuilder = async (data) => {

        const res = await api.get(`/api/getBuilder/getJson/${data.id}/`, { timeout: 0 })

        const jsonData = JSON.stringify(res.data);
        const blob = new Blob([jsonData], { type: 'application/json' });
        const url = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.download = data.name;
        link.click();

        URL.revokeObjectURL(url);
    }

    const exportIvr = async (data) => {
        const type = (activeTab == 0 ? 'bot' : 'voice')

        const url = `/social/ivr_stream?id=${data.id}&name=${data.name}&type=${type}`

        window.location = url
    }


    const getRows = async () => {
        let url = `api/enterprises/${enterpriseId}/ura/`
        url = url + (activeTab == 0 ? 'chat/' : 'voice/')

        const res = await api.get(url, { timeout: 0 })

        if (res.status === 200) {
            setRows(res.data)
            return
        }

        useNotification(
            'Ops!',
            'Não foi possível carregar o bot.',
            'danger'
        )

    }
    useEffect(async () => {
        setLoading(true)
        const promises = [getRows()]
        if (activeTab == 0) {
            promises.push(getBuilder())
            promises.push(getMCDU())
        }
        await Promise.all(promises)
        setLoading(false)
    }, [activeTab])

    const submitDelete = async () => {

        let url = `api/enterprises/${enterpriseId}/ura/`
        url = url + (activeTab == 0 ? 'chat/' : 'voice/') + `${deleteModal.id}/`

        let res = null

        setLoading(true)
        if (deleteModal.builder)
            res = await api.delete(`/api/getBuilder/${deleteModal.id}/`, { timeout: 0 })
        else
            res = await api.delete(url, { timeout: 0 })
        setLoading(false)

        if (res.status === 400){
            useNotification(
                'Ops!',
                res.data,
                'danger'
            )
            return
        }

        if (res?.status === 204) {

            setDeleteModal()
            getRows()

            if (deleteModal.builder) {
                getBuilder()
                getMCDU()
            }


            useNotification(
                'Sucesso!',
                'Bot deletado com sucesso.',
                'success'
            )
            return
        }
        useNotification(
            'Ops!',
            'Não foi possível excluir o bot.',
            'danger'
        )
    }

    const turnOffFrame = () => {
        setLoading(false)
    }

    const turnIframe = () => {
        setLoading(false)

        const element = frame.current.contentWindow.document
        element.head.innerHTML += vivrestyles
        element.getElementById('uraSpace').classList.add('kwik-dialog')

    }

    const testSession = async (data, retry) => {
        try {
            await api.get(data, { timeout: 10000 })
            return true
        } catch (e) {
            const data = await createVIVRE()
            if (data) setData(data)
            return false
        }

    }

    const getURA = async (data, retry = 0) => {
        setLoading(true)
        const res = await api.post('/api/getVIVRE/', data)
        if (res.status == 200) {
            if (await testSession(res.data)) {
                setUraModal(res.data)
            }
            else {
                if (retry <= 3) {
                    setLoading(false)
                    await getURA(data, retry + 1)
                    return
                }
                useNotification(
                    'Ops!',
                    'Ocorreu um erro ao acessar o bot, tente novamente.',
                    'danger'
                );
                setLoading(false)

            }
        }
        else if (res.status == 401) {
            useNotification(
                'Ops!',
                'Você não tem permissão para acessar essa página',
                'danger'
            );
        }
    }

    const CustomToolbar = () => {

        return (
            <GridToolbarContainer className={gridClasses.toolbarContainer}>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <LoadingButton
                    size="small"
                    component="label"
                    startIcon={<AddCircleIcon />}
                    //onClick={() => setEditBotModal({})}
                    onClick={() => {
                        if (!builderData?.embed_url) {
                            useNotification(
                                'Ops!',
                                'O Builder está offline.',
                                'danger'
                            );
                            return
                        }
                        setLoading(true)
                        setEditBuilderModal('create')
                    }}
                >
                    Adicionar
                </LoadingButton>
                {getSession().profile.enterprise.marketplace_access &&
                    <LoadingButton
                        size="small"
                        component="label"
                        startIcon={<ShoppingCartIcon />}
                        onClick={() => setOpenMarketplace({})}
                    >
                        Marketplace
                    </LoadingButton> 
                }
            </GridToolbarContainer>
        );
    }

    const defColumns = () => {
        const columns = []

        columns.push({ field: 'name', headerName: 'Nome', flex: 1, filterOperators: stringOperators })
        columns.push({
            field: 'builder', headerName: 'Builder', flex: 0.4, filterOperators: booleanOperators,
            renderCell: (params) => {
                return (
                    <>
                        {params.value ?
                            params.row.publicId ?
                                <Box><CheckCircleIcon sx={{ 'color': '#5F8963' }} /></Box>
                                : <Tooltip title='Não publicado'><Box><ErrorIcon sx={{ 'color': 'orange' }} /></Box></Tooltip>
                            :
                            <Box><CancelIcon sx={{ 'color': '#C95F50' }} /></Box>
                        }
                    </>
                )
            }
        })

        if (activeTab == 0)
            columns.push({
                field: 'mcduicon', headerName: 'Canais', flex: 0.4, filterable: false,
                renderCell: (params) => {
                    if (params.row.publicId) {
                        const mcdu = [{ entrypoint: 'CHATWEB' },
                        ...mcdus.filter(each => each.builder_id === params.row.id)]
                        return (
                            mcdu.map(each =>
                                <>
                                    <Tooltip title={each.entrypoint.toLocaleLowerCase()}>
                                        <Box>{replaceMCDU(each.entrypoint)}</Box>
                                    </Tooltip>
                                </>
                            )
                        )
                        return <></>
                    }

                    const mcdu = params?.row?.mcdu

                    if (mcdu?.length > 0)
                        return (
                            mcdu.map(each => <>
                                <Tooltip title={each.entrypoint.toLocaleLowerCase()}>
                                    <Box>{replaceMCDU(each.entrypoint)}</Box>
                                </Tooltip>
                            </>
                            )
                        )
                    return <></>
                }
            })
        columns.push(
            {
                field: 'mcdu', headerName: 'Entrada', flex: 1, filterable: false,
                valueGetter: (params) => {
                    if (params.row.builder) {
                        const mcdu = [{ mcdu: params.row.publicId, publicId: true }, ...mcdus.filter(each => each.builder_id === params.row.id)]
                        if (mcdu?.length > 0) {
                            return mcdu
                        }
                        return ''
                    }
                    const mcdu = params?.row?.mcdu

                    if (mcdu?.length > 0) {
                        return mcdu.map(each => each.mcdu).join(', ')
                    }
                    return ''
                },
                renderCell: (params) => {
                    if (params.row.builder) {
                        const link = builderData?.original_url.replace('builder', 'bot')
                        return (
                            <span style={{ whiteSpace: 'pre' }}>
                                {
                                    params.value.map(each => each.publicId ?
                                        <a href={`${link}/${each.mcdu}`} target="_blank">{each.mcdu}</a>
                                        : <span>{each.mcdu}</span>)
                                        .reduce((prev, curr) => [prev, ", ", curr])
                                }
                            </span>)
                    }
                    return params.value
                }
            }
        )

        columns.push({
            field: 'initial_module', headerName: 'Módulo inicial', flex: 1, filterable: false,
            valueGetter: ({ row }) => {
                const mcdu = row?.mcdu
                const modules = row?.modules
                if (mcdu?.length > 0 && modules) {
                    return modules.find(each => mcdu[0].ivr_module_id === each.id)?.name
                }
                return ''
            }
        })
        columns.push({
            field: 'modificated', type: 'dateTime', headerName: 'Data de modificação', flex: 1, filterOperators: dateOperators,
            renderCell: (params) => {
                let respValue = params.value
                if (params.row.updatedAt) {
                    respValue = params.row.updatedAt
                }
                return (
                    <Box>
                        {respValue ? moment(respValue).format("DD/MM/YYYY HH:mm:ss").toString() : ""}
                    </Box>
                )
            },
        })
        columns.push(
            {
                field: 'action', headerName: 'Ações', flex: 0.5, filterable: false, sortable: false,
                renderCell: (params) => (
                    <>
                        <Tooltip title="Editar" >
                            <Button
                                className="action-button"
                                onClick={() => { 
                                    if (params.row.builder) {
                                        setMCDUBuilderModal(params.row)
                                        return
                                    }
                                    setEditBotModal(params.row)
                                }}
                            >
                                <EditIcon />
                            </Button>
                        </Tooltip>
                        <Tooltip title={`${params.row.builder ? "Editar Builder" : "Editar URA"}`} >
                            <Button
                                className="action-button"
                                onClick={() => { 
                                    if (params.row.builder) {
                                        setLoading(true)
                                        setEditBuilderModal(params.row.id)
                                        return
                                    }
                                    getURA(params.row)
                                }}
                            >
                                <PrecisionManufacturingIcon />
                            </Button>
                        </Tooltip>
                        <Tooltip title="Exportar URA" >
                            <Button
                                className="action-button"
                                onClick={() => {
                                    if (!params.row.builder)
                                        exportIvr(params.row)
                                    else
                                        exportBuilder(params.row)
                                }}
                            >
                                <FileDownloadIcon />
                            </Button>
                        </Tooltip>
                        <Tooltip title="Excluir URA">
                            <Button className="action-button delete"
                                onClick={() => setDeleteModal(params.row)}>
                                <DeleteIcon />
                            </Button>
                        </Tooltip>
                    </>
                ),
            }
        )

        return columns
    }
    const finalRows = conglomerateRows(activeTab, rows, builderData?.botlist)

    return (
        <>

            {builderData &&
                <Box sx={{ overflow: "hidden", position: 'fixed', left: '-999rem' }}>
                    <iframe id="builder" src={builderData.embed_url} type="text/html"  ></iframe>
                </Box>
            }

            {openMarketplace 
                ? <MarketplaceDialog
                    open={openMarketplace}
                    handleClose={() => setOpenMarketplace(false)}
                    marketplace={builderData?.marketplace}
                    getBuilder={getBuilder}
                ></MarketplaceDialog>
                : null
            }
            {editBotModal ?
                <EditBotModal
                    activeTab={activeTab}
                    data={editBotModal}
                    setData={setEditBotModal}
                    getRows={() => {
                        getRows()
                        getMCDU()
                    }
                    } />
                :
                null
            }
            {mcduBuilderModal ?
                <MCDUBuilderModal
                    data={mcduBuilderModal}
                    mcdus={mcdus}
                    updateData={() => {
                        getMCDU()
                        getRows()
                        setMCDUBuilderModal()
                    }}
                    handleClose={() => { setMCDUBuilderModal() }}
                />
                :
                null
            }
            {deleteModal ?

                <ConfirmationDialogCaptcha open={true}
                    title="Excluir Fluxo"
                    description={
                        <span>
                            Para confirmar a ação, digite: <span style={{ 'fontWeight': 'bold' }}>{deleteModal.name}</span> no campo abaixo.</span>}
                    handleClose={() => { setDeleteModal() }}
                    handleSubmit={() => { submitDelete() }}
                    captcha={deleteModal.name}
                />

                :
                null
            }

            {editBuilderModal && 
                <BuilderModalDialog 
                    data={editBuilderModal}
                    onClose={() => { setEditBuilderModal(); getMCDU(); getBuilder() }}
                    turnOffFrame={turnOffFrame}
                    builderData={builderData}
                />
            }

            {uraModal &&
                <Dialog keepMounted
                    open={uraModal}
                    disablePortal
                    disableEnforceFocus
                    sx={{ position: 'absolute' }}
                    // onClose={props.handleClose}
                    aria-labelledby="customized-dialog-title"
                    className={'no-max-width'}
                >
                    <DialogTitle id="customized-dialog-title" className={'group-flexbox'}>
                        <span>Editar URA</span>

                        <Box>
                            {!isMaximized ?
                                <Tooltip title="Maximizar">
                                    <Button className="action-button"  >
                                        <CallMadeIcon onClick={() => { setIsMaximized(true) }} />
                                    </Button>
                                </Tooltip>
                                :
                                <Tooltip title="Minimizar" >
                                    <Button className="action-button"  >
                                        <CallReceivedIcon onClick={() => { setIsMaximized(false) }} />
                                    </Button>
                                </Tooltip>
                            }
                            <Tooltip title="Fechar" >
                                <Button className="action-button"  >
                                    <CloseIcon onClick={() => { setUraModal() }} />
                                </Button>
                            </Tooltip>
                        </Box>

                    </DialogTitle>

                    <Divider />

                    <DialogContent sx={{ width: "unset", height: "unset" }} className={`vivre-ura-dialog ${isMaximized ? 'maximized' : ''}`}>

                        <object
                            ref={frame}
                            onLoad={turnIframe}
                            data={uraModal}
                            type="text/html"
                            style={{ width: "100%", height: "100%", zIndex: 1, position: 'relative' }}
                        />
                    </DialogContent>
                </Dialog>}

            {activeTab == 0 &&
                <Box className="settings-body-bot">
                    <StyledEngineProvider injectFirst >
                        <ThemeProvider theme={theme}>
                            <DataGridPro
                                // pageSize={25}
                                pagination
                                disableSelectionOnClick
                                disableDensitySelector
                                density="compact"
                                getRowId={(each) => each.idx}
                                columns={defColumns()}
                                rows={finalRows}
                                components={{
                                    Toolbar: CustomToolbar,
                                }}
                            />
                        </ThemeProvider>
                    </StyledEngineProvider>
                </Box>
            }
            {has_voice && activeTab == 1 &&
                <Box className="settings-body-bot">
                    <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={theme}>
                            <DataGridPro
                                // pageSize={100}
                                pagination
                                disableSelectionOnClick
                                disableDensitySelector
                                density="compact"
                                columns={defColumns()}
                                rows={rows}
                                components={{
                                    Toolbar: CustomToolbar,
                                }}
                            />
                        </ThemeProvider>
                    </StyledEngineProvider>
                </Box>
            }
        </>
    )
}

export default Vivremenu
