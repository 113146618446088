
import React, { useContext, useState, useEffect, useCallback, useReducer } from 'react'

import { createPortal } from 'react-dom';

import { Dialog, DialogTitle, ListItem, Button, DialogActions, Divider, DialogContent, Box, Tooltip, TextField, IconButton } from '@mui/material'

import ResponsiveImportButton from '../../../Clients/ResponsiveImportButton'

import useNotification from '../../../Notification'
import api from '../../../../api'
import { ScreenContext } from "../.."
import { QueueSocket } from '../../../../routes'
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import Loading from '../../../Loading'
import HelpIcon from '@mui/icons-material/Help';

import { FixedSizeList } from 'react-window';
import AutoSizer from "react-virtualized-auto-sizer"

import './styles.css'

const numberRexed = /^[^0-9+]|(?<=.)[^0-9]/g

const validate = (item, pos) => {
	const errors = []
	switch (pos) {
		case 'nome':
			if (item.nome.length > 100) {
				errors.push('Cada nome só pode ter no máximo 100 caracteres.')
			}
			break
		case 'email':
			if (item.email.length > 100) {
				errors.push('Cada email só pode ter no máximo 100 caracteres.')
			}
			break

		case 'segmentos':
			item.segmentos.split(',').some(each => {
				if (each.length > 50)
					errors.push('Cada segmento só pode ter no máximo 50 caracteres.')
			})
			break
		case 'customer_code':
			if (item.customer_code?.length > 100) {
				errors.push('Cada código só pode ter no máximo 100 caracteres.')
			}
			break
		case 'telefone':
			if (item.telefone.length > 15)
				errors.push('Telefone só pode ter até 15 caracteres')
			const chars = item.telefone.match(numberRexed)
			if (chars)
				errors.push(`Existem caracteres inválidos: ${chars.join(', ')}`)
			break
		case 'enterprise_cnpj':
			if (item.enterprise_cnpj && item.enterprise_cnpj.match(/[^0-9]/)) {
				errors.push('CNPJ deve conter apenas números.')
			}
			break
		default:
			break
	}
	if (errors.length === 0) {
		return ''
	}

	return errors.map(erro => (<Box>{erro}</Box>))
}

const revalidate = (data) => {

	data.map(row => {
		const erros = ['nome', 'email', 'telefone', 'codigo', 'segmentos','enterprise_cnpj'].some(pos => validate(row, pos))
		row.error = erros
	})

	const newdata = data.sort((x, y) => Boolean(y.error) - Boolean(x.error))
	return newdata
}

const reposition = (data) => {
	data.some((each, index) => { each.position = index })
	return data
}

const maxLengthPos = {
	'segmentos': 99999,
	'telefone': 15,
	'email': 100,
	'customer_code': 100,
	'nome': 100
}

const RowEdit = ({ item, pos, label, revalidate }) => {
	const [editing, setEditing] = useState(false)
	const [loadeditem, setLoadedItem] = useState(item[pos])
	const [error, setError] = useState([])

	const submit = () => {
		setEditing(false)
		item[pos] = loadeditem
		revalidate()
	}

	useEffect(() => {
		setError(validate(item, pos))
	}, [editing])

	return (
		<Tooltip title={(error)} describeChild={true}>
			<span className={error ? 'error' : ''}>
				{!editing ?
					<>
						<Box>{loadeditem}</Box>
						<Tooltip title={label}><Button onClick={() => setEditing(true)} className='action-button'>
							<EditIcon />
						</Button>
						</Tooltip>
					</>

					:
					<>
						<TextField
							className='edit-import-input'
							value={loadeditem}
							variant='standard'
							onChange={(e) => setLoadedItem(e.target.value)}
							inputProps={{
								maxLength: maxLengthPos[pos]
							}}
						/>
						<Tooltip title={'Salvar'}><Button onClick={submit} className='action-button'><SaveIcon /></Button></Tooltip>
					</>
				}
			</span>
		</Tooltip>

	)
}


const ImportClients = () => {
	const { getGroups, getClients, enterpriseId, updateReady, permissions, settings } = useContext(ScreenContext)
	const { cache, setCache } = useContext(QueueSocket)
	const [selectedClients, updateSelectedClients] = useState([])
	const [separatorModalOpen, setSeparatorModalOpen] = useState(false);
	const [selectedSeparator, setSelectedSeparator] = useState(',');
	const [loadedData, updateLoadedData] = useState(null)
	const [loading, setLoading] = useState(false)

	const handleReplicateSegments = () => {
		if (loadedData && loadedData.length > 0) {
			const firstSegment = loadedData[0].segmentos;
			const newData = loadedData.map(item => ({
				...item,
				segmentos: firstSegment
			}));
			updateLoadedData(newData);
		}
	};

	const RowItem = (props) => {
		const { index, style } = props;

		const item = loadedData.find(item => item.position === index)

		if (!item) {
			return (<></>)
		}
		return (
			<ListItem style={style} className={`import-contact-display-item item ${item.error ? 'error' : ''}`} key={`import-contact-item${item.id}`}>
				<RowEdit item={item} pos='nome' label='Editar nome' revalidate={() => updateLoadedData([...revalidate(loadedData)])} />
				<RowEdit item={item} pos='email' label='Editar email' revalidate={() => updateLoadedData([...revalidate(loadedData)])} />
				<RowEdit item={item} pos='telefone' label='Editar telefone' revalidate={() => updateLoadedData([...revalidate(loadedData)])} />
				<RowEdit item={item} pos='customer_code' label='Editar Cód. Cliente' revalidate={() => updateLoadedData([...revalidate(loadedData)])} />
				<RowEdit item={item} pos='enterprise_name' label='Editar Nome' revalidate={() => updateLoadedData([...revalidate(loadedData)])} />
				<RowEdit item={item} pos='enterprise_cnpj' label='Editar CNPJ' revalidate={() => updateLoadedData([...revalidate(loadedData)])} />
				<RowEdit item={item} pos='segmentos' label='Editar segmentos' revalidate={() => updateLoadedData([...revalidate(loadedData)])} />
				<span className='item-last'>
					<Tooltip title='Excluir linha '>
						<Button onClick={() => updateLoadedData(reposition(loadedData.filter(each => each !== item)))} className='action-button delete'>
							<DeleteIcon />
						</Button>
					</Tooltip></span>
			</ListItem>

		)
	}



	const onChangeImportClients = async (event) => {

		const oldCache = localStorage.getItem('uploadCache');

		if (oldCache) {
			useNotification(
				'Ops!',
				'Já existe uma importação em andamento..',
				'danger'
			);
			return
		}

		const newCache = Math.random()

		const newdata = new FormData()
		newdata.append('cache_key', newCache)

		const json = JSON.stringify(loadedData);
		const blob = new Blob([json], {
			type: 'application/json'
		});

		newdata.append('data', blob)

		importCSV(newdata, newCache);

		// Resetting file input
		event.target.value = null;
	}

	const importCSV = async (data, newcache) => {

		updateReady(false)

		const res = await api.post(`/api/enterprises/${enterpriseId}/contacts/upload_contacts/`, data, { timeout: 0 })

		updateReady(true)

		if (res.status === 200) {
			useNotification(
				'Por favor aguarde!',
				'A importação em massa pode demorar e ficará disponível na aba abaixo. Continue a usar o kwik normalmente.',
				'info'
			);
			setCache(newcache)
		}

		if (res.status === 403) {
			useNotification(
				'Ops!',
				'A importação em massa não é permitida para contas integradas com CRM.',
				'danger'
			);
		}

		if (res.status === 500) {
			useNotification(
				'Ops!',
				'Ocorreu um erro interno no servidor.',
				'danger'
			);

		}
	}

	// const submitClientGroupForm = async () => {
	// 	updateShowAddClientGroupForm(false)
	// }

	const closeClientGroupForm = () => {

		// updateShowAddClientGroupForm(false)
		getClients()
		getGroups()
	}

	const importClientsButtonIsDisabled = () => {
		if (settings.is_financial_market_company && !permissions.isAdmin)
			return true
		return false
	}

	const errorCallback = useCallback((message) => {
		useNotification(
			'Ops!',
			message,
			'danger'
		)
	}, [])

	const clearText = (value) => {
		return value.replace(/[^a-zA-Z0-9,;|@._\- \u00C0-\u024F]+/g, '')
	}

	const clearNumber = value => {
		return value.replace(/[^0-9]+/g, '')
	}

	const parseArray = (array, separator) => {

		const rawdata = array.split(/\r?\n/)
		if (rawdata.length === 0)
			return errorCallback('Arquivo inválido')

		let last_index = 3
		let has_code = false

		if (rawdata[0].toLocaleLowerCase().includes('codigo')) {
			last_index = 4
			has_code = true
		}

		let has_enterprise_name = false
		if (rawdata[0].toLocaleLowerCase().includes('enterprise_name')) {
			has_enterprise_name = true
			if (has_code) {
				last_index = 5
			} else {
				last_index = 4
			}
		}

		let has_enterprise_cnpj = false
		if (rawdata[0].toLocaleLowerCase().includes('enterprise_cnpj')) {
			has_enterprise_cnpj = true
			if (has_enterprise_name && has_code) {
				last_index = 6
			} else {
				last_index = 5
			}
		}


		if (rawdata[0].toLocaleLowerCase().includes('nome'))
			rawdata.shift()

		const modData = rawdata.map((row, index) => {
			const data = clearText(row).split(separator)
			const returndata = { id: index }
			const maxLength = data.length
			returndata.nome = data[0] ? data[0].trim() : ''
			returndata.email = data[1] ? data[1].trim() : ''
			returndata.telefone = data[2] ? clearNumber(data[2]) : ''
			if (has_code)
				returndata.customer_code = data[3] ? data[3].trim() : ''

			if (has_enterprise_name) {
				if (has_code) {
					returndata.enterprise_name = data[4] ? data[4] : ''
				} else {
					returndata.enterprise_name = data[3] ? data[3] : ''
				}
			}

			if (has_enterprise_cnpj) {
				if (has_code) {
					returndata.enterprise_cnpj = data[5] ? clearNumber(data[5]) : ''
				} else {
					returndata.enterprise_cnpj = data[4] ? clearNumber(data[4]) : ''
				}
			}

			returndata.segmentos = data.slice(last_index, maxLength).join(', ')

			return returndata
		})

		const newdata = revalidate(modData)

		updateLoadedData(reposition(newdata))
		setLoading(false)

	}

	const prepareData = (file, separator) => {

		const oldCache = localStorage.getItem('uploadCache');

		if (oldCache) {
			useNotification(
				'Ops!',
				'Já existe uma importação em andamento..',
				'danger'
			);
			return
		}

		var reader = new FileReader();
		const eventFile = file;
		reader.onload = function (e) {
			parseArray(e.target.result, separator);
		}
		reader.readAsText(eventFile);
		setLoading(true)
	}

	const addNovaEntrada = () => {
		reposition
		const newitem = {
			nome: '',
			email: '',
			telefone: '',
			customer_code: '',
			segmentos: ''
		}
		updateLoadedData(reposition(revalidate([newitem, ...loadedData])))
	}

	const errors = loadedData?.filter(each => each.error).length

	return (
		<>

			{createPortal((<Loading loading={loading} />), document.body)}
			{loadedData && <>
				<Dialog
					open={true}
					fullWidth
					maxWidth='xl'
					disableEnforceFocus
					className='import-contact-dialog'
				>
					<DialogTitle id="customized-dialog-title" className={'group-flexbox'}>
						<span>Importar contatos</span> <Button sx={{ 'minWidth': '2rem', 'padding': '0' }} onClick={() => { updateLoadedData(null) }} ><CloseIcon /></Button>
					</DialogTitle>
					<Divider />
					<DialogContent className='import-contact-content'>
						<Box className='import-contact-display-item'>
							<Box>Quantidade de contatos: {loadedData.length}</Box>
							<Box>Quantidade de contatos com erros: {errors}</Box>
						</Box>
						<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
							<LoadingButton
								size="small"
								component="label"
								sx={{ alignSelf: 'self-start' }}
								startIcon={<AddCircleIcon />}
								onClick={addNovaEntrada}
							>
								Adicionar
							</LoadingButton>
							<LoadingButton
								size="small"
								component="label"
								sx={{ alignSelf: 'flex-end' }}
								startIcon={<IconButton />}
								onClick={handleReplicateSegments}
							>
								Replicar segmentos
							</LoadingButton>
						</Box>
						<Box sx={{ height: "100%", display:'flex', flexDirection:'column' }}>
							<ListItem className='import-contact-display-item header' key={`import-contact-item`}>
								<span>Nome</span>
								<span>Email</span>
								<Tooltip title="Obrigatório, 10 a 15 caracteres, pode ou não começar com + ou +55" placement="bottom" disableInteractive>
									<span>
										<div className='import-contact-segment-icon'>Telefone <HelpIcon className="question-icon" /></div>
									</span>
								</Tooltip>
								<span>Cod. Cliente</span>
								<span>Empresa</span>
								<span>CNPJ</span>
								<Tooltip title="Segmentos devem ser separados por vírgula." placement="bottom" disableInteractive>
									<span>
										<div className='import-contact-segment-icon'>Segmentos <HelpIcon className="question-icon" /></div>
									</span>
								</Tooltip>

								<span className='item-last'></span>
							</ListItem>
							<Box sx={{ height: "100%" }}>
								<AutoSizer >
									{({ height, width }) =>
									(
										<>
											<FixedSizeList
												height={height}
												width={width}
												itemSize={40}
												itemCount={loadedData.length}
												overscanCount={10}
											>
												{RowItem}
											</FixedSizeList>
										</>
									)}
								</AutoSizer>
							</Box>
						</Box>
					</DialogContent>
					<DialogActions style={{ padding: "10px 20px 10px 10px" }}>
						<LoadingButton
							onClick={onChangeImportClients}
							color="primary"
							disabled={(errors || loadedData.length === 0)}
						>
							Enviar
						</LoadingButton>
					</DialogActions>
				</Dialog>
			</>
			}

			<ListItem className={'menuItem'} sx={{ padding: 0 }}>
				<ResponsiveImportButton
					className="containedImportButton"
					onClickFn={() => {
						if (permissions && !permissions.isSupervisor)
							useNotification(
								'Ops!',
								'Você não tem permissão para executar essa ação, fale com um supervisor ou administrador da conta.',
								'danger'
							);
					}}
					isDisabled={importClientsButtonIsDisabled()}
					title={"Importar"}
					shouldRenderHiddenInput={permissions && permissions.isSupervisor}
					onChangeFn={prepareData}
					is_financial_market_company={settings.is_financial_market_company}
				/>
			</ListItem>

		</>

	)
}

export default ImportClients
