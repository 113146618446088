import React, { useEffect, useState, useCallback, useMemo } from 'react'

import { Box, Alert, AlertTitle, Chip } from '@mui/material'

import LoadingButton from '@mui/lab/LoadingButton';

import { ConnectButton } from "360dialog-connect-button";

import AddIcon from '@mui/icons-material/Add';

import useNotification from "../../Notification";
import Loading from '../../Loading';
import api from '../../../api';

import { FacebookPlugin } from '../Instagram/index.js';

import { getSession } from '../../../auth.js';

import './styles.css'

const Channels = ({ enterprise }) => {

    const partnerId = 'DrXGjiPA';
    const user = getSession();

	const [loading, setLoading] = useState(false);
	const [waiting, setWaiting] = useState(false);
	const [numbers, setNumbers] = useState([]);
	const [reload, setReload] = useState(false)
	const [connectedPage, setConnectedPage]= useState(null)
	const [metaAccount, setMetaAccount] = useState(false);

	const reloadState = () => {setTimeout(()=>setReload(p=>!p), 500)}

    const handleConnectCallback = (callbackObject) => {
        const clientId = callbackObject.client;
        const url = `api/enterprises/${enterprise.id}/360dialog_channels/`;

        callbackObject.channels.forEach((channel) => {
            api.post(url, { channel_id: channel }, { timeout: 0 })
            .then((res) => {
                if (res.status == 201) {
                    window.location.href = '/settings'
                    return;
                }
                if (res.status == 400) {
                    // TODO: callback is being executed twice, within
                    // 360dialog's window and again when it returns here
                    // this should be separated, otherwise it will throw
                    // a 400 BAD_REQUEST here
                    return;
                }
                throw res;
            })
            .catch((err) => {
                console.error(err);
            });
        });
    }

	const getNumbers = useCallback(async () => {
		setLoading(true)
		const res = await api.get(`api/enterprises/${enterprise.id}/360dialog_channels/`)
		setLoading(false)
		if (res.status !== 200) {
			useNotification(
	            'Ops!',
	            'Não foi possível encontrar os números de WhatsApp.',
	            'danger'
	        );
			return
		}
		if (res.data.length === 0)
			return getMetaNumbers()
		setNumbers(res.data)
	}, [])

	const getMetaNumbers = useCallback(async () => {
		setLoading(true)
		const res = await api.get(`api/enterprises/${enterprise.id}/whatsapp/`)
		setLoading(false)
		if (res.status !== 200) {
			// setMetaAccount(true) // TODO: uncomment when live
			useNotification(
	            'Ops!',
	            'Não foi possível encontrar os números de WhatsApp.',
	            'danger'
	        );
			return
		}
		setNumbers(res.data)
	}, [])

	useEffect(() => {
		getNumbers()
	}, [])

	useEffect(() => {
		const queryParams = new URLSearchParams(window.location.search);
		// Wait while API key is being generated
		setWaiting(queryParams.has('client') || queryParams.has('channels'))
	}, [])


	const facebookPlugin = useMemo(() => {
		return (<FacebookPlugin reloadState={reloadState} reload={reload} setConnectedPage={setConnectedPage} app="WHATSAPP"/>)
	}, [reload])

	const launchWhatsAppSignup = () => {
	    // Launch Facebook login
	    FB.login(function (response) {
			if (response.authResponse) {
				const code = response.authResponse.code;
				if (code) {
					const payload = {
						'user_token' : connectedPage['authResponse']['accessToken'],
						'code'       : code
					}
					setLoading(true)
					api.post(`api/enterprises/${enterprise.id}/whatsapp/`, payload, { timeout: 0 }).then((res) => {
						setLoading(false)
						if (res.status !== 201) {
							useNotification(
					            'Ops!',
					            'Algo deu errado, tente novamente.',
					            'danger'
					        );
							return
						}
						setNumbers(res.data)
					})
				}
			} else {
				console.log('User cancelled login or did not fully authorize.');
			}
		}, {
			config_id: process.env.REACT_APP_WHATSAPP_APP_CONFIG_ID ?? '788098360113528',
			response_type: 'code',
			override_default_response_type: true,
			extras: {
				setup: {
					"feature": "whatsapp_embedded_signup",
					"sessionInfoVersion": 2
				}
			}
		});
	}

	return (
        <Box className="input-settings settings-body">
        	<Loading loading={loading}/>
        	<Loading loading={! loading && waiting} message="Gerando chave de API..."/>
        	{metaAccount &&
	        	<Alert className="telegram-info" severity="info">
					<AlertTitle className="push-bottom">
						Para o kwik com o WhatsApp é necessário:
					</AlertTitle>
					<Box>
						<p>Uma conta empresarial no facebook.</p>
						<p>Ceder permissões ao Kwik, clicando no botão abaixo: {facebookPlugin}</p>
					</Box>
					<Box className="push-s-top">
						Você pode adicionar seu número clicando no botão abaixo.
					</Box>
				</Alert>
        	}
        	{numbers.length !== 0 &&
        		<>
		        	<Box className="category-title push-bottom">
		                Números
		            </Box>
		            <ul className="whatsapp-numbers">
			            {numbers.map((item, index) => (
			            	<li key={index}>
                                <span>{item.phone_number}</span>
                                <span>{item.phone_name}</span>
                                {! metaAccount &&
	                                <Chip className="number-status" 
		                                label={item.info_360dialog?.account_mode == "live" ? "Ativo" : "Inativo" }
		                                color={item.info_360dialog?.account_mode == "live" ? "success" : "error" }
		                                size="small"
		                            />
                            	}
			            	</li>
			            ))}
		            </ul>
		        </>
        	}
        	{numbers.length < enterprise.max_numbers &&
        		<Box>
        			{metaAccount &&
		    			<LoadingButton 
		    				startIcon={<AddIcon />} 
			        		variant="contained"
			        		onClick={() => launchWhatsAppSignup()}
			        	>
			        		Adicionar
			        	</LoadingButton>
        			}
        			{! metaAccount &&
			        	<ConnectButton partnerId={partnerId}
			                callback={handleConnectCallback}
			                queryParameters={{
			                    email        : user.username,
			                    name         : `${user.first_name} ${user.last_name}`,
			                    redirect_url : window.location.href
			                }}
			                className="onboarding-connect-button"
			                label={(<><AddIcon /> Adicionar</>)}
			            />
        			}
		        </Box>
        	}
        	{numbers.length >= enterprise.max_numbers &&
        		<Box className="whatsapp-mumbers-info">
		        	<LoadingButton startIcon={<AddIcon />} 
		        		variant="contained" 
		        		disabled={true}
		        	>Adicionar</LoadingButton>
		        	<Alert severity="info">
		        		Entre em contato com seu gerente de contas para contratar mais uma linha. Caso já tenha adquirido a linha, fale com nosso suporte para liberação.
		        	</Alert>
		        </Box>
        	}
        </Box>
	)
}

export default Channels
