import React, { useState, useEffect, useContext } from 'react';

import {
    Box,
    Button,
    Divider,
    Tooltip,
} from '@mui/material';

import {
    DataGridPro,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    ptBR,
} from '@mui/x-data-grid-pro';
import { ptBR as corePtBR } from '@mui/material/locale';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { Dialog, DialogTitle } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import MessageIcon from '@mui/icons-material/Message';
import SendTimeExtensionIcon from '@mui/icons-material/SendTimeExtension';

import '../../../styles.css';
import api from '../../../../../api';


const CampaignHistory = props => {

    const [loading, updateLoading] = useState(false)
    const [historyData, updateHistoryData] = useState([])

    const fetchHistoryData = async (id) => {
        updateLoading(true)
        const url = `/api/enterprises/${props.enterpriseId}/campaign/${id}/campaign_history/`;
        const response = await api.get(url)
        updateLoading(false)
        updateHistoryData(response.data)
    }

    useEffect(() => {
        updateHistoryData([])
        if (props.data.id && props.open)
            fetchHistoryData(props.data.id)
    }, [props.open])

    const getDateRange = (row) => {
        const startDate = new Date(row.run_datetime)
        startDate.setHours(0, 0, 0, 0)
        startDate.setHours(startDate.getHours() - 3)

        const endDate = new Date(row.run_datetime)
        endDate.setHours(23, 59, 59, 0)
        endDate.setHours(endDate.getHours() - 3)

        return [startDate.toISOString().slice(0, 16), endDate.toISOString().slice(0, 16)]
    }

    const createHistoryColumns = () => {
        return (
            [
                {
                    field: 'run_datetime', headerName: 'Data', flex: 1, type: 'dateTime',
                    valueGetter: ({ value }) => {
                        if (value) {
                            const date = new Date(value);
                            date.setHours(date.getHours() - 3);
                            return date;
                        }
                    },
                    renderCell: (params) => (
                        <Box>
                            {params.value ? params.value.toLocaleString('pt-BR') : ''}
                        </Box>
                    )
                },
                { field: 'amount', headerName: 'Quantidade', flex: 0.5 },
                {
                    field: 'success_percentage', headerName: 'Sucesso', flex: 0.5,
                    renderCell: (params) => (
                        <Box>
                            {params.value}%
                        </Box>
                    )
                },
                {
                    field: 'used_percentage', headerName: 'Resposta', flex: 0.5,
                    renderCell: (params) => (
                        <Box>
                            {params.value}%
                        </Box>
                    )
                },
                {
                    field: 'notsent_percentage', headerName: 'Não enviadas', flex: 0.5,
                    renderCell: (params) => (
                        <Box>
                            {params.value}%
                        </Box>
                    )
                },
                {
                    field: 'resent', headerName: 'Ações', flex: 0.5,
                    renderCell: (params) => (
                        <Box>
                            <Tooltip title="Ver disparos" placement="top" disableInteractive>
                                <Button className="action-button"
                                    onClick={() => props.setupNotification(props.data.id, getDateRange(params.row))}>
                                    <MessageIcon />
                                </Button>
                            </Tooltip>
                            <Tooltip title="Reenviar notificações" placement="top" disableInteractive>
                                <Button className="action-button"
                                    onClick={() => props.resendNotifications(params.row.notsent_ids, props.data.id, getDateRange(params.row))}>
                                    <SendTimeExtensionIcon/>
                                </Button>
                            </Tooltip>
                        </Box>
                    )
                },
            ]
        )
    }

    const theme = createTheme({
        palette: {
            primary: { main: '#21446C' },
        },
    }, ptBR, corePtBR);

    const CustomToolbar = () => {
        return (
            <GridToolbarContainer >
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
            </GridToolbarContainer>
        )
    }

    return (
        <div>
            {
                <Dialog keepMounted
                    open={props.open}
                    onClose={props.handleClose}
                    aria-labelledby="customized-dialog-title"
                    fullWidth
                    disablePortal
                    disableEnforceFocus
                    sx={{position:'absolute'}}
                    maxWidth="md"
                >
                    <DialogTitle id="customized-dialog-title" className={'group-flexbox'}>
                        <span>{props.title}: {props.data.name}</span>
                        <Tooltip title="Fechar" >
                            <Button className="action-button"  >
                                <CloseIcon onClick={props.handleClose} />
                            </Button>
                        </Tooltip>
                    </DialogTitle>
                    <Divider />
                    <div className='tableWrapper'>
                        <Box sx={{ width: '100%', height: '520px' }}>
                            <ThemeProvider theme={theme}>
                                <DataGridPro
                                    columns={createHistoryColumns()}
                                    rows={historyData}
                                    loading={loading}
                                    disableSelectionOnClick
                                    components={{ Toolbar: CustomToolbar }}
                                    density={'compact'}
                                />
                            </ThemeProvider>
                        </Box>
                    </div>
                </Dialog>
            }
        </div>

    );
}

export default CampaignHistory
