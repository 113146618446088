import React, { useState, useEffect, useRef, useContext } from 'react'

import { Box } from '@mui/material'
import api from '../../../api'
import Vivremenu from './Vivremenu'

import Loading from '../../Loading'

import useNotification from '../../Notification';
import { constructorContext } from '../Builder'



const Vivre = () => {

    const {has_voice, activeTab} = useContext(constructorContext)

    const [data, setData] = useState('')

    const createVIVRE = async () => {
        const res = await api.get('/api/getVIVRE/')

        if (res.status == 200){
            return (res.data)
        }
        else if (res.status == 401){
            useNotification(
                'Ops!',
                'Você não tem permissão para acessar essa página',
                'danger'
            );
        }
    }

    useEffect( async () => {
        const data = await createVIVRE()

        if(data) setData(data)

    }, [])

    return (
    <>
    <Loading loading={data == ''} />

    <Box sx={{overflow:"hidden", position:'fixed', left:'-999rem'}}>
        <object id="vivre" data={data} type="text/html"  />
    </Box>

    {data && <Vivremenu data={data} setData={setData} has_voice={has_voice} createVIVRE={createVIVRE} activeTab={activeTab}/>}
    </>
    )
}

export default Vivre