import React from 'react';
import { Box, Tooltip } from '@mui/material';
import "./index.css";

class TooltipClass extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <Tooltip title={this.props.queue} placement="bottom" disableInteractive>
                    <Box 
                        className="queue-legend" 
                        style={{ backgroundColor: this.props.color }}
                    />
                </Tooltip>
            </>
        );
    }
}

export default TooltipClass;
