import React, { useReducer } from "react";
import {
  Grid,
  TextField,
  Typography,
  Box,
  Button,
  Tooltip,
  IconButton,
  ListItem,
  List,
} from "@mui/material";
import { CircularProgress } from "@mui/material";
import Alert from "@mui/material/Alert";
import makeStyles from "@mui/styles/makeStyles";
import { useHistory } from "react-router-dom";
import QRCode from "react-qr-code";
import { lockedReducer } from '../../../utils/defaultReducer.js'
import ScoutSocketQRComponent from "../Pages/SocketQRComponent/index.js";
import './styles.css'

const ScanQRForm = () => {
  const history = useHistory();

  const [state, setState] = useReducer(lockedReducer, {
    showAlert: [],
    loading: false,
    qrCode: null,
    socket: null,
  });

  const urlParams = window.location.pathname.split("/");
  const enterpriseId = urlParams[urlParams.length - 6];
  const accountId = urlParams[urlParams.length - 5];
  const uid = urlParams[urlParams.length - 4];
  const accountcode = urlParams[urlParams.length - 3];
  const phone_number = urlParams[urlParams.length - 2];
  const token = urlParams[urlParams.length - 1];

  const useStyles = makeStyles(() => ({
    title: {
      color: "#21446C",
      fontWeight: 500,
      paddingLeft: "10px",
    },
  }));

  const checkConnection = (enterpriseId, accountId) => {
    const pollingId = setInterval(async () => {
      fetch(
        `/api/api/enterprises/${enterpriseId}/scout_accounts/${accountId}/checkconnection/`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      )
        .then(async (res) => {
          if (res.status == 200) {
            await res.json().then(async (data) => {
              if (data.state == "open") {

                clearInterval(pollingId);
                setState({ showAlert: [{
                  severity: 'success',
                  message: "Configuração finalizada com sucesso",
                }]})
              } else if (data.state == "wrongNumber") {
                setState({ 
                  showAlert: [{
                    severity: 'error',
                    message: "Numero errado, utilize o celular cadastrado na conta para ler o QR.",
                  }],
                  qrCode: null,
                  loading: false,
                })
              } else {
                console.log("Still waiting ...", data.state)
              }
            });
          }
        })
        .catch(async (err) => {
          console.error(err);
        });
    }, 5 * 1000);
  };

  const handleBtnClick = async () => {
    setState({ loading: true });
    fetch(
      `/api/api/enterprises/${enterpriseId}/scout_accounts/${accountId}/getqr/`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          uid: uid,
          token: token,
        }),
      }
    )
      .then(async (res) => {
        if (res.status == 200) {
          await res.json().then(async (data) => {
            setState({ qrCode: data.code});
            checkConnection(enterpriseId, accountId);
          });
        } else {
          setState({
            loading: false,
            showAlert: [{
              severity: 'error',
              message: "Convite expirado! Entre em contato e solicite um novo.",
            }]
          })
        }
      })
  };

  const classes = useStyles();

  return (
    <Box
      display="flex"
      height="99vh"
      sx={{
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        width: "100%"
      }}

    >
      <ScoutSocketQRComponent account={{
        id: accountId,
        phone_number: phone_number,
        accountcode: accountcode,
      }} parent={state} setParent={setState}/>
      <Box sx={{
        display: 'flex',
        width: '50%',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        <Grid container spacing={1} sx={{ justifyContent: 'center' }}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box display="flex">
              <Typography className={classes.title} variant="h4">
                Conecte-se no Kwik Scout
              </Typography>
            </Box>
            <Alert>
                <Typography className={classes.title} variant="h6">
                  Siga o passo-a-passo abaixo para conectar seu WhatsApp ao Kwik Scout
                  As opções vão depender de sua versão de telefone e do aplicativo
                </Typography>
                <List className='scout-informative-list'>
                  <ListItem>No seu telefone, entre no aplicativo WhatsApp</ListItem>
                  <ListItem>No canto inferior direito, selecione 'Configurações'</ListItem>
                  <ListItem>Selecione a opção 'dispositivos conectados'</ListItem>
                  <ListItem>Selecione a opção 'conectar dispositivo'</ListItem>
                  <ListItem>Clique no botão abaixo para gerar o QRCODE necessário</ListItem>
                  <ListItem>Escaneie com o celular o QRCODE gerado</ListItem>
                  <ListItem>Aguarde o término da sincronização entre o WhatsApp e o Kwik Scout</ListItem>
                </List>
              </Alert>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            {state.showAlert.length !== 0 && state.showAlert.map((alert) => (
              <Alert severity={alert.severity} onClose={() => setState({showAlert:[]})}>
                <Box sx={{ justifyContent: 'center' }}>{alert.message}</Box>
              </Alert>
            ))}
          </Grid>
          {!state.qrCode && (
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Button
                disabled={state.loading}
                variant="contained"
                color="primary"
                type="button"
                onClick={() => handleBtnClick()}
                fontSize="small"
                fullWidth
              >
                {state.loading && (
                  <CircularProgress
                    style={{
                      height: "20px",
                      width: "20px",
                      marginRight: "10px",
                    }}
                  />
                )}
                Gerar código QR
              </Button>
            </Grid>
          )}
          {state.qrCode && <QRCode value={state.qrCode} />}
        </Grid>
      </Box>
    </Box>
  );
};

export default ScanQRForm;
