import React from "react";
import MiniDrawer from "../../components/Drawer"
import ManageCallsGrid from "../../components/ManageCalls"

const ManageCalls = (socket) => {
    return (
        <MiniDrawer child={<ManageCallsGrid />} socket={socket.socket}/>
    );
};

export default ManageCalls;
