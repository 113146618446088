import React from 'react';

import './styles.css';

import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import { List, ListItem, ListItemText, DialogActions } from '@mui/material';
import { Box, Button, Grid, TextField } from '@mui/material';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import Loading from '../../Loading';
import api from '../../../api';
import { getSession } from "../../../auth";
import useNotification from '../../Notification';
import useValidationError from '../../ErrorValidation';


class Templates extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedTemplate : null,
            showParameters   : false,
            parameters       : [],
            queues           : [],
            queue            : null,
            originNumber     : null,
            loading          : false
        }
    }

    onChangeQueues = (event) => {
        this.setState({ queue: event.target.value });
    }

    onChangeOriginNumbers = (event) => {
        this.setState({ originNumber: event.target.value });
    }

    submit = () => {
        const requiredParameters = this.getCountTemplateParameters();

        if (requiredParameters != this.state.parameters.length) {
            this.setState({
                showParameters: true,
                parameters: Array(requiredParameters).fill({value: ''})
            });
        } else {
            this.sendNotification();
        }
    }

    getCountTemplateParameters = () => {
        return (this.state.selectedTemplate.components[0].text.match(/{{/g)||[]).length;
    }

    postNotification = (body) => {
        this.setState({ loading: true });

        api.post(`api/whatsapp/notification/`, body)
        .then(async (res) => {
            await this.setState({ loading: false });
            if (res.status === 201) {
                await this.props.handleClose();
                useNotification(
                    'Sucesso!', 
                    'Notificação enviada aos clientes selecionados.', 
                    'success'
                );
                await this.props.handlePostSuccess();
                return;
            } 
            throw res;
        })
        .catch((err) => {
            useValidationError(err.status, null);
            console.error(err);
        });
    }

    sendNotification = () => {
        const clients = this.props.clients;
        const selectionIds = this.props.selectionIds;

        const recipients = selectionIds.map((id) => { 
            return clients[id].telephone; 
        })

        const user = getSession()

        const body = {
            'from'       : this.state.originNumber,
            'to'         : recipients,
            'queue_name' : this.state.queue.split('_').pop(),
            'template'   : this.state.selectedTemplate.name,
            'parameters' : this.state.parameters.map(p => p.value),
            'agent'      : user.profile.agent.user.split('_').pop()
        };
        this.postNotification(body);
    }

    handleInputChange = (e, i) => {
        const { value } = e.target;
        const parameters = this.state.parameters;
        
        parameters[i] = {};
        parameters[i]['value'] = value;
        
        this.setState({
            parameters: parameters
        });
    }

    allParametersFilled = () => {
        if (this.state.showParameters) {
            let parametersFilled = true;
            this.state.parameters.forEach((parameter) => {
                if (parameter.value == '')
                    parametersFilled = false;
            });
            return parametersFilled;
        }
        return true;
    }

    getQueues = () => {
        let queues    = this.props.queues;
        let newQueues = [];
        if (Object.keys(queues) && Object.keys(queues).length) {
            Object.keys(queues).map(key => {
                newQueues.push(key);
            });
        }
        this.state.queues = newQueues;
    }

    componentWillMount() {
        this.getQueues();
    }

    render() {
        return (
            <div>
                <Loading loading={this.state.loading} />
                <Dialog keepMounted
                    open={this.props.open}
                    onClose={this.props.handleClose}
                    aria-labelledby="customized-dialog-title"
                    disablePortal
                    disableEnforceFocus
                    sx={{position:'absolute'}}
                >
                    <DialogTitle id="customized-dialog-title">
                        Enviar notificação aos clientes selecionados
                    </DialogTitle>
                    <DialogContent dividers
                        ref={instance => {this.content = instance;}}
                    >
                        {!this.state.showParameters &&
                            <>
                                <Grid container spacing={3}
                                    className="selections-template"
                                >
                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                        <FormControl
                                            fullWidth 
                                            size="small"
                                        >
                                            <InputLabel id="demo-simple-select-label">Fila</InputLabel>
                                            <Select
                                              labelId="demo-simple-select-label"
                                              id="demo-simple-select"
                                              label="Fila"
                                              onChange={this.onChangeQueues}
                                            >
                                              {this.state.queues.map((queue, index) => (
                                                <MenuItem key={index} value={this.props.queues[queue]}>{queue}</MenuItem>
                                              ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                        <FormControl
                                            fullWidth 
                                            size="small"
                                        >
                                            <InputLabel id="demo-simple-select-label">Número de origem</InputLabel>
                                            <Select
                                              labelId="demo-simple-select-label"
                                              id="demo-simple-select"
                                              label="Número de origem"
                                              onChange={this.onChangeOriginNumbers}
                                            >
                                              {this.props.originNumbers.map((number, index) => (
                                                <MenuItem key={index} value={number}>{number}</MenuItem>
                                              ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <List>
                                    {this.props.templates.map((template, i) => (
                                        <ListItem className={this.state.selectedTemplate == template
                                                ? "template-item selected"
                                                : "template-item"}
                                            key={i}
                                        >
                                            <ListItemText primary={template.name}
                                                secondary={template.components[0].text}
                                                onClick={() => this.setState({selectedTemplate: template})}/>
                                        </ListItem>
                                    ))}
                                    {this.props.templates.length === 0 &&
                                        <Box>Não há templates pré-aprovados para reativação.</Box>
                                    }
                                </List>
                            </>
                        }
                        {this.state.showParameters && 
                            <Box>
                                {this.state.selectedTemplate.components[0].text}
                                <h6 className="push-top">
                                    Preencha as variáveis do template abaixo:
                                </h6>
                                {this.state.parameters.map((parameter, i) => {
                                    const variableIndex = i+1;
                                    return (
                                        <TextField
                                            size="small"
                                            label={"{{"+variableIndex+"}}"}
                                            fullWidth
                                            type="text"
                                            variant="outlined"
                                            value={parameter.value}
                                            onChange={e => this.handleInputChange(e, i)}
                                            className="input-parameter"
                                        />
                                    )   
                                })}
                            </Box>
                        }
                    </DialogContent>
                    <DialogActions style={{padding:"10px 20px 10px 10px"}}>
                        {this.state.showParameters &&
                            <Button
                                onClick={() => this.setState({
                                    selectedTemplate: null, 
                                    showParameters: false,
                                    parameters: []
                                })}
                                color="primary"
                            >
                                Voltar
                            </Button>
                        }
                        <LoadingButton
                            onClick={() => this.submit()}
                            color="primary"
                            disabled={
                                (!this.props.selectionIds.length) ||
                                (this.state.queue === null) ||
                                (this.state.originNumber === null) ||
                                (this.state.selectedTemplate === null) || 
                                (!this.allParametersFilled())
                            }
                            loading={this.state.loading}
                        >
                            Enviar
                        </LoadingButton>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default Templates;
