import React, { useReducer, useState } from 'react'
import { Box, Button } from '@mui/material'
import defaultReducer from '../utils/defaultReducer'
import Page1 from './page1'

import styles from './style.module.css'
import Page2 from './page2'
import Loading from '../components/Loading'

const pages = (parent, setParent, setLoading) => {
    const data = { parent: parent, setParent: setParent, setLoading: setLoading }
    return {
        0: (<Page1 {...data} />),
        1: (<Page2 {...data} />)
    }
}

const FBTrial = () => {
    const [selectedPage, setSelectedPage] = useState(0)
    const [state, setState] = useReducer(defaultReducer, {
        access_token: '',
        data: []
    })

    const [loading, setLoading] = useState(false)

    const page = pages(state, setState, setLoading)

    return (<>
    <Loading loading={loading}/>
        <Box className={styles.background}>
            <Box className={styles.mainframe}>
                {page[selectedPage]}
            </Box>
            <Box className={styles.buttons}>
                <Box>Page: {selectedPage + 1}</Box>
                {!(selectedPage <= 0) &&
                    <Button variant='contained' onClick={() => setSelectedPage(selectedPage - 1)}>Previous</Button>
                }
                {!(selectedPage >= Object.keys(page).length - 1) &&
                    <Button variant='contained' disabled={state.access_token === '' || state.data.length === 0} onClick={() => setSelectedPage(selectedPage + 1)}>Next</Button>
                }
            </Box>
        </Box>
    </>)
}

export default FBTrial