import React from 'react';

import Frame from '../Frame';

const FrameAllChats = ({ gridArea, allChats }) => {
  return (
    <Frame gridArea={gridArea} title="Chats (total)">
      {allChats}
    </Frame>
  );
}

export default FrameAllChats;