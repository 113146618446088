import { getSession } from "../../../auth";

const UserPermissions = () => {
  const user = getSession();

  const isAdmin = () => {
      if (user && user.groups) {
          return user.groups.some((group) => {
              return group.name == 'administrador';
          });
      }
  }

  const isSupervisor = () => {
      if (user && user.groups) {
          return isAdmin() || user.groups.some((group) => {
              return group.name == 'supervisor';
          });
      }
  }

  const isOperator = () => {
      if (user && user.groups) {
          return isAdmin() || isSupervisor() || user.groups.some((group) => {
              return group.name == 'operador';
          });
      }
  }

  const isNotOnlyOperator = () => {
      if (user && user.groups) {
          return user.groups.some((group) => {
              return group.name != 'operador' && group.name != 'usuário';
          });
      }
  }

  return { 
    isAdmin             : isAdmin(),
    isSupervisor        : isSupervisor(),
    isOperator          : isOperator(),
    isNotOnlyOperator   : isNotOnlyOperator()
  };
}

export default UserPermissions;