import React, { useEffect } from 'react'
import { getSession } from '../../../../auth'

let socketregistry = 0
const socketinterval = 20000

export const treatEvents = (data, parent, setParent, account) => {

    switch (data.event){
        case 'PONG':
        case 'chats.update':
        case 'messages.upsert':
        case 'contacts.update':
        case 'connection.update':
        break
        case 'qrcode.updated':
            setParent({qrCode: data.message.qrcode.code, loading: false});
        break

        default:
            console.error(`Unknown event '${data.event}' received from scout.`)
    }
        
}

const connectSocket = (socket, setParent, account) => {
    if (socket) socket.close()
    const accountcode = account.accountcode ? account.accountcode : getSession().profile.enterprise.accountcode ;
    const newsocket = new WebSocket(`wss://${location.host}/chat/ws/scout/${accountcode}_${account.phone_number}/${accountcode}/`)

    newsocket.onopen = () => {
        clearInterval(socketregistry)
        socketregistry = setInterval(()=>{
            newsocket.send(JSON.stringify({
                event: 'PING',
                message: ''
            }));
        }, socketinterval)
    }

    newsocket.onclose = () => {
        clearInterval(socketregistry)
        setParent({socket: null})
    }
    
    setParent({socket: newsocket})
}

const ScoutQRSocketComponent = ({account, parent, setParent}) => {

    if (parent.socket) parent.socket.onmessage = (e) => {treatEvents(JSON.parse(e.data), parent, setParent, account)}

    useEffect(()=>{
        if (!parent.socket) connectSocket(parent.socket, setParent, account)
        return () => {if (parent.socket) parent.socket.close()}
    }, [parent.socket])

    return null
}

export default ScoutQRSocketComponent