import React, { useState } from "react";
import Routes from "./routes"

import { getSession } from "./auth";

function App() {

  // const [socket, setSocket] = useState(null);

  // useState(() => {
  //   let user = getSession();
  //   if (user && !socket){
  //     let hs = window.location.hostname;
  //     let ws = new WebSocket(`wss://${hs}/chat/ws/agent/${user.profile.enterprise.id}/${user.profile.agent_id}/`);
  //     ws.onopen = (e) => {
  //       setInterval(() => {
  //         ws.send(JSON.stringify({
  //           message : 'PING'
  //         }));
  //       }, 20000);
  //     }
  //     setSocket(ws);
  //   }
  // });

  // return (
  //   <Routes socket = {socket} />
  // );

  return (
    <Routes />
  );
}
export default App;
