import React from "react";
import { Grid, TextField, Typography, FormControl, InputLabel, Select } from "@mui/material";
import PropTypes from 'prop-types';
import { useStyles } from "./styles";

const EnterpriseForm = ({ values, handleChange }) => {
    const classes = useStyles();

    return (
        <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography className={classes.title} variant="h4">EMPRESA</Typography>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                    id="name"
                    label="Nome fantasia"
                    variant="outlined"
                    name="enterprise.name"
                    value={values.enterprise.name}
                    onChange={handleChange}
                    fullWidth
                    required
                    size="small"
                />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                    id="activity"
                    label="Ramo de atividade"
                    variant="outlined"
                    name="enterprise.field"
                    value={values.enterprise.field}
                    onChange={handleChange}
                    fullWidth
                    size="small"
                />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                    id="url"
                    label="Site"
                    variant="outlined"
                    name="enterprise.site"
                    value={values.enterprise.site}
                    onChange={handleChange}
                    fullWidth
                    size="small"
                />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <FormControl style={{ width: "100%" }} size="small"
                    variant="outlined" fullwidth>
                    <InputLabel htmlFor="outlined-age-native-simple">Interesse</InputLabel>
                    <Select
                        native
                        fullwidth
                        name="enterprise.interests"
                        label="Interesse"
                        id="interests"
                        onChange={handleChange}
                        value={values.enterprise.interests}
                    >
                        <option value={"1 WhatsApp com vários atendentes"}>1 WhatsApp com vários atendentes</option>
                        <option value={"Preciso monitorar o atendimento em tempo real"}>Preciso monitorar o atendimento em tempo real</option>
                        <option value={"Preciso de mais relatórios sobre os atendimentos"}>Preciso de mais relatórios sobre os atendimentos</option>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} >
                <FormControl style={{ width: "100%" }} variant="outlined" fullwidth size="small"
                >
                    <InputLabel >Quantidade de Agentes</InputLabel>
                    <Select native width={"100vw"}
                        value={values.enterprise.expected_agents}
                        name="enterprise.expected_agents"
                        label="Quantidade de Agentes"
                        id="expected_agents"
                        onChange={handleChange}
                    >
                        <option value={1}>1</option>
                        <option value={2}>2</option>
                        <option value={3}>3</option>
                        <option value={4}>4</option>
                        <option value={5}>5</option>
                    </Select>
                </FormControl>
            </Grid>
        </Grid >

    );
};

EnterpriseForm.propTypes = {
    values: PropTypes.object,
    handleChange: PropTypes.func,
}

export default EnterpriseForm;
