import React from 'react'

import { Box } from '@mui/material'
import './styles.css'
import GraphToolbar from '../default-toolbar'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import { graph_theme, defaultClassification } from '../default-toolbar'

import SankeyChart from "../../../../D3/SankeyChart";

const columns = graph => {
  const columns = []
  columns.push({
    field: 'classification',
    headerName: 'Classificação',
    flex: .5
  })
  columns.push({
    field: 'description',
    headerName: 'Descrição',
    flex: 1,
    valueGetter: defaultClassification
  })
  columns.push({
    field: 'count',
    headerName: 'Quantidade',
    flex: .5
  })
  return columns
}

const ChatsQualification = ({ data, option, periodText }) => {
  return (
    <Box className='graph-main-divisor'>
      <Box className='graph-main-table'>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={graph_theme}>
            <DataGridPro
              density='compact'
              columns={columns(option)}
              rows={data?.rows.map((r, index) => ({ ...r, id: index + 1 }))}
              hideFooter={true}
              components={{
                Toolbar: GraphToolbar
              }}
            />
          </ThemeProvider>
        </StyledEngineProvider>
      </Box>
      <Box className='graph-main-dashboards'>
        <Box className='graph-time-rows'>
          <SankeyChart data={data} periodText={periodText}/>
        </Box>
      </Box>
    </Box>
  )
}

export default ChatsQualification
