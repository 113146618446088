import React, { useState, useEffect } from 'react'

import { Box, FormGroup, FormControlLabel, Switch } from "@mui/material"

import LoadingButton from '@mui/lab/LoadingButton'
import SaveIcon from '@mui/icons-material/Save'

import Loading from '../../Loading'
import useNotification from '../../Notification'

import { getSession, saveSession } from "../../../auth"

import api from '../../../api'

import './index.css'

const General = ({ enterpriseId }) => {

    const [loading, setLoading] = useState(false)
    const [saveNickname, setSaveNickname] = useState(false)
    const [sendNickname, setSendNickname] = useState(false)

	const updateSettings = async () => {
		setLoading(true)
		const res = await api.patch(`/api/enterprises/${enterpriseId}/`, {
			'settings': {
				'save_nickname': saveNickname,
				'send_nickname': sendNickname
			}
		})
		setLoading(false)

		if (res.status !== 200) {
			useNotification(
				'Ops!',
				'Algo deu errado, tente novamente.',
				'danger'
			);	
			return
		}

		const session = getSession()
		session.profile.enterprise.settings.save_nickname = saveNickname
		session.profile.enterprise.settings.send_nickname = sendNickname
		saveSession(session)

		useNotification(
			'Sucesso!',
			'As configurações foram salvas com sucesso.',
			'success'
		);
	}

	useEffect(() => {
		setSaveNickname(getSession().profile.enterprise.settings.save_nickname)
		setSendNickname(getSession().profile.enterprise.settings.send_nickname)
	}, [])

    return (
        <Box className="input-settings" sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            <Loading loading={loading} />
            <Box className="category-title">
   				Nome do operador
   			</Box>
            <FormGroup sx={{ marginLeft: "1rem", marginBottom: "-1rem" }}>
				<FormControlLabel control={
					<Switch
						color="primary"
						checked={saveNickname}
						onChange={() => setSaveNickname(! saveNickname)}
                    />
				} 
				label="Registrar nas conversas" />
			</FormGroup>
            <FormGroup sx={{ marginLeft: "1rem" }}>
				<FormControlLabel control={
					<Switch
						color="primary"
						checked={sendNickname}
						onChange={() => setSendNickname(! sendNickname)}
                    />
				} 
				label="Enviar nas conversas" />
			</FormGroup>
            <LoadingButton
                style={{ width: 'fit-content' }}
                onClick={updateSettings}
                loading={loading}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="contained"
            >
                Salvar
            </LoadingButton>
        </Box>
    )
}

export default General
