import React, { useState } from 'react'

import {
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Divider,
    Box
} from '@mui/material'

import LoadingButton from '@mui/lab/LoadingButton'

import CloseIcon from '@mui/icons-material/Close'

import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input'
import Locale from 'react-phone-number-input/locale/pt-BR.json'

export default function AddScoutForm({ open, handleClose, submit }) {

    const [name, setName] = useState();
    const [number, setNumber] = useState();
    const [email, setEmail] = useState();

    return (
        <div>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}
                disablePortal
                disableEnforceFocus
                sx={{ position: 'absolute' }}
            >
                <DialogTitle className="group-flexbox">
                    Adicionar Conta Scout
                    <Button sx={{ 'minWidth': '2rem', 'padding': '0' }} onClick={handleClose}>
                        <CloseIcon />
                    </Button>
                </DialogTitle>
                <Divider />
                <DialogContent className="add-bot-dialog-content">
                    <TextField
                        size="small"
                        label="Nome"
                        fullWidth
                        type="text"
                        variant="outlined"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <PhoneInput
                        defaultCountry='BR'
                        locale={Locale.BR}
                        placeholder="Número de telefone (apenas números)"
                        limitMaxLength
                        value={number}
                        onChange={(e) => { setNumber(e) }}
                    />
                    <TextField
                        size="small"
                        label="Email"
                        fullWidth
                        type="email"
                        variant="outlined"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <Box className="user-footer">
                        <LoadingButton className="button-created" onClick={handleClose}>
                            Cancelar
                        </LoadingButton>
                        <LoadingButton className="button-created"
                            onClick={() => submit(name, number.replace('+', ''), email)}
                            disabled={!name || !number || !email}
                        >
                            Salvar
                        </LoadingButton>
                    </Box>
                </DialogContent>
            </Dialog>
        </div>
    );
}
