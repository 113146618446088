import React from 'react';
import { Box } from "@mui/material";
import { ResponsiveContainer, BarChart, CartesianGrid } from 'recharts';
import { XAxis, YAxis, Tooltip, Legend, Bar } from 'recharts';
import "./index.css";
import QueuesLegend from '../../Dash/QueueLegend';


class StackedBarChart extends React.Component {

    constructor() {
        super();

        this.colors  = [];
        this.columns = [];
    }

    setupColumns = () => {
        this.props.data.forEach((row) => {
            Object.keys(row).forEach((key) => {
                if (key != 'name' && !this.columns.includes(key))
                    this.columns.push(key);
            });
        });
    }

    setupColors = () => {
        this.colors = ['#5F8963', '#d5ba7d', '#C95F50', '#6A5ACD', '#4169E1', '#CD853F', '#40E0D0',
            '#BA55D3', '#90EE90', '#A52A2A', '#9370DB', '#6B8E23', '#DAA520', '#DB7093', '#BA55D3'];

        const maxColors = this.columns.length;

        if(maxColors > 15) {
            let remainingColors = maxColors - 15;

            while(remainingColors) {
                // Add random colors
                this.colors.push('#' + Math.floor(Math.random() * 0x1000000).toString(16).padStart(6, '0'));

                remainingColors -= 1;
            }
        }
    }

    showCustomCaption = () => {
        return (
            <Box className="queues-legend">
                {this.columns.map((queue, index) => (
                    <QueuesLegend color={this.colors[index]} queue={queue} />
                ))}
            </Box>
        );
    }

    componentWillMount() {
        this.setupColumns();
        this.setupColors();
    }

    render() {
        return (
            <Box className="stacked-bar-chart">
                <ResponsiveContainer 
                    width="90%"
                    height={
                        this.columns.length > 2
                        ? '90%'
                        : '100%'
                    }
                >
                    <BarChart 
                        data={this.props.data} 
                        className={
                            this.columns.length > 2
                              ? 'bar-chart-report'
                              : null
                        }
                    >
                        <XAxis dataKey="name"/>
                        <YAxis/>
                        <Tooltip/>
                        <Legend/>
                        {this.columns.map((col, i) => (
                            <Bar 
                                dataKey={col} 
                                stackId="a" 
                                fill={this.colors[i]}
                            />
                        ))}
                    </BarChart>
                </ResponsiveContainer>
                {this.columns.length > 2 && this.showCustomCaption() }
            </Box>
        )
    }
}

export default StackedBarChart;
