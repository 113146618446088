import { Store } from 'react-notifications-component';

const Notification = (title, message, type, seconds) => {
  if(title && message && type) {
    Store.addNotification({
      title: title,
      message: message,
      type: type, // 'default', 'success', 'info', 'warning', 'danger'
      insert: "bottom",
      container: "bottom-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: seconds ? seconds : 7000,
        onScreen: true,
        pauseOnHover: true,
        showIcon: true
      }
    });
  }
}

export default Notification;