import React from "react";

import { Box, Button, TextField, Grid } from "@mui/material";

import DeleteIcon from '@mui/icons-material/Delete';

import Loading from '../../Loading';
import VoiceDialog from "../../Dialogs/Voice";
import useNotification from "../../Notification";

import {
	DataGridPro,
	GridToolbarContainer,
	GridToolbarColumnsButton,
	GridToolbarFilterButton,
	gridClasses,
	LicenseInfo,
	ptBR
} from '@mui/x-data-grid-pro';

import { ptBR as corePtBR } from '@mui/material/locale';

import {
	createTheme,
	ThemeProvider,
	StyledEngineProvider
} from '@mui/material/styles';

import LoadingButton from '@mui/lab/LoadingButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SaveIcon from '@mui/icons-material/Save';

import api from '../../../api';

import { stringOperators } from "../../../utils/filterOperators";

import "./index.css";

const theme = createTheme({
	palette: {
		primary: { main: '#21446C' },
	},
}, ptBR, corePtBR);


class VoiceTable extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			sipfriends: [],
			showAddSipfriends: false,
			voiceInitialMsg: null,
			voiceOutTimeMsg: null,
			voiceEndMsg: null,
			voiceExtenMsg: null
		};
	}

	getConfig = () => {
		api.get(`/api/enterprises/${this.props.enterpriseId}/config/`)
			.then((res) => {
				if (res.status == 200) {
					let voiceInitialMsg = null;
					let voiceOutTimeMsg = null;
					let voiceEndMsg = null;
					let voiceExtenMsg = null;

					res.data.forEach((row) => {
						if (row.var_name == 'voice_initial_msg')
							voiceInitialMsg = row;
						if (row.var_name == 'voice_out_time_msg')
							voiceOutTimeMsg = row;
						if (row.var_name == 'voice_end_msg')
							voiceEndMsg = row;
						if (row.var_name == 'voice_exten_msg')
							voiceExtenMsg = row;
					});

					this.setState({
						voiceInitialMsg: voiceInitialMsg,
						voiceOutTimeMsg: voiceOutTimeMsg,
						voiceEndMsg: voiceEndMsg,
						voiceExtenMsg: voiceExtenMsg,
						loading: false
					});

					return;
				}
				throw res;
			})
			.catch((err) => {
				console.error(err);
			});
	}

	getSipfriends = () => {
		api.get(`/api/enterprises/${this.props.enterpriseId}/sipfriends/`)
			.then((res) => {
				if (res.status == 200) {
					this.setState({ sipfriends: res.data });
					return;
				}
				throw res;
			})
			.catch((err) => {
				console.error(err);
			});
	}

	addSipFriends = (number, username, password, url, callerid) => {
		this.setState({ loading: true });
		api.post(`/api/enterprises/${this.props.enterpriseId}/sipfriends/`, {
			name: number,
			username: username,
			password: password,
			url: url,
			callerid: callerid
		}).then((res) => {
			if (res.status == 201) {
				this.getSipfriends();
				this.setState({
					showAddSipfriends: false,
					loading: false,
				});
				return;
			}
			throw res;
		}).catch((err) => {
			console.error(err);
			this.setState({ loading: false });
		})
	}

	delSipFriends = (row) => {
		this.setState({ loading: true });
		api.delete(`/api/enterprises/${this.props.enterpriseId}/sipfriends/${row.id}/`)
			.then(async (res) => {
				if (res.status == 204) {
					this.getSipfriends();
					this.setState({ loading: false });
					return;
				}
				throw res;
			}).catch(async (err) => {
				console.error(err);
				this.setState({ loading: false });
			});
	}

	componentWillMount() {
		this.setState({ loading: true });
		this.getConfig();
		this.getSipfriends();
	}

	onChangeVoiceInitialMsg = (event) => {
		const voiceInitialMsg = this.state.voiceInitialMsg;
		voiceInitialMsg.var_val = event.target.value;
		this.setState({ voiceInitialMsg: voiceInitialMsg });
	}

	onChangeVoiceOutTimeMsg = (event) => {
		const voiceOutTimeMsg = this.state.voiceOutTimeMsg;
		voiceOutTimeMsg.var_val = event.target.value;
		this.setState({ voiceOutTimeMsg: voiceOutTimeMsg });
	}

	onChangeVoiceEndMsg = (event) => {
		const voiceEndMsg = this.state.voiceEndMsg;
		voiceEndMsg.var_val = event.target.value;
		this.setState({ voiceEndMsg: voiceEndMsg });
	}

	onChangeVoiceExtenMsg = (event) => {
		const voiceExtenMsg = this.state.voiceExtenMsg;
		voiceExtenMsg.var_val = event.target.value;
		this.setState({ voiceExtenMsg: voiceExtenMsg });
	}

	updateConfig = () => {
		this.setState({ loading: true });

		const toUpdate = [
			this.state.voiceInitialMsg,
			this.state.voiceOutTimeMsg,
			this.state.voiceEndMsg,
			this.state.voiceExtenMsg
		];

		toUpdate.forEach(async (data) => {
			const url = `/api/enterprises/${this.props.enterpriseId}/config/${data.id}/`;
			await api.patch(url, {
				var_val: data.var_val
			}).then((res) => {
				if (res.status != 200)
					throw res;
			}).catch((err) => {
				console.error(err);
			});
		});

		useNotification(
			'Sucesso!',
			'As mensagens foram atualizadas com sucesso.',
			'success'
		);
		this.setState({ loading: false });
	}

	createColumns() {
		return [
			{ field: 'name', headerName: 'Número', flex: 1, filterOperators: stringOperators },
			{ field: 'callerid', headerName: 'Máscara', flex: 1, filterOperators: stringOperators },
			{ field: 'username', headerName: 'Usuário', flex: 1, filterOperators: stringOperators },
			{ field: 'host', headerName: 'URL', flex: 1, filterOperators: stringOperators },
			{
				field: 'action', headerName: 'Ações', flex: 0.25, filterable: false, sortable: false,
				renderCell: (params) => (
					<Box>
						<Button className="action-button"
							onClick={() => this.delSipFriends(params)}>
							<DeleteIcon />
						</Button>
					</Box>
				)
			}
		]
	}

	CustomToolbar = () => {

		return (
			<GridToolbarContainer className={gridClasses.toolbarContainer}>
				<GridToolbarColumnsButton />
				<GridToolbarFilterButton />
				<LoadingButton
					size="small"
					component="label"
					startIcon={<AddCircleIcon />}
					onClick={() => {
						this.setState({
							showAddSipfriends: true,
						});
					}}
				>
					Adicionar
				</LoadingButton>
			</GridToolbarContainer>
		);
	}

	render() {
		return (
			<>
				<Loading loading={this.state.loading} />
				{!this.state.loading &&
					<>
						<VoiceDialog open={this.state.showAddSipfriends}
							handleClose={() => this.setState({ showAddSipfriends: false })}
							handleSubmit={this.addSipFriends}
						/>
						<Box sx={{height:'100%'}}>
							<Box sx={{ flex: 1, display:'flex', flexDirection:'column', gap:'1rem', height:'100%' }}>
								<Box sx={{height:'100%'}}>
									<Box className='BuilderContent' key={this.state.renderKey}>
										<StyledEngineProvider injectFirst>
											<ThemeProvider theme={theme}>
												<DataGridPro
													pagination
													disableSelectionOnClick
													disableDensitySelector
													density="compact"
													columns={this.createColumns()}
													rows={this.state.sipfriends}
													components={{
														Toolbar: this.CustomToolbar,
													}}
												/>
											</ThemeProvider>
										</StyledEngineProvider>
									</Box>
								</Box>
							</Box>
						</Box>
					</>
				}
			</>
		);
	}
}

export default VoiceTable;
