import React from 'react'

import OptionsMenu from '../../../../utils/OptionsMenu'
import ShippingReceiving from '../../ShippingReceiving';
import RestrictedWordsComponent from '../../RestrictedWords'

import { getSession } from '../../../../auth';
import userPermissions from '../../../User/Permissions';
import UsersQueue from '../../Users/UsersQueue';

const getOptions = () => {
    const enterprise = getSession()?.profile?.enterprise
    const groups = userPermissions();

    return {
        usersqueue: {
            permissions: groups.isAdmin,
            label: "Fila / Operador",
            component: (<UsersQueue enterpriseId={enterprise?.id}/>)
        },
        sendreceiving: {
            permissions: groups.isAdmin,
            label: "Envio / Recebimento",
            component: (<ShippingReceiving  enterpriseId={enterprise?.id}/>)
        },
        restrictedwords: {
            permissions: groups.isAdmin,
            label: "Palavras restritas",
            component: (<RestrictedWordsComponent enterpriseId={enterprise?.id}/>)
        }
    }
}

const QueueMenu = () => {
    return (
        <OptionsMenu name='queues-settings' options={getOptions} />
    )
}

export default QueueMenu