import React, { useReducer, useEffect } from 'react'
import { lockedReducer } from '../../utils/defaultReducer'
import NewDash from './NewDash'
import { Box, Button, Tooltip } from '@mui/material'
import HistoricalDash from './HistoricDash'
import { getSession } from '../../auth'
import api from '../../api'
import { alphabeticalSort } from '../../utils/alphabeticalSort'

const getMessage = (permission) => {
    if (permission)
        return ''
    return 'O módulo de histórico de relatórios está desabilitado, procure a equipe do kwik para habilitá-lo.'
}

const getQueues = async (enterpriseId, userQueuesId, setState) => {
    const res = await api.get(`/api/enterprises/${enterpriseId}/dashboard/queues/`)
    if (res.status === 200) {
        const allQueues = res.data
        allQueues.map(queue => queue.checked = userQueuesId.includes(queue.id))
        setState({ queues: allQueues.sort((x, y) => alphabeticalSort(x, y, 'description')) })
    }

    else {
        useNotification(
            'Ops!',
            'Não foi possível localizar as filas',
            'danger'
        )
    }
}

const KwikDashboard = () => {

    const user = getSession()
    const userQueuesId = user?.profile.queues.map(queue => queue.id)

    const [state, setState] = useReducer(lockedReducer, {
        tab: 'default',
        menus: null,
        queues: []
    })

    const tooltipMessage = getMessage(user?.profile?.enterprise?.historic_reports)

    useEffect(() => {
        getQueues(user?.profile?.enterprise?.id, userQueuesId, setState)
    }, [])

    return (
        <Box className='dashboard-tab-selector'>
            <Box className='dashboard-action-menu' >
                <Box className='BuilderBotSelectorBox' sx={{ width: '15rem' }}>
                    <Button
                        variant={state.tab === 'default' ? 'contained' : ''}
                        onClick={() => setState({ tab: 'default' })}
                    >
                        Instantâneo
                    </Button>
                    <Tooltip title={tooltipMessage}>
                        <span>
                            <Button
                                disabled={tooltipMessage}
                                variant={state.tab === 'historic' ? 'contained' : ''}
                                onClick={() => setState({ tab: 'historic' })}
                            >
                                Histórico
                            </Button>
                        </span>
                    </Tooltip>
                </Box>
                <Box sx={{ flex: 1 }}>{state.menus}</Box>
            </Box>
            <Box className='dashboard-mainframe'>
                {state.tab === 'default' && <NewDash innerRef={(menus) => setState({ menus: menus })} queues={state.queues}/>}
                {state.tab === 'historic' && <HistoricalDash innerRef={(menus) => setState({ menus: menus })} queues={state.queues}/>}
            </Box>

        </Box>
    )
}

export default KwikDashboard