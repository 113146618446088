import React from 'react';

import Frame from '../Frame';

const FrameTodayAgents = ({ gridArea, todayLoggedInAgents }) => {
  return (
    <Frame gridArea={gridArea} title="Operadores Logados (máx.)">
      {todayLoggedInAgents}
    </Frame>
  );
}

export default FrameTodayAgents;