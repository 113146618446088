import React, { useEffect, useReducer } from 'react'
import { Box, Select, InputLabel, MenuItem, FormControl } from '@mui/material'

import './styles.css'
import defaultReducer from '../../../utils/defaultReducer'
import api from '../../../api'
import Loading from '../../Loading'
import { getSession } from '../../../auth'
import AllCharts from './graphs/charts'
import dayjs from 'dayjs';

let timeout = 0
const default_interval = 45000

const possible_times = {
    '8' : 'Últimas 8 horas',
    '12': 'Últimas 12 horas',
    '24': 'Últimas 24 horas'
}

const retrieveData = async (queue, setState, enterprise_id, loader = false) => {
    if (Number(dayjs().format('mm')) % 5 !== 0 && !loader) return
    if (loader) setState({ loading: true })
    const res = await api.get(`api/enterprises/${enterprise_id}/dashboard/historical/${queue ? `?queue=${queue}` : ''}`)
    if (res.status === 200) {
        setState({ data: res.data, loading: false })
        return
    }
    setState({ loading: false })
}

const HistoricalDash = ({ innerRef, queues }) => {

    const enterprise = getSession()?.profile?.enterprise
    const enterprise_id = enterprise?.id
    const timezone = enterprise.settings.timezone



    const [state, setState] = useReducer(defaultReducer, {
        data: {},
        selected_queue: '__all__',
        loading: false,
        interval_filter: '8'
    })

    const date = dayjs()
    const division_rest = date.get('minute') % 5
    const last_date = date.subtract(division_rest, 'minutes').tz(timezone).format('HH:mm')

    useEffect(() => {
        innerRef(
            <Box sx={{display:'flex', gap:'1rem'}}>
                <FormControl
                    fullWidth
                    size="small"
                    sx={{ maxWidth: '15rem' }}
                >
                    <InputLabel id="queue-select-label">Fila(s)</InputLabel>
                    <Select
                        labelId="queue-select-label"
                        id="queue-box-simple-select"
                        onChange={(event) => setState({ selected_queue: event.target.value })}
                        value={state.selected_queue}
                        label='Fila(s)'
                    >
                        <MenuItem key={`0-select-queue`} value={'__all__'}>Todas</MenuItem>
                        {queues?.map(each => (
                            <MenuItem key={`${each.id}-select-queue`} value={each.name}>{each.description}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl
                    fullWidth
                    size="small"
                    sx={{ maxWidth: '15rem' }}
                >
                    <InputLabel id="hour-select-label">Intervalo</InputLabel>
                    <Select
                        labelId="hour-select-label"
                        id="hour-box-simple-select"
                        onChange={(event) => setState({ interval_filter: event.target.value })}
                        value={state.interval_filter}
                        label='Intervalo'
                    >
                        {Object.entries(possible_times)?.map(([key, value]) => (
                            <MenuItem key={`${key}-select-hour`} value={key}>{value}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>)
        clearInterval(timeout)
        retrieveData(state.selected_queue, setState, enterprise_id, true)
        timeout = setInterval(() => retrieveData(state.selected_queue, setState, enterprise_id), default_interval)
        return () => {
            clearInterval(timeout)
        }
    }, [state.selected_queue, state.interval_filter])



    return (
        <>
            <Loading loading={state.loading} />
            <Box className='historical-records-mainframe'>
                <AllCharts data={state.data} mode={state.selected_queue} interval={state.interval_filter} last_date={last_date}/>
            </Box>
        </>
    )
}

export default HistoricalDash