import React, { useState } from 'react'
import { Box } from '@mui/material';
import MessageItem from '../Chat/MessagesArea/ChatWindow/MessageItem'
import makeStyles from '@mui/styles/makeStyles';

const ChatStory = ({ key, message, user, queuesName, platform }) => {
    const [error, setError] = useState(false)
    const [isVideo, setIsVideo] = useState(false)

    const useStyles = makeStyles((theme) => {
        return {
            image: {
                maxWidth: '250px',
                border: user !== 0 ? '3px solid #21446C' : '3px solid #f1f0f7',
                borderRadius: '5px',
                marginTop: '5px',
                marginBottom: '5px'
            }
        }
    })

    const classes = useStyles();

    const mediaUrl = message.message.split("[story]")[1].split("[/story]")[0]

    const handleError = (e) => {
        if (!isVideo)
            setIsVideo(true)
        else
            setError(true)
    }

    return (
        <>
            {!error ? (
                <Box display="flex" justifyContent={user !== 0 ? 'flex-end' : 'flex-start'}>
                    <a href={mediaUrl} target="_blank" rel="noopener noreferrer">
                        {!isVideo ? (
                            <img 
                                onError={handleError} 
                                className={classes.media} 
                                src={mediaUrl} 
                                alt="story media"
                            />
                        ) : (
                            <video 
                                onError={handleError} 
                                className={classes.media} 
                                controls
                            >
                                <source src={mediaUrl} type="video/mp4" />
                                Seu navegador não suporta a tag de vídeo.
                            </video>
                        )}
                    </a>
                </Box>
            ) : (
                <MessageItem
                    key={key}
                    message={{ ...message, message: 'Esse story não está mais disponível' }}
                    user={user}
                    queuesName={queuesName}
                    platform={platform}
                    media={true}
                />
            )}
        </>
    )
}

export default ChatStory

