import React, { useEffect, useReducer, useState } from 'react'
import { Box, TextField, FormControl, Switch, FormControlLabel } from '@mui/material'
import {lockedReducer} from '../../../../utils/defaultReducer'
import TooltipIndicators from '../../../Dash/Tooltip'
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import api from '../../../../api';
import { getSession } from '../../../../auth';
import useNotification from '../../../Notification';
import Loading from '../../../Loading';

const tempostring = "{{tempo}}"

const checkDelayValue = (value, min, max) => {
    const number = Number(value)
    if (number < 0)
        return 0
    if (number > 10)
        return 10
    return number
}

const EndActions = () => {

    const enterpriseId = getSession()?.profile?.enterprise?.id

    const [loading, setLoading] = useState(false)
    const [patchLoading, setPatchLoading] = useState(false)

    const [state, setState] = useReducer(lockedReducer, {
        end_actions_time: '00:00',
        all_hangup: false,
        all_hangup_prev_message: 'msg inicial',
        all_hangup_delay_message: 0,
        all_hangup_message: 'msg final',
        all_hangup_agents: false
    })

    const loadData = async () => {
        setLoading(true)
        const res = await api.get(`/api/enterprises/${enterpriseId}/`)
        setLoading(false)
        if (res.status === 200) {
            setState(res.data.settings)
            return
        }

        useNotification(
            'Ops!',
            'Informações não carregadas',
            'danger'
        );
    }

    useEffect(() => {
        loadData()
    }, [])

    const submit = async (data) => {

        setPatchLoading(true)
        const res = await api.patch(`/api/enterprises/${enterpriseId}/`, {settings:data})
        setPatchLoading(false)
        if (res.status === 200) {
            useNotification(
                'Sucesso!',
                'Configurações do Fim de expediente alterados com sucesso.',
                'success'
            );
            return
        }
        useNotification(
            'Ops!',
            'Ocorreu um erro no sistema',
            'danger'
        );

    }

    return (
        <>
            <Loading loading={loading} />
            <Box className="flexbox input-settings" sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                <Box className="category-title push-bottom">
                    Ações de fim de expediente
                </Box>

                <FormControl fullWidth size="small"
                >
                    <TextField
                        size="small"
                        label="Hora"
                        type="time"
                        variant="outlined"
                        sx={{ width: 'fit-content' }}
                        value={state.end_actions_time}
                        onChange={(event) => setState({
                            end_actions_time: event.target.value
                        })}
                    />
                </FormControl>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '.25rem' }}>
                    <FormControlLabel
                        label="Finalizar todos atendimentos ao fim do expediente"
                        fullWidth
                        sx={{ margin: 0 }}

                        control={
                            <Switch
                                checked={state.all_hangup}
                                onChange={() => setState({ all_hangup: !state.all_hangup })}
                            />
                        }
                    />
                    <TooltipIndicators
                        text={
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                                <Box>Ao chegar no horário delimitado, será enviada uma mensagem de aviso, e depois do intervalo determinado, todas as chamadas em atendimento serão desligadas e a mensagem de finalização será enviada.</Box>
                                <Box> É possível utilizar o campo 'Intervalo entre envios' como variável para a Mensagem de aviso, escrevendo {tempostring}.</Box>
                                <Box>O menor tempo possível é 0 e o maior é 10 minutos.</Box>
                            </Box>
                        }
                        margin="0px"
                    />
                </Box>
                {state.all_hangup &&
                    <Box sx={{ display: 'flex', width: '100%', gap: '1.5rem' }}>
                        <TextField
                            sx={{ flex: 1 }}
                            size="small"
                            label="Mensagem de aviso"
                            fullWidth
                            type="text"
                            variant="outlined"
                            value={state.all_hangup_prev_message}
                            onChange={(event) => setState({
                                all_hangup_prev_message: event.target.value
                            })}
                        />
                        <TextField
                            size="small"
                            label="Intervalo entre envios (minutos)"
                            type="number"
                            variant="outlined"
                            sx={{ flex: .3 }}
                            inputProps={{
                                min: 0,
                                max: 10,
                                step: 1,
                                inputMode: 'numeric',
                                pattern: '[0-9]'
                            }}
                            value={state.all_hangup_delay_message}
                            onChange={(event) => setState({
                                all_hangup_delay_message: checkDelayValue(event.target.value, 0, 10)
                            })}
                        />
                        <TextField
                            sx={{ flex: 1 }}
                            size="small"
                            label="Mensagem de finalização"
                            fullWidth
                            type="text"
                            variant="outlined"
                            value={state.all_hangup_message}
                            onChange={(event) => setState({
                                all_hangup_message: event.target.value
                            })}
                        />
                    </Box>
                }
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '.25rem' }}>
                    <FormControlLabel
                        label="Deslogar todos operadores ao fim do expediente"
                        fullWidth
                        sx={{ margin: 0 }}

                        control={
                            <Switch
                                checked={state.all_hangup_agents}
                                onChange={() => setState({ all_hangup_agents: !state.all_hangup_agents })}
                            />
                        }
                    />
                    <TooltipIndicators
                        text={
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                                <Box>Ao chegar no horário delimitado, todos os agentes serão deslogados da plataforma e precisarão fazer login novamente.</Box>
                            </Box>
                        }
                        margin="0px"
                    />
                </Box>

                <LoadingButton
                    style={{ width: 'fit-content' }}
                    onClick={() => submit(state)}
                    loading={patchLoading}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                >
                    Salvar
                </LoadingButton>
            </Box>
        </>
    )
}


export default EndActions