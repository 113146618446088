import React, { useState, useEffect, useContext, useReducer } from 'react'
import { constructorContext } from '..'
import {
    Box, Dialog, DialogActions, DialogContent, DialogTitle,
    Tooltip, Button, TextField, Divider, Select, MenuItem, InputLabel, FormControl,
    IconButton,
    List,
    ListItem
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import api from '../../../../api';
import useNotification from '../../../Notification';
import defaultReducer, { lockedReducer } from '../../../../utils/defaultReducer';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InputAdornment from '@mui/material/InputAdornment';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { weekdays } from './index.js'
import TooltipIndicators from '../../../Dash/Tooltip'

import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import ptBR from 'dayjs/locale/pt-br';

import { ptBR as localePTBR } from '@mui/x-date-pickers/locales';

const action = (data) => {
    if (data?.id)
        return 'Editar'
    return 'Adicionar'
}

const months = {
    0: 'Todos',
    1: 'Janeiro',
    2: 'Fevereiro',
    3: 'Março',
    4: 'Abril',
    5: 'Maio',
    6: 'Junho',
    7: 'Julho',
    8: 'Agosto',
    9: 'Setembro',
    10: 'Outubro',
    11: 'Novembro',
    12: 'Dezembro'
}

const initException = {
    starttime: '08:00',
    endtime: '18:00',
    startdayofweek: 0,
    enddayofweek: 0,
    startdayofmonth: 0,
    enddayofmonth: 0,
    startmonth: 0,
    endmonth: 0,
    startyear: 0,
    endyear: 0,
    allow: 0,
    priority: 0
}

function zeroPad(num, places) {
    var zero = places - num.toString().length + 1;
    return Array(+(zero > 0 && zero)).join('0') + num;
}


const validateWeekday = (validDate, exception) => {

    if (exception.startdayofweek === 0 || exception.enddayofweek === 0)
        return true

    const dayOfWeek = validDate.getDay() + 1
    const exceptionStart = exception.startdayofweek
    const exceptionEnd = exception.enddayofweek

    if (exceptionStart <= exceptionEnd) {
        if (exceptionStart <= dayOfWeek && dayOfWeek <= exceptionEnd)
            return true
    }
    else {
        if (exceptionStart <= dayOfWeek || dayOfWeek <= exceptionEnd)
            return true
    }

    return false
}

const validateTime = (validDate, exception) => {

    const testHours = validDate.toTimeString().substring(0,8)
    const testHour = new Date(`2023-01-01T${testHours}`)
    const starthour = new Date(`2023-01-01T${exception.starttime}`)
    const endhour = new Date(`2023-01-01T${exception.endtime}`)


    if (starthour <= endhour) {
        if (starthour <= testHour && testHour <= endhour)
            return true
    }
    else {
        if (starthour <= testHour || testHour <= endhour)
            return true
    }

    return false
}

const validateDay = (validDate, exception) => {

    if (exception.enddayofweek !== 0 || exception.startdayofweek !== 0)
        return true

   
    const testDays = validDate.toISOString().substring(0, 10)
    const validDay = new Date(`${testDays}T12:00`)
    const fullYear = validDay.getFullYear()
    const startDay = new Date(`${exception.startyear ? exception.startyear : fullYear}-${zeroPad(exception.startmonth, 2)}-${zeroPad(exception.startdayofmonth, 2)}T12:00`)
    const endDay = new Date(`${exception.endyear ? exception.endyear : fullYear}-${zeroPad(exception.endmonth, 2)}-${zeroPad(exception.enddayofmonth, 2)}T12:00`)

    if (exception.startyear === 0 && exception.endyear === 0){ //anual
        
        let teststartyear = fullYear
        if (validDay < startDay)
            teststartyear -=1

        let testendyear = teststartyear
        if (endDay < startDay)
            testendyear +=1

        const newStartDay = new Date(`${teststartyear}-${zeroPad(exception.startmonth, 2)}-${zeroPad(exception.startdayofmonth, 2)}T12:00`)
        const newEndDay = new Date(`${testendyear}-${zeroPad(exception.endmonth, 2)}-${zeroPad(exception.enddayofmonth, 2)}T12:00`)
        
        if (newStartDay <= validDay && validDay <= newEndDay)
            return true
        return false
    }

    if (startDay <= validDay && validDay <= endDay)
        return true
    return false
}

const validateDefault = (validDate, exception) => {
    return (validateWeekday(validDate, exception) && validateDay(validDate, exception) && validateTime(validDate, exception))
}

const TestChecktime = ({ parent, close }) => {

    const [test, setTest] = useState('')
    const [result, setResult] = useReducer(lockedReducer, {
        data: '',
        passed: false,
        ready: false
    })

    const testDate = () => {
        const validDate = new Date(test)
        let selected = 0

        const selectedExceptions = parent.exceptions.map((each, index) => ({ ...each, idx: index + 1 }))

        const proibitions = selectedExceptions.filter(each => !each.allow).some((exception => validateDefault(validDate, exception) ? selected = exception.idx : false))
        if (proibitions) {
            setResult({
                data: `Exceção número ${selected} capturada. O atendimento será proibido.`,
                passed: false,
                ready: true
            })
            return
        }
        const defaultTime = validateDefault(validDate, parent)
        if (defaultTime) {
            setResult({
                data: 'Horário de atendimento capturado. O atendimento será permitido ',
                passed: true,
                ready: true
            })
            return
        }
        const permissions = selectedExceptions.filter(each => each.allow).some((exception => validateDefault(validDate, exception) ? selected = exception.idx : false))
        if (permissions) {
            setResult({
                data: `Exceção número ${selected} capturada. O atendimento será permitido.`,
                passed: true,
                ready: true
            })
            return
        }

        setResult({
            data: 'Fora do horário de atendimento e nenhuma exceção capturada. O atendimento será proibido.',
            passed: false,
            ready: true
        })


    }

    const checkData = () => {
        return test === "" ? 'Escolha uma data' : 'Validar data'
    }


    return (
        <Dialog
            fullWidth
            maxWidth={'xs'}
            disablePortal
            disableEnforceFocus
            sx={{ position: 'absolute' }}
            open={true}
        >
            <DialogTitle
                className={'group-flexbox'}>
                <span>Testar regra</span>
                <Tooltip title="Fechar" >
                    <Button className="action-button"  >
                        <CloseIcon onClick={close} />
                    </Button>
                </Tooltip>
            </DialogTitle>
            <DialogContent>

                <TextField
                    sx={{ flex: 1, marginTop: '.5rem' }}
                    size='small'
                    label='Data'
                    type='datetime-local'
                    value={test}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => setTest(e.target.value)}
                />

                {result.ready && <>
                    <Box className='rule-check-list'>
                        <Box sx={{ color: result.passed ? 'green' : 'red' }}>
                            {result.data}
                        </Box>
                    </Box>
                </>}
            </DialogContent>
            <DialogActions style={{ padding: "10px 20px 10px 10px" }}>
                <Tooltip title={checkData()} placement="top" describeChild={true}>
                    <span>
                        <Button
                            disabled={test === ''}
                            onClick={testDate}>
                            Validar
                        </Button>
                    </span>
                </Tooltip>
            </DialogActions>
        </Dialog>

    )

}

const EditException = ({ object, setEditing, parent, setParent }) => {

    const [state, setState] = useReducer(lockedReducer, {
        allow: 1,
        starttime: '',
        endtime: '',
        startdayofweek: 0,
        enddayofweek: 0,
        startdate: '',
        enddate: '',
        ready: false,
        weekOrDays: true,
        anual: false
    })
    

    const checkButtonText = () => {
        const text = []

        if (state.weekOrDays) {
            if (state.startdayofweek === 0)
                text.push(<Box>Dia de início não pode ser vazia.</Box>)
            if (state.enddayofweek === 0)
                text.push(<Box>Dia de fim não pode ser vazia.</Box>)
        }
        else {
            if (state.startdate.length === 0)
                text.push(<Box>Data de início não pode ser vazia.</Box>)
            if (state.enddate.length === 0)
                text.push(<Box>Data de fim não pode ser vazia.</Box>)
        }

        if (state.starttime.length === 0)
            text.push(<Box>Hora de início não pode ser vazia.</Box>)
        if (state.endtime.length === 0)
            text.push(<Box>Hora de fim não pode ser vazia.</Box>)


        if (text.length == 0)
            return ''

        return (text)
    }

    const submitException = () => {

        let data = {
            starttime: state.starttime,
            endtime: state.endtime,
            allow: state.allow,
            startdayofmonth: 0,
            enddayofmonth: 0,
            startmonth: 0,
            endmonth: 0,
            startyear: 0,
            endyear: 0,
            startdayofweek: 0,
            enddayofweek: 0,
        }

        if (!state.weekOrDays) {

            const [startyear, startmonth, startday] = [state.startdate.$y, state.startdate.$M+1, state.startdate.$D]
            const [endyear, endmonth, endday] = [state.enddate.$y, state.enddate.$M+1, state.enddate.$D]

            data = {
                ...data,
                startdayofmonth: Number(startday),
                enddayofmonth: Number(endday),
                startmonth: Number(startmonth),
                endmonth: Number(endmonth),
                startyear: state.anual ? 0 : startyear,
                endyear: state.anual ? 0 : endyear,
            }
        }
        else {
            data = {
                ...data,
                startdayofweek: state.startdayofweek,
                enddayofweek: state.enddayofweek,
            }
        }

        if (object.new) {
            setParent({ exceptions: [...parent.exceptions, data] })
        }
        else {
            parent.exceptions.splice(parent.exceptions.indexOf(object), 1, data)
            setParent({ exceptions: [...parent.exceptions] })
        }
        setEditing(null)
    }

    useEffect(() => {
        if (!object.new) {
            if (object.startdayofweek) {
                setState({
                    weekOrDays: true,
                    allow: object.allow,
                    startdayofweek: object.startdayofweek,
                    enddayofweek: object.enddayofweek,
                    starttime: object.starttime,
                    endtime: object.endtime,
                    ready: true,
                })
            }
            else {
                let isAnual = false
                if (object.startyear === 0 && object.endyear === 0)
                    isAnual = true

                setState({
                    weekOrDays: false,
                    allow: object.allow,
                    startdate: dayjs(`${object.startyear}-${zeroPad(object.startmonth, 2)}-${zeroPad(object.startdayofmonth, 2)}`),
                    enddate: dayjs(`${object.endyear}-${zeroPad(object.endmonth, 2)}-${zeroPad(object.enddayofmonth, 2)}`),
                    starttime: object.starttime,
                    endtime: object.endtime,
                    startdayofweek: 0,
                    enddayofweek: 0,
                    ready: true,
                    anual: isAnual
                })
            }

            return
        }
        setState({ ready: true })
    }, [object])

    return (
        <Dialog
            open={state.ready}
            fullWidth
            disablePortal
            disableEnforceFocus
            sx={{ position: 'absolute' }}
        >
            <DialogTitle
                id="customized-dialog-title" className={'group-flexbox'}>
                <span>{state.new ? 'Nova' : 'Editar'} Exceção</span>
                <Tooltip title="Fechar" >
                    <Button className="action-button"  >
                        <CloseIcon onClick={() => { setEditing(null) }} />
                    </Button>
                </Tooltip>
            </DialogTitle>
            <Divider />
            <DialogContent className='piloto-modal-flex'>

                <Box className='BuilderBotSelectorBox'>
                    <Button
                        variant={state.allow === 1 ? 'contained' : ''}
                        onClick={() => setState({ allow: 1 })}
                    >
                        Permitir
                    </Button>
                    <Button
                        variant={state.allow === 0 ? 'contained' : ''}
                        onClick={() => setState({ allow: 0 })}
                    >
                        Proibir
                    </Button>
                </Box>

                <Box className='BuilderBotSelectorBox'>
                    <Button
                        variant={state.weekOrDays ? 'contained' : ''}
                        onClick={() => setState({ weekOrDays: true })}
                    >
                        Intervalo da semana
                    </Button>
                    <Button
                        variant={!state.weekOrDays ? 'contained' : ''}
                        onClick={() => setState({ weekOrDays: false })}
                    >
                        Intervalo de dias
                    </Button>
                </Box>



                {state.weekOrDays ?
                    <>
                        <Box sx={{ display: 'flex', gap: '1rem' }}>
                            <FormControl size='small' sx={{ flex: 1 }}>
                                <InputLabel id="simple-startdayofweek-select">Dia de início</InputLabel>
                                <Select

                                    label='Dia de início'
                                    labelId='simple-startdayofweek-select'
                                    value={state.startdayofweek}
                                    onChange={(e) => setState({ startdayofweek: e.target.value })}
                                >
                                    {weekdays.map(each => (
                                        <MenuItem value={each.id}>{each.value}</MenuItem>
                                    ))}

                                </Select>
                            </FormControl>

                            <FormControl size='small' sx={{ flex: 1 }}>
                                <InputLabel id="simple-enddayofweek-select">Dia de fim</InputLabel>
                                <Select

                                    label='Dia de fim'
                                    labelId='simple-enddayofweek-select'
                                    value={state.enddayofweek}
                                    onChange={(e) => setState({ enddayofweek: e.target.value })}
                                >
                                    {weekdays.map(each => (
                                        <MenuItem value={each.id}>{each.value}</MenuItem>
                                    ))}

                                </Select>
                            </FormControl>
                        </Box>

                        <Box sx={{ display: 'flex', gap: '1rem' }}>
                            <TextField
                                sx={{ flex: 1 }}
                                size='small'
                                label='Hora de início'
                                type='time'
                                InputLabelProps={{ shrink: true }}
                                value={state.starttime}
                                onChange={(e) => setState({ starttime: e.target.value })}
                            />

                            <TextField
                                sx={{ flex: 1 }}
                                size='small'
                                label='Hora de fim'
                                type='time'
                                InputLabelProps={{ shrink: true }}
                                value={state.endtime}
                                onChange={(e) => setState({ endtime: e.target.value })}
                            />

                        </Box>
                    </>
                    :
                    <>

                    <Box className='BuilderBotSelectorBox'>
                        <Button
                            variant={!state.anual ? 'contained' : ''}
                            onClick={() => setState({ anual: false })}
                        >
                            Uma vez
                        </Button>
                        <Button
                            variant={state.anual ? 'contained' : ''}
                            onClick={() => setState({ anual: true })}
                        >
                            Anualmente
                        </Button>
                    </Box>


                        <LocalizationProvider sx={{justifyContent:'space-between'}} dateAdapter={AdapterDayjs} adapterLocale={ptBR}
                        localeText={localePTBR.components.MuiLocalizationProvider.defaultProps.localeText}
                        >
                            <DemoContainer components={['DatePicker', 'DatePicker']}>
                                <DatePicker 
                                    sx={{width:'100%'}} 
                                    label={'Data de início'} 
                                    views={state.anual ? ['day', 'month'] : ['day', 'month', 'year']} 
                                    format={state.anual ? "DD/MM" : "DD/MM/YYYY"} 
                                    slotProps={{ textField: { size: 'small' } }}
                                    value={dayjs(state.startdate)}
                                    onChange={(e) => setState({ startdate: e })}
                                />
                                <DatePicker 
                                    sx={{width:'100%'}} 
                                    label={'Data de fim'} 
                                    views={state.anual ? ['day', 'month'] : ['day', 'month', 'year']} 
                                    format={state.anual ? "DD/MM" : "DD/MM/YYYY"} 
                                    slotProps={{ textField: { size: 'small' } }}
                                    value={dayjs(state.enddate)}
                                    onChange={(e) => setState({ enddate: e })}
                                />
                            </DemoContainer>
                        </LocalizationProvider>                       

                        <Box sx={{ display: 'flex', gap: '1rem' }}>
                            <TextField
                                sx={{ flex: 1 }}
                                size='small'
                                label='Hora de início'
                                type='time'
                                value={state.starttime}
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => setState({ starttime: e.target.value })}
                            />

                            <TextField
                                sx={{ flex: 1 }}
                                size='small'
                                label='Hora de fim'
                                type='time'
                                value={state.endtime}
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => setState({ endtime: e.target.value })}
                            />

                        </Box>
                    </>
                }



            </DialogContent>
            <DialogActions style={{ padding: "10px 20px 10px 10px" }}>
                <Button
                    onClick={() => { setEditing(null) }}>
                    Cancelar
                </Button>
                <Tooltip title={checkButtonText()} placement="top" describeChild={true}>
                    <span>
                        <Button
                            disabled={checkButtonText()}
                            onClick={submitException}>
                            Salvar
                        </Button>
                    </span>
                </Tooltip>
            </DialogActions>
        </Dialog>
    )

}

const ExceptionBox = ({ obj, index, state, setState, setEditing }) => {

    const [exception, setException] = useReducer(lockedReducer, initException)

    useEffect(() => {
        if (!obj.new)
            setException(obj)
        else
            setException(initException)
    }, [obj])

    const getDate = (place) => {
        if (exception[`${place}dayofweek`])
            return weekdays.find(each => each.id === exception[`${place}dayofweek`])?.value
        if (exception[`${place}year`] !== 0)
            return `${exception[`${place}dayofmonth`]}/${exception[`${place}month`]}/${exception[`${place}year`]}`
        return `${exception[`${place}dayofmonth`]}/${exception[`${place}month`]}`
    }

    const processDate = () => {
        const dateStart = getDate('start')
        const dateEnd = getDate('end')

        if (dateStart === dateEnd)
            return dateStart
        return `${dateStart} até ${dateEnd}`
    }

    return (
        <>
            <Box key={`index-select-exception-${index}`}
                className='questionsboxok'>
                <InputAdornment position="start">
                    {index}.
                </InputAdornment>
                <Box className='internal-question-box'>
                    {`${exception.allow ? 'Permitir' : 'Proibir'} de ${processDate()}  
                    de
                    ${exception.starttime?.substring(0, 5)} às ${exception.endtime?.substring(0, 5)}`}
                </Box>
                <Box sx={{ display: 'flex' }}>
                    <Tooltip title="Editar Exceção">
                        <Button className="action-button"
                            onClick={() => {
                                setEditing(obj)
                            }}>
                            <EditIcon />
                        </Button>
                    </Tooltip>
                    <Tooltip title="Excluir Exceção">
                        <Button className="action-button delete"
                            onClick={() => {
                                setState({ exceptions: [...state.exceptions.filter(exception => exception != obj)] })
                            }}>
                            <DeleteIcon />
                        </Button>
                    </Tooltip>
                </Box>
            </Box>
        </>
    )
}


const ChecktimeEditModal = ({ data, setData, getRows }) => {
    const { enterpriseId, activeTab, setLoading } = useContext(constructorContext)

    const [showExceptions, setShowExceptions] = useState(false)
    const [showTest, setTest] = useState(false)
    const [editing, setEditing] = useState(null)

    const [state, setState] = useReducer(defaultReducer, {
        name: '',
        description: '',
        starttime: '08:00:00',
        endtime: '18:00:00',
        startdayofweek: 0,
        enddayofweek: 0,
        exceptions: []
    })

    const handleDrop = (droppedItem) => {
        // Ignore drop outside droppable container
        if (!droppedItem.destination) return;
        var updatedList = [...state.exceptions];
        // Remove dragged item
        const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
        // Add dropped item
        updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
        // Update State

        setState({ exceptions: updatedList });
    };

    const checkButtonText = () => {
        const text = []

        if (state.name.length == 0)
            text.push(<Box>Nome não pode estar vazio.</Box>)

        if (state.description.length == 0)
            text.push(<Box>Descrição não pode estar vazia.</Box>)

        if (state.starttime == 0)
            text.push(<Box>Escolha a hora de início.</Box>)

        if (state.startdayofweek == 0)
            text.push(<Box>Escolha o dia de início.</Box>)

        if (state.enddayofweek == 0)
            text.push(<Box>Escolha o dia de fim.</Box>)

        if (state.endtime == 0)
            text.push(<Box>Escolha a hora de fim.</Box>)

        if (text.length == 0)
            return ''

        return (text)
    }

    useEffect(async () => {
        if (!data.id)
            return

        const rules = [...data.rules]
        const defaultRule = rules.pop()

        setState({
            name: data.name,
            description: data.description,
            starttime: defaultRule?.starttime,
            endtime: defaultRule?.endtime,
            startdayofweek: Number(defaultRule?.startdayofweek),
            enddayofweek: Number(defaultRule?.enddayofweek),
            exceptions: rules
        })
    }, [data])

    const submit = async () => {
        let res = null
        const url = `api/enterprises/${enterpriseId}/checktimes/`
        let body = state
        setLoading(true)
        if (!data?.id)
            res = await api.post(url, body, { timeout: 0 })
        else
            res = await api.patch(url + `${data.id}/`, body, { timeout: 0 })
        setLoading(false)

        if (!(res.status === 200 || res.status === 201)) {
            useNotification(
                'Ops!',
                `Não foi possível ${action(data)} o horário de atendimento.`,
                'danger'
            )
            return
        }
        getRows()
        setData()
        useNotification(
            'Sucesso!',
            `Horário de atendimento ${res.status == 201 ? 'adicionado' : 'atualizado'} com sucesso.`,
            'success'
        )

    }


    return (
        <>
            <Dialog open={true}
                fullWidth
                disablePortal
                disableEnforceFocus
                sx={{ position: 'absolute' }}
            >
                <DialogTitle
                    id="customized-dialog-title" className={'group-flexbox'}>
                    <span>{action(data)} Horário de atendimento</span>
                    <Tooltip title="Fechar" >
                        <Button className="action-button"  >
                            <CloseIcon onClick={() => { setData() }} />
                        </Button>
                    </Tooltip>
                </DialogTitle>
                <Divider />
                <DialogContent className='piloto-modal-flex'>

                    <TextField
                        size='small'
                        label='Nome'
                        value={state.name}
                        onChange={(e) => setState({ name: e.target.value })}
                    />

                    <TextField
                        size='small'
                        label='Descrição'
                        value={state.description}
                        onChange={(e) => setState({ description: e.target.value })}
                    />

                    <Box sx={{ display: 'flex', gap: '1rem' }}>
                        <FormControl size='small' sx={{ flex: 1 }}>
                            <InputLabel id="simple-startdayofweek-select">Dia de início</InputLabel>
                            <Select

                                label='Dia de início'
                                labelId='simple-startdayofweek-select'
                                value={state.startdayofweek}
                                onChange={(e) => setState({ startdayofweek: e.target.value })}
                            >
                                {weekdays.map(each => (
                                    <MenuItem value={each.id}>{each.value}</MenuItem>
                                ))}

                            </Select>
                        </FormControl>

                        <FormControl size='small' sx={{ flex: 1 }}>
                            <InputLabel id="simple-enddayofweek-select">Dia de fim</InputLabel>
                            <Select

                                label='Dia de fim'
                                labelId='simple-enddayofweek-select'
                                value={state.enddayofweek}
                                onChange={(e) => setState({ enddayofweek: e.target.value })}
                            >
                                {weekdays.map(each => (
                                    <MenuItem value={each.id}>{each.value}</MenuItem>
                                ))}

                            </Select>
                        </FormControl>
                    </Box>

                    <Box sx={{ display: 'flex', gap: '1rem' }}>
                        <TextField
                            sx={{ flex: 1 }}
                            size='small'
                            label='Hora de início'
                            type='time'
                            InputLabelProps={{ shrink: true }}
                            value={state.starttime}
                            onChange={(e) => setState({ starttime: e.target.value })}
                        />

                        <TextField
                            sx={{ flex: 1 }}
                            size='small'
                            label='Hora de fim'
                            type='time'
                            InputLabelProps={{ shrink: true }}
                            value={state.endtime}
                            onChange={(e) => setState({ endtime: e.target.value })}
                        />

                    </Box>

                    <Box className='questionsbox' sx={{ position: 'relative' }}>
                        {showExceptions && <Box className='question-exceptions-label'>
                            Exceções
                            <TooltipIndicators
                                text={(<>
                                    <Box>As prioridades são sempre nessa ordem: </Box>
                                    <Box>{"Proibições -> Horário de atendimento -> Permissões."}</Box>
                                </>)}
                                marginLeft="5px"
                            />
                        </Box>}
                        <Box className='questionsboxheader' >

                            <Box sx={{ paddingLeft: '.25rem', position: 'relative' }}>
                                {!showExceptions && <Box className='question-exceptions'>
                                    Exceções
                                    <TooltipIndicators
                                        text={(<>
                                            <Box>As prioridades são sempre nessa ordem: </Box>
                                            <Box>{"Proibições -> Horário de atendimento -> Permissões."}</Box>
                                        </>)}
                                        marginLeft="5px"
                                    />
                                </Box >
                                }
                            </Box>
                            {showExceptions ?
                                <Tooltip title='Esconder perguntas'>
                                    <IconButton>
                                        <ExpandLessIcon onClick={() => setShowExceptions(false)} />
                                    </IconButton>
                                </Tooltip>
                                :
                                <Tooltip title='Mostrar perguntas'>
                                    <IconButton onClick={() => setShowExceptions(true)}>
                                        <ExpandMoreIcon />
                                    </IconButton>
                                </Tooltip>
                            }

                        </Box>
                        {showExceptions && <Box sx={{ marginTop: '.5rem' }}>
                            <DragDropContext onDragEnd={handleDrop}>
                                <Droppable
                                    id={'draggable-list-containers'} droppableId="draggable-list-containers">
                                    {(provided) => (
                                        <div
                                            className="draggable-list-containers"
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                        >
                                            {state.exceptions.map((each, index) => {
                                                each.priority = index
                                                return (
                                                    <Draggable
                                                        key={index} draggableId={`draggable-${index}id`} index={index}
                                                    >
                                                        {(provided) => (
                                                            <div
                                                                className="draggable-item-container"
                                                                ref={provided.innerRef}
                                                                {...provided.dragHandleProps}
                                                                {...provided.draggableProps}
                                                            >
                                                                <ExceptionBox
                                                                    obj={each}
                                                                    index={index + 1}
                                                                    state={state}
                                                                    setState={setState}
                                                                    setEditing={setEditing} />
                                                            </div>
                                                        )}

                                                    </Draggable>
                                                )
                                            })}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>


                            </DragDropContext>
                            {state.exceptions?.length < 100 &&
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '.25rem', marginTop: '.5rem' }}>
                                    <Tooltip title='Nova exceção'>
                                        <IconButton onClick={() => { setEditing({ new: true }) }} >
                                            <AddCircleIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Box>}
                        </Box>
                        }
                    </Box>


                </DialogContent >
                <DialogActions style={{ padding: "10px 20px 10px 10px" }}>
                    <Button
                        onClick={() => { setTest(true) }}>
                        Testar
                    </Button>
                    <Button
                        onClick={() => { setData() }}>
                        Cancelar
                    </Button>
                    <Tooltip title={checkButtonText()} placement="top" describeChild={true}>
                        <span>
                            <Button
                                disabled={checkButtonText()}
                                onClick={submit}>
                                Salvar
                            </Button>
                        </span>
                    </Tooltip>
                </DialogActions>
            </Dialog >

            {editing &&
                <EditException
                    object={editing}
                    setEditing={setEditing}
                    parent={state}
                    setParent={setState}
                />
            }

            {showTest &&
                <TestChecktime
                    close={() => setTest(false)}
                    parent={state}
                />
            }
        </>
    )
}

export default ChecktimeEditModal