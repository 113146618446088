import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

const sampleData = [
  {
    'label': 'sys_hangup_forçado_1',
    'value': 5
  }, 
  {
    'label': 'sys_hangup_forçado_2',
    'value': 2
  },
  {
    'label': 'sys_hangup_forçado_3',
    'value': 8
  },
  {
    'label': 'sys_hangup_forçado_4',
    'value': 6
  },
  {
    'label': 'sys_hangup_forçado_5',
    'value': 1
  },
  {
    'label': 'sys_hangup_forçado_6',
    'value': 3
  },
  {
    'label': 'sys_hangup_forçado_7',
    'value': 7
  },
  {
    'label': 'sys_hangup_forçado_8',
    'value': 9
  },
  {
    'label': 'sys_hangup_forçado_9',
    'value': 10
  },
  {
    'label': 'sys_hangup_forçado_10',
    'value': 4
  },
]

const BarChart = ({ data }) => {
  const svgRef = useRef();

  const classificationColorMap = {
    'Atendida'          : '#3bae3b',
    'Perdida'           : '#d34c4d',
    'Retida no chatbot' : '#3390ec',
    'Transferência'     : '#f78f33'
  };

  const getBottomMargin = (rows) => {
    let countCharacters
    rows.forEach((row) => {
      if (! countCharacters || row.label.length > countCharacters)
        countCharacters = row.label.length
    })
    return countCharacters * 4.5
  }

  useEffect(() => {

    // data = sampleData

    if (data.length === 0)
      return

    const svg = d3.select(svgRef.current);

    svg.selectAll('*').remove();

    const width = svgRef.current.clientWidth;
    const height = svgRef.current.clientHeight;
    const margin = { top: 20, right: 30, bottom: getBottomMargin(data), left: 40 };
    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;

    const groupedData = d3.group(data, (d) => d.label.split(':')[0]);
    const stackKeys = Array.from(new Set(data.map(d => d.label.split(':')[1]?.trim())));

    const stackedData = Array.from(groupedData, ([key, values]) => {
      const stackObj = { channel: key };
      values.forEach(({ label, value }) => {
        const classification = label.split(':')[1]?.trim();
        stackObj[classification] = value;
      });
      return stackObj;
    });

    const xScale = d3
      .scaleBand()
      .domain(stackedData.map((d) => d.channel))
      .range([0, innerWidth])
      .padding(0.1);

    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max(stackedData, (d) => stackKeys.reduce((sum, key) => sum + (d[key] || 0), 0))])
      .nice()
      .range([innerHeight, 0]);

    const colorScale = d3.scaleOrdinal()
      .domain(stackKeys)
      .range(stackKeys.map((key) => classificationColorMap[key] || 'steelblue'));

    const stackGenerator = d3.stack()
      .keys(stackKeys)
      .value((d, key) => d[key] || 0);

    const layers = stackGenerator(stackedData);

    const mainGroup = svg
      .append('g')
      .attr('transform', `translate(${margin.left}, ${margin.top})`);

    mainGroup
      .append('g')
      .selectAll('line')
      .data(yScale.ticks())
      .enter()
      .append('line')
      .attr('x1', 0)
      .attr('y1', (d) => yScale(d))
      .attr('x2', innerWidth)
      .attr('y2', (d) => yScale(d))
      .attr('stroke', '#ccc')
      .attr('stroke-dasharray', '2,2');

    mainGroup
      .selectAll('.layer')
      .data(layers)
      .enter()
      .append('g')
      .attr('fill', (d) => colorScale(d.key))
      .selectAll('rect')
      .data((d) => d)
      .enter()
      .append('rect')
      .attr('x', (d) => xScale(d.data.channel))
      .attr('y', (d) => yScale(d[1]))
      .attr('height', (d) => yScale(d[0]) - yScale(d[1]))
      .attr('width', xScale.bandwidth());

    mainGroup
      .append('g')
      .attr('transform', `translate(0, ${innerHeight})`)
      .call(d3.axisBottom(xScale))
      .selectAll('text')
      .attr('transform', 'rotate(-45)')
      .style('text-anchor', 'end')
      .attr('dx', '-0.5em')
      .attr('dy', '0.5em');

    mainGroup.append('g').call(d3.axisLeft(yScale));
  }, [data]);

  return (data.length !== 0 && 
    (
      <div style={{ width: '100%', height: '90%' }}>
        <svg ref={svgRef} style={{ width: '100%', height: '100%' }}></svg>
      </div>
    )
  );
};

export default BarChart;
