import React from 'react';

import './index.css';

import { Dialog, DialogTitle, DialogContent, InputAdornment, formLabelClasses } from '@mui/material';
import { List, ListItem, ListItemText, DialogActions } from '@mui/material';
import { Box, Button, Divider, Grid, TextField, Tooltip } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import ReactivationIcon from '@mui/icons-material/Update';
import CloseIcon from '@mui/icons-material/Close';

import { getSession } from '../../../auth';
import api from '../../../api';
import useNotification from '../../Notification';
import ParameterFiller from '../../Crm/Templates/ParameterFiller';
import useValidationError from '../../ErrorValidation';

import SearchIcon from '@mui/icons-material/Search';


class SendTemplateDialog extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading           : false,
            selectedTemplate  : null,
            showParameters    : false,
            parameters        : [],
            mediaParameters   : {},
            allParamsFilled   : true,
            filteredTemplates : [],
            numberTemplates   : []
        }
    }

    submit = (type) => {
        if (this.state.showParameters) {
            this.sendMediaReactivation(type)
            return
        }

        const requiredParameters = []
        if (this.state.selectedTemplate.components.length == 1 && this.state.selectedTemplate.components[0].type == 'BODY') {
            let amount = this.matchRegLen(this.state.selectedTemplate.components[0].text)
            if (amount)
                requiredParameters.push(
                    {
                        type: 'BODY',
                        amount: amount
                    }
                )
        }
        else {
            this.state.selectedTemplate.components.map(component => {
                let amount = 0
                if (component.type == 'HEADER') {
                    amount = 1
                    if (component.format == 'TEXT')
                        amount = this.matchRegLen(component.text)
                    if (amount)
                        requiredParameters.push({
                            type: component.type,
                            format: component.format,
                            amount: amount
                        })
                }
                else if (component.type == 'BODY') {
                    amount = this.matchRegLen(component.text)
                    if (amount)
                        requiredParameters.push(
                            {
                                type: 'BODY',
                                amount: amount
                            }
                        )
                }
                else if (component.type == 'BUTTONS') {
                    amount = 0
                    component.buttons.map(button => {
                        if (button.type === 'URL')
                            amount += this.matchRegLen(button.url)
                    })
                    if (amount)
                        requiredParameters.push(
                            {
                                type: 'BUTTONS',
                                amount: amount
                            }
                        )
                }

            })
        }

        if (requiredParameters.length > 0) {
            this.setState({
                showParameters: true,
                parameters: requiredParameters
            });
        }
        else {
            if (type === 'reactivation')
                this.postReactivation();
            else
                this.renotify();
        }
    }



    matchRegLen = value => {
        if (!value)
            return
        const matcher = value.match(/{{\d+}}/g)
        if (matcher)
            return matcher.length
        return 0
    }

    getCountTemplateParameters = () => {
        return (this.state.selectedTemplate.components[0].text.match(/{{/g) || []).length;
    }

    /**
     * In order to properlly reactivate a chat, we need to POST
     * the chat ID from DB table LH_CHAT
     * 
     * Chats can be either
     *  - finished (from DB table LH_CHAT)
     *  - queued/ongoing (from DB table ACD_CHATS)
     * 
     * Therefore, in order to guarantee that we are reactivating the
     * correct chat, we need to check if the given frontend chat model
     * contains a 'chat_id' key (this key is created for chats from
     * ACD_CHATS, despite the regular ID from that table), if so, we
     * use that chat_id, otherwise we can trust and use the given model
     * ID since it's a already a LH_CHAT
     **/
    getChatId = (chat) => {
        return ('chat_id' in chat) ? chat.chat_id : chat.id;
    }

    sendMediaReactivation = async (type) => {
        const user = getSession();

        // Must validate parameters.
        this.setState({ loading: true });

        const formdata = new FormData()
        const parameters = this.state.mediaParameters
        if ('headerfile' in parameters)
            formdata.append('headerfile', parameters.headerfile)
        if ('headerbody' in parameters)
            formdata.append('headerbody', parameters.headerbody)
        if ('body' in parameters)
            Object.values(parameters.body).map(p => formdata.append('body', p))
        if ('buttons' in parameters)
            formdata.append('buttons', parameters.buttons)

        if (!('headerfile' in parameters) && 'headerURL' in parameters)
            formdata.append('headerURL', parameters.headerURL)

        const isChatQueued = 'chatstatus' in this.props.activeChat
            && this.props.activeChat.chatstatus == 'QUEUE';

        if (isChatQueued) {
            formdata.append('hash', this.props.activeChat.channel)
            formdata.append('template', this.state.selectedTemplate.name)
            formdata.append('queued_reactivation', true)
        } else {
            formdata.append('template', this.state.selectedTemplate.name)
            formdata.append('chat_id', this.getChatId(this.props.activeChat))
            formdata.append('agent', user.profile.agent.user.split('_').pop())
            formdata.append(type, true)
            if (type == 'renotification') {
                formdata.append('from', this.props.activeChat.sender)
                formdata.append('notification_pk', this.props.activeChat?.notification_id || this.props.notification_pk)
                formdata.append('queue_name', this.props.activeChat.queue.name)
                formdata.append('to', this.props.activeChat.contact.nick)
            } else {
                const queuename = this.props.activeChat.queue?.name?.split('_').pop()
                if (queuename)
                    formdata.append('queue_name', queuename)
                formdata.append('from', this.props.activeChat.sender)
                formdata.append('to', this.props.activeChat.callerid)
            }
        }

        api.post(`api/whatsapp/medianotification/`, formdata)
            .then(async (res) => {
                await this.setState({ loading: false });
                if (res.status === 201 || res.status === 200) {

                    this.props.handleClose();
                    if (type == 'renotification') {
                        const chat = this.props.activeChat
                        const queue_name = this.props.accountcode + '_' + chat.queueCode
                        const channel = chat.uniqueid
                        const connection = this.props.connections?.find(con => con.queue == queue_name)
                        if ('updateNotify' in window)
                            updateNotify()
                        if (queue_name && connection)
                            connection.send(JSON.stringify({ message: "NOTIFICATIONS", channel: channel }))
                        useNotification(
                            'Renotificação enviada',
                            'O cliente foi notificado com sucesso.',
                            'success'
                        )
                        return
                    } else if (isChatQueued) {
                        this.props.connectActiveChat();
                        this.props.activeChat.socket?.send(
                            JSON.stringify({
                                'channel': this.props.activeChat.channel,
                                'message': JSON.stringify(res.data),
                                'user': -2
                            })
                        );
                        await useNotification(
                            'Sucesso!',
                            'Mensagem enviada, aguarde a resposta do cliente.',
                            'info'
                        );
                        return;
                    }

                    this.props.finishReactivation(res.data);
                    await useNotification(
                        'Chat Reativado!',
                        'Aguarde a resposta do cliente, ao responder, ele será redirecionado automaticamente para sua lista de atendimentos.',
                        'info'
                    );
                    return;
                } else if (res.status === 400 && res.data.length) {

                    const msg = res.data[0].split(' [')[0];
                    if (msg === 'Client has an ongoing reactivation.') {
                        useNotification(
                            'Ops!',
                            'O cliente tem uma reativação em andamento.',
                            'danger'
                        );
                        return;
                    } else if (msg === 'Client has a queued or ongoing chat.') {
                        useNotification(
                            'Ops!',
                            'Você não pode reativar este atendimento. O cliente tem um chat em andamento.',
                            'danger'
                        );
                        return;
                    } else if (msg === 'Multiple settings found for') {
                        const num = res.data[0].split('[+')[1].split(']')[0];
                        useNotification(
                            'Ops!',
                            `Não é possível reativar este chat, ele foi iniciado pelo número "${num}".`,
                            'danger'
                        );
                        return;
                    }
                }
                else if (res.status === 400 && 'file' in res.data) {
                    useNotification(
                        'Ops!',
                        'Formato de arquivo inválido.',
                        'danger'
                    );
                    return
                }

                throw res;
            }).catch(async (err) => {
                useNotification(
                    'Ops!',
                    'Ocorreu um erro no sistema',
                    'danger'
                );
            });
    }

    postQueueReactivation = () => {
        api.post('api/whatsapp/queued_reactivation/', {
            hash: this.props.activeChat.channel,
            template: this.state.selectedTemplate.name,
            parameters: this.state.parameters.map(p => p.value)
        }).then(async (res) => {
            this.setState({ loading: false });
            if (res.status == 200) {
                this.props.connectActiveChat();
                this.props.activeChat.socket?.send(
                    JSON.stringify({
                        'channel': this.props.activeChat.channel,
                        'message': JSON.stringify(res.data),
                        'user': -2
                    })
                );
                this.props.handleClose();
                await useNotification(
                    'Sucesso!',
                    'Mensagem enviada, aguarde a resposta do cliente.',
                    'info'
                );
                return;
            }
            throw res;
        }).catch(async (err) => {
            console.error(err);
            await useNotification(
                'Ops!',
                'Ocorreu um erro no sistema',
                'danger'
            );
        });
    }

    postReactivation = () => {
        this.setState({ loading: true });

        if ('chatstatus' in this.props.activeChat && this.props.activeChat.chatstatus == 'QUEUE') {
            this.postQueueReactivation();
            return;
        }

        const chat = this.props.activeChat
        const queue_name = chat?.queues_name ? chat?.queues_name : chat?.queue?.name
        const channel = chat.channel ? chat.channel : chat.hash
        const connection = this.props.connections?.find(con => con.queue == queue_name)

        const user = getSession();
        api.post(`api/whatsapp/reactivation/`, {
            from: this.props.activeChat.sender,
            to: this.props.activeChat.callerid,
            chat_id: this.getChatId(this.props.activeChat),
            queue_name: this.props.activeChat.queue?.name?.split('_')?.pop(),
            agent: user.profile.agent.user.split('_').pop(),
            template: this.state.selectedTemplate.name,
            parameters: this.state.parameters.map(p => p.value)
        }).then(async (res) => {
            this.setState({ loading: false });

            if (res.status === 201) {
                if (queue_name && connection)
                    connection.send(JSON.stringify({ message: "REACTIVATE", channel: channel }))
                this.props.handleClose();
                this.props.finishReactivation(res.data);
                await useNotification(
                    'Chat Reativado!',
                    'Aguarde a resposta do cliente, ao responder, ele será redirecionado automaticamente para sua lista de atendimentos.',
                    'info'
                );
                return;
            } else if (res.status === 400 && res.data.length) {
                const msg = res.data[0].split(' [')[0];
                if (msg === 'Client has an ongoing reactivation.') {
                    await useNotification(
                        'Ops!',
                        'O cliente tem uma reativação em andamento.',
                        'danger'
                    );
                    return;
                } else if (msg === 'Client has a queued or ongoing chat.') {
                    await useNotification(
                        'Ops!',
                        'Você não pode reativar este atendimento. O cliente tem um chat em andamento.',
                        'danger'
                    );
                    return;
                } else if (msg === 'Multiple settings found for') {
                    const num = res.data[0].split('[+')[1].split(']')[0];
                    await useNotification(
                        'Ops!',
                        `Não é possível reativar este chat, ele foi iniciado pelo número "${num}".`,
                        'danger'
                    );
                    return;
                }
            }
            throw res;
        }).catch(async (err) => {
            await useNotification(
                'Ops!',
                'Ocorreu um erro no sistema',
                'danger'
            );
        });
    }

    handleInputChange = (e, i) => {
        const { value } = e.target;
        const parameters = this.state.parameters;

        parameters[i] = {};
        parameters[i]['value'] = value;

        this.setState({
            parameters: parameters
        });
    }

    allParametersFilled = (value) => {
        this.setState({ allParamsFilled: value })
    }

    // allParametersFilled = () => {

    //     if (this.state.showParameters) {
    //         const header = this.state.parameters.find(each => each.type === 'HEADER')
    //         const body = this.state.parameters.find(each => each.type === 'BODY')
    //         const button = this.state.parameters.find(each => each.type === 'BUTTONS')


    //         if (header && this.state.mediaParameters) {

    //             if ('headerURL' in this.state.mediaParameters && !('headerfile' in this.state.mediaParameters)) {
    //                 if (this.state.mediaParameters.headerURL === '') {
    //                     this.setState({ allParamsFilled: false })
    //                     return false
    //                 }
    //             }
    //             else if (!('headerbody' in this.state.mediaParameters || 'headerfile' in this.state.mediaParameters)
    //                 || this.state.mediaParameters.headerbody === '') {
    //                 this.setState({ allParamsFilled: false })
    //                 return false
    //             }
    //             // if ('headerbody' in this.state.mediaParameters){
    //             //     if (this.state.mediaParameters.headerbody === ''){
    //             //         this.setState({allParamsFilled:false})
    //             //         return false
    //             //     }
    //             // }
    //         }

    //         if (body && this.state.mediaParameters) {
    //             if ('body' in this.state.mediaParameters) {
    //                 const bodylist = Object.values(this.state.mediaParameters.body)
    //                 if (body.amount !== bodylist.length || bodylist.includes('')) {
    //                     this.setState({ allParamsFilled: false })
    //                     return false
    //                 }
    //             }
    //             else {
    //                 this.setState({ allParamsFilled: false })
    //                 return false
    //             }

    //         }

    //         if (button && this.state.mediaParameters) {
    //             if (!('buttons' in this.state.mediaParameters) || this.state.mediaParameters.buttons === '') {
    //                 this.setState({ allParamsFilled: false })
    //                 return false
    //             }

    //         }

    //         return this.setState({ allParamsFilled: true })
    //     }
    //     return false
    // }

    renotify = () => {

        const chat = this.props.activeChat
        const queue_name = this.props.accountcode + '_' + chat.queueCode
        const channel = chat.uniqueid
        const connection = this.props.connections?.find(con => con.queue == queue_name)

        api.post(`api/enterprises/${this.props.enterpriseId}/notifications/renotify/`, {
            notification_pk: chat?.notification_id || this.props.notification_pk,
            template: this.state.selectedTemplate.name,
            parameters: this.state.parameters.map(p => p.value)
        }).then((res) => {
            this.props.handleClose();
            if (res.status === 201 || res.status === 200) {
                if (queue_name && connection)
                    connection.send(JSON.stringify({ message: "NOTIFICATIONS", channel: channel }))
                if ('updateNotify' in window)
                    updateNotify()
                useNotification(
                    'Renotificação enviada',
                    'O cliente foi notificado com sucesso.',
                    'success'
                )
            }
            else if (res.status === 404) {
                useNotification(
                    'Renotificação não enviada',
                    'Notificação não encontrada na base de dados para ser reenviada.',
                    'danger'
                )
            }
            else {
                useNotification(
                    'Renotificação não enviada',
                    'Houve um problema no servidor e a notificação não pôde ser enviada.',
                    'danger'
                )
            }

            throw res;
        }).catch((err) => {
            console.error(err);
        });
    }

    componentWillMount = () => {
        if (getSession().profile.enterprise.abstraction_layer) {
            let number = (this.props.activeChat.where) === 'notification'
                ? this.props.activeChat.sender
                : ('lhc' in this.props.activeChat) 
                    ? this.props.activeChat.lhc.sender 
                    : this.props.activeChat.sender
            if (number)
                number = number.replace(/\D/g, '');
            this.setState({
                numberTemplates   : this.props.templates[number],
                filteredTemplates : this.props.templates[number]
            })
        } else {
            this.setState({ filteredTemplates: this.props.templates })   
        }
    }

    handleFilterChange = (event) => {
        let templates = this.props.templates
        if (getSession().profile.enterprise.abstraction_layer)
            templates = this.state.numberTemplates
        this.setState({
            filteredTemplates: (templates.filter((template) => {
                const body = template.components?.find(each =>
                    each.type == 'BODY')?.text?.toLowerCase().includes(event.target.value.toLowerCase())
                const name = template.name?.toLowerCase().includes(event.target.value.toLowerCase())
                if (event.target.value && (body || name))
                    return true
                else if (event.target.value === "")
                    return true
            }))
        })
    }

    checkStage = () => {

        if (this.state.showParameters) {
            return this.state.allParamsFilled ? this.state.allParamsFilled : ''
        }

        return (this.state.selectedTemplate === null)

    }

    render() {
        return (
            <div>
                <Dialog keepMounted
                    open={this.props.open}
                    onClose={this.props.handleClose}
                    aria-labelledby="customized-dialog-title"
                    className={this.state.showParameters ? 'PreviewTemplateDialog' : null}
                    fullWidth
                    maxWidth="md"
                    disablePortal
                    disableEnforceFocus
                    sx={{ position: 'absolute' }}
                >
                    <DialogTitle id="customized-dialog-title" className={'group-flexbox'}>
                        <span>{this.props.title}</span>
                        <Tooltip title="Fechar" >
                            <Button className="action-button">
                                <CloseIcon onClick={this.props.handleClose} />
                            </Button>
                        </Tooltip>
                    </DialogTitle>
                    <Divider />
                    <DialogContent
                        ref={instance => { this.content = instance; }}
                    >
                        {getSession().profile.enterprise.abstraction_layer && ! this.state.showParameters &&
                            <>
                                <TextField
                                    className={'inputPlaceholder'}
                                    placeholder='Buscar template'
                                    onChange={this.handleFilterChange}
                                    variant="outlined"
                                    size="small"
                                    sx={{ marginTop: '.4rem', marginBottom: '.4rem', backgroundColor: '#EFEFEF' }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    fullWidth
                                />
                                <List sx={{
                                    'overflow'     : 'auto',
                                    'maxHeight'    : '20rem',
                                    'border'       : '1px solid #C4C4C4',
                                    'borderRadius' :  '5px'
                                }}>
                                    {this.state.numberTemplates?.length !== 0 && this.state.filteredTemplates && this.state.filteredTemplates?.length > 0 ?
                                        this.state.filteredTemplates.map((template, i) => (
                                            <ListItem className={this.state.selectedTemplate == template
                                                ? "template-item selected"
                                                : "template-item"}
                                                key={i}
                                            >
                                                <ListItemText primary={template.name}
                                                    secondary={
                                                        (template.components[0] &&
                                                            template.components[0].type) == 'BODY' ?
                                                            template.components[0].text :
                                                            '[MIDIA] ' + template.components[1].text
                                                    }
                                                    onClick={() => { this.setState({ selectedTemplate: template }) }} />
                                            </ListItem>
                                        ))
                                        :
                                        <Box>Não há templates que correspondem a sua busca.</Box>
                                    }
                                </List>
                            </>
                        }
                        {! getSession().profile.enterprise.abstraction_layer && ! this.state.showParameters &&
                            <>
                                <TextField
                                    className={'inputPlaceholder'}
                                    label={"Buscar Templates"}
                                    defaultValue=""
                                    sx={{ margin: '.5rem 1rem' }}
                                    onChange={this.handleFilterChange}
                                    variant="standard"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <List>
                                    {this.state.filteredTemplates && this.state.filteredTemplates?.length > 0 ?
                                        this.state.filteredTemplates.map((template, i) => (
                                            <ListItem className={this.state.selectedTemplate == template
                                                ? "template-item selected"
                                                : "template-item"}
                                                key={i}
                                            >
                                                <ListItemText primary={template.name}
                                                    secondary={
                                                        (template.components[0] &&
                                                            template.components[0].type) == 'BODY' ?
                                                            template.components[0].text :
                                                            '[MIDIA] ' + template.components[1].text
                                                    }
                                                    onClick={() => this.setState({ selectedTemplate: template })} />
                                            </ListItem>
                                        ))
                                        :
                                        <Box>Não há templates que correspondem a sua busca.</Box>
                                    }
                                </List>
                            </>
                        }
                        {this.state.showParameters &&
                            <Box>
                                <ParameterFiller
                                    mediaValues={this.state.selectedTemplate.components}
                                    parameters={this.state.parameters}
                                    submitParameters={this.state.mediaParameters}
                                    setReady={this.allParametersFilled}
                                />
                                {/* {this.state.selectedTemplate.components[0].text}
                                <h6 className="push-top">
                                    Preencha as variáveis do template abaixo:
                                </h6>
                                {this.state.parameters.map((parameter, i) => {
                                    const variableIndex = i+1;
                                    return (
                                        <TextField
                                            size="small"
                                            label={"{{"+variableIndex+"}}"}
                                            fullWidth
                                            type="text"
                                            variant="outlined"
                                            value={parameter.value}
                                            onChange={e => this.handleInputChange(e, i)}
                                            className="input-parameter"
                                        />
                                    )   
                                })} */}
                            </Box>
                        }
                    </DialogContent>
                    <DialogActions style={{ padding: "10px 20px 10px 10px" }}>
                        {this.state.showParameters &&
                            <Button
                                onClick={() => this.setState({
                                    selectedTemplate: null,
                                    showParameters: false,
                                    parameters: [],
                                    mediaParameters: {},
                                })}
                                color="primary"
                            >
                                Voltar
                            </Button>
                        }
                        <Button color="primary" onClick={this.props.handleClose}>
                            Cancelar
                        </Button>
                        {this.props.type === "reactivation" &&
                            <Tooltip title={this.state.allParamsFilled ? this.state.allParamsFilled : ''} placement="top" describeChild={true}>
                                <span>
                                    <LoadingButton
                                        onClick={() => this.submit("reactivation")}
                                        color="primary"
                                        disabled={
                                            this.checkStage()
                                        }
                                        loading={this.state.loading}
                                    >
                                        Reativar
                                    </LoadingButton>
                                </span>
                            </Tooltip>
                        }
                        {this.props.type === "renotification" &&
                            <Tooltip title={this.state.allParamsFilled ? this.state.allParamsFilled : ''} placement="top" describeChild={true}>
                                <span>
                                    <LoadingButton
                                        onClick={() => this.submit("renotification")}
                                        color="primary"
                                        disabled={
                                            this.checkStage()
                                        }
                                        loading={this.state.loading}
                                    >
                                        Enviar notificação
                                    </LoadingButton>
                                </span>
                            </Tooltip>
                        }
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default SendTemplateDialog;
