import React from "react";

import { Grid, TextField, Typography, Box, Button } from "@mui/material";
import { CircularProgress } from '@mui/material';
import Alert from '@mui/material/Alert';

import PasswordInput from "../../Input";

import api from '../../../api';

import "./styles.css";

class LoginForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            typeAlert       : '',
            showAlert       : [],
            loading         : false,
            uid             : '',
            token           : '',
            password        : '',
            passwordConfirm : ''
        }
    }

    setShowAlert = (type, value) => {
        this.setState({ 
            typeAlert: type, 
            showAlert: value 
        });
    }

    onChangePassword = (event) => {
        this.setState({ password: event.target.value });
    }

    onChangePasswordConfirm = (event) => {
        this.setState({ passwordConfirm: event.target.value });
    }

    handleKeyPress = (event) => {
        if(event && event.key === 'Enter'){
            this.submitResetPassword();
        }
    }

    fetchResetPassword = (password) => {
        this.setState({ loading: true });

        fetch('/api/api/users/password/confirm/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                uid      : this.state.uid,
                token    : this.state.token,
                password : password
            })
        }).then((res) => {
            if (res.status == 200) {
                this.setState({ loading: false });
                this.setShowAlert("success", ["Senha alterada com sucesso!"]);

                setInterval(() => { 
                    window.location.href = '/';
                }, 2000);
            }
            throw res;
        }).catch(async (err) => {
            if(err.status !== 200) {
                console.error(err);
                await err.json().then(async (data) => {
                    if (data.password)
                        this.setShowAlert("error", [
                            'Sua senha deve seguir os seguintes critérios:',
                            '1. Ter no mínimo 8 caracteres',
                            '2. Letras maiúsculas e minúsculas',
                            '3. Letras e números',
                            '4. Caracteres especiais (exemplo: #, @, *)'
                        ]);
                    else if (err.status === 400)
                        this.setShowAlert("error", ["Token inválido ou expirado. Solicite novamente por e-mail."]);
                    else
                        this.setShowAlert("error", ["Algo deu errado, tente novamente."]);
                }).catch(async (err) => {
                    this.setShowAlert("error", ["Algo deu errado, tente novamente."]);
                });
                this.setState({ loading: false });
            }
        });
    }

    submitResetPassword = () => {
        const password        = this.state.password;
        const passwordConfirm = this.state.passwordConfirm;

        if (password === passwordConfirm) {
            this.fetchResetPassword(password);

        } else {
            this.setShowAlert("error", ["As senhas não coincidem."]);
        }
    }

    componentWillMount() {
        const href  = window.location.href;
        const uid   = href.split('resetPassword/')[1].split('/')[0];
        const token = href.split('resetPassword/')[1].split('/')[1];

        this.setState({ uid: uid, token: token });
    }

    render() {
        return (
            <Box ml={15} mr={15} className="form-confirm-password">
                <Grid container spacing={2}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Typography className="title" variant="h4">Redefinir sua senha</Typography>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        {this.state.showAlert.length !== 0 &&
                            <Alert severity={this.state.typeAlert} onClose={() => this.setShowAlert("", [])}>
                                {this.state.showAlert.map((message) => (
                                    <Box>{message}</Box>
                                ))}
                            </Alert>
                        }
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <PasswordInput
                            label="Senha"
                            name="password"
                            defaultValue={this.state.password}
                            onChange={this.onChangePassword}
                            error={this.state.password ? "" : "Este campo é obrigatório"}
                            helperText={this.state.password ? "" : "Este campo é obrigatório"}
                            onEnterPress={true}
                            onKeyPress={this.handleKeyPress}
                        />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <PasswordInput
                            label="Confirmar senha"
                            name="passwordConfirm"
                            defaultValue={this.state.passwordConfirm}
                            onChange={this.onChangePasswordConfirm}
                            error={this.state.passwordConfirm ? "" : "Este campo é obrigatório"}
                            helperText={this.state.passwordConfirm ? "" : "Este campo é obrigatório"}
                            onEnterPress={true}
                            onKeyPress={this.handleKeyPress}
                        />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Button disabled={this.state.loading}
                                variant="contained"
                                color="primary"
                                type="submit"
                                onClick={() => this.submitResetPassword()}
                                fontSize="small"
                                fullWidth
                        >
                            {this.state.loading &&
                                <CircularProgress style={{
                                    height: "20px",
                                    width: "20px",
                                    marginRight: "10px"
                                }}/>
                            }
                            Salvar
                        </Button>
                        <Box 
                            className="exit" 
                            onClick={() => window.location.href = '/'}
                        >
                            Sair
                        </Box>
                    </Grid>
                </Grid >
            </Box >
        );
    }
}

export default LoginForm;
