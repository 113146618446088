import React from 'react';

import { TextField } from '@mui/material';

const TextOnlyBody = ({ isMethodRead, defaultValue, disabled, onChangeFn, maxLength }) => {
  return (
    <TextField
      multiline
      rows={isMethodRead ? 15 : 10}
      label="Corpo"
      fullWidth
      type="text"
      variant="outlined"
      defaultValue={defaultValue}
      disabled={disabled}
      onChange={onChangeFn}
      inputProps={{ maxLength: maxLength }}
    />
  );
}

export default TextOnlyBody;
