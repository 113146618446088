import React, {useMemo} from 'react'
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend, Text, ReferenceLine } from 'recharts';
import { Box } from '@mui/material';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

const convert_seconds = (seconds) => {
    if (!seconds) return '00:00:00'
    const converted = dayjs.duration(seconds, 'seconds')
    const days = converted.days()
    if (days > 0)
        return `${days}d ${converted.format('HH:mm:ss')}`
    return converted.format('HH:mm:ss')
} 

const formatData = (data, interval) => {
    
    const pre_ordered_list = Object.entries(data).map(([key, value]) => [dayjs(value.time*1000), { ...value, key: key }]).toSorted((x, y) => x[0] - y[0])
    const actual_date = pre_ordered_list[pre_ordered_list.length-1]
    const ordered_list = pre_ordered_list.filter(([key, ]) => (actual_date ? actual_date[0] : key).subtract(Number(interval), 'hours') <= key)

    return {
        onbot: ordered_list.map(([, value]) => ({ x: value.key, y: value.onbot }))
        , maxonbot: ordered_list.map(([, value]) => ({ x: value.key, y: value.max_onbot }))
        , queue: ordered_list.map(([, value]) => ({ x: value.key, y: value.onqueue }))
        , maxqueue: ordered_list.map(([, value]) => ({ x: value.key, y: value.max_onqueue }))
        , onchat: ordered_list.map(([, value]) => ({ x: value.key, y: value.onchat }))
        , maxonchat: ordered_list.map(([, value]) => ({ x: value.key, y: value.max_onchat }))
        , tma: ordered_list.map(([, value]) => ({ x: value.key, y: value.tma }))
        , maxtma: ordered_list.map(([, value]) => ({ x: value.key, y: value.max_tma }))
        , tme: ordered_list.map(([, value]) => ({ x: value.key, y: value.tme }))
        , maxtme: ordered_list.map(([, value]) => ({ x: value.key, y: value.max_tme }))
        , tmr: ordered_list.map(([, value]) => ({ x: value.key, y: value.tmr }))
        , maxtmr: ordered_list.map(([, value]) => ({ x: value.key, y: value.max_tmr }))
    }
}

const colorPallets = {
    'queue': '#8884d8',
    'maxqueue': '#84acd8',
    'onchat': '#216c22',
    'maxonchat': '#3a6c21',
    'tma': '#c95f50',
    'maxtma': '#c95050',
    'tme': '#96c950',
    'maxtme': '#b4c950',
    'tmr': '#b850c9',
    'maxtmr': '#9050c9',
    'onbot': '#5099c9',
    'maxonbot': '#50bdc9'
}

const AllCharts = ({ data, mode, interval, last_date }) => {

    const formatted_data = useMemo(()=> formatData(data, interval), [data])

    return (
        <Box className='chart-container-grid'>
            <Box className='chart-container queue'>
                <Box sx={{ color: colorPallets.queue }} className='chart-fixed-text'>{formatted_data.queue?.find(e => e.x === last_date)?.y}</Box>
                <ResponsiveContainer width="100%" height="100%">
                    <AreaChart
                        width={500}
                        height={400}
                        data={formatted_data.queue}
                        margin={{
                            top: 0,
                            right: 0,
                            left: 0,
                            bottom: 0,
                        }}
                    >
                        <ReferenceLine x={last_date} stroke="green" />
                        <Legend margin={{ left: -60 }} iconSize={0} verticalAlign="top" align="center" height={36} />
                        <XAxis label='Hora' hide dataKey="x" />
                        <YAxis name='test' allowDecimals={false} mirror={true} />
                        <Tooltip labelFormatter={(v) => `Hora: ${v}`} />
                        <Area strokeWidth={2} name='Chats em fila' type="monotone" dataKey="y" stroke={colorPallets.queue} fill={colorPallets.queue} />
                    </AreaChart>
                </ResponsiveContainer>
            </Box>
            <Box className='chart-container queue'>
                <Box sx={{ color: colorPallets.maxqueue }} className='chart-fixed-text'>{Math.max(...formatted_data.maxqueue.map(e=>e.y))}</Box>
                <ResponsiveContainer width="100%" height="100%">
                    <AreaChart
                        width={500}
                        height={400}
                        data={formatted_data.maxqueue}
                        margin={{
                            top: 0,
                            right: 0,
                            left: 0,
                            bottom: 0,
                        }}
                    >
                        <ReferenceLine x={last_date} stroke="green" />
                        <Legend margin={{ left: 30 }} iconSize={0} verticalAlign="top" align="center" height={36} />
                        <XAxis label='Hora' hide dataKey="x" />
                        <YAxis name='test' allowDecimals={false} mirror={true} />
                        <Tooltip labelFormatter={(v) => `Hora: ${v}`}/>
                        <Area strokeWidth={2}name='Máximo de Chats em fila' type="monotone" dataKey="y" stroke={colorPallets.maxqueue} fill={colorPallets.maxqueue} />
                    </AreaChart>
                </ResponsiveContainer>
            </Box>
            <Box className='chart-container queue'>
                <Box sx={{ color: colorPallets.onchat }} className='chart-fixed-text'>{formatted_data.onchat?.find(e => e.x === last_date)?.y}</Box>
                <ResponsiveContainer width="100%" height="100%">
                    <AreaChart
                        width={500}
                        height={400}
                        data={formatted_data.onchat}
                        margin={{
                            top: 0,
                            right: 0,
                            left: 0,
                            bottom: 0,
                        }}
                    >
                        <ReferenceLine x={last_date} stroke="green" />
                        <Legend margin={{ left: 30 }} iconSize={0} verticalAlign="top" align="center" height={36} />
                        <XAxis label='Hora' hide dataKey="x" />
                        <YAxis name='test' allowDecimals={false} mirror={true} />
                        <Tooltip labelFormatter={(v) => `Hora: ${v}`}/>
                        <Area strokeWidth={2}name='Chats em Atendimento' type="monotone" dataKey="y" stroke={colorPallets.onchat} fill={colorPallets.onchat} />
                    </AreaChart>
                </ResponsiveContainer>
            </Box>
            <Box className='chart-container queue'>
                <Box sx={{ color: colorPallets.maxonchat }} className='chart-fixed-text'>{Math.max(...formatted_data.maxonchat.map(e=>e.y))}</Box>
                <ResponsiveContainer width="100%" height="100%">
                    <AreaChart
                        width={500}
                        height={400}
                        data={formatted_data.maxonchat}
                        margin={{
                            top: 0,
                            right: 0,
                            left: 0,
                            bottom: 0,
                        }}
                    >
                        <ReferenceLine x={last_date} stroke="green" />
                        <Legend margin={{ left: 30 }} iconSize={0} verticalAlign="top" align="center" height={36} />
                        <XAxis label='Hora' hide dataKey="x" />
                        <YAxis name='test' allowDecimals={false} mirror={true} />
                        <Tooltip labelFormatter={(v) => `Hora: ${v}`}/>
                        <Area strokeWidth={2}name='Máximo de chats em Atendimento' type="monotone" dataKey="y" stroke={colorPallets.maxonchat} fill={colorPallets.maxonchat} />
                    </AreaChart>
                </ResponsiveContainer>
            </Box>
            <Box className='chart-container queue'>
                <Box sx={{ color: colorPallets.tma }} className='chart-fixed-text'>{convert_seconds(formatted_data.tma?.find(e => e.x === last_date)?.y)}</Box>
                <ResponsiveContainer width="100%" height="100%">
                    <AreaChart
                        width={500}
                        height={400}
                        data={formatted_data.tma}
                        margin={{
                            top: 0,
                            right: 0,
                            left: 0,
                            bottom: 0,
                        }}
                    >
                        <ReferenceLine x={last_date} stroke="green" />
                        <Legend margin={{ left: 30 }} iconSize={0} verticalAlign="top" align="center" height={36} />
                        <XAxis label='Hora' hide dataKey="x" />
                        <YAxis hide name='test' allowDecimals={false} mirror={true} />
                        <Tooltip labelFormatter={(v) => `Hora: ${v}`} formatter={(value, name, props) => convert_seconds(value)} />
                        <Area strokeWidth={2}name='TMA' type="monotone" dataKey="y" stroke={colorPallets.tma} fill={colorPallets.tma} />
                    </AreaChart>
                </ResponsiveContainer>
            </Box>
            <Box className='chart-container queue'>
                <Box sx={{ color: colorPallets.maxtma }} className='chart-fixed-text'>{convert_seconds(Math.max(...formatted_data.maxtma.map(e=>e.y)))}</Box>
                <ResponsiveContainer width="100%" height="100%">
                    <AreaChart
                        width={500}
                        height={400}
                        data={formatted_data.maxtma}
                        margin={{
                            top: 0,
                            right: 0,
                            left: 0,
                            bottom: 0,
                        }}
                    >
                        <ReferenceLine x={last_date} stroke="green" />
                        <Legend margin={{ left: 30 }} iconSize={0} verticalAlign="top" align="center" height={36} />
                        <XAxis label='Hora' hide dataKey="x" />
                        <YAxis hide name='test' allowDecimals={false} mirror={true} />
                        <Tooltip labelFormatter={(v) => `Hora: ${v}`} formatter={(value, name, props) => convert_seconds(value)}/>
                        <Area strokeWidth={2}name='Maior TMA' type="monotone" dataKey="y" stroke={colorPallets.maxtma} fill={colorPallets.maxtma} />
                    </AreaChart>
                </ResponsiveContainer>
            </Box>
            <Box className='chart-container queue'>
                <Box sx={{ color: colorPallets.tme }} className='chart-fixed-text'>{convert_seconds(formatted_data.tme?.find(e => e.x === last_date)?.y)}</Box>
                <ResponsiveContainer width="100%" height="100%">
                    <AreaChart
                        width={500}
                        height={400}
                        data={formatted_data.tme}
                        margin={{
                            top: 0,
                            right: 0,
                            left: 0,
                            bottom: 0,
                        }}
                    >
                        <ReferenceLine x={last_date} stroke="green" />
                        <Legend margin={{ left: 30 }} iconSize={0} verticalAlign="top" align="center" height={36} />
                        <XAxis label='Hora' hide dataKey="x" />
                        <YAxis hide name='test' allowDecimals={false} mirror={true} />
                        <Tooltip labelFormatter={(v) => `Hora: ${v}`} formatter={(value, name, props) => convert_seconds(value)}/>
                        <Area strokeWidth={2}name='TME' type="monotone" dataKey="y" stroke={colorPallets.tme} fill={colorPallets.tme} />
                    </AreaChart>
                </ResponsiveContainer>
            </Box>
            <Box className='chart-container queue'>
                <Box sx={{ color: colorPallets.maxtme }} className='chart-fixed-text'>{convert_seconds(Math.max(...formatted_data.maxtme.map(e=>e.y)))}</Box>
                <ResponsiveContainer width="100%" height="100%">
                    <AreaChart
                        width={500}
                        height={400}
                        data={formatted_data.maxtme}
                        margin={{
                            top: 0,
                            right: 0,
                            left: 0,
                            bottom: 0,
                        }}
                    >
                        <ReferenceLine x={last_date} stroke="green" />
                        <Legend margin={{ left: 30 }} iconSize={0} verticalAlign="top" align="center" height={36} />
                        <XAxis label='Hora' hide dataKey="x" />
                        <YAxis hide name='test' allowDecimals={false} mirror={true} />
                        <Tooltip labelFormatter={(v) => `Hora: ${v}`}formatter={(value, name, props) => convert_seconds(value)}/>
                        <Area strokeWidth={2}name='Maior TME' type="monotone" dataKey="y" stroke={colorPallets.maxtme} fill={colorPallets.maxtme} />
                    </AreaChart>
                </ResponsiveContainer>
            </Box>
            <Box className='chart-container queue'>
                <Box sx={{ color: colorPallets.tmr }} className='chart-fixed-text'>{convert_seconds(formatted_data.tmr?.find(e => e.x === last_date)?.y)}</Box>
                <ResponsiveContainer width="100%" height="100%">
                    <AreaChart
                        width={500}
                        height={400}
                        data={formatted_data.tmr}
                        margin={{
                            top: 0,
                            right: 0,
                            left: 0,
                            bottom: 0,
                        }}
                    >
                        <ReferenceLine x={last_date} stroke="green" />
                        <Legend margin={{ left: 30 }} iconSize={0} verticalAlign="top" align="center" height={36} />
                        <XAxis label='Hora' hide dataKey="x" />
                        <YAxis hide name='test' allowDecimals={false} mirror={true} />
                        <Tooltip labelFormatter={(v) => `Hora: ${v}`} formatter={(value, name, props) => convert_seconds(value)} />
                        <Area strokeWidth={2}name='TMR' type="monotone" dataKey="y" stroke={colorPallets.tmr} fill={colorPallets.tmr} />
                    </AreaChart>
                </ResponsiveContainer>
            </Box>
            <Box className='chart-container queue'>
                <Box sx={{ color: colorPallets.maxtmr }} className='chart-fixed-text'>{convert_seconds(Math.max(...formatted_data.maxtmr.map(e=>e.y)))}</Box>
                <ResponsiveContainer width="100%" height="100%">
                    <AreaChart
                        width={500}
                        height={400}
                        data={formatted_data.maxtmr}
                        margin={{
                            top: 0,
                            right: 0,
                            left: 0,
                            bottom: 0,
                        }}
                    >
                        <ReferenceLine x={last_date} stroke="green" />
                        <Legend margin={{ left: 30 }} iconSize={0} verticalAlign="top" align="center" height={36} />
                        <XAxis label='Hora' hide dataKey="x" />
                        <YAxis hide name='test' allowDecimals={false} mirror={true} />
                        <Tooltip labelFormatter={(v) => `Hora: ${v}`} formatter={(value, name, props) => convert_seconds(value)} />
                        <Area strokeWidth={2}name='Maior TMR' type="monotone" dataKey="y" stroke={colorPallets.maxtmr} fill={colorPallets.maxtmr} />
                    </AreaChart>
                </ResponsiveContainer>
            </Box>
            {mode === '__all__' &&
                <>
                    <Box className='chart-container queue'>
                        <Box sx={{ color: colorPallets.onbot }} className='chart-fixed-text'>{formatted_data.onbot?.find(e => e.x === last_date)?.y}</Box>
                        <ResponsiveContainer width="100%" height="100%">
                            <AreaChart
                                width={500}
                                height={400}
                                data={formatted_data.onbot}
                                margin={{
                                    top: 0,
                                    right: 0,
                                    left: 0,
                                    bottom: 0,
                                }}
                            >
                                <ReferenceLine x={last_date} stroke="green" />
                                <Legend margin={{ left: 30 }} iconSize={0} verticalAlign="top" align="center" height={36} />
                                <XAxis label='Hora' hide dataKey="x" />
                                <YAxis name='test' allowDecimals={false} mirror={true} />
                                <Tooltip labelFormatter={(v) => `Hora: ${v}`} />
                                <Area strokeWidth={2}name='Chats no bot' type="monotone" dataKey="y" stroke={colorPallets.onbot} fill={colorPallets.onbot} />
                            </AreaChart>
                        </ResponsiveContainer>
                    </Box>
                    <Box className='chart-container queue'>
                        <Box sx={{ color: colorPallets.maxonbot }} className='chart-fixed-text'>{Math.max(...formatted_data.maxonbot.map(e=>e.y))}</Box>
                        <ResponsiveContainer width="100%" height="100%">
                            <AreaChart
                                width={500}
                                height={400}
                                data={formatted_data.maxonbot}
                                margin={{
                                    top: 0,
                                    right: 0,
                                    left: 0,
                                    bottom: 0,
                                }}
                            >
                                <ReferenceLine x={last_date} stroke="green" />
                                <Legend margin={{ left: 30 }} iconSize={0} verticalAlign="top" align="center" height={36} />
                                <XAxis label='Hora' hide dataKey="x" />
                                <YAxis name='test' allowDecimals={false} mirror={true} />
                                <Tooltip labelFormatter={(v) => `Hora: ${v}`}/>
                                <Area strokeWidth={2}name='Máximo de chats no bot' type="monotone" dataKey="y" stroke={colorPallets.maxonbot} fill={colorPallets.maxonbot} />
                            </AreaChart>
                        </ResponsiveContainer>
                    </Box>
                </>
            }
        </Box >
    )
}

export default AllCharts