import React from "react";
import { Switch } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from "prop-types";

const SwitchButton = ({ onClick, checked, onChange, name }) => {

    const useStyles = makeStyles({
        switchBase: {
            color: "gray",
            "&$checked": {
                color: "gray",
            },
            "&$checked + $track": {
                backgroundColor: "gray",
            }
        },
        checked: {
            color: "#21446C",
            "&$checked": {
                color: "#21446C",
            },
            "&$checked + $track": {
                backgroundColor: "#21446C",
            }
        },

        track: {}
    });

    const classes = useStyles();

    return (
        <Switch
            onClick={onClick}
            checked={checked}
            onChange={onChange}
            name={name}
            classes={{
                switchBase: classes.switchBase,
                track: classes.track,
                checked: classes.checked
            }}
        />
    )
}

SwitchButton.propTypes = {
    onClick: PropTypes.func,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    name: PropTypes.string
};

export default SwitchButton;