import React from 'react';

import Frame from '../Frame';

const FrameQueues = ({ gridArea, queues }) => {
  return (
    <Frame gridArea={gridArea} title="Filas">
      {queues}
    </Frame>
  );
}

export default FrameQueues;