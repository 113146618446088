import React, { useState, useEffect, useContext } from 'react'
import { constructorContext } from '..'
import {
    DataGridPro,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    gridClasses,
    LicenseInfo,
    ptBR
} from '@mui/x-data-grid-pro';

import {
    createTheme,
    ThemeProvider,
    StyledEngineProvider
} from '@mui/material/styles';

import { ptBR as corePtBR } from '@mui/material/locale';

import { Box, Tooltip, Button } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import useNotification from '../../../Notification';
import DeleteIcon from '@mui/icons-material/Delete';

import api from '../../../../api';
import ConfirmationDialogCaptcha from '../../../Dialogs/ConfimationDialogCaptcha';

import LoadingButton from '@mui/lab/LoadingButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SearchEditModal from './SearchEditModal';

import { emojiReplaceJSX } from '../../../../utils/emojiDict';
import { stringOperators } from '../../../../utils/filterOperators';

const theme = createTheme({
    palette: {
        primary: { main: '#21446C' },
    },
}, ptBR, corePtBR);

const BuilderSearch = () => {
    const { activeTab, enterpriseId, loading, has_voice, setLoading } = useContext(constructorContext)

    const [rows, setRows] = useState([])
    const [messages, setMessages] = useState([])
    const [deleteModal, setDeleteModal] = useState()
    const [editModal, setEditModal] = useState()

    const getURL = () => {
        let url = `api/enterprises/${enterpriseId}/surveys/`
        url = url + (activeTab == 0 ? 'chat/' : 'voice/')
        return url
    }

    const getRows = async () => {
        setLoading(true)
        const res = await api.get(getURL(), { timeout: 0 })
        setLoading(false)
        if (res.status == 200) {
            setRows(res.data)
            return
        }
    }

    const getMessages = async () => {
        const res = await api.get(`api/enterprises/${enterpriseId}/messages/voice/`, { timeout: 0 })
        if (res.status == 200) {
            setMessages(res.data)
            return
        }
    }

    useEffect(() => {
        getRows()
        if (activeTab == 1)
            getMessages()
    }, [activeTab])

    const submitDelete = async () => {
        let url = `api/enterprises/${enterpriseId}/surveys/`
        url = url + (activeTab == 0 ? 'chat/' : 'voice/') + `${deleteModal.id}/`

        setLoading(true)
        const res = await api.delete(url, { timeout: 0 })
        setLoading(false)

        if (res.status == 204) {

            setDeleteModal()
            getRows()

            useNotification(
                'Sucesso!',
                'Pesquisa deletada com sucesso.',
                'success'
            )
            return
        }
        useNotification(
            'Ops!',
            'Não foi possível excluir a Pesquisa.',
            'danger'
        )
    }


    const CustomToolbar = () => {

        return (
            <GridToolbarContainer className={gridClasses.toolbarContainer}>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <LoadingButton
                    size="small"
                    component="label"
                    startIcon={<AddCircleIcon />}
                    onClick={() => { setEditModal({}) }}
                >
                    Adicionar
                </LoadingButton>
            </GridToolbarContainer>
        );
    }

    const defColumns = () => {
        const columns = []
        columns.push({ field: 'name', headerName: 'Nome', flex: 1, filterOperators: stringOperators })
        columns.push({
            field: 'type', headerName: 'Tipo', flex: 1, filterable: false,
            valueGetter: (params) => {

                if (params.row.min_rating == 1 && params.row.max_rating == 5)
                    return "CSAT"
                else if (params.row.min_rating == 1 && params.row.max_rating == 10)
                    return "NPS"
                else return ""
            }
        })
        if (activeTab == 0) {
            columns.push({
                field: 'initial_mesg', headerName: 'Mensagem inicial', flex: 1, filterable: false, 
                renderCell: (params) => {
                    return (<span>{emojiReplaceJSX(params.value)}</span >)
                }
            })
            columns.push({
                field: 'final_mesg', headerName: 'Mensagem final', flex: 1, filterable: false,
                renderCell: (params) => {
                    return (<span>{emojiReplaceJSX(params.value)}</span >)
                }
            })
        }

        columns.push({
            field: 'action', headerName: 'Ações', filterable: false, sortable: false,
            renderCell: (params) => (
                <>
                    <Tooltip title="Editar" >
                        <Button
                            className="action-button"
                            onClick={() => { setEditModal(params.row) }}
                        >
                            <EditIcon />
                        </Button>
                    </Tooltip>
                    <Tooltip title="Excluir Pesquisa">
                        <Button className="action-button delete"
                            onClick={() => { setDeleteModal(params.row) }}>
                            <DeleteIcon />
                        </Button>
                    </Tooltip>
                </>
            )
        })

        return columns
    }

    return (
        <>

            {editModal ?
                <SearchEditModal
                    data={editModal}
                    setData={setEditModal}
                    getRows={getRows}
                    messages={messages}
                />
                :
                null}
            {deleteModal ?
                <ConfirmationDialogCaptcha open={true}
                    title="Excluir Pesquisa"
                    description={
                        <span>
                            Para confirmar a ação, digite: <span style={{ 'fontWeight': 'bold' }}>{deleteModal.name}</span> no campo abaixo.</span>}
                    handleClose={() => { setDeleteModal() }}
                    handleSubmit={() => { submitDelete() }}
                    captcha={deleteModal.name}
                />

                :
                null
            }

            {activeTab == 0 &&
                <Box className="settings-body-bot">
                    <StyledEngineProvider injectFirst >
                        <ThemeProvider theme={theme}>
                            <DataGridPro
                                pageSize={100}
                                pagination
                                disableSelectionOnClick
                                disableDensitySelector
                                density="compact"
                                columns={defColumns()}
                                rows={rows}
                                components={{
                                    Toolbar: CustomToolbar,
                                }}
                            />
                        </ThemeProvider>
                    </StyledEngineProvider>
                </Box>
            }
            {has_voice && activeTab == 1 &&
                <Box className="settings-body-bot">
                    <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={theme}>
                            <DataGridPro
                                pageSize={100}
                                pagination
                                disableSelectionOnClick
                                disableDensitySelector
                                density="compact"
                                columns={defColumns()}
                                rows={rows}
                                components={{
                                    Toolbar: CustomToolbar,
                                }}
                            />
                        </ThemeProvider>
                    </StyledEngineProvider>
                </Box>
            }
        </>
    )
}

export default BuilderSearch