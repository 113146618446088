import React, { useState, useEffect, useContext, useRef } from 'react'

import { Box } from '@mui/material';
import MessagesArea from "../../Chat/MessagesArea";
import ModalMessagesArea from "../MessagesArea";
import HistoryMessagesDialog from "../../Dialogs/HistoryMessagesDialog";
import api from '../../../api';
import Profile from '../../Chat/Profile';
import useNotification from '../../Notification';
import { QueueSocket } from '../../../routes.js'
import Loading from '../../Loading';
import ChatRightSideBar from '../../Chat/ChatRightSideBar';
import { getSession } from '../../../auth';

const accountcode = getSession()?.profile?.enterprise?.accountcode

const MessageComponent = ({ agentId, chat, getData, onHandleClose, enterpriseId, templates, queues, predefinedMessages, setPredefinedMessages, setHeartbeat, allGroups }) => {

    const { connections } = useContext(QueueSocket)

    const [refresh, updateRefresh] = useState(false)
    const [renderKey, setRenderKey] = useState()
    const [showChatHistory, setShowChatHistory] = useState(false)
    const [chatSocket, setChatSocket] = useState()
    const [profile, setProfile] = useState(false)
    const [contactData, setContactData] = useState()
    const [activeChat, updateActiveChat] = useState()
    const [activeTab, updateActiveTab] = useState()
    const [lastSocketResponse, setLastSocketResponse] = useState()
    const [loading, setLoading] = useState(false)
    const [showChatRightSideBar, setShowChatRightSideBar] = useState(false)
    const [allLabels, setAllLabels] = useState([])
    const [chatLabels, setChatLabels] = useState([])
    const [chatGroups, setChatGroups] = useState([])

    const user = getSession();

    let conversationList = []

    const messageArea = useRef()
    // const [showProfile, setShowProfile] = useState(false)
    const [historyData, setHistoryData] = useState(
        {
            historyChat: null,
            historyMessages: null,
            platform: null,
            uniqueId: null,
            lastDateTime: null
        }
    )
    const setRefresh = () => {
        updateRefresh(p => !p)
    }


    const setReadMessages = (messages) => {
        if (messages.length === 0)
            return;

        // Setting unreadMessages is_read
        const unreadMessagesIds = [];
        messages.forEach((message) => {
            unreadMessagesIds.push(message.id);
        });

        api.post('/api/LHC/messages/set_read/', {
            messages: unreadMessagesIds
        }).then((res) => {
            if (res.status === 200)
                return;
            throw res;
        }).catch((err) => {
            console.error(err);
        });
    }

    const isSameMessage = (message1, message2) => {
        return message1.user_id == message2.user_id &&
            message1.message == message2.message
    }

    const closeWebSocket = () => {
        if (chatSocket) {
            chatSocket.close();
        }
        setChatSocket(null);
    }

    const connectWebSocket = (activeChat) => {

        closeWebSocket(); // Close any existing socket

        let channel = activeChat?.channel

        if (!channel) return; // Avoid opening undefined socket connections

        const hs = window.location.hostname;

        window?.blockChat && window.blockChat(true)

        let socket = new WebSocket(`wss://${hs}/chat/ws/chat/${channel}/`);

        activeChat.socket = socket
        chat.socket = socket

        let keepAlive = null;

        socket.onopen = (e) => {
            window?.blockChat && window.blockChat(false)
            keepAlive = setInterval(() => {
                // If server not responding for more than 30 seconds
                // the connection might have been closed somewhere
                // we need to close and reopen a new websocket connection
                let curTime = new Date().getTime();
                if (lastSocketResponse && curTime - lastSocketResponse > 20000) {
                    socket.close();
                    setRefresh;
                    connectWebSocket();
                    return;
                }

                // Pinging server every 20secs
                socket.send(JSON.stringify({
                    user: '0',
                    message: 'PING'
                }));
            }, 20000);
        }

        socket.onmessage = (e) => {
            const data = JSON.parse(e.data);
            const curDate = new Date();
            const [hour, minutes] = curDate.toTimeString().split(':', 2)
            const curTime = Math.floor(Date.now() / 1000);
            //lastSocketResponse = new Date().getTime()

            let message = {
                message: data.message,
                iso_time: hour + ':' + minutes,
                user_id: parseInt(data.user),
                time: curTime
            };

            if (message.user_id > 0)
                return

            const infinite_scroll_messages = messageArea?.current?.children?.messageArea?.messages
            const normal_messages = messageArea?.current?.children?.state?.messages
            let messages = (infinite_scroll_messages || normal_messages)

            if (!messages) return;

            if (message.user_id === 0) {
                chat.last_user_msg_time = curTime
                activeChat.last_user_msg_time = curTime
            }
            if ('reloadMessageArea' in window) window.reloadMessageArea(messages)

            // Received duplicated message
            // When a message is sent, the request responds and another websocket 
            // comes back with the same message since it's broadcasted to all 
            // other users therefore we should only update the iso_time here
            if (message.user_id == -2) {
                const realData = JSON.parse(message.message)
                message = realData
                message.time = curTime
                message.iso_time = hour + ':' + minutes
                
                if (infinite_scroll_messages){
                    messageArea.current.children.messageArea.handleAddNewMessage(message)
                    return
                }
                const oldMessage = messages.find(each => each.id === message.id)
                if (oldMessage){
                    oldMessage.time = message.time
                    oldMessage.iso_time = message.iso_time
                    messageArea?.current?.children.handleAddMessage()
                    return
                }
            }

            // Received signal from supervisor
            // This is used in order to update supervised messages status
            else if (message.user_id == -3) {
                const realData = JSON.parse(message.message)
                const oldMessage = messages.find(each => each.id == realData.id)
                oldMessage.status = realData.status
                oldMessage.time = curTime
                oldMessage.iso_time = hour + ':' + minutes
                if (infinite_scroll_messages){
                    messageArea.current.children.messageArea.handleAddNewMessage(oldMessage)
                    return
                }
                messageArea?.current?.children.handleAddMessage()
                return
            }

            // Received drop_chat from transfer
            // This is used in order to drop chat from all other users who
            // have it opened, since it has been transferred
            else if (message.user_id == -4) {
                const realData = JSON.parse(message.message)
                if ('chatID' in realData && realData.chatID == activeChat.id) {
                    onHandleClose()
                    useNotification(
                        'Informação',
                        `O chat foi transferido para a fila ${realData.queue} por ${realData.actor}.`,
                        'info'
                    );
                }
                return
            }
            
            // Received drop_chat from hangup
            // This is used in order to drop chat from all other users who
            // have it opened, since it has been hanged up
            else if (message.user_id == -5) {
                const realData = JSON.parse(message.message)
                if ('chatID' in realData && realData.chatID == activeChat.id) {
                    onHandleClose()
                    useNotification(
                        'Informação',
                        `O chat foi finalizado por ${realData.actor}.`,
                        'info'
                    );
                }
                return
            }

            // Received message sent signal
            // This signal is used in order to update the message's channel id
            // to future references as soon as it's sent to the end user
            else if (message.user_id == -6) {
                const messageObj = messages.find(each => each.id === message.message.id)
                messageObj.message_id = message.message.message_id
                if (infinite_scroll_messages)
                    messageArea.current.children.messageArea.handleAddNewMessage(messageObj)
                return
            }

            // Received read signal
            // This signal is used in order to show that a message has been
            // read by the end client
            if (message.user_id == -7) {
                const currMessage = messages.find(each => each.message_id == data.message)
                if (currMessage) {
                    currMessage.is_client_read = true
                    if (infinite_scroll_messages)
                        messageArea.current.children.messageArea.handleAddNewMessage(currMessage)
                }
                return
            }

            // let lastMessage = messages[messages.length - 1];
            // if (!that.isSameMessage(lastMessage, message) && message.message != 'PONG') {
            if (message.message != 'PONG'){
                messageArea?.current?.children.handleAddMessage(message)
            }
                
            // }
        }

        socket.onclose = (e) => {
            window?.blockChat && window.blockChat(false)
            clearInterval(keepAlive);
            setLastSocketResponse(null);
        };

        setChatSocket(socket)
    }

    const setActiveChat = (chat) => {
        if (Object.keys(chat).length === 0 && chatSocket !== null) {
            closeWebSocket();
        } else {
            connectWebSocket(chat);
        }
        updateActiveChat(chat)
    }

    const setActiveTab = (tab) => {
        updateActiveTab(tab)
    }

    const updateMessages = (message, index, status) => {
    }

    const getLabels = async () => {

        await api.get(`api/enterprises/${enterpriseId}/labelchat/`)
            .then((res) => {
                if (res.status === 200) {
                    setAllLabels(res.data)
                }
                if (res.status === 404) {
                    if ('detail' in res.data && res.data.detail == 'Not found.') {
                        useNotification(
                            'Não encontradas!',
                            'Etiquetas não encontradas.',
                            'danger'
                        );
                    }
                }
                return;
            })
            .catch((err) => {
                console.error(err);
            });
    }

    const updateChatGroups = (groups) => {
        setChatGroups(groups)
        setShowChatRightSideBar(false)
    }

    const reloadContact = () => {
        messageArea?.current?.children.reloadContact()
    }

    const buildChatRightSideBar = () => {
        return (
            <ChatRightSideBar
                agentCanUpdateContact={true}
                contactData={contactData}
                enterprise={enterpriseId}
                closeChatRightSideBar={() => setShowChatRightSideBar(false)}
                updateField={setRefresh}
                isManageCalls={true}
                fetchHistoryMessages={(row) => fetchHistoryMessages(row)}
                allLabels={allLabels}
                chatLabels={chatLabels}
                setChatLabels={setChatLabels}
                chat={chat}
                renderLabels={activeTab !== 2}
                reloadContact={reloadContact}
                allGroups={allGroups}
                setChatGroups={updateChatGroups}
            />
        );
    }

    const updateShowChatRightSideBar = (contact) => {
        // Is this really useless ?!
        // It was causing problems with contact's groups appearing even thought the feature is not active
        // chat.contact = contact
        setContactData(contact)
        setShowChatRightSideBar(p => !p)
    }

    const addPredefinedMessage = async (message, category) => {
        let data = null;

        await api.post(`api/users/${user.id}/predefinedmessages/`, {
            message: message,
            category: category
        }).then(async (res) => {
            if (res.status === 201) {
                data = res.data;

                const predefinedMessages = predefinedMessages;
                predefinedMessages.push(res.data);

                useNotification(
                    'Sucesso!',
                    'Mensagem predefinida cadastrada com sucesso.',
                    'success'
                );

                setPredefinedMessages(predefinedMessages)
            }
        }).catch(async (err) => {
            console.error(err);
        });

        return data;
    }


    const updatePredefinedMessage = (message) => {
        api.patch(`api/users/${user.id}/predefinedmessages/${message.id}/`, {
            message: message.message
        }).then((res) => {
            if (res.status === 200) {
                useNotification(
                    'Sucesso!',
                    'Mensagem predefinida alterada com sucesso.',
                    'success'
                );
            }
        }).catch((err) => {
            console.error(err);
        });
    }

    const removePredefinedMessage = (message) => {
        api.delete(`api/users/${user.id}/predefinedmessages/${message.id}/`)
            .then((res) => {
                if (res.status === 204) {
                    const predefinedMessages = predefinedMessages;
                    for (var i = 0; i < predefinedMessages.length; i++) {
                        if (predefinedMessages[i].id == message.id) {
                            predefinedMessages.splice(i, 1);
                        }
                    }
                    setPredefinedMessages(predefinedMessages)
                    useNotification(
                        'Sucesso!',
                        'Mensagem predefinida excluída com sucesso.',
                        'success'
                    );
                }
            })
            .catch((err) => {
                console.error(err);
            });
    }


    const buildMessageArea = () => {
        return (
            <>
                {
                    (showChatHistory && historyData.historyChat) && 
                    <HistoryMessagesDialog
                        activeChat={historyData.historyChat}
                        open={showChatHistory}
                        uniqueId={historyData.uniqueId}
                        nick={historyData?.historyChat?.nick || ''}
                        lastDateTime={historyData.lastDateTime}
                        historyMessages={historyData.historyMessages}
                        platform={historyData.platform}
                        accountcode={accountcode}
                        handleClose={() => { setShowChatHistory(false); setHistoryData({historyChat: null}) }}
                    />}
                <MessagesArea
                    ref={
                        messageArea
                    }
                    activeChat={chat}
                    setActiveChat={setActiveChat}
                    setActiveTab={setActiveTab}
                    agentId={agentId}
                    enterpriseId={enterpriseId}
                    addOngoingChat={() => { setHeartbeat(p => !p) }}
                    addReactivatedChat={() => { setHeartbeat(p => !p) }}
                    removeOngoingChat={() => { setHeartbeat(p => !p) }}
                    predefinedMessages={predefinedMessages}
                    addPredefinedMessage={addPredefinedMessage}
                    updatePredefinedMessage={updatePredefinedMessage}
                    removePredefinedMessage={removePredefinedMessage}
                    showChatRightSideBar={updateShowChatRightSideBar}
                    accountcode={accountcode}
                    showProfile={showProfile}
                    connectWebSocket={() => { connectWebSocket(chat) }}
                    closeWebSocket={closeWebSocket}
                    resetActiveChatProgress={() => { }}
                    enterpriseQueues={queues}
                    contactData={contactData}
                    renderKey={() => { setRenderKey(Math.random()) }}
                    key={renderKey}
                    templates={templates}
                    isManageCalls={true}
                    closeModal={onHandleClose}
                    updateMessages={updateMessages}
                    setReadMessages={setReadMessages}
                    connections={connections}
                    setChatLabels={setChatLabels}
                />
            </>
        );
    }

    const showProfile = (contact) => {
        setProfile(true)
        setContactData(contact)
    }

    const buildProfile = () => {
        return (
            <Profile
                showProfile={profile}
                contactData={contactData}
                closeProfile={() => { setProfile(false) }}
                updateField={() => { setRenderKey(Math.random()) }}
                isManageCalls={true}
                fetchHistoryMessages={(row) => this.fetchHistoryMessages(row)}
                agentCanUpdateContact={true}
            />
        );
    }

    const fetchHistoryMessages = (row) => {
        api.get(`/api/LHC/chats/${row.hash}/`)
            .then((res) => {
                if (res.status == 200) {
                    setShowChatHistory(true)
                    setHistoryData(
                        {
                            historyChat: res.data,
                            historyMessages: res.data.messages,
                            platform: res.data.platform,
                            uniqueId: row.uniqueid,
                            lastDateTime: res.data.time
                        })


                }
                throw res;
            })
            .catch((err) => {
                console.error(err);
            });
    }

    useEffect(async () => {
        if (user.profile.enterprise.infinite_scroll){
            getLabels()
            return
        } 
        setLoading(true)
        const newchat = await getData(chat.where, 0, chat.channel ? chat.channel : chat.hash)

        setLoading(false)
        if (newchat.length > 0) {
            chat.messages = newchat[0].messages
            messageArea?.current?.children.setState({ messages: newchat[0].messages })
        }

    }, [])

    return (
        <Box className="manage-calls">
            <Loading loading={loading} />
            {!loading &&
                <ModalMessagesArea
                    key='fixed-message-area'
                    open={true}
                    handleClose={onHandleClose}
                    buildMessageArea={buildMessageArea}
                    buildProfile={buildChatRightSideBar}
                    showProfile={showChatRightSideBar}
                //key={renderKey}
                />}
        </Box>
    )
}


export default MessageComponent