import React, { useState } from "react";
import PropTypes from 'prop-types';
import { OutlinedInput, InputAdornment, IconButton, FormControl, InputLabel, TextField } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";


const PasswordInput = ({ label, value, onChange, name, error, helperText, onBlur, onEnterPress, onKeyPress }) => {

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <TextField
            required
            fullWidth
            size="small"
            variant="outlined"
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            onKeyPress={onEnterPress ? onKeyPress : null}
            name={name}
            label={label}
            error={error}
            helperText={helperText}
            id="outlined-adornment-password"
            type={showPassword ? 'text' : 'password'}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            size="large">
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        //labelWidth={70}
        />
    );
}

PasswordInput.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    error: PropTypes.bool,
    helperText: PropTypes.any,
    onBlur: PropTypes.string,

};

export default PasswordInput;