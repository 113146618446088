import { createTheme, adaptV4Theme } from '@mui/material/styles';
import { ptBR } from '@mui/x-data-grid';
import { ptBR as corePtBR } from '@mui/material/locale';

const Theme = createTheme(adaptV4Theme({
    ptBR,
    corePtBR,
    palette: {
        neutral: {
            main: 'white',
        },
        common: {
            main: '#fff'
        },
        primary: {
            main: '#21446C',
        },
        secondary: {
            main: '#DFB160',
        },
        success: {
            main: '#5F8963',
        },
        error: {
            main: '#C95F50'
        },
        text: {
            secondary: {
                main: '#fff'

            }
        }


    },
}));

export default Theme;