
import React from 'react';
import { Box, Tooltip } from '@mui/material';
import TooltipIcon from '@mui/icons-material/Help';
import "./styles.css";

class TooltipClass extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Tooltip title={this.props.text} 
                placement={this.props.placement ? this.props.placement : 'bottom'} 
                onClick={this.props.onClick} 
                disableInteractive
            >
                <TooltipIcon 
                    className="tooltip-icon" 
                    style={{ 
                        float: this.props.float ? this.props.float : 'right',
                        marginLeft: this.props.marginLeft ? this.props.marginLeft : null,
                        marginTop: this.props.marginTop ? this.props.marginTop : null,
                        margin: this.props.margin ? this.props.margin: null
                    }}
                />
            </Tooltip>
        );
    }
}

export default TooltipClass;
