import React, { useEffect, useRef, useState } from 'react';

import * as d3 from 'd3';

import Typography from '@mui/material/Typography';

const PieChart = ({ label, data, type, legendPosition = 'bottom' }) => {
  const svgRef = useRef();
  
  const colorMapping = {
    'MSTEAMS'        : '#6264A7',
    'AUTOMATION'     : '#757575',
    'API'            : '#757575',
    'EMAIL'          : 'gray',
    'MESSENGER'      : '#0866ff',
    'INSTAGRAM'      : '#fd6d13',
    'INSTANTCHAT'    : '#74ace1',
    'TELEGRAM'       : '#3390ec',
    'Atendida'       : '#3bae3b',
    'Perdida'        : '#d34c4d',
    'Retida'         : '#3390ec',
    'Transferência'  : '#f78f33',
    'ERRO'           : '#d34c4d',
    'NÃO ENVIADA'    : '#f78f33',
    'SUCESSO'        : '#3bae3b',
    'PENDENTE'       : '#3390ec',
    'RESPONDIDA'     : '#3bae3b',
    'VISUALIZADA'    : '#3390ec',
    'NÃO RESPONDIDA' : '#f78f33',
    'WHATSAPP'       : '#38b127',
  }

  const [total, setTotal] = useState(0)

  useEffect(() => {
    let counter = 0
    data.forEach((row) => {
      counter += row.value
    })
    setTotal(counter)
  }, [data])

  useEffect(() => {

    if (data.length === 0)
      return

    const svg = d3.select(svgRef.current);

    svg.selectAll('*').remove();

    const width = parseInt(svg.style('width'));
    const height = parseInt(svg.style('height'));
    const radius = Math.min(width, height) / 2;

    svg.attr('viewBox', `0 0 ${width} ${height}`)
      .attr('preserveAspectRatio', 'xMidYMid meet');

    const mainGroup = svg.append('g')
      .attr('transform', `translate(${width / 2}, ${height / 2})`);

    const pie = d3.pie().value(d => d.value);
    const arc = d3.arc().innerRadius(0).outerRadius(radius);

    const color = d3.scaleOrdinal(d3.schemeCategory10);

    const pieData = pie(data);
    mainGroup.selectAll('path')
      .data(pieData)
      .enter()
      .append('path')
      .attr('d', arc)
      .attr('fill', (d, i) => colorMapping[d.data.label] || color(i))
      .attr('stroke', 'white')
      .attr('stroke-width', 1);
  }, [data]);

  return (data.length !== 0 && 
    (
      <div style={{ display: 'flex', flexDirection: (legendPosition === 'bottom') ? 'column' : 'row' }}>
        <div style={{ width: '100%', height: '100%', padding: '2rem' }}>
          <Typography variant="subtitle1">{label}</Typography>
          <svg ref={svgRef} style={{ width: '100%', height: '100%' }}></svg>
        </div>
        <div style={{ display: 'flex', flexDirection: (legendPosition === 'bottom') ? 'row' : 'column', justifyContent: 'center', marginTop: (legendPosition === 'bottom') ? '.5rem' : '3rem' }}>
          {data.map((d, i) => (
            <div key={i} style={{ display: 'flex', alignItems: (legendPosition === 'bottom') ? 'center' : 'flex-start', margin: '0 .25rem' }}>
              {d.icon &&
                <>
                  <div className={`graph-overlay ${d.label}`} style={{ margin: '0 .25rem -.25rem .25rem', fill: colorMapping[d.label] }}>{d.icon}</div>
                  <span style={{ whiteSpace: 'nowrap', fontSize: "90%" }}>{type === "number" ? d.value : `${((d.value / total) * 100).toFixed(1)}%`}</span>
                </>
              }
              {! d.icon &&
                <>
                  <div style={{
                    width: '20px',
                    height: '20px',
                    backgroundColor: colorMapping[d.label] || d3.schemeCategory10[i],
                    margin: (legendPosition === 'bottom') ? '0 .5rem 0 0' : '0 .5rem 1rem 0',
                  }}></div>
                  <span style={{ whiteSpace: 'nowrap', fontSize: "90%" }}>{type === "number" ? `${d.label} (${d.value})` : `${d.label} (${((d.value / total) * 100).toFixed(1)}%)`}</span>
                </>
              }
            </div>
          ))}
        </div>
      </div>
    )
  );
};

export default PieChart;
