import React, { useEffect, useReducer, useMemo } from 'react'
import { lockedReducer } from '../../../../utils/defaultReducer'
import { Box, FormControl, Select, MenuItem, InputLabel } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import AbcIcon from '@mui/icons-material/Abc'
import Loading from '../../../Loading'
import api from '../../../../api'
import { getSession } from '../../../../auth'

import './styles.css'
import ChatsAnswered from './Views/chats_answered'
import ChatsChannel from './Views/chats_channel'
import AnsweredLost from './Views/answered_lost'
import ChatsQualification from './Views/chats_qualification'
import CsatNps from './Views/csat_nps'
import ActiveMessages from './Views/active_messages'

import useNotification from '../../../Notification'
import Tags from './Views/tags'
import Labels from './Views/labels'

const options = {agent: 'Operador', queue: 'Fila'}

export const graph_menus = (data, option, filters, periodText) => {
  return {
    chats_answered: {
      name: 'Conversas atendidas',
      icon: <AbcIcon />,
      component: <ChatsAnswered data={data} option={option} />,
      options: true
    },
    chats_channel: {
      name: 'Conversas por canal',
      icon: <AbcIcon />,
      component: <ChatsChannel data={data} option={option} periodText={periodText} />
    },
    answered_lost: {
      name: 'Conversas totais',
      icon: <AbcIcon />,
      component: <AnsweredLost data={data} option={option} periodText={periodText} />,
      options: true
    },
    chats_qualification: {
      name: 'Qualidade do atendimento',
      icon: <AbcIcon />,
      component: <ChatsQualification data={data} option={option} periodText={periodText} />,
    },
    active_messages: {
      name: 'Disparos ativos',
      icon: <AbcIcon />,
      component: <ActiveMessages data={data} option={option} filters={filters} periodText={periodText} />
    },
    csat_nps: {
      name: 'Pesquisa de satisfação',
      icon: <AbcIcon />,
      component: <CsatNps data={data} option={option} filters={filters} periodText={periodText} />,
      options: true
    },
    tags: {
      name: 'Tags',
      icon: <AbcIcon />,
      component: <Tags data={data} option={option} periodText={periodText} />,
      options: true
    },
    labels: {
      name: 'Etiquetas',
      icon: <AbcIcon />,
      component: <Labels data={data} option={option} />,
      options: true
    }
  }
}

const getGraph = async (filters, graph, option, setState) => {
  const enterpriseId = getSession().profile.enterprise.id
  const url = `api/enterprises/${enterpriseId}/reports/graphs/`
  setState({ loading: true })
  const res = await api.post(url, {...filters, graph: graph, option:option}, { timeout: 10000 })
  let result = {rows: []}
  if (res.status === 200) {
    result = res.data
  }
  else if (res.status === 400){
    if (res.data === "[ErrorDetail(string='Range grater than 31', code='invalid')]"){
      useNotification(
        'Ops!',
        'Não é possível filtrar um intervalo maior que 31 dias.',
        'danger'
      )
    }
  }
  else{
    useNotification(
      'Ops!',
      'Erro ao calcular relatório.',
      'danger'
    )
  }
  setState({
    lastFilters: filters,
    selectedGraph: graph,
    loading: false,
    data: result
  })
  if ('showGraph' in window)
      window.showGraph(result, graph, option, filters)
}

const GraphsViewV2 = ({ setGraph = () => {}}) => {
  const [state, setState] = useReducer(lockedReducer, {
    data: {},
    selectedGraph: Object.keys(graph_menus())[0],
    lastFilters: {},
    loading: false,
    option: 'agent'
  })

  useEffect(() => {
    setGraph((filters)=> getGraph(filters, state.selectedGraph, state.option, setState))
  }, [state.selectedGraph, state.option])

  const graphMenus = useMemo( () => graph_menus(state.data, state.option), [state.data, state.option])

  const graph_list = useMemo(
    () => Object.entries(graphMenus),
    [graphMenus]
  )

  return (
    <Box>
      <Loading loading={state.loading} />
      <Box className='graph-report-list'>
      <FormControl size='small' sx={{ minWidth: '15rem' }}>
          <InputLabel id='report-graph'>Gráfico</InputLabel>
          <Select
            labelId='report-graph'
            id='report-graph'
            value={state.selectedGraph}
            label='Gráfico'
            onChange={e => setState({ selectedGraph: e.target.value })}
          >
            {graph_list.map(([key, value]) => (
              <MenuItem value={key}>{value.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        {graphMenus[state.selectedGraph]?.options && 
        <FormControl size='small' sx={{ minWidth: '6rem' }}>
        <InputLabel id='report-graph-options'>Agregar</InputLabel>
        <Select
          labelId='report-graph-options'
          id='report-graph-options'
          value={state.option}
          label='Agregar'
          onChange={e => setState({ option: e.target.value })}
        >
          {Object.entries(options).map(([key, value]) => (
            <MenuItem value={key}>{value}</MenuItem>
          ))}
        </Select>
      </FormControl>}

      </Box>
    </Box>
  )
}

export default GraphsViewV2
