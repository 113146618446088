import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: "#5399DB",
    minHeight: "100vh"
  },
  logo: {
    width: "20vw",
    cursor:"pointer"
  },
  headerImage: {
    width: "25vw"
  },
  subtitle: {
    color: "white",
  },
  button: {
    backgroundColor: "#DFB160",
    color: "#263238",
    display: "none",

    '&:hover': {
      backgroundColor: 'white',
    },
  }
}));

