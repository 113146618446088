import React from "react";
import MiniDrawer from "../../components/Drawer"
import ReportGrid from "../../components/Report"

const Chat = (socket) => {
    return (
        <MiniDrawer child={<ReportGrid />} socket={socket.socket} />
    );
};

export default Chat;
