import {
    getGridBooleanOperators,
    getGridDateOperators,
    getGridSingleSelectOperators,
    getGridStringOperators,
    getGridNumericOperators
} from '@mui/x-data-grid-pro'

export const stringOperators = getGridStringOperators().filter(
    (operator) => ['contains', 'equals'].includes(operator.value)
)
export const dateOperators = getGridDateOperators().filter(
    (operator) => ['after', 'before'].includes(operator.value)
)
export const booleanOperators = getGridBooleanOperators()
export const choiceOperators = getGridSingleSelectOperators().filter(
    (operator) => ['is', 'not'].includes(operator.value)
)
export const numericOperators = getGridNumericOperators().filter(
    (operator) => ['=', '>', '>=', '<', '<='].includes(operator.value)
)
numericOperators.map(each => { each.value = each.value.replace('=', '|') })
