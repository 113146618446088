import React from 'react';

import './styles.css';

import { Dialog, DialogTitle, DialogContent, Divider } from '@mui/material';
import { DialogActions } from '@mui/material';
import { TextField } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import api from '../../../../api';
import useNotification from '../../../Notification';


class AddClientGroupForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            name: "",
            description: "",
            loading          : false
        }
    }

    submit = () => {
        this.props.parent.getClients()
        this.createClientGroup();
    }

    createClientGroup = () => {
        const selectionIds = this.props.selectionIds;

        const body = {
            'name': this.state.name,
            'description': this.state.description,
            'clients': selectionIds
        };

        this.postClientGroup(body);
    }

    postClientGroup = (body) => {
        this.setState({ loading: true });

        api.post(`api/enterprises/${this.props.enterpriseId}/contact_group/`, body)
        .then(async (res) => {
            await this.setState({ loading: false });
            if (res.status === 201) {
                await this.props.handleClose();
                useNotification(
                    'Sucesso!',
                    'Segmento de clientes criado.',
                    'success'
                );
                return;
            }
            throw res;
        })
    }

    changeClientGroup = () => {
        
        let name = '';
        let description = '';

        if (this.props.model) {
            name = this.props.model.name;
            description = this.props.model.description;
        };

        this.setState({
            name: name,
            description: description
        });
    }

    render() {
        return (
            <>
                <Dialog keepMounted
                    open={this.props.open}
                    onClose={this.props.handleClose}
                    aria-labelledby="customized-dialog-title"
                    disablePortal
                    disableEnforceFocus
                    sx={{position:'absolute'}}
                >
                    <DialogTitle id="customized-dialog-title">
                        Criar novo segmento de clientes
                    </DialogTitle>
                    <Divider />
                    <DialogContent 
                        ref={instance => {this.content = instance;}}
                    >
                        <TextField
                            size="small"
                            label="Nome"
                            fullWidth
                            type="text"
                            variant="outlined"
                            value={this.state.name}
                            onChange={(event) => this.setState({
                                name: event.target.value
                            })}
                        />
                        <TextField
                            size="small"
                            label="Descrição"
                            fullWidth
                            type="text"
                            variant="outlined"
                            className="push-top"
                            value={this.state.description}
                            onChange={(event) => this.setState({
                                description: event.target.value
                            })}
                        />
                    </DialogContent>
                    <DialogActions style={{padding:"10px 20px 10px 10px"}}>
                        <LoadingButton
                            onClick={() => this.submit()}
                            color="primary"
                            loading={this.state.loading}
                        >
                            Enviar
                        </LoadingButton>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}

export default AddClientGroupForm;
