import React, { useEffect, useReducer } from 'react';
import {
  Dialog, DialogContent, DialogTitle, Button, Box, IconButton, List,
  ListItemButton, ListItem, ListItemAvatar, ListItemText, Avatar
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import api from '../../../../api';
import { getSession } from '../../../../auth';
import { lockedReducer } from '../../../../utils/defaultReducer';
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  ptBR,
} from '@mui/x-data-grid-pro';
import { ptBR as corePtBR } from '@mui/material/locale';
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles';
import { FilterComponent } from '../ChatList';
import { groupIcons } from '../ChatList';
import ConfirmationDialog from '../../../Dialogs/Confirmation';

const theme = createTheme(
  {
    palette: {
      primary: { main: "#21446C" },
    },
  },
  ptBR,
  corePtBR
);


const getData = async (data, setState, enterprise, setLoading) => {
  setLoading(true)
  const [settings, contacts, 
    // groups
  ] = await Promise.all([api.get(
    `api/enterprises/${enterprise.id}/scout_accounts/${data.id}/get_settings/`
  ), api.get(
    `api/enterprises/${enterprise.id}/scout_accounts/${data.id}/get_contacts/`
  ),
  // api.get(
  //   `api/enterprises/${enterprise.id}/scout_accounts/${data.id}/get_groups/`, {timeout:30000}
  // )
])

  if (settings.status === 200) {
    const rows = settings.data.ignore_list.map((row, index) => ({
      id: row,
    }));
    const contacts_merged = (contacts.status === 200 ? contacts.data : [])
    //contacts_merged.push(...(groups.status === 200 ? groups.data : []))
    setState({
      rows: rows, contacts: contacts_merged.map(each => ({
        id: each.id,
        phone_num: each.id.split('@')[0],
        profilePictureUrl: each.pictureUrl || each.profilePictureUrl,
        name: each.pushName || each.subject || each.id.split('@')[0] || '',
        type: each.id.includes('@g.us') ? 'GROUP' : 'CONTACT'
      }))
    });
    setLoading(false)
  }
};

const addIgnored = async (rows, enterprise, data, contact, setState) => {
  const addPayload = {
    event: "add_ignore",
    contact: contact.id,
  }
  const ignore_list = [...rows, contact]
  api.post(`api/enterprises/${enterprise.id}/scout_accounts/${data.id}/log_ignore_list/`, addPayload);
  await api.post(`api/enterprises/${enterprise.id}/scout_accounts/${data.id}/set_ignore_list/`, ignore_list.map(each => each.id));
  setState({ confirmation: null, rows: ignore_list })
}

const removeIgnored = async (rows, enterprise, data, contact, setState) => {
  const deletePayload = {
    event: "remove_ignore",
    contact: contact.id,
  }
  const ignore_list = rows.filter(row => row.id !== contact.id)
  api.post(`api/enterprises/${enterprise.id}/scout_accounts/${data.id}/log_ignore_list/`, deletePayload);
  await api.post(`api/enterprises/${enterprise.id}/scout_accounts/${data.id}/set_ignore_list/`, ignore_list.map(each => each.id));
  setState({ confirmation: null, rows: ignore_list })
}

const IgnoreList = ({ data, handleClose, setLoading }) => {
  const enterprise = getSession().profile.enterprise;
  const timezone = enterprise.settings.timezone;

  const [state, setState] = useReducer(lockedReducer, {
    rows: [],
    contacts: [],
    filter: '',
    confirmation: null
  });

  useEffect(() => {
    getData(data, setState, enterprise, setLoading);
  }, []);

  return (
    <>
      <Dialog
        open={true}
        fullWidth
        disablePortal
        disableEnforceFocus
        maxWidth='xs'
        sx={{ position: "absolute" }}
      >
        <DialogTitle className="group-flexbox">
          Lista de Exclusão
          <Button sx={{ minWidth: "2rem", padding: "0" }} onClick={handleClose}>
            <CloseIcon />
          </Button>
        </DialogTitle>
        <DialogContent>
          <Box>
            <FilterComponent updateFilter={(e) => setState({ filter: e })} alternative />
          </Box>
          <List>
            {state.contacts.filter(contact => contact.name?.toLocaleLowerCase().includes(state.filter.toLocaleLowerCase())
              || contact.phone_num?.includes(state.filter)).map(contact => (
                <ListItemButton
                  key={`contact-item-main-id-${contact.id}`}
                  disableGutters
                  className={`scout-contact-item ${state.rows.find(row => row.id === contact.id) ? 'deny' : ''}`}
                  onClick={() => {
                    const action = state.rows.find(row => row.id === contact.id);
                    setState({
                      confirmation: {
                        ...contact, action: action,
                        callback: () => {
                          action ?
                            removeIgnored(state.rows, enterprise, data, contact, setState)
                            :
                            addIgnored(state.rows, enterprise, data, contact, setState)
                        }
                      }
                    })
                  }}
                >
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar alt={contact.id} src={contact.profilePictureUrl} />
                    </ListItemAvatar>
                    <ListItemText

                      primary={
                        <Box className={`scout-title-header`}>
                          <span className='icon'>{groupIcons[contact.type]?.icon}</span>
                          <span className='scout-preview-message'>{contact.name}</span>
                        </Box>
                      }
                      secondary={
                        contact.phone_num
                      }
                    />
                  </ListItem>
                </ListItemButton>
              ))}
          </List>
        </DialogContent>
      </Dialog>
      {state.confirmation && <ConfirmationDialog
        open={true}
        description={`Confirme para ${!state.confirmation.action ? 'ignorar' : 'liberar'} as mensagens do contato ${state.confirmation.name}.`}
        handleClose={() => setState({ confirmation: null })}
        handleSubmit={() => { state.confirmation.callback() }}
        title={`${!state.confirmation.action ? 'Ignorar' : 'Liberar'} ${state.confirmation.name}`}
      />}
    </>
  );
};

export default IgnoreList;
