import React, { useState, useEffect } from 'react'

import { Box, Button } from '@mui/material'
import Loading from '../../Loading'
import useNotification from '../../Notification'
import { getSession } from '../../../auth'
import api from '../../../api'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import {
    DataGridPro,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    ptBR,
    getGridStringOperators
} from '@mui/x-data-grid-pro';

import { ptBR as corePtBR } from '@mui/material/locale';
import ConfirmationDialog from '../../Dialogs/Confirmation'
import useMediaQuery from '@mui/material/useMediaQuery';

import LoadingButton from '@mui/lab/LoadingButton'

import EditLabelModal from './EditLabelModal'
import AddLAddLabelDialogabel from './AddLabelDialog'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddLabelDialog from './AddLabelDialog'

import { stringOperators } from "../../../utils/filterOperators";

const Labels = () => {
    const [labelList, setLabelList] = useState([])
    const [selectedLabels, updateSelectedLabels] = useState([])
    const [loading, setLoading] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showEditModal, setShowEditModal] = useState(false)
    const [massDeleteClientDialog, updateMassDeleteClientDialog] = useState(false)
    const [showAddLabelDialog, setShowAddLabelDialog] = useState(false)
    const [selectedRow, setSelectedRow] = useState(null)
    const [refresh, setRefresh] = useState(false)

    const reloadState = () => { setRefresh(p => !p) }

    const user = getSession()
    const isSmallScreen = useMediaQuery('(max-width: 60rem)');

    const getLabels = async () => {
        setLoading(true)
        const url = `api/enterprises/${user.profile.enterprise.id}/labelchat/`
        const res = await api.get(url)

        if (res.status == 200) {
            setLabelList(res.data)
        }
        else {
            useNotification(
                'Ops!',
                'Ocorreu um erro ao carregar as etiquetas.',
                'danger'
            );
        }

        setLoading(false)
    }

    const handleEdit = (row) => {
        setShowEditModal(true)
        setSelectedRow(row)
    }

    const handleDelete = (row) => {
        setShowDeleteModal(true)
        setSelectedRow(row)
    }

    const editLabel = async (data) => {
        setLoading(true)
        const url = `api/enterprises/${user.profile.enterprise.id}/labelchat/${selectedRow.id}/`
        const res = await api.patch(url, data)

        if (res.status == 200) {
            useNotification(
                'Sucesso!',
                'Etiqueta atualizada!',
                'success'
            );

            setLabelList(prev => {
                const foundrow = prev.find(each => each.id == selectedRow.id)
                foundrow.name = res.data.name
                foundrow.color= res.data.color
                return prev
            })
            setSelectedRow(null)
        }
        else {
            useNotification(
                'Ops!',
                'Ocorreu um erro ao criar a etiqueta.',
                'danger'
            );
        }
        setShowEditModal(false)
        setLoading(false)
    }

    const addLabel = async (data) => {
        setLoading(true)
        const url = `api/enterprises/${user.profile.enterprise.id}/labelchat/`
        const res = await api.post(url, data)

        if (res.status == 201) {
            useNotification(
                'Sucesso!',
                'Etiqueta criada!',
                'success'
            );

            setLabelList(prev => [res.data, ...prev])
            setSelectedRow(null)
        }
        else {
            useNotification(
                'Ops!',
                'Ocorreu um erro ao criar a etiqueta.',
                'danger'
            );
        }
        setShowAddLabelDialog(false)
        setLoading(false)
    }

    const submitDelete = async () => {
        setLoading(true)
        const url = `api/enterprises/${user.profile.enterprise.id}/labelchat/${selectedRow.id}/`
        const res = await api.delete(url)

        if (res.status == 204) {
            useNotification(
                'Sucesso!',
                'Etiqueta deletada!',
                'success'
            );

            setLabelList(prev => prev.filter(each => each.id != selectedRow.id))
            setSelectedRow({})
        }
        else {
            useNotification(
                'Ops!',
                'Ocorreu um erro ao deletar a etiqueta.',
                'danger'
            );
        }
        setShowDeleteModal(false)
        setLoading(false)
    }

    const submitMassDelete = async () => {
        setLoading(true)
        const url = `api/enterprises/${user.profile.enterprise.id}/labelchat/bulk_delete/`
        const res = await api.post(url, { labels_ids: selectedLabels })

        if (res.status == 204) {
            useNotification(
                'Sucesso!',
                'Etiquetas deletadas!',
                'success'
            );

            setLabelList(prev => prev.filter(each => !selectedLabels.includes(each.id)))
        }
        else {
            useNotification(
                'Ops!',
                'Ocorreu um erro ao deletar as etiquetas.',
                'danger'
            );
        }
        updateMassDeleteClientDialog(false)
        setLoading(false)
    }


    const theme = createTheme({
        palette: {
            primary: { main: '#21446C' },
        },
    }, ptBR, corePtBR);

    const initializeColumns = () => {
        const thisColumns = (
            [
                { field: 'name', headerName: 'Nome', flex: 1, filterOperators: stringOperators },
                {
                    field: 'color', headerName: 'Cor', flex: 1, filterable: false,
                    renderCell: (params) => (
                        <Box
                            className={`color-picker-input cursor-unset`}
                            sx={{ 'backgroundColor': params.value  }}
                        >
                        </Box>
                    )
                },
                {
                    field: 'actions', headerName: 'Ações', filterable: false, sortable: false,
                    renderCell: (params) => (
                        <Box>
                            <Button className="action-button"
                                onClick={() => handleEdit(params.row)}>
                                <EditIcon />
                            </Button>
                            <Button className="action-button delete"
                                onClick={() => handleDelete(params.row)}>
                                <DeleteIcon />
                            </Button>
                        </Box>
                    )
                },
            ]
        )
        return thisColumns
    }


    const CustomToolbar = () => {
        return (
            <GridToolbarContainer className='toolbarWrapper'>
                <Box className='toolbarLeft'>
                    <GridToolbarColumnsButton size="small"/>
                    <GridToolbarFilterButton size="small"/>
                    <LoadingButton
                        size="small"
                        component="label"
                        startIcon={<AddCircleIcon />}
                        onClick={(e) => { setShowAddLabelDialog(true) }}
                    >
                        Adicionar
                    </LoadingButton>
                </Box>
                <Box className='toolbarRight'>
                    <>
                        <LoadingButton
                            size="small"
                            component="label"
                            startIcon={<DeleteIcon />}
                            loading={false}
                            onClick={() => { updateMassDeleteClientDialog(true) }}
                            disabled={false}
                        >
                            {isSmallScreen ? '' : 'Excluir'}
                        </LoadingButton>
                    </>
                </Box>
            </GridToolbarContainer>
        )
    }

    useEffect(() => {
        getLabels()
    }, [])

    return (
        <>
            <Loading loading={loading} />

            {showAddLabelDialog ?
                <AddLabelDialog
                    submit={addLabel}
                    handleClose={() => { setShowAddLabelDialog(false) }} />
                :
                null}


            {showEditModal ?
                <AddLabelDialog
                    selectedRow={selectedRow}
                    submit={editLabel}
                    handleClose={() => { setShowEditModal(false); setSelectedRow(null)}}      
                />
                : null
            }

            {showDeleteModal ?
                <ConfirmationDialog open={showDeleteModal}
                    title="Confirmação"
                    description={`Deseja deletar a etiqueta ${labelList.find(each => each.id == selectedRow.id)?.name}?`}
                    handleClose={() => { setShowDeleteModal(false); setSelectedRow(null) }}
                    handleSubmit={submitDelete}
                />
                : null}

            {massDeleteClientDialog ?
                <ConfirmationDialog open={massDeleteClientDialog}
                    title="Confirmação"
                    description={`Deseja deletar as etiquetas selecionadas?`}
                    handleClose={() => { updateMassDeleteClientDialog(false); updateSelectedLabels([]) }}
                    handleSubmit={submitMassDelete}
                />
                : null}


            <Box sx={{ width: '100%', height: '100%' }}>
                <ThemeProvider theme={theme}>

                    <DataGridPro
                        columns={initializeColumns()}
                        rows={labelList}
                        disableSelectionOnClick
                        density={'compact'}
                        components={{ Toolbar: CustomToolbar }}
                        checkboxSelection='true'

                        onSelectionModelChange={(newSelectionModel) => {
                            updateSelectedLabels(newSelectionModel);
                        }}
                        selectionModel={selectedLabels}
                    />
                </ThemeProvider>
            </Box>
        </>
    )
}

export default Labels