import React from "react";
import PropTypes from "prop-types";
import { Card, CardContent, Typography } from '@mui/material';

const Contact = ({fullName, title, phone, email, address}) => {
   return (
    <Card sx={{ maxWidth: 400, margin: '0 auto', mt: 4 }}>
      <CardContent>
        <Typography variant="h5" component="div" gutterBottom>
          {fullName}
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          {title}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Telefone: {phone}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Email: {email}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Endereço: {address}
        </Typography>
      </CardContent>
    </Card>
  );
}

Contact.PropTypes = {
    fullName: PropTypes.string,
    title: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    address: PropTypes.string
};

export default Contact;