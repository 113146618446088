import React from 'react'
import { Dialog, Box, Tooltip, Button, DialogActions, DialogContent, DialogTitle, Divider } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import style from './styles.module.css'

const SelectChannel = ({ handleClose, channels, setSelected }) => {

    return (
        <>
            <Dialog open={true}
                disablePortal
                disableEnforceFocus
                onClose={()=>{}}
                sx={{ position: 'absolute' }}
                className='PreviewTemplateDialog'
            >


                <DialogTitle>
                    <span>Selecione qual canal será utilizado</span>
                    <Tooltip title="Fechar" >
                        <Button className="action-button"  >
                            <CloseIcon onClick={handleClose} />
                        </Button>
                    </Tooltip>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <Box className={style.wrapper}>
                        {Object.entries(channels).map(([key, value]) =>
                            <Box className={style.channelbox} onClick={() => setSelected(key)}>
                                <Box>
                                    {value.icon}
                                </Box>
                                <Box>
                                    {key[0].toUpperCase()}{key.slice(1)}
                                </Box>

                            </Box>
                        )}
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default SelectChannel