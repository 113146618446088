import React, { useReducer, useEffect } from 'react'

import ReactDOM from 'react-dom'

import Loading from '../../Loading'
import api from '../../../api'
import useNotification from '../../Notification'
import { getSession } from '../../../auth'
import CircleIcon from '@mui/icons-material/Circle';
import { Button, Box, IconButton, List, Typography, ListItem, FormControlLabel, Switch, Icon, Drawer } from '@mui/material'
import Filter from '../../Filter'
import { alphabeticalSort } from '../../../utils/alphabeticalSort'

import RefreshIcon from '@mui/icons-material/Refresh';
import FilterListIcon from '@mui/icons-material/FilterList';
import Graphs from './graphs'

let interval = 0

const defaultReducer = (state, newstate) => {
    return { ...state, ...newstate }
}

const user = getSession()
const enterpriseId = user?.profile.enterprise.id
const userQueuesId = user?.profile.queues.map(queue => queue.id)

const timeArray = Array.from(Array(24).keys())

const SEC = 1000, MIN = 60 * SEC, HRS = 60 * MIN

const setupColors = (queues) => {
    const colors = ['#5F8963', '#d5ba7d', '#C95F50', '#6A5ACD', '#4169E1', '#CD853F', '#40E0D0',
        '#BA55D3', '#90EE90', '#A52A2A', '#9370DB', '#6B8E23', '#DAA520', '#DB7093', '#BA55D3'];

    const maxColors = Object.keys(queues).length;

    if (maxColors > colors.length) {
        let remainingColors = maxColors - colors.length;

        while (remainingColors) {
            // Add random colors
            colors.push('#' + Math.floor(Math.random() * 0x1000000).toString(16).padStart(6, '0'));

            remainingColors -= 1;
        }
    }

    return colors
}

const calculateTMR = (messages, client) => {

    //Deprecated, is on backend now
    if (!messages)
        return 0

    const messageList = messages.split('\r\n').filter(each => !each.includes('Sistema:'))
    const clientSeparator = `${client}:`
    let TMR = 0
    let tmrAmount = 0
    let clientLastMessage = null

    messageList.map(message => {
        if (message.includes(clientSeparator)) { // Message is from client
            const clientDate = message.match(/\[(.*?)\]/)?.pop()
            clientLastMessage = clientDate ? new Date(clientDate) : null
        }
        else {
            const agentDate = message.match(/\[(.*?)\]/)?.pop() // Message is from agent
            if (clientLastMessage && agentDate) {
                TMR += (new Date(agentDate) - clientLastMessage) / 1000
                tmrAmount += 1
            }

        }
    })

    if (!tmrAmount)
        tmrAmount = 1

    return Number(TMR / tmrAmount)
}


const calculateData = data => {
    const result = { receivingData: false }

    const colors = setupColors(data.queues)

    const calls = Object.values(data.calls)

    //Creating online data
    result.agents_loggedin = data.agents.filter(agent => agent.agentstatus === 'LOGIN').sort((x, y) => alphabeticalSort(x, y, 'name'))
    result.agents_loggedoff = data.agents.filter(agent => agent.agentstatus === 'LOGOFF').sort((x, y) => alphabeticalSort(x, y, 'name'))


    result.queue_names = []

    data.queues.map((queue, index) => {
        result.queue_names.push({ name: queue.name.split('_').pop(), description: queue.description, color: colors[index] })
    })

    result.queue_names = result.queue_names.sort((x, y) => alphabeticalSort(x, y, 'description'))

    //Creating received
    result.completed = 0
    result.notcompleted = 0
    result.runnerup = 0

    result.TMA = 0
    result.TME = 0
    result.TMR = 0
    result.NPS = 0
    result.npsPromoters = 0
    result.npsDetractors = 0
    result.npsNeutros = 0
    result.npsAmount = 0
    result.CSAT = 0
    result.csatValid = 0
    result.csatAmount = 0

    result.channels = {}

    calls.map(call => {
        const survey = call.survey
        delete call.survey
        const analyticCall = Object.values(call)
        const firstCall = analyticCall[0]
        call.roundTime = Number(String(firstCall.time).split(':')[0])
        call.queue_name = firstCall.queue_name
        call.status = firstCall.status
        switch (call.status) {
            case 'Atendida':
                result.completed += 1
                break
            case 'Nao Atendida':
                result.notcompleted += 1
                break
            case 'Desistencia':
                result.runnerup += 1
            default:
                break
        }

        result.TME += analyticCall.map(each => each.wait_duration).reduce((x, y) => x + y, 0)
        result.TMA += analyticCall.map(each => each.queue_duration - each.survey_duration).reduce((x, y) => x + y, 0)
        result.TMR += analyticCall.map(each => each.response_time).reduce((x, y) => x + y, 0) / analyticCall.length

        analyticCall.map(each => {
            if ('platform_entrypoint' in each) {
                if (each.platform_entrypoint in result.channels)
                    result.channels[each.platform_entrypoint] += 1
                else
                    result.channels[each.platform_entrypoint] = 1
            }
        })

        if (survey) {
            switch (survey.type) {
                case 'nps':
                    const nps = survey.answers.filter(each => each.question !== 'comment').map(each => each.value)
                    result.NPS = Number(nps.reduce((x, y) => Number(x) + Number(y), 0))
                    if (result.NPS >= 9)
                        result.npsPromoters += 1
                    else if (result.NPS <= 6)
                        result.npsDetractors += 1
                    else
                        result.npsNeutros += 1

                    result.npsAmount += 1
                    break
                case 'csat':
                    const csat = survey.answers.filter(each => each.question !== 'comment').map(each => each.value)
                    result.CSAT = Number(csat.reduce((x, y) => Number(x) + Number(y), 0))
                    if (result.CSAT >= 4)
                        result.csatValid += 1
                    result.csatAmount += 1
                    break
                default:
                    break
            }
        }
    })

    const divisor = result.completed > 0 ? result.completed : 1
    const multiplier = result.completed === 0 ? 0 : 1

    result.TME = ((result.TME / divisor) * multiplier)
    result.TMA = ((result.TMA / divisor) * multiplier)
    result.TMR = ((result.TMR / divisor) * multiplier)
    //Creating peak
    const timeData = []

    timeArray.map(each => {
        const timeDict = { 'hour': String(each).padStart(2, '0') }
        const timedCalls = calls.filter(call => call.roundTime === each)
        result.queue_names.map(queues => timeDict[queues.name] = timedCalls.filter(call => call.queue_name === queues.name && call.status === 'Atendida').length)
        timeData.push(timeDict)
    })

    result.timeData = timeData

    //NPS Search

    result.npsTotal = result.npsAmount;
    result.npsNeutros = result.npsTotal - (result.npsPromoters + result.npsDetractors);
    result.npsGrade = ((result.npsPromoters / result.npsTotal) * 100) - ((result.npsDetractors / result.npsTotal) * 100);

    result.npsStatus = [{ name: "Promotores", value: result.npsPromoters, fill: "#5F8963" },
    { name: "Detratores", value: result.npsDetractors, fill: "#C95F50" },
    { name: "Neutros", value: result.npsNeutros, fill: "#959595" }];

    // CSAT Search

    result.csatGrade = (result.csatValid / result.csatAmount) * 5;

    result.colorCSAT = result.csatGrade >= 4.0 ? '#5F8963'
        : result.csatGrade >= 3.0 ? '#d5ba7d' : '#C95F50';

    const remaining = 5 - result.csatGrade;

    result.csat = [{ name: "Positivas", value: result.csatGrade, color: result.colorCSAT },
    { name: "Negativas", value: remaining, color: 'white' }];


    // Attendance Status Total and Operators Status Total

    result.attendanceStatusTotal = Math.abs(result.completed) + Math.abs(result.notcompleted + result.runnerup);

    result.attendanceStatus = [{ name: "Realizados", value: result.completed, fill: "#21446C" },
    { name: "Perdidos", value: result.notcompleted + result.runnerup, fill: "#C95F50" }];

    result.operatorsStatusTotal = Number(result.agents_loggedin?.length) + Number(result.agents_loggedoff?.length);

    result.totalAgentsOffline = result.operatorsStatusTotal - Number(result.agents_loggedin?.length)

    result.operatorsStatus = [{ name: "Online", value: Number(result.agents_loggedin?.length), fill: "#5F8963", operators: result.agents_loggedin },
    { name: "Offline", value: Number(result.agents_loggedoff?.length), fill: "#C95F50", operators: result.agents_loggedoff }];


    result.channels = Object.entries(result.channels).filter(([key, value]) => key).map(([key, value], index) => {

        return {
            name: key,
            value: value,
            fill: colors[index]
        }
    })

    return result
}

const NewDash = ({ innerRef, queues }) => {

    const [state, setState] = useReducer(defaultReducer, {
        filter: false,
        ready: false,
        queues: queues,
        receivingData: false,
        timeData: [],
        heartBeat: false,
        queue_names: []
    })

    const getData = async () => {
        if (state.receivingData)
            return
        setState({ receivingData: true })
        const searchString = state.queues.filter(queue => queue.checked).map(queue => `queue=${queue.name.split('_').pop()}`).join('&')
        const res = await api.get(`/api/enterprises/${enterpriseId}/dashboard/?${searchString}`, { timeout: 15000 })

        if (res.status === 200) {
            setState({...calculateData(res.data), receivingData: false, ready: true})
            return 
        }
        else {
            console.error('Data not received', res)
        }

        setState({ receivingData: false,  ready: true })
    }

    useEffect(async () => {
        if (state.queues.length === 0)
            return
        await getData()
        clearTimeout(interval)
        interval = setTimeout(() => setState({ heartBeat: !state.heartBeat }), 30000)
        return () => {
            clearTimeout(interval)
        }
    }, [state.heartBeat, state.queues])

    useEffect(()=>{
        setState({queues: queues})
    }, [queues])

    useEffect(() => {

        innerRef(
            <>
                <IconButton>
                    <CircleIcon sx={{ color: state.receivingData ? '#03ff03' : 'gray' }} />
                </IconButton>
                <IconButton onClick={() => setState({ filter: !state.filter })}>
                    <FilterListIcon sx={{ color: 'gray' }} />
                </IconButton>
            </>
        )

        // const actionContainer = document.getElementById("widgets-container")
        // if (actionContainer) {
        //     const siblingContainer = document.createElement('div');
        //     siblingContainer.id = 'dash-box-2';
        //     actionContainer.insertBefore(siblingContainer, actionContainer.firstChild);
        //     const siblingContent = (
        //         <>
        //             <IconButton>
        //                 <CircleIcon sx={{ color: state.receivingData ? '#03ff03' : 'gray' }} />
        //             </IconButton>
        //             <IconButton onClick={() => setState({ filter: !state.filter })}>
        //                 <FilterListIcon sx={{ color: 'white' }} />
        //             </IconButton>
        //         </>
        //     );
        //     ReactDOM.render(siblingContent, siblingContainer);
        //     return () => {
        //         const elementToRemove = document.getElementById('dash-box-2');
        //         if (elementToRemove)
        //             elementToRemove.parentNode.removeChild(elementToRemove);
        //     };
        // }
    }, [])

    return (
        <>
            <Loading loading={!state.ready} />
            <Drawer anchor={'right'} open={state.filter} onClose={() => setState({ filter: false })}>
                <List className="dash-list">
                    <>
                        <Box className="dash-queues">
                            <Typography align="center"
                                text="Filas de Atendimento"
                                color="darkGray"
                                fontWeight='500'
                                className="group-item"
                            />
                        </Box>
                        {state.queues.length > 0 &&
                            <ListItem key={-1} className="group-item">
                                <FormControlLabel
                                    label="Todas"
                                    control={
                                        <Switch
                                            checked={state.queues.map(each => each.checked).reduce((x, y) => x && y)}
                                            onChange={(e) => { state.queues.map(each => each.checked = e.target.checked); setState({ queues: [...state.queues] }) }}
                                        />
                                    }
                                />
                            </ListItem>}
                        {state.queues.map((queue, index) => (
                            <ListItem key={index} className="group-item">
                                <FormControlLabel
                                    label={queue.description.length <= 13 ? queue.description : String(queue.description).slice(0, 13) + '...'}
                                    control={
                                        <Switch
                                            checked={queue.checked}
                                            onChange={(e) => { queue.checked = e.target.checked; setState({ queues: [...state.queues] }); }}
                                        />
                                    }
                                />
                            </ListItem>
                        ))}
                    </>
                </List>
            </Drawer>
            <Box className="dash-body">
                <Graphs state={state} setState={setState} />
            </Box>
        </>
    )
}


export default NewDash