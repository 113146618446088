import React from 'react'

import { GridToolbarContainer, GridToolbarColumnsButton,
     GridToolbarFilterButton, GridToolbarExport,gridClasses } from '@mui/x-data-grid';
import { ptBR as corePtBR } from '@mui/material/locale';
import {
    createTheme,
} from '@mui/material/styles';
import {
    ptBR
} from '@mui/x-data-grid-pro';
import dayjs from 'dayjs'

const GraphToolbar = () => {

    return (
        <GridToolbarContainer className={gridClasses.toolbarContainer}>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarExport />
        </GridToolbarContainer>
    );

}


export const graph_theme = createTheme({
    palette: {
        primary: { main: '#21446C' },
    },
}, ptBR, corePtBR);

export const convert_time = (params, mode='default') => {
    if (mode === 'default')
        return new Date((params?.value || 0) * 1000).toISOString().substr(11, 8)
    return new Date((params || 0) * 1000).toISOString().substr(11, 8)
}

export const defaultNames = (params) => {
    if (params.value)
        return params.value
    return 'agent_name' !== params.field ? 'Sem fila' : 'Sem operador'
}

export const defaultClassification = (params) => {
    if (params.value)
        return params.value
    if (params.row.classification === 'Atendida')
        return 'Sem pesquisa de satisfação'

}
export default GraphToolbar