import React from 'react';

import './index.css';

import { 
    Dialog, 
    DialogTitle, 
    DialogContent, 
    DialogActions, 
    Toolbar, 
    Divider,
    List, 
    ListItem, 
    ListItemText,
    Box,
    Button,
    Tooltip,
    InputBase,
    IconButton,
    TextField,
    InputAdornment
} from '@mui/material';

import LoadingButton from '@mui/lab/LoadingButton';
import Loading from '../../Loading';

import CloseIcon from '@mui/icons-material/Close'

import SearchIcon from '@mui/icons-material/Search';
import AutorenewIcon from '@mui/icons-material/Autorenew';

import Alert from '@mui/material/Alert';
import api from '../../../api';
import { alphabeticalSort } from '../../../utils/alphabeticalSort';

class TransferDialog extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedQueue: null,
            showAlert: true,
            onlineQueues: [],
            loading: false,
            searchQuery: '', 
        }
    }

    circle = (color = "") => {
        const shapeStyles = { width: 20, height: 20, boxShadow: '1px 1px 6px 0px #0000007a' };
        const shapeCircleStyles = { borderRadius: '50%' };
        const setColor = color ? color : 'primary.main'
        return (
            <Box component="span" sx={{ ...shapeStyles, ...shapeCircleStyles, bgcolor: setColor }} />
        );
    }

    submit = () => {
        this.setState({ showAlert: true });
        this.props.transferChat(this.state.selectedQueue);
    }

    hideAlert = () => {
        this.setState({ showAlert: false });
    }

    getOnlineQueues = async () => {
        const res = await api.get(`/api/enterprises/${this.props.enterpriseId}/users/queue_online_users/`)

        if (res.status == 200) 
            this.setState({ onlineQueues: res.data }) 
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.showAlert !== this.state.showAlert) {
            if (this.state.showAlert)
                this.getOnlineQueues()
        }
    }

    componentWillMount = () => {
        this.getOnlineQueues()
    }

    handleSearchChange = (event) => { 
        this.setState({ searchQuery: event.target.value }); 
    }

    getFilteredQueues = () => { 
        const { searchQuery } = this.state; 
        const { queues } = this.props; 
        return queues.filter(queue => queue.description.toLowerCase().includes(searchQuery.toLowerCase())); 
    }

    render() {
        const filteredQueues = this.getFilteredQueues(); 
        return (
            <div>
                {this.state.loading ? <Loading loading={true} /> : null}
                <Dialog keepMounted
                    open={this.props.open}
                    onClose={this.props.handleClose}
                    aria-labelledby="customized-dialog-title"
                    fullWidth
                    maxWidth="sm"
                    disableEnforceFocus
                >
                    <DialogTitle id="customized-dialog-title" className={'group-flexbox'}>
                        <span>{this.props.title}</span>
                        {this.props.queueName &&
                            <div id="currentQueue">Fila: {this.props.queueName}</div>
                        }
                        <Tooltip title="Fechar" >
                            <Button className="action-button">
                                <CloseIcon onClick={this.props.handleClose} />
                            </Button>
                        </Tooltip>
                    </DialogTitle>
                    <Divider />
                    <DialogContent
                        ref={instance => { this.content = instance; }}
                    >
                        <TextField
                            className={'inputPlaceholder'}
                            placeholder='Buscar fila'
                            onChange={this.handleSearchChange}
                            variant="outlined"
                            size="small"
                            sx={{ marginTop: '.4rem', marginBottom: '.4rem', backgroundColor: '#EFEFEF' }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            fullWidth
                        />
                        <List>
                            {filteredQueues.sort((x, y) => alphabeticalSort(x, y, 'description')).map((queue, i) => { 
                                const isOnline = this.state.onlineQueues.includes(queue.name);
                                return (
                                    <Toolbar key={i} sx={{ padding: 'unset !important' }} title={isOnline ? 'Online' : 'Offline'} >
                                        <ListItem className={this.state.selectedQueue === queue.name
                                            ? "transfer-item selected"
                                            : "transfer-item"}
                                            key={i}
                                        >
                                            <ListItemText primary={queue.description}
                                                secondary={queue.name.split('_')[1]}
                                                onClick={() => this.setState({ selectedQueue: queue.name })} />
                                            {isOnline ?
                                                <>
                                                    {this.circle('#17E917')}
                                                </>
                                                :
                                                <>
                                                    {this.circle('gray')}
                                                </>
                                            }
                                            
                                        </ListItem>
                                    </Toolbar>
                                );
                            })}
                            {filteredQueues.length === 0 && 
                                <Box>Não há filas para transferir</Box>
                            }
                        </List>
                        {this.props.error !== null && this.state.showAlert &&
                            <>
                                <Alert severity="error"
                                    onClose={this.hideAlert}>
                                    {this.props.error}
                                </Alert>
                            </>
                        }
                    </DialogContent>
                    <DialogActions style={{ padding: "10px 20px 10px 10px" }}>
                        <Button color="primary" onClick={this.props.handleClose}>
                            Cancelar
                        </Button>
                        <LoadingButton
                            onClick={() => {
                                this.setState({ loading: true })
                                this.submit()
                            }}
                            color="primary"
                            disabled={this.state.selectedQueue === null}
                            loading={this.props.submitButtonLoading}
                        >
                            Transferir
                        </LoadingButton>
                    </DialogActions>
                </Dialog>
            </div >
        );
    }
}

export default TransferDialog;
