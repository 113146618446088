import React from 'react'

import {
    ListItem,
    Switch,
    FormControlLabel,
    Tooltip
} from "@mui/material"

import {
    gridClasses,
    GridToolbarContainer,
    GridToolbarFilterButton,
    GridToolbarColumnsButton,
    GridToolbarExportContainer,
} from '@mui/x-data-grid-pro';
import LoadingButton from '@mui/lab/LoadingButton';

import SendIcon from '@mui/icons-material/Send';
import CampaignIcon from '@mui/icons-material/Campaign';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

import ReportExportData from './report_export_data'
import AutomatedReportDialog from "../../Dialogs/AutomatedReportDialog";
import Templates from "../../Crm/Templates";

import { getTemplates, getQueues, getNotifyNumbers, generate_scheduled_report } from './detailed_register_functions'
import { SPECIAL_REPORT_NAME } from './detailed_register_main'
import FastFiltersDialog from '../DetailedRegister/FastFiltersDialog';
import { get_report_data } from './detailed_register_functions';
import useNotification from '../../Notification';

//Agendamento de relatório

const filterTableRows = async (filters, parent, setParent) => {
    const rows = []

    if (filters['normal'])
        rows.push({ "columnField": "status", "operatorValue": "is", "id": Math.random(), "value": "Atendida" })
    if (filters['chatbot'])
        rows.push({ "columnField": "status", "operatorValue": "is", "id": Math.random(), "value": "ChatBot" })
    if (filters['timeout'])
        rows.push({ "columnField": "tags", "operatorValue": "equals", "id": Math.random(), "value": "sys_timeout" })
    if (filters['weekend'])
        rows.push({ "columnField": "tags", "operatorValue": "equals", "id": Math.random(), "value": "sys_timeout_fds" })
    if (filters['checktime'])
        rows.push({ "columnField": "tags", "operatorValue": "equals", "id": Math.random(), "value": "sys_fora_hora" })

    const filterState = parent
    const filterModel = { items: rows, linkOperator: 'or' }
    filterState.filterModel = filterModel
    setParent({ filterModel: filterModel, activeDialog: null, loading: true, })
    await get_report_data(filterState)
    setParent({ loading: false })
}


const DetailedRegisterCustomToolbar = (parent, setParent) => {

    const embedData = {
        parent: parent,
        setParent: setParent
    }

    const scheduleReport = async (data) => {
        setParent({ loading: true })
        const res = await generate_scheduled_report(parent, data, data.reportType)
        switch (res) {
            case 200:
                useNotification(
                    'Sucesso!',
                    'Relatório agendado com sucesso.',
                    'success'
                );
                break;
            case 201:
                useNotification(
                    'Sucesso!',
                    'Relatório agendado com sucesso.',
                    'success'
                );
                break;

            case 202:
                useNotification(
                    'Erro!',
                    'Já existe um relatório agendado com este nome',
                    'danger'
                );
                break;

            case 500:
                useNotification(
                    'Erro!',
                    'Já existe um agendamento com esses filtros.',
                    'danger'
                ); 
                break;
            default:
                useNotification(
                    'Erro!',
                    'Erro ao agendar relatório.',
                    'danger'
                );
                break;
        }

        setParent({ loading: false })
        setParent({ 'activeDialog': null })
    }

    return (
        <>
            <GridToolbarContainer className={gridClasses.toolbarContainer} sx={{ justifyContent: 'space-between' }}>
                <span>
                    <GridToolbarColumnsButton />
                    <GridToolbarFilterButton />
                    <>
                        {parent?.selected_report_name?.name === SPECIAL_REPORT_NAME &&
                            <LoadingButton
                                startIcon={<FilterAltIcon />}
                                size="small"
                                onClick={() => {
                                    setParent({
                                        activeDialog: (
                                            <FastFiltersDialog
                                                open={true}
                                                handleClose={() => setParent({ activeDialog: null })}
                                                filterTableRows={(e) => filterTableRows(e, parent, setParent)}
                                            />
                                        )
                                    })
                                }}
                            >
                                Filtros Rápidos
                            </LoadingButton>
                        }
                    </>
                    <GridToolbarExportContainer>
                        <ListItem
                            onClick={() => { setParent({ activeDialog: <ReportExportData type='csv' {...embedData} /> }) }}
                            className={'menuItem'}>
                            Exportar como CSV
                        </ListItem>
                        <ListItem
                            onClick={() => { setParent({ activeDialog: <ReportExportData type='xlsx' {...embedData} /> }) }}
                            className={'menuItem'}>
                            Exportar como XLSX
                        </ListItem>
                    </GridToolbarExportContainer>
                    <>
                        <LoadingButton
                            startIcon={<CampaignIcon />}
                            size="small"
                            onClick={() => {

                                setParent(
                                    {
                                        activeDialog:
                                            <AutomatedReportDialog open={true}
                                                handleClose={() => setParent({ activeDialog: null })}
                                                handleSubmit={scheduleReport}
                                                filters={parent.filterModel.items}
                                            />
                                    })
                            }}
                        >
                            Agendar
                        </LoadingButton>
                    </>
                    {parent?.selected_report_name?.name === SPECIAL_REPORT_NAME &&
                        <>
                            <LoadingButton
                                startIcon={<SendIcon />}
                                size="small"
                                onClick={async () => {
                                    setParent({ loading: true })
                                    const templates = await getTemplates(parent.templates)
                                    setParent({ loading: false })
                                    const queues = getQueues()
                                    const notifyNumbers = getNotifyNumbers()
                                    setParent(
                                        {
                                            templates: templates,
                                            activeDialog:
                                                <Templates
                                                    open={true}
                                                    templates={templates}
                                                    queues={queues}
                                                    clients={[]}
                                                    selectionIds={parent.selectedData}
                                                    originNumbers={notifyNumbers}
                                                    handleClose={() => setParent({ activeDialog: null })}
                                                    handlePostSuccess={() => {
                                                        setParent({ selectedData: [] })
                                                    }}
                                                    selectedNumberModel={parent.selectedData.filter(each => each.platform_entrypoint === 'WHATSAPP').map(each => each.origin)}
                                                />
                                        }
                                    )
                                }}
                            >
                                Notificar
                            </LoadingButton>
                        </>
                    }
                </span>

                {parent?.selected_report_name?.name === SPECIAL_REPORT_NAME &&
                    <span>
                        <Tooltip title='Desagrupar as transferências, será possível utilizar o filtro para encontrar alguma informação pertencente às transferências.'>
                            <FormControlLabel sx={{ marginLeft: '.25rem', margin:0, flexFlow:'row-reverse' }} control={
                                <Switch checked={parent.reportMode === 'alternate'} onClick={async (e) => {
                                    setParent({ loading: true })
                                    let newReportMode = 'standard'
                                    if (parent.reportMode === 'standard')
                                        newReportMode = 'alternate'
                                    localStorage.setItem('reportMode', newReportMode)
                                    parent.reportMode = newReportMode
                                    const report_data = await get_report_data(parent)
                                    setParent({ reportMode: newReportMode, report_data: report_data, loading: false })
                                }} />
                            } label="Desagrupar Transferências" />
                        </Tooltip>
                    </span>
                }

            </GridToolbarContainer>
        </>
    )
}

export default DetailedRegisterCustomToolbar