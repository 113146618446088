import React from 'react';
import PropTypes from "prop-types";
import MessageItem from "../../Chat/MessagesArea/ChatWindow/MessageItem";
import {
  Grid, DialogActions, DialogContent, Box, Tooltip,
  DialogContentText, DialogTitle, Dialog, Button, Slide
} from "@mui/material";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import TelegramIcon from '@mui/icons-material/Telegram';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import MicrosoftIcon from '@mui/icons-material/Groups';
import EmailIcon from '@mui/icons-material/Email';
import { AccessTime, VpnKey, WhatsApp } from '@mui/icons-material';
import AgentIcon from '@mui/icons-material/SupportAgent';
import InstantChatIcon from '@mui/icons-material/Language';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import ApiIcon from '@mui/icons-material/DataObject';

import "./styles.css";

import MessageArea from "../../Chat/MessagesArea/ChatWindow/MessageArea";
import NewMessageArea from '../../Chat/MessagesArea/ChatWindow/NewMessageArea';
import { getSession } from '../../../auth';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const setAgentName = (chat) => {
  return chat.custom_field1_title;
}

class HistoryMessagesDialog extends React.Component {

  constructor(props) {
    super(props);
  }

  platformIcon = () => {
    const platform = this.props.platform;

    switch (platform) {
      case 'INSTANTCHAT':
        return (
          <Tooltip title="Chat Web" placement="bottom" disableInteractive>
            <InstantChatIcon className="instantchat" />
          </Tooltip>
        );
      case 'WHATSAPP':
        return (
          <Tooltip title="WhatsApp" placement="bottom" disableInteractive>
            <WhatsApp className="whatsapp" />
          </Tooltip>
        );
      case 'TELEGRAM':
        return (
          <Tooltip title="Telegram" placement="bottom" disableInteractive>
            <TelegramIcon className="telegram" />
          </Tooltip>
        );
      case 'INSTAGRAM':
        return (
          <Tooltip title="Instagram" placement="bottom" disableInteractive>
            <InstagramIcon className="instagram" />
          </Tooltip>
        );
      case 'MESSENGER':
        return (
          <Tooltip title="Messenger" placement="bottom" disableInteractive>
            <FacebookIcon className="facebook" />
          </Tooltip>
        );
      case 'MSTEAMS':
        return (
          <Tooltip title="Microsoft Teams" placement="bottom" disableInteractive>
            <MicrosoftIcon className="msteams" />
          </Tooltip>
        );
      case 'EMAIL':
        return (
          <Tooltip title="Email" placement="bottom" disableInteractive>
            <EmailIcon className="email" />
          </Tooltip>
        );
      case 'AUTOMATION':
        return (
          <Tooltip title="Automação" placement="bottom" disableInteractive>
            <SmartToyIcon className="email" />
          </Tooltip>
        );
      case 'INTEGRATION':
        return (
          <Tooltip title="Integração" placement="bottom" disableInteractive>
            <IntegrationInstructionsIcon className="integration" />
          </Tooltip>
        );
      case 'API':
        return (
          <Tooltip title="API" placement="bottom" disableInteractive>
            <ApiIcon className="email" />
          </Tooltip>
        );
    }
  }

  render() {
    return (
      <Box>
        <Dialog
          open={this.props.open}
          //TransitionComponent={Transition}
          keepMounted
          onClose={this.props.handleClose}
          aria-describedby="alert-dialog-slide-description"
          fullWidth
          maxWidth="md"
          disablePortal
          disableEnforceFocus
          sx={{ position: 'absolute' }}
        >
          <Box className="history-header">
            <DialogTitle>
              <Grid container>
                <Grid item className="history-telephone">
                  {this.platformIcon()}
                  {' ' + this.props.nick}
                </Grid>
                <Grid item className="history-telephone">
                  <Box>
                    <Tooltip title="Operador" placement="bottom" disableInteractive>
                      <Box className="chatWindow-box-4">
                        <AgentIcon
                          className="agent-icon"
                        />
                        {setAgentName(this.props.activeChat)}
                      </Box>
                    </Tooltip>
                  </Box>
                </Grid>
                <Grid item className="history-date">
                  <Tooltip title="Data e hora inicial" placement="bottom" disableInteractive>
                    <AccessTime className="dateTime" />
                  </Tooltip>
                  {' ' + (new Date(this.props.lastDateTime * 1000)).toLocaleString('PT-br').slice(0, -3)}
                </Grid>
              </Grid>
            </DialogTitle>
          </Box>
          <DialogActions className="actions">
            <IconButton
              edge="start"
              color="inherit"
              onClick={this.props.handleClose}
              aria-label="close"
              size="large">
              <CloseIcon />
            </IconButton>
          </DialogActions>
          {(getSession().profile.enterprise.infinite_scroll || this.props.forceScroll) ? <NewMessageArea

            innerRef={
              instance => {

              }
            }
            chat={this.props.activeChat}
            accountcode={this.props.accountcode}
            enterpriseId={this.props.enterpriseId}
            isManageCalls={true}
            isHistory={true}
          /> :
            <MessageArea messages={this.props.historyMessages}
              accountcode={this.props.accountcode}
              nameArea="messageArea-history"
            />
          }
          <Box className="history-footer">
            <DialogTitle title="unique ID">
              <VpnKey className="key" />{' ' + this.props.uniqueId}
            </DialogTitle>
          </Box>
        </Dialog>
      </Box>
    );
  }
}

export default HistoryMessagesDialog;