import React, { useState, useEffect, useContext } from 'react'
import { constructorContext } from '..'
import {
    DataGridPro,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    gridClasses,
    LicenseInfo,
    ptBR
} from '@mui/x-data-grid-pro';

import {
    createTheme,
    ThemeProvider,
    StyledEngineProvider
} from '@mui/material/styles';

import { ptBR as corePtBR } from '@mui/material/locale';

import { Box, Tooltip, Button } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import useNotification from '../../../Notification';
import DeleteIcon from '@mui/icons-material/Delete';

import api from '../../../../api';
import ConfirmationDialogCaptcha from '../../../Dialogs/ConfimationDialogCaptcha';

import LoadingButton from '@mui/lab/LoadingButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ChecktimeEditModal from './ChecktimeEditModal';

import { stringOperators } from '../../../../utils/filterOperators';

const theme = createTheme({
    palette: {
        primary: { main: '#21446C' },
    },
}, ptBR, corePtBR);


export const weekdays = [
    { 'id': 1, 'value': 'Domingo' },
    { 'id': 2, 'value': 'Segunda' },
    { 'id': 3, 'value': 'Terça'   },
    { 'id': 4, 'value': 'Quarta'  },
    { 'id': 5, 'value': 'Quinta'  },
    { 'id': 6, 'value': 'Sexta'   },
    { 'id': 7, 'value': 'Sábado'  }
]

const BuilderChecktime = () => {
    const { activeTab, enterpriseId, loading, has_voice, setLoading } = useContext(constructorContext)

    const [rows, setRows] = useState([])
    const [deleteModal, setDeleteModal] = useState()
    const [editModal, setEditModal] = useState()


    const getURL = () => {
        let url = `api/enterprises/${enterpriseId}/checktimes/`
        return url
    }

    const getRows = async () => {
        setLoading(true)
        const res = await api.get(getURL(), { timeout: 0 })
        setLoading(false)
        if (res.status == 200) {
            setRows(res.data)
            return
        }
    }

    useEffect(() => {
        getRows()
    }, [])

    const submitDelete = async () => {
        let url = `api/enterprises/${enterpriseId}/checktimes/${deleteModal.id}/`

        setLoading(true)
        const res = await api.delete(url, { timeout: 0 })
        setLoading(false)

        if (res.status == 204) {

            setDeleteModal()
            getRows()

            useNotification(
                'Sucesso!',
                'Horário de atendimento deletada com sucesso.',
                'success'
            )
            return
        }
        useNotification(
            'Ops!',
            'Não foi possível excluir o Horário de atendimento.',
            'danger'
        )
    }

    const CustomToolbar = () => {

        return (
            <GridToolbarContainer className={gridClasses.toolbarContainer}>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <LoadingButton
                    size="small"
                    component="label"
                    startIcon={<AddCircleIcon />}
                    onClick={() => { setEditModal({}) }}
                >
                    Adicionar
                </LoadingButton>
            </GridToolbarContainer>
        );
    }

    const defColumns = [
        { field: 'name', headerName: 'Nome', flex: 1, filterOperators: stringOperators },
        { field: 'description', headerName: 'Descrição', flex: 1, filterOperators: stringOperators },
        {
            field: 'days', headerName: 'Dias', flex: 1, filterable: false,
            valueGetter: (params) => {
                const last_rule = [...params.row.rules].pop()
                const startday = weekdays.find(each => each.id == Number(last_rule?.startdayofweek))?.value
                const endday = weekdays.find(each => each.id == Number(last_rule?.enddayofweek))?.value
                return `De ${startday} até ${endday}`
            }
        },
        {
            field: 'hours', headerName: 'Horários', flex: 1, filterable: false,
            valueGetter: (params) => {
                const last_rule = [...params.row.rules].pop()
                return `De ${last_rule?.starttime} até ${last_rule?.endtime}`
            }
        },
        {
            field: 'action', headerName: 'Ações', filterable: false, sortable: false,
            renderCell: (params) => (
                <>
                    <Tooltip title="Editar" >
                        <Button
                            className="action-button"
                            onClick={() => { setEditModal(params.row) }}
                        >
                            <EditIcon />
                        </Button>
                    </Tooltip>
                    <Tooltip title="Excluir Horário de atendimento">
                        <Button className="action-button delete"
                            onClick={() => { setDeleteModal(params.row) }}>
                            <DeleteIcon />
                        </Button>
                    </Tooltip>
                </>
            ),
        },
    ]

    return (
        <>

            {deleteModal ?
                <ConfirmationDialogCaptcha open={true}
                    title="Excluir Horário de atendimento"
                    description={
                        <span>
                            Para confirmar a ação, digite: <span style={{ 'fontWeight': 'bold' }}>{deleteModal.name}</span> no campo abaixo.</span>}
                    handleClose={() => { setDeleteModal() }}
                    handleSubmit={() => { submitDelete() }}
                    captcha={deleteModal.name}
                />

                :
                null
            }

            {
                editModal ?
                    <ChecktimeEditModal
                        data={editModal}
                        setData={setEditModal}
                        getRows={getRows}
                    />
                    :
                    null
            }


            <Box className="settings-body-bot">
                <StyledEngineProvider injectFirst >
                    <ThemeProvider theme={theme}>
                        <DataGridPro
                            pageSize={100}
                            pagination
                            disableSelectionOnClick
                            disableDensitySelector
                            density="compact"
                            columns={defColumns}
                            rows={rows}
                            components={{
                                Toolbar: CustomToolbar,
                            }}
                        />
                    </ThemeProvider>
                </StyledEngineProvider>
            </Box>

        </>
    )
}

export default BuilderChecktime