import React from 'react';

import Frame from '../Frame';

const FrameEnterprises = ({ gridArea, enterprisesAmount }) => {
  return (
    <Frame gridArea={gridArea} title="Empresas">
      {enterprisesAmount}
    </Frame>
  );
}

export default FrameEnterprises;