import { useEffect, useState } from "react"
import { TextField, Button, Divider, Box, InputLabel, IconButton } from "@mui/material"
import DeleteIcon from '@mui/icons-material/Delete';
import defaultReducer from "../../../../utils/defaultReducer"

export const RenderProvider = ({ parent, setParent }) => {
    const provider = parent.configuration.provider
    const fields = parent.configuration.data.fields

    const [campname, setCampname] = useState('')
    const [state, setState] = useState(defaultReducer, {
        ...fields
    })

    useEffect(() => {
        setState(parent.configuration.data.fields)
    }, [parent.configuration.provider])

    useEffect(() => {
        Object.assign(fields, state)
    }, [state])

    return (<>
        {Object.entries(state).map(([key, value]) => (
            <>
                <Box sx={{ display: 'flex', gap: '1rem', width: '100%' }}>
                    <TextField
                        sx={{ flex: 10 }}
                        size='small'
                        label={value === '' ? `Adicionar chave: ${key}` : key}
                        value={value}
                        onChange={(e) => {
                            state[key] = e.target.value
                            setState({ ...state })
                        }}
                    />
                    {provider === 'other' &&
                        <IconButton
                            className='action-button delete'
                            sx={{ flex: .8 }}
                            onClick={() => {
                                delete state[key]
                                setState({ ...state })
                            }}>
                            <DeleteIcon />
                        </IconButton>
                    }
                </Box>
            </>
        ))}
        {provider === 'other' && <>
            <Divider />
            <InputLabel>Campos customizaveis </InputLabel>
            <Box sx={{ display: 'flex', gap: '1rem', width: '100%' }}>
                <TextField
                    sx={{ flex: 7 }}
                    fullWidth
                    size='small'
                    label='Nome do campo'
                    value={campname}
                    onChange={(e) => setCampname(e.target.value)}
                />
                <Button
                    onClick={() => {
                        state[campname] = ''
                        setState({ ...state })
                        setCampname('')
                    }}
                    variant='contained'
                    sx={{ flex: 3 }}>
                    Adicionar campo
                </Button>
            </Box>

        </>}
    </>)
}

const smsProviders = {
    'twillio': {
        name: 'Twillio',
        fields: {
            account_sid: '',
            auth_token: ''
        }
    },
    'zenvia': {
        name: 'Zenvia',
        fields: {
            X_API_TOKEN: '',
        }
    },
    'infobip': {
        name: 'Infobip',
        fields: {
            url: '',
            api_key: ''
        }
    },
    'other': {
        name: 'Outro',
        fields: {
            url: 'https://',
            metodo: 'POST',
            headers: '{"Authorization": "Bearer ", "Content-type": "application/json"}',
            auth: '',
            body: '{"from": {{from}}, "to": {{to}}, "message": {{message}}}',
            substituir_mais_no_numero: 'Sim'
        }
    }
}

export default smsProviders