import React, { useMemo } from 'react'

import { Box, Chip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/HighlightOff';

import {
    DataGridPro,
    ptBR,
} from '@mui/x-data-grid-pro';

import { ptBR as corePtBR } from '@mui/material/locale';
import {
    createTheme,
    ThemeProvider,
    StyledEngineProvider
} from '@mui/material/styles';

import DetailedregisterCustomToolbar from './detailed_register_customtoolbar';

import { get_report_data } from './detailed_register_functions'

import { SPECIAL_REPORT_NAME } from './detailed_register_main';

import { CustomRow, groupingColDef } from './contact_graph_exception'

import { distinctArray } from './detailed_register_functions';

const theme = createTheme({
    palette: {
        primary: { main: '#21446C' },
    },
}, ptBR, corePtBR);

const DetailedRegisterTable = ({ parent, setParent }) => {

    const data = 'results' in parent.report_data ? parent.report_data.results : parent.report_data

    const key = Math.random()

    const extraData = {
        components: { Toolbar: () => DetailedregisterCustomToolbar(parent, setParent), Row: CustomRow },
        treeData: false
    }
    if (parent.reportMode === 'standard'){
        extraData.getTreeDataPath = (row) => row.path
        extraData.groupingColDef = groupingColDef
    }

    if (parent.report_values.name === SPECIAL_REPORT_NAME && parent.reportMode === 'standard') {
        const transfers_length = data.filter(each => Number(each.transfers?.length) > 0)
        if (transfers_length.length > 0) {
            extraData['treeData'] = true
        }
    }

    const table = useMemo(() => {
        return (
            <>
                {!parent.loading && <StyledEngineProvider>
                    <ThemeProvider theme={theme}>
                        <DataGridPro
                            checkboxSelection
                            disableSelectionOnClick
                            disableDensitySelector
                            density="compact"
                            columns={parent.columns}
                            rows={data}
                            selectionModel={parent.selectedData.map(each => each.id)}
                            onSelectionModelChange={(selection) => {
                                const selectedData = parent.selectedData.filter(each => !data.map(dat => dat.id).includes(each.id))
                                const newData = data.filter(each => selection.includes(each.id))
                                setParent({ selectedData: [...selectedData, ...newData] })
                            }}

                            {...extraData}

                            onColumnVisibilityModelChange={(e) => setParent({ visibilityModel: e })}
                            columnVisibilityModel={parent.visibilityModel}

                            onFilterModelChange={(e) => setParent({ filterModel: e })}
                            filterModel={parent.filterModel}
                            filterMode={parent.selected_report_name?.mode === 'script' ? 'client' : 'server'}

                            onSortModelChange={ async (e) => {
                                const newParent = {...parent, sortModel: e}
                                const new_report_data = await get_report_data(newParent)
                                newParent.report_data = new_report_data
                                setParent(newParent)
                            }}
                            sortModel={parent.sortModel}
                            sortingMode={parent.selected_report_name?.mode === 'script' ? 'client' : 'server'}

                            //pagination
                            pagination
                            paginationMode={parent.selected_report_name?.mode === 'script' ? 'client' : 'server'}
                            rowsPerPageOptions={[25, 50, 100, 250]}
                            page={parent.page}
                            onPageSizeChange={async (size) => {
                                // change limit and redo actual request, 
                                parent.limit = size
                                const report_data = await get_report_data({ ...parent })
                                setParent({
                                    report_data: report_data,
                                    limit: parent.limit
                                })

                            }}
                            onPageChange={async (page) => {
                                // calculate new offset and change page
                                parent.offset = page * parent.limit
                                setParent({ loading: true })
                                const report_data = await get_report_data({ ...parent })
                                setParent({
                                    report_data: report_data,
                                    page: page,
                                    offset: parent.offset,
                                    loading: false
                                })
                            }}
                            rowCount={'count' in parent.report_data ? parent.report_data.count : parent.report_data.length}
                            keepNonExistentRowsSelected
                            initialState={{
                                pagination: {
                                    pageSize: Number(parent.limit)
                                }
                            }}
                        />
                    </ThemeProvider>
                </StyledEngineProvider>}
            </>
        )

    }, [parent.columns, parent.limit, parent.report_data, parent.offset,
    parent.visibilityModel, parent.page, parent.selected_report_name,
    parent.selectedData, parent.loading, parent.filterModel, parent.sortModel, parent.reportMode])

    return (
        <>
            {<Box className="report-filters">
                {distinctArray(parent.filterModel?.items, 'columnField')?.map((data) => (
                    <Chip id="report-chip"
                        label={parent.columns.find(each => each.field === data.columnField)?.headerName}
                        onDelete={() => deleteFilter(data.columnField, parent, setParent)}
                        deleteIcon={<DeleteIcon className="report-deleteIcon" />}
                    />
                ))}
            </Box>}
            {table}
        </>
        
    )
}

const deleteFilter = async (columnName, parent, setParent) => {
    let filterModel = { ...parent.filterModel };

    if (filterModel.items.length) {
        filterModel.items = filterModel.items.filter(item => item.columnField !== columnName);
    }

    setParent({ filterModel: filterModel });
}

export default DetailedRegisterTable

