import React, { useReducer, useState, useEffect } from 'react'

import {
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Divider,
    Box,
    FormGroup,
    FormControlLabel,
    Switch
} from '@mui/material'

import ReactHtmlParser from 'react-html-parser';

import LoadingButton from '@mui/lab/LoadingButton'

import CloseIcon from '@mui/icons-material/Close'

import './styles.css'


export default function AddTemplateDialog({ data, handleClose, submit }) {

    const [template, setTemplate] = useState('')

    const submitNewTemplate = () => {
        data.template = template
        submit(data)
    }

    useEffect(() => {
        setTemplate(data.template)
    }, [])

    return (
        <div>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={true}
                disablePortal
                disableEnforceFocus
                sx={{ position: 'absolute' }}
                fullWidth
                maxWidth={'lg'}
            >
                <DialogTitle className="group-flexbox">
                    Template de email
                    <Button sx={{ 'minWidth': '2rem', 'padding': '0' }} onClick={handleClose}>
                        <CloseIcon />
                    </Button>
                </DialogTitle>
                <Divider />
                <DialogContent className="add-template-dialog-content">
                    <TextField
                        multiline
                        rows={20}
                        size="small"
                        label="Template (HTML)"
                        fullWidth
                        type="text"
                        variant="outlined"
                        value={template}
                        onChange={(event) => setTemplate(event.target.value)}
                        InputProps={{
                            style: {
                                height: '100%'
                            }
                        }}
                    />
                    <Box className="add-template-viewer">
                        {ReactHtmlParser(template)}
                    </Box>
                </DialogContent>
                <DialogActions >
                    <Box className="user-footer">
                        <LoadingButton className="button-created" onClick={handleClose}>
                            Cancelar
                        </LoadingButton>
                        <LoadingButton className="button-created" 
                            onClick={() => {
                                submitNewTemplate()
                            }}
                        >
                            Salvar
                        </LoadingButton>
                    </Box>
                </DialogActions>
            </Dialog>
        </div>
    );
}
