import React, { useState, useEffect } from "react";
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import CancelIcon from '@mui/icons-material/Cancel';
import { capitalize } from "../../../utils/capitalize";
import "./styles.css";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(value, data, theme) {
  return {
    fontWeight:
      data.indexOf(value) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function MultipleSelectChip({
  title,
  selectedData,
  dataProp,
  getSelect,
  colors,
  dataIsObject,
  objectDataHasTitle,
  objectDataHasChecked,
  disabled
}) {
  const theme = useTheme();
  const [dataState, setDataState] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    // On autofill we get a stringified value.
    const dataValue = typeof value === 'string' ? value.split(',') : value;

    if (dataIsObject) {
      let newDataProp = dataProp;

      Object.keys(newDataProp).forEach(data => {
        if (dataValue.indexOf(data) !== -1) {
          newDataProp[data][objectDataHasChecked] = true;

        } else {
          newDataProp[data][objectDataHasChecked] = false;
        }
      });

      getSelect(newDataProp);

    } else {
      getSelect(dataValue);
    }

    setDataState(dataValue);
  };

  useEffect(() => {
    setDataState(selectedData);
  }, []);

  return (
    <div className="multiple-selections">
      <FormControl sx={{ width: '100%' }} size="small">
        <InputLabel id="demo-multiple-chip-label" className="input-select">{title}</InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={dataState}
          onChange={handleChange}
          disabled={disabled}
          input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {!dataIsObject && selected.map((value) => (
                <Chip
                  size="small"
                  key={value}
                  label={capitalize(value)}
                  style={{
                    backgroundColor: colors ? colors[value] : null,
                    color: colors ? 'white' : null
                  }}
                  deleteIcon={
                    <CancelIcon
                      onMouseDown={(event) => event.stopPropagation()}
                    />}
                  onDelete={() => handleChange({ target: { value: dataState.filter(each => each !== value) } })}
                />
              ))}
              {dataIsObject && objectDataHasChecked && selected.map((value) => (
                <Chip
                  size="small"
                  key={value}
                  label={dataProp[value][objectDataHasTitle]}
                  style={{
                    backgroundColor: colors ? colors[value] : null,
                    color: colors ? 'white' : null
                  }}
                  deleteIcon={
                    <CancelIcon
                      onMouseDown={(event) => event.stopPropagation()}
                    />}
                  onDelete={() => handleChange({ target: { value: dataState.filter(each => each !== value) } })}
                />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {!dataIsObject && dataProp.map((value, index) => (
            <MenuItem
              key={index}
              value={value}
              className={dataState.includes(value) ? 'hide-from-list' : ''}
              style={getStyles(value, dataState, theme)}
            >
              {value}
            </MenuItem>
          ))}
          {dataIsObject && objectDataHasTitle && Object.keys(dataProp).map((value, index) => (
            <MenuItem
              key={index}
              value={value}
              className={dataState.includes(value) ? 'hide-from-list' : ''}
              style={getStyles(value, dataState, theme)}
            >
              {dataProp[value][objectDataHasTitle]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}