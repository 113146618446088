import React from "react";

import { 
    Box, 
    Button, 
    Grid 
} from "@mui/material";

import Logo from '../../assets/images/kwik_description.png';
import userPermissions from '../../components/User/Permissions';

import api from '../../api.js';
import { saveSession, getSession } from '../../auth.js';

import { ConnectButton } from "360dialog-connect-button";

import "./index.css";

const Onboarding = () => {

    const partnerId = 'DrXGjiPA';
    const user = getSession();
    const groups = userPermissions();

    const handleConnectCallback = (callbackObject) => {

        const clientId = callbackObject.client;
        const enterpriseId = user.profile.enterprise.id;
        const url = `api/enterprises/${enterpriseId}/360dialog_channels/`;
        
        callbackObject.channels.forEach((channel) => {
            api.post(url, { channel_id: channel }, { timeout: 0 })
            .then((res) => {
                if (res.status == 201)
                    return;
                if (res.status == 400) {
                    // TODO: callback is being executed twice, within
                    // 360dialog's window and again when it returns here
                    // this should be separated, otherwise it will throw
                    // a 400 BAD_REQUEST here
                    return;
                }
                throw res;
            })
            .catch((err) => {
                console.error(err);
            });
        });
    }

    const refetchUserData = () => {
        api.get(`api/users/${user.id}/`)
        .then((res) => {
            if (res.status == 200) {
                saveSession(res.data);
                window.location.href = '/chat';
                return;
            }
            throw res;
        })
        .catch((err) => {
            console.error(err);
        })
    }
     
    return (
        <Box display="flex" flexGrow={1}>
            <Grid container/>
            <Grid container
                alignItems="center"
                justifyContent="center"
                className="onboarding-content-container"
                display="flex"
                flexDirection="column"
            >
                <Grid item>
                    <img src={Logo} alt="logo" className="onboarding-kwik-logo"/>
                </Grid>
                <Grid item container flexGrow={1}
                    alignItems="center"
                    justifyContent="center"
                    flexDirection="column"
                >
                    <Grid item flexGrow={1}></Grid>
                    <Grid item flexGrow={1} className="onboarding-greetings">
                        Olá {user.first_name} {user.last_name}, 
                        boas vindas ao Kwik!
                    </Grid>
                    {groups.isAdmin &&
                        <Grid item flexGrow={1} className="onboarding-info">
                            Para ter acesso à todas as funcionalidades da 
                            ferramenta, precisamos que finalize o setup da sua 
                            conta clicando no botão abaixo
                        </Grid>
                    }
                    {groups.isAdmin &&
                        <Grid item flexGrow={1}>
                            <ConnectButton 
                                partnerId={partnerId}
                                callback={handleConnectCallback}
                                queryParameters={{
                                    email        : user.username,
                                    name         : `${user.first_name} ${user.last_name}`,
                                    redirect_url : window.location.href,
                                    hosting_type : 'onpremise'
                                }}
                                className="onboarding-connect-button"
                                label="Iniciar Onboarding"
                            />
                        </Grid>
                    }
                    {! groups.isAdmin &&
                        <Grid item flexGrow={1} className="onboarding-info">
                            O setup da conta deve ser feito pelo administrador!
                        </Grid>
                    }
                    <Grid item flexGrow={1} className="ongoarding-footer">
                        Caso o setup já tenha sido feito, <a href="#" onClick={refetchUserData}>clique aqui</a>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container/>
        </Box >
    );
};

export default Onboarding;
