import React, { useState, useEffect, useContext, useMemo, useRef } from "react";

import {
    Box,
    Button,
    InputAdornment,
    TextField,
    List,
    ListItem,
    IconButton,
} from '@mui/material';


import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import SearchIcon from '@mui/icons-material/Search';

import api from "../../../../../api";
import ConfirmationDialog from '../../../../Dialogs/Confirmation';

import useNotification from "../../../../Notification";

import EditGroupMembersModal from '../EditGroupMembersModal'
import EditGroupNameModal from '../EditGroupNameModal'

import { FixedSizeList, ListChildComponentProps } from 'react-window';

import { ListItemButton } from "@mui/material";
import { ListItemText } from "@mui/material";

import { ScreenContext } from "../../..";
import ResponsiveCreateClientGroupButton from "../ResponsiveCreateClientGroupButton";
import { alphabeticalSort } from "../../../../../utils/alphabeticalSort";

let timeoutId;

const ClientGroupModal = ({ addclientgroup, deleteClientGroupModal, editClientGroupModal, selectedClientGroup, filterOnClick, selectedClients, addSelectedClientsToGroup, loadMoreGroups }) => {
    const { getGroups, enterpriseId, clients, groups, permissions, settings } = useContext(ScreenContext)
    const [groupList, updateGroupList] = useState([])
    const [clientGroupData, updateClientGroupData] = useState([])
    const [selectedKey, updateSelectedKey] = useState(0)
    const [editGroupMembersModal, updateEditGroupMembersModal] = useState(false)
    const [selectedGroup, setSelectedGroup] = useState([])
    const optionButton = useRef()
    const optionBox = useRef()

    const canCreateSegments = (permissions.isAdmin || permissions.isSupervisor) ? true : settings.agent_can_create_segments

    const handleSelectedGroup = (event, group) => {
        setSelectedGroup(group)
        optionButton.current.focus()
        optionBox.current.style.left = `calc(${event.clientX}px - 5px)`
        optionBox.current.style.top = `calc(${event.clientY}px - 5px)`
        setTimeout(() => { optionButton.current.blur() }, 10)
    }

    useEffect(() => {
        updateGroupList(groups.sort(alphabeticalSort))
    }, [groups])

    const renderRow = (props) => {
        const { index, style } = props
        const newstyle = { ...style }
        const group = groupList[index]

        const secondaryAction = () => {
            return (
                <>
                    {canCreateSegments ?
                        <IconButton aria- label="edit" onClick={(event) => { handleSelectedGroup(event, group); event.stopPropagation(); }}>
                            <MoreHorizIcon />
                        </IconButton >
                        : null}
                </>
            )
        }

        return (
            <ListItem
                disableGutters
                onClick={() => { filterOnClick(group.name) }} style={newstyle} key={`each-group-${group.id}`}
                secondaryAction={secondaryAction()}
            >
                <ListItemButton disableGutters sx={{ paddingLeft: "0.5rem", overflow: "hidden" }}>
                    {group.name}
                </ListItemButton>
            </ ListItem >
        )
    }

    const groupListRender = () => {

        const handleScroll = (scrollOffset) => {
            const totalHeight = groupList.length * 40
            const visibleHeight = 160

            const nearBottomThreshold = 1
            const remainingScroll = totalHeight - scrollOffset - visibleHeight

            if (remainingScroll < nearBottomThreshold)
                loadMoreGroups()
        };

        return (
            <>
                {groupList.length === 0 ? (
                    <Box key={'empty-list-nice-key'} sx={{ margin: "1rem" }}>Nenhum segmento encontrado...</Box>
                ) : (
                    <FixedSizeList
                        sx={{ height: "min-content !important" }}
                        className='group-list-flexbox'
                        height={400}
                        width={250}
                        itemSize={40}
                        itemCount={groupList.length}
                        overscanCount={5}
                        onScroll={({ scrollOffset }) => handleScroll(scrollOffset)}
                    >
                        {renderRow}
                    </FixedSizeList>
                )}
            </>
        )
    }

    const handleFilterChange = (event) => {
        if (timeoutId)
            clearTimeout(timeoutId)

        timeoutId = setTimeout(() => getGroups(undefined, event.target.value), 1000)
    }

    return (
        <>
            <>
                <Box sx={{ "marginBottom": ".25rem", "padding": "0 0.2rem" }}>
                    <ResponsiveCreateClientGroupButton
                        isDisabled={!canCreateSegments}
                        handle={addclientgroup}
                    />
                </Box>
                <TextField
                    className={'inputPlaceholder'}
                    placeholder='Buscar Segmentos'
                    onChange={handleFilterChange}
                    variant="outlined"
                    size="small"
                    sx={{ marginTop: '.4rem', marginBottom: '.4rem', backgroundColor: '#EFEFEF' }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    fullWidth
                />
                {groupListRender()}
                <Button ref={optionButton} className="helperButton"></Button>
                <Box ref={optionBox} className={'groupDropDown'}>
                    <Button onClick={() => editClientGroupModal(selectedGroup)} className='groupOption'>Editar</Button>
                    <Button onClick={() => selectedClientGroup(selectedGroup)} className='groupOption'>Ver clientes</Button>
                    <Button onClick={() => addSelectedClientsToGroup(selectedGroup)} className='groupOption'>Adicionar seleção ao segmento</Button>
                    <Button onClick={() => deleteClientGroupModal(selectedGroup)} className='groupOption'>Excluir</Button>
                </Box>
            </>

        </>
    )
}

export default ClientGroupModal
