import React, { useState } from "react";

import { Box, Grid, IconButton, TextField, Button, Dialog, Chip, DialogActions, DialogContent, DialogTitle, Divider, Tooltip, Badge } from "@mui/material";

import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import StrikethroughSIcon from '@mui/icons-material/StrikethroughS';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import LoadingButton from '@mui/lab/LoadingButton';
import { FileDrop } from 'react-file-drop';
import CloseIcon from '@mui/icons-material/Close';
import ImportIcon from '@mui/icons-material/Upload';

import './styles.css'

const EmailFooter = ({ sendMessage }) => {

  const [bodyTextContent, setBodyTextContent] = useState('');
  const [showAttachments, setShowAttachments] = useState(false)
  const [attachments, setAttachments] = useState([])

  const setFormattingOnBody = (formattingCharacter) => {
    if (!bodyTextContent)
      return;

    const selectedText = window.getSelection().toString();
    const caretIndexStart = document.getElementById('body-textarea').selectionStart;
    const caretIndexEnd = document.getElementById('body-textarea').selectionEnd;

    if (selectedText) {
      setBodyTextContent(bodyTextContent.substring(0, caretIndexStart) + `${formattingCharacter}${selectedText}${formattingCharacter.replace('<', '</')}` + bodyTextContent.substring(caretIndexEnd, bodyTextContent.length));
    } else {
      setBodyTextContent(bodyTextContent.substring(0, caretIndexStart) + `${formattingCharacter}${formattingCharacter.replace('<', '</')}` + bodyTextContent.substring(caretIndexStart, bodyTextContent.length));
    }
  }

  const sendEmailMessage = async () => {
    const res = await sendMessage(bodyTextContent, true, attachments)
    if (res !== -1){
      setBodyTextContent('')
      setAttachments([])
    }
  }

  return (
    <Box class="email-input">
      {showAttachments &&
        <Dialog
          open='true'
          disablePortal
          fullWidth
          maxWidth='md'
          disableEnforceFocus
          sx={{ position: 'absolute' }}>
          <DialogTitle id="customized-dialog-title" className={'group-flexbox'}>
            <span>Adicionar anexos</span> <Button sx={{ 'minWidth': '2rem', 'padding': '0' }} onClick={() => { setShowAttachments(false) }} ><CloseIcon /></Button>
          </DialogTitle>
          <Divider />
          <DialogContent>
            <div className="file-drop-container">
              <FileDrop
                onDrop={(files, event) => { setAttachments([...attachments, ...files]); event.target.value = null }}
              >
                {attachments.length > 0 ? attachments.map(attachment =>
                  <Chip label={attachment.name} onDelete={() => { setAttachments(attachments.filter(each => each !== attachment)) }} />) :
                  <span>Arraste e solte para inserir anexos.
                  </span>
                }
                <span>ou</span>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  component="label"
                  loading={false}
                  loadingPosition="start"
                  startIcon={<ImportIcon />}
                  disabled={false}
                  onClick={() => { }}
                >
                  Selecione
                  <input
                    hidden
                    multiple='true'
                    accept={'any'}
                    type="file"
                    onChange={(e) => { setAttachments([...attachments, ...e.target.files]); e.target.value = null }}
                  />
                </LoadingButton>
              </FileDrop>
            </div>
          </DialogContent>
          <DialogActions style={{ padding: "10px 20px 10px 10px" }}>
            <LoadingButton
              onClick={() => { setAttachments([]) }}
              color="primary"

            >
              Limpar todos
            </LoadingButton>
            <LoadingButton
              onClick={() => { setShowAttachments(false) }}
              color="primary"

            >
              Confirmar
            </LoadingButton>
          </DialogActions>
        </Dialog>
      }
      <Grid container item spacing={0.5} class="email-input-container">
        <Grid item lg={12} md={12} sm={12} xs={12} class="email-input-actions">
          <IconButton onClick={() => setFormattingOnBody('<strong>')} size="small">
            <FormatBoldIcon color="primary" />
          </IconButton>
          <IconButton onClick={() => setFormattingOnBody('<em>')} size="small">
            <FormatItalicIcon color="primary" />
          </IconButton>
          <IconButton onClick={() => setFormattingOnBody('<s>')} size="small">
            <StrikethroughSIcon color="primary" />
          </IconButton>
          <Tooltip title={attachments.map(each => each.name).join(', ')}>
            <Badge badgeContent={attachments.length} className='lower-badge' color="success" >
              <IconButton onClick={() => setShowAttachments(true)} size="small" badge>
                <AttachEmailIcon color="primary" />
              </IconButton>
            </Badge>
          </Tooltip>

          {/* {attachments.length > 0 &&
            <Button onClick={() => setShowAttachments(true)}>
              <Tooltip title={attachments.map(each => each.name).join(', ')}>
                <span>{attachments.length} {attachments.length === 1 ? 'Anexo' : 'Anexos'}</span>
              </Tooltip>
            </Button>
          } */}
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} class="email-input-content">
          <TextField
            id="body-textarea"
            multiline
            rows={10}
            size="small"
            label="Corpo"
            fullWidth
            type="text"
            variant="outlined"
            inputProps={{ maxLength: 1024 }}
            value={bodyTextContent}
            onChange={(event) => setBodyTextContent(event.target.value)}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} class="email-input-buttons">
          <Button onClick={sendEmailMessage}>Enviar</Button>
        </Grid>
      </Grid>
    </Box>
  )
}

export default EmailFooter;
