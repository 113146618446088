const IntegerOnly = (value, min=null, max=null) => {
    const newvalue = value.replace(new RegExp(/[^\d]/, "ig"), "")
    if (min && Number(value) < Number(min))
        return min 
    if (max && Number(value) > Number(max))
        return max 
    
    return newvalue
}

export default IntegerOnly