import React from "react";
import HeaderImage from '../../assets/images/register_header.svg'
import Logo from '../../assets/images/kwik_description.png'
import { Box, Grid, Typography, Button } from "@mui/material"
import PropTypes from 'prop-types';
import { useStyles } from './styles';

import './index.css';

const Header = ({ buttonText, subtitle, onClick }) => {
  const classes = useStyles();

  const gotCTA = () => {
    window.open('https://landing.kwik.app.br/kwik/')
  }

  return (
    <Box className="left-container">
      <Box width={'45vw'}
        display="flex"
        flexGrow={1}
        className={classes.header}
        >
        <Grid container
          alignItems="center"
          justifyContent="center"
          spacing={2}
          direction="column" >
          <Grid item><img src={Logo} onClick={gotCTA} alt="logo" className={classes.logo} /></Grid>
          <Grid item><Typography className={classes.subtitle}  variant="subtitle1">{subtitle}</Typography></Grid>
          <Grid item><Button variant="contained" color="primary" className={classes.button} onClick={onClick}>{buttonText}</Button></Grid>
          <Grid item><img src={HeaderImage} alt="header" className={classes.headerImage} /></Grid>
          <Grid item><Button variant="contained" className='kwik-cta-button' onClick={gotCTA}>SAIBA MAIS</Button></Grid>
        </Grid>
      </Box>
    </Box>
  );
}

Header.propTypes = {
  buttonText: PropTypes.string,
  onClick: PropTypes.func,
  subtitle: PropTypes.string
}

export default Header;

