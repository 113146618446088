import React from "react";

import { Button, IconButton } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

import CampaignIcon from '@mui/icons-material/Campaign';


export default function ResponsiveCampaignButton({ className, onClickFn, id, variant }) {
  const isSmallScreen = useMediaQuery('(max-width: 60rem)');

  return (
    <>
      {isSmallScreen ? (
        <IconButton
          className={className}
          onClick={onClickFn}
          size="small"
          variant={variant}
        >
          <CampaignIcon />
        </IconButton>
      ) : (
        <Button className={className}
          onClick={onClickFn}
          id={id}
          variant={variant}
          endIcon={<CampaignIcon />}
        >
          Campanhas
        </Button>
      )}
    </>
  )
}