import React from "react";

import { Button, IconButton } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import AddIcon from '@mui/icons-material/Add';

export default function ResponsiveNewCampaignButton({ className, onClickFn, isDisabled }) {
  const isSmallScreen = useMediaQuery('(max-width: 60rem)');

  return (
    <>
      {isSmallScreen ? (
        <IconButton
          className={className}
          onClick={onClickFn}
          size="small"
          disabled={isDisabled}
        >
          <AddIcon className="add-icon" />
        </IconButton>
      ) : (
        <Button
          className={className}
          onClick={onClickFn}
          disabled={isDisabled}
          variant="contained"
        >Nova campanha
        </Button>
      )}
    </>
  )
}
