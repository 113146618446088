import React, { useState, useEffect } from 'react'

import { Box, Button } from '@mui/material'

import isMobile from './isMobile'

import './style.css'

function getPWADisplayMode() {
    const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
    if (document.referrer.startsWith('android-app://')) {
        return 'twa';
    } else if (navigator.standalone || isStandalone) {
        return 'standalone';
    }
    return 'browser';
}

const InstallKwik = () => {

    const [mobileShow, setMobileShow] = useState('')

    useEffect(() => {
        const divInstall = document.getElementById("installContainer");
        const butInstall = document.getElementById("butInstall");

        setMobileShow(isMobile() && getPWADisplayMode() == 'browser' ? 'mobile-show' : '')

        window.addEventListener('beforeinstallprompt', (event) => {
            // Prevent the mini-infobar from appearing on mobile.
            event.preventDefault();
            if (isMobile())
                setMobileShow('mobile-show')
            // Stash the event so it can be triggered later.
            window.deferredPrompt = event;
            // Remove the 'hidden' class from the install button container.
            divInstall.classList.toggle('hidden', false);
        });

        butInstall.addEventListener('click', async () => {
            const promptEvent = window.deferredPrompt;
            if (!promptEvent) {
                // The deferred prompt isn't available.
                return;
            }
            // Show the install prompt.
            promptEvent.prompt();
            // Log the result
            const result = await promptEvent.userChoice;
            // Reset the deferred prompt variable, since
            // prompt() can only be called once.
            window.deferredPrompt = null;
            // Hide the install button.
            divInstall.classList.toggle('hidden', true);
        });

        window.addEventListener('appinstalled', (event) => {
            // Clear the deferredPrompt so it can be garbage collected
            window.deferredPrompt = null;
        });

        const isSupported = () =>
            'Notification' in window &&
            'serviceWorker' in navigator &&
            'PushManager' in window

        if (isSupported()) {
            const audio = new Audio('./250-milliseconds-of-silence.mp3')
            audio.volume = 0.2
            audio.play()
        }
    }, [])

    // Detects if device is on iOS 
    const isIos = () => {
        const userAgent = window.navigator.userAgent.toLowerCase();
        return /iphone|ipad|ipod/.test(userAgent);
    }

    return (
        <> 
            <Box className={`kwik-install-background ${mobileShow}`}>
                <Box className='kwik-install-modal'>
                    <img src='/kwik_colorido.png' />
                    {isIos() ?
                        <>
                            <Box className={'kwik-install-text'}>
                                O Kwik só está disponível no smartphone/tablet para dispositivos com sistema operacional Android.
                            </Box>


                            <Box id="installContainer" style={{ display: 'none' }}>
                                <Button id="butInstall" >Instalar</Button>
                            </Box>
                        </>
                        :
                        <>
                            <Box className={'kwik-install-text'}>
                                Para utilizar o kwik no celular ou tablet, é necessário instalar o aplicativo clicando no botão abaixo.
                            </Box>

                            <Box id="installContainer">
                                <Button id="butInstall" >Instalar</Button>
                            </Box>
                        </>
                    }
                </Box>
            </Box>
        </>
    )
}

export default InstallKwik