import React from 'react';

import { Box } from '@mui/material';

import Typography from '../../Typography';

import './styles.css';

const Frame = ({ gridArea, title, children }) => {
  return (
    <Box className={`frame ${gridArea}`} fullWidth>
      <Typography
        align="center"
        text={title}
        color="darkGray"
        fontWeight='600'
        variant="h5"
      />
      <Box className="frame-body">
        {children}
      </Box>
    </Box>
  );
}

export default Frame;