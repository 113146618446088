import React from 'react';

import withStyles from '@mui/styles/withStyles';
import MaterialLinearProgress from '@mui/material/LinearProgress';

class LinearProgress extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            progress: 0
        };
    }

    resetProgress = () => {
        this.setState({
            progress: 0
        });
    }

    getProgress = () => {
        const currentTime = Math.round(Date.now() / 1000);
        const lastUserMessageTime = this.props.lastUserMessageTime;
        const maxWaitTime = this.props.maxWaitTime;

        const progress = (currentTime-lastUserMessageTime)*100/maxWaitTime;
        
        if (progress > 100)
            return 100;

        return progress;
    }

    componentWillMount() {
        const that = this;
        const intervalId = setInterval(() => {
            let progress = that.state.progress + 100/that.props.maxWaitTime;
            if (progress > 100) {
                progress = 100;
            }
            that.setState({ progress: progress });
        }, 1000);

        this.setState({
            progress: this.getProgress(),
            intervalId: intervalId
        });
    }

    componentWillUnmount() {
        clearInterval(this.state.intervalId);
    }

    render() {
        const { classes } = this.props;
        return (
            <div >
                <MaterialLinearProgress {...this.props}
                    classes={{
                        barColorPrimary: this.state.progress <= 33 && classes.barColorStart ||
                                         this.state.progress > 33 && this.state.progress < 66 && classes.barColorMiddle ||
                                         this.state.progress >= 66 && classes.barColorEnd
                    }}
                    variant="determinate"
                    value={this.state.progress}
                />
            </div>
        );
    }
}

const styles = props => ({
    barColorStart: {
        backgroundColor: '#5F8963',
    },
    barColorMiddle: {
        backgroundColor: '#DFB160',
    },
    barColorEnd: {
        backgroundColor: '#C95F50',
    }
});

export default withStyles(styles)(LinearProgress);
