import React from 'react';

import './index.css';

import { DialogTitle, DialogContent, Divider } from '@mui/material';
import { DialogActions } from '@mui/material';
import { Box, Button, TextField } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

import { getSession } from '../../../auth';

import TooltipIndicators from '../../Dash/Tooltip';
import MultipleSelectChip from '../../MultipleSelectChip';
import { alphabeticalSort } from '../../../utils/alphabeticalSort';

class RestrictedWordsDialog extends React.Component {

    constructor(props) {
        super(props);

        this.queues = {};

        this.state = {
            name: "",
            restrictedWords: "",
            queues: [],
            loading: false
        }
    }

    componentWillMount() {
        const user = getSession();
        const test = {}

        this.props.queuesName.forEach((queue) => {
            this.queues[queue.description] = queue;
        });

        this.setState({
            name: this.props.model ? this.props.model.name : "",
            restrictedWords: this.props.model ? this.props.model.words.map((word) => word.word).join(', ') : "",
            queues: this.props.model ? this.props.model.queues.map((queue) => queue.queue) : []
        })
    }

    submit = () => {
        this.setState({ loading: true });

        const id = this.props.model ? this.props.model.id : null;

        this.props.submit(
            id,
            this.state.name,
            this.state.restrictedWords.split(',').map((word) => word.trim()),
            this.state.queues.filter(queue => queue).map((queue) => queue.id)
        );
    }

    handleQueueSelection = (queues) => {
        let selectedQueues = []
        queues.forEach((queue) => {
            selectedQueues.push(this.queues[queue]);
        })
        this.setState({ queues: selectedQueues });
    }

    tooltipText = () => {
        return (
            <>
                <Box>
                    Lista de palavras restritas separadas por vírgula, ex. 'sistema, informática'.
                </Box>
                <br></br>
                <Box>
                    Não há distinção entre letras maiúsculas e minúsculas.
                </Box>
                <br></br>
                <Box>
                    Para adicionar um termo restrito, coloque-o entre **, Exemplo: *cade* irá bloquear a palavra academia pois 'cade' está dentro de 'academia'.
                </Box>
            </>
        )
    }

    render() {
        return (
            <>
                <DialogTitle id="customized-dialog-title" className={'group-flexbox'}>
                    <span>{this.props.title}</span> <Button sx={{ 'minWidth': '2rem', 'padding': '0' }} onClick={this.props.handleClose} ><CloseIcon /></Button>
                </DialogTitle>
                <Divider />
                <DialogContent
                    ref={
                        instance => {
                            this.content = instance;
                        }
                    }
                >
                    <TextField
                        size="small"
                        label="Nome"
                        fullWidth
                        type="text"
                        variant="outlined"
                        value={this.state.name}
                        onChange={(event) => this.setState({ name: event.target.value })}
                    />
                    <Box className="rw-section">
                        Lista de Palavras Restritas
                        <TooltipIndicators
                            text={this.tooltipText()}
                            placement="right"
                            float="left"
                            marginLeft="5px"
                        />
                    </Box>
                    <TextField
                        size="small"
                        multiline
                        rows="5"
                        label="Palavras"
                        fullWidth
                        type="text"
                        variant="outlined"
                        value={this.state.restrictedWords}
                        onChange={(event) => this.setState({ restrictedWords: event.target.value })}
                    />
                    <Box className="rw-section">
                        Filas Associadas
                        <TooltipIndicators
                            text="Selecione à quais filas será aplicada esta restrição."
                            placement="right"
                            float="left"
                            marginLeft="5px"
                        />
                    </Box>
                    <MultipleSelectChip
                        selectData={Object.keys(this.queues)}
                        onDataSelect={this.handleQueueSelection}
                        title="Filas"
                        values={
                            this.props.model ?
                                this.props.model.queues.filter(queue => queue.queue).map(
                                    (queue) => (queue.queue && queue.queue.description) ? queue.queue.description : ''
                                ) : []
                        }
                    />
                </DialogContent>
                <DialogActions style={{ padding: "10px 20px 10px 10px" }}>
                    <LoadingButton
                        onClick={this.props.handleClose}
                        color="primary"
                    >
                        Cancelar
                    </LoadingButton>
                    <LoadingButton
                        onClick={() => this.submit()}
                        color="primary"
                        disabled={!this.state.name || !this.state.restrictedWords || this.state.queues.length == 0}
                        loading={this.state.loading}
                    >
                        {this.props.buttonText}
                    </LoadingButton>
                </DialogActions>
            </>
        );
    }
}

export default RestrictedWordsDialog;
