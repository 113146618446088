import React, { useEffect, useState } from 'react'

import { DialogActions } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import './styles.css'


const MultiStep = props => {
    const [step, changeStep] = useState(0)
    const [input, changeInput] = useState('')
    const [stepsData, updateStepsData] = useState({})



    const prevStep = () => { changeStep(step - 1) }
    const nextStep = async () => {
        const response = await props.onNext(step + 1)
        if (response)
            changeStep(step + 1);
    }
    const goStep = async (value) => {
        if (value > step && !props.canNext) {

            return
        }
        if (value > step) {
            const response = await props.onNext(value)
            if (response)
                changeStep(value)
        }
        else {
            changeStep(value)
        }


    }

    useEffect(() => {
        if (props.onOpen === 0)
            return
        changeStep(props.onOpen)

    }, [props.onOpen])


    props.getStep(step)
    //Create new props
    const newProps = Object.assign({}, props)
    //Delete submit from this props
    delete newProps.setTestCampaignModal
    delete newProps.onSubmit
    delete newProps.setModalActions
    delete newProps.updateButtons
    delete newProps.canNext
    delete newProps.getStep
    delete newProps.onNext
    delete newProps.onOpen
    //Create array from object
    const groupObject = Object.values(newProps)
    // const groupKeys = Object.keys(props)

    const submit = () => {
        props.setTestCampaignModal(false)
        props.onSubmit('activate')
    }

    // Lets put it outside of the multistep
    useEffect(() => {
        props.setModalActions(
            <DialogActions style={{ padding: "10px 20px 10px 10px" }} >
                {step === groupObject.length - 1 &&
                    <LoadingButton sx={{position: 'absolute', left: '15px'}} onClick={() => {props.setTestCampaignModal(true)}}>
                        Testar Campanha
                    </LoadingButton>}
                {step !== 0 &&
                    <LoadingButton onClick={prevStep}>
                        Anterior
                    </LoadingButton>}
                {step !== groupObject.length - 1 &&
                    <LoadingButton disabled={!props.canNext} onClick={nextStep}>
                        Próximo
                    </LoadingButton>}
                {step === groupObject.length - 1 &&
                    <LoadingButton disabled={!props.canNext} onClick={submit}>
                        Concluir
                    </LoadingButton>}
            </DialogActions>
        )
    }, [props.updateButtons])


    return (
        <>
            <div className='flexedRow'>
                {groupObject.map((component, index) => {
                    return (
                        <>
                            <button
                                key={`unique-step-key-${index}`}
                                className={index <= step ? 'multiButton activated' : 'multiButton'}
                                onClick={() => { goStep(index) }} >
                                {index + 1}
                            </button>
                            {index !== groupObject.length - 1 &&
                                <div className={'fill'}>
                                    <div className={index < step ? 'separateLine activatedLine' : 'separateLine'} >
                                    </div>
                                </div>}
                        </>
                    )
                })}
            </div>
            <div>
                {groupObject[step]}
            </div>
        </>
    )
}

export default MultiStep