import React, { useState, useEffect, useMemo } from "react";

import {
    Box,
} from '@mui/material';



import ResponsiveClientsButton from "../ResponsiveClientsButton";
import ResponsiveNotificationButton from "../ResponsiveNotificationButton";
import ResponsiveCampaignButton from "../ResponsiveCampaignButton";

import { getSession } from '../../../auth';

import ResponsiveNewCampaignButton from "./ResponsiveNewCampaignButton";
import CampaignTable from "./CampaignTable";
import '../styles.css';
import CreateCampaignModal from "./CreateCampaignModal";

function CampaignSection(props) {
    const [newCampaignModal, updateNewCampaignModal] = useState(false)
    const [refreshState, updateRefreshState] = useState(false)

    const displayTable = useMemo(() => {
        return (
            <>
                <CampaignTable
                    update={refreshState}
                    changeUpdate={updateRefreshState}
                    session={props.parent.enterpriseId}
                    parent={props.parent}
                />
            </>
        )
    }, [refreshState])

    const CampaignModal = () => {
        updateNewCampaignModal(true)
    }
    const createCampaign = async (event) => {
        updateNewCampaignModal(false)
        updateRefreshState(true)
    }

    useEffect(() => {
        updateRefreshState(false)
    }, [refreshState])

    const canCreateCampaigns = getSession().groups.filter(each => each.name === 'administrador' || each.name === 'supervisor').length > 0
        ? true
        : getSession().profile.enterprise.settings.agent_can_create_campaigns
        ;

    return (
        <>
            {!refreshState &&
                <>
                    <CreateCampaignModal open={newCampaignModal}
                        title="Nova campanha"
                        buttonText="Adicionar"
                        queues={props.parent.queues}
                        senders={props.parent.state.originNumbers}
                        templates={props.parent.state.templates}
                        enterpriseId={props.parent.enterpriseId}
                        handleClose={() => { updateNewCampaignModal(false); updateRefreshState() }}
                        submit={createCampaign}
                    />
                    <Box className="clients-body">
                        <Box className="clients-buttons">
                            <Box className="box-msg">
                                {canCreateCampaigns &&
                                    <ResponsiveNewCampaignButton
                                        className="button-new-campaign"
                                        id={1}
                                        variant={"outlined"}
                                        onClickFn={async () => { CampaignModal() }}
                                    />
                                }
                                <ResponsiveClientsButton
                                    className="button-table"
                                    onClickFn={async () => {
                                        props.parent.deactivateCampaign();
                                        props.parent.setState({ notificationsSection: false })
                                    }}
                                    id={2}
                                    variant={"outlined"}
                                />
                                <ResponsiveNotificationButton
                                    className="button-notification"
                                    onClickFn={async () => {
                                        props.parent.deactivateCampaign();
                                        props.parent.setState({ notificationsSection: true })
                                    }}
                                    id={3}
                                    variant={"outlined"}
                                />
                                <ResponsiveCampaignButton
                                    className="button-campaign"
                                    id={props.parent.state.campaignSection ? "button-active" : ""}
                                    variant={props.parent.state.campaignSection ? "contained" : "outlined"}
                                />
                            </Box>
                        </Box>
                        <>
                            {displayTable}
                        </>

                    </Box>
                </>
            }
        </>
    )
}

export default CampaignSection
