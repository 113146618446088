import React, { useEffect, useState } from 'react';

import { Dialog, DialogTitle, DialogContent, Grid, Box, Divider } from '@mui/material';
import { FormControl, InputLabel, Select, MenuItem, Chip } from '@mui/material';
import { List, ListItem, ListItemText, DialogActions, Button } from '@mui/material';
import { TextField } from '@mui/material';
import useNotification from "../../../Notification";

import CloseIcon from '@mui/icons-material/Close';

import '../../../../index.css';
import api from '../../../../api';

import MultiStep from './MultiStep';
import TimeSelector from './TimeSelector';
import DateTimeSelector from './DateTimeSelector';
import '../../styles.css';
import CampaignSummary from './CampaignSummary';

const CreateCampaignModal = (props) => {
    const [campaignID, updateCampaignID] = useState(0)

    const [campaignName, updateCampaignName] = useState("")
    const [clientGroup, updateclientGroupRows] = useState([])
    const [selectedGroup, updateSelectedGroup] = useState([])

    const [selectedQueue, updateSelectedQueue] = useState("")
    const [selectedSender, updateSelectedSender] = useState("")
    const [selectedTemplate, updateSelectedTemplate] = useState("")

    const [timeSelector, updateTimeSelector] = useState("")

    const [dateTimeSelector, updateDateTimeSelector] = useState({})

    const [sendNow, updateSendNow] = useState(false)

    const [actualStep, updateStep] = useState(0)
    const [canNext, updateCanNext] = useState(false)

    const [paramModal, updateParamModal] = useState(false)
    const [parameters, updateParameters] = useState([])
    const [allParamsFilled, updateAllParamsFilled] = useState(false)

    const [ready, updateReady] = useState(false)

    const queues = Object.entries(props.queues)


    const fetchClientGroup = async () => {
        const url = `/api/enterprises/${props.enterpriseId}/contact_group/`;
        const response = await api.get(url)
        updateclientGroupRows(response.data)

    }
    useEffect(() => {
        fetchClientGroup()
        updateReady(true)
    }, [])


    //Custom logic to save in between steps without modifying old logic.
    // 'create' for the first step
    // 'activate' for the last step
    // 'none' for any step.
    const onNext = (value) => {
        if (value == 1) {
            submit('create')
        }
        else {
            submit('none')
        }
    }

    const submit = async (command) => {
        if (command != 'create' && campaignID === 0)
            return
        const posturl = `/api/enterprises/${props.enterpriseId}/campaign/`
        const patchurl = `/api/enterprises/${props.enterpriseId}/campaign/${campaignID}/`
        const isPeriodic = timeSelector === 'periodic' ? true : false
        var daylist = typeof (dateTimeSelector.DAY) !== 'object' ? dateTimeSelector.DAY : dateTimeSelector.DAY.join()
        var monthlist = typeof (dateTimeSelector.MONTH) !== 'object' ? dateTimeSelector.MONTH : dateTimeSelector.MONTH.map(item => JSON.parse(item).key).join()
        var weekdaylist = typeof (dateTimeSelector.WEEKDAYS) !== 'object' ? dateTimeSelector.WEEKDAYS : dateTimeSelector.WEEKDAYS.map(item => JSON.parse(item).key).join()
        const queue = selectedQueue.split('_')[1]
        var range = '*'
        var year = dateTimeSelector.YEAR

        if (!isPeriodic) {
            const today = new Date()
            if (dateTimeSelector.DATE < today) {
                updateCanNext(false)
                useNotification(
                    'Falha!',
                    `Por favor escolha um horário maior que o atual`,
                    'danger'
                )
                return
            }

        }
        if (dateTimeSelector.PERIOD == 1) {
            range = dateTimeSelector.RANGE
            year = '0'
            monthlist = '*'
            weekdaylist = '*'
            const today2 = new Date()
            if (dateTimeSelector.DATE < today2) {
                updateCanNext(false)
                useNotification(
                    'Falha!',
                    `Por favor escolha um horário maior que o atual`,
                    'danger'
                )
                return
            }
        }
        const body = {
            name: campaignName,
            group: selectedGroup,
            queue: queue,
            sender: selectedSender,
            template: selectedTemplate.name,
            year: year,
            months: monthlist,
            week_days: weekdaylist,
            days: daylist,
            hour: dateTimeSelector.HOURS,
            minutes: dateTimeSelector.MINUTES,
            periodic: isPeriodic,
            active: command == 'activate' ? true : false,
            _range: range.toString(),
            run_now: sendNow,
            parameters: JSON.stringify(parameters.map(parameter => parameter.value)),
            step: actualStep + 1
        }

        var response = ''
        if (command == 'create' && !campaignID) {
            var response = await api.post(posturl, body)
            updateCampaignID(response.data.id)
        }
        else {
            var response = await api.patch(patchurl, body)
        }

        if (response.status === 404) {
            console.log('Response Fail')
            return
        }

        if (command == 'activate') {
            useNotification(
                'Sucesso!',
                'Campanha concluída.',
                'success'
            );


            props.submit()
        }

    }

    const changeData = () => {
        if (actualStep == 0) {
            if (campaignName.length == 0 ||
                selectedGroup.length == 0) {
                updateCanNext(false)
                return
            }
        }
        if (actualStep == 1) {
            if (selectedQueue.length == 0 ||
                selectedSender.length == 0 ||
                selectedTemplate.length == 0) {
                updateCanNext(false)
                return
            }
        }
        if (actualStep == 2) {
            if (timeSelector.length == 0) {
                updateCanNext(false)
                return
            }
        }
        if (actualStep == 3) {
            if (Object.entries(dateTimeSelector).length === 0) {
                updateCanNext(false)
                return
            }
        }
        updateCanNext(true)
    }


    const handleSelectedTemplate = template => {

        const paramAmount = (template.components[0].text.match(/{{/g) || []).length
        updateSelectedTemplate(template)
        if (paramAmount == 0) {
            updateParameters([])
            return
        }
        if (parameters.length != paramAmount) {
            updateAllParamsFilled(false)
            updateParameters(Array(paramAmount).fill({ value: '' }))
        }
        updateParamModal(true)

    }

    const handleParamGoBack = () => {
        updateParamModal(false)
        updateParameters([])
        updateSelectedTemplate('')
    }

    const handleParamChange = (e, i) => {
        const { value } = e.target;
        const newparameters = parameters;
        newparameters[i] = {};
        newparameters[i]['value'] = value;

        updateParameters(newparameters)
        checkParameters()
    }

    const checkParameters = () => {

        let parametersFilled = true;
        parameters.forEach((parameter) => {
            if (parameter.value == '')
                parametersFilled = false;
        });
        updateAllParamsFilled(parametersFilled);
        return

    }
    const handleChangeGroup = (event) => {
        const {
            target: { value },
        } = event;
        updateSelectedGroup(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };


    const recoverCampaignData = async (id) => {
        const geturl = `/api/enterprises/${props.enterpriseId}/campaign/${id}/`

        const response = await api.get(geturl)

        const periods = [
            'Diariamente',
            'No intervalo de dias',
            'Nos dias da semana selecionados',
            'Nos meses selecionados'
        ]

        const possibleWeekdays = [
            { key: 0, value: 'Segunda-feira' },
            { key: 1, value: 'Terça-feira' },
            { key: 2, value: 'Quarta-feira' },
            { key: 3, value: 'Quinta-feira' },
            { key: 4, value: 'Sexta-feira' },
            { key: 5, value: 'Sábado' },
            { key: 6, value: 'Domingo' }
        ]
        const possibleMonths = [
            { key: 1, value: 'Janeiro' },
            { key: 2, value: 'Fevereiro' },
            { key: 3, value: 'Março' },
            { key: 4, value: 'Abril' },
            { key: 5, value: 'Maio' },
            { key: 6, value: 'Junho' },
            { key: 7, value: 'Julho' },
            { key: 8, value: 'Agosto' },
            { key: 9, value: 'Setembro' },
            { key: 10, value: 'Outubro' },
            { key: 11, value: 'Novembro' },
            { key: 12, value: 'Dezembro' }
        ]

        const recoveredQueue = queues.find(each => each[1].includes(response.data.queue))
        const recoveredSender = props.senders.find(each => each === response.data.sender)
        const recoveredTemplate = props.templates.find(each => each.name === response.data.template)
        const recoveredParams = JSON.parse(response.data.parameters).map(each => ({ value: each }))
        const recoveredPeriodic = response.data.periodic === true ? 'periodic' : 'once'

        var recoveredPeriod = -1
        var recoveredLiteral = ''
        var recoverdedDate = ''

        var recoveredWeekdays = response.data.week_days
        var recoveredMonths = response.data.months
        var recoveredRange = response.data._range == '*' ? ['', ''] : response.data._range.split(',')

        if (recoveredPeriodic === 'periodic') {
            var recoveredWeekdays = response.data.week_days == '*' ? '*' : possibleWeekdays.filter(each =>
                response.data.week_days.includes(String(each.key))
            ).map(each => JSON.stringify(each)
            )

            var recoveredMonths = recoveredMonths == '*' ? '*' : possibleMonths.filter(each =>
                response.data.months.includes(String(each.key))
            ).map(each => JSON.stringify(each)
            )



            if (response.data.days === '*/1') {
                var recoveredPeriod = 0
            }
            else if (response.data._range !== '*') {
                var recoveredPeriod = 1
                var recoveredLiteral = ': ' +
                    new Date(recoveredRange[0] + 'T12:00')
                        .toLocaleDateString()
                    + ' até '
                    + new Date(recoveredRange[1] + 'T12:00')
                        .toLocaleDateString()
            }

            else if (response.data.week_days !== '*') {
                var recoveredPeriod = 2
                var recoveredLiteral = ': ' + recoveredWeekdays.map(item => JSON.parse(item).value).join(', ')
            }

            else if (response.data.months !== '*') {
                var recoveredPeriod = 3
                var recoveredLiteral = ': ' + recoveredMonths.map(item => JSON.parse(item).value).join(', ') + ' no dia ' + response.data.days
            }


            var recoveredLiteral = periods[recoveredPeriod] + recoveredLiteral + ` às ${response.data.hour}:${response.data.minutes}`
        }
        else {

            recoverdedDate = new Date(response.data.year,
                response.data.months - 1,
                response.data.days,
                response.data.hour,
                response.data.minutes)
            recoveredLiteral = recoverdedDate.toLocaleString()
        }

        const recoveredDateTimeSelector = response.data.year !== 0 ?
            {
                YEAR: response.data.year,
                MONTH: recoveredMonths,
                DAY: response.data.days,
                HOURS: response.data.hour,
                MINUTES: response.data.minutes,
                WEEKDAYS: recoveredWeekdays,
                PERIOD: recoveredPeriod,
                LITERAL: recoveredLiteral,
                RANGE: recoveredRange,
                DATE: recoverdedDate
            } : {}


        updateCampaignID(response.data.id)
        updateCampaignName(response.data.name)
        updateSelectedGroup(response.data.groups.map(each => each.group))
        updateSelectedQueue(recoveredQueue ? recoveredQueue[1] : '')
        updateSelectedSender(recoveredSender ? recoveredSender : '')
        updateSelectedTemplate(recoveredTemplate ? recoveredTemplate : '')
        updateParameters(recoveredParams)
        updateStep(response.data.step > 4 ? 4 : response.data.step)
        updateTimeSelector(recoveredPeriodic)
        updateDateTimeSelector(recoveredDateTimeSelector)
        updateReady(true)
    }

    useEffect(() => {
        changeData()
    }, [campaignName
        , selectedGroup
        , selectedQueue
        , selectedSender
        , selectedTemplate
        , actualStep
        , timeSelector
        , dateTimeSelector])


    useEffect(() => {
        updateSendNow(false)
    }, [props.data])


    useEffect(() => {
        if (props.selectedKey > 0) {
            recoverCampaignData(props.selectedKey)
            updateReady(false)
        }


        return (() => { })
    }, [props.open])

    return (

        <div>
            {paramModal ?
                <>
                    <Dialog keepMounted
                        open={props.open}
                        onClose={() => { }}
                        aria-labelledby="customized-dialog-title"
                        fullWidth
                        disablePortal
                        disableEnforceFocus
                        sx={{ position: 'absolute' }}
                    >
                        <DialogTitle id="customized-dialog-title">
                            Enviar notificação aos clientes selecionados
                        </DialogTitle>
                        <Divider />
                        <DialogContent>
                            <Box>
                                {selectedTemplate.components[0].text}
                                <h6 className="push-top">
                                    Preencha as variáveis do template abaixo:
                                </h6>
                                {parameters.map((parameter, i) => {
                                    const variableIndex = i + 1;
                                    return (
                                        <TextField
                                            key={`parameter${i}`}
                                            size="small"
                                            label={"{{" + variableIndex + "}}"}
                                            fullWidth
                                            type="text"
                                            variant="outlined"
                                            defaultValue={parameter.value}
                                            onChange={e => handleParamChange(e, i)}
                                            className="input-parameter"
                                        />
                                    )
                                })}
                            </Box>
                        </DialogContent>
                        <DialogActions style={{ padding: "10px 20px 10px 10px" }}>
                            <Button
                                onClick={handleParamGoBack}

                                color="primary"
                            >
                                Cancelar
                            </Button>
                            <Button
                                onClick={() => { updateParamModal(false) }}
                                disabled={!allParamsFilled}
                                color="primary"
                            >
                                Salvar
                            </Button>
                        </DialogActions>
                    </Dialog>
                </>
                : null}
            {ready &&
                <Dialog keepMounted
                    open={props.open}
                    onClose={props.handleClose}
                    aria-labelledby="customized-dialog-title"
                    fullWidth
                    disablePortal
                    disableEnforceFocus
                    sx={{ position: 'absolute' }}
                >
                    <DialogTitle id="customized-dialog-title" className={'group-flexbox'}>
                        <span>{props.title}</span> <Button sx={{ 'minWidth': '2rem', 'padding': '0' }} onClick={() => { props.handleClose() }} ><CloseIcon /></Button>
                    </DialogTitle>
                    <Divider />
                    <DialogContent>

                        <MultiStep
                            onSubmit={submit} // which function to call after submit
                            canNext={canNext} // Is able to click next?
                            getStep={updateStep} // Store actual step
                            onNext={onNext} // Function to call when clicking next.
                            onOpen={actualStep}
                            camp1={ // You can add multiple steps here with any name
                                <>
                                    <TextField
                                        size="small"
                                        label="Nome da campanha"
                                        fullWidth
                                        type="text"
                                        variant="outlined"
                                        className="push-top push-bottom"
                                        value={campaignName}
                                        onChange={(event) => { updateCampaignName(event.target.value) }}
                                    />
                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                        <FormControl
                                            fullWidth
                                            size="small"
                                        >
                                            <InputLabel id="demo-simple-select-label">Segmentos</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                label="Segmentos"
                                                multiple={true}
                                                value={selectedGroup}
                                                onChange={handleChangeGroup}
                                                renderValue={(selected) => (
                                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                        {selected.map((value) => (
                                                            <Chip key={`weekday${value}`} label={clientGroup.find(group => group.id == value).name} />
                                                        ))}
                                                    </Box>)}
                                            >
                                                {clientGroup.map((queue) => (
                                                    <MenuItem key={`group-select-label${queue.id}`} value={queue.id}>{queue.name}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </>}
                            camp2={
                                <>
                                    <Grid
                                        container
                                        spacing={5}
                                        className="selections-template"
                                    ></Grid>
                                    <Grid
                                        container
                                        spacing={3}
                                        className="selections-template"
                                    >
                                        <Grid item lg={6} md={6} sm={6} xs={6}>
                                            <FormControl
                                                fullWidth
                                                size="small"
                                            >
                                                <InputLabel id="demo-simple-select-label">Fila</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    label="Fila"
                                                    onChange={(event) => updateSelectedQueue(event.target.value)}
                                                    value={selectedQueue}
                                                >
                                                    {queues.map((queue) => (
                                                        <MenuItem key={queue[1]} value={queue[1]}>{queue[0]}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={6} xs={6}>
                                            <FormControl
                                                fullWidth
                                                size="small"
                                            >
                                                <InputLabel id="demo-simple-select-label">Número de origem</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    label="Número de origem"
                                                    onChange={(event) => updateSelectedSender(event.target.value)}
                                                    value={selectedSender}
                                                >
                                                    {props.senders.map((queue) => (
                                                        <MenuItem key={queue} value={queue}>{queue}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <h3>Selecione o template que será enviado.</h3>
                                    <List className='newCampaignTemplate'>
                                        {props.templates.map((template, i) => (
                                            <ListItem className={selectedTemplate == template
                                                ? "template-item selected"
                                                : "template-item"}
                                                key={i}
                                            >
                                                <ListItemText primary={template.name}
                                                    secondary={template.components[0].text}
                                                    onClick={() => { handleSelectedTemplate(template) }} />
                                            </ListItem>
                                        ))}
                                        {props.templates.length === 0 &&
                                            <Box>Não há templates pré-aprovados para reativação.</Box>
                                        }
                                    </List>
                                </>
                            }
                            camp3={<TimeSelector
                                state={timeSelector}
                                onChange={updateTimeSelector}
                                resetSelector={updateDateTimeSelector} />}
                            camp4={<DateTimeSelector
                                time={timeSelector}
                                state={dateTimeSelector}
                                onChange={updateDateTimeSelector}
                                canNext={updateCanNext} />}
                            camp5={<CampaignSummary
                                name={campaignName}
                                groups={clientGroup}
                                groupkey={selectedGroup}
                                queues={queues}
                                queue={selectedQueue}
                                sender={selectedSender}
                                template={selectedTemplate}
                                time={timeSelector}
                                datetime={dateTimeSelector}
                            />
                            }
                        />
                    </DialogContent>
                </Dialog>
            }
        </div>

    )
}

export default CreateCampaignModal
