import React, { useMemo } from 'react'

import { Grid, Box, List, ListItem } from '@mui/material'

import Typography from '../../Typography'
import TooltipIndicators from '../Tooltip'
import QueuesLegend from '../QueueLegend'

import {
    BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip,
    Legend, ResponsiveContainer, PieChart, Pie, Sector
} from 'recharts';
import { renderActiveShape } from './shapes'

const renderActiveShape2 = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value
    } = props;

    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;

    const textAnchor = cos >= 0 ? "start" : "end";

    return (
      <g>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={fill}
          fill="none"
        />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill="#333"
          fontSize="18px"
        >
          {`${value}`}
        </text>
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          dy={18}
          textAnchor={textAnchor}
          fill="#999"
          fontSize="12px"
        >
          {`${(percent * 100).toFixed(0)}%`}
        </text>
      </g>
    );
  }

const loadChartComponentv2 = ({ active, payload }) => {
    if (active && payload && payload.length) {
        return (
            <Box style={{ padding: "1rem"}} className="dashboard-custom-tooltip">
                <Box style={{ fontWeight: 100 }} className="dashboard-custom-tooltip-label">
                    {payload[0].name}
                </Box>
            </Box>
        );
    }

    return null;
};

const loadChartComponent = ({ active, payload }) => {
    if (active && payload && payload.length) {

        const operators = payload[0].payload.operators
        if (operators.length > 0)
            return (
                <Box className="dashboard-custom-tooltip">
                    <Box className="dashboard-custom-tooltip-label">{payload[0].name}:</Box>
                    <List className="dashboard-custom-tooltip-list">
                        {operators.map((operator, index) => (
                            <ListItem key={`dashboard-operator-${index}`}>
                                <Box>{operator.name}</Box><Box>Atendimentos: {operator.onchat}</Box></ListItem>
                        ))}
                    </List>
                </Box>
            );
    }

    return null;
};


const Graphs = (parent) => {


    const showCustomCaption = () => {
        return (
            <Box className="queues-legend">
                {parent.state.queue_names.map((queue, index) => (
                    <QueuesLegend key={index} color={queue.color ? queue.color : 'red'} queue={queue.description} />
                ))}
            </Box>
        );
    }

    const graphs = () => {

        return (
            <Grid container spacing={2} id="dash-grid">
                <Grid item lg={4} md={4} sm={12} xs={12} id="grid-item">
                    <Grid container spacing={2} id="dash-grid">
                        <Grid item lg={12} md={12} sm={12} xs={12} id="grid-item">
                            <Box id="big-frame-2" fullWidth>
                                <Box className='tooltip-centralizer'>
                                    <TooltipIndicators text="Total de atendimentos ao longo do dia." />
                                </Box>
                                <Box className="fontSize-title">
                                    <Typography align="center"
                                        text="Picos de Atendimento"
                                        color="darkGray"
                                        fontWeight='500'
                                        variant="subtitle1"
                                    />
                                </Box>
                                <Box className="barGraph">
                                    <ResponsiveContainer
                                        width="95%"
                                        height={
                                            parent.state.queue_names.length > 2
                                                ? '90%'
                                                : '100%'
                                        }
                                    >
                                        <BarChart
                                            className={
                                                parent.state.queue_names.length > 2
                                                    ? 'bar-chart-dash'
                                                    : null
                                            }
                                            width={500}
                                            height={300}
                                            data={parent.state.timeData}
                                            margin={{
                                                top: 5,
                                                right: 30,
                                                left: 20,
                                                bottom: 5,
                                            }}
                                            key={'data-key-container'}
                                        >
                                            <XAxis dataKey="hour" />
                                            <YAxis />
                                            <Tooltip />
                                            <Legend />
                                            {parent.state.queue_names.map((queue, index) => (
                                                <Bar key={index} name={queue.description} dataKey={queue.name} stackId="a" fill={queue.color ? queue.color : 'red'} />
                                            ))}
                                        </BarChart>

                                    </ResponsiveContainer>
                                    {parent.state.queue_names.length > 2 && showCustomCaption()}
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} id="grid-item">
                            <Box id="big-frame-2" fullWidth>
                                <Box className='tooltip-centralizer'>
                                    <TooltipIndicators text="Chamadas por Canais ao longo do dia." />
                                </Box>
                                <Box className="fontSize-title">
                                    <Typography align="center"
                                        text="Canais de atendimento"
                                        color="darkGray"
                                        fontWeight='500'
                                    />
                                </Box>
                                <PieChart width={500} height={240} style={{ height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                {Array.isArray(parent.state.channels) && (
                                    <Pie
                                        activeIndex={parent.state.activeIndex_opa}
                                        activeShape={renderActiveShape2}
                                        data={parent.state.channels.filter(channel => channel.name !== 'INTEGRATION' && channel.name !== 'AUTOMATION' && channel.name !== 'API')}
                                        cx={250}
                                        cy={100}
                                        innerRadius={0}
                                        outerRadius={70}
                                        fill="#8884d8"
                                        dataKey="value"
                                        onMouseEnter={(_, index) => parent.setState({ activeIndex_opa: index })}

                                    />
                                )}
                                    <Tooltip offset={-50} content={loadChartComponentv2} />
                                    <Legend
                                        layout="horizontal" 
                                        verticalAlign="bottom" 
                                        align="center"
                                    />
                                </PieChart>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12} id="grid-item">
                    <Grid container spacing={2} id="dash-grid" key={parent.state.key}>
                        <Grid item lg={12} md={12} sm={12} xs={12} id="grid-item">
                            <Box id="big-frame-2" fullWidth>
                                <Box className='tooltip-centralizer'>
                                    <TooltipIndicators text="Total de atendimentos realizados e perdidos nas últimas 24 horas." />
                                </Box>
                                {parent.state.attendanceStatusTotal > 0 &&
                                    <Typography align="center" text="Atendimentos" color="darkGray" fontWeight='500' variant="subtitle1" />
                                }
                                {parent.state.attendanceStatusTotal > 0 &&
                                    <Box id="operatorsGraph">
                                        <PieChart width={400} height={400} id="callsAndOperatorsGraph">
                                            <Pie
                                                activeIndex={parent.state.activeIndex_op}
                                                activeShape={renderActiveShape}
                                                data={parent.state.attendanceStatus}
                                                cx={200}
                                                cy={200}
                                                innerRadius={60}
                                                outerRadius={80}
                                                fill="#8884d8"
                                                dataKey="value"
                                                onMouseEnter={(_, index) => parent.setState({ activeIndex_op: index })}
                                            />
                                        </PieChart>
                                    </Box>
                                }
                                {!parent.state.attendanceStatusTotal &&
                                    <Box className="fontSize-title" id="tooltip-null">
                                        <Typography align="center"
                                            text="Sem dados de atendimentos"
                                            color="darkGray"
                                            fontWeight='400'
                                        />
                                    </Box>
                                }
                            </Box>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} id="grid-item">
                            <Box id="small-frame" fullWidth key={parent.state.key}>
                                <Box className='dash-centralizer'>
                                    {Number(parent.state.npsTotal) > 0 &&
                                        <Box className="graphCircle" id="dash-box-1" marginRight="1vw">
                                            <Box id="operatorsGraph">
                                                <PieChart width={400} height={400} id="npsGraph">
                                                    <Pie
                                                        activeIndex={parent.state.activeIndex_np}
                                                        activeShape={renderActiveShape}
                                                        data={parent.state.npsStatus}
                                                        cx={200}
                                                        cy={200}
                                                        innerRadius={60}
                                                        outerRadius={80}
                                                        fill="#8884d8"
                                                        dataKey="value"
                                                        onMouseEnter={(_, index) => parent.setState({ activeIndex_np: index })}
                                                    />
                                                </PieChart>
                                            </Box>
                                        </Box>
                                    }
                                    {parent.state.npsTotal > 0 &&
                                        <Box id="dash-box-1">
                                            <Box id="title-length">
                                                <Typography align="center"
                                                    text="Nota NPS"
                                                    color="darkGray"
                                                    fontWeight='400'
                                                    variant="subtitle1"
                                                />
                                            </Box>
                                            {parent.state.npsGrade >= 9 &&
                                                <Box className="dash-values" id="color-green">
                                                    {parent.state.npsGrade.toFixed(0)}
                                                </Box>
                                            }
                                            {parent.state.npsGrade < 9 && parent.state.npsGrade >= 7 &&
                                                <Box className="dash-values" id="color-yellow">
                                                    {parent.state.npsGrade.toFixed(0)}
                                                </Box>
                                            }
                                            {parent.state.npsGrade < 7 &&
                                                <Box className="dash-values" id="color-red">
                                                    {parent.state.npsGrade.toFixed(0)}
                                                </Box>
                                            }
                                        </Box>
                                    }
                                    {!Number(parent.state.npsTotal) &&
                                        <Box className="fontSize-title" id="tooltip-null-2">
                                            <Typography align="center"
                                                text="Sem dados da pesquisa NPS"
                                                color="darkGray"
                                                fontWeight='400'
                                            />
                                        </Box>
                                    }
                                </Box>
                                <TooltipIndicators text="Em uma escala de 0 a 10, quanto um cliente recomendaria 
                            sua empresa, seus produtos e/ou serviços para seus amigos, 
                            familiares e conhecidos nas últimas 24 horas." />
                            </Box>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} id="grid-item">
                            <Box id="small-frame" fullWidth>
                                <Box className='dash-centralizer'>
                                    {parent.state.csatGrade > 0 &&
                                        <Box className="graphCircle" id="dash-box-1" marginRight="1vw">
                                            <Box id="csatGraph" width="6vw">
                                                <PieChart width={800} height={400} onMouseEnter={(_, index) => parent.setState({ activeIndex_cs: index })}>
                                                    <Pie
                                                        data={parent.state.csat}
                                                        cx={220}
                                                        cy={100}
                                                        startAngle={180}
                                                        endAngle={0}
                                                        innerRadius={60}
                                                        outerRadius={80}
                                                        fill="#8884d8"
                                                        paddingAngle={5}
                                                        dataKey="value"
                                                    >
                                                        {parent.state.csat.map((csatValue, index) => (
                                                            <Cell key={`cell-${index}`} fill={csatValue.color} />
                                                        ))}
                                                    </Pie>
                                                </PieChart>
                                            </Box>
                                        </Box>
                                    }

                                    {parent.state.csatGrade > 0 &&
                                        <Box id="dash-box-1">
                                            <Box className="fontSize-title">
                                                <Typography align="center"
                                                    text="Nota CSAT"
                                                    color="darkGray"
                                                    fontWeight='400'
                                                    variant="subtitle1"
                                                />
                                            </Box>
                                            {parent.state.csatGrade >= 4.0 &&
                                                <Box className="dash-values" id="color-green">
                                                    {parent.state.csatGrade.toFixed(1)}
                                                </Box>
                                            }
                                            {parent.state.csatGrade < 4.0 && parent.state.csatGrade >= 3.0 &&
                                                <Box className="dash-values" id="color-yellow">
                                                    {parent.state.csatGrade.toFixed(1)}
                                                </Box>
                                            }
                                            {parent.state.csatGrade < 3.0 &&
                                                <Box className="dash-values" id="color-red">
                                                    {parent.state.csatGrade.toFixed(1)}
                                                </Box>
                                            }
                                        </Box>
                                    }
                                    {!parent.state.csatGrade &&
                                        <Box className="fontSize-title" id="dash-box-1">
                                            <Typography align="center"
                                                text="Sem dados da pesquisa CSAT"
                                                color="darkGray"
                                                fontWeight='400'
                                            />
                                        </Box>
                                    }
                                </Box>
                                <TooltipIndicators text="Em uma escala de 0 a 5, qual a percepção que os seus clientes têm 
                            sobre os produtos e serviços oferecidos pela sua empresa nas últimas 24 horas." />
                            </Box>
                        </Grid>

                    </Grid>
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12} id="grid-item">
                    <Grid container spacing={2} id="dash-grid" key={parent.state.key}>
                        <Grid item lg={12} md={12} sm={12} xs={12} id="grid-item">
                            <Box id="big-frame-2" fullWidth>
                                <Box className='tooltip-centralizer'>
                                    <TooltipIndicators text="Total de operadores onlines e/ou offlines no Kwik." />
                                </Box>
                                {parent.state.operatorsStatusTotal > 0 &&
                                    <Box className="fontSize-title">
                                        <Typography align="center"
                                            text="Status dos Operadores"
                                            color="darkGray"
                                            fontWeight='500'
                                            variant="subtitle1"
                                        />
                                    </Box>
                                }
                                {parent.state.operatorsStatusTotal > 0 &&
                                    <Box id="operatorsGraph">
                                        <PieChart width={400} height={400} id="callsAndOperatorsGraph">
                                            <Pie
                                                activeIndex={parent.state.activeIndex_at}
                                                activeShape={renderActiveShape}
                                                data={parent.state.operatorsStatus}
                                                cx={200}
                                                cy={200}
                                                innerRadius={60}
                                                outerRadius={80}
                                                fill="#8884d8"
                                                dataKey="value"
                                                onMouseEnter={(_, index) => parent.setState({ activeIndex_at: index })}
                                            />
                                            <Tooltip offset={-50} content={loadChartComponent} />
                                        </PieChart>
                                    </Box>
                                }
                                {!parent.state.operatorsStatusTotal &&
                                    <Box className="fontSize-title" id="tooltip-null">
                                        <Typography align="center"
                                            text="Sem dados de operadores"
                                            color="darkGray"
                                            fontWeight='400'
                                        />
                                    </Box>
                                }
                            </Box>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} id="grid-item">
                            <Box id="small-frame" fullWidth>
                                <Box className='dash-centralizer'>
                                    <Box id="dash-box-1">

                                        <Box className="fontSize-title">
                                            <Typography align="center"
                                                text="Tempo Médio de Atendimento"
                                                color="darkGray"
                                                fontWeight='500'
                                                variant="subtitle1"
                                            />
                                        </Box>


                                        <Box className="dash-values" id="color-blue">
                                            {(parent.state.TMA > 0)
                                                ? new Date(parent.state.TMA * 1000).toISOString().substr(11, 8)
                                                : '00:00:00'
                                            }

                                        </Box>
                                    </Box>
                                </Box>
                                <TooltipIndicators text="Tempo médio dos atendimentos nas últimas 24 horas." />
                            </Box>
                        </Grid>

                        <Grid item lg={6} md={6} sm={6} xs={6} id="grid-item">
                            <Box id="small-frame" fullWidth>
                                <Box className='dash-centralizer'>
                                    <Box id="dash-box-1">
                                        <Box className="fontSize-title">
                                            <Typography align="center"
                                                text="Tempo Médio de Resposta"
                                                color="darkGray"
                                                fontWeight='500'
                                                variant="subtitle1"
                                            />
                                        </Box>
                                        <Box className="dash-values" id="color-blue">
                                            {(parent.state.TMR > 0)
                                                ? new Date(parent.state.TMR * 1000).toISOString().substr(11, 8)
                                                : '00:00:00'
                                            }
                                        </Box>
                                    </Box>
                                </Box>
                                <TooltipIndicators text="Tempo médio de resposta dos operadores nas últimas 24 horas." />
                            </Box>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={6} id="grid-item">
                            <Box id="small-frame" fullWidth>
                                <Box className='dash-centralizer'>
                                    <Box id="dash-box-1">
                                        <Box className="fontSize-title">
                                            <Typography align="center"
                                                text="Tempo Médio de Espera"
                                                color="darkGray"
                                                fontWeight='500'
                                                variant="subtitle1"
                                            />
                                        </Box>
                                        <Box className="dash-values" id="color-blue">
                                            {(parent.state.TME > 0)
                                                ? new Date(parent.state.TME * 1000).toISOString().substr(11, 8)
                                                : '00:00:00'
                                            }
                                        </Box>
                                    </Box>
                                </Box>
                                <TooltipIndicators text="Tempo médio de espera em fila nas últimas 24 horas." />
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid >
        )
    }

    return (
        <>
            {graphs()}
        </>
    )
}

export default Graphs
