import React from 'react'
import OptionsMenu from '../../utils/OptionsMenu'
import './styles.css'
import QueuePage from './Pages/queue-page'
import UsersPage from './Pages/users-page'
import userPermissions from '../User/Permissions'
import IntegrationPage from './Pages/integration-page'
import { getSession } from '../../auth'
import OnChatPage from './Pages/onchat-page'
import PhonePage from './Pages/phone-page'
import ScoutPage from './Pages/scout-page'

const options = () => {
    const groups = userPermissions();
    const enterprise = getSession()?.profile?.enterprise
    return {
        queues: {
            permissions: groups.isSupervisor,
            label: 'Filas',
            component: (<QueuePage />)
        },
        users: {
            permissions: groups.isSupervisor,
            label: 'Usuários',
            component: (<UsersPage />)
        },
        integrations: {
            permissions: groups.isAdmin,
            label: 'Integrações',
            component: (<IntegrationPage />)
        },
        onchat: {
            permissions: groups.isAdmin,
            label: 'Atendimento',
            component: (<OnChatPage />)
        },
        phone: {
            permissions: groups.isAdmin && enterprise.has_voice,
            label: 'Telefonia',
            component: (<PhonePage />)
        },
        scout: {
            permissions: groups.isAdmin,
            label: 'Scout',
            component: (<ScoutPage />)
        }
    }
}

const SettingsV2 = () => {
    return (
        <OptionsMenu title='Configurações' name='settings-v2' options={options} />
    )
}


export default SettingsV2