import React, { useState } from 'react';
import { Box, Dialog, DialogTitle, DialogActions, DialogContent, Divider, Typography, TextField, Button } from '@mui/material';
import { generate_scheduled_report } from './detailed_register_functions';
import useNotification from '../../Notification'

export const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
};

const ReportExportData = ({ parent, setParent, type }) => {
    const [email, setEmail] = useState('');
    const [isValidEmail, setIsValidEmail] = useState(true);

    const handleEmailChange = (event) => {
        const inputEmail = event.target.value;
        setEmail(inputEmail);
        setIsValidEmail(validateEmail(inputEmail));
    };

    const scheduleReport = async () => {
        if (!isValidEmail) {
            console.log('O e-mail inserido não é válido.');
            return;
        }
        
        setParent({loading:true})
        const res = await generate_scheduled_report(parent, {recipients:email, period:"instant"}, type)
        if (res === 200 || res === 201){
            useNotification(
                'Sucesso!',
                'O relatório será enviado para o e-mail escolhido assim que processado.',
                'success'
            );
        } else {
            useNotification(
                'Falha!',
                'O relatório não foi enviado. Por favor tente novamente.',
                'danger'
            );
        }
        setParent({loading:false})
        setParent({'activeDialog': null})
    }

    return (
        <>
            <Dialog
                open={true}
                disablePortal
                disableEnforceFocus
                sx={{ position: 'absolute' }}
            >
                <DialogTitle>Geração de relatório em {type}</DialogTitle>
                <Divider />
                <DialogContent>
                    <Typography fontSize="12px" fontWeight="500" color="#21446c">
                        O relatório será enviado por email, para isso, digite-o abaixo.
                    </Typography>
                    <TextField
                        size='small'
                        label="Email"
                        variant="outlined"
                        fullWidth
                        sx={{ mt: 2, mb: 2 }}
                        value={email}
                        onChange={handleEmailChange}
                        error={!isValidEmail}
                        helperText={!isValidEmail && "Por favor, insira um e-mail válido"}
                        inputProps={{
                            maxLength: 100
                        }}
                    />
                </DialogContent>
                <DialogActions sx={{ padding: "0px 20px 10px 0px" }}>
                    <Button onClick={() => setParent({ 'activeDialog': null })}>Cancelar</Button>
                    <Button disabled={email.length === 0 || !isValidEmail} onClick={scheduleReport}>
                        Enviar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ReportExportData;