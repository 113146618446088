import React, { useState, useEffect, useRef } from 'react'

import { Dialog, DialogActions, DialogContent, DialogTitle, Box, Slide, TextField, List, ListItem, Chip } from '@mui/material'
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import { FileDrop } from 'react-file-drop';
import ImportIcon from '@mui/icons-material/Upload';
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ListItemButton from '@mui/material/ListItemButton';

import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import VariableTextField from '../../../Input/VariableTextField';

const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} placement="top" />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 200,
    },
});

export const Dropdown = ({ target, index = '' }) => {

    const dropref = useRef()
    const buttonref = useRef()
    const [showOptions, setShowOptions] = useState(false)

    const title_message = `
    selecione opcionalmente uma das variáveis abaixo.
    `

    const setTarget = (variable, index = ' ') => {
        target(variable, index)
        setShowOptions(false)
    }

    function getAbsPosition(element) {
        var rect = element.getBoundingClientRect();
        return { x: rect.left, y: rect.top }
    }

    const moveDiv = () => {


        const selectedDiv = dropref?.current
        const selectedButton = buttonref?.current

        const coords = getAbsPosition(selectedButton);

        const posX = coords.x - selectedDiv.clientWidth
        const posY = coords.y

        selectedDiv.style.left = `${posX}px`
        selectedDiv.style.top = `${posY}px`

        setShowOptions(true)
        
    }


    return (
        <>
            <CustomWidthTooltip title={title_message}>
                <IconButton ref={buttonref} className={'absolute-button'} onClick={e => { e.preventDefault(); moveDiv();  }}>
                    <KeyboardArrowDownIcon />
                </IconButton>
            </CustomWidthTooltip>

            <Box className={`select-pop-up-backdrop ${showOptions ? 'active': ''}`} onClick={() => setShowOptions(false)}></Box>

            <Box ref={dropref} className={`select-pop-up ${showOptions ? 'active': ''}`}>
                <List className='select-list insiderlist'>
                    <ListItemButton
                        className='select-list-item'
                        onClick={() => { setTarget('{{nome_cliente}}', index) }}
                    >
                        Nome do cliente
                    </ListItemButton>
                    <ListItemButton
                        className='select-list-item'
                        onClick={() => { setTarget('{{nome_operador}}', index) }}
                    >
                        Nome do operador
                    </ListItemButton>
                    <ListItemButton
                        className='select-list-item'
                        onClick={() => { setTarget('{{codigo_cliente}}', index) }}
                    >
                        Nome do operador
                    </ListItemButton>
                </List>
            </Box>



        </>
    )
}

const ParameterFiller = ({ mediaValues, parameters, submitParameters, setReady }) => {
    const [file, setFile] = useState()
    const [headerParam, setHeaderParam] = useState()
    const [headerKey, setHeaderKey] = useState(0)
    const [bodyKey, setBodyKey] = useState(0)
    const [buttonKey, setButtonKey] = useState(0)
    const [buttonParam, setButtonParam] = useState()
    const [bodyParam, setBodyParam] = useState({})
    const [file_url, setFileUrl] = useState('')
    

    useEffect(() => {

        const header = mediaValues.find(each => each.type === 'HEADER')
        if (header && header.example?.header_handle) {
            setFileUrl(header.example.header_handle[0])
            submitParameters.headerURL = header.example.header_handle[0]
            checkParameters()
        }
        else
            checkParameters()

    }, [])

    const checkParameters = async () => {

        const result = parameters.map(parameter => {
            if (parameter.type === 'HEADER') {
                if ('headerfile' in submitParameters)
                    return true
                if ('headerbody' in submitParameters && submitParameters.headerbody.length > 0)
                    return true
                if ('headerURL' in submitParameters && submitParameters.headerURL.length > 0)
                    return true
            }
            else if (parameter.type === 'BODY') {
                if ('body' in submitParameters && Object.values(submitParameters.body).length == parameter.amount) {
                    const resp = Object.values(submitParameters.body).map(bvalue => (bvalue.length > 0))
                    if (resp.includes(false))
                        return false
                    return true
                }
                return false
            }
            else if (parameter.type === 'BUTTONS') {
                if ('buttons' in submitParameters && submitParameters.buttons.length > 0)
                    return true
                return false
            }
            return false
        })

        if (result.includes(false)) {
            setReady('Preencha todos os parâmetros')
            return
        }

        const displayHeader = mediaValues.find(each => each.type == 'HEADER')
        const displayBody = mediaValues.find(each => each.type == 'BODY')
        let bodyText = ''
        let headerText = ''
        let example_header = headerParam
        let example_bodyList = bodyParam

        if (displayHeader && displayHeader.format == 'TEXT') {
            headerText = displayHeader.text
            headerText = headerText.replace(/{{(\d+)}}/g, function (_, m) { if (example_header && example_header != '' && example_header[0] != '') return example_header; return _; })
        }

        if (displayBody) {
            bodyText = displayBody.text
            bodyText = bodyText.replace(/{{(\d+)}}/g, function (_, m) {
                const testValue = example_bodyList[--m]
                if (testValue && testValue != '')
                    return testValue;
                return _;
            })
        }

        if (bodyText.length > 1024) {
            setReady('Corpo excedeu 1024 caracteres')
            return
        }

        if (headerText.length > 60) {
            setReady('Cabeçalho excedeu 60 caracteres')
            return
        }

        setReady(false)

        return
    }


    const handleBodyValue = async (value, i) => {
        const newBodyParam = () => {
            const newvalue = {}
            newvalue[i] = value
            return { ...bodyParam, ...newvalue }
        }
        const resultBodyParam = newBodyParam()
        await setBodyParam(resultBodyParam)
        submitParameters.body = resultBodyParam
        checkParameters()
    }

    const handleButtonValue = async value => {
        await setButtonParam(value)
        submitParameters.buttons = value
        checkParameters()
    }

    const handleHeaderParam = async value => {
        await setHeaderParam(value)
        submitParameters.headerbody = value
        checkParameters()
    }

    const handleFileInput = f => {
        if (!f)
            return
        setFile(f)
        submitParameters.headerfile = f
        checkParameters()
    }

    const setHeaderUrl = e => {
        setFileUrl(e.target.value)
        submitParameters.headerURL = e.target.value
        checkParameters()
    }

    const clearFile = () => {
        setFile()
        delete submitParameters.headerfile
        checkParameters()
    }

    const renameMedia = (parameter) => {
        switch (parameter) {
            case 'IMAGE':
                return 'uma imagem'
            case 'DOCUMENT':
                return 'um documento'
            case 'VIDEO':
                return 'um video'
            default: return ''
        }
    }

    const acceptDiff = (parameter) => {
        switch (parameter) {
            case 'IMAGE':
                return 'image/png, image/jpeg'
            case 'DOCUMENT':
                return 'application/pdf'
            case 'VIDEO':
                return 'video/mp4'
            default: return ''
        }
    }

    const acceptFormats = (parameter) => {
        switch (parameter) {
            case 'IMAGE':
                return 'png, jpeg'
            case 'DOCUMENT':
                return 'pdf'
            case 'VIDEO':
                return 'mp4'
            default: return ''
        }
    }

    const buildParamList = parameters.map(parameter => {
        switch (parameter.type) {
            case 'HEADER':
                return (
                    <>
                        <h6 className="push-top noBottom">
                            Cabeçalho:
                        </h6>
                        {parameter.format == 'TEXT' ?
                            <VariableTextField
                                size="small"
                                label={"{{1}}"}
                                fullWidth={true}
                                type="text"
                                variant="outlined"
                                value={headerParam}
                                onChange={(event) => handleHeaderParam(event.target.value)}
                                onVariableSelect={(value) => {
                                    handleHeaderParam(value)
                                    setHeaderKey(`handle-header-key-${Math.random()}`)
                                }}
                                key={headerKey}
                            />
                            :
                            <>
                                <div className="file-drop-container">
                                    <FileDrop
                                        onDrop={(files, event) => { handleFileInput(files[0]); event.target.value = null }}
                                    >
                                        {file ? <Chip label={file.name} onDelete={clearFile} /> :
                                            <span>Arraste e solte para inserir {renameMedia(parameter.format)}. Apenas [{acceptFormats(parameter.format)}]
                                            </span>
                                        }
                                        <span>ou</span>
                                        <LoadingButton
                                            variant="contained"
                                            color="primary"
                                            component="label"
                                            loading={false}
                                            loadingPosition="start"
                                            startIcon={<ImportIcon />}
                                            disabled={false}
                                            onClick={() => { }}
                                        >
                                            Selecione {renameMedia(parameter.format)}
                                            <input
                                                hidden
                                                accept={acceptDiff(parameter.format)}
                                                type="file"
                                                onChange={(e) => { handleFileInput(e.target.files[0]); e.target.value = null }}
                                            />
                                        </LoadingButton>
                                        <span style={{ margin: '8px 0px' }}>ou</span>
                                        <Tooltip title={`Alternativamente ao arquivo, coloque uma URL de um ${renameMedia(parameter.format)}`}>
                                            <TextField
                                                label='URL'
                                                size='small'
                                                disabled={file}
                                                value={file_url}
                                                onChange={setHeaderUrl}
                                            />
                                        </Tooltip>
                                    </FileDrop>
                                </div>
                            </>
                        }
                    </>
                )
            case 'BODY':
                return (
                    <>
                        <h6 className="push-top noBottom">
                            Corpo:
                        </h6>
                        {[...Array(parameter.amount).keys()].map((parameter, i) => {
                            const variableIndex = i + 1;
                            return (
                                <VariableTextField
                                    size="small"
                                    label={"{{" + variableIndex + "}}"}
                                    fullWidth={true}
                                    type="text"
                                    variant="outlined"
                                    value={bodyParam[i]}
                                    onChange={(event) => handleBodyValue(event.target.value, i)}
                                    onVariableSelect={(value) => {
                                        handleBodyValue(value, i)
                                        setBodyKey(`handle-body-key-${Math.random()}`)
                                    }}
                                    key={bodyKey+'-'+i}
                                />
                            )
                        })}
                    </>
                )
            case 'BUTTONS':
                return (
                    <>
                        <h6 className="push-top noBottom">
                            Botões:
                        </h6>
                        <Box className='relative-box'>
                            <VariableTextField
                                size="small"
                                label={"{{1}}"}
                                fullWidth={true}
                                type="text"
                                variant="outlined"
                                value={buttonParam}
                                onChange={(event) => handleButtonValue(event.target.value)}
                                onVariableSelect={(value) => {
                                    handleButtonValue(value)
                                    setButtonKey(`handle-button-key-${Math.random()}`)
                                }}
                                key={buttonKey}
                            />
                        </Box>
                    </>
                )
            default:
                return
        }

    }
    )

    const preview = () => {


        const displayHeader = mediaValues.find(each => each.type == 'HEADER')
        const displayBody = mediaValues.find(each => each.type == 'BODY')
        const displayFooter = mediaValues.find(each => each.type == 'FOOTER')
        const displayButtons = mediaValues.find(each => each.type == 'BUTTONS')

        let example_header = headerParam
        let example_file = file
        let example_url = buttonParam
        let example_bodyList = bodyParam


        let headerText = ''
        let bodyText = ''
        let headerURL = ''
        let headerDOC = () => { }

        if (displayHeader && displayHeader.format == 'TEXT') {
            headerText = displayHeader.text
            headerText = headerText.replace(/{{(\d+)}}/g, function (_, m) { if (example_header && example_header != '' && example_header[0] != '') return example_header; return _; })
        }
        if (displayHeader && displayHeader.format != 'TEXT') {
            if (example_file) {
                headerURL = URL.createObjectURL(example_file)
                if (displayHeader.format == 'IMAGE') {
                    headerDOC = () => { return (<img alt='' src={headerURL} />) }
                }
                else if (displayHeader.format == 'VIDEO') {
                    headerDOC = () => { return (<embed src={headerURL} />) }
                }
                else if (displayHeader.format == 'DOCUMENT') {
                    headerDOC = () => { return (<Box className={'wpp-doc-display'} onClick={() => { window.open(headerURL) }}>{example_file.name}</Box>) }
                }
            }
            else if (file_url) {
                headerURL = file_url
                if (displayHeader.format == 'IMAGE') {
                    headerDOC = () => { return (<img alt='' src={headerURL} />) }
                }
                else if (displayHeader.format == 'VIDEO') {
                    headerDOC = () => { return (<embed src={headerURL} />) }
                }
                else if (displayHeader.format == 'DOCUMENT') {
                    headerDOC = () => { return (<Box className={'wpp-doc-display'} onClick={() => { window.open(headerURL) }}>{headerURL}</Box>) }
                }
            }

            // if (example_file[0] != '')
            //     headerIMG = URL.createObjectURL(example_file[0])
        }



        if (displayBody) {
            bodyText = displayBody.text
            bodyText = bodyText.replace(/{{(\d+)}}/g, function (_, m) {
                const testValue = example_bodyList[--m]
                if (testValue && testValue != '')
                    return testValue;
                return _;
            })
        }


        return (
            <>
                <Box className='previewBoxStyle'>
                    <Box className='wpp-card-main'>
                        {headerDOC ? <Box className={'wpp-media-display'}>{headerDOC()}</Box> : null}
                        {headerText ? <h5 style={{ whiteSpace: "pre-line", wordBreak: "break-all" }}>{headerText}</h5> : null}
                        {bodyText ? <div style={{ whiteSpace: "pre-line", wordBreak: "break-all" }}>{bodyText}</div> : null}
                        {displayFooter ? <h8 style={{ fontSize: "0.8rem", color: "#999999" }}>{displayFooter.text}</h8> : null}
                    </Box>
                    {displayButtons ? displayButtons.buttons.map(button => {

                        let newbuttonurl = ''
                        if ('url' in button) {
                            newbuttonurl = button.url
                            newbuttonurl = newbuttonurl.replace(/{{(\d+)}}/g, function (_, m) { if (example_url && example_url != '' && example_url[0] != '') return example_url; return _; })
                        }

                        return (
                            <Box
                                className='wpp-card-button'
                                onClick={newbuttonurl != '' ? () => { window.open(newbuttonurl) } : null}>
                                {button.type == 'QUICK_REPLY' ? null : button.type == 'URL' ?
                                    <OpenInNewIcon /> : <PhoneForwardedIcon />}
                                {button.text}

                            </Box>
                        )
                    }

                    ) : null}
                </Box>
            </>
        )
    }


    return (
        <>
            <h4 className="noBottom noTop">
                Preencha os parâmetros abaixo para continuar

            </h4>
            <Box className={'PreviewTemplateDialog'}>
                <Box className={'templateFlexBox'} >
                    <Box className={'wpp-card-param'}>
                        {buildParamList}
                    </Box>
                    <Box className={'wpp-card-box'}>
                        {preview()}
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default ParameterFiller