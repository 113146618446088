const formatPhone = (telephone) => {
    let newTelephone = '';
    if (telephone) {
        const numberTelephone = telephone.replace(/[^0-9]+/g, '');
        if (isNumber(numberTelephone) && numberTelephone.substring(0, 3) !== '+55') {
            const oldTelephone = numberTelephone;
            newTelephone = `+55${oldTelephone}`;
            return newTelephone
        }
    }
    return telephone;
}

const isNumber = (n) => {
    return !isNaN(parseFloat(n)) && isFinite(n);
}

export default formatPhone