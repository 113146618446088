import React, { useState, useEffect } from "react";
import { Box, Grid, FormControlLabel } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
//import PlansForm from "../../components/Forms/PlansForm";
import { useFormik } from "formik";
import Text from "../../components/Text";
import Button from "../../components/Button";
import Loading from "../../components/Loading";
import api from '../../api';
import PlanChoiceImage from "../../assets/images/plan_choice.svg";
import "./styles.css";
import { useHistory } from "react-router-dom";
import Checkbox from "../../components/Button/Checkbox";

const Plans = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const { setFieldValue, values, handleChange } = useFormik({
    initialValues: {
      plan: {
        agents: 1,
        lines: 1,
        enterprise: "http://dev07-pbx.instantsandbox.net:8000/api/enterprises/14/", //Necessita autenticação.
        services: [],
        discounts: [],
      },
      agentsPrice: 30.00,
      linesPrice: 39.90,
      defaultPrice: 109.00,
      services: [{ name: 'Facebook', checked: false, price: 100.0 }, { name: 'WhatsApp', checked: true, price: 100.0 }, { name: 'Instagram', checked: false, price: 100.0 }, { name: 'Telegram', checked: false, price: 100.0 }],
    },

  });

  function totalPrice() {
    let services = 0;
    for (let i = 0; i < values.services.length; i++) {
      if (values.services[i].checked) {
        services += values.services[i].price;
      }
    }
    let plans = (values.agentsPrice * values.plan.agents) + (values.linesPrice * values.plan.lines) + values.defaultPrice;
    let total = plans + services
    return toReal(total);
  }

  function toReal(value) {
    return value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
  }

  function addChecked(services) {
    for (let i = 0; i < services.length; i++) {
      var checked = false;
      services[i].checked = checked;
    }
    return services;
  }

  useEffect(async () => {
    await api.get('api/users/').then(resp => {
      const data = addChecked(resp.data);
      //setFieldValue("services", data);
      console.log(data)
      setLoading(false);
    }).catch(error => {
      console.log(error);
    });
  }, [])

  function filterUserServices(plan) {
    let userServices = [];
    for (let i = 0; i < values.services.length; i++) {
      let service;
      if (values.services[i].checked) {
        let service = {
          service: values.services[i].url,
          plan: plan
        }
        userServices.push(service);
      }
    }
    return userServices;
  }

  const handleSubmit = () => {
    /* api.post('plans/', values.plan).then(resp => {
       console.log(resp.data);
       const userServices = filterUserServices(resp.data.url);
       console.log(userServices);
       api.post(resp.data.url + "services/", userServices).then(resp2 => {
         console.log(resp2.data);
       })
     }).catch(error => {
       console.log(error);
     });*/
  }

  const useStyles = makeStyles(() => ({
    totalPrice: {
      backgroundColor: "#21446c",
      minHeight: "100vh",
      color: "white",
    },
  }));

  const classes = useStyles();

  return (
    <div> {loading && <Loading />}{!loading && (<Box display="flex">
      <Box
        width={"60vw"}
        marginRight={12}
        marginLeft={18}
        marginTop={5}
        marginBottom={5}
      >
        <Box marginBottom={4}>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Box marginTop={5} marginRight={6}>
                <Text type="title" text="CUSTOMIZE SEU PLANO" color="blue" />
                <Text
                  type="subTitle"
                  text="Aqui você pode adapatar o seu plano de acordo com suas necessidades. Preencha abaixo o que gostaria de ter em sua aplicação. "
                />
              </Box>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <img src={PlanChoiceImage} width={"60%"} alt="header" />
            </Grid>
          </Grid>
        </Box>

        <Grid container spacing={2}>
          <Grid item lg={4} md={4} sm={6} xs={12} direction="column">
            <Box>
              <Text type="subTitle" text="Número de agentes" />
              <input
                type="number"
                id="tentacles"
                name="plan.agents"
                min="1"
                max="10"
                value={values.plan.agents}
                onChange={handleChange}
              />
            </Box>
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12} direction="column">
            <Box>
              <Text type="subTitle" text="Número de linhas" />
              <input
                type="number"
                id="tentacles"
                name="plan.lines"
                min="1"
                max="10"
                value={values.plan.lines}
                onChange={handleChange}
              />
            </Box>
          </Grid>
          <Box marginLeft={1} marginTop={2}>
            <Grid container spacing={2}>
              {values.services.map((service, i) =>
              (<Grid item lg={4} md={4} sm={6} xs={12} key={i}>
                <Checkbox
                  id={service.i}
                  checked={service.checked}
                  onChange={handleChange}
                  name={"services[" + i + "].checked"}
                  label={service.name}
                />
              </Grid>))}
            </Grid>
          </Box>
        </Grid>
      </Box>
      <Box className={classes.totalPrice} width={"45vw"}>
        <Box m={10}>
          <Grid container>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Text color="white" type="title" text="TOTAL" />
            </Grid>
          </Grid>
          <Box
            marginTop={2}
            paddingTop={2}
            borderTop={1}
            marginBottom={2}
            paddingBottom={10}
            borderBottom={1}
          >
            <Grid container>
              <Grid item lg={8} md={8} sm={12} xs={12}>
                <Text color="white" type="subTitle" text="  Plano default" />
              </Grid>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <Text color="white" type="subTitle" text={toReal(values.defaultPrice)}
                />
              </Grid>
              <Grid item lg={8} md={8} sm={12} xs={12}>
                <Text
                  color="white"
                  type="subTitle"
                  text={values.plan.agents + " agente(s) disponíveis"}
                />
              </Grid>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <Text color="white" type="subTitle" text={toReal(values.agentsPrice * values.plan.agents)} />
              </Grid>
              <Grid item lg={8} md={8} sm={12} xs={12}>
                <Text
                  color="white"
                  type="subTitle"
                  text={values.plan.lines + " linha(s) disponíveis"}
                />
              </Grid>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <Text color="white" type="subTitle" text={toReal(values.linesPrice * values.plan.lines)} />
              </Grid>
            </Grid>
            {values.services.map((service, i) => (
              <div key={i}>
                {service.checked &&
                  <Grid container>
                    <Grid item lg={8} md={8} sm={12} xs={12}>
                      <Text color="white" type="subTitle" text={service.name} />
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                      <Text color="white" type="subTitle" text={toReal(service.price)} />
                    </Grid>

                  </Grid>
                }

              </div>))}
          </Box>
          <Grid container>
            <Grid item lg={8} md={8} sm={12} xs={12}>
              <Text color="white" type="subTitle" text="Total" />
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <Text color="white" type="subTitle" text={totalPrice()} />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box marginTop='10vh'>
                <Button title="Finalizar pedido" color="secondary" onClick={() => history.push("serviceSettings")} />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box >)} </div>

  );
};

export default Plans;
