import React, { useState } from 'react';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Tooltip,
    Box,
    Switch,
    TextField
} from '@mui/material';

import LoadingButton from '@mui/lab/LoadingButton';

import CloseIcon from '@mui/icons-material/Close';
import HelpIcon from '@mui/icons-material/Help';

import PropTypes from "prop-types";

import VariableTextField from '../../Input/VariableTextField';

import "./styles.css"
import { getSession } from '../../../auth';

const HangupDialog = ({ open, title, description, handleClose, handleSubmit, submitButtonLoading, activeChat }) => {

    const [additionalData, setAdditionalData] = useState(false)
    const [data1Key, setData1Key] = useState()
    const [data1Value, setData1Value] = useState()
    const [data2Key, setData2Key] = useState()
    const [data2Value, setData2Value] = useState()
    const [renderKey1, setRenderKey1] = useState('additional-data-1-0')
    const [renderKey2, setRenderKey2] = useState('additional-data-2-0')
    const [message, setMessage] = useState(getSession().profile.enterprise.settings.default_force_hangup_message || '')

    const submit = () => {
        if (activeChat.chatstatus === 'ONCHAT') {
            const extraData = []
            if (data1Key || data1Value)
                extraData.push({
                    'key': data1Key,
                    'value': data1Value
                })
            if (data2Key || data2Value)
                extraData.push({
                    'key': data2Key,
                    'value': data2Value
                })
            handleSubmit(extraData)
        } else {
            handleSubmit([], message)
        }
    }

    return (
        <div>
            <Dialog open={open} keepMounted onClose={handleClose} aria-labelledby="alert-dialog-slide-title" aria-describedby="alert-dialog-slide-description" disableEnforceFocus>
                <DialogTitle id="alert-dialog-slide-title" className={'group-flexbox'}>
                    <span>{title}</span>
                    <Tooltip title="Fechar" >
                        <Button className="action-button"  >
                            <CloseIcon onClick={handleClose} />
                        </Button>
                    </Tooltip>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description" sx={{display:'flex'}}>
                        {description} {activeChat.chatstatus !== 'ONCHAT' && < Tooltip sx={{ marginLeft: "5px" }} title={`Mensagens podem não chegar ao destinatário final
                                    caso a última mensagem do cliente ultrapasse 24h.
                                    Administradores podem configurar a mensagem padrão em configurações > atendimento > Mensagens de encerramento e 'timeout'
                                    `}>
                            <HelpIcon />
                        </Tooltip>}
                    </DialogContentText>
                    {activeChat.chatstatus === 'ONCHAT' ? <Box className={additionalData ? "additional-data checked" : "additional-data"}>
                        <Switch
                            checked={additionalData}
                            onChange={() => setAdditionalData(!additionalData)}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                        Informações adicionais
                        <Tooltip sx={{ marginLeft: "5px" }} title="Adicione informações extras ao atendimento para utilizar no ChatBot.">
                            <HelpIcon />
                        </Tooltip>
                    </Box>
                        :
                        <Box sx={{ display: 'flex', flexDirection: 'column', paddingTop: '1rem' }}>
                            <TextField
                                multiline
                                rows={4}
                                fullWidth
                                size="small"
                                label={
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <span>Mensagem de finalização {message.length}/5000</span>
                                    </Box>
                                }
                                inputProps={{
                                    maxLength: 5000
                                }}
                                type="text"
                                variant="outlined"
                                value={message}

                                onChange={(event) => setMessage(event.target.value)}
                            />
                        </Box>
                    }
                    {additionalData &&
                        <>
                            <Box className="additional-data-row">
                                <TextField
                                    sx={{ width: "100px" }}
                                    size="small"
                                    label="Chave"
                                    type="text"
                                    variant="outlined"
                                    value={data1Key}
                                    onChange={(event) => setData1Key(event.target.value)}
                                />
                                <VariableTextField
                                    sx={{ flex: 1 }}
                                    size="small"
                                    label="Valor"
                                    type="text"
                                    variant="outlined"
                                    value={data1Value}
                                    onChange={(event) => setData1Value(event.target.value)}
                                    onVariableSelect={(value) => {
                                        setData1Value(data1Value ? data1Value + value : value)
                                        setRenderKey1(`additional-data-1-${Math.random()}`)
                                    }}
                                    key={renderKey1}
                                />
                            </Box>
                            <Box className="additional-data-row">
                                <TextField
                                    sx={{ width: "100px" }}
                                    size="small"
                                    label="Chave"
                                    type="text"
                                    variant="outlined"
                                    value={data2Key}
                                    onChange={(event) => setData2Key(event.target.value)}
                                />
                                <VariableTextField
                                    sx={{ flex: 1 }}
                                    size="small"
                                    label="Valor"
                                    type="text"
                                    variant="outlined"
                                    value={data2Value}
                                    onChange={(event) => setData2Value(event.target.value)}
                                    onVariableSelect={(value) => {
                                        setData2Value(data2Value ? data2Value + value : value)
                                        setRenderKey2(`additional-data-2-${Math.random()}`)
                                    }}
                                    key={renderKey2}
                                />
                            </Box>
                        </>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Não
                    </Button>
                    <LoadingButton onClick={submit} color="primary" loading={submitButtonLoading}>
                        Sim
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </div >
    );
}

HangupDialog.propTypes = {
    open: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string,
    handleClose: PropTypes.func,
    handleSubmit: PropTypes.func,
    submitButtonLoading: PropTypes.bool
};

export default HangupDialog;
