import React, { useState, useEffect, useContext, useReducer } from 'react'
import {
    Box, Dialog, DialogActions, DialogContent, DialogTitle,
    Tooltip, Button, TextField, Divider, Select, MenuItem, InputLabel, FormControl, List, ListItem, IconButton
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import api from '../../../api';
import useNotification from '../../Notification';

import defaultReducer from '../../../utils/defaultReducer';

import { getSession } from '../../../auth';

const action = (data) => {
    if (data?.id)
        return 'Editar'
    return 'Adicionar'
}

const treatnumber = (number) => {
    if (number.length > 0 && number[0] === '+')
        return number
    return `+${number}`
}

const RedirectEditModal = ({ data, setData, getRows, mcdu, channels, setLoading }) => {

    const [state, setState] = useReducer(defaultReducer, {
        user_id: '',
        entrypoint: '',
        mcdu: ''
    })


    const checkButtonText = () => {
        const text = []

        if (state.user_id.length == 0)
            text.push(<Box>Digite o número do cliente.</Box>)

        if (state.entrypoint.length == 0)
            text.push(<Box>Selecione o número do destino.</Box>)

        if (state.mcdu.length == 0)
            text.push(<Box>Selecione o piloto.</Box>)

        if (text.length == 0)
            return ''

        return (text)
    }

    useEffect(async () => {
        if (!data.id)
            return

        setState({
            ...data
        })

    }, [data])

    const submit = async () => {
        let res = null
        const enterpriseId = getSession().profile.enterprise.id
        const url = `api/enterprises/${enterpriseId}/redirect/`

        setLoading(true)
        if (!data?.id)
            res = await api.post(url, state, { timeout: 0 })
        else
            res = await api.patch(url + `${data.id}/`, state, { timeout: 0 })
        setLoading(false)

        if (!(res.status === 200 || res.status === 201)) {
            let message = `Não foi possível ${action(data)} o redirecionamento.`
            const error = res.data?.non_field_errors
            if (res.status === 400 && error && error.length > 0) {
                if (error[0] === 'The fields entrypoint, user_id must make a unique set.')
                    message = `Não foi possível ${action(data)} o redirecionamento. Pois já existe um redirecionamento para esse número com o mesmo destino.`
            }
            else if (res.status === 400 && res.data?.entrypoint_error)
                message = `Não foi possível ${action(data)} o redirecionamento. Pois o número do destino não faz parte dos seus números de whatsapp dessa empresa!`
            else if (res.status === 400 && res.data?.mcdu_error)
                message = `Não foi possível ${action(data)} o redirecionamento. Pois o fluxo não faz parte dos fluxos cadastrados para essa empresa!`
            useNotification(
                'Ops!',
                message,
                'danger'
            )
            return
        }
        getRows()
        setData()
        useNotification(
            'Sucesso!',
            `Redirecionamento ${res.status == 201 ? 'adicionado' : 'atualizado'} com sucesso.`,
            'success'
        )

    }

    return (
        <>
            <Dialog open={true}
                fullWidth
                disablePortal
                disableEnforceFocus
                sx={{ position: 'absolute' }}
            >
                <DialogTitle
                    id="customized-dialog-title" className={'group-flexbox'}>
                    <span>{action(data)} Redirecionamento</span>
                    <Tooltip title="Fechar" >
                        <Button className="action-button"  >
                            <CloseIcon onClick={() => { setData() }} />
                        </Button>
                    </Tooltip>
                </DialogTitle>
                <Divider />
                <DialogContent className='piloto-modal-flex'>

                    <TextField
                        size='small'
                        label='Número do cliente'
                        value={state.user_id}
                        onChange={(e) => setState({ user_id: e.target.value })}
                    />

                    <FormControl size='small'>
                        <InputLabel id='entrypoint-label-id'>Número do destino</InputLabel>
                        <Select
                            value={state.entrypoint}
                            onChange={(e) => setState({ entrypoint: e.target.value })}
                            label='Número do destino'
                            labelId='entrypoint-label-id'
                        >
                            {channels.whatsapp.map((each, index) => (
                                <MenuItem key={`entrypoint-each-item-${index}`} value={treatnumber(each.phone_number)}>{treatnumber(each.phone_number)}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl size='small'>
                        <InputLabel id='mcdu-label-id'>Fluxo</InputLabel>
                        <Select
                            value={state.mcdu}
                            onChange={(e) => setState({ mcdu: e.target.value })}
                            label='Fluxo'
                            labelId='mcdu-label-id'
                        >
                            {mcdu.map((each, index) => (
                                <MenuItem key={`mcdu-each-item-${index}`} value={each.mcdu}>{each.entrypoint} ({each.mcdu})</MenuItem>
                            ))}
                        </Select>
                    </FormControl>


                </DialogContent >
                <DialogActions style={{ padding: "10px 20px 10px 10px" }}>
                    <Button
                        onClick={() => { setData() }}>
                        Cancelar
                    </Button>
                    <Tooltip title={checkButtonText()} placement="top" describeChild={true}>
                        <span>
                            <Button
                                disabled={checkButtonText()}
                                onClick={submit}>
                                Salvar
                            </Button>
                        </span>
                    </Tooltip>
                </DialogActions>
            </Dialog >
        </>
    )
}

export default RedirectEditModal