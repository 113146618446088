import React from "react";

import ReactDOM from 'react-dom'

import { Box, Button, Tooltip, IconButton, Chip } from "@mui/material";
import {
    DataGridPro,
    LicenseInfo,
    GridToolbar,
    ptBR,
    gridClasses,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    getGridStringOperators,
    getGridSingleSelectOperators
} from '@mui/x-data-grid-pro';
import { ptBR as corePtBR } from '@mui/material/locale';
import {
    createTheme,
    ThemeProvider,
    StyledEngineProvider
} from '@mui/material/styles';

import LoadingButton from '@mui/lab/LoadingButton';

import AddIcon from '@mui/icons-material/Add';
import ReadIcon from '@mui/icons-material/Visibility';
import CopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import RefreshIcon from '@mui/icons-material/Refresh';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import Loading from '../../Loading';
import useValidationError from '../../ErrorValidation';
import Modal from './Modal';
import ConfirmDeletion from './ConfirmDeletion';

import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import 'animate.css/animate.min.css';

import api from '../../../api';
import { getSession } from "../../../auth";
import UserPermissions from "../../User/Permissions";
import "./styles.css";

LicenseInfo.setLicenseKey(process.env.REACT_APP_DATAGRID_LICENSE_KEY);

const theme = createTheme({
    palette: {
        primary: { main: '#21446C' },
    },
}, ptBR, corePtBR);

class Template extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            pageSize: 0,
            objTemplates: {},
            viewTemplate: {},
            dataTemplates: [],
            dataColumns: [],
            loading: false,
            enterpriseId: 0,
            showModal: false,
            readTemplate: false,
            copyTemplate: false,
            renderKey: 0,
            deleteTemplate: '',
            templateRow: null,
            showDelete: false,
            clientsFilter: { items: [] }
        };

        this.categories = {
            'MARKETING': 'Marketing',
            'UTILITY': 'Utilidades'
        };

        if (UserPermissions().isAdmin)
            this.categories.AUTHENTICATION = 'Autenticação'

        this.categories_translate = {
            'AUTO_REPLY': 'Resposta automática',
            'ACCOUNT_UPDATE': 'Atualização da conta',
            'PAYMENT_UPDATE': 'Atualização de pagamento',
            'PERSONAL_FINANCE_UPDATE': 'Atualização de finanças pessoais',
            'SHIPPING_UPDATE': 'Atualização de envio',
            'RESERVATION_UPDATE': 'Atualização de reserva',
            'ISSUE_RESOLUTION': 'Resolução de problemas',
            'APPOINTMENT_UPDATE': 'Atualização de compromisso',
            'TRANSPORTATION_UPDATE': 'Atualização de transporte',
            'TICKET_UPDATE': 'Atualização de ingresso',
            'ALERT_UPDATE': 'Atualização de alerta',
            'TRANSACTIONAL': 'Transacional',
            'MARKETING': 'Marketing',
            'AUTHENTICATION': 'Autenticação',
            'OTP': 'Senha de uso único',
            'UTILITY': 'Utilidades'
        };
    }

    calculateScreenHeight = () => {
        const screenHeight =
            window.screen.height > 1200 ? 14 :
                window.screen.height > 1100 ? 12 :
                    window.screen.height > 1000 ? 10 :
                        window.screen.height > 900 ? 8 :
                            window.screen.height > 800 ? 6 :
                                window.screen.height > 700 ? 4 : 2;

        return screenHeight;
    }

    errorValidation = async (res) => {
        const resp = await useValidationError(res.status, res.message);

        if (resp)
            this.setState({
                viewTemplate: {},
                showModal: false
            });

        return resp
    }

    getTemplates = (param=null, retries=6) => {
        this.setState({ loading: true });

        let url = `/api/enterprises/${this.state.enterpriseId}/templates/`

        if (param)
            url += '?update=True'

        api.get(url, {timeout: 30000}).then(async (res) => {
            if (Object.values(res.data).some(value => value === 'caching...')) {
                if (retries > 0) {
                    return new Promise((resolve) => 
                        setTimeout(() => resolve(this.getTemplates(null, retries-1)), 5000)
                    );
                } else {
                    throw new Error('Failed [getTemplates] after 6 retries');
                }
            }
            let screenHeight = 0;
            let rows = [];
            let obj = {};

            if (res.status && res.status === 400 && res.data && res.data.accountcode) {
                await useValidationError(res.status, res.data.accountcode);

            } else if (res.status !== 200) {
                await useValidationError(res.status, null);

            } else {
                screenHeight = this.calculateScreenHeight();

                if (screenHeight > res.data.length) {
                    screenHeight = res.data.length;
                }

                let waba_templates = []
                if (getSession().profile.enterprise.abstraction_layer) {
                    Object.keys(res.data).forEach((key) => {
                        let number_templates = res.data[key].waba_templates.map(obj => ({
                            ...obj,
                            number: key
                        }))
                        waba_templates = [
                            ...waba_templates, 
                            ...number_templates
                        ]
                    })
                } else { waba_templates = res.data.waba_templates }

                let templates = waba_templates.filter((template) => {
                    return (template.language == 'pt_BR' && !template.name.includes('sample_'))
                })

                if (templates && templates.length) {
                    templates.forEach((item, index) => {
                        rows.push({ ...item, id: index, actions: item.name });

                        obj[item.name] = item;
                    });
                }
            }

            this.setState({
                objTemplates: obj,
                dataTemplates: rows,
                pageSize: screenHeight,
                loading: false
            });
        }).catch((err) => {
            this.setState({ loading: false });
        });
    }

    deleteTemplate = (templateName, templateRow) => {
        this.setState({
            loading        : true,
            deleteTemplate : '',
            templateRow    : null,
            showDelete     : false
        });

        let url = `/api/enterprises/${this.state.enterpriseId}/templates/${templateName}/`
        if (getSession().profile.enterprise.abstraction_layer)
            url += `?number=${templateRow.number}`

        api.delete(url, {timeout: 30000})
            .then(async (res) => {
                if (res.status !== 200) 
                    await useValidationError(res.status, null);
                else
                    await this.getTemplates();
                this.setState({ loading: false });
            })
            .catch((err) => {
                console.error(err);
                this.setState({ loading: false });
            });
    }

    readTemplate = (template) => {
        this.setState({
            viewTemplate: template,
            readTemplate: true,
            copyTemplate: false,
            showModal: true,
            renderKey: Math.random()
        });
    }

    copyTemplate = (template) => {
        this.setState({
            viewTemplate: template,
            readTemplate: false,
            copyTemplate: true,
            showModal: true,
            renderKey: Math.random()
        });
    }

    addTemplate = () => {
        this.setState({
            viewTemplate: {},
            readTemplate: false,
            copyTemplate: false,
            showModal: true,
            renderKey: Math.random()
        });
    }

    mountColumns = () => {


        const filterOperators = getGridStringOperators().filter(
            (operator) => ['contains', 'equals',
                'startsWith', 'endsWith',
                'isEmpty', 'isNotEmpty'].includes(operator.value)
        )
        const selectOptions = getGridSingleSelectOperators().filter(
            (operator) => ['is', 'not'].includes(operator.value))

        const dataColumns = [
            { field: 'name', headerName: 'Nome', flex: 0.5, filterOperators: filterOperators },
            {
                field: 'category', headerName: 'Categoria', flex: 0.4, filterOperators: filterOperators,
                valueGetter: (params) => {
                    return this.categories_translate[params.value]
                },
                renderCell: (params) => (
                    <Box>
                        {params.value}
                    </Box>
                )
            },
            {
                field: 'created_at', headerName: 'Criação', flex: 0.6, filterOperators: filterOperators,
				valueGetter: ({ value }) => {
					if (value) {
						const date = new Date(value);
						date.setHours(date.getHours());
						return date;
					}
				},
				renderCell: (params) => (
					<Box>
						{params.value?.toLocaleString('pt-BR')}
					</Box>
				)
            },
            {
                field: 'status', headerName: 'Status', flex: 0.4, filterOperators: selectOptions,
                valueOptions: ['APROVADO', 'ANÁLISE', 'REPROVADO'],
                valueGetter: (params) => {
                    return (
                        params.value?.toLowerCase() == 'approved'
                            ? "Aprovado"
                            : (params.value?.toLowerCase() == 'submitted' || params.value?.toLowerCase() == 'pending')
                                ? "Análise"
                                : "Reprovado"
                    )
                },
                renderCell: (params) => (
                    <Chip
                        label={params.value}
                        color={params.value === "Aprovado" ? "success" : params.value === "Análise" ? "warning" : "error"}
                        size="small"
                    />
                )
            },
            {
                field: 'components', headerName: 'Visualizar', flex: 2.5, filterOperators: filterOperators,
                valueGetter: (params) => {
                    const component = params.row.components
                    return (component[0] && component[0].type == 'BODY')
                        ? (component[0].text + '...')
                        : '[MIDIA] ' + component[1].text + '...'
                },
                renderCell: (params) => (
                    <Box className="column-components">
                        {params.value}
                    </Box>
                )
            },
            {
                field: 'actions', headerName: 'Ações', flex: 0.4, filterable: false, sortable: false,
                renderCell: (params) => (
                    <Box className="column-actions">
                        <Tooltip title="Visualizar template" disableInteractive>
                            <ReadIcon
                                className="icon-action read-icon"
                                onClick={() => this.readTemplate(this.state.objTemplates[params.value])}
                            />
                        </Tooltip>
                        <Tooltip title="Copiar template" disableInteractive>
                            <CopyIcon
                                className="icon-action copy-icon"
                                onClick={() => this.copyTemplate(this.state.objTemplates[params.value])}
                            />
                        </Tooltip>
                        <Tooltip title="Deletar template" disableInteractive>
                            <DeleteIcon
                                className="icon-action delete-icon"
                                onClick={() => this.setState({ deleteTemplate: params.value, templateRow: params.row, showDelete: true })}
                            />
                        </Tooltip>
                    </Box>
                )
            }
        ];

        if (getSession().profile.enterprise.abstraction_layer) {
            return [...[{ field: 'number', headerName: 'Número', flex: 0.5, filterOperators: filterOperators }], ...dataColumns]
        }

        return dataColumns;
    }

    refresh = async () => {
        const user = getSession();

        if (user)
            this.state.enterpriseId = user.profile.enterprise.id;

        const dataColumns = this.mountColumns();

        await this.setState({ dataColumns: dataColumns });
        await this.getTemplates();
    }

    componentWillMount() {
        this.refresh();
    }

    CustomToolbar = () => {
        return (
            <GridToolbarContainer className={gridClasses.toolbarContainer}>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <LoadingButton size="small"
                    component="label"
                    startIcon={<AddCircleIcon />}
                    onClick={() => this.addTemplate()}
                >
                    Adicionar
                </LoadingButton>
            </GridToolbarContainer>
        )
    }

    componentDidMount() {
        const actionContainer = document.getElementById("widgets-container")
        if (actionContainer) {
            const siblingContainer = document.createElement('div');
            siblingContainer.id = 'widgets-extra-actions';
            actionContainer.insertBefore(siblingContainer, actionContainer.firstChild);
            const siblingContent = (
                <>
                    <Tooltip title="Atualizar dados" disableInteractive>
                        <IconButton className="icon-refresh" onClick={() => this.getTemplates('update')}>
                            <RefreshIcon />
                        </IconButton>
                    </Tooltip>
                </>
            );
            ReactDOM.render(siblingContent, siblingContainer);
        }
    }

    componentWillUnmount() {
        const elementToRemove = document.getElementById('widgets-extra-actions');
        if (elementToRemove)
            elementToRemove.parentNode.removeChild(elementToRemove);
    }

    render() {
        return (
            <Box fullWidth className="templates-body">
                <Loading loading={this.state.loading} message="Sincronizando templates. Isto pode levar alguns segundos..."/>
                <ReactNotifications />
                <ConfirmDeletion
                    open={this.state.showDelete}
                    title={`Excluir ${this.state.deleteTemplate}`}
                    description={`Deseja realmente excluir o template ${this.state.deleteTemplate}?`}
                    handleClose={() => this.setState({ deleteTemplate: '', templateRow: null, showDelete: false })}
                    handleSubmit={() => this.deleteTemplate(this.state.deleteTemplate, this.state.templateRow)}
                />
                <Modal
                    open={this.state.showModal}
                    handleClose={() => this.setState({ viewTemplate: {}, showModal: false })}
                    template={this.state.viewTemplate}
                    readTemplate={this.state.readTemplate}
                    copyTemplate={this.state.copyTemplate}
                    categories={this.categories}
                    getTemplates={() => this.getTemplates()}
                    errorValidation={async (res) => await this.errorValidation(res)}
                    key={this.state.renderKey}
                />
                <Box className="table-templates">
                    <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={theme}>
                            <DataGridPro
                                pagination
                                disableSelectionOnClick
                                disableDensitySelector
                                components={{ Toolbar: this.CustomToolbar }}
                                density="compact"
                                filterMode='client'
                                columns={this.state.dataColumns}
                                rows={this.state.dataTemplates}
                            />
                        </ThemeProvider>
                    </StyledEngineProvider>
                </Box>
            </Box>
        );
    }
}

export default Template;