import React, {useEffect, useState} from 'react'

import { DialogActions } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import './styles.css'


const MultiStep = props => {
    const [step, changeStep] = useState(0) 
    const [input, changeInput] = useState('')
    const [stepsData, updateStepsData] = useState({})



    const prevStep = () => {changeStep(step - 1)}
    const nextStep = () => {changeStep(step + 1); props.onNext(step + 1)}
    const goStep = (value) => {
        if (value > step && !props.canNext){
            
            return 
        }
        changeStep(value)
        props.onNext(value)
    }

    useEffect(()=>{
        if (props.onOpen === 0)
        return
        changeStep(props.onOpen)

    }, [props.onOpen])

    
    props.getStep(step)
    //Create new props
    const newProps = Object.assign({}, props)
    //Delete submit from this props
    delete newProps.onSubmit
    delete newProps.canNext
    delete newProps.getStep
    delete newProps.onNext
    delete newProps.onOpen
    //Create array from object
    const groupObject = Object.values(newProps)
    // const groupKeys = Object.keys(props)

    const submit = () => {
        props.onSubmit('activate')
    }


    return (
        <>
        <div className='flexedRow'>
            {groupObject.map((component, index) => {
            return(
            <>
            <button 
                key={`unique-step-key-${index}`}
                className={index <= step ? 'multiButton activated':'multiButton'} 
                onClick={() => {goStep(index)}} >
                {index+1}
            </button>
            {index !== groupObject.length-1 && 
            <div className={'fill'}>
            <div className={index < step ? 'separateLine activatedLine':'separateLine'} >
            </div>
            </div>}
            </>
            )
        })}
        </div>
        <div>
             {groupObject[step]} 

             <DialogActions style={{ padding: "10px 20px 10px 10px" }}>
                {step !== 0 && 
                <LoadingButton onClick={prevStep}>
                                Anterior
                </LoadingButton>}
                {step !== groupObject.length-1 && 
                <LoadingButton disabled={!props.canNext} onClick={nextStep}>
                                Próximo 
                </LoadingButton>}
                {step === groupObject.length-1 && 
                <LoadingButton disabled={!props.canNext} onClick={submit}>
                                Concluir
                </LoadingButton>}
            </DialogActions>
        </div>
        </>
    )
}

export default MultiStep