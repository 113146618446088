import React, {useState, useEffect, useContext } from "react";

import {
	Box,
	Button,
	IconButton,
	Tooltip,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	TextField
} from '@mui/material';

import useMediaQuery from '@mui/material/useMediaQuery';

import LoadingButton from '@mui/lab/LoadingButton';

import GroupAddIcon from '@mui/icons-material/GroupAdd';

import api from "../../../../../api";

import AddClientGroupForm from '../AddClientGroupForm';

import { ScreenContext } from "../../..";

export default function ResponsiveCreateClientGroupButton({ isDisabled, handle }) {
  const isSmallScreen = useMediaQuery('(max-width: 60rem)');
  
  return (
    <>
          <LoadingButton
            className="button-add-group-selected"
            component="label"
            onClick={() => handle(true)}
            startIcon={<GroupAddIcon />}
            disabled={isDisabled}
          >
            Criar Segmento
          </LoadingButton>
          <>            
          </>
    </>
  )
}
