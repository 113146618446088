import React, { useState, useEffect, useContext } from 'react'

import api from '../../api'
import { QueueSocket } from '../../routes'
import { Box } from '@mui/material'
import './style.css'
import { getSession } from '../../auth'
import AutorenewIcon from '@mui/icons-material/Autorenew';
import useNotification from '../Notification'

let timeout = null
const UploadLoaderComponent = () => {
    const { cache, setCache } = useContext(QueueSocket)
    const [loadingContent, setLoadingContent] = useState()
    const user = getSession()
    const [retracted, setRetracted] = useState(true)
    const [errorCount, setErrorCount] = useState(0)

    const checkForAvailableCache = () => {
        if (!user)
            return false
        const getMemoryCache = localStorage.getItem('uploadCache');
        let newCache = cache ? cache : getMemoryCache
        return newCache
    }

    useEffect(() => {
        clearTimeout(timeout)
        checkCache()
    }, [cache])

    const checkCache = async () => {
        if (!user)
            return

        let newCache = checkForAvailableCache()

        if (newCache) {
            const res = await api.get(`/api/enterprises/${user.profile.enterprise.id}/contacts/progress/${newCache}/`)

            if (res.status == 200) {
                localStorage.setItem('uploadCache', newCache);
                setLoadingContent(res.data)
                if (res.data?.status >= 7) {
                    localStorage.removeItem('uploadCache');
                    setLoadingContent(null)
                    setCache(-1)
                    if (res.data.status == 7){
                        useNotification(
                            'Sucesso!',
                            'Clientes importados com sucesso.',
                            'success'
                        );
                    }
                    return
                }

                timeout = setTimeout(checkCache, 1000)
                return
            }            
        }
        localStorage.removeItem('uploadCache');
        setLoadingContent(null)
    }

    const loadSimplifiedData = () =>{
        if(!loadingContent)
            return
            
        const newcache = loadingContent
        const percentage = Math.round(newcache.step / newcache.totalSteps * 100)

        return (
        <Box className={'flexrow'}>
                <AutorenewIcon className={'spinner'} />
                <span>Carregando:</span>
                <span>{`${newcache.status}/7 `}{percentage != 0 ? `${percentage}%` : null}</span>
        </Box>
        )

    }

    const loadingMessage = () => {
        if(!loadingContent)
        return

        const newcache = loadingContent
        const percentage = Math.round(newcache.step / newcache.totalSteps * 100)
        let message = ''

        switch (newcache.status) {
            case 0:
                message = 'Iniciando'
                break
            case 1:
                message = `Lendo contatos ${percentage}%.`
                break
            case 2:
                message = `Atualizando contatos ${percentage}%.`
                break
            case 3:
                message = `Salvando contatos atualizados ${percentage}%.`
                break
            case 4:
                message = `Criando novos contatos ${percentage}%.`
                break
            case 5:
                message = `Salvando novos contatos ${percentage}%.`
                break
            case 6:
                message = `Criando novos segmentos ${percentage}%.`
                break
            case 7:
                message = 'Finalizado.'
                break
        }
        return (
            <>
                <Box className={'flexcolumn'}>
                    <span>Etapa {newcache.status} de 7</span>
                    <Box className={'flexrow'}>
                        <AutorenewIcon className={'spinner'} />
                        <span>{message}</span>
                    </Box>          
                </Box>
            </>
        )

    }

    return (
        <>
            {checkForAvailableCache() &&
                <>
                    {
                        retracted ? 
                            <Box className='sideuploader retracted' onClick={() => setRetracted(false)}>{loadSimplifiedData()}</Box>
                            :
                            <Box className='sideuploader' onClick={() => setRetracted(true)}>{loadingMessage()}</Box>
                    }
                </>
                }


        </>
    )
}

export default UploadLoaderComponent