import React from 'react';

import Frame from '../Frame';

const FrameNotifications = ({ gridArea, notifications, engaged }) => {
  const total = notifications;
  const used = engaged;

  const percent = total ? Math.round((used / total) * 100) : 0;

  return (
    <Frame gridArea={gridArea} title="Notificações (% engajamento)">
      <span>{total} ({percent}%)</span>
    </Frame>
  );
}

export default FrameNotifications;
