import React from "react";

import { saveToken } from "../../auth";

import { Box } from "@mui/material";

import Header from "../../components/Header";
import ResetPassword from "../../components/Forms/ResetPassword";

import AlertMobileVersion from "../../components/Dialogs/AlertMobileVersion";

class ClassResetPassword extends React.Component {

  render() {
    return (
      <Box display="flex">
        <Header
          // subtitle={"Ainda não tem conta"}
          // buttonText={"Cadastre-se"}
          // onClick={() => history.push("/register")}
        />
        <ResetPassword/>
        <AlertMobileVersion />
      </Box>
    );
  } 
}

export default ClassResetPassword;
