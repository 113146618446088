
import React, {useState} from 'react'


import { Box } from '@mui/material'

import './styles.css'


const TimeSelector = (props) => {

    const activate = object => {
        props.onChange(object)
        props.resetSelector({})
    }

    return (
        <>
        <h4>Quando deverá ser o disparo dessa campanha?</h4>
        {
            <>
            <div className='MTSflexed'>
                <div
                 id={'once'} 
                 className={props.state !== 'once' ? 'MTSBox': 'MTSBox active'} 
                 onClick={() => {props.state !== 'once' ? activate('once'):''}}>
                    <h4>Uma vez</h4>
                    <h6>Selecione uma data e hora específica para essa campanha.</h6>
                </div>
                <div 
                id={'periodic'} 
                className={props.state !== 'periodic' ? 'MTSBox': 'MTSBox active'} 
                onClick={() => {props.state !== 'periodic' ? activate('periodic'):''}}>
                    <h4>Periodicamente</h4>
                    <h6>Selecione o período que a campanha se repetirá.</h6>
                </div>
            </div>
            </>
        }
        </>
    )
}

export default TimeSelector