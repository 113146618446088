import React, { useState, useEffect } from "react";
import TimeForm from "./TimeForm"
import GroupForm from "./GroupForm"
import OperatorsForm from "./OperatorsForm"
import MessagesForm from "./MessagesForm"
import ResearchesForm from "./ResearchesForm"
import Resume from "./Resume"
import { Box, Grid, Stepper, Step, StepLabel, Typography, StepContent } from "@mui/material";
import Button from "../../Button"
import { useStyles, muiTheme } from './styles'
import Logo from '../../../assets/images/logoinstant-branca1x 2.svg'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { useFormik } from "formik";
import SnippetsForm from "./Snippets";
import { useHistory } from "react-router-dom";
import api from "../../../api";
import { getSession } from "../../../auth";
import { sendRegister } from "../../../services/enterprises"
import { sendSnippet } from "../../../services/ivrsnippets"
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

const FormController = () => {
    const [activeStep, setActiveStep] = useState(0);
    const classes = useStyles();
    const history = useHistory();
    const [showAlert, setShowAlert] = useState("");
    const [accountCode, setAccountCode] = useState("");


    const { values, handleChange, setFieldValue } = useFormik({

        initialValues:
        {
            snippets: [],

            times: [{
                checked: true,
                showInterval: false,
                weekDay: "Segunda-feira",
                initialTime: "08:00",
                endTime: "18:00",
                initialTime2: "",
                endTime2: "",
            }, {
                checked: true,
                showInterval: false,
                weekDay: "Terça-feira",
                initialTime: "08:00",
                endTime: "18:00",
                initialTime2: "",
                endTime2: "",
            }, {
                checked: true,
                showInterval: false,
                weekDay: "Quarta-feira",
                initialTime: "08:00",
                endTime: "18:00",
                initialTime2: "",
                endTime2: "",
            },
            {
                checked: true,
                showInterval: false,
                weekDay: "Quinta-feira",
                initialTime: "08:00",
                endTime: "18:00",
                initialTime2: "",
                endTime2: "",
            },
            {
                checked: true,
                showInterval: false,
                weekDay: "Sexta-feira",
                initialTime: "08:00",
                endTime: "18:00",
                initialTime2: "",
                endTime2: "",
            },
            {
                checked: false,
                showInterval: false,
                weekDay: "Sábado",
                initialTime: "",
                endTime: "",
                initialTime2: "",
                endTime2: "",
            },
            {
                checked: false,
                showInterval: false,
                weekDay: "Domingo",
                initialTime: "08:00",
                endTime: "18:00",
                initialTime2: "",
                endTime2: "",
            }],

            groups:
                [{
                    number: "1",
                    description: "Descrição do grupo 1",
                    checked: false
                }
                ],

            agents:
                [
                ],

            messages:
                [{
                    description: "Mensagem de saudação para ser enviada durante o horário de atendimento:",
                    message: "",
                    name: "saudacao",
                },
                {
                    description: "Mensagem para ser enviada fora do horário de atendimento:",
                    message: "",
                    name: "fora_hora",
                },
                {
                    description: "Mensagem de ocupado:",
                    message: "",
                    name: "ocupado",
                },
                {
                    description: "Mensagem para gerenciar as filas de atendimento:",
                    message: "",
                    name: "filas",
                },
                {
                    description: "Mensagem  de transferência de uma fila de atendimento para a outra:",
                    message: "",
                    name: "transferindo",
                },
                {
                    description: "Mensagem  de encerramento do atendimento:",
                    message: "",
                    name: "encerramento",
                },
                ],
            researches: {
                name: "Pesquisa",
                number: "",
                minRating: "0,0",
                maxRating: "10,0",
                initialMessage: "Olá, bem vindo!",
                finalMessage: "Agradecemos a avalição",
                comments: false,
                questions: [
                    { question: "Como foi o seu atendimento?", q_order: 1 },
                    { question: "Ocorreu tudo bem com seu atendimento?", q_order: 2 },
                    { question: "Avalie seu atendeimento", q_order: 3 }
                ]
            },

        },
    });

    const getCheckTimes = () => {
        const checkTimes = Array();
        for (var i = 0; i < values.times.length; i++) {
            checkTimes.push({
                startdayofweek: i + 1,
                starttime: values.times[i].initialTime,
                endtime: values.times[i].endTime,
                priority: i
            });
            if (values.times[i].initialTime2 && values.times[i].endTime2 !== "") {
                checkTimes.push({
                    startdayofweek: i + 1,
                    starttime: values.times[i].initialTime2,
                    endtime: values.times[i].endTime2,
                    priority: i + 7
                });
            }
        }
        return checkTimes;
    }

    const getMessages = () => {
        const messages = Array();
        for (var i = 0; i < values.messages.length; i++) {
            messages.push({
                name: values.messages[i].name,
                transcription: values.messages[i].description,
            });
        }
        return messages;
    }

    const getGroups = () => {
        const groups = Array();
        for (var i = 0; i < values.groups.length; i++) {
            groups.push({
                name: values.groups[i].number,
                description: values.groups[i].description,
            });
        }
        return groups;
    }

    function getAgentsGroups(index) {
        const groups = Array();
        for (var i = 0; i < values.agents[index].groups.length; i++) {
            if (values.agents[index].groups[i].checked) {
                groups.push(values.agents[index].groups[i].number)
            }
        }
        return groups;
    }

    const getAgents
        = () => {
            const agents = Array();
            for (var i = 0; i < values.agents.length; i++) {
                agents.push({
                    user: values.agents[i].number,
                    pass_field: values.agents[i].number,
                    name: values.agents[i].name,
                    email: values.agents[i].email,
                    queues: getAgentsGroups(i)
                });
            }
            return agents;
        }

    useEffect(() => {
        api.get('/api/ivrsnippets/').then(resp => {
            const snippetsList = Array();
            const snippets = resp.data;
            console.log(snippets);
            for (var i = 0; i < snippets.length; i++) {
                snippetsList.push({
                    id: snippets[i].id,
                    name: snippets[i].name,
                    description: snippets[i].description,
                    file: snippets[i].file,
                    checked: i === 0 ? true : false
                });
            }
            setFieldValue("snippets", snippetsList);
        }).catch(error => {
            console.log(error);
        });
        const enterpriseId = getSession().profile.enterprise.id;
        api.get('/api/enterprises/' + enterpriseId + "/").then(resp => {
            console.log(resp.data.iv_accountcode);
            setAccountCode(resp.data.iv_accountcode);
        }).catch(error => {
            console.log(error);
        });

    }, [])



    const getSnippet = () => {
        for (var i = 0; i < values.snippets.length; i++) {
            if (values.snippets[i].checked)
                return values.snippets[i];
        }
    }

    const getSurvey = () => {
        return ({
            name: values.researches.name,
            initial_mesg: values.researches.initialMessage,
            final_mesg: values.researches.finalMessage,
            questions: values.researches.questions
        });
    }

    const handleSubmit = async () => {
        try {
            console.log(getAgents());
            const enterprise = getSession().profile.enterprise;
            const response = await sendRegister({
                enterprise: enterprise,
                data: {
                    agents: { agents: getAgents() },
                    queues: {
                        queues: getGroups()
                    },
                    survey: getSurvey(),
                    messages: { messages: getMessages() },
                    checktime: {
                        name: "atendimento",
                        description: "Descrição do Atendimento",
                        rules: getCheckTimes()
                    }
                }
            });
            if (response === 201) {
                const response2 = sendSnippet(getSnippet(), accountCode);
                if (response2 === 201) {
                    history.push("/chat");
                }
                else {
                    setShowAlert("Houve um problema ao salvar a URA");
                }
            }
            else if (response === 400) {
                setShowAlert("Houve alguma inconsistência com as informações.");
            }
            else {
                setShowAlert("Erro inesperado.");
            }
        } catch (e) {
            console.log(e);
        }
    }

    const steps = [
        {
            label: 'Tipo de atendimento',
            description: ``,
        },
        {
            label: 'Horário de atendimento',
            description: ``,
        },
        {
            label: 'Mensagens',
            description:
                '',
        },
        {
            label: 'Grupos de atendimento',
            description: ``,
        },
        {
            label: 'Operadores',
            description: ``,
        },
        {
            label: 'Pesquisas de satisfação',
            description: ``,
        },
        {
            label: 'Resumo',
            description: ``,
        },
    ];

    function removeAgents() {
        setFieldValue("agents", []);
    }

    function _handleNext() {
        if (activeStep === 6) {
            handleSubmit();
        }
        else if (activeStep === 3) {
            removeAgents();
            setActiveStep(activeStep + 1);
        }
        else if (activeStep < 6) {
            setActiveStep(activeStep + 1);
        }
    }
    function _handleBack() {
        setActiveStep(activeStep - 1);
    }

    function _renderStepContent(step) {
        switch (step) {
            case 0:
                return <SnippetsForm values={values} handleChange={handleChange} setFieldValue={setFieldValue} />;
            case 1:
                return <TimeForm values={values} handleChange={handleChange} setFieldValue={setFieldValue} />;
            case 2:
                return <MessagesForm values={values} handleChange={handleChange} />;
            case 3:
                return <GroupForm values={values} handleChange={handleChange} setFieldValue={setFieldValue} />;
            case 4:
                return <OperatorsForm values={values} handleChange={handleChange} setFieldValue={setFieldValue} />;
            case 5:
                return <ResearchesForm values={values} handleChange={handleChange} setFieldValue={setFieldValue} />;
            case 6:
                return <Resume values={values} />;
        }
    }

    return (
        <Box display="flex">
            <Box marginLeft={"10vw"} marginRight={"10vw"} marginTop={"4vw"} marginBottom={"4vw"} width={"50vw"} >
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Snackbar open={showAlert !== ""} autoHideDuration={8000} onClose={() => setShowAlert("")}>
                        <Alert severity="error" >{showAlert}</Alert>
                    </Snackbar>

                </Grid>
                {_renderStepContent(activeStep)}

            </Box >
            <Box width={'35vw'}
                display="flex"
                className={classes.header}
                justifyContent="center"
                align-alignItems="center"
            >
                <Box m="4vw" >
                    <Grid container
                        alignItems="center"
                        justifyContent="center"
                        spacing={1}
                        direction="row" >
                        <Grid item ><img src={Logo} alt="logo" className={classes.logo} /></Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <StyledEngineProvider injectFirst>
                                <ThemeProvider theme={muiTheme}> {
                                    <Stepper activeStep={activeStep} className={classes.stepper} orientation="vertical">
                                        {steps.map((step, index) => (
                                            <Step key={step.label} >
                                                <StepLabel className={classes.stepLabel}
                                                >
                                                    {step.label}
                                                </StepLabel>
                                                <StepContent >
                                                    <Typography className={classes.description}>{step.description}</Typography>
                                                </StepContent>
                                            </Step>
                                        ))}
                                    </Stepper>
                                }
                                </ThemeProvider>
                            </StyledEngineProvider>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>  <Button color="secondary" title={activeStep < 6 ? "Próximo" : "Salvar"} onClick={_handleNext} /></Grid>
                        < Grid item lg={12} md={12} sm={12} xs={12}> {activeStep > 0 && (<Button color="white" title="Voltar" onClick={_handleBack} />)} </Grid >
                    </Grid>
                </Box>
            </Box >
        </Box >
    );
};

export default FormController;