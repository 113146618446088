import React from "react";
import { Hidden, adaptV4Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import MaterialTypography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { createTheme, responsiveFontSizes, ThemeProvider, StyledEngineProvider } from "@mui/material";

const Typography = ({ text, color, variant, fontWeight, align, justifyContent, noWrap, fontStyle }) => {


    const useStyles = makeStyles(() => ({
        root: {
            color: color === 'blue' && "#21446C" || color === 'lightGray' && '#797E82' || color === 'white' && 'white' || color === 'darkGray' && '#68686B' || color === 'mustard' && '#DFB160' || color === 'red' && '#C95F50',
            justifyContent: justifyContent,
            fontWeight: fontWeight,
        },
    }));


    function pxToRem(value) {
        const rem = '1vw/1vh'
        return `${value}1vw/1vh`;
    }

    const theme = createTheme();

    theme.typography.headline = {
        fontSize: pxToRem(24),
        [theme.breakpoints.up("md")]: {
            fontSize: pxToRem(32)
        }
    };
    theme.typography.title = {
        fontSize: pxToRem(21),
        [theme.breakpoints.up("md")]: {
            fontSize: pxToRem(24)
        },
        [theme.breakpoints.up("lg")]: {
            fontSize: pxToRem(24)
        }

    };
    theme.typography.body1 = {
        fontSize: pxToRem(14),
        [theme.breakpoints.up("md")]: {
            fontSize: pxToRem(15)
        },
        [theme.breakpoints.up("lg")]: {
            fontSize: pxToRem(17)
        }
    };
    theme.typography.body2 = {
        fontSize: '0.8vw',
        [theme.breakpoints.up("md")]: {
            fontSize: '0.8vw',
        },
        [theme.breakpoints.up("lg")]: {
            fontSize: '0.7vw',
        }
    };
    theme.typography.button = {
        fontSize: pxToRem(14),
        [theme.breakpoints.up("md")]: {
            fontSize: pxToRem(16)
        }
    };
    theme.typography.subtitle2 = {
        fontSize: pxToRem(15),
        [theme.breakpoints.up("md")]: {
            fontSize: pxToRem(12)
        },
        [theme.breakpoints.up("lg")]: {
            fontSize: pxToRem(15)
        }
    };

    const classes = useStyles();

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <MaterialTypography noWrap={noWrap} align={align} className={classes.root} variant={variant} fontStyle={fontStyle}>{text}</MaterialTypography>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

Typography.propTypes = {
    text: PropTypes.string,
    color: PropTypes.string,
    variant: PropTypes.string,
    fontWeight: PropTypes.string,
    align: PropTypes.string,
    justifyContent: PropTypes.string,
    noWrap: PropTypes.bool,
    fontStyle: PropTypes.string
};


Typography.defaultProps = {
    align: "justify",
    justifyContent: 'justify',
    noWrap: false

}

export default Typography;