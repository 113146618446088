import React, { useEffect, useReducer, useState, useRef, useLayoutEffect } from 'react'

import { Box, Divider } from '@mui/material'

import { getSession } from '../../../../auth';
import HistoryRow from './history-row';
import api from '../../../../api';
import defaultReducer from '../../../../utils/defaultReducer';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { ShowComponent } from '../../../../utils/isVisible';

const fetchDelay = 1000

const getData = async (state, setState) => {
    const res = await api.get(`/api/enterprises/${state.contact.enterprise}/contacts/cursor/${state.contact.id}/${state.cursor}/`)
    if (res.status === 200) {
        return res.data
    }
    return [[], 0]
}

const loadMore = async (e, state, setState, scrollArea) => {
    if (!e || state.fetching || state.last_cursor) return
    setState({ fetching: true })
    const previousScrollHeight = scrollArea.current.scrollHeight
    const [chats, cursor] = await getData(state, setState)
    setState({
        rows: [...state.rows, ...chats].toSorted((x, y) => x.id - y.id),
        cursor: cursor !== 0 ? cursor : state.cursor,
        last_cursor: cursor === 0
    })
    const nextScrollHeight = scrollArea.current.scrollHeight
    if (scrollArea && scrollArea.current && cursor !== 0) {
        scrollArea.current.scrollBy(0, Math.abs(nextScrollHeight - previousScrollHeight))
    }

    setTimeout(() => setState({ fetching: false }), fetchDelay)
}

const initializeHistory = async (state, setState) => {

    const [chats, cursor] = await getData(state, setState)
    setState({
        rows: chats.toSorted((x, y) => x.id - y.id),
        cursor: cursor,
        loaded: true
    })
}

const initialState = {
    rows: [],
    cursor: 0,
    last_cursor: false,
    fetching: false,
    loaded: false
}

const NewHistory = ({ contactData, fetchHistoryMessages }) => {

    const areaRef = useRef()

    const [playingAudio, setPlayingAudio] = useState({})
    const [smallLoading, setSmallLoading] = useState(false)
    const [state, setState] = useReducer(defaultReducer, {
        contact: contactData,
        chatCursor: 0,
        ...initialState
    })

    const enterprise = getSession().profile.enterprise;

    useLayoutEffect(() => {
        initializeHistory(state, setState)
        window.receiveChatCursor = (e) => setState({ chatCursor: e })
        return () => { setState(initialState) }
    }, [])

    useEffect(() => {
        if (areaRef.current)
            areaRef.current.scrollTop = areaRef.current.scrollHeight
    }, [state.loaded])

    return (
        <>
            <Box className="contactHistory-box-9">
                <Box
                    className="contactHistory-box-5"
                >
                    Últimos atendimentos
                    <Divider className="contactHistory-divider" />
                    <Box ref={areaRef} className="contactHistory-box-7">
                        <Box sx={{ minHeight: '1rem', display: 'flex', justifyContent: 'center' }}>
                            {(!state.last_cursor && !state.fetching && state.loaded) &&
                                <ShowComponent
                                    parent={areaRef}
                                    loading={(!state.last_cursor && !state.fetching && state.loaded)}
                                    isVisibleHook={(e) => { loadMore(e, state, setState, areaRef) }}>
                                </ShowComponent>}
                            {(state.fetching && !state.last_cursor && state.loaded) &&
                                <AutorenewIcon sx={{ width: '100%' }} className={'fileIcon spinner'} />}
                        </Box>
                        {state.rows.map((row, i) =>
                            <Box sx={{ display: 'flex' }} className={`history-row-cursor ${row.uniqueid === state.chatCursor ? 'selected': ''}`}>
                                {/* <Box key={`${row.uniqueid}-identifier-badge-${row.id}`}
                                    sx={{ opacity: row.uniqueid === state.chatCursor ? '1' : '0' }}
                                    className='history-cursor-badge'
                                ></Box> */}
                                <HistoryRow
                                    i={i}
                                    key={`${row.uniqueid}-identifier-${row.id}`}
                                    playingAudio={playingAudio}
                                    setPlayingAudio={setPlayingAudio}
                                    smallLoading={smallLoading}
                                    setSmallLoading={setSmallLoading}
                                    enterprise={enterprise}
                                    row={row}
                                    fetchHistoryMessages={fetchHistoryMessages}
                                />
                            </Box>
                        )}
                        {(!state.rows || state.rows.length === 0) &&
                            <Box className="noHistory">Não possui histórico.</Box>
                        }
                    </Box>
                </Box>
            </Box>
        </>
    )
}


export default NewHistory