import React, { useState, useEffect } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
    Divider, 
    Tooltip, 
    Box,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText
} from '@mui/material';

import LoadingButton from '@mui/lab/LoadingButton';

import PropTypes from "prop-types";

import CloseIcon from '@mui/icons-material/Close';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

import createChatbot from '../../../utils/createChatbot'

import "./styles.css"

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const MarketplaceDialog = ({ open, handleClose, marketplace, getBuilder }) => {

    const [selectedChatbot, setSelectedChatbot] = useState()

    const checkSelected = (chatbotId) => (chatbotId === selectedChatbot)

    const importChatbot = async () => {
        await createChatbot(selectedChatbot)
        getBuilder()
        handleClose()
    }

    return (
        <div>
            <Dialog
                open={open}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                disableEnforceFocus
            >
                <DialogTitle id="alert-dialog-slide-title" className={'group-flexbox'}>
                    <span>Builder Marketplace</span>
                    <Tooltip title="Fechar" >
                        <Button className="action-button">
                            <CloseIcon onClick={handleClose} />
                        </Button>
                    </Tooltip>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    Selecione o ChatBot que deseja importar em sua lista de fluxos.
                    <List className="marketplace-items">
                        {marketplace.map((chatbot) => (
                            <ListItem disablePadding key={`${chatbot.id}`} className={checkSelected(chatbot.id) ? "marketplace-item-selected" : ""}>
                                <ListItemButton onClick={() => setSelectedChatbot(chatbot.id)}>
                                    <ListItemIcon>
                                        <ShoppingCartIcon className={checkSelected(chatbot.id) ? "marketplace-icon-selected" : ""}/>
                                    </ListItemIcon>
                                    <ListItemText primary={chatbot.name}>
                                    </ListItemText> 
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancelar
                    </Button>
                    <LoadingButton 
                        onClick={importChatbot} 
                        color="primary"
                        disabled={(!selectedChatbot) ? true : false}
                    >
                        Importar
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </div>
    );
}

MarketplaceDialog.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func
};

export default MarketplaceDialog;
