import React, { useEffect, useState } from 'react'

import { Button, Box } from '@mui/material'

import { getSession } from '../../../auth'
import userPermissions from '../../User/Permissions'
import Loading from '../../Loading'

import UsersComponent from '.'
import UsersQueue from './UsersQueue'
import Operators from './Operators'

const groups = userPermissions();

const Options = (enterpriseId, setState, ref) => {
    return ({
        users: (<UsersComponent enterpriseId={enterpriseId} />),
        operators: (<Operators />)

    })
}

const Buttons = {
    users: 'Usuários',
    operators: 'Operadores'

}

const UsersMenu = ({ setState, ref }) => {
    const initialTab = localStorage.getItem('users-selected-tab') ? localStorage.getItem('users-selected-tab') : 'users'
    const [selectedTab, setSelectedTab] = useState(initialTab)
    const [loading, setLoading] = useState(false)
    const enterpriseId = getSession().profile.enterprise.id

    useEffect(() => {
        localStorage.setItem('users-selected-tab', selectedTab)
    }, [selectedTab])

    return (
        <>
            <Loading loading={loading} />
            {groups.isAdmin ? <Box className='BuilderContainer'>
                <Box className='BuilderSelectorContainer'>
                    <Box className='BuilderSelectorBox'>
                        {Object.entries(Buttons).map(([key, value]) => (
                            <Button
                                variant={key === selectedTab ? 'contained' : ''}
                                onClick={() => setSelectedTab(key)}>
                                {value}
                            </Button>
                        ))}
                    </Box>
                </Box>
                <Box className='BuilderContent'>
                    {Options(enterpriseId)[selectedTab]}
                </Box>
            </Box>
                :
                <Box className='BuilderContainer'>
                    <Box className='BuilderContent'>
                        <UsersComponent enterpriseId={enterpriseId} />
                    </Box>
                </Box>
            }
        </>
    )
}


export default UsersMenu