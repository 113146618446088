import React from "react";
import PropTypes from "prop-types";

import { Box } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

import ArrowIcon from '@mui/icons-material/ArrowForward';
import GlobeIcon from '@mui/icons-material/Public';

const ChatLocation = ({ src, user, name }) => {

    const useStyles = makeStyles((theme) => {
        return {
            root: {
                maxWidth: '175px',
                backgroundColor: '#f1f0f7',
                borderRadius: '100px',
                margin: '5px 0',
                height: '40px'
            }
        }
    })

    const classes = useStyles();

    return (
        <Box display="flex"
            justifyContent={user !== 0 ? 'flex-end' : 'flex-start'}
        >
            <Box className={classes.root}
                display="flex"
                alignItems="center"
                width="100%"
                justifyContent="space-around"
            >
                <GlobeIcon color="primary"/>
                <div>{name}</div>
                <a target="_blank" href={src}>
                    <ArrowIcon color="primary"/>
                </a>
            </Box>
        </Box>
    )
}

ChatLocation.PropTypes = {
    src: PropTypes.any,
    user: PropTypes.int,
    name: PropTypes.string
};

export default ChatLocation;
