import React from 'react';
import { ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import "./index.css";


class StackedBarChat extends React.Component {

    constructor() {
        super();
    }

    render() {
        return (
            <ResponsiveContainer width="80%" height="100%">
                <PieChart className="semi-ring-chart">
                    <Pie
                        data={this.props.data}
                        startAngle={180}
                        endAngle={0}
                        innerRadius={60}
                        outerRadius={80}
                        paddingAngle={5}
                        dataKey="value"
                    >
                        {this.props.data.map((value, index) => (
                            <Cell key={`cell-${index}`} 
                                fill={value.color}
                            />
                        ))}
                    </Pie>
                </PieChart>
            </ResponsiveContainer>
        )
    }
}

export default StackedBarChat;
