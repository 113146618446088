import { Box, Tooltip, List, ListItem, ListItemButton, Dialog, Divider, IconButton, Alert } from "@mui/material";
import React, { useEffect, useReducer, useState, useCallback } from "react";

import styles from './style.module.css'

import api from "../api";
import defaultReducer from "../utils/defaultReducer";
import { TryRounded } from "@mui/icons-material";
import AvatarName from "../components/AvatarName";
import MemoMessage from "../components/Chat/MessagesArea/ChatWindow/MessageArea/MemoMessage";
import MessageArea from "../components/Chat/MessagesArea/ChatWindow/MessageArea";
import CloseChatIcon from '@mui/icons-material/PowerSettingsNew';
import Typography from "../components/Typography";
import CloseIcon from '@mui/icons-material/Close';
import Footer from "../components/Chat/MessagesArea/ChatWindow/Footer";
import ConfirmationDialog from "../components/Dialogs/Confirmation";


const invokeMessageArea = (messages, activeChat, accountcode) => {

    return (

        <>
            {
                messages &&
                <ul>
                    {messages.map((message, i) =>

                        <MemoMessage
                            message={message}
                            index={i}
                            accountcode={accountcode}
                            isManageCalls={true}
                            queuesName={'test'}
                            enterpriseId={3}
                            activeChat={activeChat}
                        />
                    )
                    }
                </ul>
            }
        </>
    )

}


let interval = 0

const Page2 = ({ parent, setParent }) => {
    const [state, setState] = useReducer(defaultReducer, {
        chats: [],
        acd_chats: []
    })

    const [selectedChat, setSelectedChat] = useState(null)
    const [closeChat, setCloseChat] = useState(false)

    const instagramPages = parent.data.filter(each => each.instagram_id).map(each => each.instagram_id)

    const getChats = useCallback(async () => {
        const queryset = '?' + [...parent.data.map(each => 'sender=' + each.page_id), ...parent.data.filter(each=>each.instagram_id).map(each => 'sender=' + each.instagram_id)].join('&')
        const res = await api.get('/api/registerFacebook/' + queryset)
        if (res.status === 200) {
            setState(res.data)
        }
    }, [])

    useEffect(() => {
        if (!selectedChat) return
        const foundChat = state?.chats?.find(each => each.uniqueid === selectedChat?.uniqueid)
        if (foundChat && selectedChat)
            setSelectedChat(foundChat)
    }, [state, selectedChat])

    useEffect(() => {
        getChats()
        interval = setInterval(getChats, 5000)
        return () => {
            clearInterval(interval)
        }
    }, [])

    const selectedChatStatus = state.acd_chats.find(each => each.channel === selectedChat?.hash)?.chatstatus

    const finishChat = async () => {
        if (!selectedChat) return
        const res = await api.delete(`/api/registerFacebook/${selectedChat.id}/`)
        if (res.status === 204) {
            setCloseChat(false)
            setSelectedChat(null)
        }
    }

    const sendMessage = async (message) => {
        if (!selectedChat) return
        const pre_message = {
            "id"           : 0,
            "message"      : message,
            "iso_time"     : "--:--",
            "msg"          : message,
            "time"         : 0,
            "user_id"      : 1,
            "name_support" :'',
            "is_read"      : true,
            "req_input"    : '',
            "chat"         : '' 
    }
        state.chats.push(pre_message)
        const res = await api.post(`/api/registerFacebook/message/`, { message: message, chatid: selectedChat.id })
        if (res.status === 201) {
            Object.assign(pre_message,res.data)
        }
    }

    return (
        <>
            {closeChat && <ConfirmationDialog open={true}
                title="Encerrar atendimento"
                description="Tem certeza que deseja finalizar esse atendimento?"
                handleClose={() => setCloseChat(false)}
                handleSubmit={() => finishChat()}
            />}
            {selectedChat &&
                <Dialog
                    open={TryRounded}
                    keepMounted
                    onClose={() => { setSelectedChat(null) }}
                    aria-describedby="alert-dialog-slide-description"
                    fullWidth
                    sx={{ position: 'absolute' }}
                    disablePortal
                    disableEnforceFocus
                    onFocus={(e) => { e.preventDefault() }}
                    maxWidth="lg"
                >
                    <Box className='grid-scroller'>
                        <Box className='grid-insider' style={{ flexDirection: 'column' }}>
                            <Box className="chatWindow-box-2">
                                <Box className="chatWindow-box-3">
                                    <Box onClick={() => { }}>
                                        <Tooltip title="Abrir informações do contato" placement="bottom" disableInteractive>
                                            <Box className="chatWindow-box-4">

                                                <AvatarName
                                                    name={selectedChat.nick}
                                                    size="small"
                                                />
                                                <Typography
                                                    text={selectedChat.nick}
                                                    color="dark-gray"
                                                    noWrap={true}
                                                    fontWeight="400"
                                                />
                                            </Box>
                                        </Tooltip>
                                    </Box>
                                </Box>
                                <Box>
                                    <Tooltip title="Encerrar" interactive>
                                        <IconButton
                                            onClick={() => { setCloseChat(true) }}
                                            size="large"
                                            className="push-right">
                                            <CloseChatIcon color="error" />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Fechar" interactive>
                                        <IconButton
                                            onClick={() => setSelectedChat(null)}
                                            size="large"
                                            className="push-right">
                                            <CloseIcon color="primary" />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            </Box>
                            <Divider />
                            <MessageArea key={selectedChat}
                                messageArea={() => invokeMessageArea(selectedChat.messages, selectedChat, '')}
                                messages={selectedChat.messages}
                                accountcode={''}
                                supervisedMessages={[]}
                                updateMessages={() => { }}
                                enterpriseId={3}
                                chatStatus={''}
                                isManageCalls={true}
                                preview={false}
                            />
                        </Box>
                    </Box>
                    {selectedChatStatus?.includes('QUEUE', 'ONCHAT') ? <Footer
                        sendMessage={sendMessage}
                        showPredefinedMessages={() => { }}
                        activeChat={selectedChat}
                        isActiveChatOnQueue={true}
                        connectActiveChat={() => { }}
                        addMessage={() => { }}
                        connections={[]}
                        scrollDown={() => { }}
                    /> :
                        <Alert severity="info" className="footer-msg">
                            This chat is still on bot, type 'human' to be redirected to a queue.
                        </Alert>
                    }
                </Dialog>
            }

            <Box className={styles.fulltext}>
                <List>
                    <ListItem>For instagram, use a different account.</ListItem>
                    {state.chats.length > 0 && <ListItem>Click on any item below, and you will be able to send a message or read exchanged messages.</ListItem>}
                </List>


                <List className={styles.chatswindow}>
                    <ListItem className={styles.chatinput}>Chats:</ListItem>
                    {state.chats.map(each => (
                        <ListItem key={each.uniqueid}>
                            <ListItemButton padding={0} onClick={() => { setSelectedChat(each) }}>
                                {each.callerid}
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Box>
        </>
    )
}

export default Page2