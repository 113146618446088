import React, { useReducer, useState, useEffect } from 'react'

import {
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Divider,
    Box,
    FormGroup,
    FormControlLabel,
    Switch,
    Tooltip
} from '@mui/material'

import LoadingButton from '@mui/lab/LoadingButton'

import CloseIcon from '@mui/icons-material/Close'

import InputAdornment from '@mui/material/InputAdornment';

import TooltipIndicators from '../../../Dash/Tooltip'
import useNotification from "../../../Notification/index.js";

import { lockedReducer } from '../../../../utils/defaultReducer'
import api from '../../../../api';
import { getSession } from '../../../../auth';

const analyze_description = (description) => {
    if (description.includes('AADSTS54005:')) {
        return 'Código Oauth já utilizado, por favor gere outro código.'
    }

    return description
}


const submit = async (state, handleClose, setLoading, fetchData, setBots) => {
    const enterpriseId = getSession().profile?.enterprise?.id
    setLoading(true)
    const res = await api.post(`/api/enterprises/${enterpriseId}/email/`, state)
    setLoading(false)
    if (res.status === 201) {
        handleClose()
        fetchData(setLoading, setBots)
        return
    }
    else if (res.status === 404) {
        useNotification(
            'Ops!',
            'Não foi possível registrar o email, verifique as suas credenciais.',
            'danger'
        )
    }
    else if (res.status === 409) {
        useNotification(
            'Ops!',
            'Email já vinculado a outra conta.',
            'danger'
        )
    }
    else if (res.status === 400) {
        useNotification(
            'Ops!',
            analyze_description(res.data.error_description),
            'danger'
        )
    }
    else {
        useNotification(
            'Ops!',
            'Ocorreu um erro com a requisição.',
            'danger'
        )
    }

}

const validationData = (state) => {
    let response = []

    if (!state.imap_username)
        response.push(<Box>Digite o e-mail</Box>)
    if (!state.imap_username.includes('@'))
        response.push(<Box>Email precisa conter um caracter @</Box>)
    if (state.imap_username.includes(' '))
        response.push(<Box>Email não pode conter espaços</Box>)
    if (!state.tenant_id)
        response.push(<Box>Digite o id do tenant</Box>)
    if (!state.client_id)
        response.push(<Box>Digite o id do cliente</Box>)
    if (!state.client_secret)
        response.push(<Box>Digite o segredo do cliente</Box>)
    if (!state.code)
        response.push(<Box>Digite o código</Box>)

    if (response.length === 0)
        return ''
    return response
}

export default function AddBotDialogOauth({ data, handleClose, setLoading, fetchData, setBots }) {


    const [state, setState] = useReducer(lockedReducer, {
        id: 0,
        imap_username: '',
        tenant_id: '',
        client_id: '',
        client_secret: '',
        code: null
    })

    const validated_data = validationData(state)

    return (
        <div>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={true}
                disablePortal
                disableEnforceFocus
                sx={{ position: 'absolute' }}
            >
                <DialogTitle className="group-flexbox">
                    {data.id ? 'Editar' : 'Adicionar'} Conta de e-mail
                    <Button sx={{ 'minWidth': '2rem', 'padding': '0' }} onClick={handleClose}>
                        <CloseIcon />
                    </Button>
                </DialogTitle>
                <Divider />
                <DialogContent className="add-bot-dialog-content">
                    <TextField
                        size="small"
                        label="Email"
                        fullWidth
                        type="text"
                        variant="outlined"
                        value={state.imap_username}
                        onChange={(e) => setState({ imap_username: e.target.value })}
                        disabled={data.id}
                    />
                    <TextField
                        size="small"
                        label="Tenant ID"
                        fullWidth
                        type="text"
                        variant="outlined"
                        value={state.tenant_id}
                        onChange={(e) => setState({ tenant_id: e.target.value })}
                        disabled={data.id}
                    />
                    <TextField
                        size="small"
                        label="Client ID"
                        fullWidth
                        type="text"
                        variant="outlined"
                        value={state.client_id}
                        onChange={(e) => setState({ client_id: e.target.value })}
                        disabled={data.id}
                    />
                    <TextField
                        size="small"
                        label="Client Secret"
                        fullWidth
                        type="text"
                        variant="outlined"
                        value={state.client_secret}
                        onChange={(e) => setState({ client_secret: e.target.value })}
                        disabled={data.id}
                    />
                    <Button variant='contained' onClick={() => window.open(`https://login.microsoftonline.com/${state.tenant_id}/oauth2/v2.0/authorize?client_id=${state.client_id}&response_type=code&redirect_uri=https%3A%2F%2Flocalhost&response_mode=query&scope=offline_access%20Mail.ReadWrite%20Mail.send&state=12345644`)}>
                        Gerar Código
                    </Button>
                    <TextField
                        size="small"
                        label="Código"
                        fullWidth
                        type="text"
                        variant="outlined"
                        value={state.code}
                        onChange={(e) => setState({ code: e.target.value })}
                        disabled={data.id}
                    />
                </DialogContent>
                <DialogActions >
                    {Object.keys(data).length === 0 &&
                        <Box className="user-footer">
                            <LoadingButton className="button-created" onClick={handleClose}>
                                Cancelar
                            </LoadingButton>
                            <Tooltip title={validated_data}>
                                <span>
                                    <LoadingButton className="button-created"
                                        onClick={() => {
                                            submit(state, handleClose, setLoading, fetchData, setBots)
                                        }}
                                        disabled={validated_data}
                                    >
                                        Salvar
                                    </LoadingButton>
                                </span>
                            </Tooltip>
                        </Box>
                    }
                </DialogActions>
            </Dialog>
        </div>
    );
}
