import React from "react";
import { Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from "prop-types";


const InlineButton = ({ onClick, text }) => {

    const useStyles = makeStyles(() => ({
        root: {
            color: "#21446C",
            cursor: "pointer",
            '&:hover': {
                color: '#DFB160',
            },
        },
    }));

    const classes = useStyles();

    return (<Typography onClick={onClick} className={classes.root} >{text}</Typography>


    )
}

InlineButton.propTypes = {
    onClick: PropTypes.func,
    text: PropTypes.string
};

export default InlineButton;