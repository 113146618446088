import { Box } from '@mui/material';
import * as htmlToImage from 'html-to-image';

export const exportHtmlToImage = (element) => {

  if (element) {

    const hiddenElements = element.querySelectorAll('.showcopyright');
    hiddenElements.forEach(el => el.style.display = 'block')

    htmlToImage.toPng(element, {
        backgroundColor: '#ffffff', // White background
        quality: 0.95,
        //filter: (node) => !node.classList?.contains('showcopyright')
      })
      .then((dataUrl) => {
        const newWindow = window.open();
        newWindow.document.write(`
          <img src="${dataUrl}" alt="Chats Channel" />
          `);
          hiddenElements.forEach(el => el.style.display = 'none');
      })
      
      .catch((error) => {
        console.error('Error generating image:', error);
      });
  }
};
