import React from 'react';

import Frame from '../Frame';

const FrameChatbot = ({ gridArea, answeredByBot }) => {
  return (
    <Frame gridArea={gridArea} title="Chats (autoatendimento)">
      {answeredByBot}
    </Frame>
  );
}

export default FrameChatbot;