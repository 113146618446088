import React, { useState, useEffect, useMemo } from "react";

import {
    Box,
    Button,
} from '@mui/material';

import * as moment from "moment";

import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import EyeIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import MessageIcon from '@mui/icons-material/Message';
import DeleteIcon from '@mui/icons-material/Delete';
import HistoryIcon from '@mui/icons-material/History';


import Table from "../../../Table";
import api from '../../../../api';
import { getSession } from '../../../../auth';
import Loading from '../../../Loading';
import ClientDialog from '../../../Dialogs/Client';
import ConfirmationDialog from '../../../Dialogs/Confirmation';

import CampaignHistory from './CampaignHistory'
import CreateCampaignModal from "../CreateCampaignModal";



function CampaignTable(props) {
    const enterpriseId = props.session
    const [onLoading, changeLoading] = useState(false)
    const [campaignRows, updateCampaignRows] = useState([])
    const [editCampaignModal, updateEditCampaignModal] = useState(false)
    const [showCampaignHistoryModal, updateShowCampaignHistoryModal] = useState(false)
    const [selectedKey, updateSelectedKey] = useState(0)
    const [campaignName, updateCampaignName] = useState("")
    const [deleteCampaignModal, updateDeleteCampaignModal] = useState(false)
    const [groups, updateGroups] = useState([])

    const renderTable = useMemo(() => {

        const getGroups = async () => {
            const url = `/api/enterprises/${enterpriseId}/contact_group/`
            const response = await api.get(url)
            updateGroups(response.data)
        }
        getGroups()

        const canCreateCampaigns = getSession().groups.filter(each => each.name === 'administrador' || each.name === 'supervisor').length > 0
            ? true : getSession().profile.enterprise.settings.agent_can_create_campaigns

        const initializeColumns = () => {
            const thisColumns = (
                [
                    { field: 'name', headerName: 'Nome', flex: 1 },
                    { field: 'template', headerName: 'Template', flex: 1, hide: true },
                    { field: 'sender', headerName: 'Notificador', flex: 0.3, hide: true },
                    {
                        field: 'groups', headerName: 'Segmentos', flex: 1,
                        valueGetter: ({ value }) => {
                            if (value) {
                                const groupIds = value.map(each => each.group)
                                return groups.filter(g => groupIds.includes(g.id)).map(each => each.name).join(', ')
                            }
                        }
                    },
                    {
                        field: 'created_at', headerName: 'Data', flex: 0.3, hide: true, type: 'dateTime',
                        valueGetter: ({ value }) => {
                            if (value) {
                                const date = new Date(value);
                                date.setHours(date.getHours() - 3);
                                return date;
                            }
                        },
                        renderCell: (params) => (
                            <Box>
                                {params.value ? params.value.toLocaleString('pt-BR') : ''}
                            </Box>
                        )
                    },
                    {
                        field: 'next_execution', headerName: 'Próxima execução', flex: 0.3, type: 'dateTime',
                        valueGetter: ({ value }) => {
                            if (value) {
                                const date = new Date(value);
                                date.setHours(date.getHours() - 3);
                                return date;
                            }
                        },
                        renderCell: (params) => (
                            <Box>
                                {params.value ? params.value.toLocaleString('pt-BR') : ''}
                            </Box>
                        )
                    },
                    { field: 'queue_name', headerName: 'Fila', flex: 0.5, hide: true },
                    {
                        field: 'periodic', headerName: 'Periódico', flex: 0.2, hide: true,
                        renderCell: (params) => {
                            if (params.value) {
                                return (<CheckIcon className="icon-answered" />);
                            }
                            return (<CloseIcon className="icon-not-answered" />);
                        }
                    },
                    {
                        field: 'active', headerName: 'Ativo', flex: 0.2,
                        renderCell: (params) => {
                            if (params.value) {
                                return (<CheckIcon className="icon-answered" />);
                            }
                            return (<CloseIcon className="icon-not-answered" />);
                        }
                    },
                    {
                        field: 'actions', headerName: 'Ações', flex: 0.2,
                        renderCell: (params) => (
                            <Box>
                                <Button className="action-button"
                                    onClick={() => editCampaign(params.row)}>
                                    <EditIcon />
                                </Button>
                                <Button className="action-button delete"
                                    onClick={() => handleDeleteCampaign(params.row)}>
                                    <DeleteIcon />
                                </Button>
                            </Box>
                        )
                    },
                    {
                        field: 'historic', headerName: 'Histórico', flex: 0.2,
                        renderCell: (params) => (
                            <Box>
                                <Button className="action-button"
                                    onClick={() => { showCampaignHistory(params.id, params.row.name) }}>
                                    <HistoryIcon />
                                </Button>
                            </Box>
                        )
                    },
                    {
                        field: 'notifications', headerName: 'Disparos', flex: 0.2,
                        renderCell: (params) => (
                            <Box>
                                <Button className="action-button"
                                    onClick={() => setupNotification(params.id, params.row.date_range)}>
                                    <MessageIcon />
                                </Button>
                            </Box>
                        )
                    },
                ]
            )
            if (!canCreateCampaigns)
                delete thisColumns[9]

            return thisColumns
        }
        return (

            < Table
                columns={initializeColumns()}
                rows={campaignRows}
                checkboxSelection={false}
                pagination={false}
                pageSize={null}
                filterMode="client"
            />
        )
    }, [campaignRows])



    const fetchCampaign = async () => {
        changeLoading(true);

        const url = `/api/enterprises/${enterpriseId}/campaign/`;
        const response = await api.get(url)
        updateCampaignRows(response.data)
        changeLoading(false)

    }

    const handleDeleteCampaign = (data) => {
        updateDeleteCampaignModal(true)
        updateSelectedKey(data.id)
    }

    const deleteCampaign = async () => {
        changeLoading(true)
        const url = `/api/enterprises/${enterpriseId}/campaign/${selectedKey}/`;
        const response = await api.delete(url)
        changeLoading(false)
        props.changeUpdate(true)
    }

    const editCampaign = (data) => {
        updateEditCampaignModal(true)
        updateSelectedKey(data.id)
    }

    const showCampaignHistory = (id, name) => {
        updateShowCampaignHistoryModal(true)
        updateSelectedKey(id)
        updateCampaignName(name)
    }

    const setupNotification = async (campaignId, dateRange) => {

        props.parent.deactivateCampaign();
        props.parent.setState({
            notificationsSection: true,
            startDateTime: dateRange[0],
            endDateTime: dateRange[1],
            startDateTimeMobile: dateRange[0],
            endDateTimeMobile: dateRange[1],
            notificationsFilter: {
                items: [{
                    columnField: 'campaign_id',
                    operatorValue: 'equals', value: String(campaignId)
                }]
            },
            notificationRedirect: true
        })

    }




    const closeEditModal = (event) => {
        updateEditCampaignModal(false)
        props.changeUpdate(true)
        updateSelectedKey(0)
    }
    const closeHistoricModal = () => {
        updateShowCampaignHistoryModal(false)
        updateSelectedKey(0)

    }
    const createCampaign = async (event) => {
        updateEditCampaignModal(false)
        props.changeUpdate(true)
    }


    useEffect(() => {
        fetchCampaign()
        props.changeUpdate(false)
    }, [props.update])


    return (
        <>
            <Loading loading={onLoading} />
            <ConfirmationDialog open={deleteCampaignModal}
                title="Confirmação"
                description="Tem certeza que deseja excluir a campanha selecionada?"
                handleClose={() => { updateDeleteCampaignModal(false) }}
                handleSubmit={deleteCampaign}
            />
            <CreateCampaignModal
                open={editCampaignModal}
                selectedKey={selectedKey}
                title="Editar campanha"
                buttonText="Adicionar"
                queues={props.parent.queues}
                senders={props.parent.state.originNumbers}
                templates={props.parent.state.templates}
                enterpriseId={props.parent.enterpriseId}
                handleClose={() => { updateEditCampaignModal(false); props.changeUpdate(true) }}
                submit={createCampaign}
            />
            <CampaignHistory open={showCampaignHistoryModal}
                title="Histórico da campanha"
                id={selectedKey}
                name={campaignName}
                enterpriseId={props.parent.enterpriseId}
                buttonText="Fechar"
                handleClose={closeHistoricModal}
            />

            {!onLoading &&
                <>
                    {renderTable}
                </>
            }

        </>
    )
}

export default CampaignTable
