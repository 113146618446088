import React from 'react'
import OptionsMenu from '../../utils/OptionsMenu'
import userPermissions from '../User/Permissions'
import Settings from './Pages/settings-page'
import Monitor from './Pages/monitor-page'

const options = () => {
    const groups = userPermissions();

    return {
        settings: {
            permissions: groups.isAdmin,
            label: 'Configurações',
            component: (<Settings/>)
        },
        monitor: {
            permissions: groups.isAdmin,
            label: 'Monitoria',
            component: (<Monitor/>)
        }
    }
}

const Scout = () => {
    return (
        <Monitor/>
    )
}

export default Scout