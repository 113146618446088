import React from "react";
import MiniDrawer from "../../components/Drawer"
import TemplateGrid from "../../components/Templates/360dialog"

const Template = (socket) => {
    return (
        <MiniDrawer child={<TemplateGrid />} socket={socket.socket} />
    );
};

export default Template;
