import React, { useContext } from 'react'

import ResponsiveButton from '../ResponsiveButton'
import TableIcon from '@mui/icons-material/TableChart';

import { ScreenContext } from '..';
import ClientsTable from './ClientsTable';
import NotificationTable from './NotificationTable';
import CampaignTable from './CampaignTable';


const TabTables = ({ loadMoreGroups }) => {
    const { selectedTab } = useContext(ScreenContext)

    switch (selectedTab) {
        case 'clients':
            return (<ClientsTable loadMoreGroups={loadMoreGroups}/>)
        case 'notifications':
            return (<NotificationTable />)
        case 'campaigns':
            return (<CampaignTable />)
        default:
            return (<></>)
    }
}

export default TabTables
