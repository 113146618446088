import React, { useEffect, useState, useRef } from 'react'

import { Dialog, DialogActions, DialogContent, DialogTitle, Box, Slide, TextField, Button, Tooltip, Divider, Chip } from '@mui/material'
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import { FileDrop } from 'react-file-drop';
import ImportIcon from '@mui/icons-material/Upload';
import api from '../../../../../api';
import useNotification from '../../../../Notification';

import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const TemplatePreview = ({ type, mediaValues, body, submit, onHandleClose, parent }) => {
    const [example_file, setFile] = useState()
    const [example_bodyList, setBodyList] = useState([])
    const [example_header, setHeader] = useState([])
    const [example_url, setURL] = useState([])
    const [ready, setReady] = useState(false)
    const [loading, setLoading] = useState(false)
    const [example_file_url, setExampleUrl] = useState('')
    const displayParams = useRef()

    const matchRegLen = value => {
        if (!value)
            return 0
        const matcher = value.match(/{{\d+}}/g)
        if (matcher)
            return matcher.length
        return 0
    }

    const sendValues = async () => {

        if (example_file_url){
            mediaValues[0].example.header_handle = [example_file_url]
            await submit()
            return
        }

        if (type == 'STANDARD')
            parent.state.bodyExample = example_bodyList
        else if (type == 'MEDIA_INTERACTIVE') {
            if (mediaValues && mediaValues[0].type == 'HEADER' && 'format' in mediaValues[0] && mediaValues[0].format != 'TEXT') {
                const formdata = new FormData()
                formdata.append('file', example_file)
                formdata.append('type', mediaValues[0].format)
                setLoading(true)
                const res = await api.post(`/api/enterprises/${parent.state.enterpriseId}/templates/newmedia/upload/`, formdata)
                setLoading(false)
                if (res.status == 200) {
                    mediaValues[0].example.header_handle = [res.data]
                }
                else {
                    let message = 'Ocorreu um problema com o carregamento do arquivo'
                    if (res.status === 400 && res.data.file)
                        message = 'Formato de arquivo não aceito.'
                    useNotification(
                        'erro!',
                        message,
                        'danger'
                    );
                    return
                }

            }
            const getMediaBody = mediaValues.find(each => each.type == 'BODY')
            if (getMediaBody && 'example' in getMediaBody && 'body_text' in getMediaBody.example)
                getMediaBody.example.body_text = [getMediaBody.example.body_text]
            // We should upload the file, receive the value back and add this url to body_text
        }
        await submit()
        parent.setState(({ preview: false }))
    }

    const checkParameters = () => {

        if (parent.isMethodRead())
            return 

        let newready = true

        if (type == 'STANDARD') {
            const amount = matchRegLen(body)
            if (amount > 0)
                example_bodyList.map(each => {
                    if (each == '')
                        newready = false
                })
        }
        else {

            const result = mediaValues.map(parameter => {
                if (parameter.type == 'HEADER') {
                    if (parameter.format == 'TEXT') {
                        const headerhandle = 'example' in parameter ? parameter.example.header_text : ''
                        const amount = matchRegLen(parameter.text)
                        if (amount > 0 && headerhandle == '')
                            return false
                    }
                    else {
                        const headerhandle = 'example' in parameter ? parameter.example.header_handle : ''
                        if (headerhandle == '')
                            return false
                    }
                }
                else if (parameter.type == 'BODY' && 'example' in parameter && 'body_text' in parameter.example) {
                    const bodyhandle = parameter.example.body_text
                    const amount = matchRegLen(parameter.text)
                    if (amount > 0 && bodyhandle.includes(''))
                        return false
                }
                else if (parameter.type == 'BUTTONS') {
                    const buttonhandle = parameter.buttons.find(button => button.type == 'URL')
                    if (buttonhandle && 'example' in buttonhandle && buttonhandle.example[0][0] == '') {
                        return false
                    }
                }
                return true
            })

            if (result.includes(false))
                newready = false
        }

        setReady(newready)
    }

    //onChange
    useEffect(() => {
        checkParameters()
    }, [example_bodyList, example_header, example_url, example_file, example_file_url])

    //Initialize
    useEffect(() => {
    if (!parent.isMethodRead()){

        if (type == 'STANDARD') {
            const amount = matchRegLen(body)
            if (amount > 0)
                setBodyList(Array(amount).fill(''))

        }
        else {
            
                mediaValues.map(parameter => {
                    if (parameter.type == 'HEADER') {
                        if (parameter.format == 'TEXT') {
                            'example' in parameter ? parameter.example.header_text = '' : parameter.example = { 'header_text': ''}
                        }
                        else {
                            'example' in parameter ? parameter.example.header_handle = '' : parameter.example = { 'header_handle': '' }
                        }
                    }
                    else if (parameter.type == 'BODY' && 'example' in parameter && 'body_text' in parameter.example) {
                        parameter.example.body_text.fill('')
                        setBodyList(parameter.example.body_text)
    
                    }
                    else if (parameter.type == 'BUTTONS') {
                        const buttonhandle = parameter.buttons.find(button => button.type == 'URL')
                        if (buttonhandle && 'example' in buttonhandle && buttonhandle.example[0]) {
                            buttonhandle.example[0] = ['']
                        }
                    }
                })
            }
    }
        else{
            const header = parent.props.template.components.find(each => each.type === 'HEADER')
            header?.example?.header_handle ? setExampleUrl(header.example.header_handle[0]) : null
        }


        checkParameters()

    }, [])

    const handleBodyValue = (value, i) => {
        const newBodyParam = () => {
            const newvalue = {}
            newvalue[i] = value
            return { ...example_bodyList, ...newvalue }
        }
        const resultBodyParam = Object.values(newBodyParam())
        setBodyList(resultBodyParam)
        if (type == 'MEDIA_INTERACTIVE') {
            const newMediaValues = mediaValues.find(media => media.type == 'BODY')
            'example' in newMediaValues ? newMediaValues.example.body_text = resultBodyParam :
                newMediaValues.example = { 'body_text': resultBodyParam }
        }
    }

    const handleHeaderParam = value => {
        setHeader([value])
        if (parent.isMethodRead()) return
        if (type == 'MEDIA_INTERACTIVE')
            mediaValues.find(media => media.type == 'HEADER').example.header_text = [value]
    }

    const handleButtonValue = value => {
        setURL([value])
        if (parent.isMethodRead()) return
        if (type == 'MEDIA_INTERACTIVE')
            mediaValues.find(media => media.type == 'BUTTONS').buttons.find(button => button.type == 'URL').example = [value]
    }

    const handleFileInput = f => {
        if (!f)
            return
        setFile(f)
        if (parent.isMethodRead()) return
        if (type === 'MEDIA_INTERACTIVE')
            mediaValues.find(media => media.type == 'HEADER').example.header_handle = [f.name]
    }

    const clearFile = () => {
        setFile()
        if (parent.isMethodRead()) return
        if (type === 'MEDIA_INTERACTIVE')
            mediaValues.find(media => media.type == 'HEADER').example.header_handle = []
    }

    const changeExampleUrl = event => {
        if (!event)
            return
        setExampleUrl(event.target.value)
        if (parent.isMethodRead()) return
        if (type === 'MEDIA_INTERACTIVE')
            mediaValues.find(media => media.type == 'HEADER').example.header_handle = [event.target.value]
    }

    const renameMedia = (parameter) => {
        switch (parameter) {
            case 'IMAGE':
                return 'uma imagem'
            case 'DOCUMENT':
                return 'um documento'
            case 'VIDEO':
                return 'um video'
            default: return ''
        }
    }
    const acceptDiff = (parameter) => {
        switch (parameter) {
            case 'IMAGE':
                return 'image/png, image/jpeg'
            case 'DOCUMENT':
                return 'application/pdf'
            case 'VIDEO':
                return 'video/mp4'
            default: return ''
        }
    }

    const acceptFormats = (parameter) => {
        switch (parameter) {
            case 'IMAGE':
                return 'png, jpeg'
            case 'DOCUMENT':
                return 'pdf'
            case 'VIDEO':
                return 'mp4'
            default: return ''
        }
    }

    const parameters = () => {
        if (type == 'STANDARD') {
            const amount = matchRegLen(body)
            if (amount == 0)
                return
            return (
                <>
                    <h6 >
                        Preencha os parâmetros abaixo:
                    </h6>
                    {Array(amount).fill(1).map((parameter, i) => {
                        const variableIndex = i + 1;
                        return (
                            <TextField
                                size="small"
                                label={"{{" + variableIndex + "}}"}
                                fullWidth
                                type="text"
                                variant="outlined"
                                value={example_bodyList[i]}
                                onChange={e => { handleBodyValue(e.target.value, i) }}
                                className="input-parameter"
                            />)
                    }
                    )}
                </>
            )

        }
        else if (type == 'MEDIA_INTERACTIVE') {
            return (
                <>
                    {mediaValues.map(parameter => {
                        switch (parameter.type) {
                            case 'HEADER':
                                if (parameter.format == 'TEXT') {
                                    const newHeaderList = Array(matchRegLen(parameter.text)).fill(1)
                                    if (newHeaderList.length == 0)
                                        return
                                }

                                return (
                                    <>
                                        <h6 >
                                            Cabeçalho:
                                        </h6>
                                        {parameter.format == 'TEXT' ?
                                            <>
                                                <TextField
                                                    size="small"
                                                    label={"{{1}}"}
                                                    fullWidth
                                                    type="text"
                                                    variant="outlined"
                                                    value={example_header}
                                                    onChange={e => { handleHeaderParam(e.target.value) }}
                                                    className="input-parameter"
                                                />
                                            </>
                                            :
                                            <>
                                                <div className="file-drop-container">
                                                    <FileDrop
                                                        onDrop={(files, event) => { handleFileInput(files[0]) }}
                                                    >
                                                        {example_file ? <Chip label={example_file.name} onDelete={clearFile} /> :
                                                            <span sx={{ fontSize: ".8rem" }}>Arraste e solte para inserir {renameMedia(parameter.format)}. Apenas [{acceptFormats(parameter.format)}]
                                                            </span>
                                                        }
                                                        <span>ou</span>
                                                        <LoadingButton
                                                            sx={{ fontSize: ".8rem" }}
                                                            variant="contained"
                                                            color="primary"
                                                            component="label"
                                                            loading={false}
                                                            loadingPosition="start"
                                                            startIcon={<ImportIcon />}
                                                            disabled={false}
                                                            onClick={() => { }}
                                                        >
                                                            Selecione {renameMedia(parameter.format)}
                                                            <input
                                                                hidden
                                                                accept={acceptDiff(parameter.format)}
                                                                type="file"
                                                                onChange={(e) => { handleFileInput(e.target.files[0]); e.target.value = null }}
                                                            />

                                                        </LoadingButton>
                                                        <span style={{margin:'8px 0px'}}>ou</span>
                                                        <Tooltip title={`Alternativamente ao arquivo, coloque uma URL de um ${renameMedia(parameter.format)}`}>
                                                            <TextField 
                                                                label='URL'
                                                                size='small'
                                                                disabled={example_file}
                                                                value={example_file_url}
                                                                onChange={changeExampleUrl}
                                                            />
                                                        </Tooltip>
                                                    </FileDrop>
                                                </div>
                                            </>
                                        }
                                    </>
                                )
                            case 'BODY':
                                const newBodyList = Array(matchRegLen(parameter.text)).fill(1)
                                if (newBodyList.length == 0)
                                    return
                                return (
                                    <>
                                        <h6 >
                                            Corpo:
                                        </h6>
                                        {newBodyList.map((parameter, i) => {
                                            const variableIndex = i + 1;
                                            return (
                                                <TextField
                                                    size="small"
                                                    label={"{{" + variableIndex + "}}"}
                                                    fullWidth
                                                    type="text"
                                                    variant="outlined"
                                                    value={example_bodyList[i]}
                                                    onChange={e => { handleBodyValue(e.target.value, i) }}
                                                    className="input-parameter"
                                                />)
                                        })}
                                    </>
                                )
                            case 'BUTTONS':
                                const urlButton = parameter.buttons.find(each => each.type == 'URL')
                                if (urlButton) {
                                    const newButtonList = Array(matchRegLen(urlButton.url)).fill(1)
                                    if (newButtonList.length == 0)
                                        return
                                }
                                else {
                                    return
                                }

                                return (
                                    <>
                                        <h6 >
                                            Botões:
                                        </h6>
                                        <TextField
                                            size="small"
                                            label={"{{1}}"}
                                            fullWidth
                                            type="text"
                                            variant="outlined"
                                            value={example_url}
                                            onChange={e => { handleButtonValue(e.target.value) }}
                                            className="input-parameter"
                                        />
                                    </>
                                )
                            default:
                                return
                        }

                    }
                    )}
                </>
            )
        }
    }


    const preview = () => {


        const displayHeader = mediaValues.find(each => each.type == 'HEADER')
        const displayBody = mediaValues.find(each => each.type == 'BODY')
        const displayFooter = mediaValues.find(each => each.type == 'FOOTER')
        const displayButtons = mediaValues.find(each => each.type == 'BUTTONS')

        const displayBodySimple = body

        let headerText = ''
        let bodyText = ''
        let headerURL = ''
        let headerDOC = () => { }

        if (displayHeader && displayHeader.format == 'TEXT') {
            headerText = displayHeader.text
            headerText = headerText.replace(/{{(\d+)}}/g, function (_, m) { if (example_header != '' && example_header[0] != '') return example_header; return _; })
        }
        if (displayHeader && displayHeader.format != 'TEXT') {
            if (example_file) {
                headerURL = URL.createObjectURL(example_file)
                if (displayHeader.format == 'IMAGE') {
                    headerDOC = () => { return (<img alt='' src={headerURL} />) }
                }
                else if (displayHeader.format == 'VIDEO') {
                    headerDOC = () => { return (<embed src={headerURL} />) }
                }
                else if (displayHeader.format == 'DOCUMENT') {
                    headerDOC = () => { return (<Box className={'wpp-doc-display'} onClick={() => { window.open(headerURL) }}>{example_file.name}</Box>) }
                }
            }
            else if (example_file_url){
                if (displayHeader.format == 'IMAGE') {
                    headerDOC = () => { return (<img alt='' src={example_file_url} />) }
                }
                else if (displayHeader.format == 'VIDEO') {
                    headerDOC = () => { return (<embed src={example_file_url} />) }
                }
                else if (displayHeader.format == 'DOCUMENT') {
                    headerDOC = () => { return (<Box className={'wpp-doc-display'} onClick={() => { window.open(example_file_url) }}>{example_file_url}</Box>) }
                }
            }

            // if (example_file[0] != '')
            //     headerIMG = URL.createObjectURL(example_file[0])
        }

        if (displayBodySimple && type == 'STANDARD') {
            bodyText = displayBodySimple
            bodyText = bodyText.replace(/{{(\d+)}}/g, function (_, m) {
                const testValue = example_bodyList[--m]
                if (testValue != '')
                    return testValue;
                return _;
            })
        }

        if (displayBody && type == 'MEDIA_INTERACTIVE') {
            bodyText = displayBody.text
            bodyText = bodyText.replace(/{{(\d+)}}/g, function (_, m) {
                const testValue = example_bodyList[--m]
                if (testValue && testValue != '')
                    return testValue;
                return _;
            })
        }


        return (
            <>
                <Box className='previewBoxStyle'>
                    <Box className='wpp-card-main'>
                        {headerDOC ? <Box className={'wpp-media-display'}>{headerDOC()}</Box> : null}
                        {headerText ? <h5 style={{ whiteSpace: "pre-line", wordBreak: "break-all" }}>{headerText}</h5> : null}
                        {bodyText ? <div style={{ whiteSpace: "pre-line", wordBreak: "break-all" }}>{bodyText}</div> : null}
                        {displayFooter ? <h8 style={{ fontSize: "0.8rem", color: "#999999" }}>{displayFooter.text}</h8> : null}
                    </Box>
                    {displayButtons ? displayButtons.buttons.map(button => {

                        let newbuttonurl = ''
                        if ('url' in button) {
                            newbuttonurl = button.url
                            newbuttonurl = newbuttonurl.replace(/{{(\d+)}}/g, function (_, m) { if (example_url != '' && example_url[0] != '') return example_url; return _; })
                        }

                        return (
                            <Box
                                className='wpp-card-button'
                                onClick={newbuttonurl != '' ? () => { window.open(newbuttonurl) } : null}>
                                {button.type == 'QUICK_REPLY' ? null : button.type == 'URL' ?
                                    <OpenInNewIcon /> : <PhoneForwardedIcon />}
                                {button.text}

                            </Box>
                        )
                    }

                    ) : null}
                </Box>
            </>
        )
    }

    const CreateParameterObject = () => {
        const parameter = parameters()
        if (!parameter)
            return null
        const validation = parameter.props.children.map(each => each != null)
        if (validation.includes(true))
            return (<Box className={'wpp-card-param'}>{parameter}</Box>)
        return null
    }

    return (
        <>
            <Dialog
                className={'PreviewTemplateDialog'}
                open={true}
                keepMounted
                onClose={() => {}}
                aria-describedby="alert-dialog-slide-description"
                fullWidth
                disablePortal
                disableEnforceFocus
                sx={{position:'absolute', zIndex:1501}}
            >
                <DialogTitle className={'group-flexbox'}>
                    Visualizar template
                    <Tooltip title="Fechar">
                        <Button className="action-button">
                            <CloseIcon onClick={onHandleClose} />
                        </Button>
                    </Tooltip>
                </DialogTitle>
                <Divider />
                <DialogContent ref={displayParams}>
                    <Box className={'templateFlexBox'} >
                        {CreateParameterObject()}
                        <Box className={'wpp-card-box'}>
                            {preview()}
                        </Box>
                    </Box>
                </DialogContent>
                {! parent.isMethodRead() &&
                    <DialogActions style={{ padding: "10px 20px 15px 1.5rem" }}>
                        <Box>
                            <Button color="primary" onClick={onHandleClose}>
                                Cancelar
                            </Button>
                            <LoadingButton className="button-created"
                                onClick={sendValues}
                                disabled={! ready}
                                loading={loading}
                            >
                                Enviar
                            </LoadingButton>
                        </Box>
                    </DialogActions>
                }
            </Dialog>
        </>
    )
}

export default TemplatePreview