
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import {
    Box,
    Button,
    Tooltip
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import HistoryIcon from '@mui/icons-material/History';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel'
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { 
    stringOperators,
    dateOperators,
    booleanOperators
} from '../../utils/filterOperators';

dayjs.extend(utc)
dayjs.extend(timezone)

export const buildAutomationColumns = (state, setState) => {
    
    return [
        { field: 'name', headerName: 'Nome', flex: 1 , filterOperators: stringOperators},
        {
            field: 'enabled', headerName: 'Ativo', flex: .2, filterOperators: booleanOperators,
            renderCell: ({ value }) => {
                if (value)
                    return (<Box><CheckCircleIcon sx={{ 'color': '#5F8963' }} /></Box>)
                return (<Box><CancelIcon sx={{ 'color': '#C95F50' }} /></Box>)
            }
        },
        {
            field: 'public_id', headerName: 'Bot', flex: 1, filterOperators: stringOperators,
            renderCell: ({ value }) => {
                if (state.builder) {
                    return (<Box>{state?.builder?.botlist?.find(each => each.id === value)?.name}</Box>)
                } else {
                    return value
                }
            }
        },
        {
            field: 'execution_time', headerName: 'Próxima execução', flex: .8, filterOperators: dateOperators,
            renderCell: ({ row }) => {
                const datenow = dayjs().tz('utc')
                if (!row.enabled) return ''
                if (datenow < dayjs(row.start_time).tz('utc', true))
                    return dayjs(row.start_time).tz('utc', true).toDate().toLocaleString()
                
                window.test = state.time
                window.next = row.next_execution
                const newtime = state.time.add(row.next_execution, 'seconds').toDate().toLocaleString()
                return newtime
            }
        },
        {
            field: 'expiration', headerName: 'Expira em', flex: .8,filterOperators: dateOperators,
            renderCell: ({ row }) => {
                if (!row.enabled) return ''
                if (row.one_off) return dayjs(row.start_time).tz('utc', true).toDate().toLocaleString()
                if (row.expires)
                    return dayjs(row.expires).tz('utc', true).toDate().toLocaleString()
                else
                    return ''
            }
        },
        {
            field: 'last_run_at', headerName: 'Última execução', flex: .8,filterOperators: dateOperators,
            renderCell: ({ value }) => {
                if (value)
                    return dayjs(value).tz('utc', true).toDate().toLocaleString()
                return ''
            }
        },
        {
            field: 'historic', headerName: 'Acompanhar', flex: 0.5, filterable: false, sortable: false,
            renderCell: ({row}) => (
                <Box>
                    <Button className="action-button"
                        onClick={() => {setState({showHistoric: row}) }}>
                        <HistoryIcon />
                    </Button>
                </Box>
            )
        },
        {
            field: 'actions', headerName: 'Ações', flex: .5,  filterable: false, sortable: false,
            renderCell: (params) => (
                <Box>
                    <Tooltip title='Editar'>
                        <Button className="action-button"
                            onClick={() => setState({ edit: params.row })}>
                            <EditIcon />
                        </Button>
                    </Tooltip>
                    <Tooltip title='Editar Bot'>
                        <Button className="action-button"
                            onClick={() => setState({ showbot: params.row })}>
                            <PrecisionManufacturingIcon />
                        </Button>
                    </Tooltip>
                    <Tooltip title='Excluir'>
                        <Button className="action-button delete"
                            onClick={() => setState({ delete: params.row })}>
                            <DeleteIcon />
                        </Button>
                    </Tooltip>
                </Box>
            )
        },
    ]
}