import React, { useReducer } from 'react'

import {
    Box, TextField, Button, Dialog, Chip, DialogActions,
    DialogContent, DialogTitle, Divider, Tooltip, FormControl, Select,
    InputLabel, MenuItem, FormLabel, FormControlLabel, Radio, RadioGroup
} from "@mui/material";
import defaultReducer from '../../utils/defaultReducer'
import CloseIcon from '@mui/icons-material/Close'
import LoadingButton from '@mui/lab/LoadingButton';
import IntegerOnly from '../../utils/IntegerOnly';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import useNotification from '../Notification';
import { getSession } from '../../auth';

import dayjs from 'dayjs';

import 'dayjs/locale/pt-br';
import api from '../../api';

import { ptBR as localePTBR } from '@mui/x-date-pickers/locales';
import ConfirmationDialog from '../Dialogs/Confirmation';

const enterpriseId = getSession()?.profile?.enterprise.id

const cron_weekdays = [
    { 'id': "0", 'value': 'Domingo' },
    { 'id': "1", 'value': 'Segunda' },
    { 'id': "2", 'value': 'Terça' },
    { 'id': "3", 'value': 'Quarta' },
    { 'id': "4", 'value': 'Quinta' },
    { 'id': "5", 'value': 'Sexta' },
    { 'id': "6", 'value': 'Sábado' }
]

const frequency_options = {
    'never': 'Nunca',
    'days': 'dia',
    'weeks': 'semana',
    'months': 'mes'
}

const default_timezone = "America/Sao_Paulo"

const verifySave = (state) => {
    const response = []

    if (state.name === '')
        response.push(<Box>Adicione um nome.</Box>)
    if (state.public_id === '')
        response.push(<Box>Adicione um fluxo.</Box>)
    if (state.datetimeini === '')
        response.push(<Box>Adicione uma data de início.</Box>)
    if (state.frequency !== 'never') {
        if (state.frequency === 'weeks' && state.repeat?.length === 0)
            response.push(<Box>Selecione um dia da semana.</Box>)
    }
    if (state.endson !== 'never') {
        if (state.expires === '')
            response.push(<Box>Selecione uma data de fim.</Box>)
    }
    if (state.frequency === 'days' && (['*', ''].includes(state.days))) {
        response.push(<Box>Selecione um dia válido.</Box>)
    }


    if (response.length === 0)
        return ''

    return response
}

const submit = async (state, setState, setParent, reloadData) => {
    setParent({ loading: true })

    const payload = { ...state }
    const id = payload.id
    delete payload.id
    let res = null
    if (id === 0)
        res = await api.post(`api/enterprises/${enterpriseId}/automation/`, payload, { timeout: 10000 })
    else
        res = await api.patch(`api/enterprises/${enterpriseId}/automation/${id}/`, payload, { timeout: 10000 })

    setParent({ loading: false })
    if (res && [200, 201].includes(res.status)) {
        useNotification(
            'Sucesso!',
            `Automação ${id === 0 ? 'criada' : 'alterada'} com sucesso!`,
            'success'
        )
        reloadData()
        setParent({ edit: false })
        return
    } else {

        let messageError = res.data?.detail || res.data?.error || res.data?.message || `Falha ao ${id === 0 ? 'criar' : 'editar'} automação [${res.status}].`
        const knownError = knownErrors(res.data)
        if (knownError)
            messageError = knownError

        useNotification(
            'Ops.',
            messageError,
            'danger'
        )
    }


}

const knownErrors = (data) => {
    if (data.detail && data.detail.includes("Duplicate entry"))
        return "Já existe uma automação com estas configurações."
    else
        return false
}


const calculate_periodic_data = (data) => {

    let frequency = data.one_off ? 'never' : 'days'

    const weeks = data.schedule_day_of_week?.includes(',') ? data.schedule_day_of_week.split(',') : data.schedule_day_of_week != '*' ? data.schedule_day_of_week : []
    let days = data.schedule_day_of_month?.includes('*/') ? Number(data.schedule_day_of_month.split('*/')[1]) : data.schedule_day_of_month
    const months = data.schedule_month_of_year?.includes('*/') ? Number(data.schedule_month_of_year.split('*/')[1]) : '*'
    let selectedMonthRule = ''
    if (weeks?.length > 0 && typeof (weeks) !== 'string')
        frequency = 'weeks'
    if (months !== '*') {
        frequency = 'months'
        days = days
    }

    return {
        frequency: frequency,
        days: days ? String(days) : '1',
        repeat: weeks ? weeks : [],
        repeatmonths: selectedMonthRule
    }
}

const EditAutomation = ({ data, parent, setParent, reloadData }) => {

    const [state, setState] = useReducer(defaultReducer, {
        id: 0,
        name: '',
        public_id: '',
        frequency: 'days',
        repeat: [],
        repeatmonths: 'monthlyday5',
        schedule_timezone: default_timezone,
        bot_url: parent.builder.original_url.replace('builder', 'bot') + '/',
        enabled: true,
        ...data,
        start_time: data.start_time ? dayjs(data.start_time).tz('utc', true) : '',
        expires: data.expires ? dayjs(data.expires).tz('utc', true) : '',
        endson: data.expires ? 'date' : 'never',
        ...calculate_periodic_data(data)
    })

    const builderItems = parent.builder.botlist.filter(each => each.publicId)
    const disabled = verifySave(state)

    return (
        <>
            <Dialog
                open='true'
                disablePortal
                fullWidth
                maxWidth='xs'
                disableEnforceFocus
                sx={{ position: 'absolute' }}
            >
                <DialogTitle className={'group-flexbox'}>
                    <span>{state.id === 0 ? 'Adicionar' : 'Editar'} automação</span> <Button sx={{ 'minWidth': '2rem', 'padding': '0' }} onClick={() => { setParent({ edit: null }) }} ><CloseIcon /></Button>
                </DialogTitle>
                <Divider />
                <DialogContent className='content-dialog-rows'>
                    <LocalizationProvider sx={{ justifyContent: 'space-between' }} dateAdapter={AdapterDayjs} adapterLocale={'pt-br'}
                        localeText={localePTBR.components.MuiLocalizationProvider.defaultProps.localeText}

                    >
                        <Box className='BuilderBotSelectorBox'>
                            <Button
                                variant={state.enabled === true ? 'contained' : ''}
                                onClick={() => setState({ enabled: true })}
                            >
                                Ativo
                            </Button>
                            <Button
                                variant={state.enabled === false ? 'contained' : ''}
                                onClick={() => setState({ enabled: false })}
                            >
                                Inativo
                            </Button>
                        </Box>
                        <TextField
                            label='Nome'
                            size='small'
                            fullWidth
                            value={state.name}
                            onChange={(e) => setState({ name: e.target.value })}
                        />
                        <FormControl
                            fullWidth
                            size="small"
                            sx={{ flex: .45 }}
                        >
                            <InputLabel id="automation-bot-select-label">Fluxo</InputLabel>
                            <Select
                                labelId="automation-bot-select-label"
                                id="automation-box-simple-select"
                                onChange={(event) => setState({ public_id: event.target.value })}
                                value={state.public_id}
                                label='Fluxo'
                            >
                                {builderItems.map(each => (
                                    <MenuItem key={`${each.id}-select-frequency`} value={each.id}>{each.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Box className='automation-end-form-control' sx={{ justifyContent: 'space-between' }}>
                            <FormLabel >Início em</FormLabel>
                            <DemoContainer components={['DatePicker']}>
                                <DateTimePicker
                                    sx={{ minWidth: '8rem' }}
                                    views={['day', 'month', 'year', 'hours', 'minutes']}
                                    format={"DD/MM/YYYY HH:mm"}
                                    timezone={default_timezone}
                                    timeSteps={1}
                                    slotProps={{ textField: { size: 'small' } }}
                                    value={dayjs(state.start_time)}
                                    minDate={dayjs(new Date())} // disallow past dates
                                    onChange={(e) => setState({ start_time: e })}
                                />
                            </DemoContainer>
                        </Box>

                        <Box className='automation-select-repeat'>
                            {state.frequency === 'never' ? <>
                                <FormLabel style={{ flex: .55 }}>Não repetir</FormLabel>
                                <FormControl
                                    fullWidth
                                    size="small"
                                    sx={{ flex: .35 }}
                                >
                                    <Select
                                        id="automation-simple-select"
                                        onChange={(event) => setState({ frequency: event.target.value })}
                                        value={state.frequency}
                                    >
                                        {Object.entries(frequency_options).map(([key, value]) => (
                                            <MenuItem key={`${key}-select-frequency`} value={key}>{value}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </>
                                :
                                <>
                                    {state.frequency === 'days' ?
                                        <>
                                            <FormLabel style={{ flex: .4 }}>Repetir  a cada</FormLabel>
                                            <FormControl
                                                fullWidth
                                                size="small"
                                                sx={{ flex: .35 }}
                                            >
                                                <Select
                                                    id="automation-simple-select"
                                                    onChange={(event) => setState({ frequency: event.target.value })}
                                                    value={state.frequency}
                                                >
                                                    {Object.entries(frequency_options).map(([key, value]) => (
                                                        <MenuItem key={`${key}-select-frequency`} value={key}>{value}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            <TextField
                                                sx={{ flex: .15, }}
                                                size='small'
                                                fullWidth
                                                type='text'
                                                value={state.days}
                                                onChange={(e) => setState({ days: IntegerOnly(e.target.value, 1, 31) })}
                                                inputProps={{
                                                    maxLength: 3,
                                                    style: { textAlign: 'center' }
                                                }}
                                            />
                                        </>
                                        :
                                        <>
                                            <FormLabel style={{ flex: .4 }}>Repetir  a cada</FormLabel>
                                            <FormControl
                                                fullWidth
                                                size="small"
                                                sx={{ flex: .35 }}
                                            >
                                                <Select
                                                    id="automation-simple-select"
                                                    onChange={(event) => setState({ frequency: event.target.value })}
                                                    value={state.frequency}
                                                >
                                                    {Object.entries(frequency_options).map(([key, value]) => (
                                                        <MenuItem key={`${key}-select-frequency`} value={key}>{value}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </>}
                                </>
                            }

                        </Box>
                        {state.frequency === 'weeks' &&
                            <>
                                <FormLabel>Nos dias selecionados</FormLabel>
                                <Box className='automation-dayweek-selector'>
                                    {cron_weekdays.map(each =>
                                        <Tooltip title={each.value}>
                                            <Chip
                                                color={state.repeat.includes(each.id) ? 'primary' : 'default'}
                                                clickable label={each.value[0]}
                                                onClick={
                                                    () => {
                                                        if (state.repeat.includes(each.id)) {
                                                            setState({ repeat: state.repeat.filter(item => item !== each.id) })
                                                        } else {
                                                            state.repeat.push(each.id)
                                                            setState({ ...state })
                                                        }
                                                    }
                                                }
                                            />
                                        </Tooltip>
                                    )
                                    }
                                </Box>
                            </>
                        }
                        {state.frequency === 'months' &&
                            <Box className='automation-select-repeat'>
                                <FormLabel style={{ flex: .4 }}>No dia</FormLabel>
                                <TextField
                                    sx={{ flex: .15, }}
                                    size='small'
                                    fullWidth
                                    type='text'
                                    value={state.days}
                                    onChange={(e) => setState({ days: IntegerOnly(e.target.value, 1, 31) })}
                                    inputProps={{
                                        maxLength: 3,
                                        style: { textAlign: 'center' }
                                    }}
                                />
                            </Box>
                        }
                        {state.frequency !== 'never' && <FormControl>
                            <FormLabel id="automation-radio-buttons-group-label">Termina em</FormLabel>
                            <RadioGroup
                                aria-labelledby="automation-radio-buttons-group-label"
                                name="radio-buttons-group"
                                value={state.endson}
                                onChange={(e) => setState({ endson: e.target.value })}
                            >
                                <FormControlLabel value={'never'} control={<Radio />} label="Nunca" />
                                <Box className='automation-end-form-control'>
                                    <FormControlLabel value={'date'} control={<Radio />} label="Em" />
                                    <DemoContainer components={['DatePicker']}>
                                        <DatePicker
                                            sx={{ width: '4rem' }}
                                            disabled={state.endson === 'never'}
                                            views={['day', 'month', 'year']}
                                            format={"DD/MM/YYYY"}
                                            timezone={default_timezone}
                                            slotProps={{ textField: { size: 'small' } }}
                                            value={dayjs(state.expires)}
                                            onChange={(e) => setState({ expires: e })}
                                        />
                                    </DemoContainer>
                                </Box>
                                {/* <Box className='automation-end-form-control'>
                                    <FormControlLabel value="after" control={<Radio />} label="Após" />
                                    <TextField
                                        disabled={state.endson !== 'after'}
                                        size='small'
                                        sx={{ width: '3.5rem', textAlign: 'center' }}
                                        value={state.endamount}
                                        onChange={(e) => setState({ endamount: IntegerOnly(e.target.value, 1, 999) })}
                                        inputProps={{
                                            maxLength: 3,
                                            style: { textAlign: 'center' }
                                        }}
                                    />
                                    <Box className={'automation-align-text'}>Ocorrências</Box>
                                </Box> */}
                            </RadioGroup>
                        </FormControl>}
                    </LocalizationProvider>
                </DialogContent>
                <DialogActions style={{ padding: "10px 20px 10px 10px" }}>
                    <Tooltip title={disabled}>
                        <span>
                            <LoadingButton
                                onClick={() => {
                                    if (state.id && data.enabled && !state.enabled) {
                                        setState({ showconfirmation: true })
                                    }
                                    else
                                        submit(state, setState, setParent, reloadData)
                                }}
                                color="primary"
                                disabled={disabled}
                            >
                                Salvar
                            </LoadingButton>
                        </span></Tooltip>
                </DialogActions>
            </Dialog >
            {state.showconfirmation && <ConfirmationDialog
                open={true}
                title={'Inativar automação'}
                description={'Inativar essa automação desativará qualquer rotina relacionada em andamento. Deseja prosseguir?'}
                handleClose={() => setState({ showconfirmation: null })}
                handleSubmit={() => submit(state, setState, setParent, reloadData)}
            />}
        </>
    )
}

export default EditAutomation