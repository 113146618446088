import React, { useState, useEffect } from 'react';

import { Box } from '@mui/material';

import * as moment from 'moment';

import api from '../../api';
import { getAdminToken, saveAdminToken } from '../../auth';

import MetricsLogin from './MetricsLogin';
import FrameAgents from './FrameAgents';
import FrameNotifications from './FrameNotifications';
import FrameTodayAgents from './FrameTodayAgents';
import FrameEnterprises from './FrameEnterprises';
import FrameOngoing from './FrameOngoing';
import FrameExchanged from './FrameExchanged';
import FrameQueues from './FrameQueues';
import FrameAllChats from './FrameAllChats';
import FrameChatbot from './FrameChatbot';

import Loading from '../Loading';
import useNotification from '../Notification';

import './styles.css';

const MetricsGrid = () => {
  const [metrics , setMetrics] = useState({})
  const [intervalId, setIntervalId] = useState(null);
  const [loading, setLoading] = useState(false)

  const [password, setPassword] = useState('');
  const [token, setToken] = useState(getAdminToken());
  
  const fetchMetrics = async () => {
    fetchAllMetrics()
  }

  const fetchAllMetrics = async () => {
   
    const res = await api.get(`/api/metrics/`, {timeout:20000})
    

    if (res.status == 200){
      setMetrics(res.data)
    }
    else{
      'Ops!',
      'Excedeu o tempo limite de requisição.',
      'danger'
    }


  }


  const handleLogin = () => {
    if (!password) {
      alert('senha não informada');
      // this.setShowAlert("error", "Senha não informada.");
      return;
    }

    fetch('/api/auth/token/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        username: 'admin',
        password: password
      })
    }).then((res) => {
      if (res.status == 200)
        return res.json();
      throw res;
    }).then((data) => {
      setToken(data.access);
      saveAdminToken(data.access);
    }).then(() => {
      fetchMetrics();
    }).catch((err) => {
      console.error(err);
      // this.setShowAlert("error", "Credenciais incorretas, tente novamente.");
      // this.setState({ loading: false });
    });
  }

  useEffect( async() => {
    if (! token)
      return;
    setLoading(true)
    await fetchMetrics();
    setLoading(false)

    const intervalId = setInterval(fetchMetrics, 30000);
    setIntervalId(intervalId);

    return () => { clearInterval(intervalId); }
  }, []);

  return (
    <>
      {!token ? (
        <MetricsLogin onChangeFn={(ev) => setPassword(ev.target.value)} onClickFn={handleLogin} />
      ) : (
        <Box className="metrics-body">
          <Loading loading={loading} />
          <Box className="metrics-grid">
            <FrameEnterprises
              gridArea="enterprises"
              enterprisesAmount={metrics.enterprises}
            />
            <FrameQueues
              gridArea="queues"
              queues={metrics.queues}
            />
            <FrameAgents
              gridArea="agents"
              agents={metrics.agents}
            />
            <FrameOngoing
              gridArea="ongoing"
              onGoingChats={metrics.acd_chats}
            />
            <FrameAllChats
              gridArea="all-chats"
              allChats={metrics.chats}
            />
            <FrameChatbot
              gridArea="chatbot"
              answeredByBot={metrics.chatbot}
            />
            <FrameExchanged
              gridArea="exchanged"
              exchangedMessages={metrics.messages}
            />
            <FrameNotifications
              gridArea="notifications"
              notifications={metrics.notification_amount}
              engaged={metrics.engaged}
            />
            <FrameTodayAgents
              gridArea="today-agents"
              todayLoggedInAgents={metrics.logged_agents}
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export default MetricsGrid;