import React from "react";
import MiniDrawer from "../../components/Drawer"
import ClientsGrid from "../../components/Crm"

const Crm = (socket) => {
    return (
        <MiniDrawer child={<ClientsGrid />} socket={socket.socket} />
    );
};

export default Crm;