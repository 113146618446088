import React from "react";

import { Box, Button, Dialog } from "@mui/material";

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import {
	DataGridPro,
	GridToolbarContainer,
	GridToolbarColumnsButton,
	GridToolbarFilterButton,
	gridClasses,
	LicenseInfo,
	ptBR
} from '@mui/x-data-grid-pro';

import { ptBR as corePtBR } from '@mui/material/locale';

import {
	createTheme,
	ThemeProvider,
	StyledEngineProvider
} from '@mui/material/styles';

import LoadingButton from '@mui/lab/LoadingButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import Modal from './Modal';
import Loading from '../../Loading';
import useValidationError from '../../ErrorValidation';
import useNotification from "../../Notification";

import api from '../../../api';

import { getSession } from '../../../auth';

import './index.css';
import { alphabeticalSort } from "../../../utils/alphabeticalSort";

import { 
	stringOperators,
	booleanOperators
} from "../../../utils/filterOperators";


const theme = createTheme({
	palette: {
		primary: { main: '#21446C' },
	},
}, ptBR, corePtBR);

class ShippingReceiving extends React.Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			renderKey: '0',
			settings: [],
			addSetting: false,
			editSetting: false,
		};

		this.queuesName = {};
	}

	CustomToolbar = () => {

		return (
			<GridToolbarContainer className={gridClasses.toolbarContainer}>
				<GridToolbarColumnsButton />
				<GridToolbarFilterButton />
				<LoadingButton
					size="small"
					component="label"
					startIcon={<AddCircleIcon />}
					onClick={async () => {
						await this.setState({
							addSetting: true,
							editSetting: {},
							renderKey: Math.random()
						});
					}}
				>
					Adicionar
				</LoadingButton>
			</GridToolbarContainer>
		);
	}

	splitComma = (paramsValue) => {
		const newParamsValue = paramsValue.split(',');
		return (
			newParamsValue.map((value, index) => (
				<Box style={{ marginLeft: '3px' }}>
					{value +
						(index < (newParamsValue.length - 1) ? ',' : '')
					}
				</Box>
			))
		);
	}

	setSettings = (values) => {
		let settings = [];

		values.forEach(value => {
			settings.push({
				...value,
				actions: value
			});
		});

		settings.map(setting => {
			setting.queues = setting.queues?.filter(queue => this.queuesName[queue])
		}) 

		this.setState({
			settings: settings,
			renderKey: `settings-modal-${Math.random()}`
		});
	}

	deleteSettings = (settingId) => {
		this.setState({ loading: true });

		api.delete(`/api/enterprises/${this.props.enterpriseId}/queues/settings/${settingId}/`)
			.then(async (res) => {
				this.setState({ loading: false });
				if (res.status === 204 && this._isMounted) {
					await this.getSettings();
					useNotification(
						'Sucesso!',
						'Configurações por filas excluídas com sucesso.',
						'success'
					);
				} else if (res.status !== 204 && this._isMounted) {
					await useValidationError(res.status, null);
				}
			}).catch((err) => {
				console.error(err);
			});
	}

	getSettings = async () => {
		await this.setState({ loading: true });

		await api.get(`/api/enterprises/${this.props.enterpriseId}/queues/settings/`)
			.then(async (res) => {
				if (res.status == 200 && this._isMounted) {
					this.setSettings(res.data);
				}
			}).catch((err) => {
				console.error(err);
			});

		this.setState({ loading: false });
	}

	generateDialog = () => {
		return (
			<Modal
				addSetting={this.state.addSetting}
				editSetting={this.state.editSetting}
				getSettings={() => this.getSettings()}
				enterpriseId={this.props.enterpriseId}
				queuesChecked={this.queuesName}
				handleClose={() => this.setState({ addSetting: false, editSetting: false })}
				key={this.state.renderKey}
			/>
		);
	}

	componentWillMount() {
		this._isMounted = true;

		const user = getSession();
		let queuesName = {};

		api.get(`api/enterprises/${user.profile.enterprise.id}/queues/chat/`).then(res =>{
			if (res.status === 200){
				res.data.sort((x,y) => alphabeticalSort(x,y,'description')).filter(each=>!each.disabled).forEach(queue => {
					queuesName[queue.name] = queue.description;
				});
				this.queuesName = queuesName
				this.getSettings();
			}
			}
			)
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	createColumns() {
		return [
			{
				field: 'queues', headerName: 'Filas', flex: 2, filterOperators: stringOperators,
				renderCell: (params) => (
					<>
						{params.value.map((value, index) => (
							<Box style={{ marginLeft: '5px' }}>
								{this.queuesName[value] +
									(index < (params.value.length - 1) ? ',' : '')
								}
							</Box>
						))}
					</>
				)
			},
			{
				field: 'can_send_url', headerName: 'Links (Envio)', flex: 1, filterOperators: booleanOperators,
				renderCell: (params) => (
					<Box>
						{params.value ? <CheckCircleIcon className="active-send" /> : <CancelIcon className="inactive-send" />}
					</Box>
				)
			},
			{
				field: 'can_receive_url', headerName: 'Links (Recebimento)', flex: 1, filterOperators: booleanOperators,
				renderCell: (params) => (
					<Box>
						{params.value ? <CheckCircleIcon className="active-send" /> : <CancelIcon className="inactive-send" />}
					</Box>
				)
			},
			{
				field: 'can_send_audio', headerName: 'Áudio (Envio)', flex: 1, filterOperators: booleanOperators,
				renderCell: (params) => (
					<Box>
						{params.value ? <CheckCircleIcon className="active-send" /> : <CancelIcon className="inactive-send" />}
					</Box>
				)
			},
			{
				field: 'can_receive_audio', headerName: 'Áudio (Recebimento)', flex: 1, filterOperators: booleanOperators,
				renderCell: (params) => (
					<Box>
						{params.value ? <CheckCircleIcon className="active-send" /> : <CancelIcon className="inactive-send" />}
					</Box>
				)
			},
			{
				field: 'can_send_image', headerName: 'Imagem (envio)', flex: 1, filterOperators: booleanOperators,
				renderCell: (params) => (
					<Box>
						{params.value ? <CheckCircleIcon className="active-send" /> : <CancelIcon className="inactive-send" />}
					</Box>
				)
			},
			{
				field: 'can_send_image_extensions', headerName: 'Imagem (extensões de envio)', flex: 1, hide: true, filterOperators: booleanOperators,
				renderCell: (params) => (
					<>
						{this.splitComma(params.value)}
					</>
				)
			},
			{
				field: 'can_receive_image', headerName: 'Imagem (recebimento)', flex: 1, filterOperators: booleanOperators,
				renderCell: (params) => (
					<Box>
						{params.value ? <CheckCircleIcon className="active-send" /> : <CancelIcon className="inactive-send" />}
					</Box>
				)
			},
			{
				field: 'can_receive_image_extensions', headerName: 'Imagem (extensões de recebimento)', flex: 1, hide: true, filterOperators: booleanOperators,
				renderCell: (params) => (
					<>
						{this.splitComma(params.value)}
					</>
				)
			},
			{
				field: 'can_send_video', headerName: 'Vídeo (envio)', flex: 1, filterOperators: booleanOperators,
				renderCell: (params) => (
					<Box>
						{params.value ? <CheckCircleIcon className="active-send" /> : <CancelIcon className="inactive-send" />}
					</Box>
				)
			},
			{
				field: 'can_send_video_extensions', headerName: 'Vídeo (extensões de envio)', flex: 1, hide: true, filterOperators: booleanOperators,
				renderCell: (params) => (
					<>
						{this.splitComma(params.value)}
					</>
				)
			},
			{
				field: 'can_receive_video', headerName: 'Vídeo (recebimento)', flex: 1, filterOperators: booleanOperators,
				renderCell: (params) => (
					<Box>
						{params.value ? <CheckCircleIcon className="active-send" /> : <CancelIcon className="inactive-send" />}
					</Box>
				)
			},
			{
				field: 'can_receive_video_extensions', headerName: 'Vídeo (extensões de recebimento)', flex: 1, hide: true, filterOperators: booleanOperators,
				renderCell: (params) => (
					<>
						{this.splitComma(params.value)}
					</>
				)
			},
			{
				field: 'can_send_document', headerName: 'Documento (envio)', flex: 1, filterOperators: booleanOperators,
				renderCell: (params) => (
					<Box>
						{params.value ? <CheckCircleIcon className="active-send" /> : <CancelIcon className="inactive-send" />}
					</Box>
				)
			},
			{
				field: 'can_send_document_extensions', headerName: 'Documento (extensões de envio)', flex: 1, hide: true, filterOperators: booleanOperators,
				renderCell: (params) => (
					<>
						{this.splitComma(params.value)}
					</>
				)
			},
			{
				field: 'can_receive_document', headerName: 'Documento (recebimento)', flex: 1, filterOperators: booleanOperators,
				renderCell: (params) => (
					<Box>
						{params.value ? <CheckCircleIcon className="active-send" /> : <CancelIcon className="inactive-send" />}
					</Box>
				)
			},
			{
				field: 'can_receive_document_extensions', headerName: 'Documento (extensões de recebimento)', flex: 1, hide: true, filterOperators: booleanOperators,
				renderCell: (params) => (
					<>
						{this.splitComma(params.value)}
					</>
				)
			},
			{
				field: 'media_supervision', headerName: 'Supervisão de mídia', flex: 1, filterOperators: booleanOperators,
				renderCell: (params) => (
					<Box>
						{params.value ? <CheckCircleIcon className="active-send" /> : <CancelIcon className="inactive-send" />}
					</Box>
				)
			},
			{ field: 'supervision_pending_message', headerName: 'Mensagem ao cliente', flex: 1, hide: true, filterOperators: stringOperators },
			{
				field: 'actions', headerName: 'Ações', flex: 1, filterable: false, sortable: false,
				renderCell: (params) => (
					<Box>
						<Button className="action-button"
							onClick={async () => {
								await this.setState({
									addSetting: false,
									editSetting: params.value,
									renderKey: Math.random()
								});
							}}>
							<EditIcon />
						</Button>
						<Button className="action-button delete"
							onClick={() => this.deleteSettings(params.value.id)}>
							<DeleteIcon />
						</Button>
					</Box>
				)
			}

		]
	}

	render() {
		return (
			<>
				<Loading loading={this.state.loading} />
				{(this.state.editSetting || this.state.addSetting) &&
					<Dialog open={true} fullWidth
					disablePortal
                    disableEnforceFocus
                    sx={{position:'absolute'}}
					>
						{this.generateDialog()}
					</Dialog>
				}
				<Box className='BuilderContainer '>
					<Box className='BuilderContent' key={this.state.renderKey}>
						<StyledEngineProvider injectFirst>
							<ThemeProvider theme={theme}>
								<DataGridPro
									pagination
									disableSelectionOnClick
									disableDensitySelector
									density="compact"
									columns={this.createColumns()}
									rows={this.state.settings}
									components={{
										Toolbar: this.CustomToolbar,
									}}
								/>
							</ThemeProvider>
						</StyledEngineProvider>
					</Box>
				</Box>
			</>
		);
	}
}

export default ShippingReceiving;