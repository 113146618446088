import React, { useEffect, useReducer } from 'react'

import {
    Box, Dialog, DialogActions, DialogContent, DialogTitle,
    Tooltip, Button, TextField, Divider, Select, MenuItem, InputLabel, FormControl, Chip
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import api from '../../../../api';
import useNotification from '../../../Notification';

import defaultReducer from '../../../../utils/defaultReducer';
import CancelIcon from '@mui/icons-material/Cancel';
import { alphabeticalSort } from '../../../../utils/alphabeticalSort';


const action = (data) => {
    if (data?.id)
        return 'Editar'
    return 'Adicionar'
}

const UsersQueueEditModal = ({ data, setData, getRows, enterpriseId, setLoading, users, has_voice }) => {
    const [state, setState] = useReducer(defaultReducer, {
        description: '',
        type: 0,
        users: []
    })

    const checkButtonText = () => {
        const text = []

        if (state.description.length == 0)
            text.push(<Box>Nome não pode estar vazio.</Box>)

        if (text.length == 0)
            return ''

        return (text)
    }

    useEffect(async () => {
        if (!data.id)
            return

        let selected_users = []
        let selected_type = 0
        if (data.type == 'Chat')
            selected_users = users.sort((x,y) => alphabeticalSort(x,y, 'first_name')).filter(each => each.profile.queues.filter(queue => queue.id === data.id).length > 0).map(each => each.profile.agent_id)
        else {
            selected_users = data.queue_members.map(each => each.agents_id)
            selected_type = 1
        }


        setState({
            description: data.description,
            type: selected_type,
            users: selected_users
        })

    }, [data])

    const submit = async () => {
        let res = null
        const type = state.type == 0 ? 'chat/' : 'voice/'
        const url = `api/enterprises/${enterpriseId}/queues/${type}`

        setLoading(true)
        if (!data?.id)
            res = await api.post(url, state, { timeout: 0 })
        else
            res = await api.patch(url + `${data.id}/`, state, { timeout: 0 })
        setLoading(false)

        if (!(res.status === 200 || res.status === 201)) {
            let errorMessage = `Não foi possível ${action(data)} a fila.`
            if (res.status == 500) {
                if (res?.data?.error?.pop() === 10)
                    errorMessage = 'Não é possivel deixar um agente sem fila.'
            }
            useNotification(
                'Ops!',
                errorMessage,
                'danger'
            )
            getRows()
            setData()
            return
        }
        getRows()
        setData()
        useNotification(
            'Sucesso!',
            `Fila ${res.status == 201 ? 'adicionada' : 'atualizada'} com sucesso.`,
            'success'
        )
    }

    return (
        <>
            <Dialog open={true}
                fullWidth
                disablePortal
                disableEnforceFocus
                sx={{ position: 'absolute' }}
            >
                <DialogTitle
                    id="customized-dialog-title" className={'group-flexbox'}>
                    <span>{action(data)} Fila</span>
                    <Tooltip title="Fechar" >
                        <Button className="action-button"  >
                            <CloseIcon onClick={() => { setData() }} />
                        </Button>
                    </Tooltip>
                </DialogTitle>
                <Divider />
                <DialogContent className='piloto-modal-flex'>

                    <TextField
                        size='small'
                        label='Nome'
                        value={state.description}
                        onChange={(e) => setState({ description: e.target.value })}
                    />

                    {(!data.id && has_voice) && <Box className='BuilderBotSelectorBox'>
                        <Button
                            variant={state.type === 0 ? 'contained' : ''}
                            onClick={() => setState({ type: 0 })}
                        >
                            Chat
                        </Button>
                        <Button
                            variant={state.type === 1 ? 'contained' : ''}
                            onClick={() => setState({ type: 1 })}
                        >
                            Voz
                        </Button>
                    </Box>}

                    <FormControl fullWidth>
                        <InputLabel size='small' id='select-on-users-queue'>Usuários</InputLabel>
                        <Select
                            multiple
                            size='small'
                            labelId={'select-on-users-queue'}
                            label='Usuários'
                            value={state.users}
                            onChange={(e) => setState({ users: e.target.value })}
                            renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {selected.map((value) => {
                                        
                                        const user = users.find(user => user.profile.agent_id === value)

                                        return (
                                            <Chip
                                            size="small"
                                            key={value}
                                            className={!user.is_active ? 'hide-from-list' : ''}
                                            label={`${user?.first_name} ${user?.last_name}`}
                                            deleteIcon={
                                                <CancelIcon
                                                    onMouseDown={(event) => event.stopPropagation()}
                                                />}
                                            onDelete={() => setState({ users: state.users.filter(each => each !== value) })}
                                        />
                                        )
                                    }
                                        
                                    )}
                                </Box>
                            )}
                        >
                            {users.sort((x,y) => alphabeticalSort(x,y, 'first_name')).map(user => (
                                <MenuItem
                                    className={(state.users.includes(user.profile.agent_id) || !user.is_active) ? 'hide-from-list' : ''}
                                    value={user.profile.agent_id} data={user}>
                                    {user.first_name} {user.last_name}
                                </MenuItem>
                            ))}

                        </Select>
                    </FormControl>

                </DialogContent >
                <DialogActions style={{ padding: "10px 20px 10px 10px" }}>
                    <Button
                        onClick={() => { setData() }}>
                        Cancelar
                    </Button>
                    <Tooltip title={checkButtonText()} placement="top" describeChild={true}>
                        <span>
                            <Button
                                disabled={checkButtonText()}
                                onClick={submit}>
                                Salvar
                            </Button>
                        </span>
                    </Tooltip>
                </DialogActions>
            </Dialog >
        </>
    )
}

export default UsersQueueEditModal