import React from "react";
import { Card, CardContent } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import Typography from "../../Typography"
import PropTypes from "prop-types";

const CardButton = ({ onClick, title, subTitle, checked, minHeight, icon, height, colorNotChecked, border, colorChecked }) => {

    const useStyles = makeStyles(() => ({
        root: {
            border: !checked && (border),
            minHeight: minHeight,
            height: height,
            cursor: "pointer",
            backgroundColor: checked && colorChecked || !checked && colorNotChecked,
            '&:hover': {
                backgroundColor: checked && colorNotChecked || !checked && colorChecked
            },

        },
    }));

    const classes = useStyles();

    return (
        <div onClick={onClick}>
            <Card className={classes.root}>
                <CardContent>
                    {icon}
                    <Typography
                        variant="subtitle1" text={title} fontWeight="500" color="darkGray"
                    />
                    <Typography
                        variant="body1" text={subTitle} color="darkGray"
                    />
                </CardContent>
            </Card>
        </div>
    )
}

CardButton.propTypes = {
    onClick: PropTypes.func,
    title: PropTypes.string,
    subTitle: PropTypes.string,
    checked: PropTypes.bool,
    minHeight: PropTypes.string,
    icon: PropTypes.any,
    height: PropTypes.string,
    colorNotChecked: PropTypes.string,
    colorChecked: PropTypes.string,
    border: PropTypes.string
};

export default CardButton;