import React, { useReducer, useEffect, useRef } from 'react'
import { lockedReducer } from '../../../utils/defaultReducer'

import { Grid, Box } from '@mui/material'
import api from '../../../api'
import { getSession } from '../../../auth'
import FinishedRow from './finishedrow'
import { FixedSizeList } from 'react-window';
import AutoSizer from "react-virtualized-auto-sizer"

const limit = 6

const getData = async (state, setState, between = 0, filterSettings = null, filtered = false) => {
    const enterpriseId = getSession()?.profile.enterprise.id
    let concaturl = ''

    if (between > 0)
        concaturl = `&from=${state.cursorup}&to=${between}`

    if (filterSettings)
        concaturl = concaturl.concat(filterSettings.data)

    if (filtered)
        concaturl = concaturl.concat('&filtered=true')

    const res = await api.get('api/enterprises/' + enterpriseId + `/calls/chat/finishedv2/?limit=${limit}&cursor=${state.cursor}${concaturl}`)
    if (res.status === 200) {
        const actual_ids = state.rows.map(e => e.id)
        state.rows.push(...res.data.rows.filter(e => !actual_ids.includes(e.id)))
        setState({
            rows: state.rows.toSorted((x, y) => y.last_msg_id - x.last_msg_id),
            cursor: res.data.cursor > 0 ? res.data.cursor : 0,
            cursorup: res.data.cursorup > 0 ? res.data.cursorup : state.cursorup
        })
    }
}


const initialState = {
    rows: [],
    cursor: 0,
    cursorup: 0
}


const renderRow = (props, rows, scrollArea, onClickItem, isManageCalls, activeChat, state, loadMore) => {
    const { index, style } = props[0];

    const row = rows[index]

    const shouldLoadMore = state.cursor !== 0

    return (
        <>
            {row ?
                rows.length === index + 1 && shouldLoadMore ?

                    <FinishedRow
                        style={style}
                        shouldAlwaysCheck={true}
                        key={`individual-finished-row-last`}
                        isVisibleHook={loadMore}
                        parent={scrollArea}
                        data={{ id: -100 }}
                    />
                    :
                    <FinishedRow
                        style={style}
                        key={`individual-finished-row${rows[index]?.id}`}
                        parent={scrollArea}
                        data={rows[index]}
                        onClickItem={onClickItem}
                        isManageCalls={isManageCalls}
                        activeChat={activeChat}
                    />
                :
                <></>
            }
        </>
    )
}

const FinishedQueueContainer = ({ onClickItem, isManageCalls, activeChat, queueAmount, filterSettings }) => {

    const scrollArea = useRef()

    const [state, setState] = useReducer(lockedReducer, initialState)

    useEffect(() => {
        state.rows = []
        state.cursor = 0
        state.cursorup = 0
        getData(state, setState, 0, filterSettings, !isManageCalls)
    }, [filterSettings])

    useEffect(() => {
        if (queueAmount > state.cursorup && state.cursorup > 0 && queueAmount > 0)
            getData(state, setState, queueAmount, filterSettings, !isManageCalls)
    }, [queueAmount, state, filterSettings])

    return (
        <>
            {
                !isManageCalls ?
                    <Box className={'fit-tab v2 nomaxheight'} ref={scrollArea} key={`mainWindow${filterSettings}`} >
                        <AutoSizer >
                            {({ height, width }) =>
                            (
                                <>
                                    <FixedSizeList
                                        height={height}
                                        width={width}
                                        itemSize={100}
                                        itemCount={state.rows.length}
                                        overscanCount={5}
                                    >
                                        {(...props) => renderRow(
                                            props,
                                            state.rows,
                                            scrollArea,
                                            onClickItem,
                                            isManageCalls,
                                            activeChat,
                                            state,
                                            (v) => { v ? getData(state, setState, 0, filterSettings, !isManageCalls) : null })}
                                    </FixedSizeList>
                                </>
                            )}
                        </AutoSizer>
                    </Box >
                    :
                    <Grid item lg={3} md={3} sm={3} xs={3} sx={{ minWidth: '25rem' }}>
                        <Box className="call-columns queue-chats v2" >
                            <Box className="column-header" >
                                <Box className="column-title">
                                    Finalizados
                                </Box>
                            </Box>
                            <Box className={'fit-tab v2 auto'} ref={scrollArea} key={`mainWindow${filterSettings}`}>
                                <AutoSizer >
                                    {({ height, width }) =>
                                    (
                                        <>
                                            <FixedSizeList
                                                height={height}
                                                width={width}
                                                itemSize={100}
                                                itemCount={state.rows.length}
                                                overscanCount={5}
                                            >
                                                {(...props) => renderRow(
                                                    props,
                                                    state.rows,
                                                    scrollArea,
                                                    onClickItem,
                                                    isManageCalls,
                                                    activeChat,
                                                    state,
                                                    (v) => { v ? getData(state, setState, 0, filterSettings, !isManageCalls) : null })}
                                            </FixedSizeList>
                                        </>
                                    )}
                                </AutoSizer>
                            </Box>
                        </Box>
                    </Grid >
            }
        </>

    )
}

export default FinishedQueueContainer