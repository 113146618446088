import React, { useState, useEffect, useContext } from 'react'
import { constructorContext } from '..'
import {
    DataGridPro,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    gridClasses,
    LicenseInfo,
    ptBR
} from '@mui/x-data-grid-pro';

import {
    createTheme,
    ThemeProvider,
    StyledEngineProvider
} from '@mui/material/styles';

import { ptBR as corePtBR } from '@mui/material/locale';

import { Box, Tooltip, Button } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import useNotification from '../../../Notification';

import api from '../../../../api';
import ConfirmationDialogCaptcha from '../../../Dialogs/ConfimationDialogCaptcha';

import LoadingButton from '@mui/lab/LoadingButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import MessagesEditModal from './MessagesEditModal';

import PauseIcon from '@mui/icons-material/Pause';
import PlayIcon from '@mui/icons-material/PlayCircle';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { emojiReplaceJSX } from '../../../../utils/emojiDict';
import { stringOperators } from '../../../../utils/filterOperators';

const theme = createTheme({
    palette: {
        primary: { main: '#21446C' },
    },
}, ptBR, corePtBR);

const BuilderMessages = () => {
    const { activeTab, enterpriseId, loading, has_voice, setLoading } = useContext(constructorContext)

    const [rows, setRows] = useState([])
    const [deleteModal, setDeleteModal] = useState()
    const [editModal, setEditModal] = useState()
    const [playingAudio, setPlayingAudio] = useState({})
    const [smallLoading, setSmallLoading] = useState(false)

    const getURL = () => {
        let url = `api/enterprises/${enterpriseId}/messages/`
        url = url + (activeTab == 0 ? 'chat/' : 'voice/')
        return url
    }

    const getRows = async () => {
        setLoading(true)
        const res = await api.get(getURL(), { timeout: 0 })
        setLoading(false)
        if (res.status == 200) {
            setRows(res.data)
            return
        }
    }

    const submitDelete = async () => {
        let url = `api/enterprises/${enterpriseId}/messages/`
        url = url + (activeTab == 0 ? 'chat/' : 'voice/') + `${deleteModal.id}/`

        setLoading(true)
        const res = await api.delete(url, { timeout: 0 })
        setLoading(false)

        if (res.status == 204) {

            setDeleteModal()
            getRows()

            useNotification(
                'Sucesso!',
                'Mensagem deletada com sucesso.',
                'success'
            )
            return
        }
        useNotification(
            'Ops!',
            'Não foi possível excluir a mensagem.',
            'danger'
        )
    }

    useEffect(() => {
        getRows()
    }, [activeTab])


    const CustomToolbar = () => {

        return (
            <GridToolbarContainer className={gridClasses.toolbarContainer}>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <LoadingButton
                    size="small"
                    component="label"
                    startIcon={<AddCircleIcon />}
                    onClick={() => { setEditModal({}) }}
                >
                    Adicionar
                </LoadingButton>
            </GridToolbarContainer>
        );
    }


    const handleAudioPlay = async (row) => {

        if (smallLoading)
            return

        if (playingAudio.audio != null) {
            playingAudio.audio.pause()
            playingAudio.audio.currentTime = 0;
        }

        setSmallLoading(true)
        setPlayingAudio({ id: row.id, audio: null })

        const exportUrl = row.file;

        let audio = new Audio(exportUrl);
        audio.onended = (() => { setPlayingAudio({ id: null, audio: null }) })

        try {
            await audio.play();
            setPlayingAudio({ id: row.id, audio: audio })
        } catch (e) {
            setSmallLoading(false)
            setPlayingAudio({ id: null, audio: null })
            useNotification(
                'Ops!',
                'Esse arquivo não pode ser reproduzido.',
                'danger'
            )
        }

        setSmallLoading(false)

    }


    const defColumns = () => {
        const columns = []
        columns.push({ field: 'name', headerName: 'Nome', flex: 1, filterOperators: stringOperators })
        if (activeTab == 0)
            columns.push({
                field: 'transcription', headerName: 'Mensagem', flex: 5, filterOperators: stringOperators,
                renderCell: (params) => {
                    return (<span>{emojiReplaceJSX(params.value)}</span >)
    }
})
if (activeTab == 1)
    columns.push({
        field: 'type', headerName: 'Tipo', flex: 1, filterable: false,
        valueGetter: ({ value }) => {
            if (value == 'uploaded')
                return 'Importado'
            return 'Google'
        }
    })
columns.push({
    field: 'action', headerName: 'Ações', filterable: false, sortable: false,
    renderCell: (params) => (
        <>
            <Tooltip title="Editar" >
                <Button
                    className="action-button"
                    onClick={() => { setEditModal(params.row) }}
                >
                    <EditIcon />
                </Button>
            </Tooltip>

            {activeTab == 1 && <>
                {playingAudio.id == params.row.id ?
                    <>
                        {playingAudio.paused ?
                            <Tooltip title="Continuar" >
                                <Button
                                    className="action-button"
                                    onClick={(event) => {
                                        playingAudio.audio.play()
                                        setPlayingAudio(p => ({ ...p, paused: false }))
                                    }}
                                >
                                    <PlayIcon sx={{ color: 'green' }} />
                                </Button>
                            </Tooltip>
                            :
                            <Tooltip title="Pausar" >
                                <Button
                                    className="action-button"
                                    onClick={(event) => {
                                        if (smallLoading)
                                            return
                                        playingAudio.audio.pause()
                                        setPlayingAudio(p => ({ ...p, paused: true }))
                                    }}
                                >
                                    {smallLoading ? <AutorenewIcon className={'spinner'} />
                                        :
                                        <PauseIcon />}
                                </Button>
                            </Tooltip>
                        }
                    </>
                    :
                    <>
                        <Tooltip title="Ouvir" >
                            <Button
                                onClick={() => handleAudioPlay(params.row)}
                                className="action-button"
                            >
                                <PlayIcon />
                            </Button>
                        </Tooltip>
                    </>
                }
            </>}


            <Tooltip title="Excluir Mensagem">
                <Button className="action-button delete"
                    onClick={() => { setDeleteModal(params.row) }}>
                    <DeleteIcon />
                </Button>
            </Tooltip>
        </>
    ),
})

return columns
    }


return (
    <>{
        editModal ?
            <MessagesEditModal
                data={editModal}
                setData={setEditModal}
                getRows={getRows}
            />
            :
            null

    }
        {deleteModal ?
            <ConfirmationDialogCaptcha open={true}
                title="Excluir Mensagem"
                description={
                    <span>
                        Para confirmar a ação, digite: <span style={{ 'fontWeight': 'bold' }}>{deleteModal.name}</span> no campo abaixo.</span>}
                handleClose={() => { setDeleteModal() }}
                handleSubmit={() => { submitDelete() }}
                captcha={deleteModal.name}
            />

            :
            null
        }

        {activeTab == 0 &&
            <Box className="settings-body-bot">
                <StyledEngineProvider injectFirst >
                    <ThemeProvider theme={theme}>
                        <DataGridPro
                            pageSize={100}
                            pagination
                            disableSelectionOnClick
                            disableDensitySelector
                            density="compact"
                            columns={defColumns()}
                            rows={rows}
                            components={{
                                Toolbar: CustomToolbar,
                            }}
                        />
                    </ThemeProvider>
                </StyledEngineProvider>
            </Box>
        }
        {has_voice && activeTab == 1 &&
            <Box className="settings-body-bot">
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={theme}>
                        <DataGridPro
                            pageSize={100}
                            pagination
                            disableSelectionOnClick
                            disableDensitySelector
                            density="compact"
                            columns={defColumns()}
                            rows={rows}
                            components={{
                                Toolbar: CustomToolbar,
                            }}
                        />
                    </ThemeProvider>
                </StyledEngineProvider>
            </Box>
        }
    </>
)
}

export default BuilderMessages