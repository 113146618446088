import React, { useState, useEffect } from 'react'

import { 
    Box, 
    FormControl, 
    Select, 
    InputLabel, 
    MenuItem,
    Button,
    Grid,
    TextField,
    Alert
} from '@mui/material'

import LoadingButton from '@mui/lab/LoadingButton'

import SaveIcon from '@mui/icons-material/Save'

import EventManager from './EventManager'

import useNotification from '../../Notification'

import createChatbot from '../../../utils/createChatbot'
import api from '../../../api'

import { getSession } from '../../../auth'


const ExternalIntegrations = () => {

    const eventManagerRef = {}

    const [originalCrm, setOriginalCrm] = useState()
    const [integration, setIntegration] = useState({
        crm     : "",
        baseUrl : "",
        token   : "",
        newCrmSelected: false
    })
    const [existingCrms, setExistingCrms] = useState({})
    const [loading, setLoading] = useState(false)

    const getExistingCrms = () => {
        api.get('api/integration_crms/').then((res) => {
            const crms = {}
            const sortedCrms = res.data.map((crm) => crm.name).sort()
            sortedCrms.forEach((crm) => crms[crm.toUpperCase().replace(/\s+/g, '')] = crm)
            setExistingCrms(crms)
        }).catch((err) => {})
    }

    const getIntegration = () => {
        const enterpriseId = getSession().profile.enterprise.id
        api.get(`api/enterprises/${enterpriseId}/`).then((response) => {
            if (response.status === 200) {
                setOriginalCrm(response.data.settings.integration_source)
                setIntegration({
                    crm     : response.data.settings.integration_source       ?? '',
                    baseUrl : response.data.settings.integration_base_url     ?? '',
                    token   : response.data.settings.integration_access_token ?? '',
                    newCrmSelected: false
                })
            }
        }).catch((error) => {})
    }

    const saveEvent = async (enterpriseId, event) => {
        if (event.replaced)
            await api.patch(`api/enterprises/${enterpriseId}/events/${event.id}/`, {
                builderbot : event.builderbot,
                event      : event.action,
                status     : true
            }, { timeout: 10000 })
        else
            await api.post(`api/enterprises/${enterpriseId}/events/`, {
                builderbot : event.builderbot,
                event      : event.action,
                status     : true
            }, { timeout: 10000 })
    }

    const submit = async () => {
        const enterpriseId = getSession().profile.enterprise.id
        const parsedCrm = integration.crm.toUpperCase().replace(/\s+/g, '')
        if (integration.crm && (! integration.baseUrl || ! integration.token)) {
            useNotification(
                'Atenção!',
                'URL e Token são campos obrigatórios.',
                'warning'
            )
            return
        }
        setLoading(true)
        for (const event of eventManagerRef.state.integration_merged) {
            if (event.marketplace) { 
                const chatbot = await createChatbot(event.builderbot)
                event.builderbot = chatbot.typebot.id
                await saveEvent(enterpriseId, event)
            }
        }
        const response = await api.patch(`api/enterprises/${enterpriseId}/`, {
            settings: {
                integration_source       : parsedCrm,
                integration_base_url     : integration.baseUrl, 
                integration_access_token : integration.token
            }
        })
        setLoading(false)
        if (response.status !== 200) {
            useNotification(
                'Ops!',
                'Algo deu errado, tente novamente.',
                'danger'
            )
            return
        }
        getIntegration()
        useNotification(
            'Sucesso!',
            'Integração realizada com sucesso.',
            'success'
        )
    }

    useEffect(() => {
        getIntegration()
        getExistingCrms()
    }, [])

    return (
        <Box className='input-settings'>
            <Grid container spacing={3}>
                <Grid item lg={2} md={4} xs={12}>
                    <FormControl fullWidth className="push-bottom" size="small">
                        <InputLabel id="existing-integration-select-label">CRM</InputLabel>
                        <Select
                            labelId="existing-integration-select-label"
                            id="existing-integration-select"
                            label="CRM"
                            value={integration.crm}
                            onChange={(event) => {
                                setIntegration((prevState) => ({
                                    ...prevState,
                                    crm: event.target.value,
                                    newCrmSelected: originalCrm != event.target.value
                                }))
                            }}
                        >
                            {Object.keys(existingCrms).map((key) => (
                                <MenuItem key={key} value={key}>{existingCrms[key]}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            {integration.crm &&
                <>
                    <TextField
                        sx={{ marginBottom: "1rem" }}
                        size="small"
                        label="URL"
                        type="text"
                        variant="outlined"
                        value={integration.baseUrl}
                        onChange={(event) => setIntegration((prevState) => ({
                            ...prevState,
                            baseUrl: event.target.value
                        }))}
                    />
                    <TextField
                        sx={{ marginBottom: "1rem" }}
                        size="small"
                        label="Token"
                        type="text"
                        variant="outlined"
                        value={integration.token}
                        onChange={(event) => setIntegration((prevState) => ({
                            ...prevState,
                            token: event.target.value
                        }))}
                    />
                    <Box className="category-title push-bottom">Eventos</Box>
                    <EventManager
                        innerRef={eventManagerRef}
                        integrationCrm={integration.crm} 
                        newCrmSelected={integration.newCrmSelected}
                    />
                    <Grid container spacing={3}>
                        <Grid item xs={12} sx={{ display: "flex", gap: "1rem" }}>
                            <LoadingButton 
                                className="push-top"
                                loading={loading}
                                loadingPosition="start"
                                startIcon={<SaveIcon />}
                                variant="contained"
                                onClick={submit}
                            >
                                Salvar
                            </LoadingButton>
                            {integration.newCrmSelected &&
                                <Alert size="small" severity="warning" className="new-crm-selected-info">
                                    Salve as alterações para verificar os fluxos e realizar edições adicionais.
                                </Alert>
                            }
                        </Grid>
                    </Grid>
                </>
            }
        </Box>
    )
}

export default ExternalIntegrations 
