import React from 'react';
import { Box } from '@mui/material';
import "./styles.css";
import LogoIcon from '../../../assets/images/kwik_favicon.png';
import ErrorIcon from '@mui/icons-material/Error';

class AlertMobileVersion extends React.Component {
  
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <Box className="background-mobile">
            <img src={LogoIcon} alt="logo" className="logoIcon" />
            <Box className="popup-mobile">
              <Box id="title-popup">Atenção <ErrorIcon /></Box>
              <Box id="body-popup">
                  Lamentamos o imprevisto, mas nossa aplicação ainda não é compatível com o tamanho da sua tela!
              </Box>
            </Box>
        </Box>
      </div>
    );
  }
}

export default AlertMobileVersion;