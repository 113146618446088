import React from 'react';

import { IconButton, Box, Divider } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import makeStyles from '@mui/styles/makeStyles';
import MaterialMenu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PropTypes from "prop-types";
import AvatarName from "../../AvatarName";
import * as moment from "moment";

import "./styles.css";

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props) => (
    <MaterialMenu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&:focus': {
            backgroundColor: theme.palette.secondary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);

const AccountMenu = (
    { user, menuItems, isLogoutButtonLoading }
) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const useStyles = makeStyles({
        icon: {
            color: "#fff"
        },
        menuHeader: {
            padding: "10px 15px",
            minWidth: "200px",
            fontSize: "10pt"
        },
        menuName: {
            fontWeight: "bold",
            color: "#68686B",
            fontSize: "14pt"
        },
        pushLeft: {
            paddingLeft: "15px"
        },
        pushTop: {
            marginTop: "10px"
        }
    });

    const classes = useStyles();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const nameQueues = () => {
        let queues = [];

        user.profile.queues.map(queue => (
            queues.push({ 
                name: queue.description, 
                code: queue.name.split('_')[1] 
            })
        ));
        
        return queues;
    }

    return (
        <div >
            <IconButton size="large">
                <AvatarName 
                    name={`${user.first_name} ${user.last_name}`} 
                    handleClick={handleClick} 
                    size="medium" 
                />
                {user.profile && 
                 user.profile.agent && 
                 user.profile.agent.agentstatus == 'LOGIN' &&
                    <Box className="circle login" title="Agente Logado"></Box>
                }
                {user.profile && 
                 user.profile.agent && 
                 user.profile.agent.agentstatus == 'LOGOFF' &&
                    <Box className="circle logoff" title="Agente Deslogado"></Box>
                }
                {user.profile && 
                 user.profile.agent && 
                 user.profile.agent.agentstatus == 'PAUSE' &&
                    <Box className="circle pause" title="Agente Pausado"></Box>
                }
            </IconButton>
            <StyledMenu
                sx={{zIndex:2001}}
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {user &&
                    <div className={classes.menuHeader}>
                        <Box className={classes.menuName}>
                            {user.first_name} {user.last_name}
                        </Box>
                        {user.profile && user.profile.agent &&
                            <div className={classes.pushTop}>
                                <Box className={classes.pushTop}>
                                    <small>Login</small>
                                </Box>
                                <Box className={classes.pushTop}>
                                    <small className={classes.pushLeft}>
                                        {moment(new Date(user.profile.agent.logints*1000)).format("DD/MM/YYYY HH:mm:ss").toString()}
                                    </small>
                                </Box>
                                <Box className={classes.pushTop}>
                                    <small>Filas</small>
                                </Box>
                                <Box className="name-queues">
                                    {nameQueues().map((queue, index) => (
                                        <small key={index} className={classes.pushLeft}>
                                            {`${queue.code} - ${queue.name}`}
                                        </small>
                                    ))}
                                </Box>
                            </div>
                        }
                        {user.profile && user.profile.agent && user.profile.agent.agentstatus == 'PAUSE' &&
                            <div className={classes.pushTop}>
                                <small>Tempo pausado</small>
                                <Box>
                                    <small className={classes.pushLeft}>
                                        {moment.duration(moment(new Date()).diff(moment(user.profile.agent.agentstatusts * 1000))).asHours().toFixed(2)} hora(s)
                                    </small>
                                </Box> 
                            </div>
                        }
                    </div>
                }
                <Divider />
                {menuItems.map((item, i) => (
                    <StyledMenuItem
                        key={i}
                        onClick={item.onClick}
                        style={{ marginBottom: "-8px" }}
                        disabled={isLogoutButtonLoading}
                    >
                        <ListItemIcon>
                            {item.icon}
                        </ListItemIcon>
                        <ListItemText primary={item.title} />
                    </StyledMenuItem>
                ))}
            </StyledMenu>

        </div>
    );
}

AccountMenu.propTypes = {
    menuItems: PropTypes.array,
    isLogoutButtonLoading: PropTypes.bool
};
export default AccountMenu;
