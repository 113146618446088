import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TelegramIcon from '@mui/icons-material/Telegram';
import InstantChatIcon from '@mui/icons-material/Language';
import InstagramIcon from '@mui/icons-material/Instagram';
import EmailIcon from '@mui/icons-material/Email';
import FacebookIcon from '@mui/icons-material/Facebook';
import SmsIcon from '@mui/icons-material/Sms';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import ApiIcon from '@mui/icons-material/DataObject';

export const replaceMCDU = (data) => {

    if (!data)
        return ''

    switch (data) {
        case 'WHATSAPP':
            return (<WhatsAppIcon className="action-button" sx={{ color: '#34af23' }} />)
        case 'TELEGRAM':
            return (<TelegramIcon className="action-button" sx={{ color: '#3390ec' }} />)
        case 'INSTAGRAM':
            return (<InstagramIcon className="action-button" sx={{ color: '#fd6b10' }} />)
        case 'EMAIL':
            return (<EmailIcon className="action-button" sx={{ color: '#757575' }} />)
        case 'MESSENGER':
            return (<FacebookIcon className="action-button" sx={{ color: '#0866ff' }} />)
        case 'SMS':
            return (<SmsIcon className="action-button" sx={{ color: '#757575' }} />)
        case 'AUTOMATION':
            return (<SmartToyIcon className="action-button" sx={{ color: '#757575' }} />)
        case 'API':
            return (<ApiIcon className="action-button" sx={{ color: '#757575' }} />)
        default:
            return (<InstantChatIcon className="action-button" sx={{ color: '#5399DB' }} />)
    }
}