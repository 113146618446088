import React from 'react'
import SettingsIntermediate from '../SettingsIntermediate'
import userPermissions from '../../User/Permissions';
import { getSession } from '../../../auth';
import Operators from '../../Settings/Users/Operators';
import UsersComponent from '../../Settings/Users'

const UsersPage = () => {
    const groups = userPermissions();
    const enterprise = getSession()?.profile?.enterprise
    return (
        <>
            <SettingsIntermediate title='Usuários' components={() => ({
                comp1:{
                    permissions: true,
                    style:'title',
                    label:'Usuários'
                },
                comp2:{
                    permissions: groups.isSupervisor,
                    style:'list',
                    label:'Usuários do Sistema',
                    sublabel: 'Ver e configurar usuários do sistema.',
                    redirect: (<UsersComponent enterpriseId={enterprise?.id}/>)
                },
                comp3:{
                    permissions: groups.isAdmin,
                    style:'list',
                    label:'Operadores',
                    sublabel: 'Opções para o tipo de usuário operador.',
                    redirect: (<Operators/>)
                }
            })
            } />
        </>
    )
}


export default UsersPage