import React from "react";

import * as moment from "moment";

import {
	Box,
	Button,
	IconButton,
	Tooltip,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	TextField
} from '@mui/material';

import { GridRow } from '@mui/x-data-grid-pro';

import LoadingButton from '@mui/lab/LoadingButton';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import QuestionIcon from '@mui/icons-material/QuestionMark';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import EyeIcon from '@mui/icons-material/Visibility';
import RefreshIcon from '@mui/icons-material/Refresh';

import ConfirmationDialog from '../Dialogs/Confirmation';
import ClientDialog from '../Dialogs/Client';

import Loading from '../Loading';
import useNotification from "../Notification";
import Table from '../Table';

import ResponsiveSendMessageButton from "./ResponsiveSendMessageButton";
import ResponsiveImportButton from "./ResponsiveImportButton";
import ResponsiveClientsButton from "./ResponsiveClientsButton";
import ResponsiveNotificationButton from "./ResponsiveNotificationButton";
import ResponsiveCampaignButton from "./ResponsiveCampaignButton";
import ResponsiveClientGroupButton from "./ResponsiveClientGroupButton";
import ResponsiveGenerateButton from "./ResponsiveGenerateButton";
import ResponsiveCreateClientGroupButton from "./ResponsiveCreateClientGroupButton";
import CampaignSection from "./CampaignSection";

import { getSession } from "../../auth";
import api from '../../api';
import userPermissions from '../User/Permissions';
import isMobile from "../../utils/isMobile";

import Templates from "./Templates";
import ResponsiveAddButton from "./ResponsiveAddButton";
import './styles.css';

class Clients extends React.Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			importLoading: false,
			clients: [],
			clientsObj: {},
			groups: [],
			groupsObj: {},
			clientsFilter: { items: [] },
			notificationsFilter: { items: [] },
			showTemplates: false,
			templates: [],
			selectionModel: [],
			originNumbers: [],
			pageSize: 0,
			columns: [],
			mobileColumns: [],
			notificationColumns: [],
			clientToDelete: null,
			clientToChange: null,
			renderKeyChangeDialog: 1,
			renderKeyCreateDialog: 2,
			deleteClientDescription: '',
			showDeleteClientDialog: false,
			showDeleteSelectedClientsDialog: false,
			showChangeClientDialog: false,
			showCreateClientDialog: false,
			createClientSaveButtonLoading: false,
			editClientSaveButtonLoading: false,
			deleteConfirmationButtonLoading: false,
			createClientGroupSaveButtonLoading: true,
			importInformation: false,
			// API integration next/previous pages
			nextPage: null,
			prevPage: null,
			notificationsSection: false,
			notifications: [],
			showTemplate: false,
			template: null,
			startDateTime: moment().set({ hour: 8, minute: 0 }).format("YYYY-MM-DDTHH:mm"),
			endDateTime: moment().set({ hour: 18, minute: 0 }).format("YYYY-MM-DDTHH:mm"),
			startDateTimeMobile: moment().set({ hour: 0, minute: 0 }).format("YYYY-MM-DDTHH:mm"),
			endDateTimeMobile: moment().set({ hour: 23, minute: 59 }).format("YYYY-MM-DDTHH:mm"),
			isMobile: isMobile(),
			campaignSection: false,
			clientGroupModal: false,
			filterCampaign: 0,
			notificationRedirect: false,
		};

		this.enterpriseId = 0;
		this.accountcode = null;
		this.queues = {};
		this.groups = null;

		this.notificationError = {
			'400': '[400] Erro de download de mídia',
			'402': '[402] Qualificação da empresa — Problema de pagamento',
			'408': '[408] Mensagem inválida',
			'410': '[410] Mensagem expirou',
			'429': '[429] Limite de envio atingido',
			'430': '[430] Certificado não assinado',
			'432': '[432] Incompatibilidade de ID do certificado',
			'433': '[433] Assinatura do certificado inválida',
			'470': '[470] Mensagem de reengajamento',
			'471': '[471] Limite de envio de spam atingido',
			'472': '[472] Número do usuário faz parte de um experimento',
			'480': '[480] Usuário potencialmente alterado',
			'500': '[500] Erro genérico',
			'1000': '[1000] Erro genérico',
			'1001': '[1001] Mensagem muito longa',
			'1002': '[1002] Tipo de destinatário inválido',
			'1004': '[1004] Recurso já existe',
			'1005': '[1005] Acesso negado',
			'1006': '[1006] Recurso não encontrado',
			'1008': '[1008] Parâmetro obrigatório ausente',
			'1009': '[1009] Parâmetro inválido',
			'1010': '[1010] Parâmetro não é obrigatório',
			'1011': '[1011] Serviço não está pronto',
			'1013': '[1013] Destinatário não possui WhatsApp',
			'1014': '[1014] Erro interno',
			'1015': '[1015] Limite de envio atingido',
			'1016': '[1016] Sistema sobrecarregado',
			'1017': '[1017] Conta não está configurado corretamente',
			'1018': '[1018] Não é o aplicativo principal principal',
			'1021': '[1021] Usuário inválido',
			'1022': '[1022] URL dos webhooks não está configurado',
			'1023': '[1023] Ocorreu um erro de banco de dados',
			'1024': '[1024] Você é obrigado a alterar sua senha',
			'1025': '[1025] Requisição inválida',
			'1026': '[1026] Destinatário incapaz de receber esta mensagem',
			'1028': '[1028] Uma notificação do sistema requer confirmação',
			'1031': '[1031] Remetente foi bloqueado',
			'2000': '[2000] Incompatibilidade de contagem de parâmetros',
			'2001': '[2001] Template não existe pro idioma específico.',
			'2002': '[2002] Receptor falhou ao baixar o modelo',
			'2003': '[2003] Não existem modelos pro idioma específico',
			'2004': '[2004] Comprimento do parâmetro muito longo',
			'2005': '[2005] Texto traduzido muito longo',
			'2006': '[2006] Política de espaço em branco violada',
			'2007': '[2007] Política de caracteres de formato violada',
			'2008': '[2008] Formato de mídia usado não é compatível',
			'2009': '[2009] Componente obrigatório no modelo está ausente',
			'2010': '[2010] URL do botão é inválido',
			'2011': '[2011] Número de telefone do botão é inválido',
			'2012': '[2012] Formato do parâmetro não corresponde ao template',
			'2013': '[2013] Os botões não são suportados pelo destinatário',
			'2014': '[2014] Namespace esperado está vazio',
			'2015': '[2015] Número inválido de seções',
			'2016': '[2016] Número inválido de linhas',
			'2017': '[2017] Política de personagem violada',
			'2023': '[2023] Número de produto inválido',
			'2024': '[2024] ID do catálogo não encontrado',
			'2025': '[2025] ID do catálogo não vinculado ao número da API',
			'2026': '[2026] Produtos ausentes',
			'2027': '[2027] Nenhum produto encontrado',
    	'131026': '[131026] Destinatário não pode receber a mensagem',
    	'131042': '[131042] A mensagem não foi enviada porque houve um ou mais erros relacionados à sua forma de pagamento',
    	'131053': '[131053] Erro no upload da mídia',
    	'131008': '[131008] Um parâmetro obrigatório está faltando',
    	'131049': '[131049] Esta mensagem não foi entregue para manter o engajamento saudável do ecossistema',
		}
	}

	getTemplates = async () => {
		await api.get(`/api/enterprises/${this.enterpriseId}/templates/`, { timeout: 0 })
			.then((res) => {
				if (res.status == 200) {
					let templates = res.data.waba_templates.filter((template) => {
						return (template.status == 'approved' &&
						template.language == 'pt_BR' &&
						!template.name.includes('sample_'));
					});
					this.state.templates = templates;
					return;
				}
				throw res;
			})
			.catch((err) => {
				console.error(err);
			});
	}

	filterOnClick = (groupName) => {
        this.setState({
          clientsFilter:  { items: [{columnField: 'groups',
          operatorValue: 'contains', value: String(groupName)}],
         }})
       }

	setClientsData = (clients) => {
		const user = getSession();
		const enterpriseSettings = user.profile.enterprise.settings;

		// Blocking sorting for integrations
		const sortable = (enterpriseSettings.integration_source) ? false : true;

		let mobileColumns = [];

		if (this.state.isMobile) {
			mobileColumns.push(
				{ field: 'name', headerName: 'Nome', flex: 1, sortable: sortable },
				{ field: 'telephone', headerName: 'Telefone', flex: 1, sortable: sortable }
			)
		}

		let columns = [
			{ field: 'name', headerName: 'Nome', flex: 1, sortable: sortable },
			{ field: 'telephone', headerName: 'Telefone', flex: 1, sortable: sortable },
			{ field: 'email', headerName: 'Email', flex: 1, sortable: sortable, hide: this.state.isMobile },
			{
				field: 'created_at', type: 'dateTime', headerName: 'Criação', flex: 1, sortable: sortable, hide: this.state.isMobile,
				valueGetter: ({ value }) => {
					if (value) {
						const date = new Date(value);
						date.setHours(date.getHours() - 3);
						return date;
					}
				},
				renderCell: (params) => (
					<Box>
						{params.value.toLocaleString('pt-BR')}
					</Box>
				)
			}
		];

		if (clients.length != 0 && 'user_fields' in clients[0]) {
			// Zendesk organization field
			columns.push({ field: 'organization_id', headerName: 'Organização', flex: 1, sortable: sortable, hide: this.state.isMobile });
			// Zendesk integration custom fields
			Object.keys(clients[0].user_fields).forEach((field) => {
				const headerName = this.capitalize(field.replaceAll('_', ' '));
				columns.push({ field: field, headerName: headerName, flex: 1, sortable: sortable, hide: this.state.isMobile });
			});
		}
		// if(!enterpriseSettings.integration_source) {
		// 	columns.push(
		// 		{ field: 'groups', headerName: 'Segmentos', flex: 1, sortable: sortable,
		// 			valueGetter: ({ value }) => {
		// 				return value.map(each => each.name).join()
		// 			},
		// 			renderCell: (params) => (
		// 				<>
		// 				{params.row.groups.map((group) => 
		// 					<Box onClick={() => {this.filterOnClick(group.name)}} className='client-group-item pointer'>
		// 						{group.name}
		// 					</Box>
		// 				)}
		// 				</>
		// 			)
		// 		})
		// }

		columns.push({
			field: 'action', headerName: 'Ações', flex: 1, sortable: sortable, hide: this.state.isMobile,
			renderCell: (params) => (
				<Box>
					<Button className="action-button"
						onClick={() => this.showChangeClientModal(params.row)}>
						<EditIcon />
					</Button>
					<Button className="action-button delete"
						onClick={() => this.showDeleteClientModal(params.row)}>
						<DeleteIcon />
					</Button>
				</Box>
			)
		});

		const clientsObj = this.mapClients(clients);

		this.setState({
			columns: columns,
			mobileColumns: mobileColumns,
			clients: clients,
			clientsObj: clientsObj
		});
	}

	getNotificationItemClass = (value) => {
		switch (value) {
			case 'SUCCESS':
				return 'notification-status success';
				break;
			case 'WAITING':
				return 'notification-status warning';
				break;
			case 'FAILURE':
				return 'notification-status failure';
				break;
			default:
				break;
		}
	}

	getNotificationStatusTranslation = (value) => {
		switch (value) {
			case 'SUCCESS':
				return 'SUCESSO';
				break;
			case 'WAITING':
				return 'PENDENTE';
				break;
			case 'FAILURE':
				return 'ERRO';
				break;
			default:
				return 'NÃO IDENTIFICADO';
				break;
		}
	}

	showTemplate = (data) => {
		const template = JSON.parse(data.template);
		const parameters = JSON.parse(data.parameters);

		let templateText = template.components[0].text;
		if (parameters) {
			templateText = templateText.replace(/{{(\d+)}}/g, function (_, m) {
				return parameters[--m];
			});
		}

		this.setState({
			showTemplate: true,
			template: templateText
		});
	}

	getNotificationColumns = () => {
			return [
			{ field: 'to', headerName: 'Destinatário', flex: 1 },
			{ field: 'sender', type: 'text', headerName: 'Origem', flex:1, hide:true},
			{ field: 'queue_name', headerName: 'Fila', flex: 1, hide: this.state.isMobile },
			{
				field: 'created_at', type: 'dateTime', headerName: 'Criação', flex: 1, hide: this.state.isMobile,
				valueGetter: ({ value }) => {
					if (value) {
						const date = new Date(value);
						date.setHours(date.getHours() - 3);
						return date;
					}
				},
				renderCell: (params) => (
					<Box>
						{params.value.toLocaleString('pt-BR')}
					</Box>
				)
			},
			{
				field: 'processed_at', type: 'dateTime', headerName: 'Processamento', flex: 1, hide: this.state.isMobile,
				valueGetter: ({ value }) => {
					if (value) {
						const date = new Date(value);
						date.setHours(date.getHours() - 3);
						return date;
					}
				},
				renderCell: (params) => (
					<Box>
						{params.value ? params.value.toLocaleString('pt-BR') : ''}
					</Box>
				)
			},
			{
				field: 'status', headerName: 'Status', flex: .5, sortable: false,
				renderCell: (params) => (
					<Box className={this.getNotificationItemClass(params.value)}>
						{this.getNotificationStatusTranslation(params.value)}
					</Box>
				)
			},
			{
				field: 'used', headerName: 'Respondida', flex: .5, sortable: false, hide: this.state.isMobile,
				renderCell: (params) => {
					if (params.value) {
						return (<CheckIcon className="icon-answered" />);
					}
					return (<CloseIcon className="icon-not-answered" />);
				}
			},
			{
				field: 'template', headerName: 'Template', flex: 1.5, hide: this.state.isMobile,
				renderCell: (params) => {
					const data = JSON.parse(params.value);
					return (
						<>
							<Button className="action-button view-template"
								onClick={() => this.showTemplate(params.row)}>
								<EyeIcon />
							</Button>
							{data.name}
						</>
					);
				}
			},
			{
				field: 'comments', headerName: 'Comentários', flex: 2, hide: this.state.isMobile,
				renderCell: (params) => {
					return this.handleNotificationErrorMessage(params.value)
				}
			},
			// {field: 'campaign_name', headerName: 'Campanha', flex: 1,}
		];
	}

	handleNotificationErrorMessage = (message) => {
		/** 
		 * Handle WhatsApp API errors
		 * https://developers.facebook.com/docs/whatsapp/on-premises/errors 
		 */

		if (!message) return;

		try {
			const parsedMessage = JSON.parse(message.replace(/'/g, '"'));
			const code = parsedMessage.errors[0].code;
			return this.notificationError[code];
		} catch (err) {
			console.error(err);
			return 'Erro desconhecido';
		}
	}

	showChangeClientModal = (client) => {
		this.setState({
			clientToChange: client,
			showChangeClientDialog: true,
			renderKeyChangeDialog: Math.random()
		});
	}

	formatPhone = (telephone) => {
		let newTelephone = '';

		if (telephone) {
			const numberTelephone = telephone.replace(/[^0-9]+/g, '');

			if (this.isNumber(numberTelephone) && numberTelephone.substring(0, 3) !== '+55') {
				const oldTelephone = numberTelephone;
				newTelephone = `+55${oldTelephone}`;

			} else {
				newTelephone = telephone;
			}
		}

		return newTelephone;
	}

	isNumber = (n) => {
		return !isNaN(parseFloat(n)) && isFinite(n);
	}

	changeClient = (id, name, initialEmail, email, initialTelephone, telephone, userFields) => {
		this.setState({ editClientSaveButtonLoading: true });

		const data = {};

		if (name)
			data['name'] = name;

		if (email && initialEmail != email)
			data['email'] = email;

		if (telephone && initialTelephone != telephone)
			data['telephone'] = this.formatPhone(telephone);

		if (userFields)
			data['user_fields'] = userFields;

		api.patch(`/api/enterprises/${this.enterpriseId}/contacts/${id}/`, data)
			.then(async (res) => {
				if (res.status == 200) {
					const clients = this.state.clients;
					const clientsObj = this.state.clientsObj;
					clients.forEach((client) => {
						if (client.id == id) {
							client.name = name;
							client.email = email;
							client.telephone = telephone;
							client.user_fields = userFields;
							Object.keys(userFields).forEach((key) => {
								client[key] = userFields[key];
							});
							clientsObj[id] = client;
						}
					});

					this.setState({
						clients: clients,
						clientsObj: clientsObj,
						clientToChange: null,
						showChangeClientDialog: false,
						editClientSaveButtonLoading: false
					});

					await useNotification(
						'Sucesso!',
						'Cliente atualizado',
						'success'
					);

					return;
				}
				throw res;
			})
			.catch(async (err) => {
				this.setState({ editClientSaveButtonLoading: false });

				await useNotification(
					'Ops!',
					'Ocorreu um erro no sistema',
					'danger'
				);
				console.error(err);
			})
	}

	createClient = async (name, email, telephone, userFields) => {
		this.setState({ createClientSaveButtonLoading: true });

		const formatTelephone = this.formatPhone(telephone);

		if (!formatTelephone) {
			await useNotification(
				'Atenção!',
				'Telefone é um campo obrigatório.',
				'warning'
			);
			return;
		}

		this.setState({ loading: true });

		api.post(`/api/enterprises/${this.enterpriseId}/contacts/`, {
			// Setting default nick as telephone for WhatsApp notifications
			nick: formatTelephone,
			name: name,
			email: email,
			telephone: formatTelephone,
			user_fields: userFields
		})
			.then(async (res) => {
				this.setState({
					loading: false,
					createClientSaveButtonLoading: false,
				});

				if (res.status == 201) {
					const client = res.data
					if ('user_fields' in client) {
						Object.keys(client.user_fields).forEach((field) => {
							client[field] = client.user_fields[field];
						});
					}

					const clientsObj = this.state.clientsObj;
					clientsObj[client.id] = client;

					this.setState({
						clients: [client, ...this.state.clients],
						clientsObj: clientsObj,
						showCreateClientDialog: false,
					});

					await useNotification(
						'Sucesso!',
						'Cliente adicionado',
						'success'
					);

					return;
				}
				throw res;
			})
			.catch(async (err) => {
				this.setState({ createClientSaveButtonLoading: false });

				if (err.data.details?.email) {

					// Validates duplicated email adress
					if (err.data.details?.email[0].error === 'DuplicateValue') {
						await useNotification(
							'Ops!',
							`O e-mail "${email}" já está cadastrado na base do zendesk em outro contato`,
							'danger'
						);

						return;
					}

					// Validates any other error
					await useNotification(
						'Ops!',
						err.data.details?.email[0].description,
						'danger'
					);

					return;
				}

				// Fallback for unknown errors
				await useNotification(
					'Ops!',
					'Ocorreu um erro no sistema',
					'danger'
				);
				console.error(err);
			});
	}

	getNickClient = (client) => {
		if (client.name)
			return ` '${client.name}'`;

		if (client.telephone)
			return ` '${client.telephone}'`;

		return '';
	}

	showDeleteClientModal = (client) => {
		const description = 'Tem certeza que deseja remover o cliente' + this.getNickClient(client) + '?';
		this.setState({
			clientToDelete: client,
			deleteClientDescription: description,
			showDeleteClientDialog: true
		});
	}

	deleteClient = (client) => {
		this.setState({
			loading: true,
			deleteConfirmationButtonLoading: true
		});

		api.delete(`/api/enterprises/${this.enterpriseId}/contacts/${client.id}/`)
			.then(async (res) => {
				this.setState({
					loading: false,
					deleteConfirmationButtonLoading: false
				});

				if (res.status === 204 || res.status === 200) {
					const clients = this.state.clients.filter((c) => {
						return c.id != client.id;
					});

					let clientsObj = this.state.clientsObj;
					delete clientsObj[client.id];

					this.setState({
						clients: clients,
						clientsObj: clientsObj,
						showDeleteClientDialog: false
					});

					await useNotification(
						'Sucesso!',
						'Cliente removido',
						'success'
					);

					return;
				}
				if (res.status === 422) {
					// Handle zendesk response 422 errors
					if ('details' in res.data && 'base' in res.data.details) {
						await useNotification(
							'Aviso!',
							res.data.details.base[0].description,
							'warning'
						);
					}
					return;
				}
				throw res;
			})
			.catch(async (err) => {
				this.setState({ deleteConfirmationButtonLoading: false });

				await useNotification(
					'Ops!',
					'Ocorreu um erro no sistema',
					'danger'
				);
				console.error(err);
			});
	}

	capitalize = (s) => {
		return s.charAt(0).toUpperCase() + s.slice(1);
	}

	getIntegrationSource = () => {
		return getSession().profile.enterprise.settings.integration_source
	}

	getClients = async () => {
		const user = getSession();
		const enterpriseSettings = user.profile.enterprise.settings;

		let url = `/api/enterprises/${this.enterpriseId}/contacts/`;

		if (enterpriseSettings.integration_source === 'ZENDESK')
			url += `zendesk/users/${this.pageSize}/`;

		await api.get(url, { timeout: 0 })
			.then(async (res) => {
				if (res.status === 200 && this._isMounted) {
					let clients = res.data;

					if (enterpriseSettings.integration_source === 'ZENDESK') {
						this.state.nextPage = (res.data['meta']['has_more'])
							? res.data['links']['next']
							: null
							;
						clients = this.getZendeskClients(res.data);
					}

					this.setClientsData(clients);

					return;
				}
				if ((res.status === 404 || res.status === 400) && user.profile.enterprise.name == 'Ativa Investimentos') {
					await useNotification(
						'Ops!',
						'O sistema não conseguiu identificar sua organização',
						'danger'
					);
					return;
				}
				throw res;
			})
			.catch(async (err) => {
				await useNotification(
					'Ops!',
					'Ocorreu um erro no sistema',
					'danger'
				);
				console.error(err);
			});
	}

	getOriginNumbers = async () => {
		await api.get(`/api/IS/account/${this.accountcode}/senders/`, { timeout: 0 })
			.then((res) => {
				if (res.status == 200) {
					this.state.originNumbers = res.data;
					return;
				}
				throw res;
			})
			.catch((err) => {
				console.error(err);
			});
	}


	onChangeImportClients = (event) => {
		const eventFile = event.target.files[0];

		const media = {
			id: 0,
			name: eventFile.name,
			preview: URL.createObjectURL(eventFile),
			file: eventFile,
			type: 'csv'
		};

		const file = new File([media.file], media.name);

		const formData = new FormData();
		formData.append('csv_file', file);

		this.importCSV(formData);

		// Resetting file input
		event.target.value = null;
	}

	importCSV = (formData) => {
		this.setState({ importLoading: true });

		api.post(`/api/enterprises/${this.enterpriseId}/contacts/upload_contacts/`, formData)
			.then(async (res) => {
				await this.setState({ importLoading: false });

				if (res.status === 200 && this._isMounted) {
					await useNotification(
						'Sucesso!',
						'Clientes importados com sucesso.',
						'success'
					);
					this.getClients();
					return;
				}
				if (res.status === 403) {
					await useNotification(
						'Ops!',
						'A importação em massa não é permitida para contas integradas com CRM.',
						'danger'
					);
					return;
				}
				if (res.status === 400 && 'csv_file' in res.data) {
					if (res.data.csv_file.indexOf('Invalid file extension') !== -1)
						await useNotification(
							'Ops!',
							'Somente arquivos .csv ou .CSV são permitidos.',
							'danger'
						);
					if (res.data.csv_file.indexOf('expected headers') !== -1)
						await useNotification(
							'Ops!',
							'O arquivo deve conter as colunas Nome | Email | Telefone.',
							'danger'
						);
					return;
				}
				throw res;
			}).catch(async (err) => {
				console.error(err);
				await useNotification(
					'Ops!',
					'Ocorreu um erro no sistema',
					'danger'
				);
			});
	}

	changePage = (pageUrl) => {
		this.setState({ loading: true });

		// TODO: Adapt for other CRM integrations
		// currently only querying Zendesk
		api.post(`/api/enterprises/${this.enterpriseId}/contacts/zendesk/page/`, {
			page_url: pageUrl
		}).then((res) => {
			if (res.status === 200 && this._isMounted) {
				const oldClients = this.state.clients;
				const newClients = this.getZendeskClients(res.data);
				const clients = oldClients.concat(newClients);
				const clientsObj = this.mapClients(clients);

				const hasMore = res.data['meta']['has_more'];

				this.setState({
					loading: false,
					clients: clients,
					clientsObj: clientsObj,
					prevPage: res.data['links']['prev'],
					nextPage: (hasMore) ? res.data['links']['next'] : null
				});

				return;
			}
			throw res;
		}).catch(async (err) => {
			console.error(err);

			this.setState({ loading: false });

			await useNotification(
				'Ops!',
				'Ocorreu um erro no sistema',
				'danger'
			);
		});
	}

	onFilterChange = async (filterModel) => {
		let query = "";

		filterModel['items'].forEach((filter) => {
			let field = filter.columnField;
			let value = filter.value;

			// Rename Zendesk phone field
			field = (field === 'telephone') ? 'phone' : field;

			if (value !== undefined)
				query += `${field}:\"${value}\" `;
		});

		query = query.slice(0, -1);

		this.searchClients(query, filterModel);
	}

	searchClients = async (query, filterModel) => {
		if (!query) {
			await this.setState({ clientsFilter: filterModel });
			await this.getClients();

			return;
		}

		this.setState({ loading: true });

		// TODO: Adapt for other CRM integrations
		// currently only querying Zendesk
		api.post(`/api/enterprises/${this.enterpriseId}/contacts/zendesk/search/`, {
			filter_type: 'user',
			page_size: this.pageSize,
			query: query
		}).then((res) => {
			if (res.status === 200 && this._isMounted) {
				const clients = this.getZendeskClients(res.data);
				const clientsObj = this.mapClients(clients);

				const hasMore = res.data['meta']['has_more'];

				this.setState({
					loading: false,
					clients: clients,
					clientsObj: clientsObj,
					prevPage: res.data['links']['prev'],
					nextPage: (hasMore) ? res.data['links']['next'] : null,
					clientsFilter: filterModel
				});

				return;
			}
			throw res;
		}).catch(async (err) => {
			console.error(err);

			this.setState({ loading: false });

			await useNotification(
				'Ops!',
				'Ocorreu um erro no sistema',
				'danger'
			);
		});
	}

	getZendeskClients = (results) => {

		const clients = ('users' in results)
			? results['users']
			: results['results']
			;

		clients.forEach(client => {
			if ('user_fields' in client) {
				Object.keys(client.user_fields).forEach((field) => {
					client[field] = client.user_fields[field];
				});
			}

			// Rename phone -> telephone field
			client['telephone'] = client['phone'];
		});

		return clients;
	}

	mapClients = (clients) => {
		const clientsObj = {};

		clients.forEach((client) => {
			clientsObj[client.id] = client;
		});

		return clientsObj;
	}

	customRow = (props) => {
		const list_index = props.index;
		const list_size = this.state.clients.length;
		const value_opacity = list_size - list_index;

		return (
			<GridRow
				{...props}
				style={{
					opacity:
						(value_opacity < 10 && this.state.nextPage) ?
							`0.${value_opacity}` : null
				}}
			/>
		);
	}

	observerScroll = () => {
		const footer_table = document.getElementById('end-clients-table');

		const observer = new IntersectionObserver((entries) => {
			if (entries.some((entry) => entry.isIntersecting) && this.state.nextPage) {
				this.changePage(this.state.nextPage);
			}
		});

		observer.observe(footer_table);

		return () => observer.disconnect();
	}

	async componentWillMount() {
		this._isMounted = true;

		this.setState({ loading: true });

		const user = getSession();
		if (user && user.profile) {
			const enterprise = user.profile.enterprise;
			if (enterprise) {
				this.enterpriseId = enterprise.id;
				this.accountcode = enterprise.accountcode;
			}
			const queues = user.profile.queues;
			if (queues) {
				let user_queues = {};
				queues.map(queue => {
					user_queues[queue.description] = queue.name;
				});
				this.queues = user_queues;
			}
		}

		this.groups = userPermissions();
		this.pageSize = 100;

		await this.getTemplates();
		await this.getOriginNumbers();
		await this.getClients();
		await this.fetchNotifications();

		const notificationColumns = this.getNotificationColumns();

		this.setState({
			notificationColumns: notificationColumns,
			loading: false
		});
	}


	componentDidMount() {
		const user = getSession();

		if (user && user.profile) {
			const enterprise = user.profile.enterprise;

			if (enterprise) {
				if (enterprise.settings && enterprise.settings.integration_source === 'ZENDESK') {
					// Monitor when the scroll reaches the end of the table, load more customers in the table
					this.observerScroll();
				}
			}
		}

		this.setState({
			isMobile: isMobile()
		});
	}

	componentWillUnmount() {
		this._isMounted = false;
		
	}

	buildClients = (enterpriseSettings) => {

		if (enterpriseSettings.integration_source === 'ZENDESK') {
			return (
				<Box className="api-integrated-table">
					<Table
						columns={this.state.isMobile ? this.state.mobileColumns : this.state.columns}
						rows={this.state.clients}
						checkboxSelection={true}
						pagination={false}
						pageSize={null}
						filterMode="server"
						onFilterModelChange={this.onFilterChange}
						filterModel={this.state.clientsFilter}
						onSelectionModelChange={(newSelectionModel) => {
							this.setState({ selectionModel: newSelectionModel });
						}}
						customRow={this.customRow}
						integrationSource="ZENDESK"
					/>
					<Box id='end-clients-table'></Box>
				</Box>
			);
		} else {
			return (
				<Table
					columns={this.state.isMobile ? this.state.mobileColumns : this.state.columns}
					rows={this.state.clients}
					checkboxSelection={true}
					pagination={false}
					pageSize={null}
					onSelectionModelChange={(newSelectionModel) => {
						this.setState({ selectionModel: newSelectionModel });
					}}
					filterMode="client"
					onFilterModelChange={(filterModel) => this.setState({ clientsFilter: filterModel })}
					filterModel={this.state.clientsFilter}
					// customToolBarButtons={[
					// 	<>
					// 		{!this.getIntegrationSource() &&
					// 			<ResponsiveClientGroupButton
					// 				className="button-delete-selected"
					// 				parent={this}
					// 				childObject= {
					// 					<ResponsiveCreateClientGroupButton
					// 					isDisabled={false}
					// 					parent={this}
					// 					selectionIds={this.state.selectionModel}
					// 				/>	
					// 				}
					// 			/>
					// 		}
					// 	</>
					// ]}
					customToolBarButtonsRight={[
						<>
							<LoadingButton
								className="button-add-group-selected"
								component="label"
								startIcon={<DeleteIcon />}
								loading={this.state.loading}
								onClick={() => this.setState({
									showDeleteSelectedClientsDialog: true
								})}
								disabled={!this.state.selectionModel.length || this.state.notificationsSection}
							>
								Excluir
							</LoadingButton>
						</>
					]}
				/>
			);
		}
	}

	fetchNotifications = async () => {
		this.setState({ loading: true });

		const url = `/api/enterprises/${this.enterpriseId}/notifications/pre_filtered/`;

		const startDateTime = moment(this.state.startDateTime).format('YYYY-MM-DD HH:mm').toString();
		const endDateTime = moment(this.state.endDateTime).format('YYYY-MM-DD HH:mm').toString();

		await api.post(url, {
			start_datetime: startDateTime,
			end_datetime: endDateTime
		}).then((res) => {
			if (res.status == 200) {
				this.setState({
					notifications: res.data,
					loading: false
				});
				return;
			}
			throw res;
		}).catch((err) => {
			console.error(err);
			this.setState({ loading: false });
		});
	}

	buildNotifications = () => {
		return (
			<>
				<Table
					columns={this.state.notificationColumns}
					rows={this.state.notifications}
					pagination={true}
					pageSize={15}
					filterMode="client"
					onFilterModelChange={(filterModel) => {this.setState({ notificationsFilter: filterModel })}}
					filterModel={this.state.notificationsFilter}
				/>
			</>
		);
	}

	deleteClients = (ids) => {
		this.setState({
			loading: true,
			showDeleteSelectedClientsDialog: false
		});

		api.post(`/api/enterprises/${this.enterpriseId}/contacts/bulk_delete/`, {
			clients_ids: ids
		}).then(async (res) => {
			if (res.status == 204) {
				await useNotification(
					'Sucesso!',
					'Clientes removidos com sucesso',
					'success'
				);

				const clients = this.state.clients.filter((client) => {
					return ids.indexOf(client.id) < 0;
				});

				const clientsObj = this.state.clientsObj;
				ids.forEach((id) => delete clientsObj[id]);

				this.setState({
					clients: clients,
					clientsObj: clientsObj,
					loading: false,
					selectionModel: []
				});

				return;
			}
			throw res;
		}).catch(async (err) => {
			console.error(err);
			if (err.status == 403) {
				if (err.data.detail === 'You do not have permission to perform this action.') {
					await useNotification(
						'Permissão negada!',
						'Você não tem permissão para executar esta ação.',
						'danger'
					);
				}
				else {
					await useNotification(
						'Ops!',
						'A exclusão em massa não é permitida para contas integradas com CRM.',
						'danger'
					);
				}
			}
			else {
				await useNotification(
					'Ops!',
					'Ocorreu um erro no sistema.',
					'danger'
				);
			}
			this.setState({ loading: false });
		})
	}

	createClientGroup = async (name, description, clients) => {
		this.setState({ createClientGroupSaveButtonLoading: true });

		api.post(`/api/enterprises/${this.enterpriseId}/contact_group/`, {
			name: name,
			description: description,
		})
			.then(async (res) => {
				this.setState({
					loading: false,
					createClientGroupSaveButtonLoading: false,
				});

				if (res.status == 201) {
					// a requisição do contact-group-contacts deve entrar aqui
					// chamar método de criação de ContactGroupContacts
					
					const group = res.data;
					const groupsObj = this.state.groupsObj;
					
					groupsObj[group.id] = group;

					this.setState({
						groups: [group, ...this.state.groups],
						groupsObj: groupsObj,
						showCreateGroupDialog: false,
					});

					await useNotification(
						'Sucesso!',
						'Segmento adicionado',
						'success'
					);

					return;
				}
				throw res;
			})
			.catch(async (err) => {
				this.setState({ createClientSaveButtonLoading: false });

				await useNotification(
					'Ops!',
					'Você não tem permissão para realizar essa ação',
					'danger'
				);
				console.error(err);
			});
	}

	deactivateCampaign = () => {
		this.setState({
			campaignSection: false
		})
	}

	reloadNotifications = () => {
		this.fetchNotifications()
		this.setState({notificationRedirect:false})
		return (<></>)
	}
	
	render() {
		const user = getSession();
		const enterpriseSettings = user.profile.enterprise.settings;

		return (
			<>
			
				{ !this.state.campaignSection &&
				<>
				<Loading loading={this.state.loading} />
				<ClientDialog open={this.state.showCreateClientDialog}
					title="Cliente"
					type="create"
					model={this.state.clients[0]}
					key={this.state.renderKeyCreateDialog}
					buttonText="Adicionar"
					handleClose={() => this.setState({
						showCreateClientDialog: false
					})}
					submit={this.createClient}
				/>

				<ClientDialog open={this.state.showChangeClientDialog}
					title="Cliente"
					type="change"
					model={this.state.clientToChange}
					key={this.state.renderKeyChangeDialog}
					buttonText="Salvar"
					handleClose={() => this.setState({
						clientToChange: null,
						showChangeClientDialog: false
					})}
					submit={this.changeClient}
				/>
				<ConfirmationDialog open={this.state.showDeleteClientDialog}
					title="Confirmação"
					description={this.state.deleteClientDescription}
					handleClose={() => this.setState({
						clientToDelete: null,
						deleteClientDescription: '',
						showDeleteClientDialog: false
					})}
					handleSubmit={() => this.deleteClient(this.state.clientToDelete)}
				/>
				<ConfirmationDialog open={this.state.showDeleteSelectedClientsDialog}
					title="Confirmação"
					description="Tem certeza que deseja excluir os clientes selecionados?"
					handleClose={() => this.setState({
						showDeleteSelectedClientsDialog: false
					})}
					handleSubmit={() => this.deleteClients(this.state.selectionModel)}
				/>
				<Templates
					open={this.state.showTemplates}
					templates={this.state.templates}
					queues={this.queues}
					clients={this.state.clientsObj}
					selectionIds={this.state.selectionModel}
					originNumbers={this.state.originNumbers}
					handleClose={() => this.setState({ showTemplates: false })}
					handlePostSuccess={async () => {
						await this.fetchNotifications();
						this.setState({
							selectionModel: [],
							notificationsSection: true
						});
					}}
				/>
				<Dialog open={this.state.showTemplate}
					onClose={() => this.setState({
						showTemplate: false,
						template: null
					})}
					disablePortal
                    disableEnforceFocus
                    sx={{position:'absolute'}}
				>
					<DialogTitle>Notificação</DialogTitle>
					<DialogContent>
						<DialogContentText id="template-dialog-description">
							{this.state.template}
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={() => this.setState({
							showTemplate: false,
							template: null
						})}>
							Fechar
						</Button>
					</DialogActions>
				</Dialog>
				<Box className="clients-body">
					<Box className="clients-buttons">
						<Box className="box-msg">
							{this.state.notificationsSection &&
								<TextField
									className="start-textfield"
									size="small"
									label="Início"
									type="datetime-local"
									variant="outlined"
									defaultValue={this.state.isMobile ? this.state.startDateTimeMobile : this.state.startDateTime}
									onChange={(event) => this.setState({ startDateTime: event.target.value })}
									InputLabelProps={{
										shrink: true,
									}}
									disabled={!this.state.notificationsSection}
								/>
							}
							{this.state.notificationsSection &&
								<TextField
									className="end-textfield"
									size="small"
									label="Fim"
									type="datetime-local"
									variant="outlined"
									defaultValue={this.state.isMobile ? this.state.endDateTimeMobile : this.state.endDateTime}
									onChange={(event) => this.setState({ endDateTime: event.target.value })}
									InputLabelProps={{
										shrink: true,
									}}
									disabled={!this.state.notificationsSection}
									style={{ marginLeft: "10px" }}
								/>
							}
							{this.state.notificationsSection &&
							<ResponsiveGenerateButton 
								className="button-generate"
								onClickFn={() => this.fetchNotifications()}
								loading={this.state.loading}
							/>
							}
							{(!this.state.notificationsSection) &&
								<ResponsiveSendMessageButton
									className="button-notify"
									onClickFn={() => this.setState({
										showTemplates: true,
										importInformation: false
									})}
									isDisabled={!this.state.selectionModel.length || this.state.notificationsSection}
								/>
							}
							{!this.state.notificationsSection &&
								<ResponsiveAddButton
									className="button-add"
									onClickFn={() => this.setState({
										showCreateClientDialog: true,
										renderKeyCreateDialog: Math.random(),
										importInformation: false
									})}
									isDisabled={this.state.notificationsSection}
								/>
							}
							{!this.state.notificationsSection &&
								<ResponsiveImportButton
									className="button-import"
									loading={this.state.importLoading}
									isDisabled={this.state.notificationsSection}
									onClickFn={() => {
										if (this.groups && !this.groups.isSupervisor) {
											useNotification(
												'Ops!',
												'Você não tem permissão para executar essa ação, fale com um supervisor ou administrador da conta.',
												'danger'
											);
										}
									}}
									shouldRenderHiddenInput={this.groups && this.groups.isSupervisor}
									onChangeFn={this.onChangeImportClients}
								/>
							}
							{!this.state.notificationsSection &&
								<Tooltip title="Exemplo e instruções de importação" placement="bottom" disableInteractive>
									<IconButton
										className="button-info"
										onClick={() => this.setState({ importInformation: true })}
									>
										<QuestionIcon className="question-icon" />
									</IconButton>
								</Tooltip>
							}
							{this.state.importInformation &&
								<Box className="import-information">
									<Box className="info-header">
										<Box className="info-title">Upload de Contatos por arquivo CSV</Box>
										<Box className="info-close"
											onClick={() => this.setState({ importInformation: false })}
										>
											<CloseIcon className="close-icon" />
										</Box>
									</Box>
									<Box className="info-description">O arquivo com os contatos deve seguir as seguintes restrições:</Box>
									<Box className="info-topic">1. O documento deve ter extensão .csv ou .CSV</Box>
									<Box className="info-topic">2. Os cabeçalhos devem ter Nome, Email e Telefone (não necessariamente nessa ordem). Caso falte algum, o arquivo não será aceito.</Box>
									<Box className="info-topic">3. O contato que estiver sem telefone não será cadastrado.</Box>
									<Box className="info-topic">4. A codificação do arquivo deve ser UTF-8, podendo haver problemas na leitura caso contrário.</Box>
									<Box className="info-description">Em anexo há um arquivo de exemplo.</Box>
									<Box className="example-file">
										<Box className="example-csv">CSV</Box>
										<a className="example-text" href="/contact_list_example.csv">contact_list_example.csv</a>
									</Box>
								</Box>
							}
							<ResponsiveClientsButton 
								className="button-table"
								onClickFn={() => this.setState({ notificationsSection: false })}
								id={!this.state.notificationsSection ? "button-active" : ""}
								variant={!this.state.notificationsSection ? "contained" : "outlined"}
							/>
							<ResponsiveNotificationButton 
								className="button-notification"
								onClickFn={async () => {
									this.setState({
										selectionModel: [],
										notificationsSection: true,
										importInformation: false
									});
								}}
								id={this.state.notificationsSection ? "button-active" : ""}
								variant={this.state.notificationsSection ? "contained" : "outlined"}
							/>
							{/*{!this.getIntegrationSource() &&
								<ResponsiveCampaignButton 
									className="button-campaign"
									onClickFn={async () => {
										this.setState({
											campaignSection: true,
										});
									}}
									id={this.state.campaignSection ? "button-active" : ""}
									variant={this.state.campaignSection ? "contained" : "outlined"}
								/>
							}*/}
							{this.state.notificationsSection &&
								<IconButton
									className="button-refresh"
									onClick={() => this.fetchNotifications()}
									disabled={this.state.loading}
									size={isMobile ? "small" : "medium"}
								>
									<RefreshIcon />
								</IconButton>
							}
						</Box>
					</Box>
						{!this.state.notificationsSection && this.buildClients(enterpriseSettings)}
						{this.state.notificationsSection && this.buildNotifications()}
						{this.state.notificationRedirect && this.reloadNotifications()}

				</Box>
				</>
				}
				{
				this.state.campaignSection && 
				<CampaignSection parent={this} enterpriseSettings={enterpriseSettings} />				
				}
			</>
		
		);
	}
}

export default Clients;