import React from 'react';

import Frame from '../Frame';

const FrameExchanged = ({ gridArea, exchangedMessages }) => {
  return (
    <Frame gridArea={gridArea} title="Mensagens Trocadas">
      {exchangedMessages}
    </Frame>
  );
}

export default FrameExchanged;