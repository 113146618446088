import React, { useReducer, useState, useEffect } from 'react'

import {
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Divider,
    Box,
    FormGroup,
    FormControlLabel,
    Switch
} from '@mui/material'

import LoadingButton from '@mui/lab/LoadingButton'

import CloseIcon from '@mui/icons-material/Close'

import InputAdornment from '@mui/material/InputAdornment';

import TooltipIndicators from '../../../Dash/Tooltip'

import { lockedReducer } from '../../../../utils/defaultReducer'

export default function AddBotDialog({ data, handleClose, submit }) {

    const [state, setState] = useReducer(lockedReducer, {
        id            : 0,
        imap_username : '',
        imap_password : '',
        imap_server   : '',
        imap_port     : 993,
        imap_ssl      : 1,
        smtp_username : '',
        smtp_password : '',
        smtp_server   : '',
        smtp_port     : 465,
        smtp_ssl      : 1,
        exceptions    : ''
    })
    const [useOtherCredentials, setUseOtherCredentials] = useState(false)

    useEffect(()=>{
        if (data.id){
            setState({
                ...data,
                imap_password: '**********',
                smtp_password: '**********'
            })
        }
    }, [data])

    return (
        <div>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={true}
                disablePortal
                disableEnforceFocus
                sx={{ position: 'absolute' }}
            >
                <DialogTitle className="group-flexbox">
                    {data.id ? 'Editar': 'Adicionar'} Conta de e-mail
                    <Button sx={{ 'minWidth': '2rem', 'padding': '0' }} onClick={handleClose}>
                        <CloseIcon />
                    </Button>
                </DialogTitle>
                <Divider />
                <DialogContent className="add-bot-dialog-content">
                    <TextField
                        size="small"
                        label="Email IMAP"
                        fullWidth
                        type="text"
                        variant="outlined"
                        value={state.imap_username}
                        onChange={(e) => setState({ imap_username: e.target.value })}
                        disabled={data.id}
                    />
                    <TextField
                        size="small"
                        label="Senha IMAP"
                        fullWidth
                        type="password"
                        variant="outlined"
                        value={state.imap_password}
                        onChange={(e) => setState({ imap_password: e.target.value })}
                        disabled={data.id}
                    />
                    <TextField
                        size="small"
                        label="Servidor IMAP"
                        fullWidth
                        type="text"
                        variant="outlined"
                        value={state.imap_server}
                        onChange={(e) => setState({ imap_server: e.target.value })}
                        disabled={data.id}
                    />
                    <TextField
                        size="small"
                        label="Porta IMAP"
                        fullWidth
                        type="text"
                        variant="outlined"
                        value={state.imap_port}
                        onChange={(e) => setState({ imap_port: e.target.value })}
                        disabled={data.id}
                    />

                    <Box className='BuilderBotSelectorBox'>
                        <Button
                            variant={state.imap_ssl ? 'contained' : ''}
                            onClick={() => setState({ imap_ssl: 1 })}
                            disabled={data.id}
                        >
                            SSL
                        </Button>
                        <Button
                            variant={!state.imap_ssl ? 'contained' : ''}
                            onClick={() => setState({ imap_ssl: 0 })}
                            disabled={data.id}
                        >
                            SEM SSL
                        </Button>
                    </Box>

                    <FormGroup>
                        <FormControlLabel disabled={data.id} control={
                            <Switch
                                color="primary"
                                checked={useOtherCredentials}
                                onChange={() => setUseOtherCredentials(p => !p)}
                            />
                        }
                            label="Usa credenciais diferentes de imap e smtp?" />
                    </FormGroup>

                    {useOtherCredentials &&
                        <>
                            <TextField
                                size="small"
                                label="Email SMTP"
                                fullWidth
                                type="text"
                                variant="outlined"
                                value={state.smtp_username}
                                onChange={(e) => setState({ smtp_username: e.target.value })}
                                disabled={data.id}
                            />
                            <TextField
                                size="small"
                                label="Senha SMTP"
                                fullWidth
                                type="password"
                                variant="outlined"
                                value={state.smtp_password}
                                onChange={(e) => setState({ smtp_password: e.target.value })}
                                disabled={data.id}
                            />
                        </>
                    }

                    <TextField
                        size="small"
                        label="Servidor SMTP"
                        fullWidth
                        type="text"
                        variant="outlined"
                        value={state.smtp_server}
                        onChange={(e) => setState({ smtp_server: e.target.value })}
                        disabled={data.id}
                    />
                    <TextField
                        size="small"
                        label="Porta SMTP"
                        fullWidth
                        type="text"
                        variant="outlined"
                        value={state.smtp_port}
                        onChange={(e) => setState({ smtp_port: e.target.value })}
                        disabled={data.id}
                    />

                    <Box className='BuilderBotSelectorBox'>
                        <Button
                            variant={state.smtp_ssl ? 'contained' : ''}
                            onClick={() => setState({ smtp_ssl: 1 })}
                            disabled={data.id}
                        >
                            SSL
                        </Button>
                        <Button
                            variant={!state.smtp_ssl ? 'contained' : ''}
                            onClick={() => setState({ smtp_ssl: 0 })}
                            disabled={data.id}
                        >
                            SEM SSL
                        </Button>
                    </Box>
                </DialogContent>
                <DialogActions >
                    {Object.keys(data).length === 0 &&
                        <Box className="user-footer">
                            <LoadingButton className="button-created" onClick={handleClose}>
                                Cancelar
                            </LoadingButton>
                            <LoadingButton className="button-created"
                                onClick={() => {
                                    let sendData = { ...state }
                                    if (!useOtherCredentials) {
                                        sendData.smtp_username = state.imap_username
                                        sendData.smtp_password = state.imap_password
                                    }
                                    submit(sendData)
                                }}
                                disabled={false}
                            >
                                Salvar
                            </LoadingButton>
                        </Box>
                    }
                </DialogActions>
            </Dialog>
        </div>
    );
}
