import React, { useState, useEffect, useContext } from 'react'
import { constructorContext } from '..'
import {
    DataGridPro,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    gridClasses,
    LicenseInfo,
    ptBR
} from '@mui/x-data-grid-pro';

import {
    createTheme,
    ThemeProvider,
    StyledEngineProvider
} from '@mui/material/styles';

import { ptBR as corePtBR } from '@mui/material/locale';

import { Box, Tooltip, Button } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import useNotification from '../../../Notification';
import DeleteIcon from '@mui/icons-material/Delete';

import api from '../../../../api';
import ConfirmationDialogCaptcha from '../../../Dialogs/ConfimationDialogCaptcha';

import LoadingButton from '@mui/lab/LoadingButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SearchEditModal from './DescriptorsEditModal';
import DescriptorsEditModal from './DescriptorsEditModal';

import { stringOperators } from '../../../../utils/filterOperators';

const theme = createTheme({
    palette: {
        primary: { main: '#21446C' },
    },
}, ptBR, corePtBR);

const BuilderDescriptors = () => {
    const { activeTab, enterpriseId, loading, has_voice, setLoading } = useContext(constructorContext)

    const [rows, setRows] = useState([])
    const [deleteModal, setDeleteModal] = useState()
    const [editModal, setEditModal] = useState()

    const getURL = () => {
        let url = `api/enterprises/${enterpriseId}/descriptors/`
        return url
    }

    const getRows = async () => {
        setLoading(true)
        const res = await api.get(getURL(), { timeout: 0 })
        setLoading(false)
        if (res.status == 200) {
            setRows(res.data)
            return
        }
    }

    useEffect(() => {
        getRows()
    }, [])

    const submitDelete = async () => {
        let url = `api/enterprises/${enterpriseId}/descriptors/${deleteModal.id}/`

        setLoading(true)
        const res = await api.delete(url, { timeout: 0 })
        setLoading(false)

        if (res.status == 204) {

            setDeleteModal()
            getRows()

            useNotification(
                'Sucesso!',
                'Descritor deletado com sucesso.',
                'success'
            )
            return
        }
        useNotification(
            'Ops!',
            'Não foi possível excluir o descritor.',
            'danger'
        )
    }


    const CustomToolbar = () => {

        return (
            <GridToolbarContainer className={gridClasses.toolbarContainer}>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <LoadingButton
                    size="small"
                    component="label"
                    startIcon={<AddCircleIcon />}
                    onClick={() => { setEditModal({}) }}
                >
                    Adicionar
                </LoadingButton>
            </GridToolbarContainer>
        );
    }

    const defColumns = [
        { field: 'tag', headerName: 'Nome', flex: 1, filterOperators: stringOperators },
        { field: 'description', headerName: 'Descrição', flex: 1, filterOperators: stringOperators },
        {
            field: 'action', headerName: 'Ações', filterable: false, sortable: false,
            renderCell: (params) => (
                <>
                    <Tooltip title="Editar" >
                        <Button
                            className="action-button"
                            onClick={() => { setEditModal(params.row) }}
                        >
                            <EditIcon />
                        </Button>
                    </Tooltip>
                    <Tooltip title="Excluir Descritor">
                        <Button className="action-button delete"
                            onClick={() => { setDeleteModal(params.row) }}>
                            <DeleteIcon />
                        </Button>
                    </Tooltip>
                </>
            ),
        },
    ]

    return (
        <>

            {editModal ?
                <DescriptorsEditModal
                    data={editModal}
                    setData={setEditModal}
                    getRows={getRows}
                />
                :
                null}
            {deleteModal ?
                <ConfirmationDialogCaptcha open={true}
                    title="Excluir Descritor"
                    description={
                        <span>
                            Para confirmar a ação, digite: <span style={{ 'fontWeight': 'bold' }}>{deleteModal.tag}</span> no campo abaixo.</span>}
                    handleClose={() => { setDeleteModal() }}
                    handleSubmit={() => { submitDelete() }}
                    captcha={deleteModal.tag}
                />

                :
                null
            }

            <Box className="settings-body-bot">
                <StyledEngineProvider injectFirst >
                    <ThemeProvider theme={theme}>
                        <DataGridPro
                            pageSize={100}
                            pagination
                            disableSelectionOnClick
                            disableDensitySelector
                            density="compact"
                            columns={defColumns}
                            rows={rows}
                            components={{
                                Toolbar: CustomToolbar,
                            }}
                        />
                    </ThemeProvider>
                </StyledEngineProvider>
            </Box>

        </>
    )
}

export default BuilderDescriptors