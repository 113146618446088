import React from 'react';

import './styles.css';

import { Dialog, DialogTitle, DialogContent, Button, Divider } from '@mui/material';
import { DialogActions } from '@mui/material';
import { TextField } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import api from '../../../../../api';
import useNotification from '../../../../Notification';

import CloseIcon from '@mui/icons-material/Close';

class AddClientGroupForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            name: "",
            description: "",
            loading: false
        }
    }

    submit = async () => {

        const promise1 = this.createClientGroup();
        const promise2 = this.props.submit();

        await Promise.all([promise1, promise2])

    }


    createClientGroup = () => {
        const selectionIds = this.props.selectionIds;

        const body = {
            'name': this.state.name,
            'description': this.state.description,
            'clients': selectionIds
        };

        this.postClientGroup(body);
    }

    postClientGroup = async (body) => {
        this.props.updateReady(false)
        const res = await api.post(`api/enterprises/${this.props.enterpriseId}/contact_group/`, body)

        if (res.status == 403) {
            useNotification(
                'Ops!',
                'Você não tem permissão para realizar essa ação.',
                'danger'
            );
        }

        if (res.status != 201) {
            let message = 'Ocorreu um erro com a requisição.'
            if ('non_field_errors' in res.data && res.data.non_field_errors)
                message = 'Já existe um segmento com esse nome nessa empresa.'
            useNotification(
                'Ops!',
                message,
                'danger'
            );
            this.props.updateReady(true)
            return
        }

        res.data.contacts = this.props.selectionIds.map(id => ({ contact: id }))

        this.props.updateGroups(prevState => this.props.subsObj(prevState, res.data))
        this.props.updateClients(prevState => {
            prevState.filter(each => body.clients.includes(each.id))
                .map(each => (
                    each.groups = this.props.subsObj(each.groups, res.data)
                ));
            return prevState
        })
        this.setState({
            name: '',
            description: ''
        })
        this.props.updateReady(true)
        this.props.updateForceReload(prevState => !prevState)
        this.setState({ loading: false });
        if (res.status === 201) {
            await this.props.handleClose();
            useNotification(
                'Sucesso!',
                'Segmento de clientes criado.',
                'success'
            );
            return;
        }
        return res;

    }

    changeClientGroup = () => {

        let name = '';
        let description = '';

        if (this.props.model) {
            name = this.props.model.name;
            description = this.props.model.description;
        };

        this.setState({
            name: name,
            description: description
        });
    }

    render() {
        return (
            <>
                <Dialog keepMounted
                    open={this.props.open}
                    // onClose={this.props.handleClose}
                    aria-labelledby="customized-dialog-title"
                    disablePortal
                    disableEnforceFocus
                    sx={{position:'absolute'}}
                >
                    <DialogTitle id="customized-dialog-title" className={'group-flexbox'}>
                        <span>{this.props.optionalTitle ? this.props.optionalTitle : 'Criar novo segmento de clientes'}</span>
                        <Button sx={{ 'minWidth': '2rem', 'padding': '0' }} onClick={this.props.handleClose} >
                            <CloseIcon />
                        </Button>
                    </DialogTitle>
                    <Divider />
                    <DialogContent 
                        ref={instance => { this.content = instance; }}
                    >
                        <TextField
                            size="small"
                            label="Nome"
                            fullWidth
                            type="text"
                            variant="outlined"
                            value={this.state.name}
                            onChange={(event) => this.setState({
                                name: event.target.value
                            })}
                        />
                        <TextField
                            size="small"
                            label="Descrição"
                            fullWidth
                            type="text"
                            variant="outlined"
                            className="push-top"
                            value={this.state.description}
                            onChange={(event) => this.setState({
                                description: event.target.value
                            })}
                        />
                    </DialogContent>
                    <DialogActions style={{ padding: "10px 20px 10px 10px" }}>
                        <LoadingButton
                            onClick={this.props.handleClose}
                            color="primary"
                            loading={this.state.loading}
                        >
                            {this.props.cancelMsg ? this.props.cancelMsg : 'Cancelar'}
                        </LoadingButton>
                        <LoadingButton
                            onClick={() => this.submit()}
                            color="primary"
                            loading={this.state.loading}
                        >
                            Salvar
                        </LoadingButton>

                    </DialogActions>
                </Dialog>
            </>
        );
    }
}

export default AddClientGroupForm;
