import React from "react";

import MiniDrawer from "../../components/Drawer"
import Builder from "../../components/Settings/Builder";
import { getSession } from "../../auth";

const BuilderPage = (socket) => {

    const has_voice = getSession()?.profile?.enterprise?.has_voice

    return (
        <MiniDrawer child={<Builder has_voice={has_voice}/>} socket={socket.socket}  />
    );
};

export default BuilderPage;
