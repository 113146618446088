import React from 'react'
import SettingsIntermediate from '../SettingsIntermediate'
import userPermissions from '../../User/Permissions';
import { getSession } from '../../../auth';
import UsersQueue from '../../Settings/Users/UsersQueue';
import ShippingReceiving from '../../Settings/ShippingReceiving';
import RestrictedWordsComponent from '../../Settings/RestrictedWords'

const QueuePage = () => {
    const groups = userPermissions();
    const enterprise = getSession()?.profile?.enterprise
    return (
        <>
            <SettingsIntermediate title='Filas' components={() => ({
                comp1:{
                    permissions: true,
                    style:'title',
                    label:'Filas'
                },
                comp2:{
                    permissions: groups.isSupervisor,
                    style:'list',
                    label:'Fila e Operador',
                    sublabel: 'Todas as configurações de filas',
                    redirect: (<UsersQueue enterpriseId={enterprise?.id}/>)
                },
                comp3:{
                    permissions: groups.isAdmin,
                    style:'list',
                    label:'Envio e Recebimento',
                    sublabel: 'Configure as opções de envio e recebimento de mensagens durante uma conversa por fila.',
                    redirect: (<ShippingReceiving enterpriseId={enterprise?.id}/>)
                },
                comp4:{
                    permissions: groups.isAdmin,
                    style:'list',
                    label:'Palavras Restritas',
                    sublabel: 'Configure as palavras restritas do sistema por fila.',
                    redirect: (<RestrictedWordsComponent enterpriseId={enterprise?.id}/>)
                }
            })
            } />
        </>
    )
}


export default QueuePage