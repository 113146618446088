import React from "react";

import MiniDrawer from "../../components/Drawer"
import Automation from "../../components/Automation";
import { getSession } from "../../auth";

const AutomationPage = (socket) => {

    const has_voice = getSession()?.profile?.enterprise?.has_voice

    return (
        <MiniDrawer child={<Automation has_voice={has_voice}/>} socket={socket.socket}  />
    );
};

export default AutomationPage;
