import React from 'react'

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import InboxIcon from '@mui/icons-material/Inbox';
import DraftsIcon from '@mui/icons-material/Drafts';

const CampaignSummary = (props) =>{

    const selectedQueue = props.queues.filter(item=> item[1] == props.queue)[0]

    const groupNameList = props.groups.filter(item => props.groupkey.includes(item.id)).map(item => item.name).join(', ')

    let period = props.time
    if (period == 'once'){
        period = 'Uma vez na data'
    }
    else {
        period = 'Periodo'
    }

    return(
        <>
        <h4>Resumo da campanha.</h4>
  <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
      
      <nav aria-label="secondary mailbox folders">
        <List sx={{ width: '100%', }} >
          <ListItem disablePadding>
            <ListItemButton>
            <ListItemText primary="Nome" secondary={props.name}/>
            </ListItemButton>
          </ListItem>

          <Divider />

          <ListItem disablePadding>
            <ListItemButton>
                <ListItemText primary="Segmento(s)" secondary={groupNameList}/>
            </ListItemButton>
          </ListItem>

          <Divider />
            
          <ListItem disablePadding>
            <ListItemButton>
                <ListItemText primary="Fila" secondary={selectedQueue[0]}/>
            </ListItemButton>
          </ListItem>


          <Divider />

            <ListItem disablePadding>
            <ListItemButton>
                <ListItemText primary="Número de origem" secondary={props.sender}/>
            </ListItemButton>
            </ListItem>

            <Divider />

            <ListItem disablePadding>
            <ListItemButton>
                <ListItemText primary="Template" secondary={props.template.components[0].text}/>
            </ListItemButton>
            </ListItem>

            <Divider />


            <ListItem disablePadding>
            <ListItemButton>
                <ListItemText primary={`${period}`} secondary={`${props.datetime.LITERAL}`}/>
            </ListItemButton>
            </ListItem>


        </List>
      </nav>
    </Box>
        
        </>

    )
}

export default CampaignSummary