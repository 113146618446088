import React, { useState, useEffect, useContext, useReducer } from 'react'
import { constructorContext } from '..'
import {
    Box, Dialog, DialogActions, DialogContent, DialogTitle,
    Tooltip, Button, TextField, Divider, Select, MenuItem, InputLabel, FormControl, List, ListItem, IconButton
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import api from '../../../../api';
import useNotification from '../../../Notification';
import EmojiTextarea from '../../../Input/EmojiTextarea';
import InputAdornment from '@mui/material/InputAdornment';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';

import defaultReducer from '../../../../utils/defaultReducer';
import IntegerOnly from '../../../../utils/IntegerOnly';

import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';


const action = (data) => {
    if (data?.id)
        return 'Editar'
    return 'Adicionar'
}

const DescriptorsEditModal = ({ data, setData, getRows }) => {
    const { enterpriseId, activeTab, setLoading } = useContext(constructorContext)

    const [state, setState] = useReducer(defaultReducer, {
        tag: '',
        description: ''
    })


    const checkButtonText = () => {
        const text = []

        if (state.tag.length == 0)
            text.push(<Box>Nome não pode estar vazio.</Box>)

        if (state.description.length == 0)
            text.push(<Box>Descrição não pode estar vazia.</Box>)

        if (text.length == 0)
            return ''

        return (text)
    }

    useEffect(async () => {
        if (!data.id)
            return

        setState({
            tag: data.tag,
            description: data.description
        })

    }, [data])

    const submit = async () => {
        let res = null
        const url = `api/enterprises/${enterpriseId}/descriptors/`

        setLoading(true)
        if (!data?.id)
            res = await api.post(url, state, { timeout: 0 })
        else
            res = await api.patch(url + `${data.id}/`, state, { timeout: 0 })
        setLoading(false)

        if (!(res.status === 200 || res.status === 201)) {
            if ('tag' in res.data && res.data.tag[0] === 'Ensure this field has no more than 80 characters.') {
                useNotification(
                    'Campo Inválido!',
                    `O campo nome não pode ter mais de 80 caracteres.`,
                    'danger'
                )
                return
            }
            if ('tag' in res.data && res.data.tag[0] === 'Name cannot start with "sys_".') {
                useNotification(
                    'Campo Inválido!',
                    `O campo nome não pode começar com a palavra "sys_".`,
                    'danger'
                )
                return
            }
            if ('description' in res.data && res.data.description[0] === 'Ensure this field has no more than 255 characters.') {
                useNotification(
                    'Campo Inválido!',
                    `O campo descrição não pode ter mais de 255 caracteres.`,
                    'danger'
                )
                return
            }
            useNotification(
                'Ops!',
                `Não foi possível ${action(data).toLowerCase()} o descritor.`,
                'danger'
            )
            return
        }
        getRows()
        setData()
        useNotification(
            'Sucesso!',
            `Descritor ${res.status == 201 ? 'adicionado' : 'atualizado'} com sucesso.`,
            'success'
        )

    }

    return (
        <>
            <Dialog open={true}
                fullWidth
                disablePortal
                disableEnforceFocus
                sx={{position:'absolute'}}
                >
                <DialogTitle
                    id="customized-dialog-title" className={'group-flexbox'}>
                    <span>{action(data)} Descritor</span>
                    <Tooltip title="Fechar" >
                        <Button className="action-button"  >
                            <CloseIcon onClick={() => { setData() }} />
                        </Button>
                    </Tooltip>
                </DialogTitle>
                <Divider />
                <DialogContent className='piloto-modal-flex'>

                    <TextField
                        size='small'
                        label='Nome'
                        value={state.tag}
                        onChange={(e) => setState({ tag: e.target.value })}
                    />

                    <TextField
                        size='small'
                        label='Descrição'
                        multiline
                        maxRows={6}
                        value={state.description}
                        onChange={(e) => setState({ description: e.target.value })}
                    />

                </DialogContent >
                <DialogActions style={{ padding: "10px 20px 10px 10px" }}>
                    <Button
                        onClick={() => { setData() }}>
                        Cancelar
                    </Button>
                    <Tooltip title={checkButtonText()} placement="top" describeChild={true}>
                        <span>
                            <Button
                                disabled={checkButtonText()}
                                onClick={submit}>
                                Salvar
                            </Button>
                        </span>
                    </Tooltip>
                </DialogActions>
            </Dialog >
        </>
    )
}

export default DescriptorsEditModal