import React, { useEffect, useReducer } from 'react'
import { lockedReducer } from '../../../../utils/defaultReducer'
import { graph_menus } from '.'

import * as moment from "moment";

const GraphViewMain = () => {

    const translatedMonths = [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro'
    ]

    const [state, setState] = useReducer(lockedReducer, {
        data       : {},
        graph      : '',
        filters    : {},
        option     : '',
        periodText : ''
    })

    const getPeriodText = (filters) => {
        let periodText
        switch (filters.type) {
            case 'month':
                const month = filters.month.$d.getMonth()
                const year = filters.month.$d.getFullYear()
                periodText = `${translatedMonths[month]} ${year}`
                break
            case 'day':
                const day = moment(filters.day.$d).format("DD/MM/YYYY").toString()
                periodText = day
                break
            case 'range':
                const startDate = moment(filters.range_ini.$d).format("DD/MM/YYYY HH:mm").toString()
                const endDate = moment(filters.range_end.$d).format("DD/MM/YYYY HH:mm").toString()
                periodText = `entre ${startDate} e ${endDate}`
                break
            default:
                break
        }
        return periodText
    }

    useEffect(()=>{
        window.showGraph = (data, graph, option, filters) => setState({data:data, graph:graph, option: option, filters:filters, periodText:getPeriodText(filters)})
        return () => {
            window.showGraph = () => {}
        }
    }, [])

    const menus = graph_menus(state.data, state.option, state.filters, state.periodText)

    return (
        <>{menus[state.graph]?.component || null}</>
    )
}

export default GraphViewMain