import React, { useState, useEffect } from "react";
import PasswordInput from "../../../Input";
import { Grid, TextField, Typography } from "@mui/material";
import InputMask from "react-input-mask";
import { useStyles } from "./styles.js";
import PropTypes from "prop-types";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const UserForm = ({ values, handleChange, handleBlur, errors, touched, setFieldValue }) => {

  const classes = useStyles();
  return (
    <Grid container spacing={2}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography className={classes.title} variant="h4">
          PRIMEIRO ACESSO
        </Typography>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <TextField
          id="first_name"
          label="Nome"
          variant="outlined"
          name="user.first_name"
          value={values.first_name}
          onChange={handleChange}
          onBlur={handleBlur}
          error={Boolean(errors.first_name) && touched.first_name}
          helperText={touched.first_name ? errors.first_name : ""}
          fullWidth
          required
          size="small"
        />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <TextField
          id="last_name"
          label="Sobrenome"
          variant="outlined"
          name="user.last_name"
          value={values.last_name}
          onChange={handleChange}
          onBlur={handleBlur}
          error={Boolean(errors.last_name) && touched.last_name}
          helperText={touched.last_name ? errors.last_name : ""}
          fullWidth
          size="small"
        />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <TextField
          id="username"
          label="E-mail corporativo"
          variant="outlined"
          name="user.username"
          value={values.username}
          onChange={handleChange}
          onBlur={handleBlur}
          error={Boolean(errors.username) && touched.username}
          helperText={touched.username ? errors.username : ""}
          fullWidth
          required
          size="small"
        />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <PhoneInput
          inputProps={{
            name: 'Telefone',
            required: true,
            autoFocus: true
          }}
          country={'br'}
          id="telephone"
          label="Telefone"
          name="user.telephone"
          value={values.telephone}
          //onChange={event => setFieldValue("user.telephone", event.target.value)}
          onBlur={handleBlur}
          error={Boolean(errors.telephone) && touched.telephone}
          helperText={touched.telephone ? errors.telephone : ""}
          inputStyle={{ width: '100%', height: '40px' }}
        />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <TextField
          id="outlined-basic"
          label="Cargo"
          name="user.role"
          variant="outlined"
          value={values.role}
          onChange={handleChange}
          onBlur={handleBlur}
          error={Boolean(errors.role) && touched.role}
          helperText={touched.role ? errors.role : ""}
          fullWidth
          size="small"
        />
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <PasswordInput
          label="Senha"
          name="user.password"
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          error={Boolean(errors.password) && touched.password}
          helperText={touched.password ? errors.password : ""}
        />
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <PasswordInput
          label="Confirmar senha"
          name="user.confirm_password"
          value={values.confirm_password}
          onChange={handleChange}
          onBlur={handleBlur}
          error={Boolean(errors.confirm_password) && touched.confirm_password}
          helperText={touched.confirm_password ? errors.confirm_password : ""}
        />
      </Grid>
    </Grid>
  );
};

UserForm.propTypes = {
  values: PropTypes.object,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  errors: PropTypes.object,
  touched: PropTypes.object,
  setFieldValue: PropTypes.func

};

UserForm.defaultProps = {
  values: {},
  handleChange: () => console.log("carregando"),
  handleBlur: () => console.log("carregando"),
  errors: {},
  touched: {},

}
export default UserForm;
