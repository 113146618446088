import api from '../api'

const createChatbot = async (chatbotId) => {

    if (! chatbotId) return

    const chatbot = await api.get(`/api/getBuilder/getJson/${chatbotId}/?marketplace`)
    const newChatbot = await api.post(`/api/getBuilder/importJson/`, { typebot: chatbot.data })
    
    return (newChatbot.status === 200) ? newChatbot.data : undefined
}

export default createChatbot
