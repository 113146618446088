import * as React from 'react';
import PropTypes from 'prop-types';

//import useEffect from 'react';

import { Box, TextField } from '@mui/material';
import {
    DataGridPro,
    getGridStringOperators,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    gridClasses,
    LicenseInfo,
    ptBR
} from '@mui/x-data-grid-pro';
import { ptBR as corePtBR } from '@mui/material/locale';
import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

LicenseInfo.setLicenseKey(process.env.REACT_APP_DATAGRID_LICENSE_KEY);

const FilterInputValue = (props) => {
    const { item, applyValue } = props;

    const handleFilterChange = (event) => {
        // Apply the filter by clicking the Enter key
        if (event.key === 'Enter') {
            applyValue({ ...item, value: event.target.value });
        }
    };

    return (
        <TextField
            label="Valor"
            defaultValue={item.value}
            onKeyDown={handleFilterChange}
            variant="standard"
        />
    );
}

const Table = ({
    columns,
    rows,
    pageSize,
    title,
    onCellClick,
    backgroundColor,
    rowClassName,
    cursor,
    checkboxSelection,
    onSelectionModelChange,
    pagination,
    toolbarFilter,
    filterMode,
    onFilterModelChange,
    filterModel,
    customRow,
    integrationSource,
    selectedItems,
    initialSort,
    customToolBarButtons,
    customToolBarButtonsRight
}) => {
    const theme = createTheme({
        palette: {
            primary: { main: '#21446C' },
        },
    }, ptBR, corePtBR);

    const useStyles = makeStyles(() => ({
        root: {
            '& .MuiDataGrid-row': {
                backgroundColor: 'white',
            },
            '& .MuiDataGrid-row:hover': {
                backgroundColor: 'rgba(238, 238, 238, 0.53)',
                cursor: cursor

            },

        },
        toolbar: {
            justifyContent: "center",
            alignContent: 'center',
            alignItems: "center",
            width: '100%',
            borderBottom: "solid 1px #DFE0EB",
            borderTopLeftRadius: '20px',
            borderTopRightRadius: '20px',
        }
    }
    ));

    const customToolbar = () => {
        return (
            <GridToolbarContainer className={ `${gridClasses.toolbarContainer} custom-toolbar-buttons`}>
                <Box className="left-toolbar-button">
                <GridToolbarColumnsButton />
                {toolbarFilter || toolbarFilter === undefined && <GridToolbarFilterButton />}
                { customToolBarButtons || customToolBarButtons === undefined && customToolBarButtons}
                </Box>
                <Box className="right-toolbar-button">
                { customToolBarButtonsRight }
                </Box>
            </GridToolbarContainer>
        );
    }

    const classes = useStyles();

    const [selectionModel, setSelectionModel] = React.useState([]);
    const [sortModel, setSortModel] = React.useState([])
    
    React.useEffect(() => {
        if(selectedItems) 
            setSelectionModel(selectedItems)
        
        if(initialSort)
            setSortModel(initialSort)
    }, [selectedItems, initialSort])

    const components = { Toolbar: customToolbar };
    if (customRow) {
        components.Row = customRow;
    }

    let columnsData = [];
    columnsData = columns;
    if (integrationSource === 'ZENDESK') {
        columnsData = React.useMemo(
            () =>
                columns.map((col) => {
                    return {
                        ...col,
                        filterOperators: getGridStringOperators()
                            .filter((operator) => operator.value === 'equals')
                            .map((operator) => ({
                                ...operator,
                                InputComponent: operator.InputComponent
                                    ? FilterInputValue
                                    : undefined,
                            })),
                    };
                }),
            [columns],
        );
    } else {
        columnsData = columns;
    }

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <div style={{ width: '100%' }} className={classes.root}>
                    <DataGridPro
                        className={classes.table}
                        pagination={pagination}
                        autoHeight
                        disableDensitySelector={true}
                        onCellClick={onCellClick}
                        density="compact"
                        rowsPerPageOptions={[10, 20, 40, 60, 100, 200, 500]}
                        columns={columnsData}
                        rows={rows}
                        pageSize={pageSize ? pageSize : null}
                        checkboxSelection={checkboxSelection}
                        onSelectionModelChange={(newSelectionModel) => {
                            if (checkboxSelection) {
                                setSelectionModel(newSelectionModel);
                                onSelectionModelChange(newSelectionModel);
                            }
                        }}
                        onSortModelChange={(newSortModel) => {
                                setSortModel(newSortModel);
                            }
                        }
                        selectionModel={selectionModel}
                        components={components}
                        disableSelectionOnClick={true}
                        filterMode={filterMode}
                        onFilterModelChange={onFilterModelChange}
                        filterModel={filterModel}
                        sortModel={sortModel}
                    />
                </div>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

Table.propTypes = {
    columns: PropTypes.array,
    rows: PropTypes.array,
    pageSize: PropTypes.number,
    title: PropTypes.string,
    onCellClick: PropTypes.func,
    backgroundColor: PropTypes.string,
    rowClassName: PropTypes.string,
    cursor: PropTypes.string,
};

export default Table;
