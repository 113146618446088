import React from 'react'

import OptionsMenu from '../../../../utils/OptionsMenu'
import { getSession } from '../../../../auth';
import userPermissions from '../../../User/Permissions';
import Labels from '../../Labels';
import Messages from '../../Messages'
import PredefinedMessages from '../../PredefinedMessages'
import EndActions from './end-actions';
import EndChat from './end-chat';

const getOptions = () => {
    const enterprise = getSession()?.profile?.enterprise
    const groups = userPermissions();

    return {
        labels: {
            permissions: groups.isAdmin,
            label: "Etiquetas",
            component: (<Labels  enterpriseId={enterprise?.id}/>)
        },
        timeouts: {
            permissions: groups.isAdmin,
            label: "Timeouts",
            component: (<Messages enterpriseId={enterprise?.id}/>)
        },
        predefined: {
            permissions: groups.isAdmin,
            label: "Mensagens Predefinidas",
            component: (<PredefinedMessages enterpriseId={enterprise?.id}/>)
        },
        endchat: {
            permissions: groups.isAdmin && enterprise.settings.can_configure_hangup_on_settings,
            label: "Fim de atendimento",
            component: (<EndChat/>)
        },
        endactions: {
            permissions: groups.isAdmin && enterprise.settings.can_configure_end_actions,
            label: "Final do expediente",
            component: (<EndActions />)
        }
    }
}

const OnChatMenu = () => {
    return (
        <OptionsMenu name='onchat-settings' options={getOptions} />
    )
}

export default OnChatMenu