import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import LoadingButton from '@mui/lab/LoadingButton';
import PropTypes from "prop-types";
import { Divider, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ConfirmationDialog = ({ open, handleClose, handleSubmit, submitButtonLoading }) => {

    return (
        <div>

            <Dialog
                open={open}
                //TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                disableEnforceFocus
            >
                <DialogTitle id="alert-dialog-slide-title" className={'group-flexbox'}>
                    <span>Reenvio de Notificações</span>
                    <Tooltip title="Fechar" >
                        <Button className="action-button"  >
                            <CloseIcon onClick={handleClose} />
                        </Button>
                    </Tooltip>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <DialogContentText style={{ marginBottom: "1rem", textAlign: "justify"}}>
                        De acordo com a nova política do WhatsApp, 
                        a quantidade de notificações de <strong style={{ fontWeight: 500 }}>marketing</strong> que um cliente pode receber é limitada dentro dos últimos <strong style={{ fontWeight: 500 }}>7 dias</strong>, 
                        para manter uma experiência de alta qualidade.
                    </DialogContentText>
                    <DialogContentText>
                        Outras razões que podem causar o <strong style={{ fontWeight: 500 }}>não envio</strong> da mensagem:
                        <p style={{ marginTop: "0.5rem", marginLeft: "1rem", marginBottom: "0.5rem" }}>
                            &bull; O número de telefone do destinatário não é um número de telefone do WhatsApp.
                        </p>
                        <p style={{ marginLeft: "1rem", marginBottom: "0.5rem" }}>
                            &bull; O destinatário não aceitou os novos Termos de Serviço e Política de Privacidade do WhatsApp.
                        </p>
                        <p style={{ marginLeft: "1rem", marginBottom: "0.5rem" }}>
                            &bull; Destinatário utilizando versão antiga do WhatsApp.
                        </p>
                    </DialogContentText>
                    <DialogContentText style={{ marginTop: "1rem", textAlign: "justify" }}>
                        O WhatsApp recomenda que o reenvio seja feito <strong style={{ fontWeight: 500 }}>3-5 dias</strong> após a última tentativa de envio.
                        Você tem certeza que deseja realizar o reenvio das notificações selecionadas?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        color="primary">
                        Não
                    </Button>
                    <LoadingButton
                        onClick={handleSubmit}
                        color="primary"
                        loading={submitButtonLoading}
                    >
                        Sim
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </div>
    );
}

ConfirmationDialog.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    handleSubmit: PropTypes.func,
    submitButtonLoading: PropTypes.bool
};

export default ConfirmationDialog;
