import useNotification from '../Notification';

const ErrorValidation = (status, message) => {
    let message_error = '';
    let error_360dialog = 0;


    if (message && message.split('error_subcode":')[1]) {
        // 360dialog error subcode
        error_360dialog = Number(message.split('error_subcode":')[1].split(',')[0]);
    }


    if (!status || status === 200 || status === 201) {
        return true;

    } else if (status === 400 && message.includes('Invalid payload') && message.includes('Header Format is Incorrect')) {
        message_error = 'O cabeçalho da mensagem não pode conter novas linhas, caracteres de formatação, emojis ou asteriscos.';

    } else if (status === 400 && message === 'Template with that name already exists.') {
        message_error = 'O nome já foi cadastrado. Aguarde 4 semanas ou crie um novo nome ainda não utilizado.';
    }

    else if (status === 400 && message.includes('you have provided is already in use')) {
        message_error = 'O nome já foi cadastrado. Aguarde 4 semanas ou crie um novo nome ainda não utilizado.';

    } else if (status === 400 && error_360dialog === 2388046) {
        message_error = 'O formato do nome está incorreto. O nome do modelo de mensagem não pode ter letras maiúsculas e espaços.';

    } else if (status === 400 && message === 'API settings not found.') {
        message_error = 'Notificações não disponíveis para sua empresa.';
    }
    else if (status === 400 && message?.includes('is not a valid URI')) {
        message_error = 'URL do botão tipo LINK inválida.';
    }
    else if (status === 400 && message?.includes('The Header field can only have up to 1 variable(s)')) {
        message_error = 'O limite de variáveis no cabeçalho é 1.';
    }
    else if (status === 400 && message?.includes('Message template language is being deleted')) {
        message_error = 'Não é possível adicionar o template com este nome, tente novamente com um nome novo';
    }
    else if (status === 403) {
        message_error = 'Você não tem permissão para executar essa ação.';

    } else if (status === 404) {
        message_error = 'Não encontrado.';

    } else {
        message_error = 'Houve um erro na requisição, tente novamente.';
    }

    if (message_error) {
        useNotification('Erro!', message_error, 'danger');
    }

    return false
}

export default ErrorValidation;