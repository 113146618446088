import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import {
  BrowserRouter as Router,
} from "react-router-dom";
import 'fontsource-roboto';
import "./index.css";
import { ThemeProvider, StyledEngineProvider } from "@mui/material";
import theme from './assets/theme'

ReactDOM.render((
  <Router>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider >
    </StyledEngineProvider>
  </Router >

), document.getElementById('root'));
