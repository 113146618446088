import React, { useContext, useEffect, lazy } from 'react'

import { Box } from '@mui/material'

import { QueueSocket } from '../../routes'

import api from '../../api';
import { getSession } from '../../auth';

import './styles.css'

const KwikAssistant = () => {

    const { setShowAssistant } = useContext(QueueSocket)

    const setJS = () => {
        const script = document.createElement('script');
        const bucket = 'https://cf-templates-bum1ctwi7lwa-us-east-1.s3.amazonaws.com'
        
        let version = 'kwik-assistant-dev.js'
        if (window.location.href.indexOf('kwik.app.br') !== -1)
            version = 'kwik-assistant.js'
        
        script.src = `${bucket}/${version}`;
        script.async = true;

        document.body.appendChild(script);
    }

    const login = async () => {
        const user = getSession();
        const agentId = user.profile.agent.id;

        // Get assistant token
        if (user.profile.enterprise.has_assistant && user.profile.has_assistant) {
            const res = await api.post(`/api/IV/agents/${agentId}/assistant_login/`)
            if (res.status == 200) {
                window.assistantAccessToken = res.data['access_token']
                window.assistantAccountCode = user.profile.enterprise.assistant_accountcode_id
                window.assistantUserId      = user.profile.assistant_user_id
                window.assistantUrl         = res.data['assistant_url']
            }
            else
                console.error('Couldnt login agent assistant [err:%o]', res.err)
        }
        setJS()
    }

    useEffect(() => {
        window.closeAssistant = (() => setShowAssistant(false))
        window.openAssistant  = (() => setShowAssistant(true))
        login()
    }, [])

    return (
        <>
            <Box sx={{ flex: .25 }} id='assistant-container-vpower'>
            </Box>
        </>
    )
}

export default KwikAssistant
