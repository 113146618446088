import React from "react";
import { Grid, Box } from "@mui/material";
import Typography from "../../../components/Typography";
import Text from "../../../components/Text";
import PropTypes from "prop-types";

const HeaderForm = ({ title, subtitle, img, width }) => {

    return (
        <Box marginBottom={5}>
            <Grid container>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                    <img src={img} width={width} alt="header" />
                </Grid>
                <Grid item lg={8} md={8} sm={12} xs={12}>
                    <Box marginTop={2} marginRight={6}>
                        <Text type="title" text={title} color="blue" />
                        <Typography text={subtitle} color="darkGray" variant="h5" />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

HeaderForm.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    img: PropTypes.image,
    width: PropTypes.string
};

export default HeaderForm;
