import React from 'react';

import { IconButton, Box, Popper, Fade, Grid, ClickAwayListener, Fab, Tooltip } from "@mui/material";

import AttachFileIcon from '@mui/icons-material/AttachFile';
import RoomIcon from '@mui/icons-material/Room';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import PhotoIcon from '@mui/icons-material/Photo';
import PropTypes from "prop-types";
import VideocamIcon from '@mui/icons-material/Videocam';

import "./styles.css";

const DISABLED_IMAGE_PLATFORMS = new Set([])

const DISABLED_VIDEO_PLATFORMS = new Set([
    'MESSENGER',
    'INSTAGRAM'
])

const DISABLED_DOCUMENT_PLATFORMS = new Set([])

class AttachmentMenu extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            achorEl: null,
            open: false,
            placement: ''
        }
    }

    handleClick = (placement) => {
        this.setState({
            anchorEl: this.attachIcon,
            open: true,
            placement: placement
        });
    };

    handleClickAway = () => {
        this.setState({
            open: false
        });
    };

    handleChange = (file, type) => {
        this.props.setFile({
            id: 0,
            name: file.name,
            preview: URL.createObjectURL(file),
            file: file,
            type: type
        });
    }

    render() {
        return (
            <ClickAwayListener onClickAway={this.handleClickAway}>
                <div className="root">
                    <Popper open={this.state.open}
                        anchorEl={this.state.anchorEl}
                        placement={this.state.placement}
                        transition
                        className="attachment-menu-popper"
                    >
                        {({ TransitionProps }) => (
                            <Fade {...TransitionProps} timeout={350}>
                                <Box display="flex" flexDirection="column" paddingBottom="20px">
                                    <input
                                        accept="image/*"
                                        style={{display:'none'}}
                                        id="contained-image-file"
                                        onChange={(e) => this.handleChange(e.target.files[0], "image")}
                                        type="file"
                                        disabled={DISABLED_IMAGE_PLATFORMS.has(this.props.activeChat.platform)}
                                    />
                                    <label htmlFor="contained-image-file"
                                        className={DISABLED_IMAGE_PLATFORMS.has(this.props.activeChat.platform) ? "attachment-btn inactive" : "attachment-btn"}
                                    >
                                        <Tooltip title={DISABLED_IMAGE_PLATFORMS.has(this.props.activeChat.platform) ? "Não é possível enviar imagens para este canal de atendimento" : "Imagem"}
                                            placement="right" disableInteractive
                                        >
                                            <Fab color="primary" 
                                                aria-label="add"
                                                className="fab add-image"
                                                component="span"
                                            >
                                                <PhotoIcon />
                                            </Fab>
                                        </Tooltip>
                                    </label>
                                    <input
                                        accept="video/*"
                                        style={{display:'none'}}
                                        id="contained-video-file"
                                        onChange={(e) => this.handleChange(e.target.files[0], "video")}
                                        type="file"
                                        disabled={DISABLED_VIDEO_PLATFORMS.has(this.props.activeChat.platform)}
                                    />
                                    <label htmlFor="contained-video-file"
                                        className={DISABLED_VIDEO_PLATFORMS.has(this.props.activeChat.platform) ? "attachment-btn inactive" : "attachment-btn"}
                                    >
                                        <Tooltip title={DISABLED_VIDEO_PLATFORMS.has(this.props.activeChat.platform) ? "Não é possível enviar vídeos para este canal de atendimento" : "Vídeo"} 
                                            placement="right" disableInteractive
                                        >
                                            <Fab color="primary" 
                                                aria-label="add"
                                                className="fab add-video"
                                                component="span"
                                            >
                                                <VideocamIcon />
                                            </Fab>
                                        </Tooltip>
                                    </label>
                                    <input
                                        accept="application/*"
                                        style={{display:'none'}}
                                        id="contained-application-file"
                                        type="file"
                                        onChange={(e) => this.handleChange(e.target.files[0], "document")}
                                        disabled={DISABLED_DOCUMENT_PLATFORMS.has(this.props.activeChat.platform)}
                                    />
                                    <label htmlFor="contained-application-file"
                                        className={DISABLED_DOCUMENT_PLATFORMS.has(this.props.activeChat.platform) ? "attachment-btn inactive" : "attachment-btn"}
                                    >
                                        <Tooltip title={DISABLED_DOCUMENT_PLATFORMS.has(this.props.activeChat.platform) ? "Não é possível enviar vídeos para este canal de atendimento" : "Documento"} 
                                            placement="right" disableInteractive
                                        >
                                            <Fab color="primary" 
                                                aria-label="add"
                                                className="fab add-document"
                                                component="span"
                                            >
                                                <InsertDriveFileIcon />
                                            </Fab>
                                        </Tooltip>
                                    </label>
                                </Box>
                            </Fade>
                        )}
                    </Popper>
                    <Grid container justifyContent="center">
                        <Grid item>
                            <IconButton size="large">
                                <Tooltip title="Anexar" placement="top" disableInteractive>
                                    <AttachFileIcon color="primary"
                                        ref={
                                            instance => {
                                                this.attachIcon = instance
                                            }
                                        }
                                        onClick={() => this.handleClick('top')}
                                    />
                                </Tooltip>
                            </IconButton>
                        </Grid>
                    </Grid>
                </div>
            </ClickAwayListener>
        );
    }
};

export default AttachmentMenu;