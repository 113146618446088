import React from 'react';

import { Dialog } from '@mui/material';

class RestrictedWordsDialog extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <Dialog 
                    keepMounted
                    open={this.props.open}
                    onClose={this.props.handleClose}
                    aria-labelledby="customized-dialog-title"
                    fullWidth
                    disablePortal
                    disableEnforceFocus
                    sx={{position:'absolute'}}
                >
                    { this.props.contents }
                </Dialog>
            </div>
        );
    }
}

export default RestrictedWordsDialog;
