import React, { useEffect, useState, useContext } from 'react';

import { IMaskInput } from 'react-imask';

import { Dialog, DialogTitle, DialogContent, Switch, Grid, Box, Button, Divider } from '@mui/material';
import { DialogActions } from '@mui/material';
import { TextField } from '@mui/material';
import { FormHelperText } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';


import api from '../../../../../api';
import Table from "../../../../Table";

import { ScreenContext } from '../../..';
import CloseIcon from '@mui/icons-material/Close';

import useNotification from '../../../../Notification';

const EditGroupNameModal = (props) => {
    const { updateGroups, updateClients, subsObj } = useContext(ScreenContext)
    const [clientGroupName, updateClientGroupName] = useState("")
    const [clientGroupDescription, updateClientGroupDescription] = useState("")


    const submit = async () => {
        const url = `/api/enterprises/${props.enterpriseId}/contact_group/${props.selectedGroup.id}/`
        const body = {
            name: clientGroupName,
            description: clientGroupDescription,
        }

        const response = await api.patch(url, body)

        if (response.status === 400) {
            let message = 'Ocorreu um erro com a requisição.'
            if ('non_field_errors' in response.data && response.data.non_field_errors)
                message = 'Já existe um segmento com esse nome nessa empresa.'
            useNotification(
                'Ops!',
                message,
                'danger'
            );
            return
        }

        if (response.status == 403) {
            useNotification(
                'Ops!',
                'Você não tem permissão para ver esse conteúdo',
                'danger'
            );
            return
        }
        updateGroups(prevState => subsObj(prevState, response.data))
        updateClients(prevState => {
            prevState.filter(each => each.groups.map(each => each.id).includes(response.data.id))
                .map(each => (
                    each.groups = subsObj(each.groups, response.data)
                ));
            return prevState
        })
        props.submit()
    }

    useEffect(() => {
        updateClientGroupName(props.selectedGroup.name)
        updateClientGroupDescription(props.selectedGroup.description)
    }, [props.selectedGroup])

    return (
        <div>
            {
                <Dialog keepMounted
                    open={props.open}
                    // onClose={props.handleClose}
                    aria-labelledby="customized-dialog-title"
                    fullWidth
                    disablePortal
                    disableEnforceFocus
                    sx={{position:'absolute'}}
                >
                    <DialogTitle id="customized-dialog-title" className={'group-flexbox'}>
                        <span> {props.title} </span> <Button sx={{ 'minWidth': '2rem', 'padding': '0' }} onClick={props.handleClose} ><CloseIcon /></Button>
                    </DialogTitle>
                    <Divider />
                    <DialogContent>
                        <>
                            <TextField
                                size="small"
                                label="Nome"
                                fullWidth
                                type="text"
                                variant="outlined"
                                className="push-top"
                                value={clientGroupName}
                                InputLabelProps={{ shrink: true }}
                                onChange={(event) => { updateClientGroupName(event.target.value) }}

                            />
                            <TextField
                                size="small"
                                label="Descrição"
                                fullWidth
                                type="text"
                                variant="outlined"
                                className="push-top"
                                value={clientGroupDescription}
                                InputLabelProps={{ shrink: true }}
                                onChange={(event) => { updateClientGroupDescription(event.target.value) }}

                            />
                        </>
                    </DialogContent>
                    <DialogActions style={{ padding: "10px 20px 10px 10px" }}>
                        <LoadingButton
                            onClick={props.handleClose}
                            color="primary"
                        >
                            Cancelar
                        </LoadingButton>
                        <LoadingButton
                            onClick={() => submit()}
                            color="primary"

                        >
                            {props.buttonText}
                        </LoadingButton>
                    </DialogActions>
                </Dialog>
            }
        </div>
    )
}

export default EditGroupNameModal
