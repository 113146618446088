import React, { useReducer } from 'react'

import { Button, Dialog, DialogTitle, DialogContent, Divider, Tooltip } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import NewHistory from '../../../../Chat/ChatRightSideBar/History/newhistory'
import { fetchHistoryMessages } from '../../../../Report/NewDetailedRegister/detailed_register_functions'
import { getSession } from '../../../../../auth'
import defaultReducer from '../../../../../utils/defaultReducer'
import HistoryMessagesDialog from '../../../../Dialogs/HistoryMessagesDialog'

const openHistoryDialog = async (uniqueid, setState) => {
    const res = await fetchHistoryMessages(uniqueid)
    if (res) {
        setState({ chat: res })
    }
}

const UserChats = ({ data, handleClose }) => {

    const [state, setState] = useReducer(defaultReducer, {
        chat: null,
        contact_no_scroll: null
    })

    const enterprise = getSession()?.profile?.enterprise

    return (
        <>
            <Dialog keepMounted
                open={true}
                aria-labelledby="customized-dialog-title"
                disablePortal
                disableEnforceFocus
                sx={{ position: 'absolute' }}
            >
                <DialogTitle id="customized-dialog-title" className={'group-flexbox'}>
                    <Tooltip title={`Histórico de conversas: ${data.name || ''}`}>
                        <span sx={{
                            maxQidth: '15rem',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        }}>Histórico de conversas</span>
                    </Tooltip>
                    <Button sx={{ 'minWidth': '2rem', 'padding': '0' }} onClick={handleClose} >
                        <CloseIcon />
                    </Button>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <NewHistory
                        contactData={data}
                        fetchHistoryMessages={(d) => openHistoryDialog(d.uniqueid, setState)}
                    />
                </DialogContent>
            </Dialog>
            {state.chat &&
                <HistoryMessagesDialog
                    activeChat={state.chat}
                    open={true}
                    uniqueId={state.chat.uniqueid}
                    nick={data ? data.nick : null}
                    lastDateTime={state.chat.time}
                    historyMessages={[]}
                    platform={state.chat.platform}
                    accountcode={enterprise.accountcode}
                    handleClose={() => setState({ chat: null })}
                    forceScroll
                />}
        </>
    )
}

export default UserChats

