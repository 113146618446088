import React from "react";

import { saveToken } from "../../auth";

import { Box } from "@mui/material";

import Header from "../../components/Header";
import LoginForm from "../../components/Forms/LoginForm";

import AlertMobileVersion from "../../components/Dialogs/AlertMobileVersion";

class Login extends React.Component {

  constructor(props) {
    super(props);
  }

  // componentDidMount() {
  //   // Refreshing access token
  //   fetch('/api/auth/token/refresh/', {
  //           method: 'POST',
  //           withCredentials: true
  //       })
  //       .then(response => {
  //           if (response.status == 200) {
  //               return response.json();
  //           }
  //           throw 'Unauthorized';
  //       })
  //       .then(data => {
  //           saveToken(data.access);
  //           window.location.href = '/dashboard';
  //       })
  //       .catch(error => {

  //       })
  //   ;
  // }

  render() {
    return (
      <Box display="flex">
        <Header
          // subtitle={"Ainda não tem conta"}
          // buttonText={"Cadastre-se"}
          // onClick={() => history.push("/register")}
        />
        <LoginForm/>
      </Box>
    );
  } 
}

export default Login;
