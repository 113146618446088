import React from 'react';

import './index.css';

import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { List, ListItem, ListItemText } from '@mui/material';
import { Box, Button, Divider, IconButton, TextField } from '@mui/material';
import { Select, InputLabel, Tooltip, FormControl, MenuItem } from '@mui/material';
import { Paper, InputBase, Popover, Grid } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import MessageIcon from '@mui/icons-material/Message';

import EmojiTextarea from '../../Input/EmojiTextarea';

import useNotification from "../../Notification";

import { emojiReplaceJSX } from '../../../utils/emojiDict';

import { PredefinedDropdown } from '../../Settings/PredefinedMessages/PredefinedMessagesModal';

class PredefinedMessageDialog extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            text: "",
            category: "",
            newCategory: "",
            predefinedMessages: {},
            showPopover: false,
            anchorEl: null,
            showAddCategory: false,
            openViewMessage: false,
            openEditMessage: false,
            editMessage: {},
            textEdit: ""
        }
    }

    usePredefinedMessage = (message) => {
        this.props.usePredefinedMessage(message);
        this.props.handleClose();
    }

    onEmojiClick = (event) => {
        let text = this.state.text;
        text += event.target.innerText;
        this.setState({
            text: text,
            showPopover: false,
            anchorEl: null
        });
    }

    onKeyDownPredefinedMessage = (event) => {
        // key shift off and enter on
        if (!event.shiftKey && (event.keyCode === 13 || event.key === 'Enter')) {
            this.addPredefinedMessage();
            event.preventDefault();
        }
    }

    onKeyDownEditMessage = (event) => {
        // key shift off and enter on
        if (!event.shiftKey && (event.keyCode === 13 || event.key === 'Enter')) {
            this.saveEditMessage();
            event.preventDefault();
        }
    }

    addPredefinedMessage = async () => {
        const text = this.state.text;

        if (text) {
            const category = this.state.category;
            const predefinedMessages = this.state.predefinedMessages;
            const messageData = await this.props.addPredefinedMessage(text, category);

            if (messageData) {
                if (! predefinedMessages[category]) {
                    predefinedMessages[category] = {};
                }
                predefinedMessages[category].push(messageData);

                this.setState({
                    text: '',
                    predefinedMessages: predefinedMessages
                });
            }
        }
    }

    saveEditMessage = async () => {
        const text = this.state.textEdit;
        let editMessage = this.state.editMessage;
        editMessage.message = text;
        await this.props.updatePredefinedMessage(editMessage);

        this.setState({
            textEdit: '',
            editMessage: {},
            openEditMessage: false
        });
    }

    removePredefinedMessage = (message) => {
        this.props.removePredefinedMessage(message);

        const category = this.state.category;
        const predefinedMessages = this.state.predefinedMessages;
        for (var i = 0; i < predefinedMessages[category].length; i++) {
            if (predefinedMessages[category][i].id == message.id) {
                predefinedMessages[category].splice(i, 1);
            }
        }
        this.setState({
            predefinedMessages: predefinedMessages
        });
    }

    onChangeCategory = (event) => {
        const category = event.target.value;

        this.setState({ category: category });
    }

    onChangeNewCategory = (event) => {
        const newCategory = event.target.value;

        this.setState({ newCategory: newCategory });
    }

    addCategory = () => {
        let newCategory = this.state.newCategory;

        if (newCategory?.includes(' - Geral')){
            useNotification(
                'Erro!',
                'Categorias gerais não podem ser criadas aqui.',
                'danger'
            );
            return
        }

        if (newCategory) {
            let predefinedMessages = this.state.predefinedMessages;

            if (!predefinedMessages[newCategory]) {
                predefinedMessages[newCategory] = [];

                this.setState({
                    predefinedMessages: predefinedMessages,
                    showAddCategory: false
                });

                useNotification(
                    'Sucesso!',
                    'Categoria adicionada com sucesso.',
                    'success'
                );
            } else {
                useNotification(
                    'Erro!',
                    'Categoria já existe.',
                    'danger'
                );
            }
        }
    }

    predefinedMessageList = (key) => {
        const predefinedMessages = this.state.predefinedMessages[key];
        const predefinedMessageList = [];

        predefinedMessages && predefinedMessages.length && predefinedMessages.map((message, i) => {
            predefinedMessageList.push(
                <ListItem className="item" key={i}>
                    <>
                        <ListItemText sx={{overflow:'clip'}} primary={emojiReplaceJSX(message.message)}
                            onClick={() => this.usePredefinedMessage(message.message)}
                        />
                        <Tooltip title="Visualizar" placement="bottom" disableInteractive>
                            <IconButton
                                onClick={() => {
                                    this.setState({
                                        textEdit: message.message,
                                        editMessage: message,
                                        openViewMessage: true
                                    });
                                }}
                                className="button-update"
                                size="large"
                            >
                                <MessageIcon className="icon-update" />
                            </IconButton>
                        </Tooltip>
                        {(message.user_id || message.user) &&
                            <>
                                <Tooltip title="Editar" placement="bottom" disableInteractive>
                                    <IconButton
                                        onClick={() => {
                                            this.setState({
                                                textEdit: message.message,
                                                editMessage: message,
                                                openEditMessage: true
                                            });
                                        }}
                                        className="button-update"
                                        size="large"
                                    >
                                        <EditIcon color="primary"
                                            className="icon-update"
                                        />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Excluir" placement="bottom" disableInteractive>
                                    <IconButton
                                        onClick={() => this.removePredefinedMessage(message)}
                                        className="button-delete"
                                        size="large">
                                        <DeleteIcon color="primary"
                                            className="icon-delete"
                                        />
                                    </IconButton>
                                </Tooltip>
                            </>
                        }
                    </>
                </ListItem>
            );
        });

        return predefinedMessageList;
    }

    componentWillMount() {
        this.state.predefinedMessages = this.props.predefinedMessages;
        this.state.category = Object.keys(this.props.predefinedMessages)[0];
    }

    render() {
        return (
            <div>
                <Dialog keepMounted
                    open={this.props.open}
                    onClose={this.props.handleClose}
                    aria-labelledby="customized-dialog-title"
                    fullWidth
                    maxWidth="sm"
                    disablePortal
                    disableEnforceFocus
                    sx={{position:'absolute'}}
                >
                    <DialogTitle id="customized-dialog-title" className={'group-flexbox'}>
                        <span>{this.props.title}</span>
                        <Tooltip title="Fechar" >
                            <Button className="action-button">
                                <CloseIcon onClick={this.props.handleClose} />
                            </Button>
                        </Tooltip>
                    </DialogTitle>
                    <Divider />
                    <DialogContent className="dialog-content">
                        {!this.state.showAddCategory &&
                            <Grid container>
                                <Grid item lg={11} md={11} sm={11} xs={11}>
                                    <FormControl
                                        fullWidth
                                        size="small"
                                    >
                                        <InputLabel id="demo-simple-select-label">Categoria</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="Categoria"
                                            defaultValue={this.state.category}
                                            onChange={this.onChangeCategory}
                                        >
                                            {Object.keys(this.state.predefinedMessages).map((key, index) =>
                                                <MenuItem value={key}>{key}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item lg={1} md={1} sm={1} xs={1}>
                                    <Tooltip title="Nova categoria" placement="bottom" disableInteractive>
                                        <IconButton
                                            onClick={() => this.setState({ showAddCategory: true })}
                                            style={{ marginLeft: "10px" }}
                                            size="medium"
                                        >
                                            <AddIcon className="icon-add" />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        }
                        {this.state.showAddCategory &&
                            <Paper
                                sx={{
                                    p: '2px 4px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: 450,
                                    bgcolor: '#efefef'
                                }}
                            >
                                <InputBase
                                    sx={{ ml: 1, flex: 1 }}
                                    placeholder="Nova categoria"
                                    onChange={this.onChangeNewCategory}
                                />
                                <Tooltip title="Adicionar" placement="bottom" disableInteractive>
                                    <IconButton onClick={() => this.addCategory()}>
                                        <AddIcon className="icon-add" />
                                    </IconButton>
                                </Tooltip>
                                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                                <Tooltip title="Cancelar" placement="bottom" disableInteractive>
                                    <IconButton onClick={() => this.setState({ showAddCategory: false })}>
                                        <CloseIcon className="icon-cancel" />
                                    </IconButton>
                                </Tooltip>
                            </Paper>
                        }
                    </DialogContent>
                    <DialogContent ref={instance => { this.content = instance; }}>
                        <List>
                            {this.predefinedMessageList(this.state.category)}
                            {Object.keys(this.state.predefinedMessages).length === 0 &&
                                <Box>Você não possui mensagens predefinidas.</Box>
                            }
                        </List>
                        <Dialog keepMounted
                            open={this.state.openEditMessage || this.state.openViewMessage}
                            onClose={() => this.setState({
                                openViewMessage: false,
                                openEditMessage: false,
                                textEdit: '',
                                editMessage: {}
                            })}
                            aria-labelledby="customized-dialog-title"
                            disablePortal
                            disableEnforceFocus
                            sx={{position:'absolute'}}
                        >
                            <DialogTitle id="customized-dialog-title"
                                style={{
                                    backgroundColor: "rgba(33, 68, 108, 0.06)",
                                    minWidth: "500px"
                                }}
                            >
                                {this.state.openViewMessage ?
                                    "Visualizar mensagem predefinida" :
                                    "Edição de mensagem predefinida"
                                }
                            </DialogTitle>
                            <DialogActions
                                style={{
                                    padding: "10px 20px 15px 1.5rem",
                                    gap: '1rem',
                                    overflowWrap:'break-word'
                                }}
                            >
                                {!this.state.openViewMessage ?
                                    <EmojiTextarea
                                        text={this.state.textEdit}
                                        setText={(event) => this.setState({ textEdit: event.target.value })}
                                        setEmoji={(text) => this.setState({ textEdit: text })}
                                        onKeyDown={this.onKeyDownEditMessage}
                                        maxLength={1000}
                                        label="Mensagem"
                                        childComponent="MessageBox"
                                        dropdown={(<PredefinedDropdown target={(e) => this.setState({ textEdit: this.state.textEdit+e })}/>)}
                                    />
                                    :
                                    <Box sx={{ width: "100%" }}>{emojiReplaceJSX(this.state.textEdit)}</Box>}
                                {this.state.openEditMessage &&
                                    <Button
                                        onClick={() => this.saveEditMessage()}
                                        color="primary">
                                        Salvar
                                    </Button>
                                }
                            </DialogActions>
                        </Dialog>
                    </DialogContent>
                    {this.state.category && !this.state.category?.includes(' - Geral') &&
                        <DialogActions
                            style={{
                                padding: "10px 20px 15px 1.5rem",
                                gap: '1rem'
                            }}
                        >
                            <EmojiTextarea
                                text={this.state.text}
                                setText={(event) => this.setState({ text: event.target.value })}
                                setEmoji={(text) => this.setState({ text: text })}
                                onKeyDown={this.onKeyDownPredefinedMessage}
                                maxLength={1000}
                                label="Mensagem"
                                childComponent="MessageBox"
                                dropdown={(<PredefinedDropdown target={(e) => this.setState({ text: this.state.text+e })}/>)}
                            />
                            <Button
                                onClick={() => this.addPredefinedMessage()}
                                color="primary">
                                Adicionar
                            </Button>
                        </DialogActions>}

                </Dialog>
            </div>
        );
    }
}

export default PredefinedMessageDialog;
