import React, {useEffect, useState} from 'react'

import {
    Select,
    InputLabel, 
    MenuItem, 
    Chip,
    Box, 
    FormControl,
    TextField 
} from '@mui/material'

import useNotification from "../../../../Notification";

import './styles.css'

const DateTimeSelector = props => {

    //const declaration
    const [dayArray, updateDayArray] = useState([])
    const [weekdayArray, updateWeekdayArray] = useState([])
    const [monthArray, updateMonthArray] = useState([])
    const [selectedPeriod, updateSelectedPeriod] = useState('')
    const [selectedHour, updateSelectedHour]= useState('')
    const [selectedRange, updateSelectedRange] = useState(['',''])
    const [maxDate, updateMaxDate] = useState('')
    const monthOffset = -1
    const hourOffset = -3
    const periods = [
        'Diariamente',
        'No intervalo de dias',
        'Nos dias da semana selecionados',
        'Nos meses selecionados'
    ]
    const possibleDays = Array.from({length: 31}, (item, index) => (index+1).toString());
    const possibleWeekdays = [
        {key:0, value:'Segunda-feira'},
        {key:1, value:'Terça-feira'},
        {key:2, value:'Quarta-feira'},
        {key:3, value:'Quinta-feira'},
        {key:4, value:'Sexta-feira'},
        {key:5, value:'Sábado'},
        {key:6, value:'Domingo'}
    ]
    const possibleMonths =[
        {key:1, value:'Janeiro'},
        {key:2, value:'Fevereiro'},
        {key:3, value:'Março'},
        {key:4, value:'Abril'},
        {key:5, value:'Maio'},
        {key:6, value:'Junho'},
        {key:7, value:'Julho'},
        {key:8, value:'Agosto'},
        {key:9, value:'Setembro'},
        {key:10, value:'Outubro'},
        {key:11, value:'Novembro'},
        {key:12, value:'Dezembro'}
    ]
    var now = new Date()
    var todayInc = now.getTime()
    var addedHours =  (3600000*3)
    const today = new Date(todayInc-addedHours).toISOString().split('.')[0].slice(0, -3)
    const todayInDate = today.slice(0,10)
    // props.time == once
    if (Object.entries(props.state).length !== 0 && props.time == 'once'){
        try{
        var newState = new Date(  props.state.YEAR
                                , parseInt(props.state.MONTH)+monthOffset
                                , props.state.DAY
                                , parseInt(props.state.HOURS)+hourOffset
                                , props.state.MINUTES).
                                toISOString().
                                split('.')[0].
                                slice(0, -3)
        }
        catch(e){
            var newState = ''
        }
        
    }

    const changeTime = (event) =>{
        const datetime = event.target.value

        if (!datetime.includes('T')){
            props.canNext(false)
            return
        }
            
        const dateArray = datetime.split('T')
        const date = dateArray[0].split('-')
        const time = dateArray[1].split(':')
        const year = date[0]
        const month = date[1]
        const day = date[2]
        const hours = time[0]
        const minutes = time[1]

        var now = new Date()
        var todayInc = now.getTime()
        var addedHours =  (3600000*3)
        const today2 = new Date(todayInc-addedHours).toISOString().split('.')[0].slice(0, -3)
        
        if (props.time == 'once' && datetime <= today2){
            props.canNext(false)
            useNotification(
                'Falha!',
                `Por favor escolha um horário maior que o atual`,
                'danger'
            )
            return
        }


            

        const dateTimePicker = {
            YEAR: year,
            MONTH: month,
            DAY: day,
            HOURS: hours,
            MINUTES: minutes,
            WEEKDAYS: '*',
            LITERAL: new Date(year,month-1,day, hours, minutes).toLocaleString(),
            DATE: new Date(year,month-1,day, hours, minutes)
        }


        props.onChange(dateTimePicker)

    }


    //props.time == periodic

    useEffect(()=>{
        if (Object.entries(props.state).length !== 0 && props.time == 'periodic'){
            
            props.state.DAY.includes('*')  ? updateDayArray([]) : updateDayArray(props.state.DAY)
            props.state.WEEKDAYS.includes('*')  ? updateWeekdayArray([]) : updateWeekdayArray(props.state.WEEKDAYS) 
            props.state.MONTH.includes('*')  ? updateMonthArray([]) : updateMonthArray(props.state.MONTH)
            updateSelectedPeriod(props.state.PERIOD)
            updateSelectedHour(props.state.HOURS+':'+props.state.MINUTES)
            updateSelectedRange(props.state.RANGE)
        }

        }, [])



    useEffect(() => {
        const changePeriod = () => {
            const year = new Date().getUTCFullYear()
            var month = '*'
            var day = '*'
            var hours = '-1'
            var minutes = '-1'
            var weekdays = '*'
            var literalDays = ''
            var date = ''
    
            if (selectedHour != ''){
                const splitHour = selectedHour.split(':')
                hours = splitHour[0]
                minutes = splitHour[1]
            }
             
    
            if (selectedPeriod == 0)
                var day = '*/1'
            else if (selectedPeriod == 1){
                
                const completeInitialDate =new Date(`${selectedRange[0]}T${hours}:${minutes}`)
                date = completeInitialDate
                const today2 = new Date()

                if ( completeInitialDate <= today2 ){
                    props.canNext(false)
                    props.onChange({})
                    useNotification(
                        'Falha!',
                        `Data e hora de início não pode ser maior ou igual a data e hora atual.`,
                        'danger'
                    )
                    return
                }

                if (new Date(selectedRange[0]) >= new Date(selectedRange[1])){
                    props.canNext(false)
                    props.onChange({})
                    useNotification(
                        'Falha!',
                        `Data de início não pode ser maior ou igual a data de fim.`,
                        'danger'
                    )
                    return
                }
                if (selectedRange[0].length == 0 || selectedRange[1].length == 0)
                {
                    props.onChange({}) 
                    return
                }
                literalDays=': '+
                new Date(selectedRange[0]+'T12:00')
                .toLocaleDateString()
                + ' até '
                + new Date(selectedRange[1]+'T12:00')
                .toLocaleDateString()
            }
                
            else if (selectedPeriod == 2){
                var weekdays = weekdayArray
                literalDays=': '+weekdayArray.map(item => JSON.parse(item).value).join(', ')
                if (weekdayArray.length == 0)
                {
                    props.onChange({}) 
                    return
                }
            }
                
            else if (selectedPeriod == 3){
                if (dayArray.length > 1 && typeof(dayArray) !== 'string' ){
                    updateDayArray([])
                    return
                }
                var day = dayArray.toString()
                var month = monthArray
                var literalDays=': ' +monthArray.map(item => JSON.parse(item).value).join(', ') + ' no dia ' + day
                if (monthArray.length == 0 || dayArray.length == 0)
                {
                    props.onChange({}) 
                    return
                }
                
            }    
               
            const dateTimePicker = {
                YEAR: year,
                MONTH: month,
                DAY: day,
                HOURS: hours,
                MINUTES: minutes,
                WEEKDAYS: weekdays,
                PERIOD: selectedPeriod,
                LITERAL: `${periods[selectedPeriod]}${literalDays} às ${hours}:${minutes}`,
                RANGE: selectedRange,
                DATE: date
            }
  
    
            if (hours != '-1' && minutes != '-1')
            props.onChange(dateTimePicker)
    
        }
        changePeriod()
    }, [dayArray, weekdayArray, monthArray, selectedPeriod, selectedHour, selectedRange])

    const handleChangePeriod = (event) => {
        updateSelectedPeriod(event.target.value)
    }
    const handleChangeHour = (event) => {
        updateSelectedHour(event.target.value)
    }

    const handleChangeDay = (event) => {
        const {
          target: { value },
        } = event;
        updateDayArray(
          // On autofill we get a stringified value.
          typeof value === 'string' ? value.split(',') : value,
        );
      };
      const handleChangeWeekday = (event) => {
        const {
          target: { value },
        } = event;
        updateWeekdayArray(
          // On autofill we get a stringified value.
          typeof value === 'string' ? value.split(',') : value,
        );
      };
      const handleChangeMonth = (event) => {
        const {
          target: { value },
        } = event;
        updateMonthArray(
          // On autofill we get a stringified value.
          typeof value === 'string' ? value.split(',') : value,
        );
      };

    const selectFirstDate = (event) => {
        updateSelectedRange([event.target.value, selectedRange[1]])
        var newInc = new Date(event.target.value).getTime()
        var addedHours =  (3600000*24*30)
        const newMaxDate = new Date(newInc+addedHours).toISOString().split('.')[0].slice(0, 10)
        updateMaxDate(newMaxDate)
    }
    const selectLastDate = (event) => {
        updateSelectedRange([selectedRange[0], event.target.value])
    }


    return (
        <>
        {props.time === 'once' && 
        <>
        <h4>Selecione a data e hora da campanha.</h4>
        <Box marginLeft="10px">
            <TextField
                id="dateEndTime"
                size="small"
                label="Data e hora"
                type="datetime-local"
                variant="outlined"
                inputProps={{min:today}}
                defaultValue={newState}
                onChange={changeTime}
                InputLabelProps={{
                    shrink: true,
                }}
            />
        </Box>
            
        </>
        }
        {props.time === 'periodic' &&
        <>
        <h4>Selecione o período da campanha.</h4>
        <div className={"dateSelectorDiv"}>
            <FormControl sx={{ m: 1, minWidth: 250, flex: 1 }}>
                <InputLabel id="simple-select-label-period">Período</InputLabel>
                <Select
                labelId="simple-select-label-period"
                id="simple-select-period"
                label="Período"
                value={selectedPeriod}
                onChange={handleChangePeriod}
                >{periods.map((item, index) => (
                <MenuItem id={item} value={index}>{item}</MenuItem>) 
                )}

                </Select>
            </FormControl>
            {selectedPeriod != -1 &&
            <>
            <FormControl sx={{ m: 1, minWidth: 100, flex:0.3 }}>
                <TextField
                    id="dateHour"
                    label="Hora"
                    type="time"
                    variant="outlined"
                    value={selectedHour}
                    onChange={handleChangeHour}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </FormControl>
            </>
            }

            {(selectedPeriod == 1)  &&
                <>
                    <FormControl className='maxWidth' sx={{ m: 1, minWidth: 200, flex:1 }}>
                        <Box >
                            <TextField
                                id="dateINI"
                                label="Data de início"
                                type="date"
                                variant="outlined"
                                inputProps={{min:todayInDate}}
                                defaultValue={selectedRange[0]}
                                onChange={selectFirstDate}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Box>
                    </FormControl>
                    <FormControl className='maxWidth' sx={{ m: 1, minWidth: 200 , flex:1 }}>
                        <Box >
                            <TextField
                                id="dateEND"
                                label="Data de fim"
                                type="date"
                                variant="outlined"
                                inputProps={{min:selectedRange[0], max:maxDate}}
                                defaultValue={selectedRange[1]}
                                onChange={selectLastDate}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Box>
                    </FormControl>
                </>
                }

            {selectedPeriod == 2 &&
                <>
                <FormControl className='monthPicker' sx={{ m: 1, minWidth: 200, flex:1 }}>
                    <InputLabel id="simple-select-label-weekdays">Dias da semana</InputLabel>
                    <Select
                        labelId="simple-select-label-weekdays"
                        id="simple-select-weekdays"
                        label="Dias da semana"
                        value={weekdayArray}
                        onChange={handleChangeWeekday}
                        multiple={true}
                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                                <Chip key={`weekday${value}`} label={JSON.parse(value).value} />
                            ))}
                            </Box>)}
                    >
                        {possibleWeekdays.map((queue) => (
                            <MenuItem key={`weekdaylist${queue.key}`} value={JSON.stringify(queue)}>
                                {queue.value}
                            </MenuItem>
                        ))}
                    </Select>
                    </FormControl>
                </>
                }
            {selectedPeriod == 3 &&
            <>
            <FormControl className='monthPicker' sx={{ m: 1, minWidth: 200, flex:1 }}>
                <InputLabel id="simple-select-label-months">Meses</InputLabel>
                <Select
                    labelId="simple-select-label-months"
                    id="simple-select-months"
                    label="Meses"
                    value={monthArray}
                    onChange={handleChangeMonth}
                    multiple={true}
                    renderValue={(selected) => (
                        <Box  sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                            <Chip key={`month${value}`} label={JSON.parse(value).value} />
                        ))}
                        </Box>)}
                >
                    {possibleMonths.map((queue) => (
                        <MenuItem key={`monthlist${queue.key}`} value={JSON.stringify(queue)}>
                            {queue.value}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 100, flex:0.3 }}>
                <InputLabel id="simple-select-label-days">Dia</InputLabel>
                <Select
                    labelId="simple-select-label-days"
                    id="simple-select-days"
                    label="Dia"
                    value={dayArray}
                    onChange={handleChangeDay}
                    multiple={false}
                    
                >
                    { possibleDays.map((queue) => (
                        <MenuItem key={`daylist${queue}`} value={queue}>
                            {queue}
                        </MenuItem>
                    ))}
                </Select>
                </FormControl>
            </>
            }
        </div>
        </>
        
        }
        
        </>
    )
}

export default DateTimeSelector