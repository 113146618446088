import React, { useState, useContext, useEffect } from 'react'

import ClientDialog from '../../../Dialogs/Client'
import ActionButton from '../../../Crm/ActionButton'
import useNotification from '../../../Notification'
import AddIcon from '@mui/icons-material/Add';

import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';

import api from '../../../../api';



import { Box, Dialog, DialogTitle, DialogActions, DialogContent, TextField, Button, Divider, FormLabel } from '@mui/material';



const AddLabelDialog = ({ selectedRow, submit, handleClose }) => {
    const [name, setName] = useState('')
    const [color, setColor] = useState('#2196f3')

    const possibleColors = [
        '#f44336', '#e91e63', '#9c27b0', '#673ab7', '#3f51b5', '#2196f3',
        '#03a9f4', '#00bcd4', '#009688', '#4caf50', '#8bc34a', '#cddc39',
        '#ffc107', '#ff9800', '#ff5722', '#795548', '#9e9e9e', '#607d8b'
    ]

    const handleChangeColor = (color) => {
        setColor(color)
    }

    const sendData = () => {

        if (!(possibleColors.includes(color))){
            useNotification(
                'Ops!',
                'Escolha apenas cores listadas abaixo.',
                'danger'
            );
            return
        }

        const data = {
            name:name,
            color:color
        }
        submit(data)
    }

    useEffect(()=>{
        if (selectedRow){
            setName(selectedRow.name)
            setColor(selectedRow.color)
        }
    }, [])

    return (
        <>

            <Dialog keepMounted
                open={true}
                onClose={() => { }}
                aria-labelledby="customized-dialog-title"
                fullWidth
                disablePortal
                disableEnforceFocus
                sx={{position:'absolute'}}
            >
                <DialogTitle id="customized-dialog-title" className={'group-flexbox'}>
                    <span style={{'color':'black'}}>{selectedRow ? "Editar Etiqueta":"Adicionar Etiqueta"}</span>
                    <Button sx={{ 'minWidth': '2rem', 'padding': '0' }} onClick={handleClose} >
                        <CloseIcon />
                    </Button>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <TextField
                        size="small"
                        label="Nome"
                        fullWidth
                        type="text"
                        variant="outlined"
                        value={name}
                        onChange={(event) => {setName(event.target.value)}}
                    />

                    <Box className='color-picker'>
                        <Box className='color-picker-label'>Cor</Box>
                        <Box className='color-picker-flex'>
                        {possibleColors.map((each) => 
                            <Box
                                className={`color-picker-input ${each == color ? 'color-selected' : ''}`}
                                sx={{'backgroundColor': each}}
                                onClick={()=>{setColor(each)}}
                            >
                            </Box>
                        )}
                        </Box>
                    </Box>

                </DialogContent>
                <DialogActions style={{ padding: "10px 20px 10px 10px" }}>
                    <LoadingButton
                        onClick={handleClose}
                        color="primary"
                        loading={false}
                    >
                        Cancelar
                    </LoadingButton>
                    <LoadingButton
                        onClick={sendData}
                        color="primary"
                        loading={false}
                    >
                        {selectedRow ? "Editar":"Adicionar"}
                    </LoadingButton>
                </DialogActions>
            </Dialog>

        </>
    )
}

export default AddLabelDialog