import React from "react";

import ScanQRForm from "../../components/Scout/ScanQRForm";
import AlertMobileVersion from "../../components/Dialogs/AlertMobileVersion";

import { Box } from "@mui/material";

const ScanScoutQR = () => {
  return (
    <Box display="flex">
        <ScanQRForm/>
    </Box>
  );
};

export default ScanScoutQR;

