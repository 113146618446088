import React from "react";
import FormController from "../../components/Forms/ServiceSettings";

const ServiceSettings = () => {

    return (<FormController />);
}

export default ServiceSettings;

