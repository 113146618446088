import React from "react";
import { Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from "prop-types";

const Text = ({ type, text, color, variant }) => {

  const useStyles = makeStyles(() => ({
    blueTitle: {
      color: "#21446C",
      fontWeight: 500,
      fontSize: "3vw",
      marginBottom: "0.5vw",
    },
    whiteTitle: {
      color: "white",
      fontWeight: 500,
      fontSize: "3vw",
      marginBottom: "0.5vw",
    },
    whiteSubtitle: {
      justifyContent: "justify",
      fontSize: "1.1vw",
      color: "white",
    },
    graySubtitle: {
      justifyContent: "justify",
      fontSize: "15px",
      color: "#08090D",
    },
    graySubtitle2: {
      justifyContent: "justify",
      fontSize: "17px",
      color: "#08090D",
      fontWeight: 400,

    }
  }));

  const classes = useStyles();

  return (
    <div>
      <div>
        {type === "title" && color === "blue" && (
          <Typography className={classes.blueTitle} variant="h4">
            {text}
          </Typography>
        )}
        {type === "title" && color === "white" && (
          <Typography className={classes.whiteTitle} variant="h4">
            {text}
          </Typography>
        )}
      </div>
      <div>
        {type === "subTitle" && color === "" && (
          <Typography className={classes.graySubtitle} > {text}</Typography>
        )}
        {type === "subTitle2" && color === "" && (
          <Typography className={classes.graySubtitle2} > {text}</Typography>
        )}
        {type === "subTitle" && color === "white" && (
          <Typography className={classes.whiteSubtitle}>{text}</Typography>
        )}
      </div>
    </div >
  );
};

Text.propTypes = {
  type: PropTypes.string,
  text: PropTypes.string,
  color: PropTypes.string,
  variant: PropTypes.string
};
Text.defaultProps = {
  color: ""
}

export default Text;

