import React from "react";
import MiniDrawer from "../../components/Drawer"
import DashboardGrid from "../../components/Dash"
import KwikDashboard from "../../components/Dash/indexv3";

const Dashboard = (socket) => {

    return (
        <MiniDrawer child={<KwikDashboard />} socket={socket.socket} />

    );
};

export default Dashboard;
