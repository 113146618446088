import React from 'react';

import Frame from '../Frame';

const FrameOngoing = ({ gridArea, onGoingChats }) => {
  return (
    <Frame gridArea={gridArea} title="Chats (fila/atendimento)">
      {onGoingChats}
    </Frame>
  );
}

export default FrameOngoing;