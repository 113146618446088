import React from 'react'
import SettingsIntermediate from '../SettingsIntermediate'
import userPermissions from '../../User/Permissions';
import { getSession } from '../../../auth';
import Integrations from '../../Settings/Integrations';
import ExternalIntegrations from '../../Settings/Integrations/external';
import KwikApiKey from '../../Settings/Integrations/kwik-api-key';


const IntegrationPage = () => {
    const groups = userPermissions();
    const enterprise = getSession()?.profile?.enterprise
    return (
        <>
            <SettingsIntermediate title='Integrações' components={() => ({
                comp1:{
                    permissions: true,
                    style:'title',
                    label:'Integrações'
                },
                comp2:{
                    permissions: groups.isAdmin,
                    style:'list',
                    label: 'Eventos',
                    sublabel: 'Eventos pré-programados do Kwik.',
                    redirect: (<Integrations enterpriseId={enterprise?.id}/>),
                    isVisible: enterprise.integration
                },
                comp3:{
                    permissions: groups.isAdmin,
                    style:'list',
                    label: 'Chave de API do Kwik',
                    sublabel: 'Chave utilizada para se comunicar com a API Pública do Kwik.',
                    redirect: (<KwikApiKey />)
                },
                comp4:{
                    permissions: groups.isAdmin,
                    style:'list',
                    label: 'Externas',
                    sublabel: 'Integrações prontas para CRMs. Ex. Zendesk, Hubspot.',
                    redirect: (<ExternalIntegrations enterpriseId={enterprise?.id}/>),
                    isVisible: enterprise.integration
                },
            })
            } />
        </>
    )
}


export default IntegrationPage
