import React, { useState, createContext, useEffect } from 'react'

import { Box, Button } from '@mui/material'
import Vivre from '../Vivre'
import { getSession } from '../../../auth'
import './styles.css'
import Loading from '../../Loading'
import BuilderMessages from './BuilderMessages'
import BuilderSearch from './BuilderSearch'
import BuilderChecktime from './BuilderChecktime'
import BuilderDescriptors from './BuilderDescriptors'

export const constructorContext = createContext()
{/* <Vivre has_voice={has_voice} /> */ }

const Options = () => {
    return ({
        bot: (<Vivre />),
        msg: (<BuilderMessages />),
        search: (<BuilderSearch />),
        checktime: (<BuilderChecktime />),
        descriptors: (<BuilderDescriptors />)
    })    
}

const Buttons = {
    bot: 'Fluxos',
    msg: 'Mensagens',
    search: 'Pesquisas',
    checktime: 'Horários de atendimento',
    descriptors: 'Descritores'
}

const Builder = ({ has_voice }) => {
    const initialTab = localStorage.getItem('builder-selected-tab') ? localStorage.getItem('builder-selected-tab') : 'bot'
    const [selectedTab, setSelectedTab] = useState(initialTab)
    const [activeTab, updateActiveTab] = useState(Number(localStorage.getItem('builder-active-tab')))
    const [loading, setLoading] = useState(false)
    const enterpriseId = getSession().profile.enterprise.id

    useEffect(()=>{
        localStorage.setItem('builder-active-tab', activeTab)
    }, [activeTab])

    useEffect(()=>{
        localStorage.setItem('builder-selected-tab', selectedTab)
    }, [selectedTab])


    return (
        <constructorContext.Provider value={{ activeTab, has_voice, enterpriseId, loading, setLoading }}>
            <Loading loading={loading} />
            <Box className='BuilderContainer'>
                <Box className='BuilderSelectorContainer'>
                    {has_voice ?
                        <Box className='BuilderBotSelectorBox'>
                            <Button
                                variant={activeTab === 0 ? 'contained' : ''}
                                onClick={() => updateActiveTab(0)}
                            >
                                CHATBOT
                            </Button>
                            <Button
                                variant={activeTab === 1 ? 'contained' : ''}
                                onClick={() => updateActiveTab(1)}
                            >
                                VOZ
                            </Button>
                        </Box>
                        :
                        null}
                    <Box className='BuilderSelectorBox'>
                        {Object.entries(Buttons).map(([key, value]) => (
                            <Button
                                variant={key === selectedTab ? 'contained' : ''}
                                onClick={() => setSelectedTab(key)}>
                                {value}
                            </Button>
                        ))}
                    </Box>
                </Box>
                <Box className='BuilderContent'>
                    {Options()[selectedTab]}
                </Box>
            </Box>
        </constructorContext.Provider>
    )

}


export default Builder