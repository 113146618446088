import React, {useContext, useEffect} from "react";

import MiniDrawer from "../../components/Drawer"
import ChatGrid from "../../components/Chat"
import { QueueSocket } from "../../routes";

const Chat = (socket) => {

    const {connections} = useContext(QueueSocket)

    return (
        <MiniDrawer child={<ChatGrid connections={connections}/>} socket={socket.socket}  />
    );
};

export default Chat;
