import React, { useState, useContext } from 'react'

import LoadingButton from '@mui/lab/LoadingButton'

import Templates from '../../Templates'

import SendIcon from '@mui/icons-material/Send'

import SelectChannel from './SelectChannel'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import SmsIcon from '@mui/icons-material/Sms';

import { ScreenContext } from '../..'
import SendSMS from './SendSMS'

const channels = (props) => {
    return {
        'whatsapp': {
            icon: (<><WhatsAppIcon className="conversationItemIcon whatsAppIcon" /></>),
            component: (<Templates
                open={true}
                templates={props.templates}
                queues={props.queues}
                clients={props.clients}
                selectionIds={props.selectedModel}
                originNumbers={props.originNumbers}
                handleClose={() => {props.setSelectedChannel(); props.setShowSelect()}}
                handlePostSuccess={props.submit}
                selectedNumberModel={props.selectedNumberModel}

            />)
        },
        'sms': {
            icon: (<><SmsIcon className="conversationItemIcon" sx={{ color: '#757575' }} /></>),
            component: (<>
                <SendSMS
                    queues={props.queues}
                    selectedNumberModel={props.selectedNumberModel}
                    handleClose={() => {props.setSelectedChannel(); props.setShowSelect()}}
                />
            </>)
        }
    }
}

const SendMessage = () => {
    const {
        originNumbers,
        clients,
        queues,
        templates,
        selectedModel,
        selectedNumberModel,
        updateNumberSelectedModel,
        updateReady
    } = useContext(ScreenContext)

    const [showSelect, setShowSelect] = useState(false)

    const [selectedChannel, setSelectedChannel] = useState()

    const submit = async () => {
        updateReady(false)
        updateNumberSelectedModel([])
        updateReady(true)
    }

    const loadChannels = channels({
        templates, queues, clients, selectedModel, originNumbers, setSelectedChannel, submit, selectedNumberModel, setShowSelect
    })

    return (
        <>
            {showSelect && <SelectChannel
                handleClose={() => setShowSelect(false)}
                channels={loadChannels}
                setSelected={setSelectedChannel}
            />}
            {selectedChannel && loadChannels[selectedChannel]?.component}
            <LoadingButton
                className="button-notify nomargin"
                onClick={() => { setShowSelect(true) }}
                variant={"contained"}
                color="warning"
                disabled={selectedModel.length === 0}
                startIcon={<SendIcon />}
            >
                Enviar mensagem
            </LoadingButton>
        </>
    )
}

export default SendMessage