import React from "react";
import PropTypes from "prop-types";
import { Card, CardContent, Typography } from '@mui/material';

const Poll = ({question, options}) => {
   return (
    <Card sx={{ maxWidth: 400, margin: '0 auto', mt: 4 }}>
      <CardContent>
        <Typography variant="h5" component="div" gutterBottom>
          Enquete 
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          {question}
        </Typography>
        {options.map((option, index) => (
            <Typography key={index} variant="body2" color="textSecondary">
            {option.optionName}
          </Typography>
        ))}
      </CardContent>
    </Card>
  );
}

Poll.PropTypes = {
    question: PropTypes.string,
    options: PropTypes.array,
};

export default Poll;