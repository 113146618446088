import React, { useContext, useState } from 'react'

import { ScreenContext } from '..';

import Information from './Information';
import SendMessage from './SendMessage';
import AddClient from './AddClient';
import ImportClients from './ImportClients';
import NotificationFilter from './NotificationFilter';
import CreateCampaign from './CreateCampaign';
import { getSession } from "../../../auth";

const TabButtons = () => {
    const { isSmallScreen, selectedTab, settings, permissions } = useContext(ScreenContext)

    const canCreateCampaigns = (permissions.isAdmin || permissions.isSupervisor) ? true : settings.agent_can_create_campaigns

    const user = getSession()

    switch (selectedTab) {
        case 'clients':
            return (
                <>
                    <SendMessage />
                    {/* <AddClient />
                    {!isSmallScreen ? <ImportClients /> : null}
                    {!isSmallScreen || (settings.is_financial_market_company && user.profile.isAdmin)? <Information is_financial_market_company={settings.is_financial_market_company} /> : null} */}
                </>
            )
        case 'notifications':
            return (
                <>
                    {!isSmallScreen ?
                        <NotificationFilter />
                        : null}
                </>
            )
        case 'campaigns':
            return (
                <>
                    {!isSmallScreen && canCreateCampaigns ?
                        <CreateCampaign />
                        : null}
                </>
            )
        default:
            return (<div></div>)
    }


}

export default TabButtons
