import React from 'react'
import { Box, Tooltip } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles';
import GetAppIcon from '@mui/icons-material/GetApp';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import AssignmentIcon from '@mui/icons-material/Assignment';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import ImageIcon from '@mui/icons-material/Image';
import TocIcon from '@mui/icons-material/Toc';
import FolderZipIcon from '@mui/icons-material/FolderZip';

export const iconDictionary = {
    'xlsx': (<TocIcon color='primary' />),
    'pdf': (<PictureAsPdfIcon color='primary' />),
    'text': (<AssignmentIcon color='primary' />),
    'video': (<OndemandVideoIcon color='primary' />),
    'image': (<ImageIcon color='primary' />),
    'zip': (<FolderZipIcon color='primary' />)
}

const useStyles = makeStyles((theme) => {
    return {
        root: {
            maxWidth: '175px',
            backgroundColor: '#f1f0f7',
            borderRadius: '100px',
            margin: '5px 0',
            height: '40px'
        }
    }
})

const AttachmentItem = ({ data }) => {

    const classes = useStyles();

    return (
        <Box className={classes.root}
            display="flex"
            alignItems="center"
            width="100%"
            justifyContent="space-around"
        >
            {/* <InsertDriveFileIcon color="primary" /> */}
            {iconDictionary[data.icon]}
            <Tooltip title={data.filename}><div className='attachment-email-item'>{data.filename}</div></Tooltip>
            <a target="_blank" href={data.acess_directory}>
                <GetAppIcon color="primary" />
            </a>
        </Box>

    )
}

export default AttachmentItem