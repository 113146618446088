import React, { useState, useEffect, useContext } from 'react'

import {
    DataGridPro,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    gridClasses,
    LicenseInfo,
    ptBR
} from '@mui/x-data-grid-pro';

import {
    createTheme,
    ThemeProvider,
    StyledEngineProvider
} from '@mui/material/styles';

import { ptBR as corePtBR } from '@mui/material/locale';

import { Box, Tooltip, Button } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import useNotification from '../../../Notification';
import DeleteIcon from '@mui/icons-material/Delete';

import api from '../../../../api';
import ConfirmationDialogCaptcha from '../../../Dialogs/ConfimationDialogCaptcha';

import LoadingButton from '@mui/lab/LoadingButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import UsersQueueEditModal from './UsersQueueEditModal';
import Loading from '../../../Loading';
import userPermissions from '../../../User/Permissions';
import { getSession } from '../../../../auth';

import {
    stringOperators,
    choiceOperators
} from "../../../../utils/filterOperators";

const theme = createTheme({
    palette: {
        primary: { main: '#21446C' },
    },
}, ptBR, corePtBR);

const UsersQueue = ({ enterpriseId }) => {

    const groups = userPermissions();
    const [rows, setRows] = useState([])
    const [users, setUsers] = useState([])
    const [deleteModal, setDeleteModal] = useState()
    const [loading, setLoading] = useState(false)
    const [editModal, setEditModal] = useState()

    const has_voice = getSession()?.profile?.enterprise?.has_voice

    const getURL = () => {
        let url = `api/enterprises/${enterpriseId}/queues/`
        return url
    }

    const getUsers = async () => {
        let url = `api/enterprises/${enterpriseId}/users/`
        const res = await api.get(url, { timeout: 0 })
        if (res.status == 200) {
            setUsers(res.data)
        }
    }

    const getRows = async () => {
        setLoading(true)
        getUsers()
        const pre_chat = api.get(getURL() + 'chat/', { timeout: 0 })
        const pre_voice = has_voice ? api.get(getURL() + 'voice/', { timeout: 0 }) : null
        const [res_chat, res_voice] = await Promise.all([pre_chat, pre_voice])
        let res_rows = []
        setLoading(false)

        if (res_chat.status == 200) {
            res_chat.data.map(each => { each.type = 'Chat'; each.identifier = `${each.id}-c` })
            res_rows = [...res_rows, ...res_chat.data.filter(each => !each.disabled)]
        }

        if (res_voice?.status == 200) {
            res_voice.data.map(each => { each.type = 'Voz'; each.identifier = `${each.id}-v` })
            res_rows = [...res_rows, ...res_voice.data]
        }

        setRows(res_rows)
    }

    useEffect(() => {
        getRows()
    }, [])

    const submitDelete = async () => {
        const type = deleteModal.type == 'Chat' ? 'chat/' : 'voice/'
        let url = `api/enterprises/${enterpriseId}/queues/${type}${deleteModal.id}/`

        setLoading(true)
        const res = await api.delete(url, { timeout: 0 })
        setLoading(false)

        if (res.status == 204) {

            setDeleteModal()
            getRows()

            useNotification(
                'Sucesso!',
                'Fila deletada com sucesso.',
                'success'
            )
            return
        }
        let errorMessage = 'Não foi possível excluir a fila.'
        if (res.status == 500) {
            if (res.data?.error?.pop() === 10)
                errorMessage = 'Não é possivel deixar um agente sem fila.'
        }
        useNotification(
            'Ops!',
            errorMessage,
            'danger'
        )
    }


    const CustomToolbar = () => {

        return (
            <GridToolbarContainer className={gridClasses.toolbarContainer}>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                {groups.isAdmin &&
                    <LoadingButton
                        size="small"
                        component="label"
                        startIcon={<AddCircleIcon />}
                        onClick={() => { setEditModal({}) }}
                    >
                        Adicionar
                    </LoadingButton>}
            </GridToolbarContainer>
        );
    }

    const defColumns = [
        { field: 'description', headerName: 'Fila', flex: 1, filterOperators: stringOperators },
        // { field: 'name', headerName: 'Código', flex: 1 },
        { field: 'type', headerName: 'Tipo', flex: 1, filterOperators: choiceOperators, valueOptions: ['Chat', 'Voz'] },
        {
            field: 'name', headerName: 'Código', flex: 1, filterOperators: stringOperators,
            valueGetter: ({ value }) => {
                return value?.split('_').pop()
            }
        },
        {
            field: 'action', headerName: 'Ações', filterable: false, sortable: false,
            renderCell: (params) => (
                <>
                    {(groups.isAdmin || params.row.type !== 'Voz') &&
                        <Tooltip title="Editar" >
                            <Button
                                className="action-button"
                                onClick={() => { setEditModal(params.row) }}
                            >
                                <EditIcon />
                            </Button>
                        </Tooltip>}
                    {groups.isAdmin &&
                        <Tooltip title="Excluir Fila">
                            <Button className="action-button delete"
                                onClick={() => { setDeleteModal(params.row) }}>
                                <DeleteIcon />
                            </Button>
                        </Tooltip>}
                </>
            ),
        },
    ]

    return (
        <>

            {editModal ?
                <UsersQueueEditModal
                    data={editModal}
                    setData={setEditModal}
                    getRows={getRows}
                    enterpriseId={enterpriseId}
                    setLoading={setLoading}
                    users={users}
                    has_voice={has_voice}
                />
                :
                null}
            {deleteModal ?
                <ConfirmationDialogCaptcha open={true}
                    title="Excluir Fila"
                    description={
                        <span>
                            Para confirmar a ação, digite: <span style={{ 'fontWeight': 'bold' }}>{deleteModal.description}</span> no campo abaixo.</span>}
                    handleClose={() => { setDeleteModal() }}
                    handleSubmit={() => { submitDelete() }}
                    captcha={deleteModal.description}
                />

                :
                null
            }

            <Loading loading={loading} />
            <Box className="settings-body-bot">
                <StyledEngineProvider injectFirst >
                    <ThemeProvider theme={theme}>
                        <DataGridPro
                            getRowId={(row) => row.identifier}
                            pageSize={100}
                            pagination
                            disableSelectionOnClick
                            disableDensitySelector
                            density="compact"
                            columns={defColumns}
                            rows={rows}
                            components={{
                                Toolbar: CustomToolbar,
                            }}
                        />
                    </ThemeProvider>
                </StyledEngineProvider>
            </Box>

        </>
    )
}

export default UsersQueue