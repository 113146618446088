import { StyleSheet } from "@react-pdf/renderer";

export const styles = StyleSheet.create({
  page: {
    backgroundColor: "white",
    color: "black",
    margin: '10pt',
    padding: '10pt',
    display: 'flex'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginBottom: '20pt'
  },
  logoKwik: {
    width: '100%',
    maxWidth: '150pt',
    height: '100%',
    objectFit: 'contain',
  },
  logoEnterprise: {
    width: '100%',
    maxWidth: '150pt',
    height: '100%',
    objectFit: 'contain',
    position: 'absolute',
    right: 10,
    top: 5
  },
  graphics: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: '20pt'
  },
  chart: {
    width: '270pt',
    height: '135pt',
    marginTop: '17pt',
    marginLeft: '5pt'
  },
  filters: {
    fontSize: '11pt',
    marginLeft: '5pt'
  },
  filter: {
    marginTop: '10pt'
  },
  filterInterval: {
    marginTop: '20pt'
  },
  footer: {
    marginTop: '20pt',
    fontSize: '11pt',
    position: 'absolute',
    bottom: 30,
    right: 30
  }
});