import React from "react";

import { Button, IconButton } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

import SendIcon from '@mui/icons-material/Send';


export default function ResponsiveSendMessageButton({ className, onClickFn, isDisabled }) {
  const isSmallScreen = useMediaQuery('(max-width: 60rem)');

  return (
    <>
      {isSmallScreen ? (
        <IconButton
          className={className}
          onClick={onClickFn}
          size="small"
          disabled={isDisabled}
        >
          <SendIcon />
        </IconButton>
      ) : (
        <Button
          className={className}
          onClick={onClickFn}
          disabled={isDisabled}
          color="warning"
          variant="contained"
        >
          Enviar Mensagem
        </Button>
      )}
    </>
  )
}
