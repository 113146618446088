import React from "react";
import { Grid, TextField, Box } from "@mui/material";
import HeaderForm from "../../HeaderForm"
import OperatorImage from "../../../../assets/images/attendance.svg"
import Checkbox from "../../../Button/Checkbox";
import Typography from "../../../Typography"
import InlineButton from "../../../Button/InlineButton"
import PropTypes from "prop-types";
import RemoveButton from "../../../Button/RemoveButton";

const OperatorsForm = ({ values, handleChange, setFieldValue }) => {

    function addNewOperator() {
        if (values.agents.length < 5) {
            const agents = Array.from(values.agents
            );
            agents.push({
                number: "",
                name: "",
                email: "",
                groups: values.groups,
            });
            setFieldValue("agents", agents);
        }
        return null;
    }

    function removeOperator(index) {
        const agents = Array.from(values.agents);
        agents.splice(index, 1);
        setFieldValue("agents", agents);
        return null;
    }

    function addGroups() {


        return null;
    }


    return (
        <Grid container spacing={2}>
            <HeaderForm img={OperatorImage} title="PERSONALIZE SEU ATENDIMENTO" subtitle="Agentes" width="80%" />
            <Box paddingBottom={3}>
                <Typography color="lightGray" text="Agora vamos registrar seus operadores. Preencha abaixo o nome, um código de identificação e um e-mail para cada um dos seus atendentes. Após confirmação será enviando um e-mail para o atendente convidando-o a utilizar a plataforma.   " />
            </Box>
            {values.agents.map((operator, i) => (
                <Box key={i} paddingBottom={5}>
                    <Grid container spacing={2} >
                        <Grid item lg={7} md={7} sm={12} xs={12}>
                            <TextField
                                id=""
                                label="Nome do operador"
                                variant="outlined"
                                size="small"
                                name={"agents[" + i + "].name"}
                                onChange={handleChange}
                                value={operator.name}
                                fullWidth
                            />
                        </Grid>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <TextField
                                id=""
                                label="Código do operador"
                                variant="outlined"
                                size="small"
                                name={"agents[" + i + "].number"}
                                onChange={handleChange}
                                value={operator.number}
                                fullWidth
                            />
                        </Grid>
                        <Grid item lg={1} md={1} sm={12} xs={12}>
                            {i > 0 && (<Box paddingTop={1.2}><RemoveButton onClick={() => removeOperator(i)} /></Box>)}
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <TextField
                                id=""
                                label="E-mail do operador"
                                variant="outlined"
                                size="small"
                                name={"agents[" + i + "].email"}
                                onChange={handleChange}
                                value={operator.email}
                                fullWidth
                            />
                        </Grid>
                        {operator.groups.map((group, j) => (
                            <Grid item lg={2} md={2} sm={6} xs={12} key={i}>
                                {group.number != "" && (
                                    <Checkbox
                                        id=""
                                        name={"agents[" + i + "].groups[" + j + "].checked"}
                                        label={group.description}
                                        checked={group.checked}
                                        onChange={handleChange}
                                    />
                                )}
                            </Grid>))}
                    </Grid>
                </Box>))}
            {values.agents.length < 5 && (
                <Grid container spacing={0}>
                    <Grid item lg={8} md={6} sm={12} xs={12}></Grid>
                    <Grid item lg={3} md={5} sm={12} xs={12}>
                        <InlineButton text="+ Adicionar agente" onClick={() => addNewOperator()} />
                    </Grid>
                    <Grid item lg={1} md={1} sm={12} xs={12}></Grid>
                </Grid >)}
        </Grid >
    );
};

OperatorsForm.propTypes = {
    values: PropTypes.object,
    handleChange: PropTypes.func,
    setFieldValue: PropTypes.func
}

export default OperatorsForm;
