import React, { useState, useEffect, useContext, useReducer } from 'react'

import {
    Box,
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogTitle,
    Tooltip, 
    Button, 
    TextField, 
    Divider, 
    Select, 
    MenuItem, 
    InputLabel, 
    FormControl, 
    List, 
    ListItem, 
    IconButton,
    Switch
} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import HelpIcon from '@mui/icons-material/Help';

import api from '../../../api';

import useNotification from '../../Notification';
import defaultReducer from '../../../utils/defaultReducer';
import IntegerOnly from '../../../utils/IntegerOnly';
import MultipleSelections from '../../Input/MultipleSelections';
import VariableTextField from '../../Input/VariableTextField';

import { getSession } from '../../../auth';

import './styles.css'

const action = (data) => {
    if (data?.id)
        return 'Editar'
    return 'Adicionar'
}

const treatnumber = (number) => {
    if (number.length > 0 && number[0] === '+')
        return number
    return `+${number}`
}

const EditEscalation = ({ data, allQueues, rows, setParentState, method, allChatBots }) => {

    const [state, setState] = useReducer(defaultReducer, {
        description      : '',
        chatBotsChanged  : false,
        queuesChanged    : false,
        chatBotsChecked  : {},
        queuesChecked    : {},
        sendQueueMessage : false,
        messagesChanged  : false,
        chatBotMessages: [{
            timeout : null,
            message : null
        }],
        queueMessages: [{
            timeout : null,
            message : null
        }],
        sendOnChatMessage: false,
        onChatMessages: [{
            timeout : null,
            message : null
        }],
        escalate          : true,
        escalateTime      : null,
        escalateQueue     : 0,
        escalateMessage   : null,
        queueKey          : 0,
        onChatKey         : 0,
        escalateKey       : 0,
        chatbotEscalation : true
    })

    const checkButtonText = () => {
        const text = []

        if (! state.description)
            text.push(<Box>Digite a descrição da jornada.</Box>)

        if (state.chatbotEscalation && Object.keys(state.chatBotsChecked).filter(key => state.chatBotsChecked[key].checked).length === 0)
            text.push(<Box>Escolha pelo menos um chatbot.</Box>)

        if (! state.chatbotEscalation && Object.keys(state.queuesChecked).filter(key => state.queuesChecked[key].checked).length === 0)
            text.push(<Box>Escolha pelo menos uma fila de atendimento.</Box>)

        if (state.escalate && (! state.escalateTime || ! state.escalateQueue))
            text.push(<Box>Digite o tempo e escolha uma fila para transbordo.</Box>)

        if (text.length == 0)
            return ''

        return (text)
    }

    const changeSendChatBotMessage = () => {
        setState({ 
            messagesChanged    : true,
            sendChatBotMessage : ! state.sendChatBotMessage 
        })
    }

    const changeSendQueueMessage = () => {
        setState({ 
            messagesChanged  : true,
            sendQueueMessage : ! state.sendQueueMessage 
        })
    }

    const changeSendOnChatMessage = () => {
        setState({ 
            messagesChanged   : true,
            sendOnChatMessage : ! state.sendOnChatMessage 
        })
    }

    const changeEscalate = () => {
        setState({ 
            escalate        : ! state.escalate,
            escalateTime    : null,
            escalateQueue   : 0,
            escalateMessage : null,
        })
    }

    const getSelectedChatBots = () => {
        if (allChatBots) {
            const chatBots = Object.keys(state.chatBotsChecked).filter((chatBot) => {
                return state.chatBotsChecked[chatBot].checked;
            });
            return chatBots;
        }
        return [];
    }

    const getSelectedData = () => {
        if (allQueues) {
            const queues = Object.keys(state.queuesChecked).filter((queue) => {
                return state.queuesChecked[queue].checked;
            });
            return queues;
        }
        return [];
    }

    const getPayload = () => {
        const payload = { "description": state.description }

        if (state.chatbotEscalation) {
            // Adding chatbot settings
            if (state.chatBotsChanged) {
                payload["chatbots_changed"] = state.chatBotsChanged
                payload["chatbots"] = Object.keys(state.chatBotsChecked).filter(key => state.chatBotsChecked[key].checked)
            }

            // Adding escalate chatbot messages
            if (state.messagesChanged) {
                payload["messages_changed"] = state.messagesChanged
                if (state.sendChatBotMessage) {
                    if (! ('messages' in payload))
                        payload['messages'] = []
                    state.chatBotMessages.forEach((message) => {
                        if (message.timeout && message.message) {
                            payload['messages'].push({
                                timeout : message.timeout * 60,
                                message : message.message,
                                status  : 'CHATBOT'
                            })  
                        }
                    })
                }
            }
        }

        if (! state.chatbotEscalation) {
            // Adding queue settings
            if (state.queuesChanged) {
                payload["queues_changed"] = state.queuesChanged
                payload["queues"] = Object.keys(state.queuesChecked).filter(key => state.queuesChecked[key].checked)
            }

            // Adding escalate queue messages
            if (state.messagesChanged) {
                payload["messages_changed"] = state.messagesChanged
                if (state.sendQueueMessage) {
                    if (! ('messages' in payload))
                        payload['messages'] = []
                    state.queueMessages.forEach((message) => {
                        if (message.timeout && message.message) {
                            payload['messages'].push({
                                timeout : message.timeout * 60,
                                message : message.message,
                                status  : 'QUEUE'
                            })  
                        }
                    })
                }
            }

            // Adding escalate onchat messages
            if (state.messagesChanged) {
                payload["messages_changed"] = state.messagesChanged
                if (state.sendOnChatMessage) {
                    if (! ('messages' in payload))
                        payload['messages'] = []
                    state.onChatMessages.forEach((message) => {
                        if (message.timeout && message.message) {
                            payload['messages'].push({
                                timeout : message.timeout * 60,
                                message : message.message,
                                status  : 'ONCHAT'
                            })
                        }
                    })
                }   
            }   
        }

        // Adding escalate settings
        payload["timeout"] = state.escalateTime * 60
        payload["destination_queue"] = state.escalateQueue
        payload["message"] = state.escalateMessage

        if (payload["destination_queue"] === 0)
            payload["destination_queue"] = null

        // Check queues and destination queue, setup queue cannot be the same as the destination queue
        let isValid = true
        Object.keys(state.queuesChecked).filter(key => state.queuesChecked[key].checked).forEach((queue) => {
            if (state.escalateQueue === queue) {
                isValid = false
                useNotification(
                    'Ops!',
                    'A fila e transbordo não pode ser uma das filas de atendimento configuradas.',
                    'warning'
                )
            }
        })

        if (! isValid)
            return

        let environMinTime = 1*60
        const environment = window.location.href
        if (environment.includes("kwik.app.br"))
            environMinTime = 5*60

        if (payload["timeout"] < (environMinTime)) {
            isValid = false
            useNotification(
                'Ops!',
                'O tempo mínimo para configuração é de 5 minutos.',
                'warning'
            )
            return
        }

        if ('messages' in payload) {
            payload['messages'].forEach((each) => {
                if (each['timeout'] < (environMinTime)) {
                    isValid = false
                    useNotification(
                        'Ops!',
                        'O tempo mínimo para configuração é de 5 minutos.',
                        'warning'
                    )
                    return   
                }
            })   
        }

        if (! isValid)
            return

        return payload
    }

    const submit = async () => {
        const enterpriseId = getSession().profile.enterprise.id
        const payload = getPayload()

        if (! payload)
            return

        let response

        if (method === 'POST')
            response = await api.post(`api/enterprises/${enterpriseId}/escalation/`, payload)
        else
            response = await api.patch(`api/enterprises/${enterpriseId}/escalation/${data.id}/`, payload)
        
        if (response.status !== 201 && response.status !== 200) {
            if (response.status === 400) {
                // Add 400 errors messages here
                useNotification(
                    'Ops!',
                    'Já existe uma jornada configurada para uma das filas de atendimento selecionadas.',
                    'warning'
                )
                return
            }
            useNotification(
                'Ops!',
                'Ocorreu um erro na requisição.',
                'danger'
            )
            return
        }

        if (method === 'POST') {
            useNotification(
                'Sucesso!',
                'Sua jornada de atendimento foi criada com sucesso.',
                'success'
            )

            setParentState({
                rows: [...rows, response.data],
                editEscalation: null
            })
            return
        }

        useNotification(
            'Sucesso!',
            'Sua jornada de atendimento foi atualizada com sucesso.',
            'success'
        )

        const updatedRows = [ ...rows ]
        updatedRows.forEach((value, key) => {
            if (value.id == response.data['id'])
                updatedRows[key] = response.data
        })

        setParentState({
            rows: updatedRows,
            editEscalation: null
        })
    }

    useEffect(async () => {
        if (Object.keys(data).length === 0)
            setState({ 
                queuesChecked   : allQueues,
                chatBotsChecked : allChatBots,
            })
    }, [allQueues, allChatBots])

    useEffect(async () => {
        if (! data.id)
            return

        const queuesChecked = { ...allQueues }
        Object.keys(queuesChecked).forEach((key) => {
            queuesChecked[key].checked = false
            data.queues.forEach((queue) => {
                if (key === queue.queue)
                    queuesChecked[key].checked = true
            })
        })

        const chatBotsChecked = { ...allChatBots }
        Object.keys(chatBotsChecked).forEach((key) => {
            chatBotsChecked[key].checked = false
            data.chatbots.forEach((chatbot) => {
                if (key === chatbot.chatbot_id)
                    chatBotsChecked[key].checked = true
            })
        })

        const chatBotMessages = [] 
        const queueMessages   = [] 
        const onChatMessages  = []
        if (data.messages.length !== 0) {
            let row
            data.messages.forEach((each) => {
                row = {...each}
                row['timeout'] = Math.trunc(row['timeout'] / 60)
                switch (each.status) {
                    case 'CHATBOT':
                        chatBotMessages.push(row)
                        break
                    case 'QUEUE':
                        queueMessages.push(row)
                        break
                    case 'ONCHAT':
                        onChatMessages.push(row)
                        break
                    default:
                        break
                }
            })
        } 

        let hasChatBotMessages = (chatBotMessages.length !== 0)
        let hasQueueMessages   = (queueMessages.length !== 0)
        let hasOnChatMessages  = (onChatMessages.length !== 0)
        
        if (chatBotMessages.length === 0)
            chatBotMessages.push({ timeout: null, message: null })
        if (queueMessages.length === 0)
            queueMessages.push({ timeout: null, message: null })
        if (onChatMessages.length === 0)
            onChatMessages.push({ timeout: null, message: null })

        let escalate = false
        if (data.timeout && data.destination_queue)
            escalate = true

        setState({
            description        : data.description,
            chatBotsChecked    : chatBotsChecked,
            queuesChecked      : queuesChecked,
            sendChatBotMessage : hasChatBotMessages,
            chatBotMessages    : chatBotMessages,
            sendQueueMessage   : hasQueueMessages,
            queueMessages      : queueMessages,
            sendOnChatMessage  : hasOnChatMessages,
            onChatMessages     : onChatMessages,
            escalate           : escalate,
            escalateTime       : Math.trunc(data.timeout / 60),
            escalateQueue      : data.destination_queue,
            escalateMessage    : data.message,
            renderKey          : `multiple-selections-${Math.random()}`,
            chatbotEscalation  : data.chatbots.length !== 0
        })
    }, [data])

    const updateChatBotMessage = (id, value, type, variable=false) => {
        const messages = state.chatBotMessages
        messages.forEach((each, index) => {
            if (index === id) {
                if (variable)
                    each[type] = each[type]+value
                else
                    each[type] = value
            }
        })
        setState({ 
            messagesChanged : true,
            chatBotMessages   : messages
        })
    }

    const updateQueueMessage = (id, value, type, variable=false) => {
        const messages = state.queueMessages
        messages.forEach((each, index) => {
            if (index === id) {
                if (variable)
                    each[type] = each[type]+value
                else
                    each[type] = value
            }
        })
        setState({ 
            messagesChanged : true,
            queueMessages   : messages
        })
    }

    const removeQueueMessage = (id) => {
        if (state.queueMessages.length === 1)
            return
        setState({ 
            messagesChanged : true,
            queueMessages   : state.queueMessages.filter((message, index) => index !== id) 
        })
    }

    const removeChatBotMessage = (id) => {
        if (state.chatBotMessages.length === 1)
            return
        setState({ 
            messagesChanged : true,
            chatBotMessages : state.chatBotMessages.filter((message, index) => index !== id) 
        })
    }

    const addChatBotMessageRow = () => {
        setState({ chatBotMessages: [...state.chatBotMessages, {
            timeout : null,
            message : null
        }]})
    }

    const addQueueMessageRow = () => {
        setState({ queueMessages: [...state.queueMessages, {
            timeout : null,
            message : null
        }]})
    }

    const updateOnChatMessage = (id, value, type, variable=false) => {
        const messages = state.onChatMessages
        messages.forEach((each, index) => {
            if (index === id)
                if (variable)
                    each[type] = each[type]+value
                else
                    each[type] = value
        })
        setState({
            messagesChanged : true, 
            onChatMessages  : messages
        })
    }

    const removeOnChatMessage = (id) => {
        if (state.onChatMessages.length === 1)
            return
        setState({ 
            messagesChanged : true,
            onChatMessages  : state.onChatMessages.filter((message, index) => index !== id) 
        })
    }

    const addOnChatMessageRow = () => {
        setState({ onChatMessages: [...state.onChatMessages, {
            timeout : null,
            message : null
        }]})
    }

    return (
        <>
            <Dialog open={true}
                fullWidth
                disablePortal
                disableEnforceFocus
                sx={{ position: 'absolute' }}
                maxWidth={'md'}
            >
                <DialogTitle id="customized-dialog-title" className={'group-flexbox'}>
                    <span>
                        {action(data)} Jornada de Atendimento
                        <Tooltip title="São configurações que podem ser criadas para tratar atendimentos abandonados, quer seja por conta do usuário deixar de responder o chatbot, ou mesmo por ficar esperando em fila (ou atendimento) por muito tempo.">
                            <HelpIcon sx={{ marginBottom: "-4px", marginLeft: ".5rem" }}/>
                        </Tooltip>
                    </span>
                    <Tooltip title="Fechar">
                        <Button className="action-button">
                            <CloseIcon onClick={() => setParentState({ editEscalation: null })} />
                        </Button>
                    </Tooltip>
                </DialogTitle>
                <Divider />
                <DialogContent className='piloto-modal-flex'>
                    <Box className="category-title">
                        Descrição
                    </Box>
                    <TextField
                        fullWidth
                        size="small"
                        label="Descrição"
                        type="text"
                        variant="outlined"
                        value={state.description}
                        onChange={(event) => setState({ description: event.target.value })}
                    />
                    {action(data) === 'Adicionar' &&
                        <Box className='BuilderBotSelectorBox'>
                            <Button 
                                variant={state.chatbotEscalation ? 'contained' : ''} 
                                onClick={() => setState({ chatbotEscalation: true })} 
                            >
                                ChatBots
                            </Button>
                            <Button 
                                variant={!state.chatbotEscalation ? 'contained' : ''} 
                                onClick={() => setState({ chatbotEscalation: false })} 
                            >
                                Filas
                            </Button>
                        </Box>
                    }
                    {action(data) !== 'Adicionar' &&
                        <Box className="category-title">
                            {state.chatbotEscalation ? 'Chatbots' : 'Filas'}
                        </Box>
                    }
                    {state.chatbotEscalation &&
                        <>
                            <Box>
                                <MultipleSelections
                                    title="Chatbots"
                                    selectedData={getSelectedChatBots()}
                                    dataIsObject={true}
                                    objectDataHasTitle="name"
                                    objectDataHasChecked="checked"
                                    dataProp={state.chatBotsChecked}
                                    getSelect={(chatBotsChecked) => setState({ 
                                        chatBotsChanged : true,
                                        chatBotsChecked : chatBotsChecked 
                                    })}
                                    key={state.renderKey}
                                />
                            </Box>
                            <Box className={state.sendChatBotMessage ? "category-title checkbox" : "category-title checkbox unchecked"}>
                                <Switch
                                    checked={state.sendChatBotMessage}
                                    onChange={changeSendChatBotMessage}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                                Mensagens de espera - CHATBOT
                                <Tooltip title="Mensagens que serão enviadas sempre que o cliente ficar os tempos definidos sem responder o chatbot.">
                                    <HelpIcon sx={{ marginLeft: ".5rem" }}/>
                                </Tooltip>
                            </Box>
                            {state.sendChatBotMessage &&
                                <>
                                    {state.chatBotMessages.map((each, index) => (
                                        <Box className="escalation-time-row">
                                            <TextField
                                                sx={{ width: "100px" }}
                                                size="small"
                                                label="Minutos"
                                                type="text"
                                                variant="outlined"
                                                value={each.timeout}
                                                onChange={(event) => updateChatBotMessage(index, IntegerOnly(event.target.value, 0, 86400), 'timeout')}
                                            />
                                            <VariableTextField
                                                sx={{ flex: 1 }}
                                                multiline={true}
                                                size="small"
                                                label="Mensagem"
                                                type="text"
                                                variant="outlined"
                                                value={each.message}
                                                onChange={(event) => updateChatBotMessage(index, event.target.value, 'message')}
                                                onVariableSelect={(value) => {
                                                    updateChatBotMessage(index, value, 'message', true)
                                                    setState({ chatBotKey: `onchat-text-field-${Math.random()}` })
                                                }}
                                                key={`${state.chatBotKey}-${index}`}
                                            />
                                            <Button 
                                                className="remove-add-icon" 
                                                variant="contained" 
                                                color="error"
                                                onClick={() => removeChatBotMessage(index)}
                                            >
                                                <RemoveIcon />
                                            </Button>
                                            <Button 
                                                className="remove-add-icon" 
                                                variant="contained"
                                                onClick={addChatBotMessageRow}
                                            >
                                                <AddIcon />
                                            </Button>
                                        </Box>
                                    ))}        
                                </>
                            }
                        </>
                    }
                    {! state.chatbotEscalation && 
                        <>
                            <Box>
                                <MultipleSelections
                                    title="Filas"
                                    selectedData={getSelectedData()}
                                    dataIsObject={true}
                                    objectDataHasTitle="name"
                                    objectDataHasChecked="checked"
                                    dataProp={state.queuesChecked}
                                    getSelect={(queuesChecked) => setState({ 
                                        queuesChanged : true,
                                        queuesChecked : queuesChecked 
                                    })}
                                    key={state.renderKey}
                                />
                            </Box>
                            <Box className={state.sendQueueMessage ? "category-title checkbox" : "category-title checkbox unchecked"}>
                                <Switch
                                    checked={state.sendQueueMessage}
                                    onChange={changeSendQueueMessage}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                                Mensagens de espera - EM FILA
                                <Tooltip title="Mensagens que serão enviadas nos tempos definidos para os clientes em fila sem atendimento que ainda não foram atendidos.">
                                    <HelpIcon sx={{ marginLeft: ".5rem" }}/>
                                </Tooltip>
                            </Box>
                            {state.sendQueueMessage &&
                                <>
                                    {state.queueMessages.map((each, index) => (
                                        <Box className="escalation-time-row">
                                            <TextField
                                                sx={{ width: "100px" }}
                                                size="small"
                                                label="Minutos"
                                                type="text"
                                                variant="outlined"
                                                value={each.timeout}
                                                onChange={(event) => updateQueueMessage(index, IntegerOnly(event.target.value, 0, 86400), 'timeout')}
                                            />
                                            <VariableTextField
                                                sx={{ flex: 1 }}
                                                multiline={true}
                                                size="small"
                                                label="Mensagem"
                                                type="text"
                                                variant="outlined"
                                                value={each.message}
                                                onChange={(event) => updateQueueMessage(index, event.target.value, 'message')}
                                                onVariableSelect={(value) => {
                                                    updateQueueMessage(index, value, 'message', true)
                                                    setState({ queueKey: `onchat-text-field-${Math.random()}` })
                                                }}
                                                key={`${state.queueKey}-${index}`}
                                            />
                                            <Button 
                                                className="remove-add-icon" 
                                                variant="contained" 
                                                color="error"
                                                onClick={() => removeQueueMessage(index)}
                                            >
                                                <RemoveIcon />
                                            </Button>
                                            <Button 
                                                className="remove-add-icon" 
                                                variant="contained"
                                                onClick={addQueueMessageRow}
                                            >
                                                <AddIcon />
                                            </Button>
                                        </Box>
                                    ))}        
                                </>
                            }
                            <Box className={state.sendOnChatMessage ? "category-title checkbox" : "category-title checkbox unchecked"}>
                                <Switch
                                    checked={state.sendOnChatMessage}
                                    onChange={changeSendOnChatMessage}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                                Mensagens de espera - EM ATENDIMENTO
                                <Tooltip title="Mensagens que serão enviadas nos tempos definidos para os clientes em atendimento sem um resposta do operador.">
                                    <HelpIcon sx={{ marginLeft: ".5rem" }}/>
                                </Tooltip>
                            </Box>
                            {state.sendOnChatMessage &&
                                <>
                                    {state.onChatMessages.map((each, index) => (
                                        <Box className="escalation-time-row">
                                            <TextField
                                                sx={{ width: "100px" }}
                                                size="small"
                                                label="Minutos"
                                                type="text"
                                                variant="outlined"
                                                value={each.timeout}
                                                onChange={(event) => updateOnChatMessage(index, IntegerOnly(event.target.value, 0, 86400), 'timeout')}
                                            />
                                            <VariableTextField
                                                sx={{ flex: 1 }}
                                                multiline={true}
                                                size="small"
                                                label="Mensagem"
                                                type="text"
                                                variant="outlined"
                                                value={each.message}
                                                onChange={(event) => updateOnChatMessage(index, event.target.value, 'message')}
                                                onVariableSelect={(value) => {
                                                    updateOnChatMessage(index, value, 'message', true)
                                                    setState({ queueKey: `queue-text-field-${Math.random()}` })
                                                }}
                                                key={`${state.onChatKey}-${index}`}
                                            />
                                            <Button 
                                                className="remove-add-icon" 
                                                variant="contained" 
                                                color="error"
                                                onClick={() => removeOnChatMessage(index)}
                                            >
                                                <RemoveIcon />
                                            </Button>
                                            <Button 
                                                className="remove-add-icon" 
                                                variant="contained"
                                                onClick={addOnChatMessageRow}
                                            >
                                                <AddIcon />
                                            </Button>
                                        </Box>
                                    ))}        
                                </>
                            }
                        </>
                    }
                    <Box className={state.escalate ? "category-title checkbox" : "category-title checkbox unchecked"}>
                        {/*<Switch
                            checked={state.escalate}
                            onChange={changeEscalate}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />*/}
                        <Box className="category-title">
                            Transbordo
                        </Box>
                        <Tooltip title="Tempo máximo para que o cliente seja transferido para a fila de atendimento escolhida.">
                            <HelpIcon sx={{ marginLeft: ".5rem" }}/>
                        </Tooltip>
                    </Box>
                    {state.escalate &&
                        <>
                            <Box className="escalation-time-row">
                                <TextField
                                    sx={{ width: "100px" }}
                                    size="small"
                                    label="Minutos"
                                    type="text"
                                    variant="outlined"
                                    value={state.escalateTime}
                                    onChange={(event) => setState({ escalateTime: IntegerOnly(event.target.value, 0, 86400) })}
                                />
                                <FormControl sx={{ flex: 1 }} size="small">
                                    <InputLabel id="escalate-queue-label">Fila de transbordo</InputLabel>
                                    <Select
                                        labelId="escalate-queue-label"
                                        id="escalate-queue"
                                        value={state.escalateQueue}
                                        label="Fila de transbordo"
                                        onChange={(event) => setState({ escalateQueue: event.target.value })}
                                    >
                                        <MenuItem value={0}>Selecione um fila...</MenuItem> 
                                        {Object.keys(allQueues).map((key) => (
                                            <MenuItem value={key}>{allQueues[key]['name']}</MenuItem> 
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box>
                                <VariableTextField
                                    fullWidth={true}
                                    multiline={true}
                                    size="small"
                                    label="Mensagem de transbordo"
                                    type="text"
                                    variant="outlined"
                                    value={state.escalateMessage}
                                    onChange={(event) => setState({ escalateMessage: event.target.value })}
                                    onVariableSelect={(value) => setState({ 
                                        escalateMessage: state.escalateMessage ? `${state.escalateMessage}${value}` : value,
                                        escalateKey: `escalate-text-field-${Math.random()}`
                                    })}
                                    key={state.escalateKey}
                                />
                            </Box>
                        </>
                    }
                </DialogContent>
                <DialogActions style={{ padding: "10px 20px 10px 10px" }}>
                    <Button onClick={() => setParentState({ editEscalation: null })}>
                        Cancelar
                    </Button>
                    <Tooltip title={checkButtonText()} placement="top" describeChild={true}>
                        <span>
                            <Button
                                disabled={checkButtonText()}
                                onClick={submit}>
                                Salvar
                            </Button>
                        </span>
                    </Tooltip>
                </DialogActions>
            </Dialog >
        </>
    )
}

export default EditEscalation
