import React, { useEffect, useState, useCallback } from 'react'

import { Box, Alert, AlertTitle, IconButton, Tooltip } from '@mui/material'

import LoadingButton from '@mui/lab/LoadingButton';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import AddBotDialog from "./AddBotDialog/index.js"
import ConfirmationDialogCaptcha from '../../Dialogs/ConfimationDialogCaptcha/index.js';
import useNotification from "../../Notification/index.js";
import Loading from '../../Loading/index.js';
import api from '../../../api.js';
import EditIcon from '@mui/icons-material/Edit';

import { getSession } from '../../../auth.js';

import './styles.css'

const Sms = ({ enterprise }) => {

	const [loading, setLoading] = useState(false);
	const [bots, setBots] = useState([]);
	const [showAddBot, setShowAddBot] = useState(null)
	const [deleteModal, setDeleteModal] = useState(null)

	const deleteBot = async (id) => {
		const enterpriseId = getSession().profile.enterprise.id;
		setLoading(true)
		const res = await api.delete(`/api/enterprises/${enterpriseId}/sms/${id}/`)
		setLoading(false)
		if (res.status !== 204) {
			useNotification(
				'Ops!',
				'Ocorreu um erro na requisição.',
				'danger'
			);
			return
		}
		setBots(bots.filter((bot) => bot.id != id))
		setDeleteModal(null)
	}

	const addBot = async (data) => {
		const enterpriseId = getSession().profile.enterprise.id;
		const id = data.id
		const validationData = { ...data }
		delete validationData.id

		let res = null
		setLoading(true)
		if (id === 0)
			res = await api.post(`/api/enterprises/${enterpriseId}/sms/`, validationData)
		else
			res = await api.patch(`/api/enterprises/${enterpriseId}/sms/${id}/`, validationData)
		setLoading(false)

		if (![200, 201].includes(res.status)) {
			if (res.status === 404) {
				useNotification(
					'Ops!',
					'Não foi possível registrar o sms, verifique as suas credenciais.',
					'danger'
				)
			}

			else if (res.status === 409) {
				useNotification(
					'Ops!',
					'Número já vinculado a outra conta.',
					'danger'
				)
			}
			return

		}

		const foundbot = bots.find(each => each.id === res.data.id)
		if (foundbot)
			Object.assign(foundbot, res.data)
		else
			setBots([...bots, res.data])

		setShowAddBot(null)
	}

	useEffect(() => {
		const fetchData = async () => {
			const enterpriseId = getSession().profile.enterprise.id;
			setLoading(true);
			const res = await api.get(`/api/enterprises/${enterpriseId}/sms/`);
			setLoading(false);

			if (res.status !== 200) {
				useNotification(
					'Ops!',
					'Não foi possível encontrar os bots de sms.',
					'danger'
				);
				return
			}

			setBots(res.data)
		};
		fetchData()
	}, [])

	return (
		<Box className="input-settings settings-body">
			<Loading loading={loading} />
			{showAddBot &&
				<AddBotDialog
					data={showAddBot}
					handleClose={() => setShowAddBot(null)}
					submit={addBot}
				/>
			}
			{deleteModal ?
				<ConfirmationDialogCaptcha open={true}
					title="Excluir página."
					description={
						<span>
							Para confirmar a ação, digite: <span style={{ 'fontWeight': 'bold' }}>remover</span> no campo abaixo.</span>}
					handleClose={() => { setDeleteModal(null) }}
					handleSubmit={() => { deleteBot(deleteModal) }}
					captcha={'remover'}
				/> : null
			}
			<Alert className="telegram-info" severity="info">
				<AlertTitle>
					Para saber como integrar o kwik com SMS <a href="https://instant.getoutline.com/s/kwik-integracoes/doc/integracao-sms-lmUhvyAs2t" target="_blank">clique aqui.</a>
				</AlertTitle>
			</Alert>
			{bots.length !== 0 &&
				<>
					<Box className="category-title push-bottom">
						Sms(s)
					</Box>
					<Box className="telegram-bots">
						<table>
							{bots.map((item, index) => (
								<tr>
									<td>Telefone: {item.phone_number}</td>
									<td>
										<Tooltip title="Editar">
											<IconButton onClick={() => { setShowAddBot(item) }}>
												<EditIcon className='editicon' />
											</IconButton>
										</Tooltip>
										<Tooltip title="Excluir">
											<IconButton onClick={() => setDeleteModal(item.id)}>
												<DeleteIcon />
											</IconButton>
										</Tooltip>
									</td>
								</tr>
							))}
						</table>
					</Box>
				</>
			}

			<Box className="email-add-button">
				<LoadingButton startIcon={<AddIcon />} variant="contained"
					onClick={() => setShowAddBot({})}
				>
					Adicionar
				</LoadingButton>
			</Box>

		</Box>
	)
}

export default Sms
