import { useRef, useEffect, useState, useLayoutEffect } from "react";
import throttle from "lodash.throttle";
import { Box, Skeleton } from "@mui/material";

/**
 * Check if an element is in viewport

 * @param {number} offset - Number of pixels up to the observable element from the top
 * @param {number} throttleMilliseconds - Throttle observable listener, in ms
 */
export default function useVisibility(
    offset = 0,
    throttleMilliseconds = 100,
    parent = null
) {
    const [isVisible, setIsVisible] = useState(false);
    const currentElement = useRef();

    const onScroll = throttle(() => {
        if (!currentElement.current) {
            setIsVisible(false);
            return;
        }
        calcVisibility()
    }, throttleMilliseconds);

    const calcVisibility = () => {
        const top = currentElement.current.getBoundingClientRect().top;
        const parentTop = parent?.current?.getBoundingClientRect()?.top || 0;
        setIsVisible(top + offset - parentTop >= 0 && top - offset - parentTop <= window.innerHeight );
    }

    useEffect(() => {
        document.addEventListener('scroll', onScroll, true);
        return () => document.removeEventListener('scroll', onScroll, true);
    });

    return [isVisible, currentElement, calcVisibility];
}


export const ShowComponent = ({ parent, children, isVisibleHook }) => {
    const [isVisible, currentElement, calcVisibility] = useVisibility(25, 100, parent)

    useEffect(()=>{
            isVisibleHook(isVisible) 
            calcVisibility()   
    }, [isVisible])

    return (
    <Box  ref={currentElement}>
        {children}
    </ Box>
    )

}