import React, {useState, useEffect, useContext} from 'react'

import { QueueSocket } from './routes';

import { getSession } from './auth';

var retry_socket_timeout = 1

const SocketQueueComponent = () => {

    const {updateChatState, connections, updateConnections} = useContext(QueueSocket)
    const [keepAlive, setKeepAlive] = useState(false)
    
    let messages = []
    const session = getSession()
    
    let queues = []
    if (session){
        queues = session.profile.queues.map(queue => queue.name) 

        const settings = session.profile.enterprise.settings
        if (!settings.websockets)
            queues = []
    }

    useEffect(()=>{

        const connect = (queue) => {
            let socket = new WebSocket(`wss://${location.host}/chat/ws/queue/${queue}/`);

            socket.queue=queue

            socket.onopen = function (e) {
                // console.log(`[open] Connection established on ${queue}`);
                updateConnections(prevState => [...prevState, socket])
                retry_socket_timeout = 1
            }
        
            socket.onmessage = function (e)  {
                // console.log(`[message] Message Received server on ${queue}`);
                const newstate = JSON.parse(e.data)
                updateChatState({...newstate, new:true, queue:queue})
                
            }
        
            socket.onclose = function (e)  {

                console.log(`[close] Connection closed on ${queue}, reconnecting in 5 seconds`);
                if (e.currentTarget.code == 3000)
                    return
                retry_socket_timeout +=1
                updateConnections(prevState => prevState.filter(oldcon => oldcon.queue != queue))
                if (retry_socket_timeout > 3*queues.length)
                    return
                setTimeout(()=>{connect(queue)}, 5000)
            }
        
            socket.onerror = function (e)  {
                console.log(`[open] Connection failed on ${queue}.`);

            }
        }
        
        if(connections.length > 0){
            connections.map((connection, index )=> {
                connection.code=3000
                connection.close()
                updateConnections(prevState => prevState.filter(oldcon => oldcon != connection))
            })
        }

        if (queues.length > 0)
            queues.map(q => {
                connect(q)
            })
        
    }, [])




    return (
    <>
    </>
    )
}


export default SocketQueueComponent