import React, { useEffect, useState, useContext, useReducer } from 'react';

import { Dialog, DialogTitle, DialogContent, Grid, Box, Autocomplete, InputAdornment, Divider, Tooltip } from '@mui/material';
import { FormControl, InputLabel, Select, MenuItem, Chip } from '@mui/material';
import { List, ListItem, ListItemText, DialogActions, Button } from '@mui/material';
import { TextField } from '@mui/material';
import useNotification from "../../Notification";

import CloseIcon from '@mui/icons-material/Close';

import '../../../index.css';
import api from '../../../api';

import MultiStep from './MultiStep';
import TimeSelector from './TimeSelector';
import DateTimeSelector from './DateTimeSelector';
import CampaignSummary from './CampaignSummary';

import ParameterFiller from '../Templates/ParameterFiller';

import SearchIcon from '@mui/icons-material/Search';

import { ScreenContext } from '..';
import { SendSMSContent, ShouldSendSms, getSmsDefaultState } from '../TabButtons/SendMessage/SendSMS';
import defaultReducer from '../../../utils/defaultReducer';

import { getSession } from "../../../auth";
import Loading from '../../Loading';

import LoadingButton from '@mui/lab/LoadingButton';

const CreateCampaignModal = (props) => {
    const { campaigns, groups, updateCampaigns, subsObj, setLoading } = useContext(ScreenContext)

    const [campaignID, updateCampaignID] = useState(0)

    const [campaignName, updateCampaignName] = useState("")
    const [clientGroup, updateclientGroupRows] = useState([])
    const [selectedGroup, updateSelectedGroup] = useState([])

    const [selectedQueue, updateSelectedQueue] = useState("")
    const [selectedSender, updateSelectedSender] = useState("")
    const [selectedTemplate, updateSelectedTemplate] = useState("")

    const [filteredTemplates, setFilteredTemplates] = useState([])
    const [numberTemplates, setNumberTemplates] = useState([])
    const [numberLoading, setNumberLoading] = useState(false)

    const [timeSelector, updateTimeSelector] = useState("")

    const [dateTimeSelector, updateDateTimeSelector] = useState({})

    const [sendNow, updateSendNow] = useState(false)

    const [updateButtons, setUpdateButtons] = useState(false)

    const [actualStep, updateStep] = useState(0)
    const [canNext, updateCanNext] = useState(false)

    const [paramModal, updateParamModal] = useState(false)
    const [deployParameters, setDeployParameters] = useState([])
    let [parameters, updateParameters] = useState([])
    let [mediaParameters, updateMediaParameters] = useState({})
    const [allParamsFilled, updateAllParamsFilled] = useState(false)

    const [modalActions, setModalActions] = useState()

    const [ready, updateReady] = useState(false)

    const [inputValue, setInputValue] = useState([])

    const [platform, setPlatform] = useState('WHATSAPP')

    const [smsState, setSmsState] = useReducer(defaultReducer, {
        ...getSmsDefaultState
    })

    const [testCampaignModal, setTestCampaignModal] = useState(false)
    const [testCampaignNumber, setTestCampaignNumber] = useState(null)

    const queues = Object.entries(props.queues)

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                maxWidth: 250,
            },
        },
    };

    useEffect(() => {
        updateclientGroupRows(groups)
        updateReady(true)
    }, [groups])


    //Custom logic to save in between steps without modifying old logic.
    // 'create' for the first step
    // 'activate' for the last step
    // 'none' for any step.
    const onNext = async (value) => {

        const validation = changeData(value - 1)
        if (!validation)
            return false

        if (value == 1) {
            const res = await submit('create')
            return res
        }
        const res = await submit('none')
        return res
    }

    const submit = async (command) => {
        if (command != 'create' && campaignID === 0)
            return
        const posturl = `/api/enterprises/${props.enterpriseId}/campaign/`
        const patchurl = `/api/enterprises/${props.enterpriseId}/campaign/${campaignID}/`
        // const testUrl = `/api/whatsapp/medianotification/`
        const testUrl = `/api/enterprises/${props.enterpriseId}/campaign/test/`
        const isPeriodic = timeSelector === 'periodic' ? true : false
        var daylist = typeof (dateTimeSelector.DAY) !== 'object' ? dateTimeSelector.DAY : dateTimeSelector.DAY.join()
        var monthlist = typeof (dateTimeSelector.MONTH) !== 'object' ? dateTimeSelector.MONTH : dateTimeSelector.MONTH.map(item => JSON.parse(item).key).join()
        var weekdaylist = typeof (dateTimeSelector.WEEKDAYS) !== 'object' ? dateTimeSelector.WEEKDAYS : dateTimeSelector.WEEKDAYS.map(item => JSON.parse(item).key).join()
        const queue = selectedQueue.split('_')[1]
        var range = '*'
        var year = dateTimeSelector.YEAR

        if (!selectedTemplate && actualStep >= 1 && platform === 'WHATSAPP') {
            updateCanNext(false)
            useNotification(
                'Falha!',
                `Por favor escolha um template válido`,
                'danger'
            )
            return false
        }

        if (!isPeriodic && actualStep >= 3 && !sendNow) {
            const today = new Date()
            if (dateTimeSelector.DATE < today) {
                updateCanNext(false)
                useNotification(
                    'Falha!',
                    `Por favor escolha um horário maior que o atual`,
                    'danger'
                )
                return false
            }

        }
        if (dateTimeSelector.PERIOD == 1) {
            range = dateTimeSelector.RANGE
            year = '0'
            monthlist = '*'
            weekdaylist = '*'
            const today2 = new Date()
            if (dateTimeSelector.DATE < today2) {
                updateCanNext(false)
                useNotification(
                    'Falha!',
                    `Por favor escolha um horário maior que o atual`,
                    'danger'
                )
                return false
            }

        }


        let formdata = {}
        const newParameters = deployParameters
        if (newParameters && Object.values(newParameters).length > 0 && actualStep === 1 && platform === 'WHATSAPP') {
            formdata = new FormData()
            if ('headerfile' in newParameters)
                formdata.append('headerfile', newParameters.headerfile)
            if ('headerbody' in newParameters)
                formdata.append('headerbody', newParameters.headerbody)
            if ('body' in newParameters)
                Object.values(newParameters.body).map(p => formdata.append('body', p))
            if ('buttons' in newParameters)
                formdata.append('buttons', newParameters.buttons)

            if (!('headerfile' in newParameters) && 'headerURL' in newParameters)
                formdata.append('headerURL', newParameters.headerURL)

            formdata.append('parameters', 'renew')
            formdata.append('queue', queue ? queue : '')
            formdata.append('sender', selectedSender ? selectedSender : '')
            formdata.append('template', selectedTemplate ? JSON.stringify(selectedTemplate) : '')
            formdata.append('step', actualStep + 1)
            formdata.append('platform', platform)
        }
        else {

            let setTemplate = ''

            if (platform === 'WHATSAPP')
                setTemplate = selectedTemplate.name
            else if (platform === 'SMS')
                setTemplate = JSON.stringify(smsState)

            if (testCampaignNumber && command == "test-activate") {
                formdata = {
                    id: campaignID,
                    name: campaignName,
                    queue_name: queue,
                    sender: selectedSender,
                    template: setTemplate,
                    run_now: true,
                    platform: platform,
                    to: testCampaignNumber,
                    from: selectedSender,
                }
            } else {
                formdata = {
                    name: campaignName,
                    group: selectedGroup.map(each => each.id),
                    queue: queue,
                    sender: selectedSender,
                    template: setTemplate,
                    year: sendNow ? '0' : year,
                    months: monthlist,
                    week_days: weekdaylist,
                    days: daylist,
                    hour: dateTimeSelector.HOURS,
                    minutes: dateTimeSelector.MINUTES,
                    periodic: isPeriodic,
                    active: command == 'activate' || command == 'test-activate' ? true : false,
                    _range: range.toString(),
                    run_now: sendNow,
                    step: actualStep + 1,
                    platform: platform
                }
            }

            // If we don't have mediaParameters, we should change parameters to empty 
            // This means, we are changing to a new template that doesn't require parameters.
            if (actualStep == 1 && (!mediaParameters || Object.values(mediaParameters).length == 0)) {
                formdata.parameters = '[]'
            }
        }

        var response = ''
        if (testCampaignNumber && command == "test-activate") {
            response = await api.post(testUrl, formdata)
            useNotification(
                'Enviado!',
                `O teste foi enviado para o número de destino com sucesso.
                 Caso não receba a mensagem, acesse a tela de notificações e verifique o status para saber mais detalhes.`,
                'success'
            );
            return
        } else if (command == 'create' && !campaignID) {
            var response = await api.post(posturl, formdata)
            if (response.status === 201) {
                updateCampaignID(response.data.id)
                updateCampaigns(prevState => subsObj(prevState, response.data).sort((x, y) => x > y ? 1 : -1))
            }
        }
        else {
            var response = await api.patch(patchurl, formdata)
            if (response.status === 200) {
                updateCampaigns(prevState => subsObj(prevState, response.data).sort((x, y) => x > y ? 1 : -1))
            }
        }

        if (response.status === 400) {
            let message = 'Ocorreu um erro com a requisição.'
            if ('non_field_errors' in response.data && response.data.non_field_errors)
                message = 'Já existe uma campanha com esse nome nessa empresa.'
            if ('file' in response.data)
                message = 'O formato do documento anexado aos parâmetros não é permitido.'
            useNotification(
                'Ops!',
                message,
                'danger'
            );
            return

        }
        else if (response.status == 403) {
            let message = 'Você não tem permissão para realizar essa ação.'
            useNotification(
                'Ops!',
                message,
                'danger'
            );
            return
        }



        if (command == 'activate' || command == 'test-activate') {
            useNotification(
                'Sucesso!',
                'Campanha concluída.',
                'success'
            );

            const newres = await props.submit()
            return true
        }
        return true

    }

    const changeData = (targetstep = 0) => {
        setUpdateButtons(p => !p)
        if (actualStep == 0) {
            if (campaignName.length == 0 ||
                selectedGroup.length == 0) {
                return false
            }
        }
        if (actualStep == 1 || targetstep >= 1){
            if  (platform === 'WHATSAPP'){
                if (selectedQueue.length == 0 ||
                    selectedSender.length == 0 ||
                    selectedTemplate.length == 0) {
                    return false
                }
            }
            if (platform === 'SMS'){
                const res = ShouldSendSms(smsState)
                if (res)
                    return false
            }
        }
        

        if (actualStep == 2 || targetstep >= 2) {
            if (timeSelector.length == 0) {
                return false
            }
        }

        if ((actualStep == 3 || targetstep >= 3) && !sendNow) {
            if (Object.entries(dateTimeSelector).length === 0) {
                return false
            }
            const isPeriodic = timeSelector === 'periodic' ? true : false
            const today = new Date()

            if (!isPeriodic && (dateTimeSelector.DATE < today || dateTimeSelector.DATE == 'Invalid Date')) {
                return false
            }
        }
        return true
    }

    const matchRegLen = value => {
        if (!value)
            return
        const matcher = value.match(/{{\d+}}/g)
        if (matcher)
            return matcher.length
        return 0
    }


    const handleSelectedTemplate = template => {

        updateMediaParameters({})
        setDeployParameters([])

        const requiredParameters = []
        if (template.components.length == 1 && template.components[0].type == 'BODY') {
            let amount = matchRegLen(template.components[0].text)
            if (amount)
                requiredParameters.push(
                    {
                        type: 'BODY',
                        amount: amount
                    }
                )
        }
        else {
            template.components.map(component => {
                let amount = 0
                if (component.type == 'HEADER') {
                    amount = 1
                    if (component.format == 'TEXT')
                        amount = matchRegLen(component.text)
                    if (amount)
                        requiredParameters.push({
                            type: component.type,
                            format: component.format,
                            amount: amount
                        })
                }
                else if (component.type == 'BODY') {
                    amount = matchRegLen(component.text)
                    if (amount)
                        requiredParameters.push(
                            {
                                type: 'BODY',
                                amount: amount
                            }
                        )
                }

                else if (component.type == 'BUTTONS') {
                    amount = 0
                    component.buttons.map(button => {
                        if (button.type === 'URL')
                            amount += matchRegLen(button.url)
                    })
                    if (amount)
                        requiredParameters.push(
                            {
                                type: 'BUTTONS',
                                amount: amount
                            }
                        )
                }

            })
        }

        if (requiredParameters.length > 0) {
            updateParamModal(true)
            updateParameters(requiredParameters)
            updateSelectedTemplate(template)
            return
        }
        updateSelectedTemplate(template)

    }

    const handleParamGoBack = () => {
        updateParamModal(false)
        updateParameters([])
        updateMediaParameters({})
        updateSelectedTemplate('')
    }

    const checkParameters = (value) => {
        updateAllParamsFilled(value)
        setDeployParameters(mediaParameters)
    }

    const handleChangeGroup = (event) => {
        const {
            target: { value },
        } = event;
        updateSelectedGroup(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };


    const recoverCampaignData = async (id) => {
        //IDK why it works with await, but doesn't without it.
        const response = await campaigns.filter(campaign => campaign.id === id).pop()
        if (response.length === 0)
            return

        const periods = [
            'Diariamente',
            'No intervalo de dias',
            'Nos dias da semana selecionados',
            'Nos meses selecionados'
        ]

        const possibleWeekdays = [
            { key: 0, value: 'Segunda-feira' },
            { key: 1, value: 'Terça-feira' },
            { key: 2, value: 'Quarta-feira' },
            { key: 3, value: 'Quinta-feira' },
            { key: 4, value: 'Sexta-feira' },
            { key: 5, value: 'Sábado' },
            { key: 6, value: 'Domingo' }
        ]
        const possibleMonths = [
            { key: 1, value: 'Janeiro' },
            { key: 2, value: 'Fevereiro' },
            { key: 3, value: 'Março' },
            { key: 4, value: 'Abril' },
            { key: 5, value: 'Maio' },
            { key: 6, value: 'Junho' },
            { key: 7, value: 'Julho' },
            { key: 8, value: 'Agosto' },
            { key: 9, value: 'Setembro' },
            { key: 10, value: 'Outubro' },
            { key: 11, value: 'Novembro' },
            { key: 12, value: 'Dezembro' }
        ]

        const recoveredQueue = queues.find(each => each[1].includes(response.queue))
        const recoveredSender = props.senders.find(each => each === response.sender)

        //Fixing polymorfism
        let recoveredTemplate = ''
        if (response.platform === 'WHATSAPP'){
            try { recoveredTemplate = props.templates.find(each => each.name === JSON.parse(response.template).name) } // new mode
            catch (e) { recoveredTemplate = props.templates.find(each => each.name === response.template) } // old mode
        }
        else if (response.platform === 'SMS') {
            recoveredTemplate = ''
            setSmsState(JSON.parse(response.template))
        }


        let recoveredParams = ''
        try {
            const newobj = {}
            JSON.parse(response.parameters).map(obj => {
                const [key, value] = Object.entries(obj).pop()
                Array.isArray(value) ? newobj[key] = { ...value } : newobj[key] = value

            })
            recoveredParams = newobj
            updateMediaParameters(recoveredParams)
        }
        catch (e) {
            if (response.parameters)
                recoveredParams = JSON.parse(response.parameters).map(each => ({ value: each }))
        }

        // }  //new mode      
        const recoveredPeriodic = response.periodic === true ? 'periodic' : 'once'

        var recoveredPeriod = -1
        var recoveredLiteral = ''
        var recoverdedDate = ''

        var recoveredWeekdays = response.week_days
        var recoveredMonths = response.months
        var recoveredRange = response._range == '*' ? ['', ''] : response._range.split(',')

        if (recoveredPeriodic === 'periodic') {
            var recoveredWeekdays = response.week_days == '*' ? '*' : possibleWeekdays.filter(each =>
                response.week_days.includes(String(each.key))
            ).map(each => JSON.stringify(each)
            )

            var recoveredMonths = recoveredMonths == '*' ? '*' : possibleMonths.filter(each =>
                response.months.includes(String(each.key))
            ).map(each => JSON.stringify(each)
            )



            if (response.days === '*/1') {
                var recoveredPeriod = 0
            }
            else if (response._range !== '*') {
                var recoveredPeriod = 1
                var recoveredLiteral = ': ' +
                    new Date(recoveredRange[0] + 'T12:00')
                        .toLocaleDateString()
                    + ' até '
                    + new Date(recoveredRange[1] + 'T12:00')
                        .toLocaleDateString()
            }

            else if (response.week_days !== '*') {
                var recoveredPeriod = 2
                var recoveredLiteral = ': ' + recoveredWeekdays.map(item => JSON.parse(item).value).join(', ')
            }

            else if (response.months !== '*') {
                var recoveredPeriod = 3
                var recoveredLiteral = ': ' + recoveredMonths.map(item => JSON.parse(item).value).join(', ') + ' no dia ' + response.days
            }


            var recoveredLiteral = periods[recoveredPeriod] + recoveredLiteral + ` às ${response.hour}:${response.minutes}`
        }
        else {

            recoverdedDate = new Date(response.year,
                response.months - 1,
                response.days,
                response.hour,
                response.minutes)
            recoveredLiteral = recoverdedDate.toLocaleString()
        }

        const recoveredDateTimeSelector = response.year !== 0 ?
            {
                YEAR: response.year,
                MONTH: recoveredMonths,
                DAY: response.days,
                HOURS: response.hour,
                MINUTES: response.minutes,
                WEEKDAYS: recoveredWeekdays,
                PERIOD: recoveredPeriod,
                LITERAL: recoveredLiteral,
                RANGE: recoveredRange,
                DATE: recoverdedDate
            } : {}

        const groupIDS = response.groups.map(each => each.group)
        updateCampaignID(response.id)
        updateCampaignName(response.name)
        updateSelectedGroup(groupIDS.map(each => ({ id: each, label: groups.find(group => group.id == each).name })))
        updateSelectedQueue(recoveredQueue ? recoveredQueue[1] : '')
        updateSelectedSender(recoveredSender ? recoveredSender : '')
        updateSelectedTemplate(recoveredTemplate ? recoveredTemplate : '')
        setDeployParameters([])
        updateStep(response.step > 4 ? 4 : response.step)
        updateTimeSelector(recoveredPeriodic)
        setPlatform(response.platform)
        updateDateTimeSelector(recoveredDateTimeSelector)
        updateReady(true)
        if (response.year == '0' && response.step >= 3 && !response.periodic)
            updateSendNow(true)
    }

    const handleNumberChange = (number) => {
        if (getSession().profile.enterprise.abstraction_layer) {
            setNumberLoading(true)
            const enterpriseId = getSession().profile.enterprise.id
            const digits = number.replace(/\D/g, '');
            api.get(`api/enterprises/${enterpriseId}/templates/${digits}/`)
            .then((res) => {
                const templates = res.data.waba_templates.filter((t) => t.status.toLowerCase() === 'approved')
                if (res.status === 200) {
                    setNumberLoading(false)
                    setNumberTemplates(templates)
                    updateSelectedSender(number)
                    setFilteredTemplates(templates)
                    return
                }
                throw err
            })
            .catch((err) => { this.setState({ numberLoading: false }) })
        } else { updateSelectedSender(number) }
    }

    useEffect(() => {
        const validation = changeData()
        updateCanNext(validation)
    }, [campaignName
        , selectedGroup
        , selectedQueue
        , selectedSender
        , selectedTemplate
        , actualStep
        , timeSelector
        , dateTimeSelector
        , sendNow
        , platform
        , smsState])


    useEffect(() => {
        updateSendNow(false)
    }, [props.data])


    useEffect(() => {
        if (props.selectedKey > 0) {
            recoverCampaignData(props.selectedKey)
            updateReady(false)
        }


        return (() => { })
    }, [props.open])


    const handleFilterChange = (event) => {
        let templates = props.templates
        if (getSession().profile.enterprise.abstraction_layer)
            templates = numberTemplates
        setFilteredTemplates(
            (templates.filter((template) => {
                const body = template.components?.find(each =>
                    each.type == 'BODY')?.text?.toLowerCase().includes(event.target.value.toLowerCase())
                const name = template.name?.toLowerCase().includes(event.target.value.toLowerCase())
                if (event.target.value && (body || name))
                    return true
                else if (event.target.value === "")
                    return true
            }))
        )
    }

    useEffect(() => {
        setFilteredTemplates(props.templates)
    }, [])

    return (
        <>
        <div>
            {paramModal ?
                <>
                    <Dialog keepMounted
                        open={props.open}
                        onClose={() => { }}
                        aria-labelledby="customized-dialog-title"
                        fullWidth
                        className={'PreviewTemplateDialog'}
                        disablePortal
                        disableEnforceFocus
                        sx={{ position: 'absolute', zIndex: 1501 }}
                    >
                        <DialogTitle id="customized-dialog-title">
                            Enviar notificação aos clientes selecionados
                        </DialogTitle>
                        <Divider />
                        <DialogContent>
                            <Box>
                                <ParameterFiller
                                    mediaValues={selectedTemplate.components}
                                    parameters={parameters}
                                    submitParameters={mediaParameters}
                                    setReady={checkParameters}
                                />
                            </Box>
                        </DialogContent>
                        <DialogActions style={{ padding: "10px 20px 10px 10px" }}>
                            <Button
                                onClick={handleParamGoBack}

                                color="primary"
                            >
                                Cancelar
                            </Button>
                            <Tooltip title={allParamsFilled ? allParamsFilled : ''} placement="top" describeChild={true}>
                                <span>
                                    <Button
                                        onClick={() => { updateParamModal(false); setUpdateButtons(p => !p) }}
                                        disabled={allParamsFilled ? true : false}
                                        color="primary"
                                    >
                                        Salvar
                                    </Button>
                                </span>
                            </Tooltip>
                        </DialogActions>
                    </Dialog>
                </>
                : null}
            {ready &&
                <Dialog keepMounted
                    open={props.open}
                    onClose={props.handleClose}
                    aria-labelledby="customized-dialog-title"
                    fullWidth
                    className='multistepdialog'
                    disablePortal
                    disableEnforceFocus
                    sx={{ position: 'absolute' }}
                >
                    <DialogTitle id="customized-dialog-title" className={'group-flexbox'}>
                        <span>{props.title}</span> <Button sx={{ 'minWidth': '2rem', 'padding': '0' }} onClick={() => { props.submit() }} ><CloseIcon /></Button>
                    </DialogTitle>
                    <Divider />
                    <DialogContent sx={{ overflow: "hidden", display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                        <MultiStep
                            setTestCampaignModal={setTestCampaignModal}
                            onSubmit={submit} // which function to call after submit
                            setModalActions={setModalActions}
                            updateButtons={updateButtons}
                            canNext={canNext} // Is able to click next?
                            getStep={updateStep} // Store actual step
                            onNext={onNext} // Function to call when clicking next.
                            onOpen={actualStep}
                            camp1={ // You can add multiple steps here with any name
                                <>
                                    <TextField
                                        size="small"
                                        label="Nome da campanha"
                                        fullWidth
                                        type="text"
                                        variant="outlined"
                                        className="push-top push-bottom"
                                        value={campaignName}
                                        onChange={(event) => { updateCampaignName(event.target.value) }}
                                        inputProps={{
                                            maxLength: 50
                                        }}
                                    />
                                    <Box className='campaign-decide-platform'>
                                        <Button variant={platform === 'WHATSAPP' ? 'contained' : 'standard'} onClick={() => setPlatform('WHATSAPP')}>Whatsapp</Button>
                                        <Button variant={platform === 'SMS' ? 'contained' : 'standard'} onClick={() => setPlatform('SMS')}>SMS</Button>
                                    </Box>

                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                        <FormControl
                                            fullWidth
                                            size="small"
                                        >
                                            <Autocomplete
                                                labelId="multiple-autocomplete-label-label"
                                                id="multiple-autocomplete-label"
                                                size={'small'}
                                                multiple={true}
                                                options={clientGroup.map(
                                                    (option) => ({ id: option.id, label: option.name }))
                                                    .filter(
                                                        option => !selectedGroup.map(each => each.id).includes(option.id))
                                                }
                                                renderInput={(params) => <TextField {...params} label="Segmentos" />}
                                                value={selectedGroup}
                                                MenuProps={MenuProps}
                                                filterSelectedOptions
                                                onChange={(event, newInputValue) => {
                                                    updateSelectedGroup(newInputValue);
                                                }}
                                                inputValue={inputValue}
                                                onInputChange={(event, newInputValue) => {
                                                    setInputValue(newInputValue);
                                                }}
                                                noOptionsText="Nenhuma opção disponível"
                                            />
                                            {/* <InputLabel id="demo-simple-select-label">Segmentos</InputLabel> 
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                label="Segmentos"
                                                multiple={true}
                                                value={selectedGroup}
                                                onChange={handleChangeGroup}
                                                renderValue={(selected) => (
                                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                        {selected.map((value) => (
                                                            <Chip key={`weekday${value}`} label={clientGroup.find(group => group.id == value).name} />
                                                        ))}
                                                    </Box>)}
                                            >
                                                {clientGroup.map((queue) => (
                                                    <MenuItem key={`group-select-label${queue.id}`} value={queue.id}>{queue.name}</MenuItem>
                                                ))}
                                            </Select> */}
                                        </FormControl>
                                    </Grid>
                                </>}
                            camp2={
                                <>
                                    {platform === 'WHATSAPP' && <>
                                        <Box
                                            sx={{ display: 'flex', gap: '1rem' }}
                                        >
                                            <Box sx={{ flex: 1 }}>
                                                <FormControl
                                                    fullWidth
                                                    size="small"
                                                >
                                                    <InputLabel id="demo-simple-select-label">Fila</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        label="Fila"
                                                        onChange={(event) => updateSelectedQueue(event.target.value)}
                                                        value={selectedQueue}
                                                        MenuProps={MenuProps}
                                                    >
                                                        {queues.map((queue) => (
                                                            <MenuItem key={queue[1]} value={queue[1]}>{queue[0]}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                            <Box sx={{ flex: 1 }}>
                                                <FormControl
                                                    fullWidth
                                                    size="small"
                                                >
                                                    <InputLabel id="demo-simple-select-label">Número de origem</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        label="Número de origem"
                                                        MenuProps={MenuProps}
                                                        onChange={(event) => handleNumberChange(event.target.value)}
                                                        value={selectedSender}
                                                    >
                                                        {props.senders.map((queue) => (
                                                            <MenuItem key={queue} value={queue}>{queue}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </Box>
                                        {getSession().profile.enterprise.abstraction_layer && numberTemplates.length === 0 &&
                                            <>
                                                {numberLoading &&
                                                    <Loading loading={numberLoading}/>
                                                }
                                                {! numberLoading &&
                                                    <Box style={{ marginTop: '1rem', marginLeft: '1rem' }}>
                                                        Selecione o número de origem na lista acima
                                                    </Box>
                                                }
                                            </>
                                        }
                                        {getSession().profile.enterprise.abstraction_layer && numberTemplates.length !== 0 &&
                                            <>
                                                <TextField
                                                    className={'inputPlaceholder'}
                                                    placeholder='Buscar template'
                                                    onChange={handleFilterChange}
                                                    variant="outlined"
                                                    size="small"
                                                    sx={{ marginTop: '.4rem', marginBottom: '.4rem', backgroundColor: '#EFEFEF' }}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <SearchIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    fullWidth
                                                />
                                                <List sx={{
                                                    'overflow'     : 'auto',
                                                    'maxHeight'    : '20rem',
                                                    'border'       : '1px solid #C4C4C4',
                                                    'borderRadius' :  '5px'
                                                }}>
                                                    {numberTemplates?.length !== 0 && filteredTemplates && filteredTemplates?.length > 0 ?
                                                        filteredTemplates.map((template, i) => (
                                                            <ListItem className={selectedTemplate == template
                                                                ? "template-item selected"
                                                                : "template-item"}
                                                                key={i}
                                                            >
                                                                <ListItemText primary={template.name}
                                                                    secondary={
                                                                        (template.components[0] &&
                                                                            template.components[0].type) == 'BODY' ?
                                                                            template.components[0].text :
                                                                            '[MIDIA] ' + template.components[1].text
                                                                    }
                                                                    onClick={() => { handleSelectedTemplate(template) }} />
                                                            </ListItem>
                                                        ))
                                                        :
                                                        <Box>Não há templates que correspondem a sua busca.</Box>
                                                    }
                                                </List>
                                            </>
                                        }
                                        {! getSession().profile.enterprise.abstraction_layer &&
                                            <>
                                                <h3>Selecione o template que será enviado.</h3>
                                                <TextField
                                                    className={'inputPlaceholder'}
                                                    label={"Buscar Templates"}
                                                    defaultValue=""
                                                    sx={{ maxWidth: '10rem', marginBottom: '0.5rem' }}
                                                    onChange={handleFilterChange}
                                                    variant="standard"
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <SearchIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                                <List className='newCampaignTemplate'>
                                                    {filteredTemplates && filteredTemplates.length > 0 ?
                                                        filteredTemplates.map((template, i) => (
                                                            <ListItem className={selectedTemplate == template
                                                                ? "template-item selected"
                                                                : "template-item"}
                                                                key={i}
                                                            >
                                                                <ListItemText primary={template.name}
                                                                    secondary={
                                                                        (template.components[0] &&
                                                                            template.components[0].type) == 'BODY' ?
                                                                            template.components[0].text :
                                                                            '[MIDIA] ' + template.components[1].text
                                                                    }
                                                                    onClick={() => { handleSelectedTemplate(template) }} />
                                                            </ListItem>
                                                        ))
                                                        :
                                                        <Box>Não há templates que correspondem a sua busca.</Box>
                                                    }
                                                </List>
                                            </>
                                        }
                                    </>}
                                    {platform === 'SMS' && <>
                                        <Box sx={{gap: '1rem', display:'flex', flexDirection:'column'}}>
                                            <SendSMSContent setLoading={setLoading} parent={smsState} setParent={setSmsState} queues={props.queues} />
                                        </Box>
                                    </>}
                                </>
                            }
                            camp3={<TimeSelector
                                state={timeSelector}
                                onChange={updateTimeSelector}
                                resetSelector={updateDateTimeSelector}
                                updateSendNow={updateSendNow} />}
                            camp4={<DateTimeSelector
                                time={timeSelector}
                                state={dateTimeSelector}
                                onChange={updateDateTimeSelector}
                                sendNow={sendNow}
                                updateSendNow={updateSendNow}
                                canNext={updateCanNext} />}
                            camp5={<CampaignSummary
                                name={campaignName}
                                groups={clientGroup}
                                groupkey={selectedGroup}
                                queues={queues}
                                queue={selectedQueue}
                                sender={selectedSender}
                                template={selectedTemplate}
                                time={timeSelector}
                                datetime={dateTimeSelector}
                                sendNow={sendNow}
                                platform={platform}
                                state={smsState}
                                mediaParameters={mediaParameters}
                                onSubmit={submit}
                                setTestCampaignModal={setTestCampaignModal}
                                testCampaignModal={testCampaignModal}
                                setTestCampaignNumber={setTestCampaignNumber}
                                testCampaignNumber={testCampaignNumber}
                            />
                            }
                        />
                    </DialogContent>
                    {modalActions}
                </Dialog>
            }
        </div>
        </>
    )
}

export default CreateCampaignModal
