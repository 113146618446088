import React from 'react';
import {
  Document,
  Page,
  Text,
  Image,
  View
} from "@react-pdf/renderer";
import html2canvas from 'html2canvas';

import { getSession } from "../../../auth";
import LogoKwik from '../../../assets/images/kwik_white_bg_resized.png';

import { styles } from './styles.js';

class ExportPDF extends React.Component {

  constructor(props) {
    super(props);
  }

  showHeader = () => {
    const logoEnterprise = getSession().profile.enterprise.logo;

    return (
      <View style={styles.header}>
        <Image src={LogoKwik} style={styles.logoKwik} />
        <Image src={logoEnterprise} style={styles.logoEnterprise} />
      </View>
    );
  }

  showFilterInterval = () => {
    let startDate       = (new Date(this.props.startDate)).toLocaleString('pt-BR');
    let endDate         = (new Date(this.props.endDate)).toLocaleString('pt-BR');
    let startDateString = startDate.substring(0, startDate.length-3);
    let endDateString   = endDate.substring(0, startDate.length-3);
    let dateInterval    = `\u2022 Dados entre ${startDateString} e ${endDateString}`;

    return (
      <View style={[styles.filters, styles.filterInterval]}>
        <Text>Filtros:</Text>
        <Text style={styles.filter}>{ dateInterval }</Text>
      </View>
    );
  }

  showFilters = () => {
    return (
      <View style={styles.filters}>
        {this.props.filters.map(filter => {
          return (
            <Text style={styles.filter}>{ filter }</Text>
          );
        })}
      </View>
    );
  }

  showGraphics = () => {
    return (
      <View style={styles.graphics}>
        {this.props.charts.map((chart, index) => {
          return (
            <Image src={chart} style={styles.chart} />
          );
        })}
      </View>
    );
  }

  showFooter = () => {
    let curDateString = (new Date()).toLocaleString('pt-BR');

    return (
      <View style={styles.footer}>
        <Text>{ curDateString }</Text>
      </View>
    );
  }

  render() {
    return (
      <Document>
        <Page size="A4" style={styles.page}>
          {this.showHeader()}
          {this.showFilterInterval()}
          {this.showFilters()}
          {this.showGraphics()}
          {this.showFooter()}
        </Page>
      </Document>
    );
  }
}

export default ExportPDF;