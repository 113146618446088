import React, { useState } from "react";
import { Grid, TextField, Button, Box } from "@mui/material";
import HeaderForm from "../../HeaderForm"
import MesssagesImage from "../../../../assets/images/messages.svg"
import PropTypes from "prop-types";
import Typography from "../../../Typography"

const MessagesForm = ({ values, handleChange }) => {

    return (
        <Grid Container container spacing={1}>
            <HeaderForm img={MesssagesImage} title="PERSONALIZE SEU ATENDIMENTO" subtitle="Mensagens" width="80%" />
            {values.messages.map((message, i) => (
                <Grid Container container spacing={1} key={i}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Box paddingTop={3}>
                            <Typography color="darkGray" text={message.description} />
                        </Box>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <TextField
                            id=""
                            multiline
                            rows={3}
                            label="Escreva aqui sua mensagem"
                            variant="outlined"
                            name={"messages[" + i + "].message"}
                            onChange={handleChange}
                            value={message.message}
                            fullWidth
                            size="small"
                            required
                        />
                    </Grid>
                </Grid>))}

        </Grid >
    );
};

MessagesForm.propTypes = {
    values: PropTypes.object,
    handleChange: PropTypes.func,
}

export default MessagesForm;
