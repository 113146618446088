import React from 'react';

import html2canvas from 'html2canvas';
import ExportPDF from './ExportPDF';

import { pdf } from "@react-pdf/renderer";
import { saveAs } from 'file-saver';
import { PieChart, Pie, Sector, Tooltip, Legend } from 'recharts';

import { Box, Grid } from "@mui/material";

import { getSession } from "../../auth";

import Typography from '../Typography';
import StackedBarChart from "../Recharts/StackedBarChart";
import SemiRingChart from "../Recharts/SemiRingChart";
import CustomPieChart from "../Recharts/CustomPieChart";

import Loading from '../Loading';

import './index.css';

class ReportGraphs extends React.Component {

    constructor(props) {
        super(props);

        this.operators = {
            'contains'   : 'contém',
            'equals'     : 'é igual a',
            'startWith'  : 'começa com',
            'endsWith'   : 'termina com',
            'isEmpty'    : 'está vazio',
            'isNotEmpty' : 'não está vazio',
            'is'         : 'é',
            'not'        : 'não é',
            'after'      : 'após',
            'onOrAfter'  : 'em ou após',
            'before'     : 'antes de',
            'onOrBefore' : 'em ou antes de'
        };

        this.simpleDateFormat = {
            month : "2-digit",
            day   : "2-digit"
        };

        this.queuesName = {};

        this.state = {
            loading         : false,
            chatPeaks       : [],
            chatStatuses    : [],
            chatChannels    : [],
            serviceStatus   : [],
            csat            : [],
            npsData         : [],
            npsGrade        : {},
            charts          : [],
            activeIndex_np  : 0,
            activeIndex_np2 : 0
        }
    }

    calculateNPS = () => {

        var total      = 0;
        var promoters  = 0;
        var neutrals   = 0;
        var detractors = 0;
        
        this.props.data.forEach((report) => {
            if (report.survey_type !== 'NPS')
                return;

            if(report.survey_numbers?.length > 0) {
                let answers = report.survey_numbers.filter(each=> each.question !== 'comment').map(each => Number(each.value));

                answers.forEach((answer) => {
                    total++;
                    if (answer >= 9)
                        promoters++;
                    else if (answer >= 7)
                        neutrals++;
                    else
                        detractors++;
                });
            }
        });

        let data = [
            { name: 'Promotores', value: promoters, fill: '#5F8963' },
            { name: 'Detratores', value: detractors, fill: '#C95F50' },
            { name: 'Neutros', value: neutrals, fill: '#959595' }
        ];

        let grade = 0;
        
        if (total != 0) {
            grade = Number(((promoters - detractors) * 100 / total).toFixed(0));
        }

        let color = (grade > 0) ? '#5F8963' : '#C95F50';

        return [data, { 'value': grade, 'color': color }];
    }

    calculateCSAT = () => {

        var total = 0;
        var valid = 0; // valid answers are 4 and 5
        
        this.props.data.forEach((report) => {

            if (report.survey_type !== 'CSAT')
                return;

            if(report.survey_numbers?.length > 0) {
                let answers = report.survey_numbers.filter(each=> each.question !== 'comment').map(each => Number(each.value));
                answers.forEach((answer) => {
                    total++;
                    if (answer >= 4)
                        valid++;
                });
            }
        });

        let remainingGrade = 5;
        let csatGrade      = 0;
        let csatColor      = '#C95F50';

        if (total != 0) {
            csatGrade = parseFloat((5*(valid/total)).toFixed(1));

            if (csatGrade >= 3.0) csatColor = '#d5ba7d';
            if (csatGrade >= 4.0) csatColor = '#5F8963';

            remainingGrade -= csatGrade;

        } else {
            remainingGrade = 0;
        }

        return [
            { name: "Positiva", value: csatGrade, color: csatColor}, 
            { name: "Negativa", value: remainingGrade, color: 'white'}
        ];
    }

    buildChatStatuses = () => {

        let data = {};

        // Only date from string
        let startDate = this.props.startDate.substring(0,10);
        let endDate = this.props.endDate.substring(0,10);

        if (startDate == endDate)
            this.addHours(data);
        else
            this.addDays(data);

        this.props.data.forEach((row) => {
            let hour = parseInt(row.time.substring(0,2));
            let key = hour;
            if (startDate != endDate) {
                // Daily dataset
                let date = new Date(row.date + ' 00:00:00');
                key = date.toLocaleDateString('pt-BR', this.simpleDateFormat);
            }

            let status = this.getReportRowStatus(row);
            
            if(!data[key])
                data[key] = {};
            if (!(status in data[key])) {
                data[key][status] = 0;
            }
            data[key][status] += 1;
        });

        let chatStatuses = []
        Object.keys(data).forEach((key) => {
            let name = key;
            if (startDate == endDate)
                name = (key < 10) ? '0'+key : key.toString();
            let statusRow = {'name': name};
            Object.keys(data[key]).forEach((status) => {
                statusRow[status] = data[key][status];
            });
            chatStatuses.push(statusRow);
        });

        return chatStatuses;
    }

    buildChatChannels = () => {
        const channels = {}

        this.props.data.forEach((row) => {
            if (! channels.hasOwnProperty(row.platform_entrypoint))
                channels[row.platform_entrypoint] = 0
            channels[row.platform_entrypoint] += 1
        })

        const colors = ['#ff7f50', '#a9a9a9', '#6b8e23', '#708090', '#ffd700', '#6a5acd', '#4169e1', '#d5ba7d', '#c95f50', '#5f8963']
        
        const getNextColor = () => {
            if (colors.length > 0)
                return colors.pop()
            return '#000000'
        }

        const toTitleCase = (str) => {
            return str.toLowerCase().replace(/\b\w/g, function (char) {
                return char.toUpperCase()
            })
        }

        return Object.keys(channels).map((key) => ({
            name  : toTitleCase(key),
            value : channels[key],
            fill  : getNextColor()
        }))
    }

    buildServiceStatus = () => {
        let normal = 0, timeout = 0, weekend = 0, chatbot = 0, checktime = 0

        this.props.data.forEach((row) => {
            if (row.status === 'Desistência')
                return
            if (row.tags && ['sys_timeout', 'sys_timeout_fds', 'sys_fora_hora'].some(s => row.tags.includes(s))) {
                let tagList = row.tags.split(',')
                if (tagList.includes('sys_timeout')) {
                    timeout += 1
                    return
                }
                if (tagList.includes('sys_timeout_fds')) {
                    weekend += 1
                    return
                }
                if (tagList.includes('sys_fora_hora')) {
                    checktime += 1
                    return
                }
                return
            }
            if (! row.agent) {
                chatbot += 1
                return
            }
            if (row.status === 'Atendida' && row.agent) {
                normal += 1
                return
            }
        })

        return [
            { name: "Normal", value: normal, fill: '#5f8963' },
            { name: "Timeout", value: timeout, fill: '#c95f50' },
            { name: "Timeout (FDS)", value: weekend, fill: '#d5ba7d' },
            { name: "Retidos", value: chatbot, fill: '#4169e1' },
            { name: "Fora hora", value: checktime, fill: '#6a5acd' }
        ];
    }

    getReportRowStatus = (row) => {
        if (!row.queue_name) 
            return 'ChatBot';
        return row.status;
    }

    buildChatPeaks = () => {
        let data = {};

        // Only date from string
        let startDate = this.props.startDate.substring(0,10);
        let endDate = this.props.endDate.substring(0,10);

        if (startDate == endDate)
            this.addHours(data);
        else
            this.addDays(data);

        this.props.data.forEach((row) => {
            let hour = parseInt(row.time.substring(0,2));
            let queue = row.queue_name;
            
            if (!queue) return;
            
            let key = hour;
            if (startDate != endDate) {
                // Daily dataset
                let date = new Date(row.date + ' 00:00:00');
                key = date.toLocaleDateString('pt-BR', this.simpleDateFormat);
            }

            if(!data[key])
                data[key] = {};
            if (!(queue in data[key]))
                data[key][queue] = 0;
            if (queue && this.isPickedupChat(row))
                data[key][queue] += 1;
        });

        let chatPeaks = []
        Object.keys(data).forEach((key) => {
            let name = key;
            if (startDate == endDate)
                name = (key < 10) ? '0'+key : key.toString();
            let peakRow = {'name': name};
            Object.keys(data[key]).forEach((queue) => {
                const queue_name    = this.queuesName[queue];
                peakRow[queue_name] = data[key][queue];
            });
            chatPeaks.push(peakRow);
        });

        return chatPeaks;
    }

    addDays = (data) => {

        let startDate = new Date(this.props.startDate);
        startDate.setHours(0,0,0,0);
        
        let endDate = new Date(this.props.endDate);
        endDate.setHours(0,0,0,0);
        
        let days = (endDate - startDate) / (1000 * 3600 * 24);

        let date = startDate;
        for (let i = 0; i <= days; i++) {
            data[date.toLocaleDateString('pt-BR', this.simpleDateFormat)] = {};
            date.setDate(date.getDate() + 1);
        }
    }

    addHours = (data) => {
        let hours = [...Array(24).keys()];
        hours.forEach((hour) => {
            data[hour] = {};
        });
    }

    isPickedupChat = (row) => {
        return row.status == 'Atendida';
    }

    exportToPDF = () => {
        this.setState({ loading: true });

        const charts  = this.addPDFGraphics();
        const filters = this.addPDFFilters();
        const curDate = (new Date()).toLocaleDateString('pt-BR');

        charts.then(res => {
            this.generatePDF(filters, res, curDate);
        });
    }

    generatePDF = (filters, charts, curDate) => {
        pdf(
            <ExportPDF 
                startDate={this.props.startDate}
                endDate={this.props.endDate}
                filters={filters}
                charts={charts}
            />
        ).toBlob().then(blob => {
            saveAs(blob, `Kwik_${curDate}.pdf`);

            this.setState({ loading: false });
        });
    }

    addPDFFilters = () => {
        const filtersArray = [];

        this.props.filters.forEach(filter => {
            let field    = this.getFieldColumnName(filter.columnField);
            let operator = this.translateOperator(filter.operatorValue);
            
            let value = filter.value;
            if (value.length == 16 && Date.parse(value))
                value = (new Date(value)).toLocaleString('pt-BR');

            filtersArray.push(`\u2022 ${field} ${operator} ${value}`);
        });

        return filtersArray;
    }

    addPDFGraphics = () => {
        const charts = document.querySelectorAll(".chart-box");
        let promiseArray = [];

        Array.from(charts).map(chart => {
            promiseArray.push(new Promise((resolve, reject) => {
                html2canvas(chart).then(canvas => {
                    resolve(canvas.toDataURL('image/png'));
                });
            }));
        });

        return Promise.all(promiseArray);
    }

    translateOperator = (operator) => {
        if (this.operators[operator])
            return this.operators[operator];
        return operator;
    }

    getFieldColumnName = (field) => {
        let columns = this.props.columns;
        for (var i = 0; i < columns.length; i++) {
            if (columns[i].field == field)
                return columns[i].headerName;
        }
    }

    refresh = () => {

        let chatPeaks     = this.buildChatPeaks();
        let chatStatuses  = this.buildChatStatuses();
        let chatChannels  = this.buildChatChannels();
        let serviceStatus = this.buildServiceStatus();
        let csat          = this.calculateCSAT();
        let nps           = this.calculateNPS();

        this.setState({
            chatPeaks     : chatPeaks,
            chatStatuses  : chatStatuses,
            chatChannels  : chatChannels,
            serviceStatus : serviceStatus,
            csat          : csat,
            npsData       : nps[0],
            npsGrade      : nps[1]
        })
    }

    onPieEnter_np = (_, index) => {
        this.setState({ activeIndex_np: index });
    };

    onPieEnter_np2 = (_, index) => {
        this.setState({ activeIndex_np2: index });
    };

    renderActiveShape = (props) => {
      const RADIAN = Math.PI / 180;
      const {
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        startAngle,
        endAngle,
        fill,
        payload,
        percent,
        value
      } = props;

      const sin = Math.sin(-RADIAN * midAngle);
      const cos = Math.cos(-RADIAN * midAngle);
      const sx = cx + (outerRadius + 10) * cos;
      const sy = cy + (outerRadius + 10) * sin;
      const mx = cx + (outerRadius + 30) * cos;
      const my = cy + (outerRadius + 30) * sin;
      const ex = mx + (cos >= 0 ? 1 : -1) * 22;
      const ey = my;

      const textAnchor = cos >= 0 ? "start" : "end";

      return (
        <g>
          <Sector
            cx={cx}
            cy={cy}
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            startAngle={startAngle}
            endAngle={endAngle}
            fill={fill}
          />
          <Sector
            cx={cx}
            cy={cy}
            startAngle={startAngle}
            endAngle={endAngle}
            innerRadius={outerRadius + 6}
            outerRadius={outerRadius + 10}
            fill={fill}
          />
          <path
            d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
            stroke={fill}
            fill="none"
          />
          <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
          <text
            x={ex + (cos >= 0 ? 1 : -1) * 12}
            y={ey}
            textAnchor={textAnchor}
            fill="#333"
            fontSize="18px"
          >
            {`${value}`}
          </text>
          <text
            x={ex + (cos >= 0 ? 1 : -1) * 12}
            y={ey}
            dy={18}
            textAnchor={textAnchor}
            fill="#999"
            fontSize="12px"
          >
            {`${(percent * 100).toFixed(0)}%`}
          </text>
        </g>
      );
    }

    loadChartComponent = ({ active, payload }) => {
        if (active && payload && payload.length) {
            return (
                <Box style={{ padding: "1rem"}} className="dashboard-custom-tooltip">
                    <Box style={{ fontWeight: 100 }} className="dashboard-custom-tooltip-label">
                        {payload[0].name}
                    </Box>
                </Box>
            );
        }

        return null;
    };

    componentWillMount() {
        const user     = getSession();
        let queuesName = {};

        // Save code references to capture queue names

        user.profile.enterprise.queues.forEach(queue => {
            const name_queue = queue.name.split('_')[1];

            queuesName[name_queue] = queue.description;
        });

        this.queuesName = queuesName;

        this.refresh();
    }

    render() {
        return (
            <Box className="report-graphs">
                <Loading loading={this.state.loading}/>
                <Grid container spacing={2} paddingLeft="25px">
                    {this.props.activeGraphics['serviceQueues'].active &&
                        <Grid item lg={4} md={6}>
                            <Box className="chart-box">
                                <Box id="fontSize-title">
                                    <Typography align="center"
                                        text="Atendimentos (filas)" 
                                        color="darkGray" 
                                        fontWeight='500' 
                                    />
                                </Box>
                                <StackedBarChart data={this.state.chatPeaks}/>
                            </Box>
                        </Grid>
                    }
                    { this.props.activeGraphics['serviceStatus'].active &&
                        <Grid item lg={4} md={6}>
                            <Box className="chart-box">
                                <Box id="fontSize-title">
                                    <Typography align="center" 
                                        text="Atendimentos (status)" 
                                        color="darkGray" 
                                        fontWeight='500' 
                                    />
                                </Box>
                                <StackedBarChart data={this.state.chatStatuses}/>
                            </Box>
                        </Grid>
                    }
                    { this.props.activeGraphics['serviceStatus'].active &&
                        <Grid item lg={4} md={6}>
                            <Box className="chart-box">
                                <Box id="fontSize-title">
                                    <Typography align="center" 
                                        text="Tipos de atendimento" 
                                        color="darkGray" 
                                        fontWeight='500' 
                                    />
                                </Box>
                                <PieChart width={500} height={250} style={{ height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <Pie
                                        activeIndex={this.state.activeIndex_np}
                                        activeShape={this.renderActiveShape}
                                        data={this.state.serviceStatus}
                                        cx={250}
                                        cy={100}
                                        innerRadius={0}
                                        outerRadius={70}
                                        fill="#8884d8"
                                        dataKey="value"
                                        onMouseEnter={this.onPieEnter_np}
                                    />
                                    <Tooltip offset={-50} content={this.loadChartComponent} />
                                    <Legend
                                        layout="horizontal" 
                                        verticalAlign="bottom" 
                                        align="center"
                                    />
                                </PieChart>
                            </Box>
                        </Grid>
                    }
                    { this.props.activeGraphics['serviceStatus'].active &&
                        <Grid item lg={4} md={6}>
                            <Box className="chart-box">
                                <Box id="fontSize-title">
                                    <Typography align="center" 
                                        text="Canais de atendimento" 
                                        color="darkGray" 
                                        fontWeight='500' 
                                    />
                                </Box>
                                <PieChart width={500} height={250} style={{ height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <Pie
                                        activeIndex={this.state.activeIndex_np2}
                                        activeShape={this.renderActiveShape}
                                        data={this.state.chatChannels}
                                        cx={250}
                                        cy={100}
                                        innerRadius={0}
                                        outerRadius={70}
                                        fill="#8884d8"
                                        dataKey="value"
                                        onMouseEnter={this.onPieEnter_np2}
                                    />
                                    <Tooltip offset={-50} content={this.loadChartComponent} />
                                    <Legend
                                        layout="horizontal" 
                                        verticalAlign="bottom" 
                                        align="center"
                                    />
                                </PieChart>
                            </Box>
                        </Grid>
                    }
                    { this.props.activeGraphics['csatSurvey'].active &&
                        <Grid item lg={4} md={6}>
                            <Box className="chart-box">
                                {(Number(this.state.csat[0].value) !== 0  ||
                                  Number(this.state.csat[1].value) !== 0) &&
                                    <Box className="report-csat">
                                        <SemiRingChart data={this.state.csat} />
                                        <Box className="report-csat-value">
                                            <Box id="fontSize-title">
                                                <Typography align="center" 
                                                    text="Nota CSAT" 
                                                    color="darkGray" 
                                                    fontWeight='500' 
                                                />
                                            </Box>
                                            <Box className="csat-grade" 
                                                style={{color: this.state.csat[0].color}}
                                            >
                                                {this.state.csat[0].value}
                                            </Box>
                                        </Box>
                                    </Box>
                                }
                                {(Number(this.state.csat[0].value) === 0  &&
                                  Number(this.state.csat[1].value) === 0) &&
                                    <Box className="fontSize-title data-null">
                                        <Typography align="center" 
                                            text="Sem dados da pesquisa CSAT" 
                                            color="darkGray" 
                                            fontWeight='400' 
                                        />
                                    </Box>
                                }
                            </Box>
                        </Grid>
                    }
                    { this.props.activeGraphics['npsSurvey'].active &&
                        <Grid item lg={4} md={6}>
                            <Box className="chart-box">
                                {Number(this.state.npsGrade.value) !== 0 &&
                                    <Box className="report-nps">
                                        <CustomPieChart data={this.state.npsData} />
                                        <Box className="report-nps-value">
                                            <Box id="fontSize-title">
                                                <Typography align="center" 
                                                    text="Nota NPS" 
                                                    color="darkGray" 
                                                    fontWeight='500' 
                                                />
                                            </Box>
                                            <Box className="nps-grade"
                                                style={{color: this.state.npsGrade.color}}
                                            >
                                                {this.state.npsGrade.value}
                                            </Box>
                                        </Box>
                                    </Box>
                                }
                                {Number(this.state.npsGrade.value) === 0 &&
                                    <Box className="fontSize-title data-null">
                                        <Typography align="center" 
                                            text="Sem dados da pesquisa NPS" 
                                            color="darkGray" 
                                            fontWeight='400' 
                                        />
                                    </Box>
                                }
                            </Box>
                        </Grid>
                    }
                </Grid>
            </Box>
        );
    }
}

export default ReportGraphs;
