import React, { useState, useEffect } from 'react'

import { Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import useNotification from '../../Notification'
import api from '../../../api'
import { getSession, saveSession, clearSession, clearToken, getToken } from '../../../auth'

const IdentificateAgentDialog = ({refresh}) => {
    const [name, setName] = useState('')

    const logoutAgent = () => {
        const user = getSession();
        if (user.profile.agent_id) {
            const token = getToken();
            // socket.send(JSON.stringify({"message": "LOGOUT"}));
            // api.post(`/api/IV/agents/${user.profile.agent_id}/logout/`)
                // .then((res) => {
                //     if (res.status == 200) {
            clearSession();
            clearToken();
            document.location.href = '/';
                        // api.post('/auth/token/blacklist/')
                        // .then((res) => {
                        //     clearSession();
                        //     clearToken();
                        //     document.location.href = '/'; 
                        // });
                //         return;
                //     }
                //     throw res;
                // })
                // .catch((err) => {
                //     if (err.data) {
                //             useNotification('Atenção!', err.data, 'warning');
                //     }
                // });
        }
    };

    const saveAgent = () => {
        const session = getSession()
        session.profile.enterprise.settings.isAgentIdentificated = name
        saveSession(session)
        refresh()
    }

    return (
        <>
            <Dialog keepMounted
                open={true}
                onClose={() => { }}
                aria-labelledby="customized-dialog-title"
                fullWidth
                disablePortal
                disableEnforceFocus
                sx={{position:'absolute'}}
            >
                <DialogTitle id="customized-dialog-title" className={'group-flexbox'}>
                    <span>É necessário a identificação do usuário para logar no kwik</span>
                </DialogTitle>
                <DialogContent dividers>
                    <TextField
                        size="small"
                        label="Nome"
                        fullWidth
                        type="text"
                        variant="outlined"
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                    />
                </DialogContent>

                <DialogActions style={{ padding: "10px 20px 10px 10px" }}>
                        <LoadingButton
                            onClick={logoutAgent}
                            color="primary"
                            loading={false}
                        >
                            Sair
                        </LoadingButton>
                        <LoadingButton
                            onClick={saveAgent}
                            color="primary"
                            disabled={!name}
                            loading={false}
                        >
                            Continuar
                        </LoadingButton>
                    </DialogActions>
            </Dialog>
        </>
    )
}

export default IdentificateAgentDialog