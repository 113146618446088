import React, { useState, useEffect } from 'react';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
    Divider, 
    Tooltip, 
    Box,
    TextField,
    Select,
    FormControl,
    InputLabel,
    MenuItem
} from '@mui/material';

import LoadingButton from '@mui/lab/LoadingButton';

import PropTypes from "prop-types";

import CloseIcon from '@mui/icons-material/Close';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';

import "./styles.css"

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const CustomFieldsDialog = ({ open, customFields, handleClose, handleSubmit }) => {

    const [toUpdateFields, setToUpdateFields] = useState([])
    const [toDeleteFields, setToDeleteFields] = useState([])
    const [toCreateFields, setToCreateFields] = useState([])

    useEffect(() => {
        setToUpdateFields(customFields)
    }, [])

    const submit = () => {
        handleSubmit(toUpdateFields, toCreateFields, toDeleteFields)
    }

    return (
        <div>
            <Dialog
                open={open}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                disableEnforceFocus
                fullWidth
            >
                <DialogTitle id="alert-dialog-slide-title" className={'group-flexbox'}>
                    <span>Campos customizados</span>
                    <Tooltip title="Fechar">
                        <Button className="action-button">
                            <CloseIcon onClick={handleClose} />
                        </Button>
                    </Tooltip>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    {toUpdateFields.map((field, index) => (
                        <Box className="custom-field-row">
                            <TextField fullWidth
                                label="Nome"
                                size="small"
                                type="text"
                                value={field.field_name}
                                onChange={(event) => {
                                    const newFields = [...toUpdateFields]
                                    newFields[index].field_name = event.target.value
                                    setToUpdateFields(newFields)
                                }}
                            />
                            <FormControl fullWidth>
                                <InputLabel id="custom-field-type-select">Tipo</InputLabel>
                                <Select
                                    size="small"
                                    labelId="custom-field-type-select"
                                    value={field.field_type}
                                    label="Tipo"
                                    onChange={(event) => {
                                        const newFields = [...toUpdateFields]
                                        newFields[index].field_type = event.target.value
                                        setToUpdateFields(newFields)
                                    }}
                                >
                                    <MenuItem value="string">Texto</MenuItem>
                                    <MenuItem value="integer">Número</MenuItem>
                                    <MenuItem value="boolean">Sim/Não</MenuItem>
                                </Select>
                            </FormControl>
                            <Button 
                                className="remove-row-icon" 
                                variant="contained" 
                                color="error"
                                onClick={() => {
                                    setToDeleteFields([...toDeleteFields, ...[field.id]])
                                    setToUpdateFields([...toUpdateFields].filter((row) => row.id !== field.id))
                                }}
                            >
                                <RemoveIcon />
                            </Button>
                        </Box>
                    ))}
                    {toCreateFields.map((field, index) => (
                        <Box className="custom-field-row">
                            <TextField fullWidth
                                label="Nome"
                                size="small"
                                type="text"
                                value={field.field_name}
                                onChange={(event) => {
                                    const newFields = [...toCreateFields]
                                    newFields[index].field_name = event.target.value
                                    setToCreateFields(newFields)
                                }}
                            />
                            <FormControl fullWidth>
                                <InputLabel id="custom-field-type-select">Tipo</InputLabel>
                                <Select
                                    size="small"
                                    labelId="custom-field-type-select"
                                    value={field.field_type}
                                    label="Tipo"
                                    onChange={(event) => {
                                        const newFields = [...toCreateFields]
                                        newFields[index].field_type = event.target.value
                                        setToCreateFields(newFields)
                                    }}
                                >
                                    <MenuItem value="string">Texto</MenuItem>
                                    <MenuItem value="integer">Número</MenuItem>
                                    <MenuItem value="boolean">Sim/Não</MenuItem>
                                </Select>
                            </FormControl>
                            <Button 
                                className="remove-row-icon" 
                                variant="contained" 
                                color="error"
                                onClick={() => {
                                    setToCreateFields([...toCreateFields].filter((row, idx) => idx !== index))
                                }}
                            >
                                <RemoveIcon />
                            </Button>
                        </Box>
                    ))}
                    <Button fullWidth 
                        variant="contained"
                        onClick={() => {
                            setToCreateFields([...toCreateFields, ...[{
                                field_name : '',
                                field_type : 'string',
                            }]])
                        }}
                    >
                        <AddIcon />
                    </Button>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancelar
                    </Button>
                    <LoadingButton onClick={submit} color="primary">
                        Salvar
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </div>
    );
}

CustomFieldsDialog.propTypes = {
    open: PropTypes.bool,
    customFields: PropTypes.array,
    handleClose: PropTypes.func,
    handleSubmit: PropTypes.func
};

export default CustomFieldsDialog;
