import { Box, List, ListItem } from "@mui/material";
import React, { useState, useMemo, useEffect } from "react";

import styles from './style.module.css'

import { FacebookPlugin } from "../components/Settings/Instagram";
import api from "../api";

const Page1 = ({ parent, setParent, setLoading }) => {
    const [state, setState] = useState(1)

    const reloadState = () => {setTimeout(()=>setState(p=>p+1), 500)}

    const setconnectedpage = async (data) => {
        
        if (!data.authResponse) {
            setParent({access_token: ''})
            return
        }
        if (parent.access_token !== '') return
        const token = data?.authResponse?.accessToken
        const userID = data?.authResponse?.userID
        if (token) {
            setLoading(true)
            const res = await api.post(`/api/registerFacebook/`, { token: token, userID: userID  }, {timeout:30000})
            setLoading(false)
            if (res.status === 200) {
                setParent(res.data)
                return
            }
            setParent({})
        }
    }

    const facebookPlugin = useMemo(() => {
		return (<FacebookPlugin english={true} reloadState={reloadState} reload={state} setConnectedPage={setconnectedpage}/>)
	}, [state])

    useEffect(()=>{
        reloadState()
    }, [])

    return (
        <Box className={styles.fulltext}>
            <List>
                <ListItem><b>Hello! Welcome to our automated Messenger and Instagram experience. Follow this tutorial and you will be able to test our features successfully.</b></ListItem>
                <ListItem>First step - Facebook login.</ListItem>
                <ListItem>Click on the button below and allow our app to access your facebook page and instagram.</ListItem>
                <ListItem>We will also provide test facebook credentials here in case you're unable to receive our messages.</ListItem>
                <ListItem><b>Please be patient while waiting for the requests to complete and for the facebook login to show.</b></ListItem>
                <ListItem><b>Please exit and log-in again using facebook login and select the instagram account and page account.</b></ListItem>
                <ListItem sx={{ paddingLeft: 0 }}>{facebookPlugin}</ListItem>
                {parent.access_token !== '' && <>
                    <ListItem>Connected pages: </ListItem>
                    {parent.data.map((item) => 
                        <ListItem><b>{item.page_id} - {item.name} -
                        {item.pilot_messenger ? <span style={{color:'green'}}> Messenger OK</span> : <span style={{color:'red'}}> Messenger NOK</span>} - 
                        {item.pilot_instagram ? <span style={{color:'green'}}> Instagram OK</span> : <span style={{color:'red'}}> Instagram NOK</span>}</b></ListItem>)}
                    </>
                }
            </List>
        </Box>
    )
}

export default Page1