import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

import { 
  DialogTitle, 
  DialogContent, 
  Box, 
  Switch, 
  Dialog, 
  Slide, 
  Tooltip,  
  Button,
  Divider
} from "@mui/material";

import "./index.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class GraphicsSettings extends React.Component {
  
  constructor(props) {
    super(props);
  }

  setChecked = (key, condition) => {
      let activeGraphics = {...this.props.activeGraphics};

      activeGraphics[key].active = condition;

      this.props.updateActiveGraphics(activeGraphics);
  }

  render() {
    return (
      <Box>
        <Dialog
          open={this.props.open}
          //TransitionComponent={Transition}
          keepMounted
          onClose={this.props.handleClose}
          aria-describedby="alert-dialog-slide-description"
          fullWidth
          maxWidth="xs"
          disablePortal
          disableEnforceFocus
          sx={{position:'absolute'}}
        >
          <DialogTitle id="customized-dialog-title" className={'group-flexbox'}>
              <span>Opções de Visualização dos Gráficos</span>
              <Tooltip title="Fechar" >
                  <Button className="action-button">
                      <CloseIcon onClick={this.props.handleClose} />
                  </Button>
              </Tooltip>
          </DialogTitle>
          <Divider />
          <DialogContent>
            {Object.keys(this.props.activeGraphics).map((key, index) => (
                <Box key={index}>
                  <Switch
                    checked={this.props.activeGraphics[key].active}
                    inputProps={{ 'aria-label': 'controlled' }}
                    onChange={() => this.setChecked(key, !this.props.activeGraphics[key].active)} 
                  />
                  {this.props.activeGraphics[key].title}
                </Box>
              ))
            }
          </DialogContent>
        </Dialog>
      </Box>
    );
  }
}

export default GraphicsSettings;