import React, { Children, useState, useEffect, useMemo } from 'react';

import {
  Grid,
  Button,
  TextField,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import ImportIcon from '@mui/icons-material/Upload';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import StrikethroughSIcon from '@mui/icons-material/StrikethroughS';
import CodeIcon from '@mui/icons-material/Code';
import useNotification from '../../../../Notification';
import ControlPointIcon from '@mui/icons-material/ControlPoint';

// import { FileDrop } from 'react-file-drop';

import './styles.css';
import ButtonBlock from './ButtonBlock';

const MediaInteractiveBody = ({ isMethodRead, getMediaValues, initialize, mediaBody, preview }) => {
  const [isHeaderDisabled, setIsHeaderDisabled] = useState(false);
  const [isFooterDisabled, setIsFooterDisabled] = useState(false);
  const [isButtonsDisabled, setIsButtonsDisabled] = useState(false);
  const [render, rerender] = useState(false)

  const [headerType, setHeaderType] = useState('TEXT');
  const [selectedButtonType, setSelectedButtonType] = useState('URL')
  const [buttonArray, setButtonArray] = useState([{
    type: 'QUICK_REPLY', 
    text: 'Unsubscribe'
  }])

  const [headerTextContent, setHeaderTextContent] = useState('');
  const [bodyTextContent, setBodyTextContent] = useState('');
  const [footerTextContent, setFooterTextContent] = useState('');

  const [buttonLimit, setButtonLimit] = useState(5)
  const [showButtons, setShowButtons] = useState(false)

  const matchRegLen = value => {
    const matcher = value.match(/{{\d+}}/g)
    if (matcher)
      return matcher.length
    return 0
  }

  const setFormattingOnBody = (formattingCharacter) => {
    if (!bodyTextContent)
      return;

    const selectedText = window.getSelection().toString();
    const caretIndexStart = document.getElementById('body-textarea').selectionStart;
    const caretIndexEnd = document.getElementById('body-textarea').selectionEnd;

    if (selectedText) {
      setBodyTextContent(bodyTextContent.substring(0, caretIndexStart) + `${formattingCharacter}${selectedText}${formattingCharacter}` + bodyTextContent.substring(caretIndexEnd, bodyTextContent.length));
    } else {
      setBodyTextContent(bodyTextContent.substring(0, caretIndexStart) + `${formattingCharacter}${formattingCharacter}` + bodyTextContent.substring(caretIndexStart, bodyTextContent.length));
    }
  }

  useEffect(() => {
    if (!initialize)
      return
    if (mediaBody) {
      setIsHeaderDisabled(true)
      setIsFooterDisabled(true)
      setIsButtonsDisabled(true)
      mediaBody.map(component => {
        if (component.type == 'HEADER') {
          'text' in component ? setHeaderTextContent(component.text) : null
          'format' in component ? setHeaderType(component.format) : null
          setIsHeaderDisabled(false)
        }
        else if (component.type == 'BODY') {
          'text' in component ? setBodyTextContent(component.text) : null
        }
        else if (component.type == 'FOOTER') {
          'text' in component ? setFooterTextContent(component.text) : null
          setIsFooterDisabled(false)
        }
        else if (component.type == 'BUTTONS') {
          setButtonArray(component.buttons)
          setIsButtonsDisabled(false)
          setShowButtons(true)
          if (component.buttons[0].type == 'QUICK_REPLY')
            setButtonLimit(3)
          else
            setButtonLimit(2)
        }
      })
    }
  }, [])


  const handleMediaValues = () => {
    const mediaValues = []

    //HEADER
    if (!isHeaderDisabled) {
      const headerComp = {
        "type": "HEADER",
        "format": headerType,
      }
      const myURL = location.origin
      switch (headerType) {
        case 'TEXT':
          //Add parameters
          headerComp.text = headerTextContent
          headerComp.example = {
            "header_text":
              ['{{HEADER_HANDLE}}']
          }
          break
        case 'IMAGE':
          headerComp.example = {
            "header_handle":
              [`{{HEADER_HANDLE}}`]
          }
          break
        case 'DOCUMENT':
          headerComp.example = {
            "header_handle":
              [`{{HEADER_HANDLE}}`]
          }
          break
        case 'VIDEO':
          headerComp.example = {
            "header_handle":
              [`{{HEADER_HANDLE}}`]
          }
          break
        default:
          break
      }
      mediaValues.push(headerComp)
    }

    //BODY
    //Add parameters
    const bodyComp = {
      "type": "BODY",
      "text": bodyTextContent,
    }
    const variables = matchRegLen(bodyTextContent)
    const resultingArray = Array(variables).fill('{{TEXT_EXAMPLE}}')
    if (resultingArray.length > 0)
      bodyComp.example = {"body_text":resultingArray}
    mediaValues.push(bodyComp)

    //FOOTER
    if (!isFooterDisabled) {
      const footerComp = {
        "type": "FOOTER",
        "text": footerTextContent
      }
      mediaValues.push(footerComp)
    }

    //BUTTONS
    if (!isButtonsDisabled) {
      const buttonsComp = {
        type: "BUTTONS",
        "buttons": buttonArray
      }
      mediaValues.push(buttonsComp)
    }

    getMediaValues(mediaValues)
  }

  const test = useMemo(() => {
    handleMediaValues()
  }, [
    isHeaderDisabled,
    headerType,
    headerTextContent,
    bodyTextContent,
    isFooterDisabled,
    footerTextContent,
    isButtonsDisabled,
    buttonArray,
    render,
  ])

  const handleButtonArray = () => {
    const amount = buttonArray.length
    const types = buttonArray.map(button => button.type)

    if (amount >= buttonLimit) {
      useNotification(
        'Falha!',
        'Máximo número de botões atingido.',
        'danger'
      );
      return
    }

    if (
      (selectedButtonType == 'URL' && types.length > 0 && types.includes('URL')) || 
      (selectedButtonType == 'PHONE_NUMBER' && types.length > 0 && types.includes('PHONE_NUMBER'))
    ){
      useNotification(
        'Falha!',
        'Esse tipo de botão não pode ser adicionado.',
        'danger'
      );
      return
    }

    if (
      (selectedButtonType == 'QUICK_REPLY' && types.length > 0 && !types.includes('QUICK_REPLY')) ||
      (selectedButtonType != 'QUICK_REPLY' && types.length > 0 & types.includes('QUICK_REPLY'))
    ) {
      useNotification(
        'Falha!',
        'Só adicione botões de resposta rápida com outros botões de resposta rápida.',
        'danger'
      );
      return
    }

    if(selectedButtonType)
    setButtonArray(prevState => [...prevState, { type: selectedButtonType }])



    if (selectedButtonType == 'QUICK_REPLY')
      setButtonLimit(3)
    else{
      setButtonLimit(2)
      setSelectedButtonType(prevState => prevState == 'URL' ? 'PHONE_NUMBER' : 'URL')
    }
      
  }




  return (
    <Grid container spacing={3}>
      <Grid item lg={3} md={3} sm={3} xs={3}>
        <Button
          fullWidth
          variant="contained"
          className={isHeaderDisabled ? 'fake-disabled' : ''}
          onClick={() => setIsHeaderDisabled(prev => !prev)}
        >
          Cabeçalho
        </Button>
      </Grid>

      {/* TODO: Colocar um alerta informando que não é possível remover BODY */}
      <Grid item lg={3} md={3} sm={3} xs={3}>
        <Button
          fullWidth
          variant="contained"
        >
          Corpo
        </Button>
      </Grid>

      <Grid item lg={3} md={3} sm={3} xs={3}>
        <Button
          fullWidth
          variant="contained"
          className={isFooterDisabled ? 'fake-disabled' : ''}
          onClick={() => setIsFooterDisabled(prev => !prev)}
        >
          Rodapé
        </Button>
      </Grid>

      <Grid item lg={3} md={3} sm={3} xs={3}>
        <Button
          fullWidth
          variant="contained"
          className={isButtonsDisabled ? 'fake-disabled' : ''}
          onClick={() => setIsButtonsDisabled(prev => !prev)}
        >
          Botões
        </Button>
      </Grid>

      {!isHeaderDisabled && (
        <Grid container item spacing={0.5}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <FormControl
              fullWidth
              size="small"
            >
              <InputLabel id="header-select-label">Tipo (Cabeçalho)</InputLabel>
              <Select
                id="header-select"
                labelId="header-select-label"
                value={headerType}
                label="Tipo (Cabeçalho)"
              >
                <MenuItem value="TEXT" onClick={() => setHeaderType('TEXT')}>Texto</MenuItem>
                <MenuItem value="IMAGE" onClick={() => setHeaderType('IMAGE')}>Imagem</MenuItem>
                <MenuItem value="DOCUMENT" onClick={() => setHeaderType('DOCUMENT')}>Documento</MenuItem>
                <MenuItem value="VIDEO" onClick={() => setHeaderType('VIDEO')}>Vídeo</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} sx={{ marginTop: '0.5rem' }}>
            {headerType === 'TEXT' ? (
              <>
                <TextField
                  multiline
                  rows="2"
                  size="small"
                  label="Cabeçalho"
                  fullWidth
                  inputProps={{ maxLength: 60 }}
                  type="text"
                  variant="outlined"
                  value={headerTextContent}
                  onChange={(event) => setHeaderTextContent(event.target.value)}
                />
                <Box className={'templates-footer flexedGap'}>
                  <Box className="character-counter positionUnset" >
                    {`${headerTextContent.length} / 60 caracteres`}
                  </Box>
                  <Box className="character-counter positionUnset" >
                    {`${matchRegLen(headerTextContent)} / 1 variáveis`}
                  </Box>
                </Box>
              </>
            ) : (
              <>

                {/* <div className="file-drop-container">
                 <FileDrop
                  // onFrameDragEnter={(event) => console.log('onFrameDragEnter', event)}
                  // onFrameDragLeave={(event) => console.log('onFrameDragLeave', event)}
                  // onFrameDrop={(event) => console.log('onFrameDrop', event)}
                  // onDragOver={(event) => console.log('onDragOver', event)}
                  // onDragLeave={(event) => console.log('onDragLeave', event)}
                  onDrop={(files, event) => console.log('onDrop!', files, event)}
                >
                  <span>Arraste e solte para inserir um arquivo</span>
                  <span>ou</span>
                  <LoadingButton
                    variant="contained"
                    color="primary"
                    component="label"
                    loading={false}
                    loadingPosition="start"
                    startIcon={<ImportIcon />}
                    disabled={false}
                    onClick={() => console.log('oi')}
                  >
                    Selecione o arquivo
                    <input
                      hidden
                      type="file"
                      onChange={() => console.log('onChange')}
                    />

                  </LoadingButton>
                </FileDrop> 
              </div>*/}
              </>
            )}

          </Grid>
        </Grid>
      )}

      {/**  
        *  Body doesn't have conditional rendering because 
        * it's always required to have a body 
      */}
      <Grid container item spacing={0.5}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <IconButton onClick={() => setFormattingOnBody('*')} size="small">
            <FormatBoldIcon color="primary" />
          </IconButton>
          <IconButton onClick={() => setFormattingOnBody('_')} size="small">
            <FormatItalicIcon color="primary" />
          </IconButton>
          <IconButton onClick={() => setFormattingOnBody('~')} size="small">
            <StrikethroughSIcon color="primary" />
          </IconButton>
          <IconButton onClick={() => setFormattingOnBody('```')} size="small">
            <CodeIcon color="primary" />
          </IconButton>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <TextField
            id="body-textarea"
            multiline
            rows="4"
            size="small"
            label="Corpo"
            fullWidth
            type="text"
            variant="outlined"
            inputProps={{ maxLength: 1024 }}
            value={bodyTextContent}
            onChange={(event) => setBodyTextContent(event.target.value)}
          />
          <Box className={'templates-footer flexedGap'}>
            <Box className="character-counter positionUnset" >
              {`${bodyTextContent.length} / 1024 caracteres`}
            </Box>
            <Box className="character-counter positionUnset" >
              {`${matchRegLen(bodyTextContent)} / 10 variáveis`}
            </Box>
          </Box>
        </Grid>
      </Grid>

      {!isFooterDisabled && (
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <>
            <TextField
              multiline
              rows="2"
              size="small"
              label="Rodapé"
              fullWidth
              type="text"
              variant="outlined"
              inputProps={{ maxLength: 60 }}
              value={footerTextContent}
              onChange={(event) => setFooterTextContent(event.target.value)}
            />
            <Box className={'templates-footer flexedGap'}>
              <Box className="character-counter positionUnset" >
                {`${footerTextContent.length} / 60 caracteres`}
              </Box>
            </Box>
          </>
        </Grid>
      )}

      {!isButtonsDisabled && (
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box sx={{ 'display': 'flex', 'alignItems': 'flex-end' }}>
            <FormControl
              size="small"
              sx={{ padding: '0.25rem', maxWidth: "15rem", minWidth: "11rem" }}
            >
              <InputLabel id="header-select-label">Tipo (Botão)</InputLabel>
              <Select
                id="header-select"
                labelId="header-select-label"
                value={selectedButtonType}
                label="Tipo (Cabeçalho)"
              >
                {buttonArray.filter(button => button.type == 'URL').length == 0 ? 
                  <MenuItem value="URL" onClick={() => { setSelectedButtonType('URL') }}>Link</MenuItem>
                : null}
                {buttonArray.filter(button => button.type == 'PHONE_NUMBER').length == 0 ?
                <MenuItem value="PHONE_NUMBER" onClick={() => { setSelectedButtonType('PHONE_NUMBER') }}>Telefone</MenuItem>
                : null}
                <MenuItem value="QUICK_REPLY" onClick={() => { setSelectedButtonType('QUICK_REPLY') }}>Resposta rápida</MenuItem>
              </Select>
            </FormControl>
            <Button
              onClick={handleButtonArray}
              sx={{ 'marginBottom': ".25rem" }}
              endIcon={<ControlPointIcon />}
            >
              Adicionar botão</Button>
          </Box>
          {buttonArray.length > 0 &&
            <>
              <Box className={'button-box-new-template'} >
                {buttonArray.map(button =>
                (
                  <Box>
                    <ButtonBlock
                      parent={button}
                      updateParent={setButtonArray}
                      rerender={() => { rerender(p => !p) }}
                      display={showButtons}
                      editable={true}
                    />
                  </Box>
                )
                )}
              </Box>
              <Box className={'templates-footer flexedGap'}>
                <Box className="character-counter positionUnset" >
                  {`${buttonArray.length} / ${buttonLimit} Botões`}
                </Box>
              </Box>
            </>
          }

        </Grid>
      )
      }
    </Grid >
  );
}

export default MediaInteractiveBody;
