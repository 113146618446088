import React, { useEffect, useReducer, useState } from 'react'
import { Box, TextField, Switch, FormControlLabel, Alert, Button } from '@mui/material'
import { lockedReducer } from '../../../utils/defaultReducer'
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import api from '../../../api';
import { getSession } from '../../../auth';
import useNotification from '../../Notification';
import Loading from '../../Loading';

const AdvancedConfigs = () => {

    const enterpriseId = getSession()?.profile?.enterprise?.id

    const [loading, setLoading] = useState(false)
    const [patchLoading, setPatchLoading] = useState(false)

    const [state, setState] = useReducer(lockedReducer, {
        send_scout_email: false,
        scout_email: '',
        scout_export_type: 'pdf' || 'csv'
    })

    const loadData = async () => {
        setLoading(true)
        const res = await api.get(`/api/enterprises/${enterpriseId}/`)
        setLoading(false)
        if (res.status === 200) {
            setState(res.data.settings)
            return
        }

        useNotification(
            'Ops!',
            'Informações não carregadas',
            'danger'
        );
    }

    useEffect(() => {
        loadData()
    }, [])

    const submit = async (data) => {

        setPatchLoading(true)
        const res = await api.patch(`/api/enterprises/${enterpriseId}/`, { settings: data })
        setPatchLoading(false)
        if (res.status === 200) {
            useNotification(
                'Sucesso!',
                'Configurações do scout alteradas com sucesso.',
                'success'
            );
            return
        }
        useNotification(
            'Ops!',
            'Ocorreu um erro no sistema',
            'danger'
        );

    }

    return (
        <>
            <Loading loading={loading} />
            <Box className="flexbox input-settings" sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                <Box >
                    <Box className="category-title push-bottom">
                        Exportação diária
                    </Box>

                    <Box className="flexbox" sx={{ gap: '1rem', display: 'flex', flexDirection: 'column' }}>

                        <FormControlLabel
                            label="Enviar por e-mail todas as conversas do dia anterior"
                            sx={{ margin: 0 }}
                            control={
                                <Switch
                                    checked={state.send_scout_email}
                                    onChange={() => setState({ send_scout_email: !state.send_scout_email })}
                                />
                            }
                        />
                        <Alert severity='info'>
                            Um arquivo .zip será enviado para cada telefone destinatário, contendo textos, imagens, áudios e outras mídias. A exportação ocorrerá diariamente à meia-noite, sempre que houver novas conversas a serem exportadas.
                        </Alert>
                        {state.send_scout_email &&
                            <TextField
                                size="small"
                                label="Email"
                                fullWidth
                                sx={{ maxWidth: '30rem' }}
                                type="text"
                                variant="outlined"
                                defaultValue={state.scout_email}
                                onChange={(event) => setState({
                                    scout_email: event.target.value
                                })}
                            />}
                        <Box className='BuilderBotSelectorBox' sx={{ maxWidth: '10rem' }}>
                            <Button
                                variant={state.scout_export_type === 'pdf' ? 'contained' : ''}
                                onClick={() => setState({ scout_export_type: 'pdf' })}
                            >
                                PDF
                            </Button>
                            <Button
                                variant={state.scout_export_type === 'csv' ? 'contained' : ''}
                                onClick={() => setState({ scout_export_type: 'csv' })}
                            >
                                CSV
                            </Button>
                        </Box>
                    </Box>
                </Box>

                <LoadingButton
                    style={{ width: 'fit-content' }}
                    onClick={() => submit(state)}
                    loading={patchLoading}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                >
                    Salvar
                </LoadingButton>
            </Box >
        </>
    )

}

export default AdvancedConfigs