import React, { useState } from "react";
import ChatImage from '../../../../assets/images/chat.svg'
import Typography from '../../../Typography';
import { Box, Grid } from "@mui/material";
import "./styles.css";

const ChatIntro = () => {
    return (

        <Box id="chatIntro-box-1">
            <img src={ChatImage} id="chatImage" />
            <Box id="chatIntro-box-2">
                <Typography align="center" variant="h5" color="darkGray" text="Selecione uma conversa na lista à esquerda para visualizar aqui"/>
            </Box>
        </Box>


    );
}

export default ChatIntro;

