import React, { useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';

const ToogleJustMe = ({ updatejustme, color }) => {
    
    const [toggled, setToggled] = useState(true);

    const handleClick = () => {
        setToggled(!toggled);
        if (updatejustme) {
            updatejustme(!toggled);
        }
    };

    return (
        <Tooltip title="Minha Pendências" placement="bottom" disableInteractive>
        <IconButton
            sx={{ p: '10px' }}
            aria-label="toggle"
            onClick={handleClick}
        >
            {toggled ? <BookmarkAddedIcon style={{ "color":"#21446C"  }} /> : <BookmarkAddedIcon style={{ color }} />}
        </IconButton>
        </Tooltip>
    );
};

export default ToogleJustMe;
