import React from 'react'

import { Box, FormControl, InputLabel, Select, TextField, MenuItem } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton';
import TableRowsIcon from '@mui/icons-material/TableRows';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ptBR from 'dayjs/locale/pt-br';
import { ptBR as localePTBR } from '@mui/x-date-pickers/locales';
import dayjs from 'dayjs';

import { buildColumns, get_report_values, get_report_data, initialize_date_parameters } from './detailed_register_functions'

export const number2dayweek = {
    0: 'D',
    1: 'S',
    2: 'T',
    3: 'Q',
    4: 'Q',
    5: 'S',
    6: 'S'
}


const DetailedRegisterLeftMenu = ({ parent, setParent, disabled = false }) => {

    const breakDateTime = (e, format) => {
        setParent({
            parameters: {
                ...parent.parameters,
                [`date__${format}`]: e.format('YYYY-MM-DD'),
                [`time__${format}`]: e.format('HH:mm')
            }
        })
    }



    const getDateSelector = () => {
        switch (parent.selected_report_name?.data_type) {
            case "interval":
                return (<>
                    <LocalizationProvider sx={{ justifyContent: 'space-between' }} dateAdapter={AdapterDayjs} adapterLocale={ptBR}
                        localeText={localePTBR.components.MuiLocalizationProvider.defaultProps.localeText}>
                        <Box>
                            <DateTimePicker
                                sx={{ width: '100%', minWidth:'13rem' }}
                                label={'Início'}
                                format={'DD/MM/YYYY HH:mm'}
                                slotProps={{ textField: { size: 'small' } }}
                                value={dayjs(`${parent.parameters.date__gte}T${parent.parameters.time__gte}`)}
                                onChange={e => breakDateTime(e, 'gte') }
                                disabled={disabled}
                                dayOfWeekFormatter={(n,date) => number2dayweek[date.$W]}
                                ampm={false}
                            />
                        </Box>
                        <Box>
                            <DateTimePicker
                                 sx={{ width: '100%', minWidth:'13rem' }}
                                label={'Fim'}
                                format={'DD/MM/YYYY HH:mm'}
                                slotProps={{ textField: { size: 'small' } }}
                                value={dayjs(`${parent.parameters.date__lte}T${parent.parameters.time__lte}`)}
                                onChange={e => breakDateTime(e, 'lte') }
                                disabled={disabled}
                                dayOfWeekFormatter={(n,date) => number2dayweek[date.$W]}
                                ampm={false}
                            />
                        </Box>
                    </LocalizationProvider>
                </>)
            case "month-year":
                return (<Box>
                    <LocalizationProvider sx={{ justifyContent: 'space-between' }} dateAdapter={AdapterDayjs} adapterLocale={ptBR}
                        localeText={localePTBR.components.MuiLocalizationProvider.defaultProps.localeText}
                    >
                        <DatePicker
                            sx={{ width: '100%' }}
                            label={'Mês e ano'}
                            views={['month', 'year']}
                            format={"MMMM, YYYY"}
                            slotProps={{ textField: { size: 'small' } }}
                            value={dayjs(`${parent.parameters.year}-${parent.parameters.month}`)}
                            onChange={e => setParent({ parameters: { ...parent.paremeters, month: e.$M + 1, year: e.$y } })}
                            disabled={disabled}
                        />
                    </LocalizationProvider>
                </Box>)
            default:
                return (<></>)
        }
    }

    return (
        <Box className={"report-header-left"}>
            <Box>
                <FormControl size="small" sx={{ maxWidth: '13rem' }}>
                    <InputLabel id="report-type">
                        Relatório
                    </InputLabel>
                    <Select
                        labelId="report-type"
                        id="report-type"
                        value={parent.selected_report_name}
                        label="Relatório"
                        onChange={async (event) => {
                            setParent({ loading: true })
                            const report_name = event.target.value
                            const temp_report_values = await get_report_values(report_name)
                            parent.parameters = initialize_date_parameters(temp_report_values?.data_type)
                            parent.offset = 0
                            parent.page = 0
                            const temp_report_data = await get_report_data({ ...parent, selected_report_name: report_name })
                            setParent({
                                ...parent,
                                report_values: temp_report_values,
                                report_data: temp_report_data,
                                loading: false,
                                selected_report_name: report_name,
                                ...buildColumns(temp_report_values, setParent),
                            })
                        }}
                        disabled={disabled}
                    >
                        {parent.report_names.map(report => <MenuItem value={report}>{report.name}</MenuItem>)}
                    </Select>
                </FormControl>
            </Box>
            {getDateSelector()}
            <Box>
                <LoadingButton
                    onClick={async () => {
                        setParent({ loading: true })
                        const data = await get_report_data(parent)
                        setParent({ loading: false, report_data: data, selectedData: [] })
                    }}
                    loadingPosition="start"
                    loading={parent.loading}
                    variant="contained"
                    startIcon={<TableRowsIcon />}
                    disabled={disabled}
                >
                    Pesquisar
                </LoadingButton>
            </Box>
        </Box>
    )
}

export default DetailedRegisterLeftMenu