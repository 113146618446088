import React, { useReducer, useEffect, useState } from 'react'

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Box,
    Tooltip
} from '@mui/material'

import {
    DataGridPro,
    GridToolbarContainer,
    ptBR,
} from '@mui/x-data-grid-pro';
import LoadingButton from '@mui/lab/LoadingButton';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Cancel from '@mui/icons-material/Cancel';
import { ptBR as corePtBR } from '@mui/material/locale';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import CloseIcon from '@mui/icons-material/Close'

import './styles.css'
import defaultReducer from '../../../../utils/defaultReducer';
import { iconDictionary } from '../../../Chat/MessagesArea/ChatWindow/MessageItem/attachment';
import { mimeTypes } from './mimetypes';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import EditAttachments from './editAttachments';

const default_attachments = {
    'application/pdf': { 'active': true, 'max_size': 64000, 'icon': 'pdf', 'direction': 'both' },
    'video/mp4': { 'active': true, 'max_size': 64000, 'icon': 'video', 'direction': 'both' },
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': { 'active': true, 'max_size': 64000, 'icon': 'xlsx', 'direction': 'both' },
    'application/x-zip-compressed': { 'active': true, 'max_size': 64000, 'icon': 'zip', 'direction': 'both' },
    'text/csv': { 'active': true, 'max_size': 64000, 'icon': 'xlsx', 'direction': 'both' },
    'text/plain': { 'active': true, 'max_size': 64000, 'icon': 'text', 'direction': 'both' },
    'image/jpeg': { 'active': true, 'max_size': 64000, 'icon': 'image', 'direction': 'both' },
    'image/png': { 'active': true, 'max_size': 64000, 'icon': 'image', 'direction': 'both' }
}

const theme = createTheme({
    palette: {
        primary: { main: '#21446C' },
    },
}, ptBR, corePtBR);

const CustomToolbar = (setShowEdit) => {
    return (
        <GridToolbarContainer >
            <LoadingButton
                size="small"
                component="label"
                startIcon={<AddCircleIcon />}
                onClick={() => { setShowEdit({editable: true}) }}
            >
                Adicionar
            </LoadingButton>
        </GridToolbarContainer>
    )
}

const columns = (setShowEdit, removeRow) => {
    return [
        { field: 'key', headerName: 'Mime', flex: 1 },
        { field: 'ext', headerName: 'Extensão', flex: 1, valueGetter: ({ row }) => mimeTypes[row.key] },
        { field: 'max_size', headerName: 'Tamanho máximo (kB)', flex: 1 },
        { field: 'icon', headerName: 'Ícone', flex: 1, renderCell: ({ value }) => (iconDictionary[value]) },
        {
            field: 'active', headerName: 'Ativo', flex: 1,
            renderCell: ({ value }) => (
                <Box>
                    {value ? <CheckCircleIcon className="active-user" /> : <Cancel className="inactive-user" />}
                </Box>
            ),
        },
        {
            field: 'inbound', headerName: 'Receber', flex: 1, valueGetter: ({ row }) => { return (['both', 'inbound'].includes(row.direction)) },
            renderCell: ({ value }) => (
                <Box>
                    {value ? <CheckCircleIcon className="active-user" /> : <Cancel className="inactive-user" />}
                </Box>
            ),
        },
        {
            field: 'outbound', headerName: 'Enviar', flex: 1, valueGetter: ({ row }) => { return (['both', 'outbound'].includes(row.direction)) },
            renderCell: ({ value }) => (
                <Box>
                    {value ? <CheckCircleIcon className="active-user" /> : <Cancel className="inactive-user" />}
                </Box>
            ),
        },
        {
            field: 'action', headerName: 'Ações', filterable: false, sortable: false,
            renderCell: ({ row }) => (
                <>
                    <Tooltip title="Editar" >
                        <Button
                            className="action-button"
                            onClick={() => { setShowEdit(row) }}
                        >
                            <EditIcon />
                        </Button>
                    </Tooltip>
                    {row.editable &&
                        <Tooltip title="Excluir Horário de atendimento">
                            <Button className="action-button delete"
                                onClick={() => { removeRow(row) }}>
                                <DeleteIcon />
                            </Button>
                        </Tooltip>}
                </>
            ),
        },
    ]

}

export default function AttachmentsDialog({ data, handleClose, submit }) {

    const [state, setState] = useReducer(defaultReducer, default_attachments)
    const [showEdit, setShowEdit] = useState()


    useEffect(() => {
        setState(data.configuration)
    }, [])

    const attachment_list = Object.entries(state).map(([key, value], index) => ({ id: index, key: key, ...value }))

    const removeRow = (row) => {
        const newState = state
        delete newState[row.key]
        setState({...newState})
    }

    return (
        <div>
            
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={true}
                disablePortal
                disableEnforceFocus
                sx={{ position: 'absolute' }}
                fullWidth
                maxWidth={'lg'}
            >
                <DialogTitle className="group-flexbox">
                    Envio e recebimento de anexos
                    <Button sx={{ 'minWidth': '2rem', 'padding': '0' }} onClick={handleClose}>
                        <CloseIcon />
                    </Button>
                </DialogTitle>
                <Divider />
                <DialogContent className="add-template-dialog-content" sx={{ minHeight: '35rem' }}>
                    <Box sx={{ width: '100%' }}>
                        <ThemeProvider theme={theme}>
                            <DataGridPro
                                columns={columns(setShowEdit, removeRow)}
                                components={{ Toolbar: () => CustomToolbar(setShowEdit) }}
                                rows={attachment_list}
                                density={'compact'}
                            />
                        </ThemeProvider>
                    </Box>

                </DialogContent>
                <DialogActions >
                    <Box className="user-footer">
                        <LoadingButton className="button-created"
                            onClick={() => {
                                submit({...data, configuration: state})
                                handleClose()
                            }}
                        >
                            Salvar
                        </LoadingButton>
                    </Box>
                </DialogActions>
            </Dialog>
            {showEdit && <EditAttachments data={showEdit} parent={state} setParent={setState} handleClose={()=>{setShowEdit(null)}}/>}
        </div>
    );
}
