import React, { useEffect, useState, useMemo, useReducer } from 'react'

import { Box, Alert, AlertTitle, IconButton, Button } from '@mui/material'

import LoadingButton from '@mui/lab/LoadingButton';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import AddBotDialog from "./AddBotDialog/index.js"
import ConfirmationDialogCaptcha from '../../Dialogs/ConfimationDialogCaptcha/index.js';
import useNotification from "../../Notification/index.js";
import Loading from '../../Loading/index.js';
import api from '../../../api.js';
import defaultReducer from '../../../utils/defaultReducer.js'
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { getSession } from '../../../auth.js';

import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';

export const FacebookPlugin = ({reloadState, reload, setConnectedPage, english, app}) => {

	const SCOPE_PERMISSIONS = {
		"DEFAULT"   : "pages_manage_metadata, pages_show_list, business_management, instagram_basic, instagram_manage_messages, pages_messaging",
		"INSTAGRAM" : "pages_manage_metadata, pages_show_list, business_management, instagram_basic, instagram_manage_messages, pages_messaging",
		"WHATSAPP"  : "whatsapp_business_messaging, whatsapp_business_management"
	}
	
	const [state, setState] = useReducer(defaultReducer, {
		loggedin : false,
		loading  : true
	})

	useMemo(async ()=>{
		(function (d, s, id) {
			var js, fjs = d.getElementsByTagName(s)[0];
			if (d.getElementById(id)) { return; }
			js = d.createElement(s); js.id = id;
			js.src = "https://connect.facebook.net/en_US/sdk.js";
			fjs.parentNode.insertBefore(js, fjs);
		}(document, 'script', 'facebook-jssdk'));
	}, [])

	const getAppData = async (key) => {
		return (await api.get(`api/getEnv/${key}/`)).data
	}

	useEffect(()=> {
		try{
			window.fbAsyncInit()
		} catch(e){
			console.error(e);
		}
	}, [reload])

	window.reloadWindowOnKwik = () => {
		reloadState()
	} 

	window.fbAsyncInit = async function () {

		const checkForFB = async () => {
	        return new Promise((resolve) => {
	            const check = () => {
	                if (typeof FB !== 'undefined') {
	                    resolve()
	                } else {
	                    setTimeout(check, 1000)
	                }
	            }
	            check()
	        });
	    };

	    await checkForFB()

		const data = await getAppData(app ?? 'INSTAGRAM')
		FB.init({
			appId   : data['app_id'],
			cookie  : true,
			xfbml   : true,
			version : data['app_version']
		});

		FB.AppEvents.logPageView();

		FB.getLoginStatus(function (response) {
			setConnectedPage(response)
			statusChangeCallback(response);
		});

	};

	const statusChangeCallback = (data) => {
		const response = {'loggedin': false}
		if (data.status === 'connected') {
			response['loggedin'] = true
		}
		response['loading'] = false
		setState(response)
	}
	return (
		(
			<Box sx={{padding:'1rem'}}>
			 	{state.loading && <Box><AutorenewIcon className={'fileIcon spinner'} /></Box>}
				<Box key={state.loading} sx={{display:state.loading || state.loggedin ? 'none': '' }}>
					<div id="fb-root"></div>
					<div class="fb-login-button"
						data-width=""
						data-size=""
						data-button-type=""
						data-layout=""
						data-onlogin="reloadWindowOnKwik()"
						data-auto-logout-link="false"
						data-use-continue-as="true"
						scope={SCOPE_PERMISSIONS[app] ?? SCOPE_PERMISSIONS['DEFAULT']}  
					></div>
				</Box>
				{!state.loading && state.loggedin && !english &&<Box>Você já está logado com o facebook <Button variant='text' onClick={()=> {FB.logout(); reloadState();}}>Deslogar</Button></Box>}
				{!state.loading && state.loggedin && english &&<Box>You already logged in facebook <Button variant='text' onClick={()=> {FB.logout(); reloadState();}}>Logout</Button></Box>}
			</Box>
		)
	)
}


const Instagram = ({ enterprise }) => {

	const [loading, setLoading] = useState(false);
	const [bots, setBots] = useState([]);
	const [showAddBot, setShowAddBot] = useState(false)
	const [deleteModal, setDeleteModal] = useState(null)
	const [connectedPage, setConnectedPage]= useState(null)
	const [reload, setReload] = useState(false)

	const reloadState = () => {setTimeout(()=>setReload(p=>!p), 500)}

	const deleteBot = async (id) => {
		let resInstagram = null;
		let resMessenger = null;
		const enterpriseId = getSession().profile.enterprise.id;
		setLoading(true);
		
		const deleteRequests = Array.isArray(id) ? 
			id.map(item => {
				if ('id_instagram' in item) {
					return api.delete(`/api/enterprises/${enterpriseId}/instagram/${item.id}/`).then(res => ({ type: 'instagram', res }));
				} else {
					return api.delete(`/api/enterprises/${enterpriseId}/messenger/${item.id}/`).then(res => ({ type: 'messenger', res }));
				}
			}) : 
			['id_instagram' in id ? 
				api.delete(`/api/enterprises/${enterpriseId}/instagram/${id.id}/`).then(res => ({ type: 'instagram', res })) : 
				api.delete(`/api/enterprises/${enterpriseId}/messenger/${id.id}/`).then(res => ({ type: 'messenger', res }))
			];

		try {
			const results = await Promise.all(deleteRequests);

			results.forEach(({ type, res }) => {
				if (type === 'instagram') resInstagram = res;
				if (type === 'messenger') resMessenger = res;
			});

			if ((resInstagram && resInstagram.status !== 204) || (resMessenger && resMessenger.status !== 204)) {
				useNotification(
					'Ops!',
					'Ocorreu um erro na requisição.',
					'danger'
				);
				return;
			}

			// const idsToDelete = Array.isArray(id) ? id.map(item => item.id) : [id.id];
        	// setBots(bots.filter((bot) => !idsToDelete.includes(bot.id)));
        	fetchData()
			setDeleteModal(null);
		} catch (error) {
			console.error(error);
			useNotification(
				'Ops!',
				'Ocorreu um erro na requisição.',
				'danger'
			);
		} finally {
			setLoading(false);
		}
	};

	const addBot = async (page_id) => {
		const enterpriseId = getSession().profile.enterprise.id;

		setLoading(true)
		const resInstagram = await api.post(`/api/enterprises/${enterpriseId}/instagram/`, {
			page_id: page_id,
			token_page: connectedPage?.authResponse?.accessToken,
			id_instagram: '-',
		})
		const resMessenger = await api.post(`/api/enterprises/${enterpriseId}/messenger/`, {
			page_id    : page_id,
			token_page : connectedPage?.authResponse?.accessToken
		})
		setLoading(false)

		if (resInstagram.status !== 201 && resMessenger.status !== 201) {
			if (resInstagram.status === 404 && resMessenger.status !== 201) {
				useNotification(
					'Ops!',
					'Não foi possível vincular a página, verifique como ceder acesso moderado à Instant Solutions.',
					'danger'
				)
			}
			else if (resInstagram.status === 405 && resMessenger.status === 405) {
				useNotification(
					'Ops!',
					'Não foi possível vincular a página, verifique como vincular sua página do facebook ao instagram.',
					'danger'
				)
			}
			else if (resInstagram.status === 406 && resMessenger.status === 406) {
				useNotification(
					'Ops!',
					'Webhook não pode ser criado, verifique com um administrador do sistema.',
					'danger'
				)
			}
			else if (resInstagram.status === 409 && resMessenger.status === 409) {
				useNotification(
					'Ops!',
					'Página não pode ser vinculada pois já está vinculada a outra conta.',
					'danger'
				)
			}
			else {
				useNotification(
					'Ops!',
					'Ocorreu um erro na requisição.',
					'danger'
				)
			}
			return
		}

		fetchData()
		setShowAddBot(false)
	}

	const managePageData = (resInstagram, resMessenger) => {
		let res = []
		let controlResInsta = []
		let controlResMesse = []
		if ((resInstagram.data.length > 0 && resMessenger.data.length > 0)) {
			resInstagram.data.forEach((item_insta) => {
				let resIn = []
				resMessenger.data.forEach((item_messe) => {
					if (item_insta.page_id == item_messe.page_id) {
						resIn.push(item_insta)
						resIn.push(item_messe)
						res.push(resIn)
						controlResInsta.push(item_insta.id)
						controlResMesse.push(item_messe.id)
					} else {
						if (!controlResMesse.includes(item_messe.id)) {
							res.push(item_messe)
							controlResMesse.push(item_messe.id)
						}
					}
				})
				if (!controlResInsta.includes(item_insta.id)) {
					res.push(item_insta)
					controlResInsta.push(item_insta.id)
				}
			})
		}

		else if (resMessenger.data.length > 0) {
			res.push(resMessenger.data)
		}

		else if (resInstagram.data.length > 0) {
			res.push(resInstagram.data)
		}

		return res
	}

	const fetchData = async () => {
		const enterpriseId = getSession().profile.enterprise.id;
		setLoading(true);
		const resInstagram = await api.get(`/api/enterprises/${enterpriseId}/instagram/`);
		const resMessenger = await api.get(`/api/enterprises/${enterpriseId}/messenger/`);
		setLoading(false);

		if (resInstagram.status !== 200 && resMessenger.status !== 200) {
			useNotification(
				'Ops!',
				'Não foi possível encontrar as páginas.',
				'danger'
			);
			return
		}
		
		const res = managePageData(resInstagram, resMessenger)

		setBots(res)
	};

	useEffect(() => {
		fetchData()
	}, [])

	const facebookPlugin = useMemo(() => {
		return (<FacebookPlugin reloadState={reloadState} reload={reload} setConnectedPage={setConnectedPage}/>)
	}, [reload])

	return (
		<Box className="input-settings settings-body">
			<Loading loading={loading} />
			{showAddBot &&
				<AddBotDialog
					open={showAddBot}
					handleClose={() => setShowAddBot(false)}
					submit={addBot}
				/>
			}
			{deleteModal ?
				<ConfirmationDialogCaptcha open={true}
					title="Excluir página."
					description={
						<span>
							Para confirmar a ação, digite: <span style={{ 'fontWeight': 'bold' }}>remover</span> no campo abaixo.</span>}
					handleClose={() => { setDeleteModal(null) }}
					handleSubmit={() => { deleteBot(deleteModal) }}
					captcha={'remover'}
				/> : null
			}
			<Alert className="telegram-info" severity="info">
				<AlertTitle>
					Para saber como integrar o kwik com o Instagram <a href="https://instant.getoutline.com/s/80b32544-e1bc-4f61-a9a0-96388c8d42b1" target="_blank">clique aqui.</a>
				</AlertTitle>
				<AlertTitle>
					Para saber como integrar o kwik com o Messenger <a href="https://instant.getoutline.com/s/77871958-d9cc-4c68-a48a-0d55ab71e3dd" target="_blank">clique aqui.</a>
				</AlertTitle>
				<Box className="push-s-bottom">
					É necessário:
				</Box>
				<Box>
					<p>Uma conta empresarial no facebook.</p>
					<p>Uma página no facebook.</p>
					<p>Uma conta empresarial no Instagram.</p>
					<p>Vincular sua página do facebook ao Instagram.</p>
					<p>Ceder permissões ao Kwik, clicando no botão abaixo: {facebookPlugin}</p>
				</Box>
				<Box className="push-s-top">
					Você pode adicionar sua página clicando no botão abaixo.
				</Box>
			</Alert>
			{bots.length !== 0 &&
				<>
					<Box className="category-title push-bottom">
						Páginas vinculadas
					</Box>
					<Box className="telegram-bots">
						<table>
							{bots.map((item, index) => (
								<tr>
									{Array.isArray(item) &&
										<>
										<td>{item[0].page_name} - {item[0].page_id}</td>
										{item.map((page, page_index) => (
											<td>{'id_instagram' in page ? 
											<InstagramIcon className="instagram" sx={{ marginBottom: '-5px!important' }}/>
											:
											<FacebookIcon className="messenger"  sx={{ marginBottom: '-5px!important' }}/>}</td>
										))}
										</>
									}
									{!Array.isArray(item) &&
										<>
										<td>{item.page_name} - {item.page_id}</td>
										<td>{'id_instagram' in item ? 
											<InstagramIcon className="instagram"  sx={{ marginBottom: '-5px!important' }}/>
											:
											<FacebookIcon className="messenger"  sx={{ marginBottom: '-5px!important' }}/>}</td>
										<td></td>
										</>
									}
									<td>
										<IconButton>
											<DeleteIcon onClick={() => {
												setDeleteModal(item)
											}} />
										</IconButton>
									</td>
								</tr>
							))}
						</table>
					</Box>
				</>
			}
			<Box className="telegram-mumbers-info">
				<LoadingButton startIcon={<AddIcon />} variant="contained"
					onClick={() => setShowAddBot(true)}
				>
					Adicionar
				</LoadingButton>
			</Box>
			
		</Box>
	)
}

export default Instagram
