import React, { useEffect, useState, useCallback , useMemo} from 'react'

import { Box, Alert, AlertTitle, IconButton } from '@mui/material'

import LoadingButton from '@mui/lab/LoadingButton';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import AddBotDialog from "./AddBotDialog/index.js"
import ConfirmationDialogCaptcha from '../../Dialogs/ConfimationDialogCaptcha/index.js';
import useNotification from "../../Notification/index.js";
import Loading from '../../Loading/index.js';
import api from '../../../api.js';

import { getSession } from '../../../auth.js';
import { FacebookPlugin } from '../Instagram/index.js';

const Messenger = ({ enterprise }) => {

	const [loading, setLoading] = useState(false);
	const [bots, setBots] = useState([]);
	const [showAddBot, setShowAddBot] = useState(false)
	const [connectedPage, setConnectedPage] = useState(null)
	const [deleteModal, setDeleteModal] = useState(null)
	const [reload, setReload] = useState(false)

	const reloadState = () => {setTimeout(()=>setReload(p=>!p), 500)}

	const deleteBot = async (id) => {
		const enterpriseId = getSession().profile.enterprise.id;
		setLoading(true)
		const res = await api.delete(`/api/enterprises/${enterpriseId}/messenger/${id}/`)
		setLoading(false)
		if (res.status !== 204) {
			useNotification(
	            'Ops!',
	            'Ocorreu um erro na requisição.',
	            'danger'
	        );
			return
		}
		setBots(bots.filter((bot) => bot.id != id))
		setDeleteModal(null) 
	}

	const addBot = async (page_id) => {
		const enterpriseId = getSession().profile.enterprise.id;

		setLoading(true)
		const res = await api.post(`/api/enterprises/${enterpriseId}/messenger/`, {
			page_id    : page_id,
			token_page : connectedPage?.authResponse?.accessToken
		})
		setLoading(false)

		if (res.status !== 201) {
			if (res.status === 404) {
				useNotification(
		            'Ops!',
		            'Não foi possível vincular a página, verifique como ceder acesso moderado à Instant Solutions.',
		            'danger'
		        )
			}
			else if (res.status === 405) {
					useNotification(
						'Ops!',
						'Não foi possível vincular a página, verifique como vincular sua página do facebook ao messenger.',
						'danger'
					)
			} 
			else if (res.status === 406) {
				useNotification(
					'Ops!',
					'Webhook não pode ser criado, verifique com um administrador do sistema.',
					'danger'
				)
		}
		else if (res.status === 409) {
			useNotification(
				'Ops!',
				'Página não pode ser vinculada pois já está vinculada a outra conta.',
				'danger'
			)
	}
			else {
				useNotification(
		            'Ops!',
		            'Ocorreu um erro na requisição.',
		            'danger'
		        )	
			}
			return
		}
		setBots([...bots, res.data])
		setShowAddBot(false)
	}

	useEffect(() => {
		const fetchData = async () => {
			const enterpriseId = getSession().profile.enterprise.id;
			setLoading(true);
			const res = await api.get(`/api/enterprises/${enterpriseId}/messenger/`);
			setLoading(false);
			
			if (res.status !== 200) {
				useNotification(
		            'Ops!',
		            'Não foi possível encontrar as páginas Messenger.',
		            'danger'
		        );
				return
			}

			setBots(res.data)
		};
		fetchData()
	}, [])

	const facebookPlugin = useMemo(() => {
		return (<FacebookPlugin reloadState={reloadState} reload={reload} setConnectedPage={setConnectedPage}/>)
	}, [reload])

	return (
		<Box className="input-settings settings-body">
        	<Loading loading={loading}/>
        	{showAddBot &&
        		<AddBotDialog
        			open={showAddBot}
        			handleClose={() => setShowAddBot(false)}
        			submit={addBot}
        		/>
        	}
            {deleteModal ?
                <ConfirmationDialogCaptcha open={true}
                    title="Excluir página."
                    description={
                        <span>
                            Para confirmar a ação, digite: <span style={{ 'fontWeight': 'bold' }}>remover</span> no campo abaixo.</span>}
                    handleClose={() => { setDeleteModal(null) }}
                    handleSubmit={() => { deleteBot(deleteModal) }}
                    captcha={'remover'}
                /> : null
			}
        	<Alert className="telegram-info" severity="info">
        		<AlertTitle>
        			Para saber como integrar o kwik com o Messenger <a href="https://instant.getoutline.com/s/77871958-d9cc-4c68-a48a-0d55ab71e3dd" target="_blank">clique aqui.</a>
    			</AlertTitle>
        		<Box className="push-s-bottom">
        			É necessário:
        		</Box>
        		<Box>
					<p>Uma conta empresarial no facebook.</p>
	    			<p>Uma página no facebook.</p> 
					<p>Ceder permissões ao Kwik, clicando no botão abaixo: {facebookPlugin}</p>
	    		</Box>
        		<Box className="push-s-top">
        			Você pode adicionar sua página clicando no botão abaixo.
        		</Box>
        	</Alert>
        	{bots.length !== 0 &&
        		<>
		        	<Box className="category-title push-bottom">
		                Páginas vinculadas
		            </Box>
		            <Box className="telegram-bots">
	            		<table>
			            {bots.map((item, index) => (
			            	<tr>
			            		<td>{item.page_name} - {item.page_id}</td>
                                <td>
	                                <IconButton>
	  									<DeleteIcon onClick={() => setDeleteModal(item.id)}/>
	  								</IconButton>
  								</td>
			            	</tr>
			            ))}
			            </table>
		            </Box>
		        </>
        	}
        	<Box className="telegram-mumbers-info">
	        	<LoadingButton startIcon={<AddIcon />} variant="contained"
	        		onClick={() => setShowAddBot(true)}
	        	>
	        		Adicionar
	        	</LoadingButton>
	        </Box>
        </Box>
	)
}

export default Messenger
