import React from "react";
import MiniDrawer from "../../components/Drawer"
import SettingsGrid from "../../components/Settings"
import SettingsV2 from "../../components/SettingsV2";

const Settings = (socket) => {
    return (
        <MiniDrawer child={<SettingsV2 />} socket={socket.socket}/>
    );
};

export default Settings;
