import React from "react";

import { Box, Grid, Divider, IconButton, Tooltip } from "@mui/material";
import { ListItem, List, ListItemText } from "@mui/material";
import FileIcon from '@mui/icons-material/DescriptionOutlined';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import PropTypes from "prop-types";

import Typography from "../../Typography";
import AvatarName from "../../AvatarName";
import ChangeField from "../../ChangeField";

import api from '../../../api';

import userPermissions from '../../User/Permissions';
import { getSession } from '../../../auth';

import "./styles.css";

class Profile extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isIntegration: false,
            renderKey    : 0
        }

        this.groups = {};
    }

    isNotOperator = () => {
        return this.groups.isNotOnlyOperator;
    }

    disableEditInput = (field) => {
        const agentCanUpdateContact = this.props.agentCanUpdateContact;
        const dataField = this.props.contactData ? this.props.contactData[field] : null;
        const isNotOperator = this.isNotOperator();

        // If the field is empty or
        // the field is 'Usuário Anônimo' or
        // the settings allow the agent to edit or
        // is an operator
        if (!dataField || 
            dataField === 'Usuário Anônimo' || 
            agentCanUpdateContact || 
            isNotOperator
        )
            return false;

        return true;
    }

    checkIntegration = (enterprises) => {
        const source       = enterprises.settings.integration_source;
        const access_token = enterprises.settings.integration_access_token;
        const base_url     = enterprises.settings.integration_base_url;

        if (source && access_token && base_url) {
            this.setState({
                isIntegration : true,
                render        : Math.random()
            });
        }
    }

    getSettings = async (enterpriseId) => {
        await api.get(`/api/enterprises/${enterpriseId}/`)
        .then((res) => {
            if (res.status == 200) {
                this.checkIntegration(res.data);
                return;
            }
            throw res;
        }).catch((err) => {
            console.error(err);
        });
    }

    componentWillMount() {
        const user = getSession();

        if (user) {
            this.getSettings(user.profile.enterprise.id);
        }

        this.groups = userPermissions();
    }

    render() {
        return (
            <Box
                className="contactHistory-box-1"
                style={{ height: this.props.isManageCalls ? '110%' : '100%' }}
            >
                <Box className="header-profile">
                    <Tooltip title="Fechar informações do contato" placement="bottom" disableInteractive>
                        <IconButton
                            onClick={() => this.props.closeProfile()}
                            className="contactHistory-iconButton"
                            size="large">
                            <ChevronRightIcon/>
                        </IconButton>
                    </Tooltip>
                    <Box className="title-profile">Dados do contato</Box>
                </Box>
                {this.props.contactData &&
                    <Box className="contactHistory-box-2">
                        <Box className="avatar-profile">
                            {this.props.contactData.name ?
                                <AvatarName 
                                    name={this.props.contactData.name}
                                    size="big" 
                                />
                                :
                                <AvatarName 
                                    name="N"
                                    size="big" 
                                />
                            }
                        </Box>
                        <Box className="contactHistory-box-3">
                            <ChangeField value={this.props.contactData.name}
                                router={`api/enterprises/${this.props.contactData.enterprise}/contacts/${this.props.contactData.id}/`}
                                field="name"
                                label="nome"
                                updateField={(data) => {
                                    this.props.contactData.name = data;
                                    this.props.updateField();
                                }}
                                disabledChange={this.disableEditInput('name')}
                            />
                        </Box>
                        <Box className="contactHistory-box-4">
                            <ChangeField value={this.props.contactData.email}
                                router={`api/enterprises/${this.props.contactData.enterprise}/contacts/${this.props.contactData.id}/`}
                                field="email"
                                label="email"
                                disabledChange={this.state.isIntegration || this.disableEditInput('email')}
                                key={this.state.renderKey}
                            />
                        </Box>
                        <Box className="contactHistory-box-4">
                            <ChangeField value={this.props.contactData.enterprise_name}
                                router={`api/enterprises/${this.props.contactData.enterprise}/contacts/${this.props.contactData.id}/`}
                                field="enterprise_name"
                                label="empresa"
                                disabledChange={this.state.isIntegration || this.disableEditInput('enterprise_name')}
                                key={this.state.renderKey}
                            />
                        </Box>
                        <Box className="contactHistory-box-4">
                            <ChangeField value={this.props.contactData.enterprise_cnpj}
                                router={`api/enterprises/${this.props.contactData.enterprise}/contacts/${this.props.contactData.id}/`}
                                field="enterprise_cnpj"
                                label="CNPJ"
                                disabledChange={this.state.isIntegration || this.disableEditInput('enterprise_cnpj')}
                                key={this.state.renderKey}
                            />
                        </Box>
                        <Box className="contactHistory-box-8">
                            {this.props.contactData.telephone}
                        </Box>
                        <Box
                            className="contactHistory-box-5"
                            style={{ height: this.props.isManageCalls ? 0 : null }}
                        >
                            Histórico de chamadas
                            <br /><Box className="contactHistory-box-6">Últimas 10 chamadas</Box>
                            <Divider className="contactHistory-divider" />
                            <Box 
                                className="contactHistory-box-7"
                                style={{ paddingBottom: this.props.isManageCalls ? '65px' : '25px' }}
                            >
                                {(this.props.contactData.chats && this.props.contactData.chats.length) && this.props.contactData.chats.map((row, i) =>
                                    <div>
                                        <Box
                                            key={i}
                                            className="itemHistory"
                                            onClick={() => this.props.fetchHistoryMessages(row)}
                                            style={{ display: "inline-flex", alignItems: "center" }}
                                        >
                                            <FileIcon className="fileIcon" />
                                            {(new Date(row.time*1000)).toLocaleString('pt-BR').slice(0,-3)}
                                        </Box>
                                    </div>
                                )}
                                {(!this.props.contactData.chats || !this.props.contactData.chats.length) &&
                                    <Box className="noHistory">Não possui histórico de chamadas</Box>
                                }
                            </Box>
                        </Box>
                    </Box>
                }
            </Box>
        );
    }
    
}


export default Profile;