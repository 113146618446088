import React, { useReducer, useEffect, useCallback } from "react";
import { lockedReducer } from '../../../../utils/defaultReducer.js'
import api from "../../../../api";

import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Divider,
  Box,
} from "@mui/material";
import QRCode from "react-qr-code";

import LoadingButton from "@mui/lab/LoadingButton";
import Loading from "../../../Loading/index.js";
import useNotification from "../../../Notification";
import ScoutSocketQRComponent from "../SocketQRComponent/index.js";

import CloseIcon from "@mui/icons-material/Close";

let pollingId = 0

export default function ConnectModal({ account, enterpriseId, handleClose }) {

  const [state, setState] = useReducer(lockedReducer, {
    qrCode: null,
    loading: true,
    socket: null,
  });

  const getQR = useCallback(async () => {
    setState({loading: true})
    const res = await api.get(
      `api/enterprises/${enterpriseId}/scout_accounts/${account.id}/getqr/`,
      { timeout: 30000 }
    );
    if (res.status === 200) {
      setState({qrCode: res.data.code});
    } else {
      console.error("Error generating qr", res);
      useNotification(
        "Erro!",
        `Erro ao gerar código QR.`,
        "danger"
      );
    }
    setState({loading: false})
  });

  const checkConnection = useCallback(async () => {
    const res = await api.get(
      `api/enterprises/${enterpriseId}/scout_accounts/${account.id}/checkconnection/`
    );
    if (res.status === 200) {
      if (res.data.state == "open") {
        handleClose();
      } else if (res.data.state == "wrongNumber") {
        useNotification(
          "Erro!",
          "Número incorreto",
          "danger");
        handleClose();
      }
    } else {
      console.error("Error checking connection", res);
      handleClose();
    }
  });

  useEffect(() => {
    getQR();
    clearInterval(pollingId);
    pollingId = setInterval(async () => {
      await checkConnection();
    }, 5 * 1000);

    return () => {
      // Cleanup on component unmount
      clearInterval(pollingId);
    };
  }, [account]);

  return (
    <div>
      <ScoutSocketQRComponent account={account} parent={state} setParent={setState}/>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={true}
        disablePortal
        disableEnforceFocus
        maxWidth='xs'
        sx={{ position: "absolute" }}
      >
        <Loading loading={state.loading} />
        <DialogTitle className="group-flexbox">
          Conectar com o Scout
          <Button sx={{ minWidth: "2rem", padding: "0" }} onClick={handleClose}>
            <CloseIcon />
          </Button>
        </DialogTitle>
        <Divider />
        <DialogContent>
          {state.qrCode && <QRCode value={state.qrCode} />}

        </DialogContent>
        <DialogActions>
          <Box className="user-footer">
            <LoadingButton className="button-created" onClick={handleClose}>
              Cancelar
            </LoadingButton>
            <LoadingButton
              className="button-created"
              onClick={() => submit(name, number)}
              disabled={!name || !number}
            >
              Salvar
            </LoadingButton>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
}
