import React, { useEffect, useState, useCallback } from 'react'

import { Box, Alert, AlertTitle, IconButton } from '@mui/material'

import LoadingButton from '@mui/lab/LoadingButton';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import AddBotDialog from "./AddBotDialog"

import useNotification from "../../Notification";
import Loading from '../../Loading';
import api from '../../../api';
import ConfirmationDialogCaptcha from '../../Dialogs/ConfimationDialogCaptcha/index.js';

import { getSession } from '../../../auth.js';

import './styles.css'

const Telegram = ({ enterprise }) => {

	const [loading, setLoading] = useState(false);
	const [bots, setBots] = useState([]);
	const [showAddBot, setShowAddBot] = useState(false)
	const [deleteModal, setDeleteModal] = useState(null)

	const deleteBot = async (id) => {
		const enterpriseId = getSession().profile.enterprise.id;
		setLoading(true)
		const res = await api.delete(`/api/enterprises/${enterpriseId}/telegram_bots/${id}/`)
		setLoading(false)
		if (res.status !== 204) {
			useNotification(
	            'Ops!',
	            'Ocorreu um erro na requisição.',
	            'danger'
	        );
			return
		}
		setBots(bots.filter((bot) => bot.id != id))
		setDeleteModal(null) 
	}

	const addBot = async (name, username, token) => {
		const enterpriseId = getSession().profile.enterprise.id;

		const tokenParts = token.split(':')
		
		const apiKey = tokenParts.pop()
		const botId  = tokenParts.pop()

		if (! apiKey || ! botId) {
			useNotification(
	            'Token Mal Formatado!',
	            'O token deve ser conter "<id>:<chave>".',
	            'danger'
	        );
			return	
		}

		setLoading(true)
		const res = await api.post(`/api/enterprises/${enterpriseId}/telegram_bots/`, {
			name     : name,
			username : username,
			bot_id   : botId,
			api_key  : apiKey
		})
		setLoading(false)

		if (res.status !== 201) {
			if (res.status === 400 && 'token' in res.data && res.data['token'][0] === 'Token inválido.') {
				useNotification(
		            'Ops!',
		            'Não foi possível criar o bot, o token não é válido.',
		            'danger'
		        )
			} else {
				useNotification(
		            'Ops!',
		            'Ocorreu um erro na requisição.',
		            'danger'
		        )	
			}
			return
		}
		setBots([...bots, res.data])
		setShowAddBot(false)
	}

	useEffect(() => {
		const fetchData = async () => {
			const enterpriseId = getSession().profile.enterprise.id;
			setLoading(true);
			const res = await api.get(`/api/enterprises/${enterpriseId}/telegram_bots/`);
			setLoading(false);
			
			if (res.status !== 200) {
				useNotification(
		            'Ops!',
		            'Não foi possível encontrar os Bots Telegram.',
		            'danger'
		        );
				return
			}

			setBots(res.data)
		};
		fetchData()
	}, [])

	return (
		<Box className="input-settings settings-body">
        	<Loading loading={loading}/>
        	{showAddBot &&
        		<AddBotDialog
        			open={showAddBot}
        			handleClose={() => setShowAddBot(false)}
        			submit={addBot}
        		/>
        	}
			{deleteModal ?
                <ConfirmationDialogCaptcha open={true}
                    title="Excluir Bot."
                    description={
                        <span>
                            Para confirmar a ação, digite: <span style={{ 'fontWeight': 'bold' }}>remover</span> no campo abaixo.</span>}
                    handleClose={() => { setDeleteModal(null) }}
                    handleSubmit={() => { deleteBot(deleteModal) }}
                    captcha={'remover'}
                /> : null
			}
        	<Alert className="telegram-info" severity="info">
        		<AlertTitle>
        			Clique <a href="https://t.me/botfather" target="_blank">aqui</a> para criar seu Bot no Telegram.
    			</AlertTitle>
        		<Box className="push-s-bottom">
        			Com os seguintes dados em mãos:
        		</Box>
        		<Box>
	    			<p>nome</p> 
	    			<p>username</p>
	    			<p>token</p>
	    		</Box>
        		<Box className="push-s-top">
        			Você pode adicionar seu novo Bot no Kwik clicando no botão abaixo.
        		</Box>
        	</Alert>
        	{bots.length !== 0 &&
        		<>
		        	<Box className="category-title push-bottom">
		                Bots
		            </Box>
		            <Box className="telegram-bots">
	            		<table>
			            {bots.map((item, index) => (
			            	<tr>
			            		<td>{item.name}</td>
                                <td>@{item.username}</td>
                                <td>
	                                <IconButton>
	  									<DeleteIcon onClick={() => setDeleteModal(item.id)}/>
	  								</IconButton>
  								</td>
			            	</tr>
			            ))}
			            </table>
		            </Box>
		        </>
        	}
        	<Box className="telegram-mumbers-info">
	        	<LoadingButton startIcon={<AddIcon />} variant="contained"
	        		onClick={() => setShowAddBot(true)}
	        	>
	        		Adicionar
	        	</LoadingButton>
	        </Box>
        </Box>
	)
}

export default Telegram
