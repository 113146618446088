import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { Divider } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import LoadingButton from '@mui/lab/LoadingButton';
import PropTypes from "prop-types";

import "./index.css";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const VoiceDialog = ({
    open,
    handleClose,
    handleSubmit,
    submitButtonLoading
}) => {

    const [number, setNumber] = useState('');
    const [callerid, setCallerid] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [url, setUrl] = useState('');

    return (
        <div>
            <Dialog
                open={open}
                //TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                disablePortal
                disableEnforceFocus
                sx={{position:'absolute'}}
            >
                <DialogTitle id="customized-dialog-title" className={'group-flexbox'}>
                    <span>Adicionar número</span> <Button sx={{ 'minWidth': '2rem', 'padding': '0' }} onClick={handleClose} ><CloseIcon /></Button>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <TextField
                        size="small"
                        label="Número"
                        fullWidth
                        type="text"
                        variant="outlined"
                        value={number}
                        className="push-top"
                        onChange={(event) => setNumber(event.target.value)}
                    />
                    <TextField
                        size="small"
                        label="Máscara"
                        fullWidth
                        type="text"
                        variant="outlined"
                        value={callerid}
                        className="push-top"
                        onChange={(event) => setCallerid(event.target.value)}
                    />
                    <TextField
                        size="small"
                        label="Usuário"
                        fullWidth
                        type="text"
                        variant="outlined"
                        value={username}
                        className="push-top"
                        onChange={(event) => setUsername(event.target.value)}
                    />
                    <TextField
                        size="small"
                        label="Senha"
                        fullWidth
                        type="text"
                        variant="outlined"
                        value={password}
                        className="push-top"
                        onChange={(event) => setPassword(event.target.value)}
                    />
                    <TextField
                        size="small"
                        label="URL"
                        fullWidth
                        type="text"
                        variant="outlined"
                        value={url}
                        className="push-top"
                        onChange={(event) => setUrl(event.target.value)}
                    />
                </DialogContent>
                <DialogActions sx={{paddingRight:'1rem'}}>
                    <LoadingButton
                        onClick={handleClose}
                        color="primary"
                    >
                        Cancelar
                    </LoadingButton>
                    <LoadingButton
                        onClick={() => handleSubmit(number, username, password, url, callerid)}
                        color="primary"
                        loading={submitButtonLoading}
                        disabled={!number || !username || !password || !url || !callerid}
                    >
                        Adicionar
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </div>
    );
}

VoiceDialog.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    handleSubmit: PropTypes.func,
    submitButtonLoading: PropTypes.bool
};

export default VoiceDialog;
