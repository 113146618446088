import React from "react";
import PropTypes from "prop-types";
import { Card, CardContent, Typography } from '@mui/material';

const Location = ({name, address, latitude, longitude}) => {
   return (
    <Card sx={{ maxWidth: 400, margin: '0 auto', mt: 4 }}>
      <CardContent>
        <Typography variant="h5" component="div" gutterBottom>
          📍 {name ? name : 'Localização'}
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          {address}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Latitude: {latitude}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Longitude: {longitude}
        </Typography>
      </CardContent>
    </Card>
  );
}

Location.PropTypes = {
    name: PropTypes.string,
    address: PropTypes.string,
    latitude: PropTypes.string,
    longitude: PropTypes.string,
};

export default Location;