import React from 'react';
import PropTypes from "prop-types";

import { Grid, DialogActions, DialogContent, Box, Tooltip, Divider,
    DialogContentText, DialogTitle, Dialog, Button, Slide } from "@mui/material";

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import StatusIcon from '@mui/icons-material/CellTower';
import { AccessTime, WhatsApp } from '@mui/icons-material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import FileIcon from '@mui/icons-material/DescriptionOutlined';

import ChangeField from "../../ChangeField";
import AvatarName from "../../AvatarName";
import MessagesArea from "../../Chat/MessagesArea";
import MessageItem from "../../Chat/MessagesArea/ChatWindow/MessageItem";
import HistoryMessagesDialog from "../../Dialogs/HistoryMessagesDialog";

import "./styles.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class MessagesAreaClass extends React.Component {
  
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Box>
        <Dialog
          open={this.props.open}
          keepMounted
          onClose={this.props.handleClose}
          aria-describedby="alert-dialog-slide-description"
          fullWidth
          sx={{position:'absolute'}}
          disablePortal
          disableEnforceFocus
          onFocus={(e) => {e.preventDefault()}}
          maxWidth="lg"
        >
          <Box className='grid-scroller'>
            <Box className='grid-insider' sx={{minHeight: '35rem'}}>
            { this.props.buildMessageArea() }
            </Box>
            { this.props.showProfile &&
                <Grid item lg={3} md={4} sm={12} xs={12}
                    className={`contact-history-grid` }
                    sx={{maxHeight:'75vh'}}

                >
                    { this.props.buildProfile() }
                </Grid>
            }
            </Box>
 
        </Dialog>
      </Box>
    );
  }
}

export default MessagesAreaClass;