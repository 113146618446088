import React from 'react';

import { List, Tabs, Tab, Box, Divider, Fab, Badge, TextField } from '@mui/material';
import { ListItem, Paper, InputBase, IconButton } from '@mui/material';
import { CircularProgress, Tooltip, Popover, Button, Switch } from '@mui/material';
import { FormGroup, FormControlLabel, ButtonGroup } from '@mui/material';
import { Grid, Chip } from '@mui/material';

import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

import QueueIcon from '@mui/icons-material/Group';
import OngoingIcon from '@mui/icons-material/SupportAgent';
import ReactivationIcon from '@mui/icons-material/Update';
import FinishedIcon from '@mui/icons-material/Done';
import SearchIcon from '@mui/icons-material/Search';
import FilterIcon from '@mui/icons-material/FilterAlt';
import RefreshIcon from '@mui/icons-material/Refresh';

import AutorenewIcon from '@mui/icons-material/Autorenew';

import * as moment from "moment";
import PageVisibility from 'react-page-visibility';

import { getSession } from "../../../auth";
import api from '../../../api';
import useNotification from "../../Notification";
import Filter from '../../Filter';
import Typography from '../../Typography';
import ConversationItem from './ConversationItem';

import { FixedSizeList } from 'react-window';
import AutoSizer from "react-virtualized-auto-sizer"
import Skeleton from '@mui/material/Skeleton';

import "./styles.css";
import { TrainRounded } from '@mui/icons-material';

function TabPanel(props) {
    const { children, value, index } = props;
    const tabActive = value == index;
    const tabType = sub ? 'sub-tab-active' : 'tab-active';
    return (
        <div className={tabActive ? tabType : null}>
            {tabActive &&
                children
            }
        </div>
    );
}

class ConversationList extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            intervalId: null,
            queueChats: [],
            queueChatsFilter: [],
            ongoingChats: [],
            ongoingChatsFilter: [],
            reactivatedChats: [],
            reactivatedChatsFilter: [],
            finishedChats: [],
            finishedChatsFilter: [],
            queueChatsLength: 0,
            queueReactivationsLength: 0,
            queueFinishedLength: 0,
            fetched: false,
            showPopover: false,
            anchorEl: null,
            initialDate: null,
            finalDate: null,
            before24Hours: true,
            after24Hours: true,
            pageVisibility: false,
            valueSearchChat: null,
            isFiltering: false,
            groups: {},
            groupsAll: false,
            newChats: 0,
            unreadMessages: 0,
            limit: 10,
            offset: 0,
            total: 0,
            ongoingMessagesLength: 0,
            smallLoading: false,
            searchedValue: '',
            fetching: false,
            justme: false

        }

        this._isMounted = false;

        this.myRef = React.createRef();
        this.queueChatLoaded = [0]
        this.queueReactivatedLoaded = [0]
        this.queueFinishedLoaded = [0]

        this.timeout = 0

        this.audio = new Audio(`${window.location.origin}/new_message.mp3`);
    }

    handleVisibilityChange = (isVisible) => {
        this.setState({ pageVisibility: !isVisible });
    }

    ChangeNotificationTexts = (type, numberChannels, unreadMessages) => {
        let channelPlural = '';
        let messagePlural = {
            title: '',
            body: ''
        };

        if (type === 'newMessage') {
            if (unreadMessages > 1) {
                messagePlural.title = 'novas mensagens';
                messagePlural.body = 'Novas mensagens';

            } else {
                messagePlural.title = 'nova mensagem';
                messagePlural.body = 'Nova mensagem';
            }

            if (numberChannels > 1)
                channelPlural = 'conversações';
            else
                channelPlural = 'conversação';

        } else if (type === 'newChat') {
            if (numberChannels > 1) {
                messagePlural.title = 'novos Chats';
                messagePlural.body = 'Novos Chats';

            } else {
                messagePlural.title = 'novo Chat';
                messagePlural.body = 'Novo Chat';
            }
        }

        return {
            channelPlural: channelPlural,
            messagePlural: messagePlural
        };
    }

    browserNotification = (type, numberChannels, unreadMessages) => {
        let title = '';
        let body = '';

        if (type === 'newMessage') {
            // Change notification texts
            const descriptions = this.ChangeNotificationTexts(type, numberChannels, unreadMessages);

            title = `${unreadMessages} ${descriptions.messagePlural.title}`;
            body = `${descriptions.messagePlural.body} em ${numberChannels} ${descriptions.channelPlural}.`;

        } else if (type === 'newChat') {
            // Change notification texts
            const descriptions = this.ChangeNotificationTexts(type, numberChannels);

            title = `${numberChannels} ${descriptions.messagePlural.title}`;
            body = `${descriptions.messagePlural.body} aguardando na fila.`;
        }

        // Open the notification
        const notification = new Notification(title, {
            body: body,
            lang: 'pt-BR',
            icon: `${window.location.origin}/kwik_favicon.png`,
            silent: true
        });

        // Close the notification after 7 seconds
        setTimeout(() => {
            notification.close();
        }, 7 * 1000);

        // Navigate to a Chat
        notification.addEventListener('click', () => {
            window.open(`${window.location.origin}/chat`);
        });
    }

    deniedNotification = (permission) => {
        if (permission === "denied") {
            useNotification(
                'Permissão negada!',
                'Você não permitiu que o Kwik envie notificações de novas mensagens para você.',
                'danger'
            );
        }
    }

    requestPermission = () => {
        return new Promise((resolve, reject) => {
            Notification.requestPermission().then(permission => {
                // If the user accepts, let's create a notification
                if (permission === "granted") {
                    resolve();
                } else {
                    reject(permission);
                }
            });
        });
    }

    sendNotification = (type, numberChannels, unreadMessages) => {
        // Let's check if the browser supports notifications
        if (!("Notification" in window)) {
            useNotification(
                'Atenção!',
                'Este navegador não suporta notificação na área de trabalho.',
                'warning'
            );
        }

        // Let's check whether notification permissions have already been granted
        else if (Notification.permission === "granted") {
            // Open new message notification
            try{
                browserNotification(type, numberChannels, unreadMessages);
            }
            catch(e){
                console.error(e);
            }
        }

        // Otherwise, we need to ask the user for permission
        else if (Notification.permission !== "denied") {
            this.requestPermission()
                .then(() => {
                    // Open new message notification
                    try{
                        browserNotification(type, numberChannels, unreadMessages);
                    }
                    catch(e){
                        console.error(e);
                    }
                })
                .catch(permission => {
                    this.deniedNotification(permission);
                });
        }

        // At last, if the user has denied notifications, and you
        // want to be respectful there is no need to bother them any more.
    }

    checkUnreadMessages = (chats) => {
        let unreadChats = 0;
        let unreadMessages = 0;

        chats.forEach((chat) => {
            let unreadChatMessages = 0;
            chat.messages.forEach((message) => {
                unreadChatMessages += (!message.is_read) ? 1 : 0;
            });
            if (unreadChatMessages) {
                unreadChats += 1;
                unreadMessages += unreadChatMessages;
            }
        });

        const hasNewMessages = unreadMessages > this.state.unreadMessages;
        if (this.state.pageVisibility && hasNewMessages && this._isMounted) {
            // Hear new message sound
            this.audio.play();
            this.sendNotification('newMessage', unreadChats, unreadMessages);
        }

        this.state.unreadMessages = unreadMessages;
    }

    checkOperatorChat = (chat) => {
        return this.isManageCalls() ||
            (!this.isManageCalls() && this.props.agentId == chat.agents_id);
    }

    rerenderList = () => {
        if (this.myRef.current) {
            let scrollDirection = this.myRef.current.state.scrollDirection
            this.myRef.current.setState({ scrollDirection: scrollDirection == "forward" ? "backward" : "forward" })
        }
    }

    fetchChats = async () => {
        if (!this.props.enterpriseId || this.state.isFiltering) return;
                await api.get('api/enterprises/' + this.props.enterpriseId + '/calls/chat/count/')
                    .then(async (res) => {
                        if (res.status == 200) {
                            const queueChatsLength = await this.checkNewChats(res.data.queued);
                            const checkNewOngoingMessages = res.data.onchatmessages

                            if (checkNewOngoingMessages > this.state.ongoingMessagesLength) {
                                const ongoingchats = await this.getOnChat()
                                this.checkUnreadMessages(ongoingchats);
                            }
                            else if (!this.isManageCalls()) {
                                this.checkUnreadMessages(this.state.ongoingChats)
                            }

                            this.setState({
                                fetched               : true,
                                queueChatsLength      : queueChatsLength,
                                ongoingMessagesLength : checkNewOngoingMessages,
                            })

                            return;
                        }
                        throw res;
                    }).catch((err) => { console.error(err); });
            }


    buildNotifications = (notifications) => {
        let notificationsChat = []
        notifications.forEach((notification) => {
            let nick = notification.to.startsWith('+') ? notification.to : '+' + notification.to
            notificationsChat.push(
                {
                    contact: {
                        name: notification.contact_name,
                        nick: nick
                    },
                    messages: this.buildNotificationMessage(notification),
                    notification: true,
                    queue: { description: notification.queue_name },
                    reactivation: false,
                    platform: "WHATSAPP",
                    agent: { name: notification.agent_name },
                    uniqueid: notification.id,
                    callerid: notification.sender,
                    chatstatus: "PENDING",
                    time: notification.created_at,
                    fakeChat: true
                }
            )
        })
        return notificationsChat;
    }

    buildNotificationMessage = (notification) => {
        let template = JSON.parse(notification.template)
        let finalMessage = template.components.find(each => each.type == 'BODY').text
        if (notification.parameters !== null) {
            let parameters = JSON.parse((JSON.parse(notification.parameters)))
            parameters = parameters.find(each => 'body' in each).body
            parameters.forEach((parameter) => {
                finalMessage = finalMessage.replace(/\{\{\d*\}\}/, parameter)
            })
        }

        let time = new Date(notification.created_at)
        let final_time = (new Date(time - 3 * 60 * 60 * 1000)).toLocaleTimeString(
            [], { hour: '2-digit', minute: '2-digit', hour12: false })

        return [
            {
                message: finalMessage,
                user_id: 1,
                is_read: true,
                iso_time: final_time
            }
        ]
    }

    fetchFinishedChats = async () => {
        if (!this.props.enterpriseId || this.state.isFiltering) return;

        this.setState({ fetchingFinished: true });

        await api.get('api/enterprises/' + this.props.enterpriseId + '/calls/chat/finished/')
            .then(async (res) => {
                if (res.status == 200) {
                    this.setState({
                        finishedChats: res.data,
                        finishedChatsFilter: res.data,
                        fetchingFinished: false
                    });
                    return;
                }
                throw res;
            }).catch((err) => { console.error(err); });
    }

    checkNewChats = (newQueueChatsLength) => {
        let queueChatsLength = this.state.queueChatsLength;

        if (this.props.activeTab == 0 &&
            (newQueueChatsLength > queueChatsLength))
            this.getQueueChats()



        if (newQueueChatsLength > queueChatsLength) {
            const newChats = newQueueChatsLength - queueChatsLength;


            if (this.state.pageVisibility) {
                // Hear new message sound
                this.audio.play();
                this.sendNotification('newChat', newChats, null);
            }
            if (this.props.activeTab !== 0)
                this.state.newChats = newChats;
        }

        queueChatsLength = newQueueChatsLength;

        return queueChatsLength;
    }

    sortByLastMessage = (chat1, chat2) => {
        const time1 = chat1.messages[chat1.messages.length - 1].time;
        const time2 = chat2.messages[chat2.messages.length - 1].time;

        if (time1 > time2)
            return -1
        if (time1 < time2)
            return 1;

        return 0;
    }

    compareEqualArrays = (array_1, array_2) => {
        return JSON.stringify(array_1) === JSON.stringify(array_2);
    }

    setActiveTab = (event, tab) => {

        if (this.state.fetching)
            return

        this.props.closeProfile();
        this.props.setActiveChat({});
        this.props.setActiveTab(tab);

        if (this.notIsManageCalls() && this.state.isFiltering) {
            this.deleteFilter();
        }
    }

    setActiveSubTab = (event, tab) => {
        this.props.closeProfile();
        this.props.setActiveChat({});
        this.props.setActiveSubTab(tab);

        if (tab === 1) {
            this.fetchNotifications();
        }

        if (this.notIsManageCalls() && this.state.isFiltering) {
            this.deleteFilter();
        }
    }

    setActiveChat = (chat) => {
        this.props.closeProfile();
        this.props.setActiveChat(chat);
    }

    refreshActiveChat = () => {
        let queueChats = this.state.queueChats;
        let ongoingChats = this.state.ongoingChats;
        for (var i = 0; i < queueChats.length; i++) {
            if (this.props.activeChat.channel == queueChats[i].channel) {
                this.setActiveChat(queueChats[i]);
                return;
            }
        }
        for (var i = 0; i < ongoingChats.length; i++) {
            if (this.props.activeChat.channel == ongoingChats[i].channel) {
                this.setActiveChat(ongoingChats[i]);
                return;
            }
        }
    }

    closeProfile = () => {
        this.props.closeProfile();
    }

    addOngoingChat = (chat) => {
        let queueChats = this.state.queueChats.filter((queueChat) => {
            return queueChat.id != chat.id;
        });

        this.setState({
            queueChats: queueChats,
            ongoingChats: [chat].concat(this.state.ongoingChats)
        });
    }

    addReactivatedChat = (chat) => {
        const finishedChats = this.state.finishedChats.filter((finishedChat) => {
            return finishedChat.id != chat.id;
        });

        this.setState({
            finishedChats: finishedChats,
            finishedChatsFilter: finishedChats,
            reactivatedChats: [chat].concat(this.state.reactivatedChats)
        });
    }

    removeOngoingChat = (chat) => {
        const ongoingChats = this.state.ongoingChats.filter((queueChat) => {
            return queueChat.id != chat.id;
        });

        this.setState({
            ongoingChats: ongoingChats,
        });

        this.setState({
            ongoingChats: ongoingChats,
            finishedChats: finishedChats,
            finishedChatsFilter: finishedChats
        });
    }

    resetActiveChatProgress = () => {
        this.activeConversationItem.resetChatProgress();

        this.setState({
            queueChats: this.state.queueChats.sort(this.sortByLastMessage),
            ongoingChats: this.state.ongoingChats.sort(this.sortByLastMessage)
        });
    }

    setDateTime = (type, value) => {
        const initialDate = moment(new Date()).subtract(value, type).format("YYYY-MM-DDTHH:mm").toLocaleString();
        const finalDate = moment(new Date()).format("YYYY-MM-DDTHH:mm").toLocaleString();

        this.setState({
            initialDate: initialDate,
            finalDate: finalDate,
            renderKey: Math.random()
        });
    }

    filterDate = (initialDate, finalDate, stateChats) => {
        const chatsFilter = stateChats.filter(chat => {
            const lastUserMessageTime = chat.last_user_msg_time * 1000;
            return (lastUserMessageTime >= Date.parse(initialDate)) && (lastUserMessageTime <= Date.parse(finalDate));
        });

        return chatsFilter;
    }

    applyDateFilter = () => {
        const initialDate = this.state.initialDate;
        const finalDate = this.state.finalDate;

        let state = {
            queueChatsFilter: [],
            ongoingChatsFilter: [],
            reactivatedChatsFilter: [],
            finishedChatsFilter: [],
            isFiltering: true
        };

        if (this.isManageCalls()) {
            const queueChatsFilter = this.filterDate(initialDate, finalDate, this.state.queueChatsFilter);
            state.queueChatsFilter = queueChatsFilter;

            const ongoingChatsFilter = this.filterDate(initialDate, finalDate, this.state.ongoingChatsFilter);
            state.ongoingChatsFilter = ongoingChatsFilter;

            const reactivatedChatsFilter = this.filterDate(initialDate, finalDate, this.state.reactivatedChatsFilter);
            state.reactivatedChatsFilter = reactivatedChatsFilter;
        }

        const finishedChatsFilter = this.filterDate(initialDate, finalDate, this.state.finishedChatsFilter);
        state.finishedChatsFilter = finishedChatsFilter;

        this.setState(state);
    }

    filterChats = (valueSearchChat, stateChats) => {
        const chatsFilter = stateChats.filter(chat => {
            let contact_name = '';
            let agent_name = '';
            let queue_name = '';

            if (chat.contact?.name)
                contact_name = chat.contact.name.toLowerCase();

            if (chat.agent_name)
                agent_name = chat.agent_name.toLowerCase();

            if (chat.queue.description)
                queue_name = chat.queue.description.toLowerCase();

            const value_search_chat = valueSearchChat.toLowerCase();

            // If the search matches the chat code or 
            // contact number, contact name, agent name or
            // message sent in chat
            if (chat.uniqueid.includes(value_search_chat) ||
                chat.callerid.includes(value_search_chat) ||
                contact_name.includes(value_search_chat) ||
                agent_name.includes(value_search_chat) ||
                queue_name.includes(value_search_chat))
                return true;

            // Returns an array with messages equal to the search
            const messageChatsFilter = chat.messages.filter(messageChat => {
                return messageChat.message.toLowerCase().includes(value_search_chat);
            });

            // If messageChatsFilter size is greater than 1, found messages
            return (messageChatsFilter.length > 0) ? true : false;
        });

        return chatsFilter;
    }

    applyMessageFilter = () => {
        const valueSearchChat = this.state.valueSearchChat;

        if (!valueSearchChat) return;

        let state = { isFiltering: true };

        if (this.isManageCalls()) {
            const queueChats = this.state.queueChatsFilter;
            const queueChatsFilter = this.filterChats(valueSearchChat, queueChats);
            state.queueChatsFilter = queueChatsFilter;

            const ongoingChats = this.state.ongoingChatsFilter;
            const ongoingChatsFilter = this.filterChats(valueSearchChat, ongoingChats);
            state.ongoingChatsFilter = ongoingChatsFilter;

            const reactivatedChats = this.state.reactivatedChatsFilter;
            const reactivatedChatsFilter = this.filterChats(valueSearchChat, reactivatedChats);
            state.reactivatedChatsFilter = reactivatedChatsFilter;
        }

        const finishedChats = this.state.finishedChatsFilter;
        const finishedChatsFilter = this.filterChats(valueSearchChat, finishedChats);
        state.finishedChatsFilter = finishedChatsFilter;

        this.setState(state);
    }

    getMessageFilter = async (search = '', ini = '', end = '') => {
        if (search == '') {
            this.setState({ finishedChatsFilter: null })
            return
        }
        this.setState({ smallLoading: true })
        const res = await api.get(`/api/enterprises/${this.props.enterpriseId}/calls/chat/finished/?search=${search}&limit=${this.state.limit}&offset=0&ini=${ini}&end=${end}`)

        if (res.status == 200) {
            this.setState({
                finishedChatsFilter: res.data.results,
                searchedValue: search
            })
            this.rerenderList()
        }

        this.setState({ smallLoading: false })
    }


    onChangeSearchChat = (event) => {
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
            this.getMessageFilter(event.target.value)
            this.timeout = 0
        }, 500)

        // if (event.target.value.length >= 3)
        //     this.applyMessageFilter();

        // const valueSearchChat = event.target.value;
        // const stateSearchChat = this.state.valueSearchChat;

        // if (valueSearchChat !== stateSearchChat &&
        //     (stateSearchChat && (valueSearchChat.length < stateSearchChat.length))) {
        //     this.deleteFilter();
        // }

        // this.setState({ valueSearchChat: valueSearchChat });
    }

    onChangeDateStart = (event) => {
        const dateStartTime = event.target.value;

        this.setState({ initialDate: dateStartTime });
    }

    onChangeDateEnd = (event) => {
        const dateEndTime = event.target.value;

        this.setState({ finalDate: dateEndTime });
    }

    handleKeyPressMessage = (event) => {
        if (event && event.key === 'Enter') {
            event.preventDefault();

            this.applyMessageFilter();
        }
    }

    chatsLength = (stateName) => {
        const length = this.state[stateName].length;

        return (
            <Box className={length < 100 ? 'queue-length length-small' : 'queue-length length-big'}>
                {length}
            </Box>
        );
    }

    setSwitch = (type, condition) => {
        let state = {};

        if (type === 'before') {
            if (condition) {
                state.finalDate = moment(new Date()).format("YYYY-MM-DDTHH:mm").toLocaleString();

            } else {
                state.finalDate = moment(new Date()).subtract(1, 'day').format("YYYY-MM-DDTHH:mm").toLocaleString();
            }

            state.before24Hours = condition;

        } else if (type === 'after') {
            if (condition) {
                state.initialDate = moment(new Date()).subtract(7, 'day').format("YYYY-MM-DDTHH:mm").toLocaleString();

            } else {
                state.initialDate = moment(new Date()).subtract(1, 'day').format("YYYY-MM-DDTHH:mm").toLocaleString();
            }

            state.after24Hours = condition;
        }

        state.renderKey = Math.random();

        this.setState(state)
    }

    setChecked = async (key, condition) => {
        let state = {};
        let queues = { ...this.state.groups };
        if (key === 'all') {
            this.state.groupAll = condition;
            Object.keys(queues).map((key, index) => {
                queues[key].checked = condition;
            });
        } else {
            queues[key].checked = condition;
            if (!condition) {
                state.groupAll = false;
                state.isFiltering = true;
            }
        }
        state.groups = queues;
        await this.setState(state);
        this.applyQueuesFilter();
    }

    queuesFilter = (stateName) => {
        let state = { ...this.state };
        const chatsFilter = state[stateName].filter(chat => {
            const queueName = chat.queue.name.split('_')[1];
            return state.groups[queueName].checked;
        });
        state[`${stateName}Filter`] = chatsFilter;
        this.setState(state);
    }

    applyQueuesFilter = async () => {
        await this.queuesFilter('queueChats');
        await this.queuesFilter('ongoingChats');
        await this.queuesFilter('reactivatedChats');
        this.queuesFilter('finishedChats');
    }

    showFilters = () => {
        return (
            <Box>
                <ListItem button key={-1} className="group-item">
                    <FormControlLabel
                        label="Todas"
                        control={
                            <Switch
                                checked={this.state.groupAll}
                                onChange={() => this.setChecked('all', !this.state.groupAll)}
                            />
                        }
                    />
                </ListItem>
                {Object.keys(this.state.groups).map((key, index) => (
                    <ListItem button key={index} className="group-item">
                        <FormControlLabel
                            label={this.state.groups[key].description}
                            control={
                                <Switch
                                    checked={this.state.groups[key].checked}
                                    onChange={() => this.setChecked(key, !this.state.groups[key].checked)}
                                />
                            }
                        />
                    </ListItem>
                ))}
            </Box>
        );
    }

    deleteFilter = async () => {
        await this.setState({
            showPopover: false,
            anchorEl: null,
            queueChatsFilter: this.state.queueChats,
            ongoingChatsFilter: this.state.ongoingChats,
            reactivatedChatsFilter: this.state.reactivatedChats,
            finishedChatsFilter: this.state.finishedChats,
            isFiltering: false
        });

        this.setChecked('all', true);
    }

    setWindows = (type, condition) => {
        let state = { renderKey: Math.random() };
        if (type === 'before') {
            if (condition) {
                state.finalDate = moment(new Date()).format("YYYY-MM-DDTHH:mm").toLocaleString();
            } else {
                state.finalDate = moment(new Date()).subtract(1, 'day').format("YYYY-MM-DDTHH:mm").toLocaleString();
            }
            state.before24Hours = condition;
        } else if (type === 'after') {
            if (condition) {
                state.initialDate = moment(new Date()).subtract(7, 'day').format("YYYY-MM-DDTHH:mm").toLocaleString();
            } else {
                state.initialDate = moment(new Date()).subtract(1, 'day').format("YYYY-MM-DDTHH:mm").toLocaleString();
            }
            state.after24Hours = condition;
        }
        this.setState(state);
    }

    disableButton = () => {
        return !(this.state.before24Hours || this.state.after24Hours);
    }

    isManageCalls = () => {
        return this.props.isManageCalls;
    }

    notIsManageCalls = () => {
        return !this.props.isManageCalls;
    }

    setGroups = () => {
        let groups = {};
        const user = getSession();
        user.profile.enterprise.queues.forEach(queue => {
            const key = queue.name.split('_')[1];
            groups[key] = { checked: true, description: queue.description };
        });
        this.setState({ groups: groups, groupAll: true });
    }

    setNotificationAudio = () => {
        const notificationAudio = this.props.notificationAudio;

        if (notificationAudio) {
            this.audio = new Audio(`${window.location.origin}/export/${notificationAudio.split('/export/')[1]}`);
        }
    }

    //Show chats and show skeleton onLoading.
    renderQueueChats = (props) => {
        const { index, style } = props;

        let difference = Math.abs(index % this.state.limit)
        let pooling = 0

        if (difference == 0 && !this.queueChatLoaded.includes(index)) {
            this.state.offset = index
            this.getQueueChats(index)
            this.queueChatLoaded.push(index)
        }
        let chat = this.state.queueChats[index]

        return (
            <ListItem style={style} key={index} component="div" disablePadding onClick={(this.rerenderList)}>

                <Box key={`${index}-key-on-queue`} bgcolor='' sx={{ width: "100%", height: "100%" }}>
                    {chat ?
                        <>
                            {chat.uniqueid === this.props.activeChat.uniqueid &&
                                <ConversationItem
                                    sx={{ height: "100%", justifyContent: "center" }}
                                    ref={
                                        instance => {
                                            this.activeConversationItem = instance;
                                        }
                                    }
                                    onClick={() => {
                                        this.setActiveChat(chat);
                                    }}
                                    activeId={this.props.activeChat.uniqueid}
                                    showProgressTime={false}
                                    conversation={chat}
                                    setReadMessages={this.props.setReadMessages}
                                />
                            }
                            {chat.uniqueid !== this.props.activeChat.uniqueid &&
                                <ConversationItem sx={{ height: "100%", justifyContent: "center" }}
                                    onClick={() => {
                                        this.setActiveChat(chat);
                                    }}
                                    activeId={this.props.activeChat.uniqueid}
                                    showProgressTime={false}
                                    conversation={chat}
                                    setReadMessages={this.props.setReadMessages}
                                />
                            }

                        </>
                        :
                        <Box
                            sx={{ height: "100%", justifyContent: "center" }}
                        ><Skeleton sx={{ height: "100%" }} variant="rectangular" animation="wave" />
                        </Box>}
                    <Divider />
                </Box>


            </ListItem>
        );
    }
    renderReactivatedChats = (props) => {
        const { index, style } = props;

        let difference = Math.abs(index % this.state.limit)
        let pooling = 0

        if (difference == 0 && !this.queueReactivatedLoaded.includes(index)) {
            this.state.offset = index
            this.getReactivated(index)
            this.queueReactivatedLoaded.push(index)
        }
        let chat = this.state.reactivatedChats[index]

        return (
            <ListItem style={style} key={index} component="div" disablePadding onClick={(this.rerenderList)}>

                <Box key={`${index}-key-on-queue-reactivated`} bgcolor='' sx={{ width: "100%", height: "100%" }}>
                    {chat ?
                        <>
                            {chat.uniqueid === this.props.activeChat.uniqueid &&
                                <ConversationItem
                                    ref={
                                        instance => {
                                            this.activeConversationItem = instance;
                                        }
                                    }
                                    onClick={() => {
                                        this.setActiveChat(chat);
                                    }}
                                    activeId={this.props.activeChat.uniqueid}
                                    showProgressTime={false}
                                    conversation={chat}
                                    setReadMessages={this.props.setReadMessages}
                                />
                            }
                            {chat.uniqueid !== this.props.activeChat.uniqueid &&
                                <ConversationItem
                                    onClick={() => {
                                        this.setActiveChat(chat);
                                    }}
                                    activeId={this.props.activeChat.uniqueid}
                                    showProgressTime={false}
                                    conversation={chat}
                                    setReadMessages={this.props.setReadMessages}
                                />
                            }

                        </>
                        :
                        <Box
                            sx={{ height: "100%", justifyContent: "center" }}
                        ><Skeleton sx={{ height: "100%" }} variant="rectangular" animation="wave" />
                        </Box>}
                    <Divider />
                </Box>


            </ListItem>
        );
    }

    renderFinishedChats = (props) => {
        const { index, style } = props;

        let difference = Math.abs(index % this.state.limit)
        let pooling = 0

        if (difference == 0 && !this.queueFinishedLoaded.includes(index)) {
            this.state.offset = index
            this.getFinished(index)
            this.queueFinishedLoaded.push(index)
        }

        let chat = this.state.finishedChats[index]
        if (this.state.searchedValue && this.state.finishedChatsFilter)
            chat = this.state.finishedChatsFilter[index]


        return (
            <ListItem style={style} key={index} component="div" disablePadding onClick={(this.rerenderList)}>

                <Box key={`${index}-key-on-queue-finished`} bgcolor='' sx={{ width: "100%", height: "100%" }}>
                    {chat ?
                        <>
                            {chat.uniqueid === this.props.activeChat.uniqueid &&
                                <ConversationItem
                                    ref={
                                        instance => {
                                            this.activeConversationItem = instance;
                                        }
                                    }
                                    onClick={() => {
                                        this.setActiveChat(chat);
                                    }}
                                    activeId={this.props.activeChat.uniqueid}
                                    showProgressTime={false}
                                    conversation={chat}
                                    setReadMessages={this.props.setReadMessages}
                                />
                            }
                            {chat.uniqueid !== this.props.activeChat.uniqueid &&
                                <ConversationItem
                                    onClick={() => {
                                        this.setActiveChat(chat);
                                    }}
                                    activeId={this.props.activeChat.uniqueid}
                                    showProgressTime={false}
                                    conversation={chat}
                                    setReadMessages={this.props.setReadMessages}
                                />
                            }

                        </>
                        :
                        <Box
                            sx={{ height: "100%", justifyContent: "center" }}
                        ><Skeleton sx={{ height: "100%" }} variant="rectangular" animation="wave" />
                        </Box>}
                    <Divider />
                </Box>


            </ListItem>
        );
    }

    componentDidMount() {
        this.fetchChats();
        this._isMounted = true;
    }

    componentWillUnmount() {
        clearInterval(this.state.intervalId);
        this._isMounted = false;
    }

    componentWillMount() {
        this.setNotificationAudio();
        this.setDateTime('day', 7);
        this.setGroups();

        this.requestPermission()
            .catch(permission => {
                this.deniedNotification(permission);
            });
    }

    ComponentDidUpdate() {

    }

    //If new chat, we should add it to the respective list accordingly
    webSocketGetChats = async () => {
        if (this.props.chatState && this.props.chatState.new) {
            this.props.chatState.new = false

            const message = this.props.chatState.message
            const channel = this.props.chatState.channel
            switch (message) {
                case 'NEW_CHAT':
                    const newChats = await this.getQueueChats(0, channel)

                    if (this.state.pageVisibility) {
                        // Hear new message sound
                        this.audio.play();
                        this.sendNotification('newChat', newChats, null);
                    }
                    if (this.props.activeTab !== 0)
                        this.setState({ newChats: newChats })
                    break;

                case 'HANGUP':
                    this.getFinished(0, channel)
                    break;

                default:
                    break;
            }
        }
    }


    render() {

        this.webSocketGetChats()


        const styles = {
            row: {
                width: '100%',
                backgroundColor: '',
                maxHeight: this.props.isManageCalls ? '65vh' : '100%',
                overflow: 'auto',
                padding: 0,
                position: this.props.isManageCalls ? '' : 'relative'
            }
        };



        return (
            <>
                {this.notIsManageCalls() &&
                    <>
                        <PageVisibility onChange={this.handleVisibilityChange}></PageVisibility>
                        <Box id="conversationList-box-1">
                            <Box id="conversationList-box-2">
                                <Box id="conversationList-box-3">
                                    <Tabs
                                        value={this.props.activeTab}
                                        //onChange={this.setActiveTab}
                                        onChange={this.setActiveTab}
                                        className={`tabs-conversation-list ${this.state.fetching ? 'tabs-list-blocked' : ''}`}
                                    >
                                        <Tab onClick={() => this.setState({ newChats: 0 })}
                                            label={
                                                <Tooltip title="Fila" placement="bottom" disableInteractive>
                                                    <Badge color="secondary" variant="dot" invisible={this.state.newChats === 0}>
                                                        <QueueIcon />
                                                    </Badge>
                                                </Tooltip>
                                            }
                                            className="tab badge-unreadMessages"
                                        />
                                        <Tab
                                            label={
                                                <Tooltip title="Atendimento" placement="bottom" disableInteractive>
                                                    <Badge color="secondary" variant="dot" invisible={this.state.unreadMessages === 0}>
                                                        <OngoingIcon />
                                                    </Badge>
                                                </Tooltip>
                                            }
                                            className="tab badge-unreadMessages"
                                        />
                                        <Tab
                                            label={
                                                <Tooltip title="Pendente" placement="bottom" disableInteractive>
                                                    <ReactivationIcon />
                                                </Tooltip>
                                            }
                                            className="tab"
                                        />
                                        <Tab
                                            label={
                                                <Tooltip title="Finalizado" placement="bottom" disableInteractive>
                                                    <FinishedIcon />
                                                </Tooltip>
                                            }
                                            className="tab"
                                        />
                                    </Tabs>
                                </Box>
                                <TabPanel value={this.props.activeTab} index={0}>
                                    <List style={styles.row}>
                                        {this.state.queueChats.map((chat, i) =>
                                        (<Box key={i} bgcolor=''>
                                            {chat.uniqueid === this.props.activeChat.uniqueid &&
                                                <ConversationItem
                                                    ref={
                                                        instance => {
                                                            this.activeConversationItem = instance;
                                                        }
                                                    }
                                                    onClick={() => {
                                                        this.setActiveChat(chat);
                                                    }}
                                                    activeId={this.props.activeChat.uniqueid}
                                                    showProgressTime={false}
                                                    conversation={chat}
                                                    setReadMessages={this.props.setReadMessages}
                                                    labels={this.props.labels}
                                                />
                                            }
                                            {chat.uniqueid !== this.props.activeChat.uniqueid &&
                                                <ConversationItem
                                                    onClick={() => {
                                                        this.setActiveChat(chat);
                                                    }}
                                                    activeId={this.props.activeChat.uniqueid}
                                                    showProgressTime={false}
                                                    conversation={chat}
                                                    setReadMessages={this.props.setReadMessages}
                                                    labels={this.props.labels}
                                                />
                                            }
                                            <Divider />
                                        </Box>)
                                        )}
                                        {!this.state.fetched &&
                                            <Box id="conversationList-box-4">
                                                <CircularProgress />
                                            </Box>
                                        }
                                    </List>
                                </TabPanel>
                                <TabPanel value={this.props.activeTab} index={1}>
                                    {/* <List style={styles.row}>
                                        {this.state.ongoingChats.map((chat, i) =>
                                        (<Box key={i} bgcolor=''>
                                            {chat.uniqueid === this.props.activeChat.uniqueid &&
                                                <ConversationItem
                                                    ref={
                                                        instance => {
                                                            this.activeConversationItem = instance;
                                                        }
                                                        onClick={() => {
                                                        this.setActiveChat(chat);
                                                    }}
                                                    showProgressTime={true}
                                                    activeId={this.props.activeChat.uniqueid}
                                                    conversation={chat}
                                                    setReadMessages={this.props.setReadMessages}
                                                    labels={this.props.labels}
                                                />
                                            }
                                            {chat.uniqueid !== this.props.activeChat.uniqueid &&
                                                <ConversationItem
                                                    onClick={() => {
                                                        this.setActiveChat(chat);
                                                    }}
                                                    showProgressTime={true}
                                                    activeId={this.props.activeChat.uniqueid}
                                                    conversation={chat}
                                                    setReadMessages={this.props.setReadMessages}
                                                    labels={this.props.labels}
                                                />
                                            }
                                            <Divider />
                                        </Box>)
                                        )}
                                        {!this.state.fetched &&
                                            <Box id="conversationList-box-5">
                                                <CircularProgress />
                                            </Box>
                                        }
                                    </List> */}
                                </TabPanel>
                                <TabPanel value={this.props.activeTab} index={2}>
                                    <Tabs
                                        value={this.props.activeSubTab}
                                        onChange={this.setActiveSubTab}
                                        className="tabs-conversation-list"
                                    >
                                        <Tab
                                            label={"Reativações"}
                                            className="sub-tab"
                                        />
                                        <Tab
                                            label={"Notificações"}
                                            className="sub-tab"
                                        />
                                    </Tabs>
                                    <TabPanel value={this.props.activeSubTab} index={0} sub={true}>
                                        <List style={
                                            {
                                                width: '100%',
                                                backgroundColor: '',
                                                maxHeight: this.props.isManageCalls ? '65vh' : '85%',
                                                overflow: 'auto',
                                                padding: 0,
                                                position: this.props.isManageCalls ? '' : 'absolute'
                                            }
                                        }
                                        >
                                            {this.state.reactivatedChats.map((chat, i) =>
                                            (<Box key={i} bgcolor=''>
                                                {chat.uniqueid === this.props.activeChat.uniqueid &&
                                                    <ConversationItem
                                                        ref={
                                                            instance => {
                                                                this.activeConversationItem = instance;
                                                            }
                                                        }
                                                        onClick={() => {
                                                            this.setActiveChat(chat);
                                                        }}
                                                        activeId={this.props.activeChat.uniqueid}
                                                        showProgressTime={false}
                                                        conversation={chat}
                                                        setReadMessages={this.props.setReadMessages}
                                                        labels={this.props.labels}
                                                    />
                                                }
                                                {chat.uniqueid !== this.props.activeChat.uniqueid &&
                                                    <ConversationItem
                                                        onClick={() => {
                                                            this.setActiveChat(chat);
                                                        }}
                                                        activeId={this.props.activeChat.uniqueid}
                                                        showProgressTime={false}
                                                        conversation={chat}
                                                        setReadMessages={this.props.setReadMessages}
                                                        labels={this.props.labels}
                                                    />
                                                }
                                                <Divider />
                                            </Box>)
                                            )}
                                            {!this.state.fetched &&
                                                <Box id="conversationList-box-6">
                                                    <CircularProgress />
                                                </Box>
                                            }
                                        </List>
                                    </TabPanel>
                                    <TabPanel value={this.props.activeSubTab} index={1} sub={true}>
                                        <List style={
                                            {
                                                width: '100%',
                                                backgroundColor: '',
                                                maxHeight: this.props.isManageCalls ? '65vh' : '85%',
                                                overflow: 'auto',
                                                padding: 0,
                                                position: this.props.isManageCalls ? '' : 'absolute'
                                            }
                                        }>
                                            {/* {this.state.fetched &&
                                                // <Box>
                                                //     {this.state.notifications.map((chat, i) =>
                                                //     (<Box key={i} bgcolor=''>
                                                //         {chat.uniqueid === this.props.activeChat.uniqueid &&
                                                //             <ConversationItem
                                                //                 ref={
                                                //                     instance => {
                                                //                         this.activeConversationItem = instance;
                                                //                     }
                                                //                 }
                                                //                 onClick={() => {
                                                //                     this.setActiveChat(chat);
                                                //                 }}
                                                //                 activeId={this.props.activeChat.uniqueid}
                                                //                 showProgressTime={false}
                                                //                 conversation={chat}
                                                //                 setReadMessages={this.props.setReadMessages}
                                                //             />
                                                //         }
                                                //         onClick={() => {
                                                //             this.setActiveChat(chat);
                                                //         }}
                                                //         activeId={this.props.activeChat.uniqueid}
                                                //         showProgressTime={false}
                                                //         conversation={chat}
                                                //         setReadMessages={this.props.setReadMessages}
                                                //         labels={this.props.labels}
                                                //         />
                                                //     }
                                                //         {chat.uniqueid !== this.props.activeChat.uniqueid &&
                                                //             <ConversationItem
                                                //                 onClick={() => {
                                                //                     this.setActiveChat(chat);
                                                //                 }}
                                                //                 activeId={this.props.activeChat.uniqueid}
                                                //                 showProgressTime={false}
                                                //                 conversation={chat}
                                                //                 setReadMessages={this.props.setReadMessages}
                                                //                 labels={this.props.labels}
                                                //             />
                                                //         }
                                                //         <Divider />
                                                //     </Box>)
                                                //     )}
                                                // </Box>
                                            } */}
                                            {!this.state.fetched &&
                                                <Box id="conversationList-box-7">
                                                    <CircularProgress />
                                                </Box>
                                            }
                                        </List>
                                        <Box className="pagination-buttons">
                                            <Button
                                                className="pagination-btn"
                                                onClick={() => { this.updateNotificationOffset("-") }}
                                                disabled={this.state.backNotiButton}
                                            >
                                                <ArrowBackIcon />
                                            </Button>
                                            <Button
                                                className="pagination-btn"
                                                onClick={() => { this.updateNotificationOffset("+") }}
                                                disabled={this.state.forWardNotiButton}
                                            >
                                                <ArrowForwardIcon />
                                            </Button>
                                        </Box>
                                    </TabPanel>
                                </TabPanel>
                                <TabPanel value={this.props.activeTab} index={3}>
                                    <Box className="filter-finished-chats">
                                        <Paper
                                            component="form"
                                            sx={{
                                                p: '2px 4px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                borderRadius: 0,
                                                bgcolor: '#efefef',
                                                height: 55,
                                                boxShadow: 0
                                            }}
                                        >
                                            <Tooltip title="Pesquisar por código, mensagem, nome ou número" placement="bottom" disableInteractive>
                                                <InputBase
                                                    sx={{ ml: 1, flex: 1 }}
                                                    placeholder="Pesquisar atendimentos"
                                                    onChange={this.onChangeSearchChat}
                                                //onKeyUp={this.onChangeSearchChat}
                                                // onKeyPress={this.handleKeyPressMessage}
                                                />
                                            </Tooltip>
                                            <Tooltip title="Aplicar filtro da pesquisa por texto" placement="bottom" disableInteractive>
                                                {this.state.smallLoading ? <IconButton><AutorenewIcon className={'spinner'} /></IconButton>
                                                    :
                                                    <IconButton
                                                        sx={{ p: '10px' }} aria-label="search"
                                                        onClick={() => this.applyMessageFilter()}
                                                    >
                                                        <SearchIcon />
                                                    </IconButton>
                                                }

                                            </Tooltip>
                                            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                                            <Tooltip title="Filtros por data e hora" placement="bottom" disableInteractive>
                                                <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions"
                                                    onClick={(event) => this.setState({
                                                        showPopover: true,
                                                        anchorEl: event.currentTarget
                                                    })}
                                                    className={
                                                        this.state.isFiltering
                                                            ? 'active-filter-icon'
                                                            : 'inactive-filter-icon'
                                                    }
                                                >
                                                    <FilterIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                                            <Tooltip title="Carregar chats finalizados" placement="bottom" disableInteractive>
                                                <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions"
                                                    onClick={() => this.fetchFinishedChats()}
                                                    disabled={this.state.fetchingFinished}
                                                    className="refresh-finished-btn"
                                                >
                                                    <RefreshIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Popover
                                                className="popover-filter"
                                                open={this.state.showPopover}
                                                anchorEl={this.state.anchorEl}
                                                onClose={() => this.setState({
                                                    showPopover: false,
                                                    anchorEl: null
                                                })}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'left',
                                                }}
                                            >
                                                <FormGroup className="filter-switch">
                                                    <Box className="title-filters">Visualização dos contatos</Box>
                                                    <Box className="switchs-filters">
                                                        <FormControlLabel
                                                            control={
                                                                <Switch
                                                                    checked={this.state.before24Hours}
                                                                    onClick={() => this.setSwitch('before', !this.state.before24Hours)}
                                                                />
                                                            }
                                                            label="Dentro da janela de 24 horas"
                                                            className="switch-item"
                                                        />
                                                        <FormControlLabel
                                                            control={
                                                                <Switch
                                                                    checked={this.state.after24Hours}
                                                                    onClick={() => this.setSwitch('after', !this.state.after24Hours)}
                                                                />
                                                            }
                                                            label="Fora da janela de 24 horas"
                                                            className="switch-item"
                                                        />
                                                    </Box>
                                                </FormGroup>
                                                <FormGroup className="filters-body" key={this.state.renderKey}>
                                                    <Box className="title-filters">Intervalo de datas</Box>
                                                    <Tooltip title="Data inicial do filtro" placement="right" disableInteractive>
                                                        <TextField
                                                            className="date-start-time"
                                                            size="small"
                                                            label="Início"
                                                            type="datetime-local"
                                                            variant="outlined"
                                                            defaultValue={this.state.initialDate}
                                                            disabled={this.disableButton()}
                                                            onChange={this.onChangeDateStart}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    </Tooltip>
                                                    <Tooltip title="Data final do filtro" placement="right" disableInteractive>
                                                        <TextField
                                                            className="date-end-time"
                                                            size="small"
                                                            label="Fim"
                                                            type="datetime-local"
                                                            variant="outlined"
                                                            defaultValue={this.state.finalDate}
                                                            disabled={this.disableButton()}
                                                            onChange={this.onChangeDateEnd}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </FormGroup>
                                                <Box className="filters-buttons">
                                                    <Box className="title-filters">Filtros rápidos</Box>
                                                    <ButtonGroup
                                                        className="buttons-group"
                                                        variant="outlined"
                                                        aria-label="outlined button group"
                                                        size="medium"
                                                        disabled={this.disableButton()}
                                                    >
                                                        <Tooltip title="Definir intervalo de datas em 24 horas" placement="top" disableInteractive>
                                                            <Button onClick={() => this.setDateTime('day', 1)}>
                                                                24 horas
                                                            </Button>
                                                        </Tooltip>
                                                        <Tooltip title="Definir intervalo de datas em 1 semana" placement="top" disableInteractive>
                                                            <Button onClick={() => this.setDateTime('day', 7)}>
                                                                1 semana
                                                            </Button>
                                                        </Tooltip>
                                                        <Tooltip title="Definir intervalo de datas em 1 mês" placement="top" disableInteractive>
                                                            <Button onClick={() => this.setDateTime('months', 1)}>
                                                                1 mês
                                                            </Button>
                                                        </Tooltip>
                                                    </ButtonGroup>
                                                </Box>
                                                <Box className="filters-action">
                                                    <Button className="button-discard"
                                                        onClick={() => this.setState({
                                                            showPopover: false,
                                                            anchorEl: null,
                                                            finishedChatsFilter: this.state.finishedChats
                                                        })}
                                                    >
                                                        Descartar
                                                    </Button>
                                                    <Button variant="contained"
                                                        disabled={this.disableButton()}
                                                        onClick={() => this.applyDateFilter()}
                                                    >
                                                        Aplicar
                                                    </Button>
                                                </Box>
                                            </Popover>
                                        </Paper>
                                    </Box>

                                    {this.state.fetched ?
                                        <AutoSizer>
                                            {({ height, width }) =>
                                            (
                                                <FixedSizeList
                                                    ref={this.myRef}
                                                    height={height}
                                                    width={width}
                                                    itemSize={100}
                                                    itemCount={
                                                        this.state.searchedValue && this.state.finishedChatsFilter ?
                                                            this.state.finishedChatsFilter.length :
                                                            this.state.queueFinishedLength}
                                                    overscanCount={5}
                                                >
                                                    {this.renderFinishedChats}
                                                </FixedSizeList>
                                            )
                                            }
                                        </AutoSizer>
                                        :
                                        <Box id="conversationList-box-4">
                                            <CircularProgress />
                                        </Box>
                                    }
                                    {/* <List style={styles.row}>
                                        {this.state.finishedChatsFilter.map((chat, i) =>
                                        (<Box key={i} bgcolor=''>
                                            {chat.uniqueid === this.props.activeChat.uniqueid &&
                                                <ConversationItem
                                                    ref={
                                                        instance => {
                                                            this.activeConversationItem = instance;
                                                        }
                                                    }
                                                    onClick={() => {
                                                        this.setActiveChat(chat);
                                                    }}
                                                    activeId={this.props.activeChat.uniqueid}
                                                    showProgressTime={false}
                                                    conversation={chat}
                                                    setReadMessages={this.props.setReadMessages}
                                                />
                                            }
                                            {chat.uniqueid !== this.props.activeChat.uniqueid &&
                                                <ConversationItem
                                                    onClick={() => {
                                                        this.setActiveChat(chat);
                                                    }}
                                                    activeId={this.props.activeChat.uniqueid}
                                                    showProgressTime={false}
                                                    conversation={chat}
                                                    setReadMessages={this.props.setReadMessages}
                                                />
                                            }
                                            <Divider />
                                        </Box>)
                                                        onClick={() => {
                                                            this.setActiveChat(chat);
                                                        }}
                                                        activeId={this.props.activeChat.uniqueid}
                                                        showProgressTime={false}
                                                        conversation={chat}
                                                        setReadMessages={this.props.setReadMessages}
                                                        labels={this.props.labels}
                                                    />
                                                }
                                                {chat.uniqueid !== this.props.activeChat.uniqueid &&
                                                    <ConversationItem
                                                        onClick={() => {
                                                            this.setActiveChat(chat);
                                                        }}
                                                        activeId={this.props.activeChat.uniqueid}
                                                        showProgressTime={false}
                                                        conversation={chat}
                                                        setReadMessages={this.props.setReadMessages}
                                                        labels={this.props.labels}
                                                    />
                                                }
                                                <Divider/>
                                            </Box>)
                                        )}
                                        {!this.state.fetched &&
                                            <Box id="conversationList-box-6">
                                                <CircularProgress />
                                            </Box>
                                        }
                                    </List> */}
                                </TabPanel>
                            </Box>
                        </Box>
                    </>
                }
                {this.isManageCalls() &&
                    <>
                        <Box className="filter-chats">
                            <Paper
                                component="form"
                                sx={{ display: 'flex', alignItems: 'center', width: 500, height: 50, bgcolor: '#efefef' }}
                            >
                                <Tooltip title="Filtrar por código da chamada, número de telefone, nome do contato ou operador" placement="bottom" disableInteractive>
                                    <InputBase
                                        sx={{ ml: 1, flex: 1 }}
                                        placeholder="Pesquisar atendimentos"
                                        onChange={this.onChangeSearchChat}
                                    //onKeyUp={this.onChangeSearchChat}
                                    // onKeyPress={this.handleKeyPressMessage}
                                    />
                                </Tooltip>
                                <Tooltip title="Aplicar filtro da pesquisa por texto" placement="bottom" disableInteractive>
                                    <IconButton
                                        sx={{ p: '10px' }} aria-label="search"
                                        onClick={() => this.applyMessageFilter()}
                                    >
                                        <SearchIcon />
                                    </IconButton>
                                </Tooltip>
                                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                                <Tooltip title="Filtrar por filas de atendimentos" placement="bottom" disableInteractive>
                                    <Box className="manage-calls-filter">
                                        <Filter content={
                                            <List className="manage-calls-list">
                                                <Box className="manage-calls-queues">
                                                    <Typography align="center"
                                                        text="Filas de Atendimento"
                                                        color="darkGray"
                                                        fontWeight='500'
                                                        className="group-item"
                                                    />
                                                </Box>
                                                {this.showFilters()}
                                            </List>
                                        } />
                                    </Box>
                                </Tooltip>
                                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                                <Tooltip title="Outros filtros" placement="bottom" disableInteractive>
                                    <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions"
                                        onClick={(event) => this.setState({
                                            showPopover: true,
                                            anchorEl: event.currentTarget
                                        })}
                                        className="filter-icon"
                                    >
                                        <FilterIcon />
                                    </IconButton>
                                </Tooltip>
                                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                                <Tooltip title="Carregar chats finalizados" placement="bottom" disableInteractive>
                                    <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions"
                                        onClick={() => this.fetchFinishedChats()}
                                        disabled={this.state.fetchingFinished}
                                        className="refresh-finished-btn"
                                    >
                                        <RefreshIcon />
                                    </IconButton>
                                </Tooltip>
                                <Popover
                                    className="popover-filter"
                                    open={this.state.showPopover}
                                    anchorEl={this.state.anchorEl}
                                    onClose={() => this.setState({
                                        showPopover: false,
                                        anchorEl: null
                                    })}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                >
                                    <FormGroup className="filter-switch">
                                        <Box className="title-filters">Visualização dos contatos</Box>
                                        <Box className="switchs-filters">
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={this.state.before24Hours}
                                                        onClick={() => this.setWindows('before', !this.state.before24Hours)}
                                                    />
                                                }
                                                label="Dentro da janela de 24 horas"
                                                className="switch-item"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={this.state.after24Hours}
                                                        onClick={() => this.setWindows('after', !this.state.after24Hours)}
                                                    />
                                                }
                                                label="Fora da janela de 24 horas"
                                                className="switch-item"
                                            />
                                        </Box>
                                    </FormGroup>
                                    <FormGroup className="filters-body" key={this.state.renderKey}>
                                        <Box className="title-filters">Intervalo de datas</Box>
                                        <Tooltip title="Data inicial do filtro" placement="right" disableInteractive>
                                            <TextField
                                                className="date-start-time"
                                                size="small"
                                                label="Início"
                                                type="datetime-local"
                                                variant="outlined"
                                                defaultValue={this.state.initialDate}
                                                disabled={this.disableButton()}
                                                onChange={this.onChangeDateStart}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Tooltip>
                                        <Tooltip title="Data final do filtro" placement="right" disableInteractive>
                                            <TextField
                                                className="date-end-time"
                                                size="small"
                                                label="Fim"
                                                type="datetime-local"
                                                variant="outlined"
                                                defaultValue={this.state.finalDate}
                                                disabled={this.disableButton()}
                                                onChange={this.onChangeDateEnd}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Tooltip>
                                    </FormGroup>
                                    <Box className="filters-buttons">
                                        <Box className="title-filters">Filtros rápidos</Box>
                                        <ButtonGroup
                                            className="buttons-group"
                                            variant="outlined"
                                            aria-label="outlined button group"
                                            size="medium"
                                            disabled={this.disableButton()}
                                        >
                                            <Tooltip title="Definir intervalo de datas em 24 horas" placement="top" disableInteractive>
                                                <Button onClick={() => this.setDateTime('day', 1)}>
                                                    24 horas
                                                </Button>
                                            </Tooltip>
                                            <Tooltip title="Definir intervalo de datas em 1 semana" placement="top" disableInteractive>
                                                <Button onClick={() => this.setDateTime('day', 7)}>
                                                    1 semana
                                                </Button>
                                            </Tooltip>
                                            <Tooltip title="Definir intervalo de datas em 1 mês" placement="top" disableInteractive>
                                                <Button onClick={() => this.setDateTime('months', 1)}>
                                                    1 mês
                                                </Button>
                                            </Tooltip>
                                        </ButtonGroup>
                                    </Box>
                                    <Box className="filters-action">
                                        <Button className="button-discard"
                                            onClick={this.deleteFilter}
                                        >
                                            Descartar
                                        </Button>
                                        <Button variant="contained"
                                            disabled={this.disableButton()}
                                            onClick={() => this.applyDateFilter()}
                                        >
                                            Aplicar
                                        </Button>
                                    </Box>
                                </Popover>
                            </Paper>
                            {this.state.isFiltering &&
                                <Chip
                                    className="is-filtering"
                                    label="Filtro ativo"
                                    onDelete={this.deleteFilter}
                                />
                            }
                        </Box>
                        <Grid Container container>
                            <Grid item lg={3} md={3} sm={3} xs={3}>
                                <Box className="call-columns queue-chats" >
                                    <Box className="column-header" >
                                        <Box className="column-title">
                                            Fila {this.chatsLength('queueChatsFilter')}
                                        </Box>
                                    </Box>
                                    <Box className="calls-list">
                                        <List style={styles.row}>
                                            {this.state.queueChatsFilter.map((chat, i) =>
                                                (<Box key={i} bgcolor=''>
                                                    <ConversationItem
                                                        onClick={() => this.props.openChat(chat)}
                                                        showProgressTime={false}
                                                        conversation={chat}
                                                        isManageCalls={true}
                                                        setReadMessages={this.props.setReadMessages}
                                                        labels={this.props.labels}
                                                    />
                                                    <Divider/>
                                                </Box>)
                                            )}
                                            {!this.state.fetched &&
                                                <Box id="conversationList-box-6">
                                                    <CircularProgress />
                                                </Box>
                                            }
                                        </List>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item lg={3} md={3} sm={3} xs={3}>
                                <Box className="call-columns ongoing-chats">
                                    <Box className="column-header">
                                        <Box className="column-title">
                                            Atendimento {this.chatsLength('ongoingChatsFilter')}
                                        </Box>
                                    </Box>
                                    <Box className="calls-list">
                                        <List style={styles.row}>
                                            {this.state.ongoingChatsFilter.map((chat, i) =>
                                                (<Box key={i} bgcolor=''>
                                                    <ConversationItem
                                                        onClick={() => this.props.openChat(chat)}
                                                        showProgressTime={false}
                                                        conversation={chat}
                                                        isManageCalls={true}
                                                        setReadMessages={this.props.setReadMessages}
                                                        labels={this.props.labels}
                                                    />
                                                    <Divider/>
                                                </Box>)
                                            )}
                                            {!this.state.fetched &&
                                                <Box id="conversationList-box-6">
                                                    <CircularProgress />
                                                </Box>
                                            }
                                        </List>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item lg={3} md={3} sm={3} xs={3}>
                                <Box className="call-columns reactivated-chats">
                                    <Box className="column-header">
                                        <Box className="column-title">
                                            Pendente {this.chatsLength('reactivatedChatsFilter')}
                                        </Box>
                                    </Box>
                                    <Box className="calls-list">
                                        <List style={styles.row}>
                                            {this.state.reactivatedChatsFilter.map((chat, i) =>
                                                (<Box key={i} bgcolor=''>
                                                    <ConversationItem
                                                        onClick={() => this.props.openChat(chat)}
                                                        showProgressTime={false}
                                                        conversation={chat}
                                                        isManageCalls={true}
                                                        setReadMessages={this.props.setReadMessages}
                                                        labels={this.props.labels}
                                                    />
                                                    <Divider/>
                                                </Box>)
                                            )}
                                            {!this.state.fetched &&
                                                <Box id="conversationList-box-6">
                                                    <CircularProgress />
                                                </Box>
                                            }
                                        </List>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item lg={3} md={3} sm={3} xs={3}>
                                <Box className="call-columns finished-chats">
                                    <Box className="column-header">
                                        <Box className="column-title">
                                            Finalizado {this.chatsLength('finishedChatsFilter')}
                                        </Box>
                                    </Box>
                                    <Box className="calls-list">
                                        <List style={styles.row}>
                                            {this.state.finishedChatsFilter.map((chat, i) =>
                                                (<Box key={i} bgcolor=''>
                                                    <ConversationItem
                                                        onClick={() => this.props.openChat(chat)}
                                                        showProgressTime={false}
                                                        conversation={chat}
                                                        isManageCalls={true}
                                                        setReadMessages={this.props.setReadMessages}
                                                        labels={this.props.labels}
                                                    />
                                                    <Divider/>
                                                </Box>)
                                            )}
                                            {!this.state.fetched &&
                                                <Box id="conversationList-box-6">
                                                    <CircularProgress />
                                                </Box>
                                            }
                                        </List>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </>
                }
            </>
        );
    }
}

export default ConversationList;
