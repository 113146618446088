import React, { useEffect, useReducer, useState, useContext } from 'react'

import {
    Dialog, DialogActions, DialogContent, DialogTitle, Divider, Tooltip, Button
    , FormControl, InputLabel, Select, MenuItem, Box, TextField
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import defaultReducer from '../../../../../utils/defaultReducer'
import { getSession } from '../../../../../auth'
import api from '../../../../../api'
import LoadingButton from '@mui/lab/LoadingButton';
import style from './styles.module.css'
import { ScreenContext } from '../../..'
import useNotification from '../../../../Notification'
import Loading from '../../../../Loading'
import { Dropdown } from '../../../Templates/ParameterFiller'

const enterpriseId = getSession()?.profile?.enterprise?.id

const WHATSAPP_STRING = '{{link}}'

const getSmsConfs = async (setConfig, setLoading) => {
    setLoading(true)
    const res = await api.get(`/api/enterprises/${enterpriseId}/sms/data/`)
    setLoading(false)
    if (res.status === 200)
        return setConfig(res.data)
    return false
}

const submit = async (state, close, setLoading) => {
    setLoading(true)
    const res = await api.post(`/api/enterprises/${enterpriseId}/sms/notification/`, state)
    setLoading(false)
    if (res.status === 200) {
        useNotification(
            'Sucesso!',
            'Notificação enviada aos clientes selecionados.',
            'success'
        );
        close()
        return
    }
    useNotification(
        'Ops!',
        'Mensagem de SMS não enviada.',
        'danger'
    );
}

export const ShouldSendSms = (state) => {
    const message = []

    if (state.msg.length === 0)
        message.push(<Box>Mensagem não pode estar em branco.</Box>)
    if (state.phone_number === 0)
        message.push(<Box>Telefone não pode estar em branco.</Box>)
    if (state.destination !== 0 && !state.msg.includes(WHATSAPP_STRING))
        message.push(<Box>Com fluxo de retorno, é obrigatório adicionar o link.</Box>)

    if (message.length === 0)
        return ''
    return message
}

const setWhatsappLink = (state, setState, max_length) => {
    if (!state.msg.includes(WHATSAPP_STRING)) {
        const msg = state.msg + ` ${WHATSAPP_STRING}`
        setState({ msg: msg.substring(0, max_length) })
    }
}

export const getSmsDefaultState = {
        clients: [],
        phone_number: 0,
        msg: '',
        queue: 0,
        destination: 0,
        builder: false,
        phone_name: '',
        agent: getSession()?.profile?.agent?.user
    }



export const SendSMSContent = ({queues, parent, setParent, setLoading=(()=>{}) }) => {

    const [config, setConfig] = useState()

    useEffect(() => {
        getSmsConfs(setConfig, setLoading)
    }, [])

    const maxLength = parent.destination === 0 ? 160 : (config?.max_length + WHATSAPP_STRING.length)

    return (
        <>
            <Box>
                <FormControl
                    fullWidth
                    size="small"
                >
                    <InputLabel id="telphone-simple-select-label">Telefone</InputLabel>
                    <Select
                        labelId="telphone-simple-select-label"
                        id="telphone-simple-select"
                        label="Telefone"
                        onChange={(event) => setParent({ phone_number: event.target.value, 
                            phone_name:config.phone_numbers.find(each => each.id === event.target.value)?.phone_number })}
                        value={parent.phone_number}
                    >
                        <MenuItem key='default-selected-telephone' value={0}>Selecione*</MenuItem>
                        {config?.phone_numbers.map(each => (
                            <MenuItem key={`${each.phone_number}-select-telephone`} value={each.id}>{each.phone_number} - {each.configuration?.data?.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
            <Box className={`emojitextarea-wrapper-box ${style.marginUnset}`} >
                <TextField
                    fullWidth
                    multiline
                    maxRows={4}
                    size='small'
                    label={`Mensagem (máximo de ${maxLength} caracteres)`}
                    value={parent.msg}
                    onChange={(e) => setParent({ msg: e.target.value })}
                    inputProps={{
                        maxLength: maxLength,
                        style: { paddingRight: '1.5rem' }
                    }}
                />
                <Dropdown className={style.test} target={(e) => { setParent({ msg: `${parent.msg} ${e}`.substring(0, maxLength) }) }} />
            </Box>
            <Box className={style.flexbox}>
                <FormControl
                    fullWidth
                    size="small"
                >
                    <InputLabel id="destination-simple-select-label">Fluxo de retorno</InputLabel>
                    <Select
                        labelId="destination-simple-select-label"
                        id="destination-simple-select"
                        label="Fluxo de retorno"
                        onChange={(event) => {
                            const change = { destination: event.target.value, builder: false }
                            if (parent.msg.includes(WHATSAPP_STRING) && event.target.value === 0) {
                                change['msg'] = parent.msg.replace(WHATSAPP_STRING, '')
                            }
                            if (config.builder.map(each => each.id).includes(event.target.value))
                                change['builder'] = true
                            setParent(change)
                        }}
                        value={parent.destination}
                    >
                        <MenuItem key='default-selected-mcdu' value={0}>Nenhum</MenuItem>
                        {config?.mcdus.filter(each => each.entrypoint === 'WHATSAPP').map((each) => (
                            <MenuItem key={`${each.id}-select-mcdu`} value={each.mcdu}>{each.mcdu} - Whatsapp</MenuItem>
                        ))}
                        {config?.builder.map((each) => (
                            <MenuItem key={`${each.id}-select-builder`} value={each.id}>{each.name} - Builder</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {parent.destination ?
                    <FormControl
                        fullWidth
                        size="small"
                    >
                        <InputLabel id="demo-simple-select-label">Fila</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Fila"
                            onChange={(event) => setParent({ queue: event.target.value })}
                            value={parent.queue}
                        >
                            <MenuItem key='default-selected-queue' value={0}>Nenhuma</MenuItem>
                            {Object.entries(queues).map(([key, value]) => (
                                <MenuItem key={`${value}-select-queue`} value={value}>{key}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    : null}
            </Box>
            {
                parent.destination !== 0 && !parent.msg.includes(WHATSAPP_STRING) && <Button
                    variant='contained'
                    onClick={() => { setWhatsappLink(parent, setParent, maxLength) }}>
                    Adicionar link ao texto
                </Button>
            }
        </>
    )
}

const SendSMS = ({ handleClose, queues, selectedNumberModel }) => {

    const [state, setState] = useReducer(defaultReducer, {
        ...getSmsDefaultState,
        clients: selectedNumberModel,
    })

    const [loading, setLoading] = useState(false)
    const shouldSendSms = ShouldSendSms(state)

    return (
        <>
            <Loading loading={loading} />
            <Dialog
                open={true}
                disablePortal
                disableEnforceFocus
                onClose={() => { }}
                sx={{ position: 'absolute' }}
                className='PreviewTemplateDialog'
            >
                <DialogTitle>
                    <span>Enviar SMS aos clientes selecionados</span>
                    <Tooltip title="Fechar" >
                        <Button className="action-button"  >
                            <CloseIcon onClick={handleClose} />
                        </Button>
                    </Tooltip>
                </DialogTitle>
                <Divider />
                <DialogContent className={style.defaultSpacing}>
                    <SendSMSContent parent={state} setParent={setState} queues={queues} setLoading={setLoading} />
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        onClick={handleClose}
                        color="primary"
                    >
                        Cancelar
                    </LoadingButton>
                    <Tooltip title={shouldSendSms} placement="top" describeChild={true}>
                        <span><LoadingButton
                            onClick={() => submit(state, handleClose, setLoading)}
                            color="primary"
                            disabled={shouldSendSms}
                        >
                            Enviar
                        </LoadingButton></span>
                    </Tooltip>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default SendSMS