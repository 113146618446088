import React, { useState, useMemo } from 'react'

import { Box, Button, List, ListItem, ListItemButton, Dialog, Divider, IconButton, Alert, Tooltip } from '@mui/material'

import LoadingButton from '@mui/lab/LoadingButton';
import Loading from '../components/Loading'

import AddIcon from '@mui/icons-material/Add';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { TryRounded } from "@mui/icons-material";
import CloseChatIcon from '@mui/icons-material/PowerSettingsNew';
import CloseIcon from '@mui/icons-material/Close';

import { FacebookPlugin } from "../components/Settings/Instagram";
import { clearSession, saveSession, clearToken, saveToken } from "../auth";
import MemoMessage from "../components/Chat/MessagesArea/ChatWindow/MessageArea/MemoMessage";
import NewMessageArea from "../components/Chat/MessagesArea/ChatWindow/NewMessageArea";
import Footer from "../components/Chat/MessagesArea/ChatWindow/Footer";
import AvatarName from "../components/AvatarName";
import Typography from "../components/Typography";
import api from "../api";
import styles from './style.module.css'

const WBTrial = () => {

    let messageArea = null

    const [state, setState] = useState(1)
    const [loading, setLoading] = useState(false)
    const [onboard, setOnboard] = useState(false)
    const [ontrial, setOntrial] = useState(false)
    const [chats, setChats] = useState([])
    const [activeChat, setActiveChat] = useState()
    const [agentId, setAgentId] = useState()
    const [connectedPage, setConnectedPage]= useState(null)

    const reloadState = () => {setTimeout(()=>setState(p=>p+1), 500)}

    const facebookPlugin = useMemo(() => {
        return (<FacebookPlugin english={true} reloadState={reloadState} reload={state} setConnectedPage={setConnectedPage} app="WHATSAPP"/>)
    }, [state])

    const launchWhatsAppSignup = () => {
        // Launch Facebook login
        FB.login(function (response) {
            if (response.authResponse) {
                const code = response.authResponse.code;
                if (code) {
                    const payload = {
                        'user_token' : connectedPage['authResponse']['accessToken'],
                        'code'       : code
                    }
                    setLoading(true)
                    api.post(`api/enterprises/1/whatsapp/wbtrial/`, payload, { timeout: 0 }).then((res) => {
                        setLoading(false)
                        setOnboard(true)
                    })
                }
            } else {
                console.log('User cancelled login or did not fully authorize.');
            }
        }, {
            config_id: process.env.REACT_APP_WHATSAPP_APP_CONFIG_ID ?? '788098360113528',
            response_type: 'code',
            override_default_response_type: true,
            extras: {
                setup: {
                    "feature": "whatsapp_embedded_signup",
                    "sessionInfoVersion": 2
                }
            }
        });
    }

    const agentLogin = () => {
        fetch('/api/auth/token/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                username: 'instant11@instant.com.br',
                password: 'P@ss-W0rd'
            })
        }).then((res) => {
            if (res.status == 200)
                return res.json(); 
            throw res;
        }).then(async (data) => {
            let agentId = data.user.profile.agent.id;
            setAgentId(agentId)
            saveToken(data.access);
            await api.post(`/api/IV/agents/${agentId}/login/`).then((res) => {
                data.user.profile.agent = res.data;
                saveSession(data.user);
            });
        })
    }

    const getAllChats = () => {
        const getChats = async () => {
            const queue  = await api.get(`api/enterprises/1/calls/chat/queue/`)
            const onchat = await api.get(`api/enterprises/1/calls/chat/onchat/`)
            setChats([
                ...queue.data, 
                ...onchat.data
            ])
        }
        getChats()
        setInterval(getChats, 5000)
    }

    const closeWebSocket = () => {
        if (activeChat)
            activeChat.socket.close()
    }

    const connectWebSocket = (chat) => {
        closeWebSocket()

        const socket = new WebSocket(`wss://${window.location.hostname}/chat/ws/chat/${chat.channel}/`);
        chat.socket = socket
        
        socket.onmessage = (e) => {
            const data = JSON.parse(e.data);
            const curDate = new Date();
            const [hour, minutes] = curDate.toTimeString().split(':', 2)
            const curTime = Math.floor(Date.now() / 1000);
            let message = {
                message  : data.message,
                iso_time : hour + ':' + minutes,
                user_id  : parseInt(data.user),
                time     : curTime,
            };
            if (message.user_id > 0)
                return
            setActiveChat()
            setActiveChat(chat)
        }
    }

    const sendMessage = (message) => {
        if (message !== '') {
            api.post('api/LHC/messages/', {
                message        : message,
                chat_hash      : activeChat.channel,
                queue_id       : activeChat.queue.id,
                is_read        : false,
                show_to_client : true
            }).then(async (res) => {
                const chat = activeChat
                const curDate = new Date();
                const [hour, minutes] = curDate.toTimeString().split(':', 2)
                const curTime = Math.floor(Date.now() / 1000);
                const msgObj = {
                    "message"  : message,
                    "iso_time" : `${hour}:${minutes}`,
                    "time"     : curTime,
                    "user_id"  : 1
                }
                if (messageArea)
                    messageArea.handleAddNewMessage(msgObj)
                else {
                    const chat = activeChat
                    setActiveChat()
                    setActiveChat(chat)
                }
            })
        }
    }

    const hangupChat = () => {
        api.post(`api/IV/agents/${agentId}/hangup/`, {
            channel  : activeChat.channel,
            callerid : activeChat.callerid
        }).then((res) => {
            closeWebSocket()
            setActiveChat()
        })
    }
    
    return (
        <>
            <Loading loading={loading}/>
            {activeChat &&
                <Dialog
                    open={TryRounded}
                    keepMounted
                    onClose={() => { closeWebSocket(); setActiveChat(); }}
                    aria-describedby="alert-dialog-slide-description"
                    fullWidth
                    sx={{ position: 'absolute' }}
                    disablePortal
                    disableEnforceFocus
                    onFocus={(e) => { e.preventDefault() }}
                    maxWidth="lg"
                >
                    <Box className='grid-scroller'>
                        <Box className='grid-insider' style={{ flexDirection: 'column' }}>
                            <Box className="chatWindow-box-2">
                                <Box className="chatWindow-box-3">
                                    <Box onClick={() => { }}>
                                        <Tooltip title="Abrir informações do contato" placement="bottom" disableInteractive>
                                            <Box className="chatWindow-box-4">
                                                <WhatsAppIcon style={{ marginRight: '1rem' }}/>
                                                <Typography
                                                    text={activeChat.lhc.nick}
                                                    color="dark-gray"
                                                    noWrap={true}
                                                    fontWeight="400"
                                                />
                                            </Box>
                                        </Tooltip>
                                    </Box>
                                </Box>
                                <Box>
                                    <Tooltip title="Encerrar" interactive>
                                        <IconButton
                                            onClick={() => hangupChat()}
                                            size="large"
                                            className="push-right">
                                            <CloseChatIcon color="error" />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Fechar" interactive>
                                        <IconButton
                                            onClick={() => { closeWebSocket(); setActiveChat(); }}
                                            size="large"
                                            className="push-right">
                                            <CloseIcon color="primary" />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            </Box>
                            <Divider />
                            <NewMessageArea
                                innerRef={
                                    instance => {
                                        messageArea = instance;
                                    }
                                }
                                chat={activeChat.lhc}
                                accountcode={'isacr00001'}
                                enterpriseId={1}
                                isManageCalls={true}
                            />
                            <Footer
                                sendMessage={sendMessage}
                                showPredefinedMessages={() => {}}
                                activeChat={activeChat}
                                isActiveChatOnQueue={true}
                                connectActiveChat={() => {}}
                                addMessage={() => {}}
                                connections={[]}
                                scrollDown={() => {}}
                                hideAttachments={true}
                                hidePredefinedMessages={true}
                                hideAudio={true}
                            />
                        </Box>
                    </Box>
                </Dialog>
            }
            <Box className={styles.background}>
                <Box className={styles.mainframe}>
                    <Box className={styles.fulltext}>
                        {! ontrial &&
                            <List>
                                <ListItem><b>Hello! Welcome to our automated WhatsApp experience.</b></ListItem>
                                <ListItem sx={{ marginBottom: '1rem' }}><b>Follow this tutorial and you will be able to test our features successfully.</b></ListItem>
                                <ListItem>Step 1: Login to Facebook and connect to KwikBSP</ListItem>
                                <ListItem sx={{ paddingLeft: '1rem' }}>{facebookPlugin}</ListItem>
                                <ListItem>Step 2: Onboard your WhatsApp Business phone number</ListItem>
                                <ListItem>
                                    {onboard &&
                                        <Box style={{ paddingLeft: '1rem' }}>You are onboard!</Box>
                                    }
                                    {! onboard &&
                                        <LoadingButton variant="contained" onClick={() => launchWhatsAppSignup()}>
                                            Onboarding
                                        </LoadingButton>
                                    }
                                </ListItem>
                                <ListItem>Step 3: Send a message to your number, then click the button below</ListItem>
                                <ListItem>
                                    <LoadingButton variant="contained" color="success" onClick={() => {
                                        setOntrial(true)
                                        agentLogin()
                                        setTimeout(() => {
                                            getAllChats()
                                        }, 1000);
                                    }}>
                                        Start Trial
                                    </LoadingButton>
                                </ListItem>
                            </List>
                        }
                        {ontrial &&
                            <>
                                <Box>Chats will appear in the list below, click to view and send messages:</Box>
                                <List sx={{ minHeight: '80%' }}>
                                    {chats.map((chat) => (
                                        <ListItem className={styles.chatitem} onClick={() => {
                                            setActiveChat(chat)
                                            connectWebSocket(chat)
                                        }}>
                                            <WhatsAppIcon style={{ marginRight: '1rem' }}/>
                                            {chat.lhc.callerid}
                                        </ListItem>
                                    ))}
                                </List>
                                <Box>This is a simple trial for our WhatsApp onboarding and messaging features.</Box>
                            </>
                        }
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default WBTrial
