import React, { useEffect, useState } from 'react'

import { Box } from '@mui/material'
import './styles.css'
import GraphToolbar from '../default-toolbar'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import { graph_theme, defaultNames, defaultClassification } from '../default-toolbar'

import SankeyChart from "../../../../D3/SankeyChart";

const columns = graph => {
  const columns = []
  if (graph === 'agent')
    columns.push({ field: 'agent_name', headerName: 'Operador', flex: 1, renderCell: defaultNames })
  else columns.push({ field: 'queue_description', headerName: 'Fila', flex: 1,  renderCell: defaultNames })
  columns.push({
    field: 'classification',
    headerName: 'Classificação',
    flex: .5
  })
  columns.push({
    field: 'description',
    headerName: 'Descrição',
    flex: 1,
    valueGetter: defaultClassification
  })
  columns.push({
    field: 'count',
    headerName: 'Quantidade',
    flex: .5
  })
  return columns
}

const AnsweredLost = ({ data, option, periodText }) => {

  const [parsedData, setParsedData] = useState()

  const handleFilterModelChange = (filterModel) => {
    const filteredData = data.rows.filter((row) => {
      return filterModel.items.every(({ columnField, value }) => {
        const cellValue = row[columnField];
        return String(cellValue).toLowerCase().includes(String(value).toLowerCase());
      });
    });
    setParsedData({ rows: filteredData })
  }

  return (
    <Box className='graph-main-divisor'>
      <Box className='graph-main-table'>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={graph_theme}>
            <DataGridPro
              density='compact'
              columns={columns(option)}
              rows={data?.rows.map((r, index) => ({ ...r, id: index + 1 }))}
              hideFooter={true}
              components={{
                Toolbar: GraphToolbar
              }}
              onFilterModelChange={handleFilterModelChange}
            />
          </ThemeProvider>
        </StyledEngineProvider>
      </Box>
      <Box className='graph-main-dashboards'>
        <Box className='graph-time-rows'>
          <SankeyChart data={(parsedData) ? parsedData : data} periodText={`${(option === 'agent') ? 'operadores' : 'filas'}, ${periodText}`} />
        </Box>
      </Box>
    </Box>
  )
}

export default AnsweredLost
