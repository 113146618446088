import React from 'react'
import SettingsIntermediate from '../SettingsIntermediate'
import userPermissions from '../../User/Permissions';
import { getSession } from '../../../auth';
import Operators from '../../Settings/Users/Operators';
import UsersComponent from '../../Settings/Users'
import Labels from '../../Settings/Labels';
import General from '../../Settings/General';
import Messages from '../../Settings/Messages';
import PredefinedMessages from '../../Settings/PredefinedMessages';
import EndChat from '../../Settings/NewMenus/OnChatMenu/end-chat';
import EndActions from '../../Settings/NewMenus/OnChatMenu/end-actions';
import Redirect from '../../Settings/Redirect';
import Escalation from '../../Settings/Escalation';

const OnChatPage = () => {
    const groups = userPermissions();
    const enterprise = getSession()?.profile?.enterprise
    return (
        <>
            <SettingsIntermediate title='Atendimento' components={() => ({
                comp1:{
                    permissions: true,
                    style:'title',
                    label:'Atendimento'
                },
                comp2:{
                    permissions: groups.isAdmin,
                    style:'list',
                    label:'Geral',
                    sublabel: 'Configurações gerais do atendimento.',
                    redirect: (<General enterpriseId={enterprise?.id}/>)
                },
                comp3:{
                    permissions: groups.isAdmin,
                    style:'list',
                    label:'Etiquetas',
                    sublabel: 'Configurar etiquetas de atendimento.',
                    redirect: (<Labels enterpriseId={enterprise?.id}/>)
                },
                comp4:{
                    permissions: groups.isAdmin,
                    style:'list',
                    label:"Mensagens de encerramento e 'timeout'",
                    sublabel: 'Configurar mensagem de encerramento e tempo limite de duração das conversas.',
                    redirect: (<Messages enterpriseId={enterprise?.id}/>)
                },
                comp5:{
                    permissions: groups.isAdmin,
                    style:'list',
                    label:'Mensagens Predefinidas',
                    sublabel: 'Configurar mensagens predefinidas para todos os atendimentos.',
                    redirect: (<PredefinedMessages enterpriseId={enterprise?.id}/>)
                },
                comp6:{
                    permissions: groups.isAdmin && enterprise.settings.can_configure_hangup_on_settings,
                    style:'list',
                    label:'Fim do Atendimento',
                    sublabel: 'Configurar ações para o fim do atendimento.',
                    redirect: (<EndChat/>)
                },
                comp7:{
                    permissions: groups.isAdmin && enterprise.settings.can_configure_end_actions,
                    style:'list',
                    label:'Final do Expediente',
                    sublabel: 'Configurar ações para o final do expediente.',
                    redirect: (<EndActions/>)
                },
                comp8:{
                    permissions: groups.isAdmin,
                    style:'list',
                    label:'Redirecionamentos',
                    sublabel: 'Redirecionar mensagens para pilotos diferentes.',
                    redirect: (<Redirect />)
                },
                comp9:{
                    permissions: groups.isAdmin,
                    style:'list',
                    label:'Jornadas de Atendimento',
                    sublabel: 'Configurar jornadas de atendimento para filas.',
                    redirect: (<Escalation />)
                }
            })
            } />
        </>
    )
}


export default OnChatPage