import React, { useEffect, useReducer } from 'react'
import { Dialog, Box, DialogTitle, DialogContent, Divider, Button, Tooltip } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import {
    DataGridPro,
    ptBR,
} from '@mui/x-data-grid-pro';
import { ptBR as corePtBR } from '@mui/material/locale';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import defaultReducer from '../../utils/defaultReducer';
import dayjs from 'dayjs';
import api from '../../api';
import HistoryIcon from '@mui/icons-material/History';
import AutomationEventDialog from './eventdialog';
import { getSession } from '../../auth';


const theme = createTheme({
    palette: {
        primary: { main: '#21446C' },
    },
}, ptBR, corePtBR);

const dummyData = [

]

const buildHistoricColumns = (state, setState) => {
    return [
        { field: 'uniqueid', headerName: 'Chamada', flex: 1 },
        { field: 'state', headerName: 'Status', flex: 1 },
        { field: 'reasons', headerName: 'Comentários', flex: 1 },
        { field: 'datetime', headerName: 'Hora de execução', flex: 1,
        valueGetter: ({value}) => dayjs(value).toDate().toLocaleString() },
        {
            field: 'actions', headerName: 'Eventos', flex: .5,
            renderCell: (params) => (
                <Box>
                    <Tooltip title='Visualizar Eventos'>
                        <Button className="action-button"
                            onClick={() => setState({ eventLog: params.row })}>
                            <HistoryIcon />
                        </Button>
                    </Tooltip>
                </Box>
            )
        },
    ]
}

const loadRows = async (id, setState, setParent, limit, offset) => {
    const enterpriseId = getSession().profile.enterprise.id
    setParent({ loading: true })
    const res = await api.get(`api/enterprises/${enterpriseId}/automation/${id}/historic/?limit=${limit}&offset=${offset}`)
    if (res.status === 200)
        setState({ 
            rows: res.data.results.map((row) => { 
                return {
                    id: row.id,
                    uniqueid: row.uniqueid,
                    state: row.status == 'Atendida' ? 'sucesso' : 'falha',
                    datetime: `${row.date} ${row.time}`,
                    reasons: row.reasons,
                }
            }),
            rowCount: res.data.count,
        })
    setParent({ loading: false })
}


const AutomationHistoric = ({ data, parent, setParent, reloadData }) => {

    const [state, setState] = useReducer(defaultReducer, {
        rows: [],
        eventLog: null,
        page: 0,
        pageSize: 25,
        offset: 0,
        rowCount: 0,
    })

    useEffect(() => {
        if (data.id){
            loadRows(data.id, setState, setParent)
        }
            
    }, [data.id])

    const handlePageSizeChange = (newSize) => {
        setState({ pageSize: newSize })
        loadRows(data.id, setState, setParent, newSize, state.offset)
    }

    const handlePageChange = (newPage) => {
        const newOffset = state.pageSize * newPage
        setState({ offset: newOffset, page: newPage })
        loadRows(data.id, setState, setParent, state.pageSize, newOffset )
    }

    return (
        <>
            <>
                <Dialog
                    open='true'
                    disablePortal
                    fullWidth
                    maxWidth='md'
                    disableEnforceFocus
                    sx={{ position: 'absolute' }}
                >
                    <DialogTitle className={'group-flexbox'}>
                        <span>Histórico da automação: {data.name}</span> <Button sx={{ 'minWidth': '2rem', 'padding': '0' }} onClick={() => { setParent({ showHistoric: null }) }} ><CloseIcon /></Button>
                    </DialogTitle>
                    <Divider />
                    <DialogContent className='content-dialog-rows'>

                        <Box sx={{ width: '100%', height: '520px' }}>
                            <ThemeProvider theme={theme}>
                                <DataGridPro
                                    columns={buildHistoricColumns(state, setState)}
                                    rows={state.rows}
                                    disableSelectionOnClick
                                    density={'compact'}

                                    //pagination
                                    pagination
                                    paginationMode="server"
                                    rowsPerPageOptions={[25, 50, 100]}
                                    page={state.page}
                                    onPageSizeChange={handlePageSizeChange}
                                    onPageChange={handlePageChange}
                                    rowCount={state.rowCount}
                                    keepNonExistentRowsSelected
                                    initialState={{
                                        pagination: {
                                            pageSize: Number(state.pageSize)
                                        }
                                    }}
                                />
                            </ThemeProvider>
                        </Box>

                    </DialogContent>
                </Dialog >
                {state.eventLog && <AutomationEventDialog data={state.eventLog} setParent={setState}/>}
            </>
        </>
    )
}

export default AutomationHistoric