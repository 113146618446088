import React from "react";
import { Box } from "@mui/material";
import "./styles.css";

class AvatarName extends React.Component {

  constructor(props) {

      super(props);
  }

  stringToColor = (string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  render() {
    const nameParts = this.props.name.split(' ');
    const firstName = nameParts[0];
    const lastName  = (nameParts.length == 2) ? nameParts[1] : null;
    const initials  = (lastName) ? firstName[0] + lastName[0] : firstName[0];

    return (
      <Box 
        className={`avatarName avatarName-${this.props.size}`}
        bgcolor={this.stringToColor(this.props.name)} 
        onClick={this.props.handleClick ? this.props.handleClick : null}
      >
        {initials}
      </Box>
    );
  }
};

export default AvatarName;
