import api from "../../api"

export function sendSnippet(snippet, accountCode) {
    api.get('/api/ivrsnippets/' + snippet.id + '/download/').then(resp => {
        console.log(resp);
        console.log(resp.data);
        var file = new File([resp.data], "document.ivr");
        let formData = new FormData();
        formData.append('accountcode', accountCode);
        formData.append('file', file);
        api.post('/api/IV/ivrs/chat/', formData,
        ).then(resp => {
            console.log(resp.data);
        }).catch(error => {
            console.log(error);
        });
    }).catch(error => {
        console.log(error);
    });

}