import React from "react";

import { IconButton, Button } from '@mui/material';

import useMediaQuery from '@mui/material/useMediaQuery';

import './styles.css'

export default function ResponsiveButton({ className, onClickFn, id, variant, title, setIcon, selected }) {
    const isSmallScreen = useMediaQuery('(max-width: 60rem)');

    return (
        <>
            {isSmallScreen ? (
                <IconButton
                    className={`${className} btnStart ${!selected ? 'btnInactive' : ''}`}
                    onClick={onClickFn}
                    id={id}
                    key={id}
                    size="small"
                    variant={variant}
                >
                    {setIcon}

                </IconButton>
            ) : (
                <Button className={`${className} btnStart ${!selected ? 'btnInactive' : ''}`}
                    onClick={onClickFn}
                    id={id}
                    key={id}
                    variant={variant}
                    endIcon={setIcon}
                >
                    {title}
                </Button>
            )}
        </>
    )
}