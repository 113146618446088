import React, { useState, useEffect, useRef } from 'react'

import {
    Button, Dialog, DialogActions, DialogContent, DialogTitle, Box, FormControl,
    InputLabel, Select, MenuItem, Grid, Tooltip, Paper, IconButton, InputBase,
    Divider, List
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import EmojiTextarea from '../../../Input/EmojiTextarea';
import useNotification from '../../../Notification';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ListItemButton from '@mui/material/ListItemButton';
import { styled } from '@mui/material/styles';
import { tooltipClasses } from '@mui/material/Tooltip';

const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} placement="top" />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 200,
    },
});

export const PredefinedDropdown = ({ target, index = '' }) => {

    const dropref = useRef()
    const buttonref = useRef()
    const [showOptions, setShowOptions] = useState(false)

    const title_message = `
    selecione opcionalmente uma das variáveis abaixo.
    `

    const setTarget = (variable, index = ' ') => {
        target(variable, index)
    }

    function getAbsPosition(element) {
        var rect = element.getBoundingClientRect();
        return { x: rect.left, y: rect.top }
    }

    const moveDiv = () => {


        const selectedDiv = dropref?.current
        const selectedButton = buttonref?.current

        const coords = getAbsPosition(selectedButton);

        const posX = coords.x
        const posY = coords.y

        selectedDiv.style.left = `${posX}px`
        selectedDiv.style.top = `${posY}px`

        setShowOptions(true)
    }


    return (
        <>
            <CustomWidthTooltip title={title_message}>
                <IconButton ref={buttonref} className='absolute-button' onClick={e => {e.preventDefault(); moveDiv()}}>
                    <KeyboardArrowDownIcon />
                </IconButton>
            </CustomWidthTooltip>

            <Box className={`select-pop-up-backdrop ${showOptions ? 'active': ''}`} onClick={() => setShowOptions(false)}></Box>

            <Box ref={dropref} className={`select-pop-up ${showOptions ? 'active': ''}`}>
                <List className='select-list insiderlist'>
                    <ListItemButton
                        className='select-list-item'
                        onClick={() => { setTarget('{{nome_cliente}}', index);setShowOptions(false)  }}
                    >
                        Nome do cliente
                    </ListItemButton>
                    <ListItemButton
                        className='select-list-item'
                        onClick={() => { setTarget('{{nome_operador}}', index); setShowOptions(false) }}
                    >
                        Nome do operador
                    </ListItemButton>
                    <ListItemButton
                        className='select-list-item'
                        onClick={() => { setTarget('{{codigo_cliente}}', index); setShowOptions(false) }}
                    >
                        Código do cliente
                    </ListItemButton>
                </List>
            </Box>


        </>
    )
}

const PredefinedMessagesModal = ({ title, messages, addMessage, selected, handleClose }) => {
    const [category, setCategory] = useState()
    const [newCategory, setNewCategory] = useState('')
    const [categorys, setCategorys] = useState([])
    const [showAddCategory, setShowAddCategory] = useState(false)
    const [textEdit, setTextEdit] = useState('')

    useEffect(() => {
        const newcategorys = messages.map(each => each.category)
        setCategorys(newcategorys.filter((value, index, array) => array.indexOf(value) === index))
    }, [messages])

    useEffect(()=>{
        if (selected){
            setCategory(selected.category)
            setTextEdit(selected.message)
        }
        
    }, [selected])

    const addPredefinedMessage = async () => {
        await addMessage(textEdit, category, selected?selected.id:0)
        handleClose()
    }

    const addCategory = () => {
        setShowAddCategory(false)
        if (categorys.includes(newCategory)) {
            useNotification(
                'Erro!',
                'Categoria já existe.',
                'danger'
            );
            return
        }
        useNotification(
            'Sucesso!',
            'Categoria adicionada com sucesso.',
            'success'
        );
        categorys.push(newCategory)

    }


    const onKeyDownPredefinedMessage = (event) => {
        // key shift off and enter on
        if (!event.shiftKey && (event.keyCode === 13 || event.key === 'Enter')) {
            this.addPredefinedMessage();
            event.preventDefault();
        }
    }

    return (
        <>
            <Dialog keepMounted
                open={true}
                onClose={() => { }}
                aria-labelledby="customized-dialog-title"
                disablePortal
                disableEnforceFocus
                sx={{position:'absolute'}}
            >
                <DialogTitle id="customized-dialog-title"
                    className={'group-flexbox'}
                    style={{
                        minWidth: "500px"
                    }}
                >
                    <span>{title}</span> <Button sx={{ 'minWidth': '2rem', 'padding': '0' }} onClick={handleClose} ><CloseIcon /></Button>
                </DialogTitle>
                <Divider />
                <DialogContent
                    className="dialog-content">
                    <Grid container>
                        {!showAddCategory ?
                            <>
                                <Grid item lg={11} md={11} sm={11} xs={11}>
                                    <FormControl
                                        fullWidth
                                        size="small"
                                    >
                                        <InputLabel id="demo-simple-select-label">Categoria</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="Categoria"
                                            value={category}
                                            onChange={(e) => setCategory(e.target.value)}
                                        >
                                            {categorys.map(category =>
                                                <MenuItem value={category}>{category}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item lg={1} md={1} sm={1} xs={1}>
                                    <Tooltip title="Nova categoria" placement="bottom" disableInteractive>
                                        <IconButton
                                            onClick={() => setShowAddCategory(true)}
                                            style={{ marginLeft: "10px" }}
                                            size="medium"
                                        >
                                            <AddIcon className="icon-add" />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </>
                            :
                            <Paper
                                sx={{
                                    p: '2px 4px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: 450,
                                    bgcolor: '#efefef'
                                }}
                            >
                                <InputBase
                                    sx={{ ml: 1, flex: 1 }}
                                    placeholder="Nova categoria"
                                    onChange={(e) => setNewCategory(e.target.value)}
                                />
                                <Tooltip title="Adicionar" placement="bottom" disableInteractive>
                                    <IconButton onClick={addCategory} >
                                        <AddIcon className="icon-add" />
                                    </IconButton>
                                </Tooltip>
                                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                                <Tooltip title="Cancelar" placement="bottom" disableInteractive>
                                    <IconButton onClick={() => setShowAddCategory(false)}>
                                        <CloseIcon className="icon-cancel" />
                                    </IconButton>
                                </Tooltip>
                            </Paper>
                        }
                    </Grid>
                </DialogContent>
                <DialogActions
                    style={{
                        padding: "10px 20px 15px 1.5rem",
                        gap:'1rem'
                    }}
                >
                    <EmojiTextarea
                        text={textEdit}
                        setText={(event) => setTextEdit(event.target.value)}
                        setEmoji={(text) => setTextEdit(text)}
                        onKeyDown={onKeyDownPredefinedMessage}
                        maxLength={1000}
                        label="Mensagem"
                        childComponent="MessageBox"
                        dropdown={(<PredefinedDropdown target={(e)=> setTextEdit(textEdit+e)}/>)}
                    />
                    <Button
                        onClick={addPredefinedMessage}
                        color="primary"
                        disabled={!textEdit || !category}>
                        {selected ? 'Editar' : 'Adicionar'}
                    </Button>
                </DialogActions>

            </Dialog>
        </>
    )
}



export default PredefinedMessagesModal