import React from 'react'

import { Box } from '@mui/material'
import './styles.css'
import GraphToolbar from '../default-toolbar'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import { graph_theme, convert_time, defaultNames } from '../default-toolbar'

const columns = graph => {
  const columns = []
  if (graph === 'agent')
    columns.push({ field: 'agent_name', headerName: 'Operador', flex: 1, renderCell: defaultNames })
  else columns.push({ field: 'queue_description', headerName: 'Fila', flex: 1, renderCell: defaultNames })
  columns.push({ field: 'count', headerName: 'Quantidade', flex: 1 })
  columns.push({
    field: 'TME',
    headerName: 'TME',
    flex: 1,
    renderCell: convert_time
  })
  columns.push({
    field: 'TMA', 
    headerName: 'TMA',
    flex: 1,
    renderCell: convert_time
  })
  columns.push({
    field: 'TMR', 
    headerName: 'TMR',
    flex: 1,
    renderCell: convert_time
  })
  return columns
}

const ChatsAnswered = ({ data, option }) => {
  return (
    <Box className='graph-main-divisor'>
      <Box className='graph-main-table'>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={graph_theme}>
            <DataGridPro
              density='compact'
              columns={columns(option)}
              rows={data?.rows.map((r, index) => ({ ...r, id: index + 1 }))}
              hideFooter={true}
              components={{
                Toolbar: GraphToolbar
              }}
            />
          </ThemeProvider>
        </StyledEngineProvider>
      </Box>
      <Box className='graph-main-dashboards'>
        <Box className='graph-time-rows'>
          <Box className='graph-box'>
            <Box sx={{backgroundColor: '#5399DB', color: 'white'}}>TME total</Box>
            <Box sx={{backgroundColor: 'white'}}>{convert_time(data.TME, 'v2')}</Box>
          </Box>
          <Box className='graph-box'>
            <Box sx={{backgroundColor: '#5399DB', color: 'white'}}>TMA total</Box>
            <Box sx={{backgroundColor: 'white'}}>{convert_time(data.TMA, 'v2')}</Box>
          </Box>
          <Box className='graph-box'>
            <Box sx={{backgroundColor: '#5399DB', color: 'white'}}>TMR total</Box>
            <Box sx={{backgroundColor: 'white'}}>{convert_time(data.TMR, 'v2')}</Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default ChatsAnswered
