import React from "react";

import { Box, Grid } from "@mui/material";

import ChatWindow from "./ChatWindow/index.js";
import ChatIntro from "./ChatIntro/index.js";

import Loading from '../../Loading';

import "./styles.css";

class MessagesArea extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false
        };

        this.children = null
    }

    render() {
        return (
            <Box id="chatWindow-box">
                <Loading loading={this.state.loading} />
                {this.props.activeChat.uniqueid !== undefined &&
                    <ChatWindow
                        ref={
                            instance => {
                                this.children = instance
                            }
                        }
                        activeChat={this.props.activeChat}
                        activeTab={this.props.activeTab}
                        activeSubTab={this.props.activeSubTab}
                        setActiveChat={this.props.setActiveChat}
                        setActiveTab={this.props.setActiveTab}
                        setActiveSubTab={this.props.setActiveSubTab}
                        agentId={this.props.agentId}
                        enterpriseId={this.props.enterpriseId}
                        addOngoingChat={this.props.addOngoingChat}
                        addReactivatedChat={this.props.addReactivatedChat}
                        removeOngoingChat={this.props.removeOngoingChat}
                        predefinedMessages={this.props.predefinedMessages}
                        addPredefinedMessage={this.props.addPredefinedMessage}
                        updatePredefinedMessage={this.props.updatePredefinedMessage}
                        removePredefinedMessage={this.props.removePredefinedMessage}
                        accountcode={this.props.accountcode}
                        showChatRightSideBar={this.props.showChatRightSideBar}
                        connectWebSocket={this.props.connectWebSocket}
                        closeWebSocket={this.props.closeWebSocket}
                        resetActiveChatProgress={this.props.resetActiveChatProgress}
                        enterpriseQueues={this.props.enterpriseQueues}
                        queueId={this.props.activeChat.queues_id}
                        queueName={this.props.activeChat.queue?.description}
                        contactData={this.props.contactData}
                        templates={this.props.templates}
                        key={this.props.renderKey}
                        isManageCalls={this.props.isManageCalls}
                        closeModal={this.props.closeModal}
                        setLoading={(condition) => this.setState({ loading: condition })}
                        updateMessages={this.props.updateMessages}
                        setReadMessages={this.props.setReadMessages}
                        connections={this.props.connections}
                        setChatLabels={this.props.setChatLabels}
                    />
                }
                {this.props.activeChat.uniqueid === undefined &&
                    <ChatIntro />
                }
            </Box >
        );
    }
}

export default MessagesArea;
