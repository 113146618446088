import React, { useState } from "react";

import { Grid, TextField, Typography, Box, Button, Tooltip, IconButton } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { CircularProgress } from '@mui/material';
import Alert from '@mui/material/Alert';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';

import PasswordInput from "../../Input";

import { useHistory } from "react-router-dom";
import { useFormik } from "formik";

import * as Yup from "yup";


const CreatePasswordForm = () => {

    const [showAlert, setShowAlert] = useState([]);
    const [loading, setLoading] = useState(false);

    const [passwordInformation, setPasswordInformation] = useState(false);

    const { values, handleChange, errors, handleBlur, touched, handleSubmit } = useFormik({
        initialValues: {
            password: "",
            password_confirm: ""
        },
        validationSchema: Yup.object().shape({
            password: Yup.string().required("Este campo é obrigatório"),
            password_confirm: Yup.string().required("Este campo é obrigatório")
        }),
        onSubmit: async values => {
            setLoading(true);
            setUserPassword();
        }
    });

    const history = useHistory();

    const useStyles = makeStyles(() => ({
        title: {
            color: "#21446C",
            fontWeight: 500,
            paddingLeft: "10px"
        }
    }));

    const classes = useStyles();

    const setUserPassword = () => {
        if (values.password !== values.password_confirm) {
            setShowAlert(["Algo deu errado:",
                "As senhas são diferentes"])
            setLoading(false);
            return;
        }

        const urlParams = window.location.pathname.split('/');
        const username = urlParams[urlParams.length-3];
        const uid = urlParams[urlParams.length-2];
        const token = urlParams[urlParams.length-1];

        fetch('/api/api/users/password/confirm/', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    uid: uid,
                    token: token,
                    password: values.password
                })
            })
            .then((res) => {
                if (res.status == 200) {
                    history.push('/?username='+username);
                } 
                else if (! res.ok) {
                    if (res.status == 502) {
                        // retry
                        setUserPassword();
                        return;
                    }
                }
                throw res;
            })
            .catch(async (err) => {
                console.error(err);
                await err.json().then(async (data) => {
                    if (data.token) 
                        setShowAlert(['Convite expirado! Entre em contato kwik@instant.com.br e solicite um novo.']);
                    else if (data.password)
                        setShowAlert([
                            'Sua senha deve seguir os seguintes critérios:',
                            '1. Ter no mínimo 8 caracteres',
                            '2. Letras maiúsculas e minúsculas',
                            '3. Letras e números',
                            '4. Caracteres especiais (exemplo: #, @, *)'
                        ]);
                    else if (data.common){
                        setShowAlert(['Algo deu errado:',
                            'A senha escolhida é muito comum.'])
                    }
                    else
                        setShowAlert(['Algo errado, por favor, tente novamente!']);
                }).catch(async (err) => {
                    setShowAlert(['Algo errado, por favor, tente novamente!']);
                });
                setLoading(false);
            });
        ;
    }

    return (
        <Box display="flex"
            height="99vh"
            ml={15} mr={15}
            justifyContent="center"
            alignContent="center"
            alignItems="center"
            width={"40vw"}
        >
            <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Box display="flex">
                        <Typography className={classes.title}
                            variant="h4"
                        >
                            Cadastre sua senha
                        </Typography>
                    </Box>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    {showAlert.length !== 0 &&
                        <Alert severity="error" onClose={() => setShowAlert([])}>
                            {showAlert.map((message) => (
                                <Box>{message}</Box>    
                            ))}
                        </Alert>
                    }
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <PasswordInput
                        label="Senha"
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(errors.password) && touched.password}
                        helperText={touched.password ? errors.password : ""}
                    />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <PasswordInput
                        label="Confirmar senha"
                        name="password_confirm"
                        value={values.password_confirm}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(errors.password_confirm) && touched.password_confirm}
                        helperText={touched.password_confirm ? errors.password_confirm : ""}
                    />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Button disabled={loading}
                            variant="contained"
                            color="primary"
                            type="submit"
                            onClick={() => handleSubmit()}
                            fontSize="small"
                            fullWidth
                    >
                        {loading &&
                            <CircularProgress style={{
                                height: "20px",
                                width: "20px",
                                marginRight: "10px"
                            }}/>
                        }
                        Cadastrar
                    </Button>
                </Grid>
            </Grid >
        </Box >
    );
};

export default CreatePasswordForm;
