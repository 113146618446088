import React from "react";

import { Button, IconButton } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

import MessageIcon from '@mui/icons-material/Message';


export default function ResponsiveNotificationButton({ className, onClickFn, id, variant }) {
  const isSmallScreen = useMediaQuery('(max-width: 60rem)');

  return (
    <>
      {isSmallScreen ? (
        <IconButton
          className={className}
          onClick={onClickFn}
          size="small"
          variant={variant}
        >
          <MessageIcon />
        </IconButton>
      ) : (
        <Button className={className}
          onClick={onClickFn}
          id={id}
          variant={variant}
          endIcon={<MessageIcon />}
        >
          Disparos
        </Button>
      )}
    </>
  )
}