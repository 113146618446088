import React from "react";

import { IconButton } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import TableRowsIcon from '@mui/icons-material/TableRows';

import useMediaQuery from '@mui/material/useMediaQuery';

export default function ResponsiveGenerateButton(
  { className, onClickFn, loading }
) {
  const isSmallScreen = useMediaQuery('(max-width: 60rem)');

  return (
    <>
      {isSmallScreen ? (
        <IconButton
          className={className}
          onClick={onClickFn}
          size="small"
        >
          <TableRowsIcon />
        </IconButton>
      ) : (
        <LoadingButton
          className={className}
          onClick={onClickFn}
          loading={loading}
        >
          Gerar
        </LoadingButton>
      )}
    </>
  )
}