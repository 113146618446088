import React from "react";
import MiniDrawer from "../../components/Drawer"
import Scout from "../../components/Scout"

const ScoutPage = (socket) => {

    return (
        <MiniDrawer child={<Scout />} socket={socket.socket}/>
    );
};

export default ScoutPage;
