import React, { useState, useEffect, useContext, useReducer } from 'react'
import { constructorContext } from '..'
import {
    Box, Dialog, DialogActions, DialogContent, DialogTitle,
    Tooltip, Button, TextField, Divider, Select, MenuItem, InputLabel, FormControl, List, ListItem, IconButton
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import api from '../../../../api';
import useNotification from '../../../Notification';
import EmojiTextarea from '../../../Input/EmojiTextarea';
import InputAdornment from '@mui/material/InputAdornment';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';

import defaultReducer from '../../../../utils/defaultReducer';
import IntegerOnly from '../../../../utils/IntegerOnly';

import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';


const action = (data) => {
    if (data?.id)
        return 'Editar'
    return 'Adicionar'
}

const searchTypes = [
    { id: 0, value: 'NPS' },
    { id: 1, value: 'CSAT' },
    //{ id: 2, value: 'LIVRE' }
]

const buttonTypes = [
    { id: 0, value: 'Texto' },
    { id: 1, value: 'Botão' }
]

const commentTypes = [
    { id: 0, value: 'Não' },
    { id: 1, value: 'Sim' }
]


const QuestionBox = ({ obj, messages, index, state, setState }) => {
    const { activeTab } = useContext(constructorContext)
    const [editable, setEditable] = useState(true)


    useEffect(() => {
        if (obj.id) {
            setEditable(false)
        }
    }, [])

    return (
        <>
            {!editable ?
                <>
                    <Box
                        className='questionsboxok'>
                        <InputAdornment position="start">
                            {obj.q_order}.
                        </InputAdornment>
                        <Box className='internal-question-box'>{activeTab == 0 ? obj.question : obj.messages?.name}</Box>

                        <Box sx={{ display: 'flex' }}>
                            <Tooltip title="Editar Pergunta">
                                <Button className="action-button"
                                    onClick={() => {
                                        setEditable(true)
                                    }}>
                                    <EditIcon />
                                </Button>
                            </Tooltip>
                            <Tooltip title="Excluir Pergunta">
                                <Button className="action-button delete"
                                    onClick={() => {
                                        setState({ questions: [...state.questions.filter(question => question != obj)] })
                                    }}>
                                    <DeleteIcon />
                                </Button>
                            </Tooltip>
                        </Box>
                    </Box>
                </>
                :
                <>
                    {activeTab == 0 ? <TextField
                        size='small'
                        className='questionsboxquestion'
                        value={obj.question}
                        onBlur={
                            () => setEditable(false)
                        }
                        onChange={(e) => {
                            obj.question = e.target.value
                            setState({ questions: [...state.questions] })
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    {obj.q_order}.
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <>
                                    <Tooltip title="Salvar Pergunta">
                                        <Button className="action-button"
                                            onClick={() => {
                                                setEditable(false)
                                            }}>
                                            <DoneIcon />
                                        </Button>
                                    </Tooltip>
                                </>
                            )
                        }}
                    /> :
                        <Select
                            size='small'
                            className='questionsboxquestion'
                            value={obj.messages}
                            onChange={(e) => {
                                obj.messages = e.target.value
                                setState({ questions: [...state.questions] })
                                setEditable(false)
                            }}
                        >
                            {messages.map(message => (
                                <MenuItem value={message}>{message.name}</MenuItem>
                            ))}
                        </Select>
                    }
                </>}
        </>
    )
}


const SearchEditModal = ({ data, setData, getRows, messages }) => {
    const { enterpriseId, activeTab, setLoading } = useContext(constructorContext)

    const [showQuestions, setShowQuestions] = useState(true)

    let initialState = {}
    if (activeTab == 0)
        initialState = {
            name: '',
            type: 1,
            min_rating: 1,
            max_rating: 5,
            questions: [],
            initial_mesg: '',
            final_mesg: '',
            buttons: 0,
            has_comment: 0,
            comment_prompt: 'Deixe seu comentario sobre o atendimento:'
        }
    else
        initialState = {
            name: '',
            type: 1,
            min_rating: 1,
            max_rating: 5,
            questions: [],
            initial_mesg: '',
            final_mesg: '',
            error_mesg: '',
            notyped_mesg: ''
        }
    const [state, setState] = useReducer(defaultReducer, initialState)

    const handleDrop = (droppedItem) => {
        // Ignore drop outside droppable container
        if (!droppedItem.destination) return;
        var updatedList = [...state.questions];
        // Remove dragged item
        const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
        // Add dropped item
        updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
        // Update State
        setState({ questions: updatedList });
    };

    const checkButtonText = () => {
        const text = []

        if (state.name.length == 0)
            text.push(<Box>Nome não pode estar vazio.</Box>)

        if (state.min_rating.length == 0 || state.max_rating.length == 0)
            text.push(<Box>Nota não pode estar vazia.</Box>)

        if (Number(state.min_rating) >= Number(state.max_rating))
            text.push(<Box>Nota mínima não pode ser maior ou igual nota máxima.</Box>)

        if (state.questions.length == 0) {
            text.push(<Box>Adicione uma pergunta.</Box>)
        }

        if (activeTab == 0 && state.questions.length > 0 && state.questions.find(each => !each.question)) {
            text.push(<Box>Preencha todas as perguntas.</Box>)
        }

        if (activeTab == 1 && state.questions.length > 0 && state.questions.find(each => !each.messages)) {
            text.push(<Box>Preencha todas as perguntas.</Box>)
        }

        if (state.initial_mesg.length == 0) {
            text.push(<Box>Adicione a mensagem inicial.</Box>)
        }

        if (state.final_mesg.length == 0) {
            text.push(<Box>Adicione a mensagem final.</Box>)
        }

        if (text.length == 0)
            return ''

        return (text)
    }

    useEffect(async () => {
        if (!data.id)
            return

        let type = 0
        {
            if (data.min_rating == 1 && data.max_rating == 10)
                type = 0
            else if (data.min_rating == 1 && data.max_rating == 5)
                type = 1
            else
                type = 2
        }

        setShowQuestions(false)

        let initial_mesg = data.initial_mesg
        let final_mesg = data.final_mesg
        let error_mesg = ''
        let notyped_mesg = ''

        if (activeTab == 1) {
            initial_mesg = messages.find(each => each.id == data.initial_mesg?.id)
            final_mesg = messages.find(each => each.id == data.final_mesg?.id)
            error_mesg = messages.find(each => each.id == data.error_mesg?.id)
            notyped_mesg = messages.find(each => each.id == data.notyped_mesg?.id)

            if (!initial_mesg)
                initial_mesg = {}
            if (!final_mesg)
                final_mesg = {}
            if (!error_mesg)
                error_mesg = {}
            if (!notyped_mesg)
                notyped_mesg = {}

        }

        const updatedState = {
            name: data.name,
            type: type,
            min_rating: data.min_rating,
            max_rating: data.max_rating,
            questions: data.questions,
            initial_mesg: initial_mesg,
            final_mesg: final_mesg,
            buttons: data.buttons,
            error_mesg: error_mesg,
            notyped_mesg: notyped_mesg,
        }

        if (activeTab == 0) {
            delete updatedState.error_mesg
            delete updatedState.notyped_mesg
            updatedState['has_comment'] = data.has_comment
            updatedState['comment_prompt'] = data.comment_prompt ? data.comment_prompt : 'Deixe seu comentario sobre o atendimento:'
        }

        setState(updatedState)

    }, [data])

    const submit = async () => {
        let res = null
        const url = `api/enterprises/${enterpriseId}/surveys/${(activeTab == 0 ? 'chat/' : 'voice/')}`

        setLoading(true)
        if (!data?.id)
            res = await api.post(url, state, { timeout: 0 })
        else
            res = await api.patch(url + `${data.id}/`, state, { timeout: 0 })
        setLoading(false)

        if (!(res.status === 200 || res.status === 201)) {
            if ('name' in res.data && res.data.name[0] === 'Ensure this field has no more than 80 characters.') {
                useNotification(
                    'Campo Inválido!',
                    `O campo nome não pode ter mais de 80 caracteres.`,
                    'danger'
                )
                return   
            }
            if ('name' in res.data && res.data.name[0] === 'Name already exists.') {
                useNotification(
                    'Ops!',
                    `Já existe uma pesquisa com esse nome.`,
                    'danger'
                )
                return
            }
            if ('initial_mesg' in res.data && res.data.initial_mesg[0] === 'Ensure this field has no more than 255 characters.') {
                useNotification(
                    'Campo Inválido!',
                    `O campo mensagem inicial não pode ter mais de 255 caracteres.`,
                    'danger'
                )
                return   
            }
            if ('final_mesg' in res.data && res.data.final_mesg[0] === 'Ensure this field has no more than 255 characters.') {
                useNotification(
                    'Campo Inválido!',
                    `O campo mensagem final não pode ter mais de 255 caracteres.`,
                    'danger'
                )
                return   
            }
            if ('question' in res.data && res.data.question[0] === 'Ensure this field has no more than 255 characters.') {
                useNotification(
                    'Campo Inválido!',
                    `A pergunta não pode ter mais de 255 caracteres.`,
                    'danger'
                )
                return   
            }
            if (Array.isArray(res.data)) {
                for (var i = 0; i < res.data.length; i++) {
                    if ('question' in res.data[i] && res.data[i].question[0] === 'Ensure this field has no more than 255 characters.') {
                        useNotification(
                            'Campo Inválido!',
                            `A pergunta não pode ter mais de 255 caracteres.`,
                            'danger'
                        )
                        return   
                    }
                }
            }
            useNotification(
                'Ops!',
                `Não foi possível ${action(data).toLowerCase()} a pesquisa.`,
                'danger'
            )
            return
        }
        getRows()
        setData()
        useNotification(
            'Sucesso!',
            `Pesquisa ${res.status == 201 ? 'adicionada' : 'atualizada'} com sucesso.`,
            'success'
        )

    }
    const selectType = (event) => {
        const newtype = { type: event.target.value }
        switch (event.target.value) {
            case 0:
                newtype['min_rating'] = 1
                newtype['max_rating'] = 10
                break
            case 1:
                newtype['min_rating'] = 1
                newtype['max_rating'] = 5
                break
            default:
                break

        }
        setState(newtype)
    }


    return (
        <>
            <Dialog open={true}
                fullWidth
                disablePortal
                disableEnforceFocus
                sx={{ position: 'absolute' }}
            >
                <DialogTitle
                    id="customized-dialog-title" className={'group-flexbox'}>
                    <span>{action(data)} Pesquisa</span>
                    <Tooltip title="Fechar" >
                        <Button className="action-button"  >
                            <CloseIcon onClick={() => { setData() }} />
                        </Button>
                    </Tooltip>
                </DialogTitle>
                <Divider />
                <DialogContent className='piloto-modal-flex'>

                    <TextField
                        size='small'
                        label='Nome'
                        value={state.name}
                        onChange={(e) => setState({ name: e.target.value })}
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <FormControl sx={{ maxWidth: 150, flex: 1 }}>
                            <InputLabel size='small' id="simple-search-select">Tipo de pesquisa</InputLabel>
                            <Select
                                size='small'
                                label='Tipo de pesquisa'
                                labelId='simple-search-select'
                                value={state.type}
                                onChange={selectType}
                            >
                                {searchTypes.map(each => (
                                    <MenuItem value={each.id}>{each.value}</MenuItem>
                                ))}

                            </Select>
                        </FormControl>
                        <Box sx={{ display: 'flex', gap: '1rem' }}>
                            <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center', textAlignLast: 'center' }}>
                                <InputLabel id="min-rating-field">Nota mínima</InputLabel>
                                <TextField
                                    sx={{ maxWidth: '3rem', backgroundColor: state.type != 2 ? "#dadada" : 'white' }}
                                    size='small'
                                    type='text'
                                    labelId='min-rating-field'
                                    value={state.min_rating}
                                    onChange={(e) => setState({ min_rating: IntegerOnly(e.target.value, 0, 10) })}
                                    InputProps={{
                                        readOnly: state.type != 2,
                                    }}
                                />
                            </Box>
                            <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center', textAlignLast: 'center' }}>
                                <InputLabel id="man-rating-field">Nota máxima</InputLabel>
                                <TextField
                                    sx={{ maxWidth: '3rem', backgroundColor: state.type != 2 ? "#dadada" : 'white' }}
                                    size='small'
                                    type='numeric'
                                    labelId='max-rating-field'
                                    value={state.max_rating}
                                    onChange={(e) => setState({ max_rating: IntegerOnly(e.target.value, 0, 10) })}
                                    InputProps={{
                                        readOnly: state.type != 2,
                                    }}
                                />
                            </Box>
                        </Box>
                    </Box>

                    <Box className='questionsbox' sx={{ position: 'relative' }}>
                        <Box className='question-exceptions-label'>
                            {showQuestions && 'Perguntas'}
                        </Box>
                        <Box className='questionsboxheader'>
                            <Box sx={{ paddingLeft: '.25rem' }}>
                                {!showQuestions && 'Perguntas'}
                            </Box>
                            {showQuestions ?
                                <Tooltip title='Esconder perguntas'>
                                    <IconButton>
                                        <ExpandLessIcon onClick={() => setShowQuestions(false)} />
                                    </IconButton>
                                </Tooltip>
                                :
                                <Tooltip title='Mostrar perguntas'>
                                    <IconButton onClick={() => setShowQuestions(true)}>
                                        <ExpandMoreIcon />
                                    </IconButton>
                                </Tooltip>
                            }

                        </Box>
                        {showQuestions && <Box sx={{marginTop:'.5rem'}}>
                            <DragDropContext onDragEnd={handleDrop}>
                                <Droppable
                                    id={'draggable-list-containers'} droppableId="draggable-list-containers">
                                    {(provided) => (
                                        <div
                                            className="draggable-list-containers"
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                        >
                                            {state.questions.map((each, index) => {
                                                each.q_order = index + 1
                                                return (
                                                    <Draggable
                                                        key={index} draggableId={`draggable-${index}id`} index={index}
                                                    >
                                                        {(provided) => (
                                                            <div
                                                                className="draggable-item-container"
                                                                ref={provided.innerRef}
                                                                {...provided.dragHandleProps}
                                                                {...provided.draggableProps}
                                                            >
                                                                <QuestionBox
                                                                    obj={each}
                                                                    index={index}
                                                                    messages={messages}
                                                                    state={state}
                                                                    setState={setState} />
                                                            </div>
                                                        )}

                                                    </Draggable>
                                                )
                                            })}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>


                            </DragDropContext>
                            {state.questions?.length < 10 &&
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '.25rem', marginTop:'.5rem' }}>
                                    <Tooltip title='Nova pergunta'>
                                        <IconButton onClick={() => { setState({ questions: [...state.questions, {}] }) }} >
                                            <AddCircleIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Box>}
                        </Box>
                        }
                    </Box>

                    {activeTab == 0 ?
                        <>

                            <EmojiTextarea
                                text={state.initial_mesg}
                                setText={(event) => setState({ initial_mesg: event.target.value })}
                                setEmoji={(text) => setState({ initial_mesg: text })}
                                label="Mensagem Inicial"
                                childComponent="MessageBox"
                            />

                            <EmojiTextarea
                                text={state.final_mesg}
                                setText={(event) => setState({ final_mesg: event.target.value })}
                                setEmoji={(text) => setState({ final_mesg: text })}
                                label="Mensagem Final"
                                childComponent="MessageBox"
                            />
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '1rem' }}>
                                <FormControl sx={{ flex: 1 }} size='small'>
                                    <InputLabel id="simple-search-select-button">Formato de resposta</InputLabel>
                                    <Select

                                        label='Formato de resposta'
                                        labelId='simple-search-select-button'
                                        value={state.buttons}
                                        onChange={(e) => setState({ buttons: e.target.value })}
                                    >
                                        {buttonTypes.map(each => (
                                            <MenuItem value={each.id}>{each.value}</MenuItem>
                                        ))}

                                    </Select>
                                </FormControl>

                                <FormControl sx={{ flex: 1 }} size='small'>
                                    <InputLabel id="comment-search-select-button">Caixa de comentários</InputLabel>
                                    <Select

                                        label='Caixa de comentários'
                                        labelId='comment-search-select-button'
                                        value={state.has_comment}
                                        onChange={(e) => setState({ has_comment: e.target.value })}
                                    >
                                        {commentTypes.map(each => (
                                            <MenuItem value={each.id}>{each.value}</MenuItem>
                                        ))}

                                    </Select>
                                </FormControl>
                            </Box>
                            {Boolean(state.has_comment) &&
                                <EmojiTextarea
                                    text={state.comment_prompt}
                                    setText={(event) => setState({ comment_prompt: event.target.value })}
                                    setEmoji={(text) => setState({ comment_prompt: text })}
                                    label="Pergunta sobre o atendimento"
                                    childComponent="MessageBox"
                                />
                            }
                        </>
                        :
                        <>
                            <FormControl sx={{ flex: 1 }} >
                                <InputLabel size='small' id="simple-search-select-initialmesg">Mensagem Inicial</InputLabel>
                                <Select
                                    size='small'
                                    label='Mensagem Inicial'
                                    labelId='simple-search-select-initialmesg'
                                    value={state.initial_mesg}
                                    onChange={(e) => setState({ initial_mesg: e.target.value })}
                                >
                                    {messages.map(message => (
                                        <MenuItem value={message}>{message.name}</MenuItem>
                                    ))}

                                </Select>
                            </FormControl>
                            <FormControl sx={{ flex: 1 }} >
                                <InputLabel size='small' id="simple-search-select-finalmesg">Mensagem Final</InputLabel>
                                <Select
                                    size='small'
                                    label='Mensagem Final'
                                    labelId='simple-search-select-finalmesg'
                                    value={state.final_mesg}
                                    onChange={(e) => setState({ final_mesg: e.target.value })}
                                >
                                    {messages.map(message => (
                                        <MenuItem value={message}>{message.name}</MenuItem>
                                    ))}

                                </Select>
                            </FormControl>
                            <FormControl sx={{ flex: 1 }} >
                                <InputLabel size='small' id="simple-search-select-errormesg">Mensagem de Erro</InputLabel>
                                <Select
                                    size='small'
                                    label='Mensagem de Erro'
                                    labelId='simple-search-select-errormesg'
                                    value={state.error_mesg}
                                    onChange={(e) => setState({ error_mesg: e.target.value })}
                                >
                                    {messages.map(message => (
                                        <MenuItem value={message}>{message.name}</MenuItem>
                                    ))}

                                </Select>
                            </FormControl>
                            <FormControl sx={{ flex: 1 }} >
                                <InputLabel size='small' id="simple-search-select-nodigitmesg">Mensagem de Não digitado</InputLabel>
                                <Select
                                    size='small'
                                    label='Mensagem de Não digitado'
                                    labelId='simple-search-select-nodigitmesg'
                                    value={state.notyped_mesg}
                                    onChange={(e) => setState({ notyped_mesg: e.target.value })}
                                >
                                    {messages.map(message => (
                                        <MenuItem value={message}>{message.name}</MenuItem>
                                    ))}

                                </Select>
                            </FormControl>
                        </>
                    }



                </DialogContent >
                <DialogActions style={{ padding: "10px 20px 10px 10px" }}>
                    <Button
                        onClick={() => { setData() }}>
                        Cancelar
                    </Button>
                    <Tooltip title={checkButtonText()} placement="top" describeChild={true}>
                        <span>
                            <Button
                                disabled={checkButtonText()}
                                onClick={submit}>
                                Salvar
                            </Button>
                        </span>
                    </Tooltip>
                </DialogActions>
            </Dialog >
        </>
    )
}

export default SearchEditModal