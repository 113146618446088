export const alphabeticalSort = (x,y, value='name', revert = false) => {
    
    const nameX = value !== '' ? x[value].toUpperCase() : x.toUpperCase()
    const nameY = value !== '' ? y[value].toUpperCase() : y.toUpperCase()

    const multiplier = revert ? -1 : 1

    if (y?.code === 0) return 1
    if (nameX < nameY)
        return -1 * multiplier
    else if (nameX > nameY)
        return 1 * multiplier
    return 0
}