export const mimeTypes ={
    "x-world/x-3dmf": ".qd3d",
    "application/x-7z-compressed": ".7z",
    "application/octet-stream": ".zoo",
    "application/x-authorware-bin": ".aab",
    "application/x-authorware-map": ".aam",
    "application/x-authorware-seg": ".aas",
    "text/vnd.abc": ".abc",
    "text/html": ".shtml",
    "video/animaflex": ".afl",
    "application/postscript": ".ps",
    "audio/aiff": ".aiff",
    "audio/x-aiff": ".aiff",
    "application/x-aim": ".aim",
    "text/x-audiosoft-intra": ".aip",
    "application/x-navi-animation": ".ani",
    "application/x-nokia-9000-communicator-add-on-software": ".aos",
    "application/mime": ".aps",
    "application/arj": ".arj",
    "image/x-jg": ".art",
    "video/x-ms-asf": ".asx",
    "text/x-asm": ".s",
    "text/asp": ".asp",
    "application/x-mplayer2": ".asx",
    "video/x-ms-asf-plugin": ".asx",
    "audio/basic": ".snd",
    "audio/x-au": ".au",
    "application/x-troff-msvideo": ".avi",
    "video/avi": ".avi",
    "video/msvideo": ".avi",
    "video/x-msvideo": ".avi",
    "video/avs-video": ".avs",
    "application/x-bcpio": ".bcpio",
    "application/mac-binary": ".bin",
    "application/macbinary": ".bin",
    "application/x-binary": ".bin",
    "application/x-macbinary": ".bin",
    "image/bmp": ".bmp",
    "image/x-windows-bmp": ".bmp",
    "application/book": ".book",
    "application/x-bzip2": ".bz2",
    "application/x-bsh": ".shar",
    "application/x-bzip": ".bz",
    "text/plain": ".txt",
    "text/x-c": ".cpp",
    "application/vnd.ms-pki.seccat": ".cat",
    "application/clariscad": ".ccad",
    "application/x-cocoa": ".cco",
    "application/cdf": ".cdf",
    "application/x-cdf": ".cdf",
    "application/x-netcdf": ".nc",
    "application/pkix-cert": ".crt",
    "application/x-x509-ca-cert": ".der",
    "application/x-chat": ".chat",
    "application/java": ".class",
    "application/java-byte-code": ".class",
    "application/x-java-class": ".class",
    "application/x-cpio": ".cpio",
    "application/mac-compactpro": ".cpt",
    "application/x-compactpro": ".cpt",
    "application/x-cpt": ".cpt",
    "application/pkcs-crl": ".crl",
    "application/pkix-crl": ".crl",
    "application/x-x509-user-cert": ".crt",
    "application/x-csh": ".csh",
    "text/x-script.csh": ".csh",
    "application/x-pointplus": ".css",
    "text/css": ".css",
    "text/csv": ".csv",
    "application/x-director": ".dxr",
    "application/x-deepv": ".deepv",
    "video/x-dv": ".dv",
    "video/dl": ".dl",
    "video/x-dl": ".dl",
    "application/msword": ".word",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": ".docx",
    "application/commonground": ".dp",
    "application/drafting": ".drw",
    "application/x-dvi": ".dvi",
    "drawing/x-dwf (old)": ".dwf",
    "model/vnd.dwf": ".dwf",
    "application/acad": ".dwg",
    "image/vnd.dwg": ".svf",
    "image/x-dwg": ".svf",
    "application/dxf": ".dxf",
    "text/x-script.elisp": ".el",
    "application/x-bytecode.elisp (compiled elisp)": ".elc",
    "application/x-elc": ".elc",
    "application/x-envoy": ".evy",
    "application/vnd.ms-fontobject": ".eot",
    "application/x-esrehber": ".es",
    "text/x-setext": ".etx",
    "application/envoy": ".evy",
    "text/x-fortran": ".for",
    "application/vnd.fdf": ".fdf",
    "application/fractals": ".fif",
    "image/fif": ".fif",
    "audio/flac": ".flac",
    "video/fli": ".fli",
    "video/x-fli": ".fli",
    "image/florian": ".turbot",
    "text/vnd.fmi.flexstor": ".flx",
    "video/x-atomic3d-feature": ".fmf",
    "image/vnd.fpx": ".fpx",
    "image/vnd.net-fpx": ".fpx",
    "application/freeloader": ".frl",
    "audio/make": ".pfunk",
    "image/g3fax": ".g3",
    "image/gif": ".gif",
    "video/gl": ".gl",
    "video/x-gl": ".gl",
    "audio/x-gsm": ".gsm",
    "application/x-gsp": ".gsp",
    "application/x-gss": ".gss",
    "application/x-gtar": ".gtar",
    "application/x-compressed": ".zip",
    "application/x-gzip": ".gzip",
    "multipart/x-gzip": ".gzip",
    "text/x-h": ".hh",
    "application/x-hdf": ".hdf",
    "application/x-helpfile": ".hlp",
    "application/vnd.hp-hpgl": ".hpgl",
    "text/x-script": ".hlb",
    "application/hlp": ".hlp",
    "application/x-winhelp": ".hlp",
    "application/binhex": ".hqx",
    "application/binhex4": ".hqx",
    "application/mac-binhex": ".hqx",
    "application/mac-binhex40": ".hqx",
    "application/x-binhex40": ".hqx",
    "application/x-mac-binhex40": ".hqx",
    "application/hta": ".hta",
    "text/x-component": ".htc",
    "text/webviewhtml": ".htt",
    "x-conference/x-cooltalk": ".ice",
    "image/x-icon": ".ico",
    "text/calendar": ".ics",
    "image/ief": ".iefs",
    "application/iges": ".igs",
    "model/iges": ".igs",
    "application/x-ima": ".ima",
    "application/x-httpd-imap": ".imap",
    "application/inf": ".inf",
    "application/x-internett-signup": ".ins",
    "application/x-ip2": ".ip",
    "video/x-isvideo": ".isu",
    "audio/it": ".it",
    "application/x-inventor": ".iv",
    "i-world/i-vrml": ".ivr",
    "application/x-livescreen": ".ivy",
    "audio/x-jam": ".jam",
    "text/x-java-source": ".java",
    "application/x-java-commerce": ".jcm",
    "image/jpeg": ".jpg",
    "image/pjpeg": ".jpg",
    "image/x-jps": ".jps",
    "application/x-javascript": ".js",
    "application/javascript": ".js",
    "application/ecmascript": ".js",
    "text/javascript": ".js",
    "text/ecmascript": ".js",
    "application/json": ".json",
    "image/jutvision": ".jut",
    "audio/midi": ".midi",
    "music/x-karaoke": ".kar",
    "application/x-ksh": ".ksh",
    "text/x-script.ksh": ".ksh",
    "audio/nspaudio": ".lma",
    "audio/x-nspaudio": ".lma",
    "audio/x-liveaudio": ".lam",
    "application/x-latex": ".ltx",
    "application/lha": ".lha",
    "application/x-lha": ".lha",
    "application/x-lisp": ".lsp",
    "text/x-script.lisp": ".lsp",
    "text/x-la-asf": ".lsx",
    "application/x-lzh": ".lzh",
    "application/lzx": ".lzx",
    "application/x-lzx": ".lzx",
    "text/x-m": ".m",
    "video/mpeg": ".mpg",
    "audio/mpeg": ".mpga",
    "audio/x-mpequrl": ".m3u",
    "application/x-troff-man": ".man",
    "application/x-navimap": ".map",
    "application/mbedlet": ".mbd",
    "application/x-magic-cap-package-1.0": ".mc$",
    "application/mcad": ".mcd",
    "application/x-mathcad": ".mcd",
    "image/vasa": ".mcf",
    "text/mcf": ".mcf",
    "application/netmc": ".mcp",
    "application/x-troff-me": ".me",
    "message/rfc822": ".mime",
    "application/x-midi": ".midi",
    "audio/x-mid": ".midi",
    "audio/x-midi": ".midi",
    "music/crescendo": ".midi",
    "x-music/x-midi": ".midi",
    "application/x-frame": ".mif",
    "application/x-mif": ".mif",
    "www/mime": ".mime",
    "audio/x-vnd.audioexplosion.mjuicemediafile": ".mjf",
    "video/x-motion-jpeg": ".mjpg",
    "audio/x-matroska": ".mka",
    "video/x-matroska": ".mkv",
    "application/base64": ".mme",
    "application/x-meme": ".mm",
    "audio/mod": ".mod",
    "audio/x-mod": ".mod",
    "video/quicktime": ".qt",
    "video/x-sgi-movie": ".mv",
    "audio/x-mpeg": ".mp2",
    "video/x-mpeg": ".mp3",
    "video/x-mpeq2a": ".mp2",
    "audio/mpeg3": ".mp3",
    "audio/x-mpeg-3": ".mp3",
    "video/mp4": ".mp4",
    "application/x-project": ".mpx",
    "application/vnd.ms-project": ".mpp",
    "application/marc": ".mrc",
    "application/x-troff-ms": ".ms",
    "application/x-vnd.audioexplosion.mzz": ".mzz",
    "image/naplps": ".naplps",
    "application/vnd.nokia.configuration-message": ".ncm",
    "image/x-niff": ".niff",
    "application/x-mix-transfer": ".nix",
    "application/x-conference": ".nsc",
    "application/x-navidoc": ".nvd",
    "application/oda": ".oda",
    "audio/ogg": ".ogg",
    "video/ogg": ".ogg",
    "application/x-omc": ".omc",
    "application/x-omcdatamaker": ".omcd",
    "application/x-omcregerator": ".omcr",
    "font/otf": ".otf",
    "text/x-pascal": ".p",
    "application/pkcs10": ".p10",
    "application/x-pkcs10": ".p10",
    "application/pkcs-12": ".p12",
    "application/x-pkcs12": ".p12",
    "application/x-pkcs7-signature": ".p7a",
    "application/pkcs7-mime": ".p7m",
    "application/x-pkcs7-mime": ".p7m",
    "application/x-pkcs7-certreqresp": ".p7r",
    "application/pkcs7-signature": ".p7s",
    "application/pro_eng": ".prt",
    "text/pascal": ".pas",
    "image/x-portable-bitmap": ".pbm",
    "application/vnd.hp-pcl": ".pcl",
    "application/x-pcl": ".pcl",
    "image/x-pict": ".pct",
    "image/x-pcx": ".pcx",
    "chemical/x-pdb": ".xyz",
    "application/pdf": ".pdf",
    "audio/make.my.funk": ".pfunk",
    "image/x-portable-graymap": ".pgm",
    "image/x-portable-greymap": ".pgm",
    "image/pict": ".pict",
    "application/x-newton-compatible-pkg": ".pkg",
    "application/vnd.ms-pki.pko": ".pko",
    "text/x-script.perl": ".pl",
    "application/x-pixclscript": ".plx",
    "image/x-xpixmap": ".xpm",
    "text/x-script.perl-module": ".pm",
    "application/x-pagemaker": ".pm5",
    "image/png": ".x-png",
    "application/x-portable-anymap": ".pnm",
    "image/x-portable-anymap": ".pnm",
    "application/mspowerpoint": ".ppz",
    "application/vnd.ms-powerpoint": ".pwz",
    "model/x-pov": ".pov",
    "image/x-portable-pixmap": ".ppm",
    "application/powerpoint": ".ppt",
    "application/x-mspowerpoint": ".ppt",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation": ".pptx",
    "application/x-freelance": ".pre",
    "paleovu/x-pv": ".pvu",
    "text/x-script.phyton": ".py",
    "application/x-bytecode.python": ".pyc",
    "audio/vnd.qcelp": ".qcp",
    "image/x-quicktime": ".qtif",
    "video/x-qtc": ".qtc",
    "audio/x-pn-realaudio": ".rmp",
    "audio/x-pn-realaudio-plugin": ".rpm",
    "audio/x-realaudio": ".ra",
    "application/x-cmu-raster": ".ras",
    "image/cmu-raster": ".rast",
    "image/x-cmu-raster": ".ras",
    "application/vnd.rar": ".rar",
    "text/x-script.rexx": ".rexx",
    "image/vnd.rn-realflash": ".rf",
    "image/x-rgb": ".rgb",
    "application/vnd.rn-realmedia": ".rm",
    "audio/mid": ".rmi",
    "application/ringing-tones": ".rng",
    "application/vnd.nokia.ringing-tone": ".rng",
    "application/vnd.rn-realplayer": ".rnx",
    "application/x-troff": ".tr",
    "image/vnd.rn-realpix": ".rp",
    "text/richtext": ".rtx",
    "text/vnd.rn-realtext": ".rt",
    "application/rtf": ".rtx",
    "application/x-rtf": ".rtf",
    "video/vnd.rn-realvideo": ".rv",
    "audio/s3m": ".s3m",
    "application/x-tbook": ".tbk",
    "application/x-lotusscreencam": ".scm",
    "text/x-script.guile": ".scm",
    "text/x-script.scheme": ".scm",
    "video/x-scm": ".scm",
    "application/sdp": ".sdp",
    "application/x-sdp": ".sdp",
    "application/sounder": ".sdr",
    "application/sea": ".sea",
    "application/x-sea": ".sea",
    "application/set": ".set",
    "text/sgml": ".sgml",
    "text/x-sgml": ".sgml",
    "application/x-sh": ".sh",
    "application/x-shar": ".shar",
    "text/x-script.sh": ".sh",
    "text/x-server-parsed-html": ".ssi",
    "audio/x-psid": ".sid",
    "application/x-sit": ".sit",
    "application/x-stuffit": ".sit",
    "application/x-koan": ".skt",
    "application/x-seelogo": ".sl",
    "application/smil": ".smil",
    "audio/x-adpcm": ".snd",
    "application/solids": ".sol",
    "application/x-pkcs7-certificates": ".spc",
    "text/x-speech": ".talk",
    "application/futuresplash": ".spl",
    "application/x-sprite": ".sprite",
    "application/x-wais-source": ".wsrc",
    "application/streamingmedia": ".ssm",
    "application/vnd.ms-pki.certstore": ".sst",
    "application/step": ".stp",
    "application/sla": ".stl",
    "application/vnd.ms-pki.stl": ".stl",
    "application/x-navistyle": ".stl",
    "application/x-sv4cpio": ".sv4cpio",
    "application/x-sv4crc": ".sv4crc",
    "image/svg+xml": ".svg",
    "application/x-world": ".wrl",
    "x-world/x-svr": ".svr",
    "application/x-shockwave-flash": ".swf",
    "application/x-tar": ".tar",
    "application/toolbook": ".tbk",
    "application/x-tcl": ".tcl",
    "text/x-script.tcl": ".tcl",
    "text/x-script.tcsh": ".tcsh",
    "application/x-tex": ".tex",
    "application/x-texinfo": ".texinfo",
    "application/plain": ".text",
    "application/gnutar": ".tgz",
    "image/tiff": ".tiff",
    "image/x-tiff": ".tiff",
    "video/mp2t": ".ts",
    "audio/tsp-audio": ".tsi",
    "application/dsptype": ".tsp",
    "audio/tsplayer": ".tsp",
    "text/tab-separated-values": ".tsv",
    "text/x-uil": ".uil",
    "text/uri-list": ".uris",
    "application/i-deas": ".unv",
    "application/x-ustar": ".ustar",
    "multipart/x-ustar": ".ustar",
    "text/x-uuencode": ".uue",
    "application/x-cdlink": ".vcd",
    "text/x-vcalendar": ".vcs",
    "application/vda": ".vda",
    "video/vdo": ".vdo",
    "application/groupwise": ".vew",
    "video/vivo": ".vivo",
    "video/vnd.vivo": ".vivo",
    "application/vocaltec-media-desc": ".vmd",
    "application/vocaltec-media-file": ".vmf",
    "audio/voc": ".voc",
    "audio/x-voc": ".voc",
    "video/vosaic": ".vos",
    "audio/voxware": ".vox",
    "audio/x-twinvq-plugin": ".vql",
    "audio/x-twinvq": ".vqf",
    "application/x-vrml": ".vrml",
    "model/vrml": ".wrz",
    "x-world/x-vrml": ".wrz",
    "x-world/x-vrt": ".vrt",
    "application/x-visio": ".vsw",
    "application/wordperfect6.0": ".wp5",
    "application/wordperfect6.1": ".w61",
    "audio/wav": ".wav",
    "audio/x-wav": ".wav",
    "application/x-qpro": ".wb1",
    "image/vnd.wap.wbmp": ".wbmp",
    "application/vnd.xara": ".web",
    "video/webm": ".webm",
    "image/webp": ".webp",
    "application/x-123": ".wk1",
    "windows/metafile": ".wmf",
    "text/vnd.wap.wml": ".wml",
    "application/vnd.wap.wmlc": ".wmlc",
    "text/vnd.wap.wmlscript": ".wmls",
    "application/vnd.wap.wmlscriptc": ".wmlsc",
    "font/woff": ".woff",
    "font/woff2": ".woff2",
    "application/wordperfect": ".wpd",
    "application/x-wpwin": ".wpd",
    "application/x-lotus": ".wq1",
    "application/mswrite": ".wri",
    "application/x-wri": ".wri",
    "text/scriplet": ".wsc",
    "application/x-wintalk": ".wtk",
    "image/x-xbitmap": ".xbm",
    "image/x-xbm": ".xbm",
    "image/xbm": ".xbm",
    "video/x-amt-demorun": ".xdr",
    "xgl/drawing": ".xgz",
    "image/vnd.xiff": ".xif",
    "application/excel": ".xlw",
    "application/x-excel": ".xlw",
    "application/x-msexcel": ".xlw",
    "application/vnd.ms-excel": ".xlw",
    "audio/xm": ".xm",
    "application/xml": ".xml",
    "text/xml": ".xml",
    "xgl/movie": ".xmz",
    "application/x-vnd.ls-xpix": ".xpix",
    "image/xpm": ".xpm",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": ".xlsx",
    "video/x-amt-showrun": ".xsr",
    "image/x-xwd": ".xwd",
    "image/x-xwindowdump": ".xwd",
    "application/x-yaml": ".yml",
    "application/x-compress": ".z",
    "application/x-zip-compressed": ".zip",
    "application/zip": ".zip",
    "multipart/x-zip": ".zip",
    "text/x-script.zsh": ".zsh"
}