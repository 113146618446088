import React, { useEffect, useReducer, useState } from 'react'
import { Box, TextField, Switch, FormControlLabel } from '@mui/material'
import { lockedReducer } from '../../../../utils/defaultReducer'
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import api from '../../../../api';
import { getSession } from '../../../../auth';
import useNotification from '../../../Notification';
import Loading from '../../../Loading';



const EndChat = () => {

    const enterpriseId = getSession()?.profile?.enterprise?.id

    const [loading, setLoading] = useState(false)
    const [patchLoading, setPatchLoading] = useState(false)

    const [state, setState] = useReducer(lockedReducer, {
        send_email_on_hangup: false,
        notification_email: ''
    })

    const loadData = async () => {
        setLoading(true)
        const res = await api.get(`/api/enterprises/${enterpriseId}/`)
        setLoading(false)
        if (res.status === 200) {
            setState(res.data.settings)
            return
        }

        useNotification(
            'Ops!',
            'Informações não carregadas',
            'danger'
        );
    }

    useEffect(() => {
        loadData()
    }, [])

    const submit = async (data) => {

        setPatchLoading(true)
        const res = await api.patch(`/api/enterprises/${enterpriseId}/`, { settings: data })
        setPatchLoading(false)
        if (res.status === 200) {
            useNotification(
                'Sucesso!',
                'Configurações do Fim de atendimento alterados com sucesso.',
                'success'
            );
            return
        }
        useNotification(
            'Ops!',
            'Ocorreu um erro no sistema',
            'danger'
        );

    }

    return (
        <>
            <Loading loading={loading} />
            <Box className="flexbox input-settings" sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                <Box >
                    <Box className="category-title push-bottom">
                        Notificar fim de atendimento
                    </Box>
                    <Box className="flexbox" sx={{gap:'1rem', display:'flex', flexDirection:'column'}}>

                        <FormControlLabel
                            label="Enviar e-mail para destinatários ao finalizar atendimento"
                            sx={{ margin: 0 }}
                            control={
                                <Switch
                                    checked={state.send_email_on_hangup}
                                    onChange={() => setState({ send_email_on_hangup: !state.send_email_on_hangup })}
                                />
                            }
                        />
                        {state.send_email_on_hangup &&
                            <TextField
                                size="small"
                                label="Email"
                                fullWidth
                                sx={{maxWidth:'30rem'}}
                                type="text"
                                variant="outlined"
                                defaultValue={state.notification_email}
                                onChange={(event) => setState({
                                    notification_email: event.target.value
                                })}
                            />}

                    </Box>
                </Box>

                <LoadingButton
                    style={{ width: 'fit-content' }}
                    onClick={() => submit(state)}
                    loading={patchLoading}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                >
                    Salvar
                </LoadingButton>
            </Box >
        </>
    )
}


export default EndChat