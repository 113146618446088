import React, { useEffect, useReducer, useState } from "react";
import { Box, Alert, TextField, Button, Paper, Tooltip, InputBase, InputAdornment } from "@mui/material";
import { getSession } from "../../../auth.js";
import api from "../../../api";
import Loading from "../../Loading/index.js";
import { lockedReducer } from '../../../utils/defaultReducer.js'
import './styles.css'
import ChatWindow from "./ChatWindow/index.js";
import AccountList from "./AccountList/index.js";
import ScoutSocketComponent, { treatEvents } from "./SocketComponent/index.js";
import SearchIcon from "@mui/icons-material/Search";
import TextSearch from "./TextSearch/index.js";

const getAccounts = async (enterprise_id, setState) => {

  setState({loading: true})
  const res = await api.get(
    `api/enterprises/${enterprise_id}/scout_accounts/`
  );
  if (res.status === 200) {
    setState({accounts:res.data, loading:false})
    return
  }
  setState({loading:false})
}

const MonitorPage = () => {

  const windowRef = {}

  const enterprise = getSession().profile.enterprise

  const [state, setState] = useReducer(lockedReducer, {
    chats: [],
    accounts: [],
    activeAccount: null,
    activeChat: null,
    chatMessageId: null,
    loading: false,
    socket: null,
    showSearch: false,
  })

  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    getAccounts(enterprise.id, setState)
  }, []);

  window.setState = setState

  return (
    <>
      <Loading loading={state.loading} />
      <ScoutSocketComponent parent={state} setParent={setState} windowRef={windowRef} />
      {enterprise.platforms.scout ? <>

        <Box className="scout-wrapper">
          <Box className="scout-searchbar">
              <Paper
                  component="form"
                  sx={{ display: 'flex', alignItems: 'center', width: 500, height: 50, bgcolor: '#efefef' }}
              >
                  <Tooltip title="Procure nas conversas." placement="bottom" disableInteractive >
                      <InputBase
                          sx={{ ml: 2, flex: 1 }}
                          placeholder="Busca"
                          value={searchQuery}
                          onChange={e => setSearchQuery(e.target.value)}
                          onKeyDown={e => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                setState({ showSearch: true });
                            }
                            
                          }}
                          startAdornment={
                              <InputAdornment position="start">
                                  <SearchIcon />
                              </InputAdornment>
                          }
                      />
                  </Tooltip >
              </Paper>
          </Box>
          <Box className="scout-accounts">
            <Box className='scout-accountlist'><AccountList parent={state} setParent={setState} /></Box>
            <Box className='scout-chatwindow'><ChatWindow parent={state} setParent={setState} innerRef={windowRef} /></Box>
          </Box>
        </Box>
        { state.showSearch && <TextSearch searchQuery={searchQuery} handleClose={() => setState({ showSearch: false })} parent={state} setParent={setState} /> }
        </>
        :
        <Box className='scout-alert-padding'>
          <Alert severity="info">
            Entre em contato com seu gerente de contas para contratar o Kwik Scout - O canal de "Compliance"  e gravacao de WhatsApp do Kwik - <a href="https://wa.link/0bs73j">Clique aqui.</a>
          </Alert>
        </Box>
      }
    </>
  );
};

export default MonitorPage;
