import React, { useState } from "react";
import UserForm from "./UserForm"
import EnterpriseForm from "./EnterpriseForm"
import { Box, Button, Grid } from "@mui/material";
import { useFormik } from "formik";
import api from '../../../api'
import * as Yup from "yup";
import { useStyles } from "./styles.js";
import { useHistory } from "react-router-dom";
// import { login } from "../../../services/auth"

const FormController = () => {
    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(0);
    const history = useHistory();

    const { values, handleChange, errors, handleBlur, touched, setFieldValue } = useFormik({
        initialValues: {
            user: {
                first_name: "",
                last_name: "",
                username: "",
                password: "",
                telephone: "+5521999999999",
                role: "",
                enterprise: "",
                confirm_password: "",
            },
            enterprise: {
                name: "",
                site: "",
                field: "",
                interests: "",
                expected_agents: 1,
            }
        },
        validationSchema: Yup.object().shape({
            user: Yup.object().shape({
                first_name: Yup.string("Informe um nome válido").required(
                    "Este campo é obrigatório"
                ).min(3, "O mínimo é de 3 caracteres.").max(50, "O máximo é de 50 caracteres."),
                last_name: Yup.string("Informe um nome válido").min(3, "O mínimo é de 3 caracteres.").max(50, "O máximo é de 50 caracteres."),
                username: Yup.string("Informe um e-mail válido").required(
                    "Este campo é obrigatório"
                ).email("Informe um e-mail válido"),
                password: Yup.string().required(
                    "Este campo é obrigatório"
                ).min(6, "O mínimo é de 6 caracteres.").matches(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*-_])(?=.{6,})/,
                    "A senha deve conter pelo menos um número, letras maiúsuclas e minúsculas e ao menos um caracter especial."
                ),
                telephone: Yup.number("Insira um formato de telefone válido").required(
                    "Este campo é obrigatório"
                ).min(8, "O mínimo é de 8 caracteres."),
                role: Yup.string("Informe um cargo válido").min(3, "O mínimo é de 3 caracteres.").max(60, "O máximo é de 60 caracteres."),
                confirm_password: Yup.string().required(
                    "Este campo é obrigatório"
                ).oneOf([Yup.ref('password'), null], 'As senhas devem coincidir')
            }),
        })
    });

    const handleSubmit = async () => {
        if (activeStep < 1) {
            setActiveStep(activeStep + 1);
        }
        // else {
            // const response = await login("admin", "admin");
            // if (response === 200) {
            //     api.post('/api/enterprises/', values.enterprise).then(resp => {
            //         console.log(resp.data);

            //         const user = {
            //             first_name: values.user.first_name,
            //             last_name: values.user.last_name,
            //             username: values.user.username,
            //             password: values.user.password,
            //             profile: {
            //                 telephone: values.user.telephone,
            //                 role: values.user.role,
            //                 enterprise: resp.data.id
            //             }
            //         }
            //         console.log(user);
            //         api.post('/api/users/', user).then(response => {
            //             console.log(response.data);
            //             history.push("/")
            //         }).catch(error => {
            //             console.log(error.response.data)
            //             alert("Tivemos um problema, tente novamente");
            //         });
            //     }).catch(error => {
            //         console.error(error)
            //         alert("Tivemos um problema, tente novamente");
            //     });
            // }
        // }
    }

    function _handleBack() {
        setActiveStep(activeStep - 1);
    }

    function _renderStepContent(step) {
        switch (step) {
            case 0:
                return <UserForm setFieldValue={setFieldValue} values={values.user} handleChange={handleChange} errors={errors.user} handleBlur={handleBlur} touched={touched.user} />;
            case 1:
                return <EnterpriseForm values={values} handleChange={handleChange} />;
        }
    }

    return (
        <Box flexGrow={1} m={15} width={"40vw"} >
            {_renderStepContent(activeStep)}
            < Grid container spacing={2} >
                {activeStep === 1 &&
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Button
                            fullwidth
                            variant="contained"
                            className={classes.button_back}
                            type="submit"
                            onClick={_handleBack}
                        >
                            Voltar
                        </Button>
                    </Grid>}
                <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Button
                        fullwidth
                        variant="contained"
                        className={classes.button}
                        type="submit"
                        onClick={handleSubmit}
                    >
                        {activeStep === 1 ? "Cadastrar" : "Avançar"}
                    </Button>
                </Grid>


            </Grid >
        </Box >
    )
};

export default FormController;