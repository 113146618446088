import React, {  useState } from 'react'

import { Box, IconButton, Tooltip } from "@mui/material";

import HelpIcon from '@mui/icons-material/Help';
import CloseIcon from '@mui/icons-material/Close';

const Information = (props) => {

    const [showInformation, updateShowInformation] = useState(false)

    const getCSVExampleFile = () => {
        if(props.is_financial_market_company) {
            return (
                <>
                    <Box className="info-topic">
                        4. Os cabeçalhos devem ter Nome, Email, Telefone e Assessor (não necessariamente nessa ordem). 
                        Caso falte algum, o arquivo não será aceito.
                    </Box>
                    <Box className="info-description">Em anexo há um arquivo de exemplo. Observação: 
                    o arquivo anexado como exemplo não poderá ser utilizado, pois os e-mails de assessores 
                    só servem para demonstrar o padrão do arquivo e não existem no banco de dados.</Box>
                    <Box className="example-file">
                        <Box className="example-csv">CSV</Box>
                        <a className="example-text" href="/contact_and_advisor_list_example.csv">contact_list_example.csv</a>
                    </Box>
                </>
            )
        }
        return (
            <>
                <Box className="info-topic">
                    4. Os cabeçalhos devem ter Nome, Email e Telefone (não necessariamente nessa ordem). 
                    Caso falte algum, o arquivo não será aceito.
                </Box>
                <Box className="info-description">Em anexo há um arquivo de exemplo.</Box>
                <Box className="example-file">
                    <Box className="example-csv">CSV</Box>
                    <a className="example-text" href="/contact_list_example.csv">contact_list_example.csv</a>
                </Box>
            </>
        )
    }

    return (
        <>
            {showInformation ?
                <Box className="import-information">
                    <Box className="info-header">
                        <Box className="info-title">Upload de Contatos por arquivo CSV</Box>
                        <Box className="info-close"
                            onClick={() => { updateShowInformation(false) }}
                        >
                            <CloseIcon className="close-icon" />
                        </Box>
                    </Box>
                    <Box className="info-description">O arquivo com os contatos deve seguir as seguintes restrições:</Box>
                    <Box className="info-topic">1. O documento deve ter extensão .csv ou .CSV</Box>
                    <Box className="info-topic">2. O contato que estiver sem telefone não será cadastrado.</Box>
                    <Box className="info-topic">3. A codificação do arquivo deve ser UTF-8, podendo haver problemas na leitura caso contrário.</Box>
                    {getCSVExampleFile()}
                </Box> : null}
            <Tooltip title="Exemplo e instruções de importação" placement="bottom" disableInteractive>
                <IconButton
                    sx={{marginBottom:0}}
                    onClick={() => { updateShowInformation(true) }}
                >
                    <HelpIcon className="question-icon" />
                </IconButton>
            </Tooltip>
        </>
    )
}

export default Information
