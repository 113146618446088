import React, { useState } from "react";
import { Grid, TextField, Box } from "@mui/material";
import HeaderForm from "../../HeaderForm"
import ResearchImage from "../../../../assets/images/research.svg"
import Text from "../../../../components/Text";
import Checkbox from "../../../../components/Button/Checkbox";
import Typography from "../../../Typography";
import PropTypes from "prop-types";

const ResearchesForm = ({ values, handleChange, setFieldValue }) => {
    const [research, setResearch] = useState(true);

    return (
        <Grid container spacing={2}>
            <HeaderForm img={ResearchImage} title="PERSONALIZE SEU ATENDIMENTO" subtitle="Pesquisas de Satisfação" width="100%" />
            <Box paddingBottom={1}>
                <Typography color="darkGray" text="As pesquisas de satisfação são realizadas ao final de cada atendimento para que o cliente possa avaliar o atendimento prestado pelos seus atendentes. " />
            </Box>

            {research && (
                <Box paddingTop={2}>
                    <Grid container spacing={2}>
                        <Grid item lg={8} md={8} sm={12} xs={12}>
                            <TextField
                                id=""
                                label="Nome da pesquisa"
                                variant="outlined"
                                size="small"
                                name="researches.name"
                                onChange={handleChange}
                                value={values.researches.name}
                                fullWidth
                            />
                        </Grid>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <TextField
                                id=""
                                label="Código da pesquisa"
                                variant="outlined"
                                size="small"
                                name="researches.number"
                                onChange={handleChange}
                                value={values.researches.number}
                                fullWidth
                            />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <TextField
                                id=""
                                label="Nota mínina"
                                variant="outlined"
                                size="small"
                                name="researches.minRating"
                                onChange={handleChange}
                                value={values.researches.minRating}
                                fullWidth
                            />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <TextField
                                id=""
                                label="Nota máxima"
                                variant="outlined"
                                size="small"
                                name="researches.maxRating"
                                onChange={handleChange}
                                value={values.researches.maxRating}
                                fullWidth
                            />
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <TextField
                                id=""
                                label="Mensagem Inicial"
                                variant="outlined"
                                size="small"
                                name="researches.initialMessage"
                                onChange={handleChange}
                                value={values.researches.initialMessage}
                                fullWidth
                            />
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <TextField
                                id=""
                                label="Mensagem Final"
                                variant="outlined"
                                size="small"
                                name="researches.finalMessage"
                                onChange={handleChange}
                                value={values.researches.finalMessage}
                                fullWidth
                            />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Box paddingTop={1}>
                                <Typography color="darkGray" text="Deseja adicionar boxe para comentários?" />
                            </Box>
                        </Grid>
                        <Grid item lg={3} md={3} sm={3} xs={3}>
                            <Checkbox
                                id="1"
                                name="researches.comments"
                                checked={values.researches.comments}
                                onChange={() => setFieldValue("researches.comments", !values.researches.comments)}
                                label="Sim"
                            />
                        </Grid>
                        <Grid item lg={3} md={3} sm={3} xs={3}>
                            <Checkbox
                                id="2"
                                name="researches.comments"
                                checked={!values.researches.comments}
                                onChange={() => setFieldValue("researches.comments", !values.researches.comments)}
                                label="Não"
                            />
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography
                                fontWeight="500"
                                text="Perguntas"
                                color="darkGray"
                            />
                        </Grid>
                        {values.researches.questions.map((question, i) => (
                            <Grid item lg={12} md={12} sm={12} xs={12} key={i}>
                                <TextField
                                    id=""
                                    label="Escreva sua pergunta"
                                    variant="outlined"
                                    size="small"
                                    name={"researches.questions[" + i + "].question"}
                                    onChange={handleChange}
                                    value={question.question}
                                    fullWidth
                                />
                            </Grid>))}
                    </Grid>
                </Box>)
            }

        </Grid >
    );
};

ResearchesForm.propTypes = {
    values: PropTypes.object,
    handleChange: PropTypes.func,
    setFieldValue: PropTypes.func,
}

export default ResearchesForm;
