import React, { useState } from 'react'

import { Box, Button } from '@mui/material'

import './styles.css'

const OptionsExamples = (props) => {
    return {
        option1: {
            permissions: true,
            label: 'Opção1',
            component: (<Box {...props}></Box>)
        }
    }
}

const selectFirstMenu = (componentName, options) => {
    const nameOnStorage = localStorage.getItem(`${componentName}-menu`)
    if (nameOnStorage)
        return nameOnStorage
    return Object.keys(options)[0]

}

const OptionsMenu = ({title, name, options = () => { }, drillProps = null }) => {

    const getOptions = options(drillProps)

    const [selectedTab, setSelectedTab] = useState(selectFirstMenu(name, getOptions))

    const changeTab = (key) => {
        localStorage.setItem(`${name}-menu`, key)
        setSelectedTab(key)
        if (window?.returnSettings) window.returnSettings()
    }

    return (
        <Box className='BuilderContainer'>
            <Box className='BuilderSelectorContainer'>
                {title ? <Box className='options-menu-title'>{title}</Box> : null}
                <Box className='BuilderSelectorBox'>
                    {Object.entries(getOptions).map(([key, value]) => (
                        <>
                            {value?.permissions 
                                ? <Button
                                    variant={key === selectedTab ? 'contained' : ''}
                                    onClick={() => changeTab(key)}>
                                    {value?.label} 
                                    {value?.icon &&
                                        value.icon
                                    }
                                </Button> 
                                : null
                            }
                        </>
                    ))}
                </Box>
            </Box>
            <Box className='BuilderContent'>
                {getOptions[selectedTab]?.permissions ? getOptions[selectedTab]?.component : null}
            </Box>
        </Box>
    )
}

export default OptionsMenu