import React from 'react';

import Frame from '../Frame';

const FrameAgents = ({ gridArea, agents }) => {
  return (
    <Frame gridArea={gridArea} title="Operadores">
      {agents}
    </Frame>
  );
}

export default FrameAgents;
