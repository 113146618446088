import React, {useContext, useState, useEffect} from 'react'

import {
    Box, Dialog, DialogActions, DialogContent, DialogTitle,
    Tooltip, Button, Divider, Select, MenuItem, InputLabel, FormControl, Chip, Switch, FormControlLabel
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import useNotification from '../../../Notification';
import api from '../../../../api';
import CancelIcon from '@mui/icons-material/Cancel';
import { constructorContext } from '../../Builder';

const MCDUBuilderModal = ({data, mcdus, updateData, handleClose}) => {
    const { enterpriseId, setLoading } = useContext(constructorContext)
    const [isTransferBot, setIsTransferBot] = useState(mcdus.find(each => each.mcdu === "ForceTransfer" && each.builder_id === data.id) !== undefined)

    const [mcdu, setMCDU] = useState([])

    useEffect(()=>{
        setMCDU(mcdus.filter(each => each.builder_id === data.id))
    }, [])

    const submit = async () => {
        let res = null
        if (!data.publicId){
            useNotification(
                'Ops!',
                `O Bot não está publicado.`,
                'danger'
            )
            return
        }
        const url = `api/enterprises/${enterpriseId}/ura/chat/builder/${data.id}/`

        const body = {
            mcdu: mcdu,
            isTransferBot: isTransferBot ? 1 : 0
        }

        setLoading(true)
            res = await api.patch(url, body, { timeout: 0 })
        setLoading(false)

        if (!(res.status === 200 || res.status === 201)) {
            useNotification(
                'Ops!',
                `Não foi possível alterar o bot.`,
                'danger'
            )
            return
        }
        updateData()
        useNotification(
            'Sucesso!',
            `Bot atualizado com sucesso.`,
            'success'
        )


    }

    return (
        <>
        <Dialog open={true}
                fullWidth
                disablePortal
                disableEnforceFocus
                sx={{ position: 'absolute' }}
            >
                <DialogTitle
                    id="customized-dialog-title" className={'group-flexbox'}>
                    <span>Editar Bot</span>
                    <Tooltip title="Fechar" >
                        <Button className="action-button"  >
                            <CloseIcon onClick={handleClose} />
                        </Button>
                    </Tooltip>
                </DialogTitle>
                <Divider />
                <DialogContent className='piloto-modal-flex'>

                    {/* <TextField
                        size='small'
                        label='Nome'
                        value={state.name}
                        onChange={(e) => setState({name:e.target.value})}
                        inputProps={{
                            maxLength:45
                        }}
                    /> */}
                    {data?.id &&

                        <>
                            <FormControl fullWidth>
                                <InputLabel size='small' id='select-on-pilot'>Canal</InputLabel>
                                <Select
                                    multiple={true}
                                    size='small'
                                    labelId='select-on-pilot'
                                    label='Canal'
                                    value={mcdu}
                                    onChange={(e) => setMCDU(e.target.value)}

                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {selected.filter(each => each.mcdu !== "ForceTransfer").map((value) => (
                                                <Chip
                                                    size='small'
                                                    key={value}
                                                    label={`${value.entrypoint} ${value?.mcdu ? value.mcdu : null}`}
                                                    deleteIcon={
                                                        <CancelIcon
                                                            onMouseDown={(event) => event.stopPropagation()}
                                                        />}
                                                    onDelete={() => setMCDU(mcdu.filter(each => each !== value))}
                                                />
                                            ))}
                                        </Box>
                                    )}

                                >
                                    { mcdus.filter(each=> each.entrypoint !== 'CHATWEB' || each.mcdu !== "ForceTransfer").map(obj =>
                                        <MenuItem
                                            className={mcdu.includes(obj) ? 'hide-from-list' : ''}
                                            value={obj}>
                                            {obj.entrypoint} {obj?.mcdu ? `(${obj.mcdu})` : null}
                                        </MenuItem>)

                                    }
                                </Select>
                            </FormControl>
                            <FormControl fullWidth sx={{display: 'flex', paddingLeft: '0.5rem', alignItems: 'flex-start'}}>
                                <FormControlLabel control={
                                    <Switch
                                        size='small'
                                        checked={isTransferBot == true}
                                        onChange={(e) => setIsTransferBot(e.target.checked ? true : false)}
                                    />
                                }
                                    label='Bot de Transferência'
                                />
                            </FormControl>
                        </>
                    }
                </DialogContent>
                <DialogActions style={{ padding: "10px 20px 10px 10px" }}>
                    <Button
                        onClick={handleClose}>
                        Cancelar
                    </Button>
                        <span>
                            <Button
                                onClick={submit}>
                                Salvar
                            </Button>
                        </span>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default MCDUBuilderModal