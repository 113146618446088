import React, { useEffect, useReducer, useState } from 'react'
import defaultReducer from '../../utils/defaultReducer'

import useNotification from '../Notification';

import Loading from '../Loading';
import LoadingButton from '@mui/lab/LoadingButton'

import {
    Box,
} from '@mui/material';

import {
    DataGridPro,
    ptBR,
} from '@mui/x-data-grid-pro';
import { ptBR as corePtBR } from '@mui/material/locale';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import api from '../../api';
import { getSession } from '../../auth';
import { buildAutomationColumns } from './columns';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import ConfirmationDialogCaptcha from '../Dialogs/ConfimationDialogCaptcha';
import EditAutomation from './editautomation';
import { BuilderModalDialog } from '../Settings/Vivre/Vivremenu';
import dayjs from 'dayjs';
import AutomationHistoric from './historic';

import './styles.css'

const theme = createTheme({
    palette: {
        primary: { main: '#21446C' },
    },
}, ptBR, corePtBR);

const enterpriseId = getSession()?.profile?.enterprise.id

const loadData = async (setState) => {
    setState({ loading: true })
    const builder = api.get('/api/getBuilder/')
    const automation = api.get(`/api/enterprises/${enterpriseId}/automation/`)
    const [builderres, automationres] = await Promise.all([builder, automation])
    const result = { loading: false }
    if (builderres.status === 200) {
        result.builder = builderres.data
    } else {
        useNotification(
            'warning',
            'O Builder está offline',
            'danger'
        )
    }
    if (automationres.status === 200) {
        result.automation = automationres.data
    } else {
        useNotification(
            'warning',
            'Automações não encontradas',
            'danger'
        )
    }

    setState(result)
}

const deleteAutomation = async (data, setState, loadData) => {
    setState({ loading: true })
    const res = await api.delete(`api/enterprises/${enterpriseId}/automation/${data.id}/`, { timeout: 10000 })
    setState({ loading: false })
    if (res.status === 204) {
        useNotification(
            'Sucesso!',
            'Automação excluída com sucesso',
            'success'
        )
        setState({ delete: null })
        loadData(setState)
        return
    }
    useNotification(
        'warning',
        'Automação não pôde ser excluída',
        'danger'
    )
}


const Automation = () => {

    const [state, setState] = useReducer(defaultReducer, {
        builder: {},
        automation: [],
        edit: null,
        delete: null,
        showbot: null,
        loading: false,
        showHistoric: false,
        time: dayjs()
    })

    useEffect(() => {
        loadData(setState)
    }, [])

    return (
        <>
            {state.builder &&
                <Box sx={{ overflow: "hidden", position: 'fixed', left: '-999rem' }}>
                    <iframe id="builder" src={state.builder.embed_url} type="text/html"  ></iframe>
                </Box>
            }
            {state.loading ? <Loading loading={true} /> : null}
            {state.showbot && <BuilderModalDialog
                data={state.showbot.public_id}
                builderData={state.builder}
                turnOffFrame={() => setState({ loading: false })}
                onClose={() => { setState({ showbot: false }); loadData(setState) }}

            />}
            {state.showHistoric && <AutomationHistoric data={state.showHistoric} parent={state} setParent={setState} reloadData={() => loadData(setState)} />}
            {state.edit && <EditAutomation data={state.edit} parent={state} setParent={setState} reloadData={() => loadData(setState)} />}
            {state.delete && <ConfirmationDialogCaptcha
                open={true}
                title="Confirmação"
                description={
                    <>
                        <Box>
                            Concorda em excluir a automação {state.delete.name}?
                        </Box>
                        <Box>
                            Para confirmar a ação, digite: <span style={{ 'fontWeight': 'bold' }}>remover</span> no campo abaixo.
                        </Box>
                    </>
                }
                handleClose={() => { setState({ delete: null }) }}
                handleSubmit={() => { deleteAutomation(state.delete, setState, loadData) }}
                captcha={'remover'}

            />}
            <Box className="screen-wrapper">
                <Box className="outer-Screen">
                    <Box className="inner-Screen">
                        <LoadingButton
                            className="button-notify nomargin"
                            onClick={() => { setState({ edit: {
                                schedule_day_of_month: "*/1",
                            } }) }}
                            variant={"contained"}
                            color="warning"
                            startIcon={<FactCheckIcon />}
                        >
                            Nova automação
                        </LoadingButton>
                    </Box>

                </Box>
                <Box className="table-screen">
                    <ThemeProvider theme={theme}>
                        <DataGridPro
                            columns={buildAutomationColumns(state, setState)}
                            rows={state.automation}
                            disableSelectionOnClick
                            density={'compact'}
                        />
                    </ThemeProvider>
                </Box>
            </Box>
        </>
    )
}

export default Automation