import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

import { 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Box, 
  Switch, 
  Dialog, 
  Slide, 
  Tooltip,  
  Button,
  Divider
} from "@mui/material";

class FastFiltersDialog extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      normal    : true,
      timeout   : true,
      weekend   : true,
      chatbot   : true,
      checktime : true
    }
  }

  render() {
    return (
      <Box>
        <Dialog
          open={this.props.open}
          keepMounted
          onClose={this.props.handleClose}
          aria-describedby="alert-dialog-slide-description"
          fullWidth
          maxWidth="sm"
          disablePortal
          disableEnforceFocus
          sx={{ position:'absolute' }}
        >
          <DialogTitle id="customized-dialog-title" className={'group-flexbox'}>
              <span>Filtros Rápidos</span>
              <Tooltip title="Fechar" >
                  <Button className="action-button">
                      <CloseIcon onClick={this.props.handleClose} />
                  </Button>
              </Tooltip>
          </DialogTitle>
          <Divider />
          <DialogContent>
            <Box>
              <Switch
                checked={this.state.normal}
                inputProps={{ 'aria-label': 'controlled' }}
                onChange={() => this.setState({ normal: ! this.state.normal })} 
              />
              Atendimento normal
            </Box>
            <Box>
              <Switch
                checked={this.state.timeout}
                inputProps={{ 'aria-label': 'controlled' }}
                onChange={() => this.setState({ timeout: ! this.state.timeout })} 
              />
              Desligada por “timeout”
            </Box>
            <Box>
              <Switch
                checked={this.state.weekend}
                inputProps={{ 'aria-label': 'controlled' }}
                onChange={() => this.setState({ weekend: ! this.state.weekend })} 
              />
              Desligada por “timeout” (final de semana)
            </Box>
            <Box>
              <Switch
                checked={this.state.chatbot}
                inputProps={{ 'aria-label': 'controlled' }}
                onChange={() => this.setState({ chatbot: ! this.state.chatbot })} 
              />
              Retida no “chatbot” (sem atendimento humano)
            </Box>
            <Box>
              <Switch
                checked={this.state.checktime}
                inputProps={{ 'aria-label': 'controlled' }}
                onChange={() => this.setState({ checktime: ! this.state.checktime })} 
              />
              Fora do horário de atendimento
            </Box>
          </DialogContent>
          <DialogActions sx={{ padding: "0px 20px 10px 0px" }}>
            <Button onClick={this.props.handleClose}>Cancelar</Button>
            <Button onClick={() => this.props.filterTableRows(this.state)}>Aplicar</Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  }
}

export default FastFiltersDialog;
