import React from 'react';
import { Pie } from "react-chartjs-2";
import { Box } from "@mui/material"


class OperatorsGraph extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            data: {
                labels: this.props.labels,
                borderWidth: 0,
                datasets: [
                    {
                        label: this.props.labels,
                        data: this.props.data,
                        backgroundColor: this.props.bgColor,
                        borderColor: '#f1f1f1',
                        borderWidth: 0
                    },
                ]
            }
        }
    }

    render(){
        return (
            <Pie
                height="50%"
                data={this.state.data}
                options={{
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: true,
                            position: 'right',
                            align: 'center',
                            textAlign: 'right',

                        },
                    }
                }
                }
            />
        );
    }
}

export default OperatorsGraph;
