import React from "react";
import { Grid, TextField, Box } from "@mui/material";
import HeaderForm from "../../HeaderForm"
import GroupImage from "../../../../assets/images/group_chat.svg"
import Typography from "../../../Typography"
import InlineButton from "../../../Button/InlineButton"
import PropTypes from "prop-types";
import RemoveButton from "../../../Button/RemoveButton";

const GroupForm = ({ values, handleChange, setFieldValue }) => {

    function addNewGroup() {
        if (values.groups.length < 3) {
            const groups = Array.from(values.groups);
            groups.push({
                number: "",
                description: "",
                checked: false
            });
            setFieldValue("groups", groups);
        }
    }

    function removeGroup(index) {
        const groups = Array.from(values.groups);
        groups.splice(index, 1);
        setFieldValue("groups", groups);
    }


    return (
        <Box >
            <HeaderForm img={GroupImage} title="PERSONALIZE SEU ATENDIMENTO" subtitle="Grupos de atendimento" width="80%" />
            <Grid item lg={11} md={11} sm={12} xs={12}>
                <Box paddingBottom={3}>
                    <Typography color="darkGray" text="Aqui você pode criar grupos para o seu atendimento, preencha abaixo um código de identificação  e uma descrição para cada um dos grupos que deseja criar. Será possível criar mais grupos adiante. " />
                </Box>
            </Grid>
            {values.groups.map((group, i) => (
                <Box key={i} paddingBottom={2}>
                    <Grid container spacing={2} key={i}>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <TextField
                                id=""
                                name={"groups[" + i + "].number"}
                                label="Código do grupo"
                                variant="outlined"
                                value={group.number}
                                onChange={handleChange}
                                size="small"
                                fullWidth
                                required={i === 0}
                            />
                        </Grid>
                        <Grid item lg={7} md={7} sm={12} xs={12}>
                            <TextField
                                id=""
                                name={"groups[" + i + "].description"}
                                label="Descrição do grupo"
                                variant="outlined"
                                onChange={handleChange}
                                value={group.description}
                                size="small"
                                fullWidth
                            />
                        </Grid>
                        <Grid item lg={1} md={1} sm={12} xs={12}>
                            {i > 0 && (<Box paddingTop={1.2}><RemoveButton onClick={() => removeGroup(i)} /></Box>)}
                        </Grid>
                    </Grid>
                </Box>))}
            {values.groups.length < 3 && (
                <Grid container spacing={2}>
                    <Grid item lg={8} md={8} sm={12} xs={12}></Grid>
                    <Grid item lg={3} md={3} sm={12} xs={12}>
                        <InlineButton text="+ Adicionar grupo" onClick={() => addNewGroup()} />
                    </Grid>
                    <Grid item lg={1} md={1} sm={12} xs={12}></Grid>
                </Grid >)}

        </Box >
    );
};

GroupForm.propTypes = {
    values: PropTypes.object,
    handleChange: PropTypes.func,
    setFieldValue: PropTypes.func

}

export default GroupForm;
