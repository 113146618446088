import React, { useRef, useState, useEffect } from 'react';

import { IconButton, Menu, Box } from '@mui/material';

import ChatIcon from '@mui/icons-material/Chat';

import CryptoJS from 'crypto-js';

import InternalChat from '../../InternalChat';

import useNotification from '../../Notification';
import api from '../../../api';

import { getSession } from "../../../auth";

import './index.css'

const InternalChatMenu = () => {

    const user = getSession();

    const iconButton = useRef(null);

    const [anchorEl, setAnchorEl] = useState(null);
    const [socket, setSocket] = useState(null);
    const [channel, setChannel] = useState(null);
    const [messages, setMessages] = useState([]);
    const [showCircle, setShowCircle] = useState(false);
    const [lastSocketResponse, setLastSocketResponse] = useState(null);

    const anchorElRef = useRef(null);
    anchorElRef.current = anchorEl;

    const lastSocketResponseRef = useRef(null);
    lastSocketResponseRef.current = lastSocketResponse;
    
    const reAnchor = async () => {
        handleClose();
        await new Promise(r => setTimeout(r, 500))
        handleClick()
    }

    const handleClick = () => {
        setAnchorEl(iconButton.current)
        window.setLink = false
        setShowCircle(false)
    };

    const handleClose = () => {  
        setAnchorEl();
    };

    const connectInternalChatSocket = (audio) => {
        const hostname = window.location.hostname
        const accountcode = user?.profile.enterprise.accountcode
        const channel = CryptoJS.MD5(accountcode).toString()

        setChannel(channel)
        
        const s = new WebSocket(`wss://${hostname}/chat/ws/chat/${channel}/`)

        let keepAlive = null

        s.onopen = (e) => {
            keepAlive = setInterval(() => {
                // If server not responding for more than 30 seconds
                // the connection might have been closed somewhere
                // we need to close and reopen a new websocket connection
                let curTime = new Date().getTime();
                if (lastSocketResponseRef && lastSocketResponseRef.current && curTime - lastSocketResponseRef.current > 20000) {
                    s.close()
                    connectInternalChatSocket(audio)
                    return;
                }

                // Pinging server every 20secs
                s.send(JSON.stringify({
                    user: '0',
                    message: 'PING'
                }))
            }, 20000)
        }

        s.onmessage = (e) => {
            const message = JSON.parse(e.data)

            setLastSocketResponse(new Date().getTime())

            if (message.message == 'PONG')
                return

            const curDate = new Date();
            const [hour, minutes] = curDate.toTimeString().split(':', 2)

            message['iso_time'] = hour + ':' + minutes

            if (! anchorElRef || ! anchorElRef.current) {
                setShowCircle(true)
                audio.play()
            }

            setMessages((messages) => [...messages, message])
        }

        s.onclose = (e) => {
            clearInterval(keepAlive);
            setLastSocketResponse(null);
        }

        setSocket(s)
    }

    const getChatMessages = async () => {
        const enterprise = user?.profile.enterprise
        const enterpriseId = enterprise.id
        const channel = CryptoJS.MD5(enterprise.accountcode).toString()

        const res = await api.get(`/api/enterprises/${enterpriseId}/chat_messages/channel/${channel}/?limit=100&offset=0`)

        if (res.status !== 200) {
            useNotification(
                'Ops!',
                'Não foi possível recuperar as mensagens do chat interno.',
                'danger'
            )
            return
        }

        setMessages(res.data.results.reverse())
    }

    useEffect(() => {

        if (! user?.profile.enterprise.has_internal_chat)
            return

        const audio = new Audio(`${window.location.origin}/new_message.mp3`)

        getChatMessages()

        if (! socket) {
            connectInternalChatSocket(audio)
        }
        
        return () => {
            if (socket) {
                socket.close()
                setSocket(null)
            }
        }
    }, [])

    if (user?.profile.enterprise.has_internal_chat) {
        return (
            <div className="internal-chat-menu-container">
                <IconButton id="internal-chat-icon" 
                    ref={iconButton} 
                    onClick={handleClick}>
                    <ChatIcon className="toolbar-chat-icon" />
                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    sx={{zIndex:2001}}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    elevation={0}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                    className="internal-chat-portal-container"
                >
                    <InternalChat 
                        reAnchor={() => reAnchor()}
                        handleClose={() => handleClose()}
                        socket={socket}
                        channel={channel}
                        messages={messages}
                    />
                </Menu>
                {showCircle &&
                    <Box className="notification-circle"></Box>
                }
            </div>
        );
    } else {
        return (
            <></>
        );
    }
}

export default InternalChatMenu;
