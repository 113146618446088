import React, { useEffect, useReducer, useState } from 'react'
import { Box, TextField, Switch, FormControlLabel } from '@mui/material'
import { lockedReducer } from '../../../../utils/defaultReducer'
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import api from '../../../../api';
import { getSession } from '../../../../auth';
import useNotification from '../../../Notification';
import Loading from '../../../Loading';



const Operators = () => {

    const enterpriseId = getSession()?.profile?.enterprise?.id

    const [loading, setLoading] = useState(false)
    const [patchLoading, setPatchLoading] = useState(false)

    const [state, setState] = useReducer(lockedReducer, {
        agent_can_update_contact: false,
        agent_can_create_segments: false,
        agent_can_create_campaigns: false,

    })

    const loadData = async () => {
        setLoading(true)
        const res = await api.get(`/api/enterprises/${enterpriseId}/`)
        setLoading(false)
        if (res.status === 200) {
            setState(res.data.settings)
            return
        }

        useNotification(
            'Ops!',
            'Informações não carregadas',
            'danger'
        );
    }

    useEffect(() => {
        loadData()
    }, [])

    const submit = async (data) => {

        setPatchLoading(true)
        const res = await api.patch(`/api/enterprises/${enterpriseId}/`, { settings: data })
        setPatchLoading(false)
        if (res.status === 200) {
            useNotification(
                'Sucesso!',
                'Configurações do operador alterados com sucesso.',
                'success'
            );
            return
        }
        useNotification(
            'Ops!',
            'Ocorreu um erro no sistema',
            'danger'
        );

    }

    return (
        <>
            <Loading loading={loading} />
            <Box className="input-settings" sx={{ width: 'fit-content' }}>
                <Box className="category-title push-bottom">
                    Operador
                </Box>
                <Box className="flexbox" sx={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}>
                    <FormControlLabel
                        label="Operador pode atualizar dados do cliente"
                        control={
                            <Switch
                                checked={state.agent_can_update_contact}
                                onChange={() => setState({ agent_can_update_contact: !state.agent_can_update_contact })}
                            />
                        }
                    />
                    <FormControlLabel
                        label="Operador pode criar/editar segmentos"
                        control={
                            <Switch
                                checked={state.agent_can_create_segments}
                                onChange={() => setState({ agent_can_create_segments: !state.agent_can_create_segments })}
                            />
                        }
                    />
                    <FormControlLabel
                        label="Operador pode criar/editar campanhas"
                        control={
                            <Switch
                                checked={state.agent_can_create_campaigns}
                                onChange={() => setState({ agent_can_create_campaigns: !state.agent_can_create_campaigns })}
                            />
                        }
                    />
                    <LoadingButton
                        style={{ width: 'fit-content' }}
                        onClick={() => submit(state)}
                        loading={patchLoading}
                        loadingPosition="start"
                        startIcon={<SaveIcon />}
                        variant="contained"
                    >
                        Salvar
                    </LoadingButton>
                </Box>
            </Box>
        </>
    )
}


export default Operators