import React, { useState } from 'react'

import {
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Divider,
    Box
} from '@mui/material'

import LoadingButton from '@mui/lab/LoadingButton'

import CloseIcon from '@mui/icons-material/Close'

import './styles.css'


export default function AddBotDialog({ open, handleClose, submit }) {

    const [name, setName] = useState();
    const [username, setUsername] = useState();
    const [token, setToken] = useState()

    return (
        <div>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}
                disablePortal
                disableEnforceFocus
                sx={{ position: 'absolute' }}
            >
                <DialogTitle className="group-flexbox">
                    Adicionar Bot Telegram
                    <Button sx={{ 'minWidth': '2rem', 'padding': '0' }} onClick={handleClose}>
                        <CloseIcon/>
                    </Button>
                </DialogTitle>
                <Divider/>
                <DialogContent className="add-bot-dialog-content">
                    <TextField
                        size="small"
                        label="Nome"
                        fullWidth
                        type="text"
                        variant="outlined"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <TextField
                        size="small"
                        label="Username"
                        fullWidth
                        type="text"
                        variant="outlined"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <TextField
                        size="small"
                        label="Token"
                        fullWidth
                        type="text"
                        variant="outlined"
                        value={token}
                        onChange={(e) => setToken(e.target.value)}
                    />
                    <Box className="user-footer">
                        <LoadingButton className="button-created" onClick={handleClose}>
                            Cancelar
                        </LoadingButton>
                        <LoadingButton className="button-created"
                            onClick={() => submit(name, username, token)}
                            disabled={! name || ! username || ! token}
                        >
                            Salvar
                        </LoadingButton>
                    </Box>
                </DialogContent>
            </Dialog>
        </div>
    );
}
