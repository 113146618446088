import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import PropTypes from "prop-types";
import { Divider } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class ConfirmDeletion extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>

                <Dialog
                    open={this.props.open}
                    //TransitionComponent={Transition}
                    keepMounted
                    onClose={this.props.handleClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                    disablePortal
                    disableEnforceFocus
                    sx={{position:'absolute'}}
                >
                    <DialogTitle id="alert-dialog-slide-title">{this.props.title}</DialogTitle>
                    <Divider />
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            {this.props.description}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={this.props.handleClose}
                            color="primary">
                            Não
                        </Button>
                        <Button
                            onClick={this.props.handleSubmit}
                            color="primary">
                            Sim
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default ConfirmDeletion;