import React, { useEffect, useMemo } from 'react'
import { ListItem, Box, ListItemButton, Divider } from "@mui/material"
import useVisibility from "../../../utils/isVisible"
import { CircularProgress } from "@mui/material"
import ConversationItem from "../../Chat/ConversationList/ConversationItem"


const FinishedRow = ({ parent, isVisibleHook = () => { }, shouldAlwaysCheck = false, data, onClickItem, activeChat, isManageCalls, style }) => {
    const [isVisible, currentElement, calcVisibility] = useVisibility(100, 100, parent)

    useEffect(() => {
        //isVisibleHook(isVisible)
        calcVisibility()
    }, [])

    if (shouldAlwaysCheck) {
        useEffect(() => {
            isVisibleHook(isVisible)
        }, [isVisible])
    }

    const active = activeChat?.uniqueid === data.uniqueid

    const memoItem = useMemo(() => {
        return (
            <ConversationItem
                key={`${data.id}-conversation-item`}
                onClick={() => { onClickItem(data); }}
                showProgressTime={false}
                activeId={activeChat?.uniqueid}
                conversation={data}
                isManageCalls={isManageCalls ? true : false}
                setReadMessages={() => { }}
                forceActive={active}
            />
        )
    }, [data, active])

    return (
        < ListItem component="div" disablePadding ref={currentElement} style={style}>
            <ListItemButton sx={{ padding: 0 }}>
                <Box key={`${data.id}-key-on-queue`} bgcolor='' sx={{ width: "100%", height: "100%" }}>
                    {(isVisible && data.id > 0) ?
                        <>
                           { memoItem }
                        </>
                        :
                        <Box className={'loader-skeleton'}
                            sx={{ height: '100px', justifyContent: "center" }}
                        >
                            <CircularProgress />
                        </Box>
                    }
                    <Divider />
                </Box>
            </ListItemButton >
        </ListItem>

    )
}

export default FinishedRow