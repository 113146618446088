import React from "react";
import MaterialButton from "@mui/material/Button";
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from "prop-types";

const Button = ({ title, color, onClick }) => {

    function renderBackgroundColor() {
        if (color === "primary")
            return "#21446C"
        if (color === "secondary")
            return "#DFB160"
        if (color === "white")
            return "white"
    }

    function rendercColor() {
        if (color === "primary")
            return "white"
        if (color === "secondary")
            return "#263238"
        if (color === "white")
            return "#263238"
    }


    const useStyles = makeStyles(() => ({
        button: {
            backgroundColor: renderBackgroundColor(),
            color: rendercColor(),
            width: "100%",
            height: "3vw",
            marginTop: "1vw",
            fontSize: "1.2vw",
            '&:hover': {
                backgroundColor: 'gray',
            },
        },
    }));

    const classes = useStyles();

    return (<MaterialButton fullWidth onClick={onClick} className={classes.button}>{title}</MaterialButton>)
}

Button.propTypes = {
    title: PropTypes.string,
    color: PropTypes.string,
    onClick: PropTypes.func
};

Button.defaultProps = {
    color: "primary"
}

export default Button;