import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from "prop-types";
import AddCircleIcon from '@mui/icons-material/AddCircle';


const AddButton = ({ onClick }) => {

    const useStyles = makeStyles(() => ({
        button: {
            color: "#21446C",
            cursor: "pointer",
            '&:hover': {
                color: '#DFB160',
            },
        },
    }));

    const classes = useStyles();

    return (<AddCircleIcon onClick={onClick} className={classes.button} color="primary" />
    )
}

AddButton.propTypes = {
    onClick: PropTypes.func
};

export default AddButton;