import React, { useState, useEffect, useReducer } from 'react'

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import { FormControlLabel, Switch } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import {lockedReducer} from '../../../utils/defaultReducer'

import './index.css';

export const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
};


const periods = {
    'D': 'Diariamente',
    'W': 'Semanalmente',
    'M': 'Mensalmente'
};

const possibleDays = Array.from({ length: 31 }, (item, index) => (index + 1).toString());

const weekdays = {
    1:'Domingo',
    2:'Segunda-feira',
    3:'Terça-feira',
    4:'Quarta-feira',
    5:'Quinta-feira',
    6:'Sexta-feira',
    7:'Sábado'
};

export default function AutomatedReportDialog({
    open,
    handleClose,
    handleSubmit,
    filters,
    selectedRow
}) {
    const [email, setEmail] = useState('');
    const [isValidEmail, setIsValidEmail] = useState(true);

    const handleEmailChange = (event) => {
        const inputEmail = event.target.value;
        setEmail(inputEmail);
        setIsValidEmail(validateEmail(inputEmail));
    };

    const [state, setState] = useReducer(lockedReducer, {
        hour: selectedRow?.hour + ':' || '' + selectedRow?.minutes || '',
        reportType: selectedRow?.is_xlsx || false ? 'xlsx' : 'csv',
        selectedDay: selectedRow?.days || 1,
        selectedWeekDay: selectedRow?.week_days || 1,
        recipients: selectedRow?.recipients || '',
        period: selectedRow?.period || 'today',
        id: selectedRow?.id || null
    })

    
    const checkFormData = () => {
        if (state.hour === undefined || state.hour.length != 5) return false
        if (!isValidEmail) return false
        if (email.length === 0) return false
        state.recipients = email
        return true
    }
    const [active, setActive] = useState(true)

    const translateFilter = {
        colunsFiels: {
            uniqueid: 'Identificador',
            status: 'Status',
            survey_duration: 'Duração da pesquisa',
            messages: 'Mensagens',
        },
        operatorValues: {
            is: 'é',
            equals: 'igual a',
            notEquals: 'diferente de',
            greaterThan: 'maior que',
            greaterThanOrEqual: 'maior ou igual a',
            lessThan: 'menor que',
            lessThanOrEqual: 'menor ou igual a',
            inRange: 'entre',
            contains: 'contém',
            notContains: 'não contém',
            startsWith: 'começa com',
            endsWith: 'termina com',
            isBlank: 'está em branco',
            isNotBlank: 'não está em branco',
        }

    }


    return (
        <Dialog open={open} onClose={handleClose} fullWidth
            disablePortal
            disableEnforceFocus
            sx={{ position: 'absolute' }}>
            <DialogTitle>{filters ? 'Agendar' : 'Editar'} Envio de Relatório</DialogTitle>
            <Divider />
            <DialogContent>
                {filters ?
                    <>
                        <Typography fontSize="19px" fontWeight="500" color="#21446c">
                            Filtros
                        </Typography>

                        {filters.map((each) => (
                            <Box sx={{ mt: 1, mb: .5, ml: 3 }}>
                                {each.columnField} {each.operatorValue} {each.value}
                            </Box>
                        ))}
                        {filters.length == 0 &&
                            <Box sx={{ mt: 1, mb: 1, ml: 3 }}>
                                Sem filtros.
                            </Box>
                        }
                    </>
                    :
                    <>
                        <Box sx={{ mt: 1, mb: 1, ml: 1.5 }}>
                            <FormControlLabel
                                sx={{ flexDirection: "row-reverse" }}
                                label="Ativo"
                                control={
                                    <Switch
                                        checked={active}
                                        onChange={(e) => setActive(p => !p)}
                                    />
                                }
                            />
                        </Box>
                    </>
                }
                <Typography fontSize="19px" fontWeight="500" color="#21446c"
                    sx={{ mt: 2 }}
                >
                    Formato do relatório
                </Typography>
                <div className={"dateSelectorDiv"} style={{ gap: '0.5rem' }}>
                    <FormControl size='small' sx={{ mt: 2, mb: 2, flex: 0.4 }}>
                        <InputLabel id="period-label-format">Formato</InputLabel>
                        <Select
                            labelId="period-label-format"
                            label="Formato"
                            value={state.reportType}
                            onChange={(event) => setState({reportType:event.target.value})}>
                            {['csv', 'xlsx'].map((item) => (
                                <MenuItem id={item} value={item}>
                                    {item}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <Typography fontSize="19px" fontWeight="500" color="#21446c">
                    Destinatários
                </Typography>
                <TextField
                    size='small'
                    label="Email(s)"
                    variant="outlined"
                    fullWidth
                    sx={{ mt: 2, mb: 2 }}
                    value={email}
                    onChange={handleEmailChange}
                    error={!isValidEmail}
                    helperText={!isValidEmail && "Por favor, insira um e-mail válido"}
                    inputProps={{
                        maxLength: 100
                    }}
                    
                />
                <Typography fontSize="19px" fontWeight="500" color="#21446c">
                    Periodicidade
                </Typography>
                <div className={"dateSelectorDiv"}>
                    <FormControl size='small' sx={{ mt: 2, minWidth: 250, flex: 1 }}>
                        <InputLabel id="select-period">
                            Quando?
                        </InputLabel>
                        <Select
                            labelId="select-period"
                            id="simple-select-period"
                            label="Quando?"
                            value={state.period}
                            onChange={(e) => setState({period:e.target.value})}
                        >
                            {Object.entries(periods).map(([key, value]) => (
                                <MenuItem id={key} value={key}>
                                    {value}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl size='small' sx={{ mt: 2, ml: 1, minWidth: 100, flex: 0.4 }}>
                        <TextField
                            size='small'
                            id="dateHour"
                            label="Hora"
                            type="time"
                            variant="outlined"
                            value={state.hour}
                            onChange={(event) => setState({hour:event.target.value})}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </FormControl>
                    {state.period === 'W' &&
                        <>
                            <FormControl size='small' className='monthPicker' sx={{ mt: 2, minWidth: 200, flex: 1 }}>
                                <InputLabel id="select-weekdays">Semanalmente</InputLabel>
                                <Select
                                    labelId="select-weekdays"
                                    id="simple-select-weekdays"
                                    label="Dia(s) da semana"
                                    value={state.selectedWeekDay}
                                    onChange={(e) => setState({selectedWeekDay: e.target.value})}
                                >
                                    {Object.entries(weekdays).map(([key, value]) => (
                                        <MenuItem
                                            key={`weekday${key}`}
                                            value={key}>
                                            {value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </>
                    }
                    {state.period == 'M' &&
                        <>
                            <FormControl size='small' sx={{ mt: 2, ml: 1, minWidth: 100, flex: 0.4 }}>
                                <InputLabel id="select-days">Dia</InputLabel>
                                <Select
                                    labelId="select-days"
                                    id="simple-select-days"
                                    label="Grupo"
                                    value={state.selectedDay}
                                    onChange={(e) => setState({selectedDay: e.target.value})}
                                    multiple={false}
                                >
                                    {possibleDays.map((each) => (
                                        <MenuItem key={`daylist${each}`} value={each}>
                                            {each}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </>
                    }
                </div>
            </DialogContent>
            <DialogActions sx={{ padding: "0px 20px 10px 0px" }}>
                <Button onClick={handleClose}>Cancelar</Button>
                <Button disabled={!checkFormData()}
                    onClick={() => handleSubmit(state)}
                >
                    {filters ? 'Agendar' : 'Editar'}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
