import React, { useState } from 'react'

import { Box, Dialog, DialogTitle, DialogActions, DialogContent, Divider, Typography, TextField, Button } from '@mui/material'

const SendReportDialog = ({ type, handleClose, submit }) => {

    const [email, setEmail] = useState('');
    const [isValidEmail, setIsValidEmail] = useState(true);

    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const handleEmailChange = (event) => {
        const inputEmail = event.target.value;
        setEmail(inputEmail);
        setIsValidEmail(validateEmail(inputEmail));
    };

    const handleSubmit = () => {
        if (isValidEmail) {
            submit(email);
        }
    };

    return (
        <>
            <Dialog
                open={true}
                disablePortal
                disableEnforceFocus
                sx={{ position: 'absolute' }}
            >
                <DialogTitle>Geração de relatório em {type}</DialogTitle>
                <Divider />
                <DialogContent>
                    <Typography fontSize="12px" fontWeight="500" color="#21446c">
                        O relatório será enviado por email, para isso, digite-o abaixo.
                    </Typography>
                    <TextField
                        size='small'
                        label="Email"
                        variant="outlined"
                        fullWidth
                        sx={{ mt: 2, mb: 2 }}
                        value={email}
                        onChange={handleEmailChange}
                        error={!isValidEmail}
                        helperText={!isValidEmail && "Por favor, insira um e-mail válido"}
                        inputProps={{
                            maxLength: 100
                        }}
                    />
                </DialogContent>
                <DialogActions sx={{ padding: "0px 20px 10px 0px" }}>
                    <Button onClick={handleClose}>Cancelar</Button>
                    <Button disabled={email.length === 0 || !isValidEmail} onClick={handleSubmit}>
                        Enviar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )

}

export default SendReportDialog