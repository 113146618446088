import React from 'react'

import { Box, Dialog, Tooltip, Button, DialogTitle, DialogContent, List, ListItem, ListItemButton, Divider } from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'
import { formatRowDateTime } from './contact_graph_exception'

const TagsDialog = ({ parent, data, handleClose }) => {

    return (
        <Box>
            <Dialog
                open={true}
                keepMounted
                onClose={() => { }}
                aria-describedby="alert-dialog-slide-description"
                fullWidth
                maxWidth="sm"
                disablePortal
                disableEnforceFocus
                sx={{ position: 'absolute' }}
            >

                <DialogTitle className={'group-flexbox'}>
                    <span>Visualizar tags - {parent.row.uniqueid}</span>
                    <Tooltip title="Fechar" >
                        <Button className="action-button"  >
                            <CloseIcon onClick={handleClose} />
                        </Button>
                    </Tooltip>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <List className='builder-tag-list'>
                        {data.length > 0 ? data.map(each => <ListItem>
                            <ListItemButton>
                                <Box sx={{flex: 1}}>
                                    {each.name}
                                </Box>
                                <Box sx={{flex: 1, textAlignLast: 'end'}}>
                                    {formatRowDateTime({row: each})}
                                </Box>
                            </ListItemButton>
                        </ListItem>)
                        :
                        parent.value?.split(',').map(each => <ListItem>
                            <ListItemButton>
                                <Box sx={{flex: 1}}>
                                    {each}
                                </Box>
                            </ListItemButton>
                        </ListItem>)
                        }
                    </List>
                </DialogContent>
            </Dialog>
        </Box>
    )

}

export default TagsDialog