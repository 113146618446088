import React from 'react'

import {Box, Chip} from '@mui/material'



const DetailedRegisterFilters = ({parent, setParent}) => {
    return (
        <>
        {Object.entries(parent.filters).map(([key, value]) => (
            <Chip id="report-chip"
                label={value}
                onDelete={() => {}}
                deleteIcon={<DeleteIcon className="report-deleteIcon" />}
            />
        ))}
        </>
    )
}

export default DetailedRegisterFilters