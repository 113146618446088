import React, { useState } from 'react';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
    TextField,
    Box
} from '@mui/material';

import PropTypes from "prop-types";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const CredentialsDialog = ({ 
    open, 
    title, 
    description, 
    handleClose, 
    handleSubmit 
}) => {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const submit = () => {
        handleSubmit(username, password);
    }

    return (
        <div>
            <Dialog
                open={open}
                //TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                disablePortal
                disableEnforceFocus
                sx={{position:'absolute'}}
            >
                <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <Box className="push-bottom">
                            {description}
                        </Box>
                        <TextField
                            size="small"
                            label="E-mail"
                            fullWidth
                            type="text"
                            variant="outlined"
                            value={username}
                            onChange={(event) => setUsername(event.target.value)}
                            className="push-bottom"
                        />
                        <TextField
                            size="small"
                            label="Senha"
                            fullWidth
                            type="password"
                            variant="outlined"
                            value={password}
                            onChange={(event) => setPassword(event.target.value)}
                        />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        color="primary">
                        Cancelar
                    </Button>
                    <Button
                        onClick={submit}
                        color="primary"
                        className="push-right"
                    >
                        Enviar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

CredentialsDialog.propTypes = {
    open: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string,
    handleClose: PropTypes.func,
    handleSubmit: PropTypes.func
};

export default CredentialsDialog;
