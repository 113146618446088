import React, { useState, useEffect } from "react";

import { Box, InputAdornment, TextField, Divider, List } from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";

import { ListItem, ListItemButton } from '@mui/material';

import '../styles.css';
import { getSession } from "../../../../auth";
import api from "../../../../api";


export default function LabelArea({ chat, chatLabels, allLabels, setChatLabels }) {


    const [filterValue, setFilterValue] = useState('')
    const [labelList, updateLabelList] = useState([])
    const [labelListFiltered, updateLabelListFiltered] = useState([])
    const [refresh, setRefresh] = useState(false)

    const reloadState = () => {setRefresh(p=>!p)}


    useEffect(()=>{
        if (filterValue != '')
            handleFilterChange(filterValue)
    }, [labelList])
    
    useEffect(() => {
        const newlabels = initializeLabelList()
        updateLabelList(newlabels)
        if (filterValue == '')
            updateLabelListFiltered(newlabels)
                    
    }, [refresh])

    const initializeLabelList = () => {
        const chatLabelsIds = chatLabels.map((chatLabel) => chatLabel.id)
        return allLabels.filter(label => !chatLabelsIds.includes(label.id))
    }


    const subsObj = (objOld, objNew) => {
        const parsed = {}
        objOld.map(each => { parsed[each.id] = each })
        const newobj = {}
        if (Array.isArray(objNew)) {
            objNew.map(each => { newobj[each.id] = each })
            return Object.values({ ...parsed, ...newobj })
        }
        newobj[objNew.id] = objNew
        return Object.values({ ...parsed, ...newobj })
    }

    const moveLabel = async (label, command) => {
        const user = getSession()
        const url = `api/enterprises/${user.profile.enterprise.id}/labelchat/move/`
        const data = {
            label:label.id,
            action:command,
            chatchannel:chat.hash ? chat.hash: chat.channel,
        }

        const res = await api.post(url,data)

        if(res.status == 201){
            const newChatLabels = subsObj(chatLabels, res.data)
            chat.labels = newChatLabels
            setChatLabels(newChatLabels)
        }
        else if (res.status == 202){
            const newChatLabels = chatLabels.filter(each => each.id != res.data.id)
            chat.labels = newChatLabels 
            setChatLabels(newChatLabels)   
        }
        reloadState()
        
        
    }

    const handleFilterChange = (value) => {
        setFilterValue(value)
        const newlabellist = labelList.filter((label) => {
            if (label.name && value)
                return label.name.toLowerCase().includes(value.toLowerCase())
            else if (value === "")
                return true
        })
        updateLabelListFiltered(newlabellist)
    }

    const renderChatLabel = (label, command) => {
        return (
            <ListItem
                disableGutters
                className="label-item"
                onClick={() => {moveLabel(label, command)}}
                sx={{background:`${label.color} !important`}}
            >
                <ListItemButton  disableGutters sx={{ padding: "4px", overflow: "hidden" }}>
                    <span>{label.name}</span>
                </ListItemButton>
            </ ListItem >
        )
    }

    const renderAllAvailableLabels = () => {
        return (
            <>
                {labelListFiltered.length == 0 ?
                    <Box key={'empty-list-nice-key'} sx={{ margin : "1rem" }}> Sem etiquetas encontradas. Solicite ao seu supervisor.</Box>
                    :
                    <List className='label-list'>
                        {labelListFiltered.map(each=>renderChatLabel(each, 'add'))}
                    </List>
                }
            </>
        )
    }

    const renderChatLabels = () => {
        
        return (
            <>
                {chatLabels.length == 0 ?
                    <Box key={'empty-list-nice-key'} sx={{ margin : "1rem" }}> Atendimento não possui etiquetas. </Box>
                    :
                    <List className='label-list'>
                        {chatLabels.map(each=>renderChatLabel(each, 'remove'))}
                    </List>
                }
            </>
        )
    }
    
    return (
        <>

            {renderChatLabels()}
            <Divider className="contactHistory-divider" />
            <Box 
                sx={{ "padding": ".5rem 1rem" }}
            >
                <TextField
                    className={'inputPlaceHolder'}
                    label={'Buscar Etiquetas'}
                    value={filterValue}
                    onChange={(event) => {handleFilterChange(event.target.value)}}
                    variant="standard"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
                
            </Box>
            {renderAllAvailableLabels()}

        </>
    )
}
