import React from "react";
import PropTypes from "prop-types";
import { Box, Tooltip } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import GetAppIcon from '@mui/icons-material/GetApp';


const findMime = (src, filename) => {
    let dl = document.createElement('a')
    dl.href = src
    dl.download = filename; 
    dl.click()
    dl.remove()
}

const ChatDocument = ({ src, user, name, message, base64 }) => {

    const useStyles = makeStyles((theme) => {
        return {
            root: {
                maxWidth: '275px',
                backgroundColor: '#f1f0f7',
                borderRadius: '100px',
                margin: '5px 0',
                height: '40px'
            },
            clickable: {
                maxWidth: '14rem',
                overflow: 'hidden',
                textWrap: 'nowrap',
                color: 'black'
            }
        }
    })

    const classes = useStyles();

    return (
        <Box display="flex"
            justifyContent={user !== 0 ? 'flex-end' : 'flex-start'}
        >
            <Tooltip placement='left' title={message?.agent_name ? `Enviado por: ${message?.agent_name}` : ''}>
                <Box className={classes.root}
                    display="flex"
                    alignItems="center"
                    width="100%"
                    justifyContent="space-around"
                >
                    <InsertDriveFileIcon color="primary" />
                    <Box className={classes.clickable}>{name}</Box>
                    {!base64 ? <a target="_blank" href={src}>
                        <GetAppIcon color="primary" />
                    </a>
                    :
                    <Box sx={{cursor:'pointer', display:'flex'}} onClick={() => findMime(src, name)}>
                        <GetAppIcon color="primary" />
                    </Box>
                    }
                </Box>
            </Tooltip>
        </Box>
    )
}

ChatDocument.PropTypes = {
    src: PropTypes.any,
    user: PropTypes.int,
    name: PropTypes.string
};

export default ChatDocument;   