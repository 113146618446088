import React from "react";

import { Button, Grid, TextField, InputLabel, OutlinedInput, Tooltip } from "@mui/material";
import { CircularProgress, IconButton, InputAdornment, FormControl } from "@mui/material";
import { Save } from '@mui/icons-material';

import api from '../../api';
import Typography from "../Typography";
import OutsideClickHandler from 'react-outside-click-handler';
import "./styles.css";

import useNotification from "../Notification";
import useValidationError from '../ErrorValidation';

class ChangeField extends React.Component {

  constructor(props) {

      super(props);

      this.state = {
        showChangeField : false,
        inputValue      : props.value,
        loading         : false
      };
  }

  fetchUpdate = async () => {
    this.setState({ loading: true });

    const dataField = document.getElementById(this.props.field).value;
    let body = {};

    body[this.props.field] = dataField;

    await api.patch(this.props.router, body).then(async (res) => {
      if (res.status === 200) {
        await this.setState({
          inputValue      : dataField, 
          showChangeField : false,
          loading         : false
        });
        useNotification(
          'Sucesso!', 
          'Contato alterado com sucesso.', 
          'success'
        );
        this.props.updateField(dataField);
      } else if (res.status === 205) {
        useNotification(
          'Sucesso!', 
          'Contato alterado com sucesso.', 
          'success'
        );
        this.props.reloadContact();
      } else {
        this.setState({ loading: false });
        useValidationError(res.status, null);
        throw res;
      }
    }).catch((err) => {
      console.error(err);
    });
  }

  render() {
    return (
      <div onClick={() => this.setState({ showChangeField: true })}>
        {(!this.state.showChangeField || this.props.disabledChange) &&
          <Typography 
              text={this.state.inputValue
                ? this.state.inputValue
                : this.props.label}
              fontStyle={this.state.inputValue
                ? ""
                : "italic"}
              color={this.state.inputValue
                ? ""
                : "lightGray"}
          />
        }
        {this.state.showChangeField && !this.props.disabledChange &&
          <OutsideClickHandler onOutsideClick={() => this.setState({ showChangeField: false })}>
            <TextField
                id={this.props.field}
                label={this.props.label}
                defaultValue={this.state.inputValue}
                disabled={this.state.loading}
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title={`Salvar ${this.props.label.toLowerCase()}`} placement="bottom" disableInteractive>
                        <IconButton
                          onClick={() => this.fetchUpdate()}
                          edge="end"
                          size="small">
                            {this.state.loading ? <CircularProgress size={25} /> : <Save />}
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  )
                }}
            />
          </OutsideClickHandler>
        }
      </div>
    );
  }
};

export default ChangeField;
