import React, { useEffect } from 'react'
import { getSession } from '../../../../auth'
import dayjs from 'dayjs'

let socketregistry = 0
const socketinterval = 20000

export const treatEvents = (data, state, setState, windowRef) => {
    let chat = null

    switch (data.event){
        case 'PONG':
        break
        case 'chats.update':
            /* Any chat updated, you need to select an account to track this chats... If new chat is received, it should be appended, otherwise it's ignored*/
            if (!state.activeAccount || state.activeAccount.owner !== data.message.owner) return
            chat = state.chats.find(chat => chat.id === data.message.id)
            if (!chat){
                const [phone_num, type] = data.message.id.split('@')
                if (phone_num === state.activeAccount.phone_number) return
                setState({chats: [...state.chats, {
                    id: data.message.id,
                    last_message_timestamp : dayjs().unix(),
                    account_id: state.activeAccount.id,
                    instance_name: data.message.instance_name,
                    owner: data.message.instance_name,
                    phone_num: phone_num,
                    type: type === 'g.us' ? 'GROUP' : 'CONTACT'
                }]})
            }
        break
        case 'messages.upsert':
            /* New messages received, if you have an openchat, this message will be included on your chat. */
            if (!state.activeAccount) return
            
            chat = state.chats.find(chat => chat.id === data?.message?.source)
            if (chat){
                chat.last_message_timestamp = dayjs().unix()
                chat.message = data.message
                chat.message_type = data.message.messageType
                chat.fromMe = data.message.fromMe
                chat.sender = data.message.pushName
            }
            if (state.activeChat?.id === data.message.source)
                windowRef.addMessages(data.message)
            
        break
        case 'connection.update':
             /* Connection updated, if an account is online and goes to offline, should close deactivate chat and account, closing the chat */
            const account = state.accounts.find(account => account.name === data.message.instance_name)
            if (account && account.status !== data.message.state){
                account.status = data.message.state
                const newstate = [...state.accounts]
                if (account.status !== 'open' && state.activeAccount?.name === account?.name){
                    newstate.activeChat = null
                    newstate.activeAccount = null
                }
                setState(newstate)
            }
        break
        case 'contacts.update':
            /* Any chat updated, you need to select an account to track this chats... The chat should be on your list, and it must be, then, it will update chat data*/
            if (!state.activeAccount || state.activeAccount.name !== data.message.instance_name) return
            chat = state.chats.find(chat => chat.id === data.message.id)
            if (chat && !chat.id.includes('@g.us')){
                if (data.message.pushName) chat.name = data.message.pushName
                if (chat.profile_picture !== data.message.profilePictureUrl) chat.profile_picture = data.message.profilePictureUrl
            }

                setState({chats: [...state.chats]})
            
        break

        default:
            console.error(`Unknown event '${data.event}' received from scout.`)
    }
        
}

const connectSocket = (socket, setParent) => {
    if (socket) socket.close()
    const accountcode = getSession().profile.enterprise.accountcode
    const newsocket = new WebSocket(`wss://${location.host}/chat/ws/scout/${accountcode}/`)

    newsocket.onopen = () => {
        clearInterval(socketregistry)
        socketregistry = setInterval(()=>{
            newsocket.send(JSON.stringify({
                event: 'PING',
                message: ''
            }));
        }, socketinterval)
    }

    newsocket.onclose = () => {
        clearInterval(socketregistry)
        setParent({socket: null})
    }
    
    setParent({socket: newsocket})
}

const ScoutSocketComponent = ({parent, setParent, windowRef}) => {

    if (parent.socket) parent.socket.onmessage = (e) => {treatEvents(JSON.parse(e.data), parent, setParent, windowRef)}

    useEffect(()=>{
        if (!parent.socket) connectSocket(parent.socket, setParent)
        return () => {if (parent.socket) parent.socket.close()}
    }, [parent.socket])

    return null
}

export default ScoutSocketComponent