import api from "../../api"

export const sendRegister = (data) => {
    return (api.post('/api/enterprises/register/', data
    ).then(response => {
        console.log(response.data);
        return response.status;
    }).catch(error => {
        console.log(error);
        return error.response.status;
    }));
}

