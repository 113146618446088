import React, { useState, useEffect, useRef } from "react";

import { Box, TextField, IconButton } from "@mui/material";

import SendIcon from '@mui/icons-material/Send';

import useNotification from '../Notification';
import api from '../../api';

import { getSession } from "../../auth";

import './index.css'

const InternalChat = ({ reAnchor, socket, channel, messages }) => {

    const user = getSession()

    const scrollRef = useRef(null);

    const [message, setMessage] = useState('')

    const scrollToBottom = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
        }
    };

    const sendMessage = async () => {
        if (message && socket) {
            const payload = {
                'channel' : channel,
                'message' : message,
                'user'    : `${user.first_name} ${user.last_name}`,
            }

            const enterpriseId = getSession().profile.enterprise.id
            const res = await api.post(`/api/enterprises/${enterpriseId}/chat_messages/`, payload)
            
            if (res.status !== 201) {
                useNotification(
                    'Ops!',
                    'Algo deu errado, tente novamente.',
                    'danger'
                )
                return
            }

            socket.send(JSON.stringify(payload))
            setMessage('')
        }
    }

    const onScroll = () => {
        // TODO: implement scroll pagination
    }

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    return (
        <Box className="internal-chat-container">
            <Box className="internal-chat-chat">
                <ul ref={scrollRef} onScroll={onScroll}>
                    {messages.map((each) => (
                        <Box className={each.user === `${user.first_name} ${user.last_name}` ? "message-right" : "message-left"}>
                            <Box className={each.user === `${user.first_name} ${user.last_name}` ? "message-item right" : "message-item left"}>
                                <Box>{each.user}:</Box>
                                <Box>{each.message}</Box>
                                <Box>{each.iso_time}</Box>
                            </Box>
                        </Box>
                    ))}
                </ul>
            </Box>
            <Box className="internal-chat-footer">
                <TextField
                    className="internal-chat-input"
                    size="small"
                    fullWidth
                    type="text"
                    variant="outlined"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    onKeyPress={(e) => { if (e.key == 'Enter') sendMessage() }}
                    placeholder="Digite sua mensagem..."
                />
                <IconButton onClick={sendMessage}>
                    <SendIcon />
                </IconButton>
            </Box>
        </Box>
    );
}

export default InternalChat;
