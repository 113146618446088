import React from "react";
import { Grid, Box } from "@mui/material";
import HeaderForm from "../../HeaderForm"
import SnippetsImage from "../../../../assets/images/snippets.svg"
import PropTypes from "prop-types";
import Typography from "../../../Typography"
import CardButton from "../../../Cards/CardButton";

const SnippetsForm = ({ values, setFieldValue }) => {

    function handleChange(i) {
        for (let j = 0; j < values.snippets.length; j++) {
            if (j !== i) {
                setFieldValue("snippets[" + j + "].checked", false);
            }
            setFieldValue("snippets[" + i + "].checked", !values.snippets[i].checked);
        }
        return null;
    }

    return (
        <Grid container spacing={2}>
            <HeaderForm img={SnippetsImage} title="PERSONALIZE SEU ATENDIMENTO" subtitle="Tipo de atendimento" width="78%" />
            <Box paddingBottom={3}>
                <Typography color="darkGray" text="É importante definir o seu perfil de atendimento, para que possamos configurar tudo da melhor forma para seu atendimento. Selecione a seguir o tipo de atendimento da sua empresa." />
            </Box>
            <Grid container spacing={2}>
                {values.snippets.map((snippet, i) => (
                    <Grid item lg={6} md={6} sm={12} xs={12} key={i}>
                        < CardButton minHeight='180px' colorChecked="#DFB160" border="1px solid #21446C" title={snippet.name} subTitle={snippet.description} checked={snippet.checked} onClick={() => handleChange(i)} />
                    </Grid>))}
            </Grid>
        </Grid >
    );
};

SnippetsForm.propTypes = {
    values: PropTypes.object,
    setFieldValue: PropTypes.func,
}

export default SnippetsForm;
