import React, { useEffect } from 'react';

import { useTheme } from '@mui/material/styles';

import { FormControl, InputLabel, Select, MenuItem, Chip } from '@mui/material';
import { OutlinedInput, Box } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function MultipleSelectChip({ selectData, onDataSelect, values, title }) {

    const theme = useTheme();

    const [data, setData] = React.useState([]);

    const handleChange = (event) => {
        const { target: { value }, } = event;
        setData(value);
        onDataSelect(value);
    };

    useEffect(() => {
        setData(values);
    }, []);

    return (
        <div>
            <FormControl sx={{ width: "100%" }} size="small">
                <InputLabel id="demo-multiple-chip-label">{title}</InputLabel>
                <Select
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    multiple
                    value={data}
                    onChange={handleChange}
                    input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                    renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                                <Chip
                                    size="small" 
                                    key={value} 
                                    label={value}
                                    deleteIcon={
                                        <CancelIcon
                                            onMouseDown={(event) => event.stopPropagation()}
                                        />}
                                    onDelete={() => handleChange({ target: { value: data.filter(each => each !== value) } })} />
                            ))}
                        </Box>
                    )}
                    MenuProps={MenuProps}
                >
                    {selectData.filter(description => !data.includes(description)).map((queueDesc) => (
                        <MenuItem
                            key={queueDesc}
                            value={queueDesc}
                        >
                            {queueDesc}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}
