import React from "react";
import Header from "../../components/Header";
import { Box } from "@mui/material";
import FormController from "../../components/Forms/RegisterForm/formController";
import { useHistory } from "react-router-dom";

const UserRegister = () => {
  const history = useHistory();

  return (
    <Box display="flex">
      <FormController />
      <Header
        subtitle={"Já possui cadastro? "}
        buttonText={"Entre agora"}
        onClick={() => history.push("/")}
      />
    </Box>
  );
};
export default UserRegister;
