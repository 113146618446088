import React from "react";

import MiniDrawer from "../../components/Drawer"
import UsageComponent from "../../components/Settings/Usage";
import OptionsMenu from "../../utils/OptionsMenu";
import { getSession } from "../../auth";
import Channels from "../../components/Settings/Channels";
import Telegram from "../../components/Settings/Telegram";
import Instagram from "../../components/Settings/Instagram";
import Email from "../../components/Settings/Email";
import Sms from "../../components/Settings/Sms";
import Messenger from "../../components/Settings/Messenger";
import userPermissions from "../../components/User/Permissions";

import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TelegramIcon from '@mui/icons-material/Telegram';
import EmailIcon from '@mui/icons-material/Email';

import SmsIcon from '@mui/icons-material/Sms';
import SettingsPage from "../../components/Scout/Pages/settings-page";
import CellTowerIcon from "@mui/icons-material/CellTower";

const getOptions = () => {
    const enterprise = getSession()?.profile?.enterprise
    const groups = userPermissions();

    return {
        whatsapp: {
            permissions: enterprise?.platforms?.whatsapp && groups.isAdmin,
            label: 'WhatsApp',
            component: (<Channels enterprise={enterprise}/>),
            icon: (<WhatsAppIcon className="whatsapp menu-icon"/>)
        },
        usagewpp: {
            permissions: enterprise?.platforms?.whatsapp && groups.isAdmin,
            label: 'Consumo',
            component: (<UsageComponent enterpriseId={enterprise?.id}/>)
        },
        telegram: {
            permissions: enterprise?.platforms?.telegram && groups.isAdmin,
            label: 'Telegram',
            component: (<Telegram enterprise={enterprise}/>),
            icon: (<TelegramIcon className="telegram menu-icon"/>)
        },
        instagram: {
            permissions: enterprise?.platforms?.instagram && groups.isAdmin,
            label: 'Instagram / Messenger',
            component: (<Instagram enterprise={enterprise}/>),
            icon: (<InstagramIcon className="menu-icon" sx={{ color: '#fd6b10' }}/>)
        },
        scout: {
            permissions: groups.isAdmin,
            label: 'Scout',
            component: (<SettingsPage />),
            icon: (<CellTowerIcon className="menu-icon" sx={{ color: '#1f10fd' }}/>)
        },
        // messenger: {
        //     permissions: enterprise?.platforms?.messenger && groups.isAdmin,
        //     label: 'Messenger',
        //     component: (<Messenger enterprise={enterprise}/>),
        //     icon: (<FacebookIcon className="menu-icon" sx={{ color: '#0866ff' }}/>)
        // },
        email: {
            permissions: enterprise?.platforms?.email && groups.isAdmin,
            label: 'Email',
            component: (<Email enterprise={enterprise}/>),
            icon: (<EmailIcon className="menu-icon" sx={{ color: '#757575' }}/>)
        },
        sms: {
            permissions: enterprise?.platforms?.sms && groups.isAdmin,
            label: 'Sms',
            component: (<Sms enterprise={enterprise}/>),
            icon: (<SmsIcon className="menu-icon" sx={{ color: '#757575' }}/>)
        }
    }
}

const ChannelsPage = (socket) => {
    return (
        <MiniDrawer child={
            <OptionsMenu name='channels'
                options={getOptions} />
        } socket={socket.socket} />
    );
};

export default ChannelsPage;
