import React, { useEffect, useReducer } from 'react'

import { Dialog, DialogContent, DialogTitle, Button, Box } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import api from '../../../../api'
import { getSession } from '../../../../auth'
import { lockedReducer } from '../../../../utils/defaultReducer'
import {
    DataGridPro,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    gridClasses,
    ptBR
} from '@mui/x-data-grid-pro';
import { ptBR as corePtBR } from '@mui/material/locale';
import {
    createTheme,
    ThemeProvider,
    StyledEngineProvider
} from '@mui/material/styles';
import dayjs from 'dayjs'

const theme = createTheme({
    palette: {
        primary: { main: '#21446C' },
    },
}, ptBR, corePtBR);

const events_dict = {
    'connect': 'Conectado',
    'disconnect': 'Desconectado',
    'create': 'Criado',
    'remove': 'Excluído',
    'add_ignore': 'Ignorado',
    'remove_ignore': 'Liberado'
}

const defColumns = (timezone) => {
    const columns = []
    columns.push({ field: 'phone_number', headerName: 'Telefone', flex: 1 })
    columns.push({ field: 'name', headerName: 'Nome', flex: 1 })
    columns.push({
        field: 'event', headerName: 'Evento', flex: 1
        , renderCell: ({ value }) => (events_dict[value])
    })
    columns.push({
        field: 'details', headerName: 'Alvo', flex: 1
        , renderCell: ({ value }) => (value?.split('@')[0] || '')
    })
    columns.push({
        field: 'created_at', headerName: 'Data', flex: 1
        , renderCell: ({ value }) => dayjs.utc(value).local().format('DD/MM/YYYY HH:mm')
    })

    return columns
}

const getData = async (data, setState, enterprise) => {
    const res = await api.get(`api/enterprises/${enterprise.id}/scout_accounts/${data.id}/history/`)
    if (res.status === 200)
        setState({rows: res.data})
}

const History = ({ data, handleClose }) => {

    const enterprise = getSession().profile.enterprise
    const timezone = enterprise.settings.timezone

    const [state, setState] = useReducer(lockedReducer, {
        rows: []
    })

    useEffect(() => {
        getData(data, setState, enterprise)
    }, [])

    return (
        <Dialog
            open={true}
            fullWidth
            disablePortal
            disableEnforceFocus
            maxWidth={'md'}
            sx={{ position: "absolute" }}
        >
            <DialogTitle className="group-flexbox">
                Histórico de conexões
                <Button sx={{ minWidth: "2rem", padding: "0" }} onClick={handleClose}>
                    <CloseIcon />
                </Button>
            </DialogTitle>
            <DialogContent>
                <Box className="modal-scout-table">
                    <StyledEngineProvider injectFirst >
                        <ThemeProvider theme={theme}>
                            <DataGridPro
                                pageSize={100}
                                pagination
                                disableSelectionOnClick
                                disableDensitySelector
                                density="compact"
                                columns={defColumns(timezone)}
                                rows={state.rows}
                            />
                        </ThemeProvider>
                    </StyledEngineProvider>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default History