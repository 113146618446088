import React from 'react'
import SettingsIntermediate from '../SettingsIntermediate'
import userPermissions from '../../User/Permissions';
import { getSession } from '../../../auth';
import VoiceComponent from '../../Settings/Voice'
import VoiceTable from '../../Settings/Voice/voice-table';

const PhonePage = () => {
    const groups = userPermissions();
    const enterprise = getSession()?.profile?.enterprise
    return (
        <>
            <SettingsIntermediate title='Telefonia' components={() => ({
                comp1:{
                    permissions: true,
                    style:'title',
                    label:'Telefonia'
                },
                comp2:{
                    permissions: groups.isAdmin,
                    style:'list',
                    label:"Mensagens",
                    sublabel:"Configure as mensagens pre-programadas para a telefonia",
                    redirect: (<VoiceComponent enterpriseId={enterprise?.id}/>),
                },
                comp3:{
                    permissions: groups.isAdmin,
                    style:'list',
                    label:"Números",
                    sublabel:"Configure os números para a telefonia",
                    redirect: (<VoiceTable enterpriseId={enterprise?.id}/>),
                }
            })
            } />
        </>
    )
}


export default PhonePage