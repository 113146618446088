import React, {useReducer} from 'react'

import {lockedReducer} from '../../../utils/defaultReducer'
import {Box, Chip} from '@mui/material'
import { FileDrop } from 'react-file-drop';
import LoadingButton from '@mui/lab/LoadingButton';
import {authenticateFile} from './detailed_register_functions'
import LogoImage from '../../../assets/images/kwik_white_bg_resized.png';
import UploadIcon from '@mui/icons-material/Upload';

const AuthenticateFile = ({parent, setParent}) => {

    const [state, setState] = useReducer(lockedReducer, {
        files: [],
        authSeal : {}
    })

    const changeFile = async (files) => {
        setParent({loading:true})
        setState(await authenticateFile(files))
        setParent({loading:false})
    }

    return (
        <Box className="report-authenticate-container">
        <Box className="filedrop-container">
            <FileDrop onDrop={ (files, event) => {
                changeFile(files)
            }}>
                {state.files.length > 0 ?
                    <span className='filedrop-filename'>
                        {Object.values(state.files).map((each, index) => (
                            <Chip
                                className={state.files[0].authentic ? 'greenshadow' : 'redshadow'}
                                key={`kb-filedrop-${index}`}
                                label={each.name}
                            />
                        ))}
                    </span>
                    :
                    <span>Arraste e solte o arquivo.</span>
                }
                <span>ou</span>
                <LoadingButton
                    variant="contained"
                    color="primary"
                    component="label"
                    loading={false}
                    loadingPosition="start"
                    startIcon={<UploadIcon />}
                    disabled={false}
                >
                    Selecione o arquivo.
                    <input
                        hidden
                        type="file"
                        accept={'.pdf'}
                        onChange={(e) => {
                            changeFile(e.target.files)
                        }}
                    />
                </LoadingButton>
            </FileDrop>
        </Box>
        {Object.keys(state.authSeal).length !== 0 &&
            <Box className="report-authenticity-seal">
                <Box className="header">
                    <img src={LogoImage} alt="Logo" className="logo" />
                    <Box className="report-authenticate-title">
                        Selo de autenticidade
                    </Box>
                </Box>
                <table>
                    <tbody>
                        <tr>
                            <td>Empresa</td>
                            <td>{state.authSeal.enterprise}</td>
                        </tr>
                        <tr>
                            <td>Usuário</td>
                            <td>{state.authSeal.user}</td>
                        </tr>
                        <tr>
                            <td>Arquivo</td>
                            <td>{state.authSeal.fileName}</td>
                        </tr>
                        <tr>
                            <td>Data de criação</td>
                            <td>{state.authSeal.createdAt}</td>
                        </tr>
                        <tr>
                            <td>Data de autenticação</td>
                            <td>{state.authSeal.validatedAt}</td>
                        </tr>
                        <tr>
                            <td>Resultado</td>
                            <td>{state.authSeal.result}</td>
                        </tr>
                        <tr>
                            <td>Código de autenticidade</td>
                            <td>{state.authSeal.hash}</td>
                        </tr>
                    </tbody>
                </table>
            </Box>
        }
    </Box>
    )
}

export default AuthenticateFile