import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import Typography from '@mui/material/Typography';

const CSATChart = ({ value }) => {
    const svgRef = useRef();
    const [dimensions, setDimensions] = useState({ width: 400, height: 200 });

    const getColor = (csat) => {
        if (csat <= 2) return '#d34c4d';
        if (csat <= 4) return '#f78f33';
        return '#3bae3b';
    }

    useEffect(() => {
        const updateDimensions = () => {
            const width = svgRef.current.clientWidth;
            const height = width / 2;
            setDimensions({ width, height });
        };

        updateDimensions();

        window.addEventListener('resize', updateDimensions);
        return () => window.removeEventListener('resize', updateDimensions);
    }, []);

    useEffect(() => {
        const { width, height } = dimensions;
        const radius = Math.min(width, height) / 2;

        d3.select(svgRef.current).selectAll('*').remove();

        const svg = d3.select(svgRef.current)
            .attr('width', width)
            .attr('height', height)
            .append('g')
            .attr('transform', `translate(${width / 2}, ${height})`);

        const arc = d3.arc()
            .innerRadius(radius * 1.0)
            .outerRadius(radius * 1.6);

        svg.append('path')
            .attr('class', 'background arc')
            .attr('d', arc({ startAngle: -Math.PI, endAngle: Math.PI }))
            .attr('fill', '#e0e0e0');

        const filledArc = d3.arc()
            .innerRadius(radius * 1.0)
            .outerRadius(radius * 1.6)
            .startAngle(-Math.PI / 2)
            .endAngle((-Math.PI / 2) + (value / 5) * Math.PI);

        svg.append('path')
            .attr('class', 'arc')
            .attr('d', filledArc())
            .attr('fill', getColor(value));

        svg.append('text')
            .attr('x', 0)
            .attr('y', -25)
            .attr('text-anchor', 'middle')
            .style('font-size', '1.25rem')
            .text(value);
    }, [value, dimensions]);

    return (
        <div style={{ width: '100%', padding: '2rem' }}>
            <Typography variant="subtitle1">CSAT</Typography>
            <svg ref={svgRef} style={{ width: '100%' }}></svg>
        </div>
    );
};

export default CSATChart;
