import React from 'react';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { Grid, DialogActions, DialogContent } from "@mui/material";
import { Box, Divider } from "@mui/material";
import { DialogTitle } from "@mui/material";
import { Slide, Button } from "@mui/material";
import { Switch } from "@mui/material";
import { TextField } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';


import api from '../../../../api';
import Loading from '../../../Loading';
import useValidationError from '../../../ErrorValidation';
import useNotification from "../../../Notification";
import { getSession } from '../../../../auth';
import MultipleSelections from '../../../Input/MultipleSelections';

import "./styles.css";
import { alphabeticalSort } from '../../../../utils/alphabeticalSort';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class Modal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            canSendUrl: true,
            canReceiveUrl: true,
            canSendAudio: true,
            canReceiveAudio: true,
            canSendImage: true,
            canSendImageExtensions: [],
            canReceiveImage: true,
            canReceiveImageExtensions: [],
            canSendVideo: true,
            canSendVideoExtensions: [],
            canReceiveVideo: true,
            canReceiveVideoExtensions: [],
            canSendDocument: true,
            canSendDocumentExtensions: [],
            canReceiveDocument: true,
            canReceiveDocumentExtensions: [],
            mediaSupervision: true,
            supervisionPendingMessage: 'Mídia enviada, aguardando aprovação...',
            supervisionRejectedMessage: 'A mídia foi rejeitada pela supervisão!',
            queuesChecked: {},
            renderKey: 0
        }
        this.imageExtensions = [
            'jpeg',
            'jpg',
            'png'
        ];
        this.videoExtensions = [
            'mp4',
            '3gpp'
        ];
        this.documentExtensions = [
            'csv',
            'txt',
            'doc',
            'docx',
            'pdf',
            'xls'
        ];
    }

    handleChangeCanSendUrl = (event) => {
        this.setState({ canSendUrl: event.target.checked });
    }

    handleChangeCanSendAudio = (event) => {
        this.setState({ canSendAudio: event.target.checked });
    }

    handleChangeCanSendImage = (event) => {
        this.setState({ canSendImage: event.target.checked });
    }

    handleChangeCanReceiveImage = (event) => {
        this.setState({ canReceiveImage: event.target.checked });
    }

    handleChangeCanSendVideo = (event) => {
        this.setState({ canSendVideo: event.target.checked });
    }

    handleChangeCanReceiveVideo = (event) => {
        this.setState({ canReceiveVideo: event.target.checked });
    }

    handleChangeCanSendDocument = (event) => {
        this.setState({ canSendDocument: event.target.checked });
    }

    handleChangeCanReceiveDocument = (event) => {
        this.setState({ canReceiveDocument: event.target.checked });
    }

    handleChangeMediaSupervision = (event) => {
        this.setState({ mediaSupervision: event.target.checked });
    }

    handleChangeSupervisionPendingMessage = (event) => {
        this.setState({ supervisionPendingMessage: event.target.value });
    }

    handleChangeSupervisionRejectedMessage = (event) => {
        this.setState({ supervisionRejectedMessage: event.target.value });
    }

    handleChangeQueues = (queueName) => {
        let queuesChecked = this.state.queuesChecked;

        queuesChecked[queueName].checked = !queuesChecked[queueName].checked;

        this.setState({ queuesChecked: queuesChecked });
    }

    getSelectData = () => {
        if (this.props.editSetting) {
            const queues = Object.keys(this.state.queuesChecked).filter(queue => {
                return this.state.queuesChecked[queue].checked;
            });
            return queues;
        }
        return [];
    }

    setStateLoad = (editSetting) => {
        let state = {};

        if ('queues' in editSetting) {
            let queuesChecked = this.state.queuesChecked;
            editSetting.queues.forEach(queue => {
                if (queuesChecked[queue])
                    queuesChecked[queue].checked = true;
            });
            state.queuesChecked = queuesChecked;

        } if ('can_send_url' in editSetting) {
            state.canSendUrl = editSetting.can_send_url;

        } if ('can_receive_url' in editSetting) {
            state.canReceiveUrl = editSetting.can_receive_url;

        } if ('can_send_audio' in editSetting) {
            state.canSendAudio = editSetting.can_send_audio;

        } if ('can_receive_audio' in editSetting) {
            state.canReceiveAudio = editSetting.can_receive_audio;

        } if ('can_send_image' in editSetting) {
            state.canSendImage = editSetting.can_send_image;

        } if ('can_send_image_extensions' in editSetting) {
            const extensions = editSetting.can_send_image_extensions;
            state.canSendImageExtensions = extensions !== '' ? extensions.split(',') : [];

        } if ('can_receive_image' in editSetting) {
            state.canReceiveImage = editSetting.can_receive_image;

        } if ('can_receive_image_extensions' in editSetting) {
            const extensions = editSetting.can_receive_image_extensions;
            state.canReceiveImageExtensions = extensions !== '' ? extensions.split(',') : [];

        } if ('can_send_video' in editSetting) {
            state.canSendVideo = editSetting.can_send_video;

        } if ('can_send_video_extensions' in editSetting) {
            const extensions = editSetting.can_send_video_extensions;
            state.canSendVideoExtensions = extensions !== '' ? extensions.split(',') : [];

        } if ('can_receive_video' in editSetting) {
            state.canReceiveVideo = editSetting.can_receive_video;

        } if ('can_receive_video_extensions' in editSetting) {
            const extensions = editSetting.can_receive_video_extensions;
            state.canReceiveVideoExtensions = extensions !== '' ? extensions.split(',') : [];

        } if ('can_send_document' in editSetting) {
            state.canSendDocument = editSetting.can_send_document;

        } if ('can_send_document_extensions' in editSetting) {
            const extensions = editSetting.can_send_document_extensions;
            state.canSendDocumentExtensions = extensions !== '' ? extensions.split(',') : [];

        } if ('can_receive_document' in editSetting) {
            state.canReceiveDocument = editSetting.can_receive_document;

        } if ('can_receive_document_extensions' in editSetting) {
            const extensions = editSetting.can_receive_document_extensions;
            state.canReceiveDocumentExtensions = extensions !== '' ? extensions.split(',') : [];

        } if ('media_supervision' in editSetting) {
            state.mediaSupervision = editSetting.media_supervision;

        } if ('supervision_pending_message' in editSetting) {
            state.supervisionPendingMessage = editSetting.supervision_pending_message;

        } if ('supervision_rejected_message' in editSetting) {
            state.supervisionRejectedMessage = editSetting.supervision_rejected_message;
        }

        state.renderKey = `multiple-selections-${Math.random()}`;

        this.setState(state);
    }

    loadEdit = () => {
        const editSetting = this.props.editSetting;
        this.setStateLoad(editSetting);
    }

    noQueueSelected = () => {
        let noQueueSelected = false;
        const queuesChecked = this.state.queuesChecked;

        Object.keys(queuesChecked).forEach(queue => {
            if (queuesChecked[queue].checked) {
                noQueueSelected = true;
                return noQueueSelected;
            }
        });

        return !noQueueSelected;
    }

    createBody = () => {
        let body = {};

        const queuesChecked = this.state.queuesChecked;
        body.queues = [];
        Object.keys(queuesChecked).forEach(queue => {
            if (queuesChecked[queue].checked) {
                body.queues.push(queue);
            }
        });

        const canSendImage = this.state.canSendImage;
        const canSendAudio = this.state.canSendAudio;
        const canReceiveAudio = this.state.canReceiveAudio
        const canReceiveImage = this.state.canReceiveImage;
        const canSendVideo = this.state.canSendVideo;
        const canReceiveVideo = this.state.canReceiveVideo;
        const canSendDocument = this.state.canSendDocument;
        const canReceiveDocument = this.state.canReceiveDocument;

        body.can_send_url = this.state.canSendUrl;
        body.can_receive_url = this.state.canReceiveUrl
        body.can_send_audio = this.state.canSendAudio;
        body.can_receive_audio = this.state.canReceiveAudio

        body.can_send_image = canSendImage;
        if (canSendImage)
            body.can_send_image_extensions = this.state.canSendImageExtensions.toString();

        body.can_receive_image = canReceiveImage;
        if (canReceiveImage)
            body.can_receive_image_extensions = this.state.canReceiveImageExtensions.toString();

        body.can_send_video = canSendVideo;
        if (canSendVideo)
            body.can_send_video_extensions = this.state.canSendVideoExtensions.toString();

        body.can_receive_video = canReceiveVideo;
        if (canReceiveVideo)
            body.can_receive_video_extensions = this.state.canReceiveVideoExtensions.toString();

        body.can_send_document = canSendDocument;
        if (canSendDocument)
            body.can_send_document_extensions = this.state.canSendDocumentExtensions.toString();

        body.can_receive_document = canReceiveDocument;
        if (canReceiveDocument)
            body.can_receive_document_extensions = this.state.canReceiveDocumentExtensions.toString();

        const mediaSupervision = this.state.mediaSupervision;

        body.media_supervision = mediaSupervision;

        if (mediaSupervision) {
            body.supervision_pending_message = this.state.supervisionPendingMessage;
            body.supervision_rejected_message = this.state.supervisionRejectedMessage;
        }

        return body;
    }

    addOrEditSetting = async () => {
        const noQueueSelected = await this.noQueueSelected();

        if (noQueueSelected) {
            useNotification(
                'Atenção!',
                'Selecione ao menos uma fila de atendimento para aplicar as configurações.',
                'warning'
            );
            return;
        }

        const body = await this.createBody();

        if (this.props.addSetting) {
            this.addSettings(body);

        } else {
            const settingId = this.props.editSetting.id;
            this.editSettings(settingId, body);
        }
    }

    addSettings = (body) => {
        this.setState({ loading: true });

        api.post(`/api/enterprises/${this.props.enterpriseId}/queues/settings/`, body)
            .then(async (res) => {
                this.setState({ loading: false });

                if (res.status !== 201) {
                    await useValidationError(res.status, null);
                } else {
                    await this.props.handleClose();
                    await this.props.getSettings();
                    useNotification(
                        'Sucesso!',
                        'Configurações por filas adicionadas com sucesso.',
                        'success'
                    );
                }
            })
            .catch((err) => {
                console.error(err);
            });
    }

    editSettings = (settingId, body) => {
        this.setState({ loading: true });

        api.patch(`/api/enterprises/${this.props.enterpriseId}/queues/settings/${settingId}/`, body)
            .then(async (res) => {
                this.setState({ loading: false });

                if (res.status !== 200) {
                    await useValidationError(res.status, null);
                } else {
                    await this.props.handleClose();
                    await this.props.getSettings();
                    useNotification(
                        'Sucesso!',
                        'Configurações por filas alteradas com sucesso.',
                        'success'
                    );
                }
            })
            .catch((err) => {
                console.error(err);
            });
    }

    componentWillMount() {
        const user = getSession();
        let queuesChecked = {};

        Object.entries(this.props.queuesChecked).forEach(([key, value]) => {
            queuesChecked[key] = {
                name: value,
                checked: false
            };
        });

        this.state.queuesChecked = queuesChecked;

        if (this.props.editSetting) {
            this.loadEdit();
        }
    }

    render() {
        return (
            <>
                <Loading loading={this.state.loading} />
                <>
                    <DialogTitle id="customized-dialog-title" className={'group-flexbox'} >
                        <span>Configurações de fila</span> <Button sx={{ 'minWidth': '2rem', 'padding': '0' }} onClick={this.props.handleClose} ><CloseIcon /></Button>
                    </DialogTitle>
                    <Divider />

                    <DialogContent>
                        <Box className="input-settings">
                            <Box className="settings-category-title">
                                Links
                            </Box>
                            <Grid container>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Grid container>
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <Box className="title-settings space-between">
                                                Pode enviar links
                                                <Switch
                                                    checked={this.state.canSendUrl}
                                                    onChange={this.handleChangeCanSendUrl}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />
                                            </Box>
                                            <Box className="title-settings space-between">
                                                Pode receber links
                                                <Switch
                                                    checked={this.state.canReceiveUrl}
                                                    onChange={(e) => this.setState({canReceiveUrl: e.target.checked})}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Box className="settings-category-title">
                                Áudio
                            </Box>
                            <Grid container>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Grid container>
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <Box className="title-settings space-between">
                                                Pode enviar áudio
                                                <Switch
                                                    checked={this.state.canSendAudio}
                                                    onChange={this.handleChangeCanSendAudio}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />
                                            </Box>
                                            <Box className="title-settings space-between">
                                                Pode receber áudio
                                                <Switch
                                                    checked={this.state.canReceiveAudio}
                                                    onChange={(e) => this.setState({canReceiveAudio: e.target.checked})}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Box className="settings-category-title">Imagem</Box>
                            <Grid container>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Grid container>
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <Box className="title-settings space-between">
                                                Pode enviar imagem
                                                <Switch
                                                    checked={this.state.canSendImage}
                                                    onChange={this.handleChangeCanSendImage}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <MultipleSelections
                                                title="Extensões de envio permitidas"
                                                selectedData={this.state.canSendImageExtensions}
                                                dataProp={this.imageExtensions}
                                                getSelect={(canSendImageExtensions) =>
                                                    this.setState({ canSendImageExtensions: canSendImageExtensions })}
                                                disabled={!this.state.canSendImage}
                                                key={this.state.renderKey}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Grid container>
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <Box className="title-settings space-between">
                                                Pode receber imagem
                                                <Switch
                                                    checked={this.state.canReceiveImage}
                                                    onChange={this.handleChangeCanReceiveImage}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <MultipleSelections
                                                title="Extensões de recebimento permitidas"
                                                selectedData={this.state.canReceiveImageExtensions}
                                                dataProp={this.imageExtensions}
                                                getSelect={(canReceiveImageExtensions) =>
                                                    this.setState({ canReceiveImageExtensions: canReceiveImageExtensions })}
                                                disabled={!this.state.canReceiveImage}
                                                key={this.state.renderKey}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Box className="settings-category-title">Vídeo</Box>
                            <Grid container>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Grid container>
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <Box className="title-settings space-between">
                                                Pode enviar vídeo
                                                <Switch
                                                    checked={this.state.canSendVideo}
                                                    onChange={this.handleChangeCanSendVideo}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <MultipleSelections
                                                title="Extensões de envio permitidas"
                                                selectedData={this.state.canSendVideoExtensions}
                                                dataProp={this.videoExtensions}
                                                getSelect={(canSendVideoExtensions) =>
                                                    this.setState({ canSendVideoExtensions: canSendVideoExtensions })}
                                                disabled={!this.state.canSendVideo}
                                                key={this.state.renderKey}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Grid container>
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <Box className="title-settings space-between">
                                                Pode receber vídeo
                                                <Switch
                                                    checked={this.state.canReceiveVideo}
                                                    onChange={this.handleChangeCanReceiveVideo}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <MultipleSelections
                                                title="Extensões de recebimento permitidas"
                                                selectedData={this.state.canReceiveVideoExtensions}
                                                dataProp={this.videoExtensions}
                                                getSelect={(canReceiveVideoExtensions) =>
                                                    this.setState({ canReceiveVideoExtensions: canReceiveVideoExtensions })}
                                                disabled={!this.state.canReceiveVideo}
                                                key={this.state.renderKey}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Box className="settings-category-title">Documento</Box>
                            <Grid container>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Grid container>
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <Box className="title-settings space-between">
                                                Pode enviar documento
                                                <Switch
                                                    checked={this.state.canSendDocument}
                                                    onChange={this.handleChangeCanSendDocument}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <MultipleSelections
                                                title="Extensões de envio permitidas"
                                                selectedData={this.state.canSendDocumentExtensions}
                                                dataProp={this.documentExtensions}
                                                getSelect={(canSendDocumentExtensions) =>
                                                    this.setState({ canSendDocumentExtensions: canSendDocumentExtensions })}
                                                disabled={!this.state.canSendDocument}
                                                key={this.state.renderKey}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Grid container>
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <Box className="title-settings space-between">
                                                Pode receber documento
                                                <Switch
                                                    checked={this.state.canReceiveDocument}
                                                    onChange={this.handleChangeCanReceiveDocument}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <MultipleSelections
                                                title="Extensões de recebimento permitidas"
                                                selectedData={this.state.canReceiveDocumentExtensions}
                                                dataProp={this.documentExtensions}
                                                getSelect={(canReceiveDocumentExtensions) =>
                                                    this.setState({ canReceiveDocumentExtensions: canReceiveDocumentExtensions })}
                                                disabled={!this.state.canReceiveDocument}
                                                key={this.state.renderKey}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Box className="settings-category-title">
                                Supervisão de mídia
                                <Switch
                                    checked={this.state.mediaSupervision}
                                    onChange={this.handleChangeMediaSupervision}
                                    inputProps={{ "aria-label": "controlled" }}
                                    className="align-right"
                                />
                            </Box>
                            <Grid container>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Grid container>
                                        <Grid item lg={12} md={12} sm={12} xs={12} className="push">
                                            <TextField
                                                size="small"
                                                label="Mensagem ao cliente (pendente)"
                                                fullWidth
                                                type="text"
                                                variant="outlined"
                                                defaultValue={this.state.supervisionPendingMessage}
                                                onChange={this.handleChangeSupervisionPendingMessage}
                                                disabled={!this.state.mediaSupervision}
                                            />
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12} xs={12} className="push">
                                            <TextField
                                                size="small"
                                                label="Mensagem ao cliente (rejeitada)"
                                                fullWidth
                                                type="text"
                                                variant="outlined"
                                                defaultValue={this.state.supervisionRejectedMessage}
                                                onChange={this.handleChangeSupervisionRejectedMessage}
                                                disabled={!this.state.mediaSupervision}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Box className="settings-category-title">Filas de atendimento</Box>
                            <Grid container>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box>
                                        <MultipleSelections
                                            title="Filas"
                                            selectedData={this.getSelectData()}
                                            dataIsObject={true}
                                            objectDataHasTitle="name"
                                            objectDataHasChecked="checked"
                                            dataProp={this.state.queuesChecked}
                                            getSelect={(queuesChecked) => this.setState({ queuesChecked: queuesChecked })}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </DialogContent>
                    <DialogActions sx={{ padding: '0rem 1.7rem 1rem 0rem' }}>
                        <Box >
                            <Box className="buttons-settings">
                                <LoadingButton className="button-created"
                                    onClick={this.props.handleClose}
                                >
                                    Cancelar
                                </LoadingButton>
                                <LoadingButton className="button-created"
                                    onClick={() => this.addOrEditSetting()}
                                    loading={this.state.loading}
                                >
                                    {this.props.addSetting ? "Adicionar" : "Salvar"}
                                </LoadingButton>
                            </Box>
                        </Box>
                    </DialogActions>
                </>
            </>
        );
    }
}

export default Modal;