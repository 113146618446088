import React from "react";
import { Grid, Box } from "@mui/material";
import HeaderForm from "../../HeaderForm"
import ResumeImage from "../../../../assets/images/resume.svg"
import PropTypes from "prop-types";
import Typography from "../../../Typography"

const Resume = ({ values }) => {

    return (
        <Box>
            <HeaderForm img={ResumeImage} title="PERSONALIZE SEU ATENDIMENTO" subtitle="Resumo" width="80%" />
            {/* Tipo de atendimento */}
            {values.snippets.map((snippet, i) => (
                <Box paddingTop={2} key={i}>
                    {snippet.checked && (
                        <Grid container spacing={1}>
                            <Grid item lg={11} md={11} sm={12} xs={12}>
                                <Box paddingBottom={1} borderBottom="2px #21446C solid">
                                    <Typography color="blue" text="TIPO DE ATENDIMENTO" fontWeight="500" variant="subtitle1" />
                                </Box>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Typography color="darkGray" text={snippet.name} fontWeight="500" />
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Typography color="lightGray" text={snippet.description} />
                            </Grid>
                        </Grid>
                    )}
                </Box>))}
            {/* Horário de atendimento */}
            <Box>
                <Grid container spacing={2}>
                    <Grid item lg={11} md={11} sm={12} xs={12}>
                        <Box paddingBottom={1} borderBottom="2px #21446C solid">
                            <Typography color="blue" text="HORÁRIO DE ATENDIMENTO" fontWeight="500" variant="subtitle1" />
                        </Box>
                    </Grid>
                    <Grid item lg={4} md={4} sm={4} xs={4}>
                        <Typography color="darkGray" fontWeight="500" text="Dia da semana" />
                    </Grid>
                    <Grid item lg={2} md={2} sm={2} xs={2}>
                        <Typography color="darkGray" fontWeight="500" text="Início" />
                    </Grid>
                    <Grid item lg={2} md={2} sm={2} xs={2}>
                        <Typography color="darkGray" fontWeight="500" text="Fim" />
                    </Grid>
                    <Grid item lg={2} md={2} sm={2} xs={2}>
                        <Typography color="darkGray" fontWeight="500" text="Início" />
                    </Grid>
                    <Grid item lg={2} md={2} sm={2} xs={2}>
                        <Typography color="darkGray" fontWeight="500" text="Fim" />
                    </Grid>
                </Grid>
                {values.times.map((time, i) => (
                    <Box key={i} >
                        {
                            time.checked && (
                                < Grid container spacing={2}  >
                                    <Grid item lg={4} md={4} sm={4} xs={4}>
                                        <Typography color="lightGray" text={time.weekDay} />
                                    </Grid>
                                    <Grid item lg={2} md={2} sm={2} xs={2}>
                                        <Typography color="lightGray" text={time.initialTime} />
                                    </Grid>
                                    <Grid item lg={2} md={2} sm={2} xs={2}>
                                        <Typography color="lightGray" text={time.endTime} />
                                    </Grid>
                                    <Grid item lg={2} md={2} sm={2} xs={2}>
                                        <Typography color="lightGray" text={time.initialTime2 === "00:00" ? "--:--" : time.initialTime2} />
                                    </Grid>
                                    <Grid item lg={2} md={2} sm={2} xs={2}>
                                        <Typography color="lightGray" text={time.endTime2 === "00:00" ? "--:--" : time.endTime2} />
                                    </Grid>
                                </Grid>)}
                    </Box >
                ))}
            </Box>
            {/* Mensagens */}
            < Box paddingTop={4} >
                <Grid container spacing={1}>
                    <Grid item lg={11} md={11} sm={12} xs={12}>
                        <Box paddingBottom={1} borderBottom="2px #21446C solid">
                            <Typography color="blue" text="MENSAGENS" fontWeight="500" variant="subtitle1" />
                        </Box>
                    </Grid>
                    {values.messages.map((message, i) => (
                        <Grid container spacing={1} key={i}>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Typography color="darkGray" fontWeight="500" text={message.description} />
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Typography color="lightGray" text={message.message === "" ? "Não definido" : message.message} />
                            </Grid>
                        </Grid>))}
                </Grid>
            </Box>
            {/* Grupos de atendimento */}
            < Box paddingTop={4} >
                <Grid container spacing={1}>
                    <Grid item lg={11} md={11} sm={12} xs={12}>
                        <Box paddingBottom={1} borderBottom="2px #21446C solid">
                            <Typography color="blue" text="GRUPOS DE ATENDIMENTO" fontWeight="500" variant="subtitle1" />
                        </Box>
                    </Grid>
                </Grid>

                {values.groups.map((group, i) => (
                    <Box paddingTop={2} key={i}>
                        <Grid container spacing={0} >
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <Typography color="darkGray" fontWeight="500" text={"Número do grupo:"} />
                            </Grid>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <Typography color="lightGray" text={group.number !== "" ? group.number : "Não definido"} />
                            </Grid>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <Typography color="darkGray" fontWeight="500" text={"Descrição:"} />
                            </Grid>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <Typography color="lightGray" text={group.description !== "" ? group.description : "Não definido"} />
                            </Grid>
                        </Grid>
                    </Box>
                ))}
            </Box>
            {/* Atendentes */}
            < Box paddingTop={4} >
                <Grid container spacing={1}>
                    <Grid item lg={11} md={11} sm={12} xs={12}>
                        <Box paddingBottom={1} borderBottom="2px #21446C solid">
                            <Typography color="blue" text="OPERADORES" fontWeight="500" variant="subtitle1" />
                        </Box>
                    </Grid>
                </Grid>
                {values.agents.map((operator, i) => (
                    <Box paddingTop={2} key={i}>

                        <Grid container spacing={0}>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <Typography color="darkGray" fontWeight="500" text={"Número do operador:"} />
                            </Grid>
                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                <Typography color="lightGray" text={operator.number !== "" ? operator.number : "Não definido"} />
                            </Grid>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <Typography color="darkGray" fontWeight="500" text={"Nome:"} />
                            </Grid>
                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                <Typography color="lightGray" text={operator.name !== "" ? operator.name : "Não definido"} />
                            </Grid>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <Typography color="darkGray" fontWeight="500" text={"E-mail:"} />
                            </Grid>
                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                <Typography color="lightGray" text={operator.email !== "" ? operator.email : "Não definido"} />
                            </Grid>
                        </Grid>
                    </Box>))}
            </Box>

            {/* Pesquisa de satisfação */}
            < Box paddingTop={4} >
                <Grid container spacing={1}>
                    <Grid item lg={11} md={11} sm={12} xs={12}>
                        <Box paddingBottom={1} borderBottom="2px #21446C solid">
                            <Typography color="blue" text="PESQUISA DE SATISFAÇÃO" fontWeight="500" variant="subtitle1" />
                        </Box>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Typography color="darkGray" fontWeight="500" text="Nome da pesquisa:" />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Typography color="lightGray" text={values.researches.name === "" ? "Não definido" : values.researches.name} />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Typography color="darkGray" fontWeight="500" text="Número da pesquisa:" />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Typography color="lightGray" text={values.researches.number === "" ? "Não definido" : values.researches.number} />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Typography color="darkGray" fontWeight="500" text="Nota mínima:" />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Typography color="lightGray" text={values.researches.minRating === "" ? "Não definido" : values.researches.minRating} />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Typography color="darkGray" fontWeight="500" text="Nota máxima:" />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Typography color="lightGray" text={values.researches.maxRating === "" ? "Não definido" : values.researches.maxRating} />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Typography color="darkGray" fontWeight="500" text="Mensagem Inicial:" />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Typography color="lightGray" text={values.researches.initialMessage === "" ? "Não definido" : values.researches.initialMessage} />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Typography color="darkGray" fontWeight="500" text="Mensagem Final:" />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Typography color="lightGray" text={values.researches.finalMessage === "" ? "Não definido" : values.researches.finalMessage} />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Typography color="darkGray" fontWeight="500" text="Comentários:" />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Typography color="lightGray" text={values.researches.comments ? "Habilitado" : " Não habilitado"} />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Typography color="darkGray" fontWeight="500" text="Perguntas" />
                    </Grid>

                    {values.researches.questions.map((question, i) => (
                        <Grid item lg={12} md={12} sm={12} xs={12} key={i}>
                            <Typography color="lightGray" text={question.question !== "" ? question.question : ""} />
                        </Grid>))}
                </Grid>
            </Box>


        </Box >
    );
};

Resume.propTypes = {
    values: PropTypes.object,
}

export default Resume;
