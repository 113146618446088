import React, { useEffect, useState, useMemo, useContext } from 'react';

import { IMaskInput } from 'react-imask';

import { Dialog, DialogTitle, DialogContent, Box, List, ListItem, ListItemText, ListItemButton, Divider } from '@mui/material';
import { DialogActions, InputAdornment, TextField, Button } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import {
    DataGridPro,
    GridToolbarContainer,
    GridToolbarFilterButton,
    ptBR,
    useGridApiRef,
} from '@mui/x-data-grid-pro';
import { ptBR as corePtBR } from '@mui/material/locale';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import SearchIcon from '@mui/icons-material/Search';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import PhoneIcon from '@mui/icons-material/PhoneAndroid';
import EmailIcon from '@mui/icons-material/Email';

import api from '../../../../../api';
import Table from "../../../../Table";
import { ScreenContext } from '../../..';

import useNotification from '../../../../Notification';

import "./styles.css"


const EditGroupMembersModal = (props) => {
    const { rmvObj, subsObj, updateGroups, updateClients } = useContext(ScreenContext)
    const [selectionModel, updateSelectionModel] = useState([])
    const [clientsModel, updateClientsModel] = useState([])
    const [newClientsModel, updateNewClientsModel] = useState([])
    const [loading, updateLoading] = useState(false)
    const [sortModel, updateSortModel] = useState()
    const [smallLoading, updateSmallLoading] = useState(false)
    var [timeout, updatetimeout] = useState(0)


    const getNewClientList = async (search) => {
        if (search == '') {
            updateNewClientsModel([])
            return
        }
        updateSmallLoading(true)
        const url = `/api/enterprises/${props.enterpriseId}/contacts/?search=${search}&limit=5&offset=0`
        const res = await api.get(url)
        updateNewClientsModel(res.data.results.filter(client => !selectionModel.includes(client.id)))
        updateSmallLoading(false)
    }

    const handleFilterChange = (event) => {
        clearTimeout(timeout)
        timeout = setTimeout(() => {
            getNewClientList(event.target.value)
            timeout = 0
        }, 500)
    }

    const requestDataGroup = async () => {
        updateLoading(true)
        const url = `/api/enterprises/${props.enterpriseId}/contact_group/${props.selectedGroup.id}/simplecontacts/`
        const res = await api.get(url, { timeout: 0 })

        if (res.data == 403) {
            useNotification(
                'Ops!',
                'Você não tem permissão para ver esse conteúdo',
                'danger'
            );
            return
        }

        updateLoading(false)
        return (res.data)
    }

    //Initialize
    useEffect(async () => {
        // const clientdata = await requestDataGroup()
        // const selectedmodel = props.selectedGroup.contacts.map(each => each.contact)
        // updateSelectionModel(selectedmodel)
        // updateClientsModel(clientdata.filter(client => selectedmodel.includes(client.id)))

        const clientdata = await requestDataGroup()
        updateSelectionModel(clientdata.map(each => each.id))
        updateClientsModel(clientdata)
        // the DataGroup should be the selectionModel Ids, we should filter out from the clients model, everyone that does not belong to the selectedGroup

        // updateSortModel([
        //     {
        //         field: 'id',
        //         sort: 'desc',
        //     },
        // ])

    }, [])


    const submit = async () => {
        updateLoading(true)
        const url = `/api/enterprises/${props.enterpriseId}/contact_group/${props.selectedGroup.id}/`
        const body = {
            name: props.selectedGroup.name,
            description: props.selectedGroup.description,
            clients: selectionModel
        }
        const response = await api.patch(url, body)
        updateLoading(false)

        if (response.status == 403) {
            useNotification(
                'Ops!',
                'Você não tem permissão para ver esse conteúdo',
                'danger'
            );
            return
        }

        if (response.status == 400) {
            let message = 'Ocorreu um erro com a requisição.'
            if ('non_field_errors' in response.data && response.data.non_field_errors)
                message = 'Já existe um segmento com esse nome nessa empresa.'
            useNotification(
                'Ops!',
                message,
                'danger'
            );
            return
        }
        updateGroups(prevState => subsObj(prevState, response.data))

        updateClients(prevState => {
            prevState.map(each => {
                each.groups = each.groups.filter(group => group.id !== props.selectedGroup.id)
            })
            prevState.filter(each => body.clients.includes(each.id)) // Apenas nos clientes selecionados.
                .map(each => (
                    each.groups = subsObj(each.groups, response.data)
                ));
            return prevState
        })

        // props.updateForceReload(prevState => !prevState)
        if (response.status === 404) {
            // console.log('Response Fail') // chamar o notification padrão
            return
        }
        props.submit()
    }


    const theme = createTheme({
        palette: {
            primary: { main: '#21446C' },
        },
    }, ptBR, corePtBR);

    const CustomToolbar = () => {
        return (
            <GridToolbarContainer >
                <GridToolbarFilterButton />
            </GridToolbarContainer>
        )
    }

    const createClientsColumns = () => {

        let columns = [
            { field: 'name', headerName: 'Nome', flex: 1, sortable: true },
            { field: 'telephone', headerName: 'Telefone', flex: 1, sortable: true },
            {
                field: 'action', headerName: 'Ações', flex: .25,
                renderCell: (params) => (
                    <Box>
                        <Button className="action-button delete"
                            onClick={() => { removeFromGroup(params.row) }}>
                            <RemoveCircleOutlineIcon />
                        </Button>
                    </Box>
                )
            },
            {
                field: 'id', headerName: 'Pertence ao segmento', flex: 1, sortable: true, hide: true,
                valueGetter: ({ value }) => {
                    const selecteds = props.selectedGroup.contacts.map(each => each.contact)
                    if (selecteds && selecteds.includes(value))
                        return true;
                    return false
                },
            },
        ];

        return columns;

    }

    const addToGroup = (client) => {
        updateClientsModel(prevState => subsObj(prevState, client))
        updateSelectionModel(prevState => [...new Set([...prevState, client.id])])
        updateNewClientsModel(prevState => rmvObj(prevState, client.id))
    }

    const removeFromGroup = (client) => {
        updateClientsModel(prevState => prevState.filter(each => each.id != client.id))
        updateSelectionModel(prevState => rmvObj(prevState, client.id))
        updateNewClientsModel(prevState => subsObj(prevState, client))
    }

    return (
        <div>
            {
                <Dialog keepMounted
                    open={props.open}
                    // onClose={props.handleClose}
                    aria-labelledby="customized-dialog-title"
                    fullWidth
                    disablePortal
                    disableEnforceFocus
                    sx={{position:'absolute'}}
                >
                    <DialogTitle id="customized-dialog-title" className={'group-flexbox'} >
                        <span>{props.title}: {props.selectedGroup.name}</span> <Button sx={{ 'minWidth': '2rem', 'padding': '0' }} onClick={props.handleClose} ><CloseIcon /></Button>
                    </DialogTitle>
                    <Divider />
                    <DialogContent sx={{ padding: "1rem" }}>
                        <>
                            <Box className="category-title push-bottom">Clientes</Box>
                            <div>
                                <Box sx={{ width: '100%', height: '300px' }}>
                                    <ThemeProvider theme={theme}>
                                        <DataGridPro
                                            columns={createClientsColumns()}
                                            rows={clientsModel}
                                            loading={loading}
                                            // checkboxSelection
                                            disableSelectionOnClick
                                            components={{ Toolbar: CustomToolbar }}
                                            onSelectionModelChange={(newSelectionModel) => {
                                                updateSelectionModel(newSelectionModel);
                                            }}
                                            selectionModel={selectionModel}
                                            density={'compact'}
                                            sortModel={sortModel}
                                            onSortModelChange={(newSortModel) => updateSortModel(newSortModel)}
                                        />
                                    </ThemeProvider>
                                </Box>
                            </div>
                            <Box className="category-title push-top push-bottom">Adicionar</Box>
                            <TextField
                                className={'inputPlaceholder'}
                                placeholder='Buscar clientes'
                                onChange={handleFilterChange}
                                variant="outlined"
                                size="small"
                                sx={{ marginTop: '.4rem', marginBottom: '.4rem', backgroundColor: '#EFEFEF' }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                fullWidth
                            />
                            {newClientsModel.length > 0 ?
                                <List dense sx={{ width: '100%', height: "100px", "padding": "0.3rem 0", marginTop: '.5rem', "overflow": "auto", bgcolor: 'background.paper' }}>
                                    {newClientsModel.map((value) => {
                                        const labelId = `list-item-unique-key-${value.id}`
                                        const itemid = `item-unique-id-${value.id}`
                                        return (
                                            <ListItem
                                                sx={{ marginBottom: ".25rem" }}
                                                key={itemid}
                                                disablePadding
                                                secondaryAction={
                                                    <IconButton edge="end" aria-label="comments">
                                                        {/* <RemoveCircleOutlineIcon onClick={() => { removeFromGroup(value.id) }} /> */}
                                                        <AddIcon onClick={() => { addToGroup(value) }} />
                                                    </IconButton>
                                                }
                                            >
                                                <ListItemButton >
                                                    <ListItemText 
                                                        sx={{ margin: "0" }} 
                                                        id={labelId} 
                                                        primary={value.name} 
                                                        secondary={(value.telephone || value.email) ? (
                                                            <Box className="clients-secondary-list-items">
                                                                {value.telephone && (
                                                                    <Box>
                                                                        <PhoneIcon />
                                                                        {value.telephone}
                                                                    </Box>
                                                                )}
                                                                {value.email && (
                                                                    <Box>
                                                                        <EmailIcon style={{ color: "#757575" }}/>
                                                                        {value.email}
                                                                    </Box>
                                                                )}
                                                            </Box>
                                                        ) : null} 
                                                    />
                                                </ListItemButton>
                                            </ListItem>
                                        )
                                    })}
                                </List>
                                : <Box sx={{ "padding": "1rem" }}>Nenhum cliente encontrado...</Box>}

                        </>
                    </DialogContent>

                    <DialogActions style={{ padding: "10px 20px 10px 10px" }}>
                        <LoadingButton
                            onClick={props.handleClose}
                            color="primary"

                        >
                            Cancelar
                        </LoadingButton>
                        <LoadingButton
                            onClick={submit}
                            color="primary"

                        >
                            {props.buttonText}
                        </LoadingButton>
                    </DialogActions>
                </Dialog>
            }
        </div >
    )
}

export default EditGroupMembersModal
