import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from "prop-types";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

const RemoveButton = ({ onClick }) => {

    const useStyles = makeStyles(() => ({
        button: {
            color: "#DFB160",
            cursor: "pointer",
            '&:hover': {
                color: "#21446C",
            },
        },
    }));

    const classes = useStyles();

    return (<DeleteOutlineIcon onClick={onClick} className={classes.button} color="primary" />
    )
}

RemoveButton.propTypes = {
    onClick: PropTypes.func
};

export default RemoveButton;