import React, { useState, useEffect } from 'react';

import { IMaskInput } from 'react-imask';

import {
    Box,
    Button,
} from '@mui/material';

import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import { DialogActions } from '@mui/material';
import { TextField } from '@mui/material';
import { FormHelperText } from '@mui/material';



import Table from "../../../../Table";
import '../../../styles.css';
import api from '../../../../../api';

const CampaignHistory = props => {
    const [historyData, updateHistoryData] = useState([])
    const [ready, updateReady] = useState(false)

    const fetchHistoryData = async (id) => {
        if (id === 0) return
        const url = `/api/enterprises/${props.enterpriseId}/campaign/${id}/campaign_history/`;
        const response = await api.get(url)
        updateHistoryData(response.data)

    }
    useEffect(() => {
        updateReady(true)
    }, [historyData])

    useEffect(() => {
        updateReady(false)
        fetchHistoryData(props.id)
    }, [props.open])

    const createHistoryColumns = () => {
        return (
            [
                {
                    field: 'run_datetime', headerName: 'Data', flex: 1, type: 'dateTime',
                    valueGetter: ({ value }) => {
                        if (value) {
                            const date = new Date(value);
                            date.setHours(date.getHours() - 3);
                            return date;
                        }
                    },
                    renderCell: (params) => (
                        <Box>
                            {params.value ? params.value.toLocaleString('pt-BR') : ''}
                        </Box>
                    )
                },
                { field: 'amount', headerName: 'Quantidade', flex: 0.5 },
                {
                    field: 'success_percentage', headerName: 'Sucesso', flex: 0.5,
                    renderCell: (params) => (
                        <Box>
                            {params.value}%
                        </Box>
                    )
                },
                {
                    field: 'used_percentage', headerName: 'Resposta', flex: 0.5,
                    renderCell: (params) => (
                        <Box>
                            {params.value}%
                        </Box>
                    )
                },

            ]
        )
    }


    return (
        <div>
            {ready &&
                <Dialog keepMounted
                    open={props.open}
                    onClose={props.handleClose}
                    aria-labelledby="customized-dialog-title"
                    fullWidth
                    disablePortal
                    disableEnforceFocus
                    sx={{position:'absolute'}}
                >
                    <DialogTitle id="customized-dialog-title">
                        {props.title}: {props.name}
                    </DialogTitle>
                    <div className='tableWrapper'>
                        <Table
                            columns={createHistoryColumns()}
                            rows={historyData}
                            checkboxSelection={false}
                            pagination={false}
                            pageSize={null}
                            filterMode="client"

                        />
                    </div>
                </Dialog>
            }
        </div>

    );
}

export default CampaignHistory
