import React, { useState, useEffect, useContext } from 'react'
import { constructorContext } from '..'
import {
    Box, Dialog, DialogActions, DialogContent, DialogTitle,
    Tooltip, Button, TextField, Divider, Select, MenuItem, InputLabel, FormControl
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import api from '../../../../api';
import useNotification from '../../../Notification';
import EmojiTextarea from '../../../Input/EmojiTextarea';
import InputAdornment from '@mui/material/InputAdornment';

import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import { FileDrop } from 'react-file-drop';
import ImportIcon from '@mui/icons-material/Upload';

import PauseIcon from '@mui/icons-material/Pause';
import PlayIcon from '@mui/icons-material/PlayCircle';
import AutorenewIcon from '@mui/icons-material/Autorenew';

import MaleIcon from '@mui/icons-material/Male';
import FemaleIcon from '@mui/icons-material/Female';

const action = (data) => {
    if (data?.id)
        return 'Editar'
    return 'Adicionar'
}

const MessagesEditModal = ({ data, setData, getRows }) => {
    const { enterpriseId, activeTab, setLoading } = useContext(constructorContext)

    const [name, setName] = useState('')
    const [message, setMessage] = useState('')
    const [type, setType] = useState(0)
    const [gender, setGender] = useState(1)
    const [file, setFile] = useState()
    const [playingAudio, setPlayingAudio] = useState({})
    const [smallLoading, setSmallLoading] = useState(false)

    const checkButtonText = () => {
        const text = []

        if (name.length == 0)
            text.push(<Box>Nome não pode estar vazio.</Box>)


        if ((activeTab == 0 || type == 0) && (!message || message?.length == 0))
            text.push(<Box>Mensagem não pode estar vazia.</Box>)

        if (activeTab == 1 && !data.id && type == 1 && !file) {
            text.push(<Box>Adicione um arquivo de áudio.</Box>)
        }


        if (text.length == 0)
            return ''

        return (text)
    }

    useEffect(async () => {
        if (!data.id)
            return
        setName(data.name)

        if (data.type == 'uploaded')
            setType(1)

        if (data.transcription)
            setMessage(data.transcription)
        else
            setMessage(data.transcription)

        if (data.tts_gender == 'FEMALE')
            setGender(1)
    }, [data])

    const submit = async () => {
        let res = null
        const url = `api/enterprises/${enterpriseId}/messages/${(activeTab == 0 ? 'chat/' : 'voice/')}`

        let body = {}
        if (activeTab == 0) {
            body = {
                name: name,
                transcription: message,
            }
        }
        else {
            body = new FormData()
            body.append('name', name)
            if (file)
                body.append('file', file)
            if (type == 0) {
                body.append('type', 'google')
                body.append('tts_gender', gender == 0 ? 'MALE' : 'FEMALE')
                body.append('transcription', message)
            }
        }


        setLoading(true)
        if (!data?.id)
            res = await api.post(url, body, { timeout: 0 })
        else
            res = await api.patch(url + `${data.id}/`, body, { timeout: 0 })
        setLoading(false)

        if (!(res.status === 200 || res.status === 201)) {
            if ('name' in res.data && res.data.name[0] === 'Ensure this field has no more than 45 characters.') {
                useNotification(
                    'Campo Inválido!',
                    `O campo nome não pode ter mais de 45 caracteres.`,
                    'danger'
                )
                return
            }
            useNotification(
                'Ops!',
                `Não foi possível ${action(data).toLowerCase()} a mensagem.`,
                'danger'
            )
            return
        }
        getRows()
        setData()
        useNotification(
            'Sucesso!',
            `Mensagem ${res.status == 201 ? 'adicionada' : 'atualizada'} com sucesso.`,
            'success'
        )

    }


    const handleAudioPlay = async (url) => {

        if (smallLoading)
            return

        if (playingAudio.audio != null) {
            playingAudio.audio.pause()
            playingAudio.audio.currentTime = 0;
        }

        setSmallLoading(true)
        setPlayingAudio({ audio: null })

        let audio = new Audio(url);
        audio.onended = (() => { setPlayingAudio({ audio: null }) })

        try {
            await audio.play();
            setPlayingAudio({ audio: audio })
        } catch (e) {
            setSmallLoading(false)
            setPlayingAudio({ audio: null })
            useNotification(
                'Ops!',
                'Esse audio não pode ser reproduzido.',
                'danger'
            )
        }

        setSmallLoading(false)

    }

    const testAudio = async () => {
        const body = {
            transcription: message,
            tts_gender: gender == 0 ? 'MALE' : 'FEMALE'
        }

        const url = `api/enterprises/${enterpriseId}/messages/voice/listen/`
        setSmallLoading(true)
        const res = await api.post(url, body, { timeout: 0 })
        setSmallLoading(false)
        if (res.status == 200) {
            handleAudioPlay("data:audio/wav;base64," + res.data);

        }
        else {
            useNotification(
                'Ops!',
                `Não foi possível ouvir a mensagem, certifique-se que a licença da API está instalada.`,
                'danger'
            )
        }

    }

    return (
        <>
            <Dialog open={true}
                fullWidth
                disablePortal
                disableEnforceFocus
                sx={{position:'absolute'}}
                >
                <DialogTitle
                    id="customized-dialog-title" className={'group-flexbox'}>
                    <span>{action(data)} Mensagem</span>
                    <Tooltip title="Fechar" >
                        <Button className="action-button"  >
                            <CloseIcon onClick={() => { setData() }} />
                        </Button>
                    </Tooltip>
                </DialogTitle>
                <Divider />
                <DialogContent className='piloto-modal-flex'>

                    <TextField
                        size='small'
                        label='Nome'
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />

                    {activeTab == 0 ?


                        <EmojiTextarea
                            text={message}
                            setText={(event) => setMessage(event.target.value)}
                            setEmoji={(text) => setMessage(text)}
                            label="Mensagem"
                            childComponent="MessageBox"
                        />
                        :
                        <>
                            <Box className='BuilderBotSelectorBox'>
                                <Button
                                    variant={type === 0 ? 'contained' : ''}
                                    onClick={() => setType(0)}
                                >
                                    Google
                                </Button>
                                <Button
                                    variant={type === 1 ? 'contained' : ''}
                                    onClick={() => setType(1)}
                                >
                                    Importar arquivo
                                </Button>
                            </Box>
                            {type == 0 ?
                                <>
                                    <TextField
                                        size='small'
                                        label='Mensagem'
                                        value={message}
                                        onChange={(e) => setMessage(e.target.value)}
                                        multiline
                                        maxRows={6}
                                        fullWidth
                                        inputProps={{
                                            maxLength: 320
                                        }}
                                    />
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Box
                                            sx={{ alignSelf: 'self-end' }}
                                            className='BuilderBotSelectorBox'>
                                            <Tooltip title='MASCULINO'>
                                                <Button
                                                    variant={gender === 0 ? 'contained' : ''}
                                                    onClick={() => setGender(0)}
                                                >
                                                    <MaleIcon />
                                                </Button>
                                            </Tooltip>
                                            <Tooltip title='FEMININO'>
                                                <Button
                                                    variant={gender === 1 ? 'contained' : ''}
                                                    onClick={() => setGender(1)}
                                                >
                                                    <FemaleIcon />
                                                </Button>
                                            </Tooltip>
                                        </Box>

                                        {playingAudio.audio ?
                                            <>
                                                {playingAudio.paused ?
                                                    <Tooltip title="Continuar" >
                                                        <Button
                                                            variant='contained'
                                                            color="success"
                                                            sx={{ alignSelf: 'self-end' }}
                                                            onClick={() => {
                                                                playingAudio.audio.play()
                                                                setPlayingAudio(p => ({ ...p, paused: false }))
                                                            }}
                                                        ><PlayIcon /></Button>
                                                    </Tooltip>
                                                    :
                                                    <Tooltip title="Pausar" >
                                                        <Button
                                                            variant='contained'
                                                            sx={{ alignSelf: 'self-end' }}
                                                            onClick={() => {
                                                                if (smallLoading)
                                                                    return
                                                                playingAudio.audio.pause()
                                                                setPlayingAudio(p => ({ ...p, paused: true }))
                                                            }}
                                                        >{smallLoading ? <AutorenewIcon className={'spinner'} />
                                                            :
                                                            <PauseIcon />}</Button>
                                                    </Tooltip>

                                                }
                                            </>
                                            :
                                            <>
                                                {!smallLoading ?
                                                    <Tooltip title="Ouvir" >
                                                        <Button
                                                            variant='contained'
                                                            sx={{ alignSelf: 'self-end' }}
                                                            onClick={testAudio}
                                                        ><PlayIcon /></Button>
                                                    </Tooltip>
                                                    :
                                                    <Tooltip title="Carregando" >
                                                        <Button
                                                            variant='contained'
                                                            sx={{ alignSelf: 'self-end' }}
                                                            onClick={() => { }}
                                                        ><AutorenewIcon className={'spinner'} /></Button>
                                                    </Tooltip>}
                                            </>
                                        }

                                    </Box>
                                </>
                                :
                                <div className="file-drop-container">
                                    <FileDrop
                                        onDrop={(files, event) => { setFile(files[0]) }}
                                    >
                                        {file ? <span>{file.name}</span> :
                                            <span>Arraste e solte para inserir o arquivo de áudio</span>
                                        }

                                        <span>ou</span>
                                        <LoadingButton
                                            variant="contained"
                                            color="primary"
                                            component="label"
                                            loading={false}
                                            loadingPosition="start"
                                            startIcon={<ImportIcon />}
                                            disabled={false}
                                            onClick={() => { }}
                                        >
                                            Selecione o arquivo.
                                            <input
                                                hidden
                                                type="file"
                                                accept="audio/*"
                                                onChange={(e) => { setFile(e.target.files[0]) }}
                                            />

                                        </LoadingButton>
                                    </FileDrop>
                                </div>
                            }

                        </>
                    }
                </DialogContent >
                <DialogActions style={{ padding: "10px 20px 10px 10px" }}>
                    <Button
                        onClick={() => { setData() }}>
                        Cancelar
                    </Button>
                    <Tooltip title={checkButtonText()} placement="top" describeChild={true}>
                        <span>
                            <Button
                                disabled={checkButtonText()}
                                onClick={submit}>
                                Salvar
                            </Button>
                        </span>
                    </Tooltip>
                </DialogActions>
            </Dialog >
        </>
    )
}

export default MessagesEditModal