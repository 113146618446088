import React from 'react'

import { Box } from '@mui/material'
import './styles.css'
import GraphToolbar from '../default-toolbar'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import { graph_theme, convert_time, defaultNames } from '../default-toolbar'

const columns = graph => {
  const columns = []
  if (graph === 'agent')
    columns.push({ field: 'agent_name', headerName: 'Operador', flex: 1, renderCell: defaultNames })
  else columns.push({ field: 'queue_description', headerName: 'Fila', flex: 1, renderCell: defaultNames, forceQueue: true })
  columns.push({
    field: 'name',
    headerName: 'Etiqueta',
    flex: 1,
    renderCell: (params) => <Box className='graph-label-bubble' sx={{backgroundColor: params.row.color}}>{params.value}</Box>
  })
  columns.push({
    field: 'count',
    headerName: 'Quantidade',
    flex: .5
  })
  return columns
}

const Labels = ({ data, option }) => {
  return (
    <Box className='graph-main-divisor'>
      <Box className='graph-main-table'>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={graph_theme}>
            <DataGridPro
              density='compact'
              columns={columns(option)}
              rows={data?.rows.map((r, index) => ({ ...r, id: index + 1 }))}
              hideFooter={true}
              components={{
                Toolbar: GraphToolbar
              }}
            />
          </ThemeProvider>
        </StyledEngineProvider>
      </Box>
      <Box className='graph-main-dashboards'>
        <Box className='graph-time-rows'></Box>
      </Box>
    </Box>
  )
}

export default Labels
