import React, { useState, useEffect, useContext } from 'react'
import {
    Box, Dialog, DialogActions, DialogContent, DialogTitle,
    Tooltip, Button, TextField, Divider, Select, MenuItem, InputLabel, FormControl, Chip
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import api from '../../../../api';
import useNotification from '../../../Notification';
import { constructorContext } from '../../Builder';
import TooltipIndicators from '../../../Dash/Tooltip';
import CancelIcon from '@mui/icons-material/Cancel';
import { alphabeticalSort } from '../../../../utils/alphabeticalSort';

const action = (data) => {
    if (data?.id)
        return 'Editar'
    return 'Adicionar'
}


const EditBotModal = ({ data, setData, getRows }) => {

    const { activeTab, enterpriseId, setLoading } = useContext(constructorContext)

    const [name, setName] = useState('')
    const [mcdu, setMCDU] = useState([])
    const [mcduList, setMCDUList] = useState([])
    const [moduleList, setModuleList] = useState([])
    const [initialModule, setInitialModule] = useState({})
    const [selectedChatweb, setSelectedChatweb] = useState({})

    const checkButtonText = () => {
        const text = []

        if (name.length == 0)
            text.push(<Box>Nome não pode estar vazio.</Box>)

        if (mcdu && mcdu.length > 0 && initialModule && !initialModule.id)
            text.push(<Box>Com Canal selecionado, o módulo inicial também deve ser selecionado.</Box>)

        if (text.length == 0)
            return ''

        return (text)
    }
    const getUrl = () => {
        let url = `api/enterprises/${enterpriseId}/mcdu/`
        return url + (activeTab == 0 ? 'chat/' : 'voice/')
    }

    const getMCDU = async () => {
        const res = await api.get(getUrl(), { timeout: 0 })
        if (res.status == 200) {
            setMCDUList(res.data)
            return
        }
        useNotification(
            'Ops!',
            'Não foi possível carregar os canais.',
            'danger'
        )

    }

    const getIVR = async (id) => {
        const destiny = activeTab == 0 ? 'chat/' : 'voice/'
        const res = await api.get(`api/enterprises/${enterpriseId}/ura/${destiny}${id}/`)
        if (res.status == 200) {
            setName(res.data.name)
            setModuleList(res.data.modules)
            return
        }
        useNotification(
            'Ops!',
            'Não foi possível carregar o bot.',
            'danger'
        )
    }

    useEffect(() => {
        if (mcduList && mcduList.length > 0 && data.mcdu.length > 0) {
            const mcdu_ids = data.mcdu.map(each => each.id)
            const foundMCDU = mcduList.filter(each => mcdu_ids.includes(each.id))
            if (foundMCDU && foundMCDU.length > 0) {
                setMCDU(foundMCDU)
                setInitialModule(moduleList.find(each => each.id === foundMCDU[0].ivr_module_id))
            }
        }
        setLoading(false)
    }, [mcduList])

    useEffect(async () => {
        if (!data.id)
            return
        setLoading(true)
        await getIVR(data.id)
        getMCDU()
    }, [data])

    const submit = async () => {
        let res = null
        const url = `api/enterprises/${enterpriseId}/ura/${(activeTab == 0 ? 'chat/' : 'voice/')}`

        const body = {
            name: name,
            mcdu: mcdu,
            initialModule: initialModule
        }

        setLoading(true)
        if (!data?.id)
            res = await api.post(url, body, { timeout: 0 })
        else
            res = await api.patch(url + `${data.id}/`, body, { timeout: 0 })
        setLoading(false)

        if (!(res.status === 200 || res.status === 201)) {
            useNotification(
                'Ops!',
                `Não foi possível ${action()} o bot.`,
                'danger'
            )
            return
        }
        getRows()
        setData()
        useNotification(
            'Sucesso!',
            `Bot ${res.status == 201 ? 'adicionado' : 'atualizado'} com sucesso.`,
            'success'
        )


    }

    const chatwebtext = () => {
        return `<script type="text/javascript">
    var LHCChatOptions = {};
    LHCChatOptions.opt = {widget_height:390,widget_width:300,popup_height:520,popup_width:500};
    (function() {
    var po = document.createElement('script'); po.type = 'text/javascript'; po.async = true;
    var refferer = (document.referrer) ? encodeURIComponent(document.referrer) : '';
    var location  = (document.location) ? encodeURIComponent(document.location) : '';
    po.src = '${location.origin}/chat/index.php/por/chat/getstatus/(click)/internal/(position)/bottom_right/(top)/390/(units)/pixels/(leaveamessage)/false/(department)/2/(mcdu)/${selectedChatweb?.mcdu}/(proactive)/0/?r='+refferer+'&l='+location;
    var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(po, s);
    })();
    </script>
    `}

    const chatwebinfotext = 'Copie e cole esse link no código html do seu site.'

    const chatwebinfo = () => {
        return (
            <>
                <span>Link</span>
                <TooltipIndicators
                    text={chatwebinfotext}
                />
            </>
        )
    }

    const chatWeb = mcdu.filter(each => each.entrypoint === 'CHATWEB')

    return (
        <>
            <Dialog open={true}
                fullWidth
                disablePortal
                disableEnforceFocus
                sx={{ position: 'absolute' }}
            >
                <DialogTitle
                    id="customized-dialog-title" className={'group-flexbox'}>
                    <span>{action(data)} Bot</span>
                    <Tooltip title="Fechar" >
                        <Button className="action-button"  >
                            <CloseIcon onClick={() => { setData() }} />
                        </Button>
                    </Tooltip>
                </DialogTitle>
                <Divider />
                <DialogContent className='piloto-modal-flex'>

                    <TextField
                        size='small'
                        label='Nome'
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        inputProps={{
                            maxLength:45
                        }}
                    />
                    {data?.id &&

                        <>
                            <FormControl fullWidth>
                                <InputLabel size='small' id='select-on-pilot'>Canal</InputLabel>
                                <Select
                                    multiple={true}
                                    size='small'
                                    labelId='select-on-pilot'
                                    label='Canal'
                                    value={mcdu}
                                    onChange={(e) => setMCDU(e.target.value)}

                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {selected.map((value) => (
                                                <Chip
                                                    size='small'
                                                    key={value}
                                                    label={`${value.entrypoint} ${value?.mcdu ? value.mcdu : null}`}
                                                    deleteIcon={
                                                        <CancelIcon
                                                            onMouseDown={(event) => event.stopPropagation()}
                                                        />}
                                                    onDelete={() => setMCDU(mcdu.filter(each => each !== value))}
                                                />
                                            ))}
                                        </Box>
                                    )}

                                >
                                    {activeTab == 0 ? mcduList.map(obj =>
                                        <MenuItem
                                            className={mcdu.includes(obj) ? 'hide-from-list' : ''}
                                            value={obj}>
                                            {obj.entrypoint} {obj?.mcdu ? `(${obj.mcdu})` : null}
                                        </MenuItem>)
                                        :
                                        mcduList.map(obj =>
                                            <MenuItem
                                                className={mcdu.includes(obj) ? 'hide-from-list' : ''}
                                                value={obj}>
                                                {obj.mcdu}
                                            </MenuItem>
                                        )
                                    }
                                </Select>
                            </FormControl>

                            <FormControl fullWidth>
                                <InputLabel id='select-on-initialModule'>Módulo Inicial</InputLabel>
                                <Select
                                    size='small'
                                    labelId='select-on-initialModule'
                                    label='Módulo Inicial'
                                    value={initialModule}
                                    onChange={(e) => setInitialModule(e.target.value)}
                                >
                                    {moduleList.sort(alphabeticalSort).map(obj =>
                                        <MenuItem value={obj}>{obj.name}</MenuItem>)}
                                </Select>
                            </FormControl>
                            {chatWeb.length > 0 &&

                                <Tooltip placement='top' title={'Selecione o canal para receber o link'}>
                                    <Box labelId='select-pilot' sx={{ display: 'flex', gap: '1rem', flexWrap: 'wrap' }}>
                                        {chatWeb.map(each =>
                                            <Button
                                                sx={{ border: selectedChatweb === each ? "none" : "1px solid #c7c7c7" }}
                                                variant={selectedChatweb === each ? 'contained' : ''}
                                                onClick={() => setSelectedChatweb(each)}>{each.mcdu}</Button>)}
                                    </Box>
                                </Tooltip>
                            }

                            {(activeTab == 0 && chatWeb.length > 0 && selectedChatweb?.mcdu) &&
                                <>

                                    <Tooltip placement='top' title={chatwebinfotext}>
                                        <TextField
                                            labelId='link-text-area'
                                            label={chatwebinfo()}
                                            size='small'
                                            multiline
                                            maxRows={5}
                                            InputProps={{
                                                readonly: true
                                            }}
                                            value={chatwebtext()}
                                        />
                                    </Tooltip>
                                    <Button
                                        variant='contained'
                                        onClick={() => {
                                            navigator.clipboard.writeText(chatwebtext()).then(function () {
                                                console.log('Async: Copying to clipboard was successful!');
                                            }, function (err) {
                                                console.error('Async: Could not copy text: ', err);
                                            });
                                        }}>
                                        Copiar link
                                    </Button>
                                </>}
                        </>
                    }
                </DialogContent>
                <DialogActions style={{ padding: "10px 20px 10px 10px" }}>
                    <Button
                        onClick={() => { setData() }}>
                        Cancelar
                    </Button>
                    <Tooltip title={checkButtonText()} placement="top" describeChild={true}>
                        <span>
                            <Button
                                disabled={checkButtonText()}
                                onClick={submit}>
                                Salvar
                            </Button>
                        </span>
                    </Tooltip>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default EditBotModal