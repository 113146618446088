import React, { 
    PureComponent 
} from 'react';

import { 
    LineChart, 
    ReferenceLine, 
    Line, 
    XAxis, 
    YAxis, 
    CartesianGrid, 
    Tooltip, 
    Legend, 
    ResponsiveContainer 
} from 'recharts';


export default class Dialog360UsageChart extends PureComponent {

    static demoUrl = 'https://codesandbox.io/s/simple-line-chart-kec3v';

    render() {
        return (
            <ResponsiveContainer width="100%" height="100%">
                <LineChart
                    data={this.props.data}
                    margin={{
                        top    : 5,
                        right  : 30,
                        left   : 20,
                        bottom : 5,
                    }}
                >
                    <CartesianGrid stroke="#E2E2E2"/>
                    <XAxis dataKey="name"/>
                    <YAxis/>
                    <Tooltip/>
                    <Legend/>
                    <ReferenceLine y="1000" stroke="black" strokeWidth={3}
                        label={{ 
                            position: 'top'
                        }}
                    />
                    <Line dataKey="Total" stroke="#007bff" strokeWidth={2}/>
                    <Line dataKey="Ativas" stroke="#17a2b8" strokeWidth={2}/>
                    <Line dataKey="Passivas" stroke="#28a745" strokeWidth={2}/>
                    <Line dataKey="Marketing" stroke="#ffc107" strokeWidth={2}/>
                    <Line dataKey="Utilidade" stroke="#dc3545" strokeWidth={2}/>
                    <Line dataKey="Autenticação" stroke="#6c757d" strokeWidth={2}/>
                </LineChart>
            </ResponsiveContainer>
        );
    }
}
