import React, { useState, useEffect, useMemo } from 'react'

import {
    Box, Grid, FormControl, InputLabel, Select, MenuItem, Tooltip, IconButton,
    Paper, Divider, InputBase, List, Button
} from '@mui/material'

import {
    DataGridPro,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    gridClasses,
    LicenseInfo,
    ptBR
} from '@mui/x-data-grid-pro';

import { ptBR as corePtBR } from '@mui/material/locale';

import {
    createTheme,
    ThemeProvider,
    StyledEngineProvider
} from '@mui/material/styles';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import EmojiTextarea from '../../Input/EmojiTextarea';
import useNotification from '../../Notification';
import api from '../../../api';
import LoadingButton from '@mui/lab/LoadingButton'
import PredefinedMessagesModal from './PredefinedMessagesModal';
import ConfirmationDialog from '../../Dialogs/Confirmation';
import Loading from '../../Loading';
import { emojiReplaceJSX } from '../../../utils/emojiDict';
import { stringOperators } from '../../../utils/filterOperators';

const theme = createTheme({
    palette: {
        primary: { main: '#21446C' },
    },
}, ptBR, corePtBR);




const PredefinedMessages = ({ enterpriseId }) => {

    const [loading, setLoading] = useState(false)
    const [messages, setMessages] = useState([])
    const [selected, setSelected] = useState()
    const [showDeleteDialog, setShowDeleteDialog] = useState(false)
    const [showPredefinedMessages, setShowPredefinedMessages] = useState(false)

    const CustomToolbar = () => {

        return (
            <GridToolbarContainer className={gridClasses.toolbarContainer}>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <LoadingButton
                    size="small"
                    component="label"
                    startIcon={<AddCircleIcon />}
                    onClick={(e) => { setShowPredefinedMessages(true); setSelected() }}
                >
                    Adicionar
                </LoadingButton>
            </GridToolbarContainer>
        );
    }

    const deleteMessage = async () => {
        if (!selected) return;

        setLoading(true)
        const res = await api.delete(`/api/enterprises/${enterpriseId}/predefinedmessages/${selected.id}/`)
        setLoading(false)

        if (res.status == 204) {
            useNotification(
                'Sucesso!',
                `Mensagem predefinida deletada com sucesso.`,
                'success'
            );
            getMessages()
            setShowDeleteDialog(false);
        }
        else {
            useNotification(
                'Erro!',
                'Ocorreu um erro interno ao deletar a mensagem predefinida.',
                'danger'
            );
        }
    }

    const addMessage = async (text, category, id = 0) => {
 
        const data = {
            message: text,
            category: category
        }
        const url = `/api/enterprises/${enterpriseId}/predefinedmessages/`
        let res = { status: 400 }
        setLoading(true)
        if (id != 0)
            res = await api.patch(`${url}${id}/`, data)
        else
            res = await api.post(url, data)
        setLoading(false)

        if (res.status == 201 || res.status == 200) {
            useNotification(
                'Sucesso!',
                `Mensagem predefinida ${id != 0 ? 'editada' : 'adicionada'} com sucesso.`,
                'success'
            );
            getMessages()
        }
        else {
            useNotification(
                'Erro!',
                `Ocorreu um erro interno ao ${id != 0 ? 'editar' : 'adicionar'} a mensagem predefinida.`,
                'danger'
            );
        }
    }

    const getMessages = async () => {

        const res = await api.get(`/api/enterprises/${enterpriseId}/predefinedmessages/`)

        if (res.status == 200) {
            setMessages(res.data)
        }
        else {
            useNotification(
                'Ops!',
                'Mensagens predefinidas não carregadas',
                'danger')
        }
    }

    useEffect(async () => {
        setLoading(true)
        await getMessages()
        setLoading(false)
    }, [])

    const dataColumns = useMemo(() => {
        return [
            { field: 'message', headerName: 'Mensagem', flex: 0.65, filterOperators: stringOperators, 
                renderCell: (params) => {
                    return (<span>{emojiReplaceJSX(params.value)}</span >)
                } 
            },
            { field: 'category', headerName: 'Categoria', flex: 0.25, filterOperators: stringOperators },
            {
                field: 'action', headerName: 'Ações',
                filterable: false, sortable: false,
                renderCell: (params) => (
                    <>
                        <Button className="action-button"
                            onClick={() => { setShowPredefinedMessages(true); setSelected(params.row) }}>
                            <EditIcon />
                        </Button>
                        <Button className="action-button delete"
                            onClick={() => { setShowDeleteDialog(true); setSelected(params.row) }}>
                            <DeleteIcon />
                        </Button>
                    </>
                ),
            },
        ]
    }, [])

const renderDeleteText = () => {
    if (!selected) return ''
    return (
        <>
            <Box sx={{ padding: '1rem 0rem' }}>Deseja deletar a mensagem predefinida?</Box>
            <Box><Box sx={{ fontWeight: 'bold' }}>Categoria:</Box> {selected.category}</Box>
            <Box sx={{overflowWrap:'break-word'}}><Box sx={{ fontWeight: 'bold' }}>Mensagem:</Box> {emojiReplaceJSX(selected.message)}</Box>
        </>
    )
}

return (
    <>
        <Loading loading={loading} />
        <ConfirmationDialog open={showDeleteDialog}
            dividers
            title="Excluir mensagem predefinida"
            description={renderDeleteText()}
            handleClose={() => setShowDeleteDialog(false)}
            handleSubmit={deleteMessage}
        />
        {showPredefinedMessages &&
            <PredefinedMessagesModal
                title={`${selected ? 'Editar' : 'Nova'} mensagem predefinida`}
                messages={messages}
                addMessage={addMessage}
                selected={selected}
                handleClose={() => { setShowPredefinedMessages(false); setSelected() }}
            />
        }
        <Box className="settings-body" >
            <Box className="predefined-settings">

                <Grid container sx={{ height: '100%' }}>
                    <Box sx={{ width: '100%', height: '100%' }}>
                        <ThemeProvider theme={theme}>
                            <DataGridPro
                                disableSelectionOnClick
                                disableDensitySelector
                                density="compact"
                                columns={dataColumns}
                                rows={messages}
                                components={{
                                    Toolbar: CustomToolbar,
                                }}
                            />
                        </ThemeProvider>
                    </Box>

                </Grid>
            </Box>
        </Box>
    </>
)
}

export default PredefinedMessages