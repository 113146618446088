import React, { useState, useEffect, useContext, useRef } from "react";

import { Button } from "@mui/material";

import api from "../../api";

import { getSession } from "../../auth";

import "./index.css";
import { QueueSocket } from "../../routes";

const Webphone = ({ reAnchor, handleClose, handleLoading }) => {

    const webextensionKwik = 'webextension-kwik';

    const {sendNotification} = useContext(QueueSocket)

    const [ready, setReady] = useState(false);

    const [socketURI, setSocketURI] = useState('');
    const [turnHost, setTurnHost] = useState('');
    const [extension, setExtension] = useState('');
    const [password, setPassword] = useState('');
  
    const user = getSession();
    const buttonRef = useRef(null);
    
    const has_voice = user.profile.enterprise.has_voice;  

    // https://stackoverflow.com/questions/30683628/react-js-setting-value-of-input
    function setNativeValue(element, value) {
        let lastValue = element.value;
        element.value = value;

        let event = new Event("input", { target: element, bubbles: true });
        event.simulated = true;
        
        let tracker = element._valueTracker;

        if (tracker)
            tracker.setValue(lastValue);

        element.dispatchEvent(event);
    }

    function setJS() {
        return new Promise((resolve, reject) => {
            const script = document.createElement('script');

            let file = webextensionKwik;
            let host = window.location.hostname;
            if (host.includes('dev')) {
                file = `/${file}-dev.js`;
            } else if(host.includes('staging')) {
                file = `https://cf-templates-bum1ctwi7lwa-us-east-1.s3.amazonaws.com/${file}-dev.js`;
                // Handle development and staging environments
            } else {
                file = `https://cf-templates-bum1ctwi7lwa-us-east-1.s3.amazonaws.com/${file}.js`;
            }

            script.src   = file
            script.async = true;

            script.onload = () => {
                resolve();
            };
        
            script.onerror = (error) => {
                reject(error);
            };
            
            document.body.appendChild(script);
        });
    }

    function setCSS() {
        const link = document.createElement('link');

        let file = webextensionKwik;
        let host = window.location.hostname;
        if (host.includes('dev')) {
            file = `/${file}-dev.css`;
        } else if(host.includes('staging')) {
            file = `https://cf-templates-bum1ctwi7lwa-us-east-1.s3.amazonaws.com/${file}-dev.css`;
            // Handle development and staging environments
        } else {
            file = `https://cf-templates-bum1ctwi7lwa-us-east-1.s3.amazonaws.com/${file}.css`;
        }

        link.rel  = 'stylesheet';     
        link.type = 'text/css';     
        link.href = file
        
        document.head.appendChild(link);    
        
        const bgColor = document.querySelector("html");
        bgColor.style.backgroundColor="white";
    }

    const fetchData = (exten) => {
        api.get('/api/WEBRTC/ps_auths/'+exten+'/get_exten/')
        .then((res) => {
            setSocketURI(res.data.ws_uri);
            setTurnHost(res.data.turn_host);
            setExtension(res.data.username);
            setPassword(res.data.password);
            setTimeout(() => {
                if (buttonRef.current) {
                    buttonRef.current.click();                
                    setTimeout(() => {                        
                        handleClose();                    
                    }, 500);
                }
            }, 1500);
            
        })        
        .catch((err) => {
            console.error(err);
        });
    }

    const startWebphone = () => {
        const container = document.getElementById('tryit-jssip-container')
        const inputs = container.querySelectorAll('form input');
        const button = container.querySelector('form button');

        if (inputs.length !== 0 && button) {
            
            let turnUDP = inputs[1].value;
            let turnTCP = inputs[2].value;

            turnUDP = turnUDP.replace(/:(.*?):/, `:${turnHost}:`);
            turnTCP = turnTCP.replace(/:(.*?):/, `:${turnHost}:`);

            inputs[0].value = setNativeValue(inputs[0], socketURI);
            inputs[1].value = setNativeValue(inputs[1], turnUDP);
            inputs[2].value = setNativeValue(inputs[2], turnTCP);
            inputs[3].value = setNativeValue(inputs[3], extension);
            inputs[4].value = setNativeValue(inputs[4], password);

            button.click();

            container.style.display = 'block';

            reAnchor();
            setReady(true);

            window.UA.on('newRTCSession', (data) => {
                if (data.originator === 'remote') {
                    reAnchor();
                    sendNotification('newCall', 1, 1);
                }
            });            
            window.UA.on('registered', (data) => {
                handleLoading(false);                
            });            
        }
    }

    if (has_voice) {
        if (user.profile.agent.preferred_interface) {
            useEffect(() => {
                setCSS();
                setJS()
                .then(() => {
                    const exten = user.profile.agent.preferred_interface.split('SIP/')[1];    
                    fetchData(exten);
                });
            }, []);

            return (
                <>
                    <div id="tryit-jssip-container"></div>
                    {! ready &&
                        <Button variant="contained"                        
                            onClick={startWebphone}
                            ref={buttonRef}
                        >
                            Iniciar
                        </Button>
                    }
                </>
            );
        }
        else {
            return (
                <div style={{ padding: "5px" }}>
                    Ramal não configurado.
                </div>
            );
        }
    }
    else
        return (
            <div style={{ padding: "5px" }}>
                Voz não contratada.
            </div>
        );
}

export default Webphone;
