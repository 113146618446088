import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';

import api from '../../../api'
import { getSession } from '../../../auth'

let timeoutId

export default function GroupAutocomplete({ allGroups, onChange, selectedGroup }) {
  
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [nextPage, setNextPage] = React.useState()
  
  const loading = open && options.length === 0;

  const getGroups = (search, groupsUrl) => {

    if (groupsUrl === undefined || (typeof search !== 'object' && search !== undefined)) {
      setOpen(true)
      setOptions([])
    } else setOpen(true)

    const enterpriseId = getSession().profile.enterprise.id

    let url
    if (groupsUrl !== undefined)
      url = `/api/${groupsUrl.split('/api/')[1]}`
    else {
      url = `/api/enterprises/${enterpriseId}/contact_group/noserialize/`
      if (typeof search !== 'object' && search !== undefined)
        url = `${url}?search=${search}`   
    }

    api.get(url).then((res) => {
      if (res.status === 200)
        setOptions((prevOptions) => [...prevOptions, ...res.data.results])
        setNextPage(res.data.next)
    }).catch((err) => {})
  }

  const handleScroll = (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target;
    if (nextPage && scrollHeight - scrollTop <= clientHeight + 5)
      getGroups(undefined, nextPage)
  }

  return (
    <Autocomplete
      size="small"
      sx={{ padding: "1rem" }}
      open={open}
      onOpen={() => getGroups()}
      onClose={() => setOpen(false)}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      getOptionLabel={(option) => option.name}
      options={options}
      loading={loading}
      filterOptions={(x) => x}
      onInputChange={(event) => {
        if (event?.type === 'change') {
          if (timeoutId)
            clearTimeout(timeoutId)
          timeoutId = setTimeout(() => getGroups(event.target.value), 1000)
        }
      }}
      onChange={(event, group) => onChange(group)}
      ListboxProps={{ onScroll: handleScroll }}
      value={selectedGroup}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Buscar Segmentos"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
