import React from "react";
import { FormControlLabel } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import MaterialCheckbox from "@mui/material/Checkbox";
import PropTypes from "prop-types";
import Typography from "../../Typography"

const Checkbox = ({ checked, onChange, name, id, label }) => {

    const useStyles = makeStyles({
        checkbox: {
            color: "gray",
            "&$checked": {
                color: "gray",
            },
            "&$checked + $track": {
                backgroundColor: "gray",
            }
        },
        checked: {
            color: "#21446C",
            "&$checked": {
                color: "#21446C",
            },
            "&$checked + $track": {
                backgroundColor: "#21446C",
            }
        },

        track: {}
    });

    const classes = useStyles();

    return (

        <FormControlLabel
            control={
                <MaterialCheckbox
                    type="checkbox"
                    id={id}
                    name={name}
                    checked={checked}
                    onChange={onChange}
                    classes={{
                        switchBase: classes.checkbox,
                        track: classes.track,
                        checked: classes.checked
                    }}
                />}
            label={<Typography color="darkGray" text={label} />}
        />
    )
}

Checkbox.propTypes = {
    onClick: PropTypes.func,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    name: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.string
};

export default Checkbox;