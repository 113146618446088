import React from 'react';

import PasswordInput from '../../Input';
import { Button } from '@mui/material';

import './styles.css';

const MetricsLogin = ({ onChangeFn, onClickFn }) => {
  return (
    <div className="metrics-login">
      <PasswordInput
        label="Senha"
        onChange={onChangeFn}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={onClickFn}
        fullWidth
      >
        Login
      </Button>
    </div>
  );
}

export default MetricsLogin;