import React, { useState, useContext } from 'react'

import { Box, TextField } from '@mui/material'

import TableRowsIcon from '@mui/icons-material/TableRows'
import LoadingButton from '@mui/lab/LoadingButton';

import ResponsiveGenerateButton from '../../../Clients/ResponsiveGenerateButton'

import { ScreenContext } from '../..'

const NotificationFilter = () => {
    const { 
            updateStartDateTime,
            updateEndDateTime,
            endDateTime, 
            startDateTime, 
            fetchNotifications, 
        } = useContext(ScreenContext)
    const [loading, updateLoading] = useState(false)
    
    const handleUpdateNotifications = async () => {
        updateLoading(true)
        await fetchNotifications(startDateTime, endDateTime)
        updateLoading(false)
    }

    return (
        <Box style={{ marginBottom: "0.75rem" }}>
            <TextField
                className="start-textfield"
                size="small"
                label="Início"
                type="datetime-local"
                variant="outlined"
                defaultValue={startDateTime}
                onChange={(event) => updateStartDateTime(event.target.value)}
                InputLabelProps={{ shrink: true }}
            />
            <TextField
                className="end-textfield"
                size="small"
                label="Fim"
                type="datetime-local"
                variant="outlined"
                defaultValue={endDateTime}
                onChange={(event) => updateEndDateTime(event.target.value)}
                InputLabelProps={{ shrink: true }}
                style={{ marginLeft: "0.25rem" }}
            />
            <LoadingButton style={{ marginLeft: "0.5rem", height: "100%" }}
                onClick={() => handleUpdateNotifications()}
                loadingPosition="start"
                loading={loading}
                variant="contained"
                startIcon={<TableRowsIcon />}
            >
                Pesquisar
            </LoadingButton>
        </Box>
    )
}

export default NotificationFilter