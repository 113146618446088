import React from "react";

import { IconButton, Button } from '@mui/material';
import TableIcon from '@mui/icons-material/TableChart';

import useMediaQuery from '@mui/material/useMediaQuery';

export default function ResponsiveClientsButton(
  { className, onClickFn, id, variant }
) {
  const isSmallScreen = useMediaQuery('(max-width: 60rem)');

  return (
    <>
      {isSmallScreen ? (
        <IconButton
          className={className}
          onClick={onClickFn}
          size="small"
          variant={variant}
        >
          <TableIcon />
        </IconButton>
      ) : (
        <Button className={className}
          onClick={onClickFn}
          id={id}
          variant={variant}
          endIcon={<TableIcon />}
        >
          Clientes
        </Button>
      )}
    </>
  )
}