import { adaptV4Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { createTheme } from '@mui/material/styles';


export const muiTheme = createTheme(adaptV4Theme({
    overrides: {
        MuiStepIcon: {

            text: {
                fill: "#21446C",
            },
            root: {
                color: 'white',
                iconColor: "pink",
                '&$active': {
                    color: '#DFB160',
                },
                '&$completed': {
                    color: '#E0E0E0',
                },
            },
        },
        MuiStepLabel: {
            label: {
                color: 'white',
                fontSize: "15px",

                '&$active': {
                    color: '#DFB160',
                },
                '&$completed': {
                    color: '#E0E0E0',
                },
            },
        }

    }
}));

export const useStyles = makeStyles(() => ({
    header: {
        backgroundColor: "#21446c",
        minHeight: "100vh",
    },
    logo: {
        width: "12vw"

    },
    stepper: {
        backgroundColor: "#21446c",
        color: "white",
        iconColor: "white"
    },
    description: {
        fontSize: "0.9vw"
    },
    active: {
        backgroundColor: "white"

    },
    stepContent: {
        backgroundColor: "white",
        color: "#21446C"
    }


}));

