import React, { useEffect, useReducer } from 'react'
import { Dialog, Box, DialogTitle, DialogContent, Divider, Button } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import {
    DataGridPro,
    ptBR,
} from '@mui/x-data-grid-pro';
import { ptBR as corePtBR } from '@mui/material/locale';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import defaultReducer from '../../utils/defaultReducer';
import dayjs from 'dayjs';
import api from '../../api';
import Loading from '../Loading';


const theme = createTheme({
    palette: {
        primary: { main: '#21446C' },
    },
}, ptBR, corePtBR);


const buildEventColumns = () => {
    return [
        { field: 'event', headerName: 'Evento', flex: 1 },
        { field: 'value', headerName: 'Valor', flex: 1 },
        { field: 'execution_time', headerName: 'Hora de execução', flex: 1,
        valueGetter: ({value}) => dayjs(value).toDate().toLocaleString() },
    ]
}

const loadRows = async (data, setState) => {
    setState({ loading: true })
    const res = await api.get(`api/LHC/chats/${data.uniqueid}/`)
    if (res.status === 200){
        setState({ 
            rows: res.data.messages.map((m) =>{ 
                return {
                    id: m.id,
                    event: "mensagem",
                    value: m.message,
                    execution_time: m.isotime,
                } 
            }), 
            loading: false 
        })
        return
    }
        
    setState({ loading: false })
}

const AutomationEventDialog = ({ data,  setParent }) => {

    const [state, setState] = useReducer(defaultReducer, {
        rows: [],
        loading: false
    })

    useEffect(() => {
        if (data){
            loadRows(data, setState)
        }
    }, [data])

    return (
        <>
            <>
            {state.loading ? <Loading loading={true} /> : null}
                <Dialog
                    open='true'
                    disablePortal
                    fullWidth
                    maxWidth='md'
                    disableEnforceFocus
                    sx={{ position: 'absolute' }}
                >
                    <DialogTitle className={'group-flexbox'}>
                        <span>Eventos da automação: {data.uniqueid}</span> <Button sx={{ 'minWidth': '2rem', 'padding': '0' }} onClick={() => { setParent({ eventLog: null }) }} ><CloseIcon /></Button>
                    </DialogTitle>
                    <Divider />
                    <DialogContent className='content-dialog-rows'>

                        <Box sx={{ width: '100%', height: '520px' }}>
                            <ThemeProvider theme={theme}>
                                <DataGridPro
                                    columns={buildEventColumns()}
                                    rows={state.rows}
                                    disableSelectionOnClick
                                    density={'compact'}
                                />
                            </ThemeProvider>
                        </Box>

                    </DialogContent>
                </Dialog >
            </>
        </>
    )
}

export default AutomationEventDialog