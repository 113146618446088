import React, { useState, forwardRef, useReducer, useEffect } from "react";

import InputMask from 'react-input-mask';
import base64url from 'base64url';

import Header from "../../components/Header";

import { Box, Grid, Typography, TextField, CircularProgress, Alert } from "@mui/material";

import LoadingButton from '@mui/lab/LoadingButton';

import { useHistory } from "react-router-dom";

import api from '../../api';
import useNotification from '../../components/Notification';
import defaultReducer from '../../utils/defaultReducer';

import './styles.css'

const Trial = () => {

    const [state, setState] = useReducer(defaultReducer, {
        loading    : false,
        name       : '',
        agentName  : '',
        agentEmail : '',
        telephone  : '',
        site       : '',
        field      : '',
        interests  : '',
        error      : '',
        finished   : false,
        startDate  : '',
        endDate    : ''
    })

    const [recaptchaToken, setRecaptchaToken] = useState("");
  
    const history = useHistory();

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://www.google.com/recaptcha/api.js?render=6Ld7ZKsoAAAAALBFMr7xA5lAzj8ba3vaKDyKumK4";
        script.async = true;
        document.body.appendChild(script);
    }, []);

    const isValidToken = async (token) => {
        const response = await api.post(`/api/recaptcha/verify_token/`, { token: token })
        return response.status === 200
    }

    const handleSubmit = async () => {
        const loadRecaptcha = async () => {
            await window.grecaptcha.execute("6Ld7ZKsoAAAAALBFMr7xA5lAzj8ba3vaKDyKumK4", { action: "submit" }).then(async (token) => {
                if (await isValidToken(token)) {
                    setState({ loading: true })
                    const response = await api.post(`/api/enterprises/start_trial/`, {
                        name        : state.name,
                        agent_name  : state.agentName,
                        agent_email : state.agentEmail,
                        telephone   : (state.telephone) ? `+55${state.telephone.match(/\d+/g).map(Number)}` : '',
                        site        : state.site,
                        field       : state.field,
                        interests   : state.interests
                    })

                    if (response.status !== 201) {
                        if (response.status === 400) 
                            setState({ 
                                loading : false, 
                                error   : response.data 
                            })
                        return
                    }

                    setState({
                        loading   : false,
                        error     : '',
                        finished  : true,
                        startDate : new Date(response.data['created_at']).toLocaleString('pt-BR'),
                        endDate   : new Date(response.data['trial_end_at']).toLocaleString('pt-BR')
                    });   
                }
            });
        };
        loadRecaptcha();
    }

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }

    return (
        <Box display="flex">
            <Header onClick={() => history.push("/")}/>
            <Box className="trial-form">
                <Grid container spacing={2}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Box display="flex">
                            <Typography className="trial-title" variant="h4">
                                Bem-vindo à desgustação do Kwik
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <span>Para realizar seu cadastro, preencha os campos abaixo.</span>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <TextField
                            label="Empresa"
                            variant="outlined"
                            name="name"
                            defaultValue={state.name}
                            onChange={(event) => setState({ name: event.target.value })}
                            fullWidth
                            required
                            size="small"
                            disabled={state.finished}
                        />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <TextField
                            label="Nome"
                            variant="outlined"
                            name="agent-name"
                            defaultValue={state.agentName}
                            onChange={(event) => setState({ agentName: event.target.value })}
                            fullWidth
                            required
                            size="small"
                            disabled={state.finished}
                        />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <TextField
                            label="E-mail"
                            variant="outlined"
                            name="agent-email"
                            defaultValue={state.agentEmail}
                            onChange={(event) => setState({ agentEmail: event.target.value })}
                            fullWidth
                            required
                            size="small"
                            disabled={state.finished}
                        />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        {! state.finished && 
                            <InputMask
                                mask="(99) 99999-9999"
                                maskChar=""
                                value={state.telephone}
                                onChange={(event) => setState({ telephone: event.target.value })}
                            >
                                {() => (
                                    <TextField
                                        label="Telefone"
                                        variant="outlined"
                                        name="telefone"
                                        fullWidth
                                        size="small"
                                    />
                                )}
                            </InputMask>
                        }
                        {state.finished &&
                            <TextField
                                label="Telefone"
                                variant="outlined"
                                name="telefone"
                                fullWidth
                                size="small"
                                value={state.telephone}
                                disabled={true}
                            />
                        }
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <TextField
                            label="Website"
                            variant="outlined"
                            name="site"
                            defaultValue={state.site}
                            onChange={(event) => setState({ site: event.target.value })}
                            fullWidth
                            size="small"
                            disabled={state.finished}
                        />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <TextField
                            label="Campo de atuação"
                            variant="outlined"
                            name="field"
                            defaultValue={state.field}
                            onChange={(event) => setState({ field: event.target.value })}
                            fullWidth
                            size="small"
                            disabled={state.finished}
                        />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <TextField
                            label="Interesses (ex. WhatsApp)"
                            variant="outlined"
                            name="interests"
                            defaultValue={state.interests}
                            onChange={(event) => setState({ interests: event.target.value })}
                            fullWidth
                            size="small"
                            disabled={state.finished}
                        />
                    </Grid>
                    {state.finished && 
                        <>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Alert severity="success">Parabéns, seu cadastro foi realizado com sucesso!</Alert>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Alert severity="warning">Sua degustação tem início em <strong>{state.startDate}</strong> e será válida até <strong>{state.endDate}</strong>.</Alert>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Alert severity="info">Em alguns instantes, você receberá um e-mail no endereço <strong>{state.agentEmail}</strong> para realizar o cadastro da sua senha, e em seguida, poderá utilizar o Kwik.</Alert>
                            </Grid>
                        </>
                    }
                    {state.error &&
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Alert severity="error">{state.error}</Alert>
                        </Grid>
                    }
                    {! state.finished && 
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <LoadingButton sx={{ width: '100%' }}
                                variant="contained"
                                color="primary"
                                onClick={() => handleSubmit()}
                                disabled={! state.name || ! state.agentName || ! isValidEmail(state.agentEmail)}
                                loading={state.loading}
                            >
                                Cadastrar
                            </LoadingButton>
                        </Grid>
                    }
                </Grid>
            </Box>
        </Box>
    );
};

export default Trial
