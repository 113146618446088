import React from "react";

import { Box, Tabs, Tab } from '@mui/material';

import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import 'animate.css/animate.min.css';

import Integrations from './Integrations';
import VoiceComponent from './Voice';
import userPermissions from "../User/Permissions";

import UsersMenu from "./Users/usersMenu";

import SettingsDialogs from '../Dialogs/SettingsDialogs';

import { getSession } from "../../auth";

import "./styles.css";

import QueueMenu from "./NewMenus/QueueMenu";
import OnChatMenu from "./NewMenus/OnChatMenu";


const groups = userPermissions();


const tabs = (parent) => {

	return {
		'queues': {
			enabled: groups.isAdmin,
			label: 'Filas',
			component: (
				<QueueMenu />
			)
		},
		'users': {
			enabled: groups.isSupervisor,
			label: 'Usuários',
			component: (
				<UsersMenu enterpriseId={parent.state.enterpriseId} />
			)
		},
		'integrations': {
			enabled: groups.isAdmin,
			label: "Integrações",
			component: (
				<Integrations
					enterpriseId={parent.state.enterpriseId}
					setState={(state) => parent.setState(state)}
				/>
			)
		},
		'onchat': {
			enabled: groups.isAdmin,
			label: 'Atendimento',
			component: (
				<OnChatMenu />
			)
		},
		'voz': {
			enabled: parent.state.has_voice && groups.isAdmin,
			label: "Telefonia",
			component: (
				<VoiceComponent
					enterpriseId={parent.state.enterpriseId}
				/>
			)
		},
	}

}

const getInitialTab = () => {
	const initialTab = localStorage.getItem('settings-initialTab')
	if (!initialTab)
		return groups.isAdmin ? 'queues' : 'users'
	return initialTab
}

class Settings extends React.Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			enterprise: null,
			enterpriseId: 0,
			activeTab: '',
			activeComponent: null,
			open: false,
			has_voice: false,
			vivre: false,
			has_whatsapp: false
		};

		this.tabIndex = 0
		this.selectedTab = 0
	}

	setActiveTab = (tab, component) => {
		localStorage.setItem('settings-initialTab', tab)
		this.setState({ activeTab: tab, });
	}

	generateShippingReceiving = () => {
		return (!this.shippingReceiving) ? null : this.shippingReceiving.generateDialog();
	}

	generateRestrictedWWords = () => {
		return (!this.restrictedWords) ? null : this.restrictedWords.generateDialog();
	}

	generateUsers = () => {
		return (!this.users) ? null : this.users.generateDialog();
	}

	generateDialog = () => {
		switch (this.state.activeTab) {
			case 1:
				return this.generateShippingReceiving();
			case 2:
				return this.generateRestrictedWWords();
			case 3:
				return this.generateUsers();
		}
	}

	componentWillMount() {
		this._isMounted = true;

		const user = getSession()
		const initialTab = getInitialTab()

		this.setActiveTab(initialTab)

		if (user) {
			this.state.enterprise = user.profile.enterprise
			this.state.enterpriseId = user.profile.enterprise.id
			this.state.has_voice = user.profile.enterprise.has_voice
			this.state.vivre = user.profile.enterprise.settings.vivre
			this.state.has_whatsapp = user.profile.enterprise.platforms.whatsapp
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	renderComponent = () => {
		const activeComponent = tabs(this)[this.state.activeTab]
		return (<>{activeComponent?.enabled ? activeComponent?.component : null}</>)
	}

	render() {
		return (
			<Box className="settings" sx={{ height: '100%' }}>
				<ReactNotifications />
				<SettingsDialogs
					open={this.state.open}
					handleClose={() => this.setState({ open: false })}
					contents={this.generateDialog()}
				/>
				<Box className="menu-settings">
					<Tabs
						onChange={() => { }}
						variant="scrollable"
						scrollButtons="auto"
						aria-label="scrollable auto tabs example"
						value={this.state.activeTab}
					>
						{Object.entries(tabs(this)).map(([key, value]) =>
							<Box onClick={() => this.setActiveTab(key, value)}>
								{value.enabled &&
									<Tab className={this.state.activeTab === key ? 'tabActive' : 'tabInactive'} label={value.label} value={key} />
								}
							</Box>
						)}
					</Tabs>
				</Box>
				{this.renderComponent()}
			</Box>
		);
	}
}

export default Settings;
