import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
    button: {
        backgroundColor: "#21446c",
        color: "white",
        width: "100%",
        height: "45px",
       marginTop: "1vw"
    },
    button_back: {
        backgroundColor: "#DFB160",
        color: "#white",
        width: "100%",
        height: "45px",
        marginTop: "1vw"

    },
    title: {
        color: "#21446C",
        fontWeight: 500,
    }
}));


