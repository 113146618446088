import React, { useReducer } from 'react'

import {
    Box, Button, Dialog, DialogActions,
    DialogContent, DialogTitle, Divider, Tooltip, FormControl, Select,
    InputLabel, MenuItem, ListSubheader
} from "@mui/material";
import {lockedReducer} from '../../../../utils/defaultReducer'
import CloseIcon from '@mui/icons-material/Close'
import LoadingButton from '@mui/lab/LoadingButton';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import useNotification from '../../../Notification';
import { getSession } from '../../../../auth';

import createChatbot from '../../../../utils/createChatbot'
import api from '../../../../api';

import { ptBR as localePTBR } from '@mui/x-date-pickers/locales';

const enterpriseId = getSession()?.profile?.enterprise.id

const verifySave = (state, newCrmSelected) => {

    if (newCrmSelected)
        return true

    const response = []

    if (response.length === 0)
        return ''

    return response
}

const submit = async (state, setState, setParent, reloadData) => {
    setParent({ loading: true })

    const payload = { ...state }

    if (state.fromMarketplace) {
        const chatbot = await createChatbot(payload.builderbot)
        payload.builderbot = chatbot.typebot.id
    }
    delete payload.fromMarketplace

    const id = payload.id
    delete payload.id
    let res = null
    if (id === 0)
        res = await api.post(`api/enterprises/${enterpriseId}/events/`, payload, { timeout: 10000 })
    else
        res = await api.patch(`api/enterprises/${enterpriseId}/events/${id}/`, payload, { timeout: 10000 })

    setParent({ loading: false })
    if (res && [200, 201].includes(res.status)) {
        useNotification(
            'Sucesso!',
            `Evento atualizado com sucesso!`,
            'success'
        )
        reloadData()
        setParent({ edit: false })
        return
    }

    useNotification(
        'Ops.',
        `Falha ao editar evento.`,
        'danger'
    )

}

const EditEvent = ({ data, parent, setParent, reloadData, newCrmSelected }) => {
    const [state, setState] = useReducer(lockedReducer, {
        id              : 'id' in data ? data.id : 0,
        status          : 'status' in data ? data.status : true,
        builderbot      : 'builderbot' in data ? data.builderbot : '',
        event           : 'action' in data ? data.action : '',
        fromMarketplace : false
    })

    const builderItems = parent.builder.botlist.filter(each => each.publicId)

    let marketplaceItems = []
    if ('marketplace' in parent.builder)
        marketplaceItems = parent.builder.marketplace.filter(each => each.publicId)

    const disabled = verifySave(state, newCrmSelected)

    return (
        <>
            <Dialog
                open='true'
                disablePortal
                fullWidth
                maxWidth='xs'
                disableEnforceFocus
                sx={{ position: 'absolute' }}
            >
                <DialogTitle className={'group-flexbox'}>
                    <span>Editar Evento</span> <Button sx={{ 'minWidth': '2rem', 'padding': '0' }} onClick={() => { setParent({ edit: null }) }} ><CloseIcon /></Button>
                </DialogTitle>
                <Divider />
                <DialogContent className='content-dialog-rows' sx={{gap: '1rem', display:'flex', flexDirection:'column'}}>
                    <LocalizationProvider sx={{ justifyContent: 'space-between' }} dateAdapter={AdapterDayjs} adapterLocale={'pt-br'}
                        localeText={localePTBR.components.MuiLocalizationProvider.defaultProps.localeText}

                    >
                        <Box className='BuilderBotSelectorBox'>
                            <Button
                                variant={state.status === true ? 'contained' : ''}
                                onClick={() => setState({ status: true })}
                            >
                                Ativo
                            </Button>
                            <Button
                                variant={state.status === false ? 'contained' : ''}
                                onClick={() => setState({ status: false })}
                            >
                                Inativo
                            </Button>
                        </Box>
                        <FormControl
                            fullWidth
                            size="small"
                            disabled
                            sx={{ flex: .45 }}
                        >
                            <InputLabel id="event-bot-select-label">Evento</InputLabel>
                            <Select
                                labelId="event-bot-select-label"
                                id="event-box-simple-select"
                                onChange={(event) => setState({ event: event.target.value })}
                                value={state.event}
                                label='Evento'
                            >
                                {parent.integration_events.map(each => (
                                    <MenuItem key={`${each.action}-select-event-frequency`} value={each.action}>{each.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl
                            fullWidth
                            size="small"
                            sx={{ flex: .45 }}
                        >
                            <InputLabel id="flux-bot-select-label">Fluxo</InputLabel>
                            <Select
                                labelId="flux-bot-select-label"
                                id="flux-box-simple-select"
                                onChange={(event) => {
                                    let fromMarketplace = false
                                    if (marketplaceItems.some(item => item.id === event.target.value))
                                        fromMarketplace = true
                                    setState({ 
                                        builderbot: event.target.value,
                                        fromMarketplace: fromMarketplace
                                    })
                                }}
                                value={state.builderbot}
                                label='Fluxo'
                                disabled={newCrmSelected}
                            >
                                {marketplaceItems.length !== 0 ? (
                                    [
                                        <ListSubheader key="chatbots-header" className="marketplace-subheader">Chatbots</ListSubheader>,
                                        ...builderItems.map(each => (
                                            <MenuItem key={`${each.id}-select-flux-frequency`} value={each.id}>
                                                {each.name}
                                            </MenuItem>
                                        )),
                                        <ListSubheader key="marketplace-header" className="marketplace-subheader divider">Marketplace</ListSubheader>,
                                        ...marketplaceItems.map(each => (
                                            <MenuItem key={`${each.id}-select-flux-frequency`} value={each.id}>
                                                {each.name}
                                            </MenuItem>
                                        )),
                                    ]
                                ) : (
                                    builderItems.map(each => (
                                        <MenuItem key={`${each.id}-select-flux-frequency`} value={each.id}>{each.name}</MenuItem>
                                    ))
                                )}
                            </Select>
                        </FormControl>
                    </LocalizationProvider>
                </DialogContent>
                <DialogActions style={{ padding: "10px 20px 10px 10px" }}>
                    <Tooltip title={disabled}>
                        <span>
                            <LoadingButton
                                onClick={() => {
                                    if (state.builderbot == null && state.status === true){
                                        useNotification(
                                            'Ops.',
                                            `Selecione um fluxo para o evento.`,
                                            'danger'
                                        )
                                        return
                                    }
                                    submit(state, setState, setParent, reloadData)
                                }}
                                color="primary"
                                disabled={disabled}
                            >
                                Salvar
                            </LoadingButton>
                        </span></Tooltip>
                </DialogActions>
            </Dialog >
            {/* {state.showconfirmation && <ConfirmationDialog
                open={true}
                title={'Inativar automação'}
                description={'Inativar essa automação desativará qualquer rotina relacionada em andamento. Deseja prosseguir?'}
                handleClose={() => setState({ showconfirmation: null })}
                handleSubmit={() => submit(state, setState, setParent, reloadData)}
            />} */}
        </>
    )
}

export default EditEvent