import React, { useState, useEffect } from 'react'

import {
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Box
} from '@mui/material'

import InputAdornment from '@mui/material/InputAdornment'

import LoadingButton from '@mui/lab/LoadingButton'

import CloseIcon from '@mui/icons-material/Close'

import TooltipIndicators from '../../../Dash/Tooltip'


export default function AddExceptionsDialog({ data, handleClose, submit }) {

    const [exceptions, setExceptions] = useState('')

    const submitNewExceptions = () => {
        data.exceptions = exceptions
        submit(data)
    }

    useEffect(() => {
        setExceptions(data.exceptions)
    }, [])

    return (
        <div>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={true}
                disablePortal
                disableEnforceFocus
                sx={{ position: 'absolute' }}
            >
                <DialogTitle className="group-flexbox">
                    Endereços de e-mail (ou domínios) ignorados
                    <Button sx={{ 'minWidth': '2rem', 'padding': '0' }} onClick={handleClose}>
                        <CloseIcon />
                    </Button>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <TextField
                        size='small'
                        label='Endereços de e-mail ou domínios'
                        value={exceptions}
                        onChange={(e) => setExceptions(e.target.value)}
                        multiline
                        rows={6}
                        fullWidth
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end" sx={{height:'unset'}}>
                                    <TooltipIndicators
                                        text="Digite endereços de e-mail que deseja ignorar separados por vírgula. Exemplo: teste@test.com.br ou @test.com.br"
                                    />
                                </InputAdornment>
                            )
                        }}
                        inputProps={{
                            maxLength: 10000,
                        }}
                    />
                </DialogContent>
                <DialogActions >
                    <Box className="user-footer">
                        <LoadingButton className="button-created" onClick={handleClose}>
                            Cancelar
                        </LoadingButton>
                        <LoadingButton className="button-created" 
                            onClick={() => {
                                submitNewExceptions()
                            }}
                        >
                            Salvar
                        </LoadingButton>
                    </Box>
                </DialogActions>
            </Dialog>
        </div>
    );
}
