import React from "react";
import { Grid, TextField, Box, FormControlLabel } from "@mui/material";
import HeaderForm from "../../HeaderForm"
import TimeImage from "../../../../assets/images/time.svg"
import AddButton from '../../../Button/AddButton';
import PropTypes from "prop-types";
import SwitchButton from '../../../Button/SwitchButton';
import RemoveButton from '../../../Button/RemoveButton';
import Typography from "../../../Typography";

const TimeForm = ({ values, handleChange, setFieldValue }) => {

    return (
        <Grid container spacing={2} >
            <HeaderForm img={TimeImage} title="PERSONALIZE SEU ATENDIMENTO" subtitle="Horário de atendimento" width="75%" />
            <Box paddingBottom={3}>
                <Typography color="darkGray" text="É importante definir um horário de atendimento, para que as mensagens pré-definidas sejam enviadas corretamente, dentre outras configurações." />
            </Box>
            <Box paddingBottom={"5vw"} >
                {
                    values.times.map((time, i) => (
                        <Box key={i} >
                            <Grid container spacing={2}  >

                                <Grid item lg={3} md={3} sm={12} xs={12}>
                                    <Box paddingTop="5px">
                                        <Typography variant='subtitle1' color="darkGray" text={time.weekDay} />
                                    </Box>
                                </Grid>
                                <Grid item lg={2} md={2} sm={6} xs={12}>
                                    <FormControlLabel
                                        control={
                                            <SwitchButton
                                                checked={time.checked}
                                                onChange={handleChange}
                                                name={"times[" + i + "].checked"}
                                            />
                                        }
                                    />
                                </Grid>
                                <Grid item lg={2} md={2} sm={6} xs={12}>
                                    <TextField
                                        id=""
                                        name={"times[" + i + "].initialTime"}
                                        variant="outlined"
                                        fullWidth
                                        size="small"
                                        disabled={!time.checked}
                                        value={time.initialTime}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item lg={1} md={1} sm={3} xs={3}>
                                    <Box paddingTop="5px">
                                        <Typography variant='subtitle1' color="darkGray" text="às"></Typography>
                                    </Box>
                                </Grid>
                                <Grid item lg={2} md={2} sm={6} xs={12}>
                                    <TextField
                                        id=""
                                        name={"times[" + i + "].endTime"}
                                        variant="outlined"
                                        onChange={handleChange}
                                        fullWidth
                                        value={time.endTime}
                                        size="small"
                                        disabled={!time.checked}
                                    />
                                </Grid>
                                <Grid item lg={1} md={1} sm={6} xs={12}>
                                    <Box paddingTop="7px">{!time.showInterval && (
                                        <AddButton onClick={() => setFieldValue("times[" + i + "].showInterval", true)} />)}
                                    </Box>
                                </Grid>
                            </Grid>
                            {time.showInterval && (
                                <Grid container spacing={2} >
                                    <Grid item lg={5} md={5} sm={6} xs={12}></Grid>
                                    <Grid item lg={2} md={2} sm={6} xs={12}>
                                        <TextField
                                            id=""
                                            name={"times[" + i + "].initialTime2"}
                                            onChange={handleChange}
                                            value={time.initialTime2}
                                            variant="outlined"
                                            fullWidth
                                            size="small"
                                            disabled={!time.checked}
                                        />
                                    </Grid>
                                    <Grid item lg={1} md={1} sm={3} xs={3}>
                                        <Box paddingTop="5px">
                                            <Typography color="darkGray" variant='subtitle1' text="às"></Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item lg={2} md={2} sm={6} xs={12}>
                                        <TextField
                                            id=""
                                            name={"times[" + i + "].endTime2"}
                                            variant="outlined"
                                            onChange={handleChange}
                                            fullWidth
                                            value={time.endTime2}
                                            size="small"
                                            disabled={!time.checked}
                                        />
                                    </Grid>
                                    <Grid item lg={1} md={1} sm={6} xs={12}>
                                        <Box paddingTop="7px">
                                            <RemoveButton onClick={() => setFieldValue("times[" + i + "].showInterval", false)} />
                                        </Box>
                                    </Grid>
                                </Grid>)}
                        </Box>
                    ))}
            </Box >
        </Grid >

    );
};

TimeForm.propTypes = {
    values: PropTypes.object,
    handleChange: PropTypes.func,
    setFieldValue: PropTypes.func
}


export default TimeForm;
