import React, { useState } from 'react'

import { Box, Divider } from '@mui/material'

import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TelegramIcon from '@mui/icons-material/Telegram';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import PhoneDisabledIcon from '@mui/icons-material/PhoneDisabled';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import PauseIcon from '@mui/icons-material/Pause';
import useNotification from "../../../Notification";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import InstantChatIcon from '@mui/icons-material/Language';
import MicrosoftIcon from '@mui/icons-material/Groups';
import EmailIcon from '@mui/icons-material/Email';

import api from '../../../../api';
import { getSession } from '../../../../auth';

const History = ({ contactData, fetchHistoryMessages }) => {

    const [playingAudio, setPlayingAudio] = useState({})
    const [smallLoading, setSmallLoading] = useState(false)

    const callsData = contactData.calls || []
    callsData.map(each => {
        each.isotime = (new Date(`${each.date}T${each.time}`).getTime())
    })
    const chatData = contactData.chats || []
    chatData.map(each => each.isotime = each.time * 1000)
    const mergedData = [...callsData, ...chatData].sort((x,y) => x.isotime > y.isotime ?  -1 : 1)
    mergedData.splice(10)

    const enterprise = getSession().profile.enterprise;


    const convertAudio = async (exportUrl, accountcode) => {

        const data = {
            file: exportUrl
        }

        const res = await api.post(`/api/convertaudio/`, data)

        if (res.status == 200)
            return res.data
        console.error('Failed to convert data.')
        return ''
    }

    const handleAudioPlay = async (row) => {

        if (smallLoading)
            return

        if (playingAudio.audio != null) {
            playingAudio.audio.pause()
            playingAudio.audio.currentTime = 0;
        }

        setSmallLoading(true)
        setPlayingAudio({ id: row.id, audio: null })

        const accountcode = enterprise.accountcode
        const uniqueid = row.uniqueid;
        const type = row.type == 'Entrante' ? 'inbound' : 'outbound';
        const date = row.date.replaceAll('-', '');
        const destination = row.destination;
        const fileName = `${uniqueid}.WAV`;
        const leg = 0;

        let directory = `${accountcode}_${destination}`;
        if (type === 'inbound')
            // Directory name is different for inbound calls
            directory = directory.split('_').pop();

        //const exportUrl = `/export/${accountcode}/${type}/${date}/${directory}/${fileName}`;

        let exportUrl = ''
        
        let source = 'messages'

        try{
            if (row[source])
                exportUrl = atob(row[source])
        }catch(e){
            console.error(e)
            exportUrl = ''
        }

        let audio = new Audio(exportUrl);
        audio.onended = (() => { setPlayingAudio({ id: null, audio: null }) })
        try {
            await audio.play();
            setPlayingAudio({ id: row.id, audio: audio })
        } catch (err) {
            useNotification(
                'Aguarde!',
                'O áudio está sendo preparado.',
                'info'
            )
            try {
                const res = await convertAudio(exportUrl, accountcode)
                audio = new Audio(res);
                audio.onended = (() => { setPlayingAudio({ id: null, audio: null }) })
                await audio.play()
                setPlayingAudio({ id: row.id, audio: audio })
            }
            catch (err) {
                console.error(err)
                setPlayingAudio({ id: null, audio: null })
                useNotification(
                    'Ops...',
                    'O arquivo de áudio não existe.',
                    'danger'
                )
            }

        }

        setSmallLoading(false)

    }

    const getChatIcon = (platform) => {
        switch(platform){
            case 'WHATSAPP_ATIVO':
            case 'WHATSAPP':
                return <WhatsAppIcon className="fileIcon" sx={{ color: '#34af23' }}/>
                break
            case 'TELEGRAM':
                return <TelegramIcon className="fileIcon" sx={{ color: '#3390ec' }}/>
                break
            case 'INSTAGRAM':
                return <InstagramIcon className="fileIcon" sx={{ color: '#fd6b10' }}/>
                break
            case 'MESSENGER':
                return <FacebookIcon className="fileIcon" sx={{ color: '#0866ff' }}/>
                break
            case 'INSTANTCHAT':
                return <InstantChatIcon className="fileIcon" sx={{ color: '#5399DB' }}/>
                break
            case 'MSTEAMS':
                return <MicrosoftIcon className="fileIcon" sx={{ color: '#6264A7' }}/>
                break
            case 'EMAIL':
                return <EmailIcon className="fileIcon" sx={{ color: '#757575' }}/>
                break
            default:
                return <WhatsAppIcon className="fileIcon" />
                break
        }
    }

    return (
        <>
            <Box className="contactHistory-box-9">
                <Box
                    className="contactHistory-box-5"
                >
                    Últimos 10 atendimentos
                    <Divider className="contactHistory-divider" />
                    <Box className="contactHistory-box-7">
                        {(mergedData && mergedData.length) && mergedData.map((row, i) => 
                            <>
                                {'nick' in row ?
                                    <div>
                                        <Box
                                            key={i}
                                            className="itemHistory"
                                            onClick={() => fetchHistoryMessages(row)}
                                            style={{ display: "inline-flex", alignItems: "center" }}
                                        >
                                            {getChatIcon(row.platform)}
                                            {(new Date(row.isotime)).toLocaleString('pt-BR').slice(0, -3)}
                                        </Box>
                                    </div>
                                :
                                    <div>
                                        {playingAudio.id == row.id ?
                                            <>
                                                {playingAudio.paused ?
                                                    <Box
                                                        key={i}
                                                        className="itemHistory"
                                                        onClick={(event) => {
                                                            playingAudio.audio.play()
                                                            setPlayingAudio(p => ({ ...p, paused: false }))
                                                        }}
                                                        style={{ display: "inline-flex", alignItems: "center" }}
                                                    >
                                                        <PhoneEnabledIcon className="fileIcon" sx={{ color: 'green' }} />
                                                        {(new Date(row.isotime)).toLocaleString('pt-BR').slice(0, -3)}
                                                    </Box>
                                                    :
                                                    <Box
                                                        key={i}
                                                        className="itemHistory"
                                                        onClick={(event) => {
                                                            if (smallLoading)
                                                                return
                                                            playingAudio.audio.pause()
                                                            setPlayingAudio(p => ({ ...p, paused: true }))
                                                        }}
                                                        style={{ display: "inline-flex", alignItems: "center" }}
                                                    >
                                                        {smallLoading ? <AutorenewIcon className={'fileIcon spinner'} />
                                                            :
                                                            <PauseIcon className="fileIcon" />}
                                                        {(new Date(row.isotime)).toLocaleString('pt-BR').slice(0, -3)}
                                                    </Box>
                                                }
                                            </>
                                            :
                                            <>
                                                {row.status === 'Atendida' ?
                                                    <Box
                                                        key={i}
                                                        className="itemHistory"
                                                        onClick={() => handleAudioPlay(row)}
                                                        style={{ display: "inline-flex", alignItems: "center" }}
                                                    >
                                                        <PhoneEnabledIcon className="fileIcon" sx={{ color: 'green' }} />
                                                        {(new Date(row.isotime)).toLocaleString('pt-BR').slice(0, -3)}
                                                    </Box>
                                                    :
                                                    <Box
                                                        key={i}
                                                        className="itemHistory"
                                                        onClick={() => {  }}
                                                        style={{ display: "inline-flex", alignItems: "center" }}
                                                    >
                                                        <PhoneDisabledIcon className="fileIcon" sx={{ color: 'red' }}/>
                                                        {(new Date(row.isotime)).toLocaleString('pt-BR').slice(0, -3)}
                                                    </Box>
                                                }

                                            </>
                                        }
                                    </div>
                                }
                            </>
                        )}
                        {(!mergedData || !mergedData.length) &&
                            <Box className="noHistory">Não possui histórico.</Box>
                        }
                    </Box>
                </Box>
            </Box>
        </>
    )
}


export default History